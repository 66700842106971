import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_TitleMutation,
  useAdd_TitleMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type ITitleModal = IModal & {
  titleData?: { id: number; name: string };
};

const TitleSchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const TitleModal: React.FC<ITitleModal> = ({ onClose, titleData }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addTitle,
    { data: addTitleResult, loading: addTitleLoading, error: addTitleError },
  ] = useAdd_TitleMutation();

  const [
    updateTitle,
    {
      data: updateTitleResult,
      loading: updateTitleLoading,
      error: updateTitleError,
    },
  ] = useUpdate_TitleMutation();

  React.useEffect(() => {
    if (titleData) {
      setInitialValues({
        name: titleData.name,
      });
    }
  }, [titleData]);

  const addNewTitle = (values: ValueProps) => {
    addTitle({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addTitle,
    });
  };

  const upateExistingTitle = (values: ValueProps) => {
    updateTitle({
      variables: {
        id: titleData?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (titleData) {
      upateExistingTitle(values);
    } else {
      addNewTitle(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addTitleLoading || updateTitleLoading);
  const error = !!(addTitleError || updateTitleError);
  const success = !!(
    addTitleResult?.insert_title?.returning?.length ||
    updateTitleResult?.update_title?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TitleSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${titleData ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${titleData ? 'Update' : 'Add'} Title`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Title ${titleData ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Title Name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default TitleModal;
