import React from 'react';
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/modal/Modal';
import TextArea from '../../../components/textarea/TextArea';
import theme from '../../../theme/theme';
import { IModal } from '../../../utils/types';
import { Formik, FormikHelpers, Form } from 'formik';
import { RejectFormSchema } from '../../../utils/yup-schema';
import { useReject_ProspectMutation } from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  reason: string;
}

interface IProps extends IModal {
  id: number;
}
const RejectionDialog: React.FC<IProps> = ({ id, onClose }) => {
  const { userId } = useAuthState();
  const history = useHistory();
  const [
    rejectProspect,
    { data, loading, error },
  ] = useReject_ProspectMutation();
  const success = !!data?.update_member?.returning[0].id;

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        history.push('/dashboard/prospects');
      }, 1200);
    }
  }, [success, history]);

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    rejectProspect({
      variables: {
        id,
        reason: values.reason,
        doneBy: userId,
      },
      update: UpdateHandlers.rejectProspect,
    });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ reason: '' }}
      validationSchema={RejectFormSchema}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <Modal
            width="520px"
            onClose={onClose}
            icon={{ name: 'xmark', color: 'red' }}
            confirm={{
              type: 'submit',
              label: 'Reject prospect',
              loading: formikProps.isSubmitting || loading,
              className: 'actual-reject-btn',
            }}
            title="Reject Prospect">
            <React.Fragment>
              {error && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500], marginBottom: '20px' }}
                  className="success-message">
                  {`Prospect rejected successfully...`}
                </Flex>
              )}
              <p
                css={{
                  color: theme.colors.gray[500],
                  marginBottom: '8px',
                  fontSize: '14px',
                }}>
                Please provide reason for rejection here.
              </p>
              <TextArea
                name="reason"
                placeholder="Enter text here"
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.reason}
              />
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default RejectionDialog;
