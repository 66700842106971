import React from 'react';
import { jsx } from '@emotion/core';
import MenuItem from './MenuItem';
import Flex from '../../layout/Flex';
import Icon from '../../icon/Icon';
import theme from '../../../theme/theme';
import { useLocation } from 'react-router-dom';
import { IRoute } from '../../../routes/routes';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface IProps {
  route: IRoute;
  className?: string;
  id?: string;
  isActiveRoute?: boolean;
  isExpanded?: boolean;
  onOpen?(id: string, route: IRoute): void;
  openIds?: string[];
}

const SubMenu: React.FC<IProps> = ({
  route,
  isActiveRoute,
  isExpanded,
  onOpen = () => {},
  openIds,
  className,
}) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const { role } = useAuthState();

  const hasActiveRoute =
    route.children &&
    route.children?.filter((route) => {
      return route.route === pathname;
    }).length > 0;

  const isActiveSubMenu = openIds?.includes(route.subId as string);

  if (!route.allowed?.includes(role as string)) {
    return null;
  }

  if (!route.children) {
    return <MenuItem route={route}></MenuItem>;
  }

  return (
    <div
      className={className}
      css={{
        background: isActiveSubMenu ? theme.colors.gray[100] : 'none',
        borderLeft: `3px solid ${
          isActiveSubMenu ? theme.colors.blue[600] : 'transparent'
        }`,
      }}>
      <Flex
        jc="space-between"
        css={{
          // background: hasActiveRoute ? theme.colors.cyan[100] : "transparent",
          paddingRight: '8px',
          '&:hover': { background: theme.colors.gray[100] },
        }}
        ai="center"
        onClick={() => {
          onOpen(route.subId as string, route);
        }}>
        <MenuItem
          isSubMenu
          isActiveRoute={hasActiveRoute}
          css={{
            pointerEvents: 'none',
            marginRight: '16px',
            background: 'transparent',
          }}
          route={route}></MenuItem>
        {route.children && (
          <Icon
            color={
              hasActiveRoute ? theme.colors.blue[600] : theme.colors.gray[500]
            }
            style={{
              transform: isExpanded ? `rotate(180deg)` : 'none',
              transition: 'transform .5s',
              transformOrigin: 'center',
              fontSize: '10px',
            }}
            icon={['fas', 'chevron-down']}></Icon>
        )}
      </Flex>
      <div>
        <div
          ref={menuRef}
          css={{
            height: isExpanded ? 'auto' : 0,
            overflow: 'hidden',
            transition: 'height .25s',
          }}>
          {route.children.map((child, index) => {
            if (child.children) {
              return (
                <SubMenu
                  css={{ borderLeft: 'none' }}
                  key={index}
                  isActiveRoute={isActiveRoute}
                  route={child}
                  isExpanded={openIds?.includes(child.subId as string)}
                  onOpen={onOpen}
                />
              );
            }
            return (
              <MenuItem
                isSubMenu
                isActiveRoute={pathname === child.route}
                key={index}
                route={child}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default SubMenu;
