import React from 'react';
import Card from '../../../components/card/Card';
import Grid from '../../../components/layout/Grid';

interface IProp {
  goals: {
    id: string;
    name: string;
  }[];
  selectGoalType: (id: string) => any;
}

export const GoalType: React.FC<IProp> = ({ goals, selectGoalType }) => {
  return (
    <Grid lg={5}>
      {goals.map((goal, index) => (
        <Card key={index}>
          <p onClick={() => selectGoalType(goal.id)}>{goal.name}</p>
        </Card>
      ))}
    </Grid>
  );
};
