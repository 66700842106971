import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { sizes } from '../../utils/types';
import theme from '../../theme/theme';
import { getInitials } from '../../utils/helpers';
import Flex from '../layout/Flex';
/**@jsx jsx*/

interface IProps {
  name?: string;
  background?: string;
  textColor?: string;
  shape?: 'circular' | 'rounded';
  size?: sizes;
  src?: string;
  className?: string;
  styles?: CSSObject;
}

const avatarSizes: any = {
  '2xs': '16px',
  xs: '24px',
  sm: '32px',
  md: '42px',
  lg: '64px',
  xl: '100px',
  '2xl': '148px',
};

const fontSizes: any = {
  '2xs': '8px',
  xs: '10px',
  sm: '12px',
  md: '16px',
  lg: '24px',
  xl: '28px',
  '2xl': '32px',
};

const Avatar: React.FC<IProps> = ({
  children,
  name,
  size = 'md',
  shape = 'circular',
  background = theme.colors.blue[100],
  textColor = theme.colors.blue[800],
  className,
  styles,
}) => {
  const defaultAvatarStyles: CSSObject = {
    height: avatarSizes[size],
    width: avatarSizes[size],
    borderRadius: shape === 'circular' ? '50%' : '10%',
    fontSize: fontSizes[size],
    background: background,
    color: textColor,
    ...styles,
  };

  if (name) {
    return (
      <Flex
        className={className}
        ai="center"
        jc="center"
        css={{ ...defaultAvatarStyles }}>
        {getInitials(name)}
      </Flex>
    );
  }

  return (
    <Flex
      className={className}
      ai="center"
      jc="center"
      css={{ ...defaultAvatarStyles }}>
      {children}
    </Flex>
  );
};

export default Avatar;
