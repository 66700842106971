import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import ApksTable from './components/ApksTable';
import ApkModal from './components/ApkModal';
import { useApksQuery, useDelete_ApkMutation } from '../../generated/graphql';
import { IApk } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const Apks: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedApk, setSelectedApk] = React.useState<number | undefined>();
  const { data: apks, loading } = useApksQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteApk] = useDelete_ApkMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedApk(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (apk: IApk) => {
    setIsOpen(true);
    setSelectedApk(apk.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    try {
      const response = await deleteApk({
        variables: {
          id: selectedApk,
          doneBy: userId,
        },
        update: UpdateHandlers.deleteApk,
      });
      setShowConfirm(false);

      if (response.data?.update_apk?.affected_rows) {
        notify(true, 'APK deleted successfully.');
      } else {
        notify(false, 'failed to delete apk.');
      }
    } catch (err) {
      notify(false, 'failed to delete apk.');
    }
  };

  const confirm = (apk: IApk) => {
    setSelectedApk(apk.id);
    setShowConfirm(true);
  };

  const getApk = (id?: number) => {
    if (id) {
      const apk = apks?.apk?.find((apk) => apk.id === id);
      if (apk) {
        return {
          id: apk.id,
          name: apk.name,
          version: apk.version,
        };
      }
    }
    return;
  };

  const getApks = (): IApk[] => {
    return (
      apks?.apk?.map((apk) => ({
        id: apk.id,
        name: apk.name,
        version: apk.version,
        createdAt: apk.created_at,
        lastChanged: apk.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && <ApkModal onClose={close} apk={getApk(selectedApk)} />}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Industry"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="APKs"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <ApksTable
        isLoading={loading}
        apks={getApks()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Apks;
