import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import PaymentApprovalModal from './components/PaymentApprovalModal';
import { useGetPaymentsByValueDateQuery } from '../../generated/graphql';
import { ITableColumn } from '../../utils/types';
import Tag from '../../components/tag/Tag';
import { useParams } from 'react-router-dom';
import Stack from '../../components/stack/Stack';
import Button from '../../components/button/Button';
import theme from '../../theme/theme';
import { capitalize, formatMoney } from '../../utils/helpers';
import Grid, { GridItem } from '../../components/layout/Grid';
import Table, { TableRow } from '../../components/table/Table';
import Cell from '../../components/table/components/Cell';
import usePagination from '../../hooks/usePagination';
import SearchControl from '../../components/table/components/SearchControl';
import SplitDownload from './components/SplitDownload';

/**@jsx jsx*/

const columns: ITableColumn[] = [
  {
    title: 'Batch ID',
    dataIndex: 'batch_id',
    key: 'batch_id',
  },
  {
    title: 'Customer Code',
    dataIndex: 'customer_code',
    key: 'customer_code',
  },
  {
    title: 'Value Date',
    dataIndex: 'value_date',
    key: 'value_date',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount: number) => {
      return formatMoney(amount);
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      return <Tag color="cyan">{status}</Tag>;
    },
  },
];

export interface IPayment {
  id: number;
  name: string;
  createdAt: Date;
  lastChanged: Date;
}

interface Payment {
  id: number;
  mf_code: string;
  payment_date: string;
  value_date: string;
  amount: number;
  status: string;
  payment_mode: string;
}

const searchFilter = ['value_date', 'customer_code'];

const transformPaymentData = (data: any[] = []) => {
  const newData = data.map((entry) => ({
    id: entry.id,
    mf_code: entry.member?.mf_code ?? '',
    customer_code: entry.member?.psl_code ?? '',
    payment_date: entry.payment_date,
    value_date: entry.value_date,
    amount: entry.amount,
    status: entry.status?.name ?? '',
    payment_mode: entry.payment_mode?.name ?? '',
    batch_id: entry.batch_id ?? '',
  }));
  return newData;
};

const getPaymentSplitData = (data: any[] = []) => {
  const newData = data.map((entry) => ({
    paymentId: entry.id,
    mf_code: entry.member?.mf_code ?? '',
    customer_code: entry.member?.psl_code ?? '',
    customer: `${entry.member.user?.first_name ?? ''} ${entry.member.user?.last_name ?? ''} ${entry.member.user?.last_name ?? ''}`,
    payment_date: entry.payment_date,
    value_date: entry.value_date,
    amount: entry.amount,
    balanced_income_fund: entry?.payment_shares
      ?.filter((a: any) => a.product.name === 'Plus Balanced Fund')
      .reduce((acc: any, cur: any) => {
        return acc + cur.product_amount;
      }, 0),
    plus_income_fund: entry?.payment_shares
      ?.filter((a: any) => a.product.name === 'Plus Income Fund')
      .reduce((acc: any, cur: any) => {
        return acc + cur.product_amount;
      }, 0),
  }));
  return newData;
};

const ApproveBatchedPayments: React.FC = () => {
  const { valueDate, statusId } = useParams<{
    valueDate: string;
    statusId: string;
  }>();
  const [isOpen, setIsOpen] = React.useState(false);
  // const payments = React.useRef<Payment[]>([]);
  let [payments, setPayments] = React.useState<Payment[]>([]);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [selectedPayment, setSelectedPayment] = React.useState<
    number | undefined
  >();
  const { data, loading } = useGetPaymentsByValueDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      valueDate: valueDate,
      statusId,
    },
  });

  // React.useEffect(() => {
  //   if (data) {
  //     const paymentData = transformPaymentData(data?.payment ?? []);
  //     payments.current = paymentData;
  //   }
  // }, [data]);

  React.useEffect(() => {
    if (data) {
      if (searchKeyword) {
        const dataToFilter = transformPaymentData(data?.payment ?? []);
        let filteredData: { [key: string]: any }[] = dataToFilter;

        if (typeof searchFilter === 'string') {
          filteredData = filteredData.filter((item) => {
            if (!item[searchFilter]) {
              return '';
            }
            return item[searchFilter]
              .toLowerCase()
              .includes(searchKeyword.toLowerCase());
          });
        }

        if (Array.isArray(searchFilter)) {
          const results = searchFilter.map((filter) => {
            return filteredData.filter((item) => {
              if (!item[filter]) {
                return '';
              }
              return item[filter]
                .toLowerCase()
                .includes(searchKeyword.toLowerCase());
            });
          });

          const tempResults = results.flat();
          const resultsSet = new Set();
          tempResults.forEach((row) => {
            resultsSet.add(JSON.stringify(row));
          });
          filteredData = Array.from(resultsSet).map((row) => {
            return JSON.parse(row as string);
          });
        }

        // payments.current = filteredData as Payment[];
        setPayments(filteredData as Payment[])
      } else {
        // payments.current = transformPaymentData(data?.payment ?? []);
        setPayments(transformPaymentData(data?.payment ?? []));
      }
    }
    
  }, [data, searchKeyword]);

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedPayment(undefined);
  }, []);

  const view = (item: Payment) => {
    setIsOpen(true);
    setSelectedPayment(item.id);
  };

  const getPaymentShares = () => {
    const paymentEntry = data!.payment!.find(
      (payment) => payment.id === selectedPayment
    );

    const goalProducts = paymentEntry!.payment_shares.map((share: any) => {
      const transformed = {
        goal: share!.member_goal!.goal!.name,
        shares: [
          {
            product: share.product.name,
            amount: share.product_amount,
          },
        ],
      };

      return transformed;
    });

    return {
      paymentId: paymentEntry!.id,
      totalAmount: paymentEntry!.amount,
      paymentDate: paymentEntry!.payment_date,
      paymentShares: goalProducts,
    };
  };

  function handleSearch(e: any) {
    setSearchKeyword(e.target.value);
  }

  // const { paginatedData, paginationConfig } = usePagination(payments.current);
  const { paginatedData, paginationConfig } = usePagination(payments);

  const headings =
    columns &&
    columns.map((column) => {
      return column.title;
    });

  const products =
    data?.payment.reduce((acc: { [key: string]: number }, curr) => {
      const payment_shares = curr?.payment_shares ?? [];

      for (const entry of payment_shares) {
        if (entry.product?.name) {
          if (acc[entry.product.name] === undefined) {
            acc[entry.product.name] = entry.product_amount;
          } else {
            acc[entry.product.name] += entry.product_amount;
          }
        }
      }
      return acc;
    }, {}) ?? {};
  

  return (
    <React.Fragment>
      {isOpen && (
        <PaymentApprovalModal onClose={close} payment={getPaymentShares()} />
      )}
      <SectionTitle title="Payments" />

      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <span></span>
        <SplitDownload
          valueDate={valueDate}
          splitData={getPaymentSplitData(data?.payment ?? [])}
        />
      </div>
      <section css={{ marginBottom: '40px', marginTop: '30px' }}>
        <Grid>
          <GridItem span={5}>
            <table css={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead
                css={{
                  borderBottom: `1px solid ${theme.colors.gray[200]}`,
                  borderRadius: '4px',
                  background: theme.colors.gray[50],
                }}>
                <tr>
                  <Cell>Summary</Cell>
                  {Object.keys(products).map((product) => (
                    <Cell key={product}>{capitalize(product)}</Cell>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <Cell>Amount</Cell>
                  {Object.entries(products).map(([key, sum]) => (
                    <Cell key={key}>{formatMoney(sum)}</Cell>
                  ))}
                </tr>
              </tbody>
            </table>
          </GridItem>
          <GridItem span={7}>
            {/* <Flex jc="flex-end">
              <Button action={() => setOpenConfirmDialog(true)}>Approve</Button>
            </Flex> */}
          </GridItem>
        </Grid>
      </section>
      <Stack>
        <Stack isInline spacing={8} jc="space-between">
          <SearchControl keyword={searchKeyword} onSearch={handleSearch} />
        </Stack>
        <Table
          isLoading={loading}
          searchKeyword={searchKeyword}
          headings={[...(headings as []), 'Actions']}
          showControls={true}
          isEmpty={paginatedData.length === 0}
          paginationData={paginationConfig}
          showPagination={payments.length > 0}>
          {/* showPagination={payments.current.length > 0}> */}
          {paginatedData.map((item: any, index: number) => {
            return (
              <TableRow key={index}>
                {columns?.map((column, index) => {
                  if (column.render) {
                    return (
                      <Cell key={index}>
                        {column.render(item[column.dataIndex])}
                      </Cell>
                    );
                  }
                  return <Cell key={index}>{item[column.dataIndex]}</Cell>;
                })}
                <Cell>
                  <Button
                    action={(e: any) => {
                      e.stopPropagation();
                      view(item);
                    }}>
                    View
                  </Button>
                </Cell>
              </TableRow>
            );
          })}
        </Table>
      </Stack>
    </React.Fragment>
  );
};

export default ApproveBatchedPayments;
