import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import { overflow } from '../../utils/types'
/**@jsx jsx*/

interface IProps {
  className?: string;
  overflow?: overflow;
}

const Card: React.FC<IProps> = ({ children, className, overflow}) => {
  return (
    <div
      className={className}
      css={{
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: '6px',
        overflow: overflow ?? 'hidden',
      }}>
      {children}
    </div>
  );
};

export default Card;
