import React from 'react';
import { jsx } from '@emotion/core';
import Input from '../../input/Input';
import Icon from '../../icon/Icon';
import theme from '../../../theme/theme';
/**@jsx jsx*/

interface IProps {
  className?: string;
  onSearch?: (args: any) => void;
  keyword?: string;
}

const SearchControl: React.FC<IProps> = ({
  className,
  keyword,
  onSearch = () => {},
}) => {
  return (
    <div
      className={className}
      css={{ position: 'relative', minWidth: '300px' }}>
      <Input
        value={keyword}
        onChange={onSearch}
        placeholder=""
        css={{ input: { paddingRight: '40px' } }}
      />
      <Icon
        size="sm"
        color={theme.colors.gray[300]}
        style={{ position: 'absolute', right: '16px', top: '12px' }}
        icon={['fas', 'magnifying-glass']}></Icon>
    </div>
  );
};

export default SearchControl;
