import React from 'react';
import Flex from '../components/layout/Flex';
import { jsx } from '@emotion/core';
import theme from '../theme/theme';
import Icon from '../components/icon/Icon';
import logo from '../assets/img/logo-blue.png';
/**@jsx jsx*/

class Error extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Flex stack css={{ width: '100vw', height: '100vh' }}>
          <Flex ai="center" css={{ height: '80px', padding: '0 48px' }}>
            <img src={logo} width="80px" alt="" />
          </Flex>
          <Flex
            css={{ width: '100vw', height: 'calc(100vh - 80px)' }}
            jc="center"
            ai="center">
            <div css={{ width: 'auto' }}>
              <div>
                <div css={{ marginBottom: '16px' }}>
                  <Icon
                    color={theme.colors.red[500]}
                    size="2x"
                    icon={['fas', 'rectangle-xmark']}></Icon>
                </div>
                <h3
                  css={{
                    fontFamily: theme.typography.fonts.semibold,
                    color: theme.colors.primary,
                    marginBottom: '8px',
                  }}>
                  Something has gone wrong
                </h3>
                <p
                  css={{
                    fontSize: '14px',
                    color: theme.colors.gray[500],
                    marginBottom: '12px',
                  }}>
                  We're sorry and we are working our best to fix this. <br /> In
                  the meantime,have you tried the following?
                </p>
                <ul
                  css={{
                    paddingLeft: '36px',
                    li: {
                      listStyle: 'outside',
                      fontSize: '14px',
                      color: theme.colors.gray[500],
                      marginBottom: '12px',
                    },
                  }}>
                  <li>Refreshing this page</li>
                  <li>Clearing your browser cache</li>
                </ul>
              </div>
            </div>
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default Error;
