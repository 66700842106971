import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import {
  useProductUnitPriceQuery,
  useDelete_Product_Unit_PriceMutation,
} from '../../generated/graphql';
import { IProductUnitPrice } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import ProductUnitPricesTable from './components/ProductUnitPricesTable';
import ProductUnitPriceModal from './components/ProductUnitPriceModal';
import Toastify from '../../components/toastify/Toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const ProductUnitPrices: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedUnitPrice, setSelectedUnitPrice] = React.useState<
    number | undefined
  >();
  const { data: productUnitPrices, loading } = useProductUnitPriceQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUnitPrice] = useDelete_Product_Unit_PriceMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedUnitPrice(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (price: IProductUnitPrice) => {
    setIsOpen(true);
    setSelectedUnitPrice(price.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deleteUnitPrice({
      variables: {
        id: selectedUnitPrice,
      },
      update: UpdateHandlers.deleteProductUnitPrice,
    });
    setShowConfirm(false);

    if (response.data?.delete_price?.affected_rows) {
      notify(true, 'Unit price deleted successfully.');
    } else {
      notify(false, 'failed to delete unit price.');
    }
  };

  const getUnitPrice = (id?: number) => {
    if (id) {
      const price = productUnitPrices?.price?.find((entry) => entry.id === id);
      if (price) {
        return {
          id: price.id,
          unitPrice: price.unit_price,
          priceDate: price.price_date,
          productId: price.product.id,
        };
      }
    }
    return;
  };

  const confirm = (unitPrice: IProductUnitPrice) => {
    setSelectedUnitPrice(unitPrice.id);
    setShowConfirm(true);
  };

  const getProductUnitPrices = (): IProductUnitPrice[] => {
    return (
      productUnitPrices?.price?.map((entry) => ({
        id: entry.id,
        productName: entry.product.name,
        unitPrice: entry.unit_price,
        priceDate: entry.price_date,
        createdAt: entry.created_at,
        lastChanged: entry.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <ProductUnitPriceModal
          onClose={close}
          price={getUnitPrice(selectedUnitPrice)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Product Unit Price"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Product Unit Price"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <ProductUnitPricesTable
        isLoading={loading}
        productUnitPrices={getProductUnitPrices()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default ProductUnitPrices;
