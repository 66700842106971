import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../../../components/modal/Modal';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import { useApprovePaymentsMutation } from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import Stack from '../../../components/stack/Stack';
import { formatMoney } from '../../../utils/helpers';
import Icon from '../../../components/icon/Icon';
import Tag from '../../../components/tag/Tag';
import { HeadingStrip } from '../../../components/text/Text';
import Separator from '../../../components/separator/Separator';
import { toast } from 'react-toastify';
import Toastify from '../../../components/toastify/Toastify';

/**@jsx jsx*/

interface ValueProps {
  name: string;
  channel: string;
}

interface ProductSplit {
  product: any;
  amount: any;
}

interface Share {
  goal: string;
  shares: ProductSplit[];
}

type IPProviderModal = IModal & {
  payment: {
    paymentId: any;
    totalAmount: any;
    paymentDate: any;
    paymentShares: Share[];
  };
};

const PaymentApprovalModal: React.FC<IPProviderModal> = ({
  onClose,
  payment,
}) => {
  const [
    approvePayments,
    {
      data: approvePaymentsResult,
      loading: approvePaymentsLoading,
      error: approvePaymentsError,
    },
  ] = useApprovePaymentsMutation();

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const loading = !!approvePaymentsLoading;
  const error = !!(
    approvePaymentsError || approvePaymentsResult?.approvePayments.errors
  );
  const success = !!approvePaymentsResult?.approvePayments.success;

  const approvePaymentshandler = async () => {
    const response = await approvePayments({
      variables: {
        ids: [payment.paymentId],
      },
      refetchQueries: ['PaymentShares'],
      awaitRefetchQueries: true,
    });
    if (response?.data?.approvePayments?.success) {
      notify(true, 'payment approved successfully');
      onClose?.();
    } else {
      notify(false, 'payment approval failed');
    }
  };

  return (
    <Modal
      onClose={onClose}
      cancel={{ label: 'Cancel', function: onClose }}
      width="700px"
      confirm={{
        type: 'submit',
        label: 'Approve',
        loading: loading,
        icon: {
          name: 'check',
          color: theme.colors.white,
          position: 'after',
        },
        function: approvePaymentshandler,
      }}
      title={`Review Payment`}>
      <React.Fragment>
        {error && (
          <Flex jc="center" css={{ color: theme.colors.red[500] }}>
            Operation failed...please try again
          </Flex>
        )}
        {success && (
          <Flex
            jc="center"
            css={{ color: theme.colors.green[500] }}
            className="add-success">
            {'Payment approved successfully...'}
          </Flex>
        )}
        <Stack spacing={48}>
          <Flex jc="space-between" ai="flex-start">
            <div>
              <div css={{ marginBottom: '8px' }}>
                <h2
                  css={{
                    fontFamily: theme.typography.fonts.semibold,
                    fontSize: '20px',
                  }}>
                  {formatMoney(payment?.totalAmount)}
                </h2>
              </div>
              <Flex ai="center">
                <Flex
                  css={{
                    marginRight: '24px',
                    p: { color: theme.colors.gray[400], fontSize: '14px' },
                  }}
                  ai="center">
                  <Icon
                    color={theme.colors.gray[300]}
                    icon={['fas', 'hashtag']}
                    style={{ marginRight: '8px' }}></Icon>
                  <p>Payment ID: {payment.paymentId}</p>
                </Flex>
                <Flex
                  css={{
                    p: { color: theme.colors.gray[400], fontSize: '14px' },
                  }}
                  ai="center">
                  <Icon
                    size="sm"
                    color={theme.colors.gray[300]}
                    icon={['fas', 'calendar']}
                    style={{ marginRight: '8px' }}></Icon>
                  <p>{payment.paymentDate}</p>
                </Flex>
              </Flex>
            </div>
            <div>
              <Tag color="cyan">Active</Tag>
            </div>
          </Flex>

          <div>
            {payment.paymentShares.map((share: Share, index) => {
              return (
                <div key={index}>
                  <HeadingStrip gap={0}>{share.goal}</HeadingStrip>
                  <div>
                    {share.shares.map((split: ProductSplit, index) => {
                      return (
                        <React.Fragment key={index}>
                          {
                            <Flex
                              css={{
                                padding: '16px',
                              }}
                              jc="space-between">
                              <p css={{ color: theme.colors.gray[500] }}>
                                {split.product}
                              </p>

                              <p css={{ color: theme.colors.gray[800] }}>
                                {formatMoney(split.amount)}
                              </p>
                            </Flex>
                          }
                          <Separator gap={4} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Stack>
      </React.Fragment>
    </Modal>
  );
};

export default PaymentApprovalModal;
