import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_Insurance_TypeMutation,
  useAdd_Insurance_TypeMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type InsuranceTypeModal = IModal & {
  insuranceType?: { id: number; name: string };
};

const InsuranceTypeSchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const InsuranceTypeModal: React.FC<InsuranceTypeModal> = ({
  onClose,
  insuranceType,
}) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addInsuranceType,
    {
      data: addInsuranceTypeResult,
      loading: addInsuranceTypeLoading,
      error: addInsuranceTypeError,
    },
  ] = useAdd_Insurance_TypeMutation();

  const [
    updateInsuranceType,
    {
      data: updateInsuranceTypeResult,
      loading: updateInsuranceTypeLoading,
      error: updateInsuranceTypeError,
    },
  ] = useUpdate_Insurance_TypeMutation();

  React.useEffect(() => {
    if (insuranceType) {
      setInitialValues({
        name: insuranceType.name,
      });
    }
  }, [insuranceType]);

  const addNewInsuranceType = (values: ValueProps) => {
    addInsuranceType({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addInsuranceType,
    }).catch(() => {});
  };

  const upateExistingInsuranceType = (values: ValueProps) => {
    updateInsuranceType({
      variables: {
        id: insuranceType?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    }).catch(() => {});
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (insuranceType) {
      upateExistingInsuranceType(values);
    } else {
      addNewInsuranceType(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addInsuranceTypeLoading || updateInsuranceTypeLoading);
  const error = !!(addInsuranceTypeError || updateInsuranceTypeError);
  const success = !!(
    addInsuranceTypeResult?.insert_insurance_type?.returning?.length ||
    updateInsuranceTypeResult?.update_insurance_type?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={InsuranceTypeSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${insuranceType ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${insuranceType ? 'Update' : 'Add'} Insurance Type`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Insurance Type ${
                    insuranceType ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Insurance Type"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default InsuranceTypeModal;
