import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Field, Form, FormikHelpers, FieldProps } from 'formik';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useProductQuery,
  Product,
  useAssetDurationQuery,
  Asset_Duration,
  useAdd_Asset_AllocationMutation,
  useUpdate_Asset_AllocationMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import { AssetAllocationSchema } from '../../../utils/yup-schema';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import NativeSelect from '../../../components/select/NativeSelect';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  product: string;
  duration: string;
  weight: string;
}

type IDurationModal = IModal & {
  allocation?: {
    id: number;
    product: string;
    duration: string;
    weight: string;
  };
};

const AllocationModal: React.FC<IDurationModal> = ({ onClose, allocation }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    product: '',
    duration: '',
    weight: '',
  });

  const { data: products } = useProductQuery();
  const { data: durations } = useAssetDurationQuery();

  const [
    addAssetAllocation,
    {
      data: addAssetAllocationResult,
      loading: addAssetAllocationLoading,
      error: addAssetAllocationError,
    },
  ] = useAdd_Asset_AllocationMutation();
  const [
    updateAssetAllocation,
    {
      data: updateAssetAllocationResult,
      loading: updateAssetAllocationLoading,
      error: updateAssetAllocationError,
    },
  ] = useUpdate_Asset_AllocationMutation();

  React.useEffect(() => {
    if (allocation) {
      setInitialValues({
        product: allocation.product,
        duration: allocation.duration,
        weight: allocation.weight,
      });
    }
  }, [allocation]);

  const addAllocation = (values: ValueProps) => {
    addAssetAllocation({
      variables: {
        changes: [
          {
            product_id: values.product,
            asset_duration_id: values.duration,
            weight: Number(values.weight) / 100,
            created_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addAssetAllocation,
    });
  };

  const updateAllocation = (values: ValueProps) => {
    updateAssetAllocation({
      variables: {
        id: allocation?.id,
        changes: {
          product_id: values.product,
          asset_duration_id: values.duration,
          weight: Number(values.weight) / 100,
          modified_by: userId,
        },
      },
      update: UpdateHandlers.updateAssetAllocation,
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (allocation) {
      updateAllocation(values);
    } else {
      addAllocation(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addAssetAllocationLoading || updateAssetAllocationLoading);
  const error = !!(addAssetAllocationError || updateAssetAllocationError);
  const success = !!(
    addAssetAllocationResult?.insert_asset_allocation?.returning?.length ||
    updateAssetAllocationResult?.update_asset_allocation?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={AssetAllocationSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${allocation ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${allocation ? 'Update' : 'Add'} Asset Allocation`}>
            <React.Fragment>
              {error && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Asset Allocation ${
                    allocation ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <div>
                    <NativeSelect
                      label="product"
                      name="product"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.product}>
                      <option value="">Select Product</option>
                      {products?.product?.map((product: Partial<Product>) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <ErrorMessage name="product" />
                  </div>

                  <div>
                    <NativeSelect
                      label="duration"
                      name="duration"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.duration}>
                      <option value="">select Duration</option>
                      {durations?.asset_duration?.map(
                        (duration: Partial<Asset_Duration>) => (
                          <option key={duration.id} value={duration.id}>
                            {`${duration.duration_start} - ${duration.duration_end}`}
                          </option>
                        )
                      )}
                    </NativeSelect>
                    <ErrorMessage name="duration" />
                  </div>

                  <Field name="weight">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          type="text"
                          name="weight"
                          placeholder="Enter weight"
                          label="Weight"
                          onChange={formikProps.handleChange}
                          {...field}
                        />
                        <ErrorMessage name="weight" />
                      </div>
                    )}
                  </Field>
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AllocationModal;
