import { getDaysInMonth, getDay } from 'date-fns';

export function getDaysInPreviousMonth(month: number, year: number): Date[] {
  const firstDayOfMonth = getDay(new Date(year, month, 1)) + 1;
  const numberOfDaysInPreviousMonth = getDaysInMonth(new Date(year, month - 1));
  const offset = firstDayOfMonth - 1;
  const bleed = numberOfDaysInPreviousMonth - offset;
  const dates = Array.from(Array(offset).keys()).map((n) => {
    return new Date(year, month - 1, bleed + n + 1);
  });
  return dates;
}

export function getDaysInNextMonth(month: number, year: number) {
  const totalDays = 7 * 6;
  const previousMonthDays = getDaysInPreviousMonth(month, year).length;
  const bleed =
    totalDays - (previousMonthDays + getDaysInMonth(new Date(year, month)));
  const dates = Array.from(Array(bleed).keys()).map((n) => {
    return new Date(year, month + 1, n + 1);
  });
  return dates;
}

export function getDates(month: number, year: number): Date[] {
  const days = getDaysInMonth(new Date(year, month));
  const daysToArray = Array.from(Array(days).keys());
  const dates = daysToArray.map((day) => {
    return new Date(year, month, day + 1);
  });

  const previousMonthDates = getDaysInPreviousMonth(month, year);
  const nextMonthDates = getDaysInNextMonth(month, year);

  return [...previousMonthDates, ...dates, ...nextMonthDates];
}

export const daysOfTheWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export function isSameMonth(date: Date, month: number): boolean {
  return date.getMonth() === month;
}
