import React from 'react';
import { toast } from 'react-toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import AdminTable from './components/AdminTable';
import AdminModal from './components/AdminModal';
import {
  useAdminsQuery,
  useDelete_UserMutation,
} from '../../generated/graphql';
import { IUser } from '../../utils/types';
import moment from 'moment';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';

const Admins: React.FC = () => {
  const { userId } = useAuthState();
  const { data: admins, loading } = useAdminsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUser] = useDelete_UserMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedAdmin, setSelectedAdmin] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedAdmin(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (admin: IUser) => {
    setIsOpen(true);
    setSelectedAdmin(admin.id);
  };

  const deactivate = (admin: IUser) => {
    setSelectedAdmin(admin.id);
    setShowConfirm(true);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const deactivateUser = async () => {
    const response = await deleteUser({
      variables: {
        id: selectedAdmin,
        doneBy: userId,
      },
      refetchQueries: ['Admins'],
      awaitRefetchQueries: true,
    });
    setShowConfirm(false);

    if (response.data?.update_user?.affected_rows) {
      notify(true, 'Admin deleted successfully.');
    } else {
      notify(false, 'failed to delete admin.');
    }
  };

  const getAdmin = (id?: number) => {
    if (id) {
      const admin = admins?.user?.find((user) => user.id === id);
      if (admin) {
        return {
          id: admin.id,
          firstName: admin.first_name as string,
          lastName: admin.last_name as string,
          otherName: admin.other_name ?? '',
          gender: admin.gender_id,
          dateOfBirth: moment(admin.date_of_birth).toDate(),
          mobile: admin.mobile as string,
          email: admin.email as string,
          username: admin.email as string,
          password: 'advisor',
        };
      }
    }
    return;
  };

  const getAdmins = (): IUser[] => {
    return (
      admins?.user?.map((admin) => ({
        id: admin.id,
        fullName: `${admin.first_name ?? ''} ${admin.other_name ?? ''} ${
          admin.last_name ?? ''
        }`,
        gender: admin.gender.name as string,
        gender_id: admin.gender_id,
        dateOfBirth: admin.date_of_birth,
        mobile: admin.mobile as string,
        email: admin.email as string,
        createdAt: admin.created_at,
        lastChanged: admin.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && <AdminModal onClose={close} admin={getAdmin(selectedAdmin)} />}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: deactivateUser }}
          onClose={close}
          title="Delete Admin"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Admins"
        action={{
          label: 'Add new Admin',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <AdminTable
        isLoading={loading}
        admins={getAdmins()}
        actions={[
          { label: 'Deactivate', action: deactivate },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Admins;
