import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import Flex from '../../components/layout/Flex';
import { SubHeading } from '../../components/text/Text';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import Stack from '../../components/stack/Stack';
import Grid, { GridItem } from '../../components/layout/Grid';
import PasswordResetModal from './components/PasswordResetModal';
/**@jsx jsx*/

const Settings: React.FC = () => {
  const [settingsModalIsShowing, setSettingsModalIsShowing] = React.useState(
    false
  );
  return (
    <React.Fragment>
      {settingsModalIsShowing && (
        <PasswordResetModal
          onClose={() => {
            setSettingsModalIsShowing(false);
          }}
        />
      )}
      <SectionTitle title="Settings"></SectionTitle>
      <Grid>
        <GridItem span={4}>
          <Flex css={{ marginBottom: '8px' }}>
            <SubHeading>Password</SubHeading>
          </Flex>
          <Stack spacing={8}>
            <Input
              value="kajsdhaksjd"
              disabled={true}
              label="Password"
              type="password"></Input>
            <div>
              <Button
                action={() => {
                  setSettingsModalIsShowing(true);
                }}
                appearance="primary"
                css={{ width: '50%' }}>
                Reset password
              </Button>
            </div>
          </Stack>
        </GridItem>
      </Grid>
    </React.Fragment>
  );
};

export default Settings;
