import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import SearchTable from './components/SearchTable';
import { useMemberSearchQuery } from '../../generated/graphql';
import { useLocation } from 'react-router-dom';
/**@jsx jsx*/

const DashboardSearch: React.FC = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const searchKey = query.get('name') as string;
  const { data: members, loading } = useMemberSearchQuery({
    variables: {
      searchKey: `%${searchKey}%`,
    },
    fetchPolicy: 'cache-and-network',
  });

  const getMembers = () => {
    return members?.member?.map((member) => ({
      id: member.id as string,
      fullName: `${member.user?.first_name ?? ''} ${
        member.user?.last_name ?? ''
      }`,
      pslCode: member.psl_code ?? '',
      gender: member.user?.gender?.name ?? '',
      assignedTo: `${member.assigned_to_user?.first_name ?? ''} ${
        member.assigned_to_user?.last_name ?? ''
      }`,
      status: member.status?.name ?? '',
      isCustomer: member.is_customer,
    }));
  };

  return (
    <React.Fragment>
      <SectionTitle title={`Search results for ${searchKey ?? ''}`} />
      <SearchTable isLoading={loading} members={getMembers()} />
    </React.Fragment>
  );
};
export default DashboardSearch;
