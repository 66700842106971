import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';

/**@jsx jsx */

interface IProps {
  gap?: number;
  className?: string;
}

const Separator: React.FC<IProps> = ({ gap = 16, className }) => {
  return (
    <div
      className={className}
      css={{
        height: '1px',
        background: theme.colors.gray[100],
        margin: `${gap}px 0`,
      }}></div>
  );
};

export default Separator;
