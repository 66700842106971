import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import { IModal } from '../../utils/types';
import theme from '../../theme/theme';
import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';
import Button from '../button/Button';
/**@jsx jsx*/

const Modal: React.FC<IModal> = ({
  title,
  icon,
  onClose,
  width = '30%',
  children,
  confirm,
  cancel,
  className,
  top = 0,
  zIndex = 99999999,
}) => {
  return (
    <Flex
      ai="center"
      jc="center"
      css={{
        background: 'rgba(0,12,31,0.5)',
        height: '100vh',
        width: '100vw',
        overflowY: 'scroll',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex,
      }}>
      <div
        className={className}
        css={{
          width: width,
          background: theme.colors.white,
          marginTop: top + 'px',
          borderRadius: '6px',
          maxHeight: '90%',
          overflowY: 'scroll',
          animation: `${theme.animations.scaleUp} .5s`,
        }}>
        <Flex css={{ padding: '12px 20px 24px 20px' }} jc="space-between">
          {title ? (
            <Flex ai="center">
              {icon && (
                <Avatar
                  css={{ marginRight: '16px' }}
                  background={
                    theme.colors[icon.color as string]
                      ? theme.colors[icon.color as string][100]
                      : theme.colors.blue[100]
                  }
                  size="sm">
                  <Icon
                    color={
                      theme.colors[icon.color as string]
                        ? theme.colors[icon.color as string][600]
                        : theme.colors.blue[600]
                    }
                    icon={['fas', icon.name]}></Icon>
                </Avatar>
              )}
              <h3 css={{ fontSize: '14px' }}>{title}</h3>
            </Flex>
          ) : (
            <div></div>
          )}

          <button
            css={{
              border: 'none',
              width: '24px',
              height: '24px',
              padding: 0,
              fontSize: '16px',
              background: 'transparent',
            }}
            onClick={onClose}>
            <Icon color={theme.colors.gray[400]} icon={['fas', 'xmark']}></Icon>
          </button>
        </Flex>

        <div css={{ padding: '0 20px', marginBottom: '24px' }}>{children}</div>
        {(confirm || cancel) && (
          <Flex
            jc="flex-end"
            css={{ padding: '12px 20px', background: theme.colors.gray[50] }}>
            {cancel && (
              <Button
                action={cancel.function}
                css={{ marginRight: '12px' }}
                icon={cancel.icon}
                appearance="outline">
                {cancel.label}
              </Button>
            )}
            {confirm && (
              <Button
                appearance="primary"
                className={confirm.className}
                type={confirm.type}
                action={confirm.function}
                icon={confirm.icon}
                isLoading={confirm.loading}
                isDisabled={confirm.disabled}>
                {confirm.label}
              </Button>
            )}
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default Modal;
