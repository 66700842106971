import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_Marital_StatusMutation,
  useAdd_Marital_StatusMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type MaritalStatusModal = IModal & {
  maritalStatus?: { id: number; name: string };
};

const MaritalStatusSchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const MaritalStatusModal: React.FC<MaritalStatusModal> = ({
  onClose,
  maritalStatus,
}) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addMaritalStatus,
    {
      data: addMaritalStatusResult,
      loading: addMaritalStatusLoading,
      error: addMaritalStatusError,
    },
  ] = useAdd_Marital_StatusMutation();

  const [
    updateMaritalStatus,
    {
      data: updateMaritalStatusResult,
      loading: updateMaritalStatusLoading,
      error: updateMaritalStatusError,
    },
  ] = useUpdate_Marital_StatusMutation();

  React.useEffect(() => {
    if (maritalStatus) {
      setInitialValues({
        name: maritalStatus.name,
      });
    }
  }, [maritalStatus]);

  const addNewMaritalStatus = (values: ValueProps) => {
    addMaritalStatus({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addMaritalStatus,
    }).catch(() => {});
  };

  const upateExistingMaritalStatus = (values: ValueProps) => {
    updateMaritalStatus({
      variables: {
        id: maritalStatus?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    }).catch(() => {});
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (maritalStatus) {
      upateExistingMaritalStatus(values);
    } else {
      addNewMaritalStatus(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addMaritalStatusLoading || updateMaritalStatusLoading);
  const error = !!(addMaritalStatusError || updateMaritalStatusError);
  const success = !!(
    addMaritalStatusResult?.insert_marital_status?.returning?.length ||
    updateMaritalStatusResult?.update_marital_status?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={MaritalStatusSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${maritalStatus ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${maritalStatus ? 'Update' : 'Add'} Marital Status`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Marital Status ${
                    maritalStatus ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Marital Status"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default MaritalStatusModal;
