import React, { createContext, useReducer, useContext } from 'react';

type AccountType = 'individual' | 'trust' | 'joint';

type Action =
  | { type: 'SET_ACCOUNT_TYPE'; accountType: AccountType };

type Dispatch = (action: Action) => void;

type State = {
  accountType?: AccountType;
};

const CustomerStateContext = createContext<State | undefined>(undefined);
const CustomerDispatchContext = createContext<Dispatch | undefined>(undefined);

function CustomerReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_ACCOUNT_TYPE': {

    // console.log("accountType: " + action.accountType?.toString());

      return {
        ...state,
        accountType: action.accountType,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function CustomerProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(CustomerReducer, {
    accountType: "individual",
  });

  return (
    <CustomerStateContext.Provider value={state}>
      <CustomerDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerStateContext.Provider>
  );
}

function useCustomerState() {
  const context = useContext(CustomerStateContext);
  if (context === undefined) {
    throw new Error('useCustomerState must be used within an CustomerProvider');
  }
  return context;
}

function useCustomerDispatch() {
  const context = useContext(CustomerDispatchContext);
  if (context === undefined) {
    throw new Error('useCustomerDispatch must be used within an CustomerProvider');
  }
  return context;
}

export { CustomerProvider, useCustomerState, useCustomerDispatch };
