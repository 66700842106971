import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../../theme/theme';
/**@jsx jsx*/

export const InputLabel: React.FC<{
  id?: string;
  className?: string;
  gap?: number;
}> = ({ id, className, children, gap = 4 }) => {
  return (
    <label
      className={className}
      id={id}
      css={{
        fontSize: '12px',
        fontFamily: theme.typography.fonts.semibold,
        display: 'block',
        marginBottom: gap + 'px',
        color: theme.colors.gray[500],
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      }}
      htmlFor={id}>
      {children}
    </label>
  );
};

export default InputLabel;
