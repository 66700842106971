import React from 'react';
import { jsx } from '@emotion/core';
import Cell from './components/Cell';
import Card from '../card/Card';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import Stack from '../stack/Stack';
import Pagination from './components/Pagination';
import { ITable } from '../../utils/types';
import Checkbox from '../checkbox/Checkbox';
import Loader from '../loader/Loader';
/**@jsx jsx*/

export const TableRow: React.FC<{ className?: string }> = ({ children }) => {
  return (
    <tr
      css={{
        '&:nth-of-type(even)': {
          background: theme.colors.gray[50],
        },
        '&:not(:last-of-type)': {
          // borderBottom: `1px solid ${theme.colors.gray[200]}`,
          overflowY: 'visible',
        },
      }}>
      {children}
    </tr>
  );
};

const Table: React.FC<ITable> = ({
  className,
  headings = [],
  children,
  isEmpty,
  showPagination,
  paginationData,
  rowSelection,
  isLoading = false,
}) => {
  const [recordsPerPage, setRecordsPerPage] = React.useState(
    paginationData?.pageLimit || 10
  );

  function handlePageLimitChange(pageLimit: number) {
    setRecordsPerPage(pageLimit);
  }

  return (
    <React.Fragment>
      <Stack>
        <Card>
          <div
            css={{
              maxWidth: '100vw',
              width: '100%',
              overflowX: 'scroll',
              overflowY: 'visible',
            }}>
            <table
              css={{ width: '100%', borderCollapse: 'collapse' }}
              className={className}>
              <thead
                css={{
                  borderBottom: `1px solid ${theme.colors.gray[200]}`,
                  borderRadius: '4px',
                }}>
                <tr>
                  {rowSelection && (
                    <Cell isHeader>
                      <Checkbox id="table-row-header" />
                    </Cell>
                  )}
                  {headings.map((heading, index) => {
                    return (
                      <Cell key={index} isHeader>
                        {heading}
                      </Cell>
                    );
                  })}
                </tr>
              </thead>
              {!isLoading && <tbody>{children}</tbody>}
            </table>
          </div>

          {isLoading && (
            <Flex
              jc="center"
              ai="center"
              css={{ width: '100%', height: '100px' }}>
              <Loader
                size="small"
                color={theme.colors.primary}
                label="Loading Data"
              />
            </Flex>
          )}

          {isEmpty && !isLoading && (
            <Flex
              jc="center"
              ai="center"
              css={{ width: '100%', height: '64px' }}>
              <p css={{ color: theme.colors.gray[400] }}>No Data</p>
            </Flex>
          )}
        </Card>
        {showPagination && (
          <Pagination
            totalRecords={paginationData?.totalRecords}
            pageLimit={recordsPerPage}
            onPageChange={paginationData?.onPageChange}
            onPageLimitChange={handlePageLimitChange}
          />
        )}
      </Stack>
    </React.Fragment>
  );
};

export default Table;
