import React from 'react';
import ReactDOM from 'react-dom';
const portalRoot = document.getElementById('portal-root');

class Portal extends React.PureComponent {
  portalWrapper: any;

  constructor(props: any) {
    super(props);
    this.portalWrapper = document.createElement('div');
  }

  componentDidMount() {
    portalRoot?.appendChild(this.portalWrapper);
  }

  componentWillUnmount() {
    portalRoot?.removeChild(this.portalWrapper);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.portalWrapper);
  }
}

export default Portal;
