import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import Icon from '../icon/Icon';
import { IButtonIcon } from '../../utils/types';
import Loader from '../loader/Loader';
import { Link } from 'react-router-dom';
/**@jsx jsx*/

interface IProps {
  path?: string;
  type?: 'button' | 'reset' | 'submit';
  appearance?: 'primary' | 'ghost' | 'outline' | 'secondary';
  height?: number;
  action?: (args?: any) => void;
  icon?: IButtonIcon;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

const defaultButtonStyles: CSSObject = {
  display: 'flex',
  padding: '0 16px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '3px',
  fontSize: '13px',
  overflow: 'hidden',
  span: { fontFamily: theme.typography.fonts.semibold },
};

const appearanceVariants: { [apperance: string]: CSSObject } = {
  primary: {
    ...defaultButtonStyles,
    background: theme.colors.blue[700],
    color: theme.colors.white,
    border: 'none',
  },
  secondary: {
    ...defaultButtonStyles,
    background: theme.colors.petraOrange,
    color: theme.colors.white,
    border: 'none',
  },
  outline: {
    ...defaultButtonStyles,
    border: `1px solid ${theme.colors.gray[50]}`,
    color: theme.colors.gray[600],
    background: 'white',
    boxShadow:
      'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
  },
  disabled: {
    ...defaultButtonStyles,
    background: theme.colors.gray[200],
    color: theme.colors.gray[400],
  },

  ghost: {},
};

const Button: React.FC<IProps> = ({
  children,
  path,
  type,
  appearance = 'secondary',
  action,
  height = 32,
  icon,
  isDisabled = false,
  isLoading = false,
  className,
}) => {
  if (path) {
    return (
      <Link
        to={path}
        css={{
          ...appearanceVariants[isDisabled ? 'disabled' : appearance],
          height: height + 'px',
          pointerEvents: isDisabled ? 'none' : 'all',
          position: 'relative',
          '&:hover .overlay': { transform: 'scaleX(1)' },
        }}
        className={className}>
        <p
          css={{
            zIndex: 1,
            color:
              appearance === 'outline'
                ? theme.colors.gray[600]
                : theme.colors.white,
          }}>
          {children}
        </p>
        {appearance === 'secondary' && (
          <div
            className="overlay"
            css={{
              height: '40px',
              transform: 'scaleX(0)',
              transformOrigin: 'left',
              width: '100%',
              background: theme.colors.blue[700],
              position: 'absolute',
              zIndex: 0,
              transition: 'all .5s',
            }}></div>
        )}
      </Link>
    );
  }

  return (
    <button
      className={className}
      css={{
        ...appearanceVariants[isDisabled ? 'disabled' : appearance],
        height: height + 'px',
        pointerEvents: isDisabled ? 'none' : 'all',
        position: 'relative',
        '&:hover .overlay': { transform: 'scaleX(1)' },
      }}
      onClick={action}
      type={type}>
      {isLoading ? (
        <Loader
          color={
            appearance === 'outline' ? theme.colors.primary : theme.colors.white
          }
          label="Loading"
          scheme={appearance === 'outline' ? 'light' : 'dark'}
          size="xsmall"
        />
      ) : (
        <Flex jc="center" css={{ width: '100%', zIndex: 1 }}>
          {icon && (
            <Icon
              style={{
                order: icon.position === 'after' ? 2 : 0,
                marginRight: icon.position === 'before' ? '8px' : 0,
                marginLeft: icon.position === 'after' ? '8px' : 0,
              }}
              icon={['fas', icon.name]}
              // icon={icon}
              color={isDisabled ? theme.colors.gray[300] : icon.color}
              size={icon.size}
            />
          )}
          <span>{children}</span>
        </Flex>
      )}
      {appearance === 'secondary' && (
        <div
          className="overlay"
          css={{
            height: '40px',
            transform: 'scaleX(0)',
            transformOrigin: 'left',
            width: '100%',
            background: theme.colors.blue[700],
            position: 'absolute',
            zIndex: 0,
            transition: 'all .5s',
          }}></div>
      )}
    </button>
  );
};

export default Button;
