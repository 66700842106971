import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../../theme/theme';
import Icon from '../../icon/Icon';
import Flex from '../../layout/Flex';
import useDropDown from '../../../hooks/useDropDown';
import { useHistory } from 'react-router-dom';
/**@jsx jsx*/

interface IProps {
  inputValue?: string;
  onInputChange?: (args?: any) => void;
  onInputFocus?: (args?: any) => void;
  onInputBlur?: (args?: any) => void;
}

const HeaderSearch: React.FC<IProps> = ({
  inputValue,
  onInputChange = () => {},
  onInputFocus = () => {},
  onInputBlur = () => {},
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [inputState, setInputState] = React.useState('');
  const { isOpen, elRef, handleVisibilityChange } = useDropDown(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const history = useHistory();

  function handleInputChange(e: any) {
    if (inputValue !== undefined) {
      onInputChange(e);
      return;
    }
    setInputState(e.target.value);
    onInputChange(e);
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (inputState !== '') {
        history.push({
          pathname: '/dashboard/search',
          search: `?name=${inputState}`,
        });
        resetInputState(e);
      }
      return;
    }
  };

  function resetInputState(e: any) {
    handleInputChange({ target: { value: '' } });
    inputRef.current?.focus();
  }

  function handleOnFocus(e: any) {
    setIsFocused(true);
    onInputFocus(e);
    handleVisibilityChange(e);
  }
  function handleOnBlur(e: any) {
    setIsFocused(false);
    onInputBlur(e);
  }

  React.useEffect(() => {
    if (inputValue !== undefined) {
      setInputState(inputValue as string);
    }
  }, [inputValue]);

  return (
    <div ref={elRef} css={{ position: 'relative' }}>
      {isOpen && (
        <div
          css={{
            position: 'absolute',
            width: '100%',
            top: 'calc(100% + 10px)',
            borderRadius: '4px',
            padding: '16px 0',
            background: theme.colors.white,
            border: `1px solid ${theme.colors.gray[200]}`,
            boxShadow: theme.shadows.min,
            animation: `${theme.animations.scaleUp} .5s`,
          }}>
          <Flex
            css={{
              background: theme.colors.gray[100],
              padding: '8px 16px',
            }}
            ai="center">
            <div>
              <Icon
                color={theme.colors.gray[600]}
                icon={['fas', 'magnifying-glass']}
                style={{ fontSize: '14px', marginRight: '8px' }}></Icon>
            </div>
            {inputState ? (
              <p css={{ fontSize: '14px' }}>
                Find{' '}
                <span
                  css={{
                    fontFamily: theme.typography.fonts.bold,
                    wordBreak: 'break-all',
                  }}>{`"${inputState}"`}</span>{' '}
                in all PSL Backoffice
              </p>
            ) : (
              <p css={{ fontSize: '14px' }}>Start typing to search</p>
            )}
          </Flex>
        </div>
      )}
      <Flex
        css={{
          height: '36px',
          width: '360px',
          position: 'relative',
          background: isFocused
            ? theme.colors.white
            : theme.colors.whiteAlpha[300],
          border: 'none',
          borderRadius: '4px',
          transition: 'all .5s',
        }}
        jc="space-between"
        ai="center">
        <input
          value={inputState}
          onChange={handleInputChange}
          placeholder="Search for customers , prospects"
          type="text"
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          name="header-search"
          autoComplete="off"
          css={{
            height: '100%',
            width: 'calc(100%)',
            border: 'none',
            padding: '8px 36px 8px 8px',
            borderRadius: '4px',
            background: 'none',
            color: theme.colors.white,
            fontFamily: theme.typography.fonts.semibold,
            '&::placeholder': {
              color: theme.colors.whiteAlpha[600],
              fontSize: '14px',
            },
            '&:focus': { outline: 'none', color: theme.colors.darkBlue },
          }}
        />
        <Flex
          ai="center"
          jc="center"
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '36px',
            height: '36px',
          }}>
          {inputState ? (
            <Flex
              data-block={true}
              ai="center"
              jc="center"
              onClick={resetInputState}
              css={{ width: '36px', height: '36px' }}>
              <Icon
                style={{ pointerEvents: 'none' }}
                color={theme.colors.gray[400]}
                icon={['fas', 'xmark']}></Icon>
            </Flex>
          ) : (
            <Icon
              color={
                isFocused
                  ? theme.colors.gray[400]
                  : theme.colors.whiteAlpha[500]
              }
              size="sm"
              icon={['fas', 'magnifying-glass']}></Icon>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default HeaderSearch;
