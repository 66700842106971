import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  usePaymentChannelQuery,
  Payment_Channel,
  useAdd_Payment_ProviderMutation,
  useUpdate_Payment_ProviderMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import NativeSelect from '../../../components/select/NativeSelect';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
  code?: string | null;
  channel: string;
}

type IPProviderModal = IModal & {
  provider?: {
    id: number;
    name: string;
    code?: string | null;
    channel: string;
  };
};

const PaymentProviderSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  code: Yup.string(),
  channel: Yup.string().required('required'),
});

const PaymentProviderModal: React.FC<IPProviderModal> = ({
  onClose,
  provider,
}) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
    channel: '',
    code: '',
  });

  const { data: paymentChannels } = usePaymentChannelQuery();

  const [
    addPaymentProvider,
    {
      data: addPaymentProviderResult,
      loading: addProviderLoading,
      error: addProviderError,
    },
  ] = useAdd_Payment_ProviderMutation();
  const [
    updatePaymentProvider,
    {
      data: updatePaymentProviderResult,
      loading: updateProviderLoading,
      error: updateProviderError,
    },
  ] = useUpdate_Payment_ProviderMutation();

  React.useEffect(() => {
    if (provider) {
      setInitialValues({
        name: provider.name,
        channel: provider.channel,
        code: provider.code,
      });
    }
  }, [provider]);

  const addProvider = (values: ValueProps) => {
    addPaymentProvider({
      variables: {
        changes: [
          {
            name: values.name,
            code: values.code,
            payment_channel_id: values.channel,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addPaymentProvider,
    });
  };

  const updateProvider = (values: ValueProps) => {
    updatePaymentProvider({
      variables: {
        id: provider?.id,
        changes: {
          name: values.name,
          code: values.code,
          payment_channel_id: values.channel,
          modified_by: userId,
        },
      },
      update: UpdateHandlers.updatePaymentProvider,
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (provider) {
      updateProvider(values);
    } else {
      addProvider(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addProviderLoading || updateProviderLoading);
  const error = !!(addProviderError || updateProviderError);
  const success = !!(
    addPaymentProviderResult?.insert_payment_provider?.returning?.length ||
    updatePaymentProviderResult?.update_payment_provider?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={PaymentProviderSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${provider ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${provider ? 'Update' : 'Add'} Payment Provider`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Payment provider ${
                    provider ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter provider name"
                    label="Provider Name"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.name}
                  />
                  <ErrorMessage name="name" />
                </div>
                <div>
                  <Input
                    type="text"
                    name="code"
                    placeholder="Enter code"
                    label="Code"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.code}
                  />
                  <ErrorMessage name="code" />
                </div>
                <div>
                  <NativeSelect
                    label="Payment Channel"
                    name="channel"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.channel}>
                    <option value="">select payment channel</option>
                    {paymentChannels?.payment_channel?.map(
                      (channel: Partial<Payment_Channel>) => (
                        <option key={channel.id} value={channel.id}>
                          {channel.name}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <ErrorMessage name="channel" />
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentProviderModal;
