import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useUpdate_GoalMutation,
  useAdd_GoalMutation,
  useProductQuery,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import ComboBox from '../../../components/select/ComboBox';
import Stack from '../../../components/stack/Stack';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
  products: string[];
}

type IGoalModal = IModal & {
  goal?: { id: number; name: string; products: string[] };
};

const GoalSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  products: Yup.array().min(1, 'must have atleast one item'),
});

const GoalModal: React.FC<IGoalModal> = ({ onClose, goal }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
    products: [],
  });

  const { data: products } = useProductQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [
    addGoal,
    { data: addGoalResult, loading: addGoalLoading, error: addGoalError },
  ] = useAdd_GoalMutation();

  const [
    updateGoal,
    {
      data: updateGoalResult,
      loading: updateGoalLoading,
      error: updateGoalError,
    },
  ] = useUpdate_GoalMutation();

  React.useEffect(() => {
    if (goal) {
      setInitialValues({
        name: goal.name,
        products: goal.products,
      });
    }
  }, [goal]);

  const addNewGoal = (values: ValueProps) => {
    addGoal({
      variables: {
        goal_changes: [
          {
            name: values.name,
            goal_products: {
              data: values.products.map((product) => ({
                product_id: product,
              })),
            },
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addGoal,
    }).catch(() => {});
  };

  const upateExistingGoal = (values: ValueProps) => {
    updateGoal({
      variables: {
        id: goal?.id,
        products: values.products.map((product) => ({
          goal_id: goal?.id,
          product_id: product,
        })),
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    }).catch(() => {});
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (goal) {
      upateExistingGoal(values);
    } else {
      addNewGoal(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addGoalLoading || updateGoalLoading);
  const error = addGoalError || updateGoalError;
  const success = !!(
    addGoalResult?.insert_goal?.returning?.length ||
    updateGoalResult?.update_goal?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={GoalSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${goal ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${goal ? 'Update' : 'Add'} Goal`}>
            <React.Fragment>
              {!!error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500], marginBottom: '10px' }}
                  className="add-failed">
                  {error.message}
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Goal ${goal ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    label="Goal Name"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.name}
                  />
                  <ErrorMessage name="name" />
                </div>
                <div>
                  <ComboBox
                    placeholder="Select products"
                    label="Products"
                    name="products"
                    options={
                      products?.product?.map((product) => ({
                        label: product.name,
                        value: product.id,
                      })) || []
                    }
                    value={formikProps.values.products}
                    onChange={formikProps.handleChange}
                  />
                  <ErrorMessage name="products" />
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default GoalModal;
