import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import GoalsTable from './components/GoalsTable';
import GoalModal from './components/GoalModal';
import { useGoalsQuery, useDelete_GoalMutation } from '../../generated/graphql';
import { IGoal } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const Goals: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedGoal, setSelectedGoal] = React.useState<number | undefined>();
  const { data: goals, loading } = useGoalsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteGoal] = useDelete_GoalMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedGoal(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (goal: IGoal) => {
    setIsOpen(true);
    setSelectedGoal(goal.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deleteGoal({
      variables: {
        id: selectedGoal,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteGoal,
    });
    setShowConfirm(false);

    if (response.data?.update_goal?.affected_rows) {
      notify(true, 'Goal deleted successfully.');
    } else {
      notify(false, 'failed to delete goal.');
    }
  };

  const getGoal = (id?: number) => {
    if (id) {
      const goal = goals?.goal?.find((goal) => goal.id === id);
      if (goal) {
        return {
          id: goal.id,
          name: goal.name,
          products: goal.goal_products.map((entry) => entry.product.id),
        };
      }
    }
    return;
  };

  const confirm = (goal: IGoal) => {
    setSelectedGoal(goal.id);
    setShowConfirm(true);
  };

  const getGoals = (): IGoal[] => {
    return (
      goals?.goal?.map((goal) => ({
        id: goal.id,
        name: goal.name,
        createdAt: goal.created_at,
        lastChanged: goal.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && <GoalModal onClose={close} goal={getGoal(selectedGoal)} />}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Goal"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Goals"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <GoalsTable
        isLoading={loading}
        goals={getGoals()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Goals;
