import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import IncomeSourcesTable from './components/IncomeSourcesTable';
import IncomeSourceModal from './components/IncomeSourceModal';
import {
  useIncomeSourceQuery,
  useDelete_Income_SourceMutation,
} from '../../generated/graphql';
import { IncomeSource } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/
import { testRow } from '../../shared/constants';

const IncomeSources: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedIncomeSource, setSelectedIncomeSource] = React.useState<
    number | undefined
  >();
  const { data: incomeSources, loading } = useIncomeSourceQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteIncomeSource] = useDelete_Income_SourceMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedIncomeSource(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (incomeSource: IncomeSource) => {
    setIsOpen(true);
    setSelectedIncomeSource(incomeSource.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    try {
      const response = await deleteIncomeSource({
        variables: {
          id: selectedIncomeSource,
          doneBy: userId,
        },
        update: UpdateHandlers.deleteIncomeSource,
      });
      setShowConfirm(false);

      if (response.data?.update_income_source?.affected_rows) {
        notify(true, 'Income Source deleted successfully.');
      } else {
        notify(false, 'failed to delete income source.');
      }
    } catch (err) {}
  };

  const confirm = (incomeSource: IncomeSource) => {
    setSelectedIncomeSource(incomeSource.id);
    setShowConfirm(true);
  };

  const getIncomeSource = (id?: number) => {
    if (id) {
      const incomeSource = incomeSources?.income_source?.find(
        (incomeSource) => incomeSource.id === id
      );
      if (incomeSource) {
        return {
          id: incomeSource.id,
          name: incomeSource.name,
        };
      }
    }
    return;
  };

  const getIncomeSources = (): IncomeSource[] => {
    return (
      incomeSources?.income_source
        ?.filter((gender) => {
          return gender.name !== testRow;
        })
        .map((incomeSource) => ({
          id: incomeSource.id,
          name: incomeSource.name,
          createdAt: incomeSource.created_at,
          lastChanged: incomeSource.updated_at,
        })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <IncomeSourceModal
          onClose={close}
          incomeSource={getIncomeSource(selectedIncomeSource)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Income Source"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Income Sources"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <IncomeSourcesTable
        isLoading={loading}
        incomeSources={getIncomeSources()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default IncomeSources;
