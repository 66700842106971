import React, { useCallback, useMemo, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import {
  PaginatedProspectsQueryVariables,
  usePaginatedProspectsQuery,
} from '../../generated/graphql';
import TableEx from '../../components/table/TableEx';
import Tag from '../../components/tag/Tag';
import Button from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useAuthState } from '../../context/auth';
import { accountType } from '../../utils/types';
import theme from '../../theme/theme';
import CustomerTypeModal from '../customers/components/CustomerTypeModal';
import { ADVISOR } from '../../routes/routes';
import { useCustomerDispatch } from '../../context/customer';
import { AccountType } from '../../shared/sections';
import { getAccountType } from '../../utils/helpers';
/**@jsx jsx*/

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'PSL Code',
    accessor: 'pslCode',
  },
  {
    Header: 'Account Type',
    accessor: 'accountType',
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedTo',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (row: any) => {
      return <Tag>{row.value}</Tag>;
    },
  },
];

const Prospects: React.FC = () => {
  const { role } = useAuthState();
  const dataRef = useRef<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, loading, fetchMore } = usePaginatedProspectsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 10,
      offset: 0,
    },
  });

  const placeholder = "first name, last name, assigned user"
  const totalCount = data?.member_aggregate.aggregate?.count ?? 0;

  interface ValueProps {
    name: accountType;
  }

  const history = useHistory();
  const dispatch = useCustomerDispatch();

  const aColumns = useMemo(
    () => [
      ...columns,
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (row: any) => {
          return (role !== "advisor") ?
            (
              <Button
                action={(e: any) => {
                  e.stopPropagation();
                  history.push(`/dashboard/prospects/${row.value}`);
                }}>
                <span>Review</span>
              </Button>
            )
            :
            (
              <Button
                action={(e: any) => {
                  e.stopPropagation();
                  history.push(`/dashboard/prospects/${row.value}`);
                }}>
                <span>Edit</span>
              </Button>
            )
        },
      },
    ],
    [history, role]
  );

  const transformData = useCallback((tData: any[]) => {
    return (
      tData.map((prospect) => ({
        id: prospect.id as string,
        fullName: `${prospect.user?.first_name ?? ''} ${prospect.user?.last_name ?? ''
          }`,
        pslCode: prospect.psl_code ?? '',
        accountType: getAccountType(prospect.account_type),
        assignedTo: `${prospect.assigned_to_user?.first_name ?? ''} ${prospect.assigned_to_user?.last_name ?? ''
          }`,
        status: prospect.status?.name ?? '',
        action: prospect.id as string,
      })) ?? []
    );
  }, []);

  React.useEffect(() => {
    if (data) {
      dataRef.current = data?.member ?? [];
      setTableData(transformData(data.member));
    }
  }, [data, transformData]);


  const fetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      const offset = pageIndex * pageSize;

      let fetchMoreMod = fetchMore;
      if (search) {
        fetchMoreMod = debounce(fetchMore, 300, { leading: true });
      }

      fetchMoreMod<keyof PaginatedProspectsQueryVariables>({
        variables: {
          offset,
          limit: pageSize,
          search: search ? `%${search}%` : null,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newMembers = fetchMoreResult.member;
          setTableData(transformData(newMembers));
          return {
            ...prev,
            member: newMembers,
          };
        },
      });
    },
    [fetchMore, transformData]
  );

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = (values: ValueProps) => {
    close();

    if (values.name === AccountType.individual) {
      dispatch({ type: "SET_ACCOUNT_TYPE", accountType: "individual" });
    }
    else if (values.name === AccountType.trust) {
      dispatch({ type: "SET_ACCOUNT_TYPE", accountType: "trust" });
    }
    else if (values.name === AccountType.joint) {
      dispatch({ type: "SET_ACCOUNT_TYPE", accountType: "joint" });
    }

    history.push(`/dashboard/create-prospect`);
  };

  return (
    <React.Fragment>
      {isOpen && (
        <CustomerTypeModal onClose={close} title="Create Prospect Account" handleSubmit={handleSubmit} />
      )}
      <SectionTitle
        title="Prospects"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADVISOR],
        }}
      />
      <TableEx
        showPagination
        showControls
        data={tableData}
        columns={aColumns}
        totalCount={totalCount}
        loading={loading}
        fetchData={fetchData}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};

export default Prospects;
