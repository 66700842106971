import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import DataTable from '../../components/table/DataTable';
import {
  useApprovePaymentsMutation,
  DirectDebitChargeDocument,
  useConfirmPaymentsMutation,
  useGetAppConfigQuery,
  useGetPaymentsPendingApprovalOrConfirmQuery,
  useGetStatusQuery,
} from '../../generated/graphql';
import { ITableColumn } from '../../utils/types';
import Tag from '../../components/tag/Tag';
import { groupBy, mapToObj, formatMoney } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import Confirm from '../../components/confirm/Confirm';
import { useApolloClient } from '@apollo/react-hooks';
import { useAuthState } from '../../context/auth';

/**@jsx jsx*/

type IProps = {
  action: string;
  statusId: number;
};

export interface Payment {
  value_date: string;
  total_amount: string;
  debit_count: any;
  debit_charge: string;
  status: string;
}

type Action =
  | { type: 'APPROVE_PAYMENTS' }
  | { type: 'CONFIRM_PAYMENTS' }
  | { type: 'PAYMENTS_APPROVAL_SUCCESS' }
  | { type: 'PAYMENTS_APPROVAL_FAILURE' }
  | { type: 'CONFIRM_PAYMENTS_SUCCESS' }
  | { type: 'STATUS_NOT_FOUND' }
  | { type: 'CONFIRM_PAYMENTS_FAILURE' };

type State = {
  title: string;
  message: string;
  icon: { name: string; color: string };
  type: string;
};

const columns: ITableColumn[] = [
  {
    title: 'Value Date',
    dataIndex: 'value_date',
    key: 'value_date',
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    key: 'total_amount',
  },
  {
    title: 'No of Debit Orders',
    dataIndex: 'debit_count',
    key: 'debit_count',
  },
  {
    title: 'Expected Debit Charge',
    dataIndex: 'debit_charge',
    key: 'debit_charge',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      return <Tag color="cyan">{status}</Tag>;
    },
  },
];

const dialogInfo = {
  approve: {
    title: 'Approve Payments',
    question: 'Are you sure you want to approve these payments...?',
    success: 'payments approved successfully...',
    failed: 'Failed to approve payments...',
  },
  confirm: {
    title: 'Confirm Payments',
    question: 'Are you sure you want to confirm these payments...?',
    success: 'payments confirmed successfully...',
    failed: 'Failed to confirm payments...',
  },
  icon: {
    question: {
      name: 'question-circle',
      color: 'blue',
    },
    success: {
      name: 'check',
      color: 'green',
    },
    failed: {
      name: 'xmark',
      color: 'red',
    },
  },
};

const initialValues = {
  title: 'Approve Payments',
  message: 'Are you sure you want to approve these payments...?',
  icon: dialogInfo.icon.question,
  type: 'approve',
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'APPROVE_PAYMENTS':
      return {
        ...state,
        type: 'approve',
        title: dialogInfo.approve.title,
        message: dialogInfo.approve.question,
        icon: dialogInfo.icon.question,
      };
    case 'PAYMENTS_APPROVAL_SUCCESS':
      return {
        ...state,
        type: 'approve',
        title: dialogInfo.approve.title,
        message: dialogInfo.confirm.success,
        icon: dialogInfo.icon.success,
      };
    case 'PAYMENTS_APPROVAL_FAILURE':
      return {
        ...state,
        type: 'approve',
        title: dialogInfo.approve.title,
        message: dialogInfo.approve.failed,
        icon: dialogInfo.icon.failed,
      };
    case 'CONFIRM_PAYMENTS':
      return {
        ...state,
        type: 'confirm',
        title: dialogInfo.confirm.title,
        message: dialogInfo.confirm.question,
        icon: dialogInfo.icon.question,
      };
    case 'CONFIRM_PAYMENTS_SUCCESS':
      return {
        ...state,
        type: 'confirm',
        title: dialogInfo.confirm.title,
        message: dialogInfo.confirm.success,
        icon: dialogInfo.icon.success,
      };
    case 'CONFIRM_PAYMENTS_FAILURE':
      return {
        ...state,
        type: 'confirm',
        title: dialogInfo.confirm.title,
        message: dialogInfo.confirm.failed,
        icon: dialogInfo.icon.failed,
      };
    case 'STATUS_NOT_FOUND':
      return {
        ...state,
        message: 'Status confirmed not found',
        icon: dialogInfo.icon.failed,
      };
    default:
      return {
        type: 'approve',
        title: dialogInfo.approve.title,
        message: dialogInfo.approve.question,
        icon: dialogInfo.icon.question,
      };
  }
};

const Approval = () => {
  const { role } = useAuthState();
  const [status, setStatus] = React.useState(0);
  const [allowPortfolioToApprove, setAllowPortfolioToApprove] = React.useState(
    true
  );
  const { data: appConfigData } = useGetAppConfigQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      name: 'allow_portfolio_to_approve',
    },
  });
  const { data: statusData } = useGetStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    let runEffect = true;
    const updateStatus = (name: string) => {
      const found = statusData?.status?.find(
        (status) => status.name.toLowerCase() === name
      );
      if (found && runEffect) {
        setStatus(found.id);
      }
    };

    if (role !== 'client_service' && Array.isArray(statusData?.status)) {
      updateStatus('pending approval');
    }

    if (role === 'client_service' && Array.isArray(statusData?.status)) {
      updateStatus('confirmed');
    }

    return () => {
      runEffect = false;
    };
  }, [allowPortfolioToApprove, statusData, setStatus, role]);

  React.useEffect(() => {
    const configVal =
      appConfigData?.app_config?.[0]?.value === 'yes' ? true : false;
    setAllowPortfolioToApprove(configVal);
  }, [appConfigData, setAllowPortfolioToApprove]);

  return (
    <PaymentApprovals
      action={allowPortfolioToApprove ? 'approve' : 'confirm'}
      statusId={status}
    />
  );
};

const PaymentApprovals = React.memo<IProps>(({ action, statusId }) => {
  const history = useHistory();
  const client = useApolloClient();
  const [valueDate, setValueDate] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const [{ title, message, icon, type }, dispatch] = React.useReducer<
    React.Reducer<State, Action>
  >(reducer, initialValues);

  const { data: statusData } = useGetStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data } = useGetPaymentsPendingApprovalOrConfirmQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      statusId,
    },
  });

  const [approvePayments] = useApprovePaymentsMutation();

  const [confirmPayments] = useConfirmPaymentsMutation();

  React.useEffect(() => {
    if (valueDate && !openConfirmDialog) {
      history.push(`/dashboard/approve-payments/${valueDate}/${statusId}`);
    }
  }, [valueDate, history, openConfirmDialog, statusId]);

  React.useEffect(() => {
    if (data?.payment?.length) {
      (async () => {
        setLoading(true);
        const groupedPayments = mapToObj(
          groupBy(data?.payment ?? [], (entry) => entry.value_date)
        );

        const results: Payment[] = [];
        for (const [key, values] of Object.entries(groupedPayments)) {
          const debit_count = values.reduce((acc: number, curr: any) => {
            if (curr.payment_mode?.name === 'Direct Debit') {
              acc += 1;
            }
            return acc;
          }, 0);

          const debitChargeResponse = await client.query({
            query: DirectDebitChargeDocument,
            variables: { date: key },
          });

          const debit_charge = `${formatMoney(
            debit_count *
              debitChargeResponse?.data?.direct_debit_charge?.[0]?.amount ?? 0
          )}`;

          const total_amount = values
            .reduce((acc: number, curr: any) => {
              const amount = isNaN(Number(curr.amount))
                ? 0
                : Number(curr.amount);
              acc += amount;
              return acc;
            }, 0)
            .toFixed(2);

          results.push({
            value_date: key,
            total_amount: formatMoney(total_amount),
            debit_count,
            debit_charge,
            status: 'PENDING APPROVAL',
          });
        }
        setPayments(results);
        setLoading(false);
      })();
    }
  }, [data, setPayments, setLoading, client]);

  const viewIndividualPayments = (payment: Payment) => {
    setValueDate(payment.value_date);
  };

  const approve = (payment: Payment) => {
    setOpenConfirmDialog(true);
    setValueDate(payment.value_date);
  };

  const confirm = (payment: Payment) => {
    setOpenConfirmDialog(true);
    setValueDate(payment.value_date);
    dispatch({ type: 'CONFIRM_PAYMENTS' });
  };

  const approvePaymentshandler = async () => {
    if (data?.payment?.length) {
      setLoading(true);
      const ids = data.payment
        .filter((payment) => payment.value_date === valueDate)
        .map((entry) => entry.id);
      if (ids.length) {
        const response = await approvePayments({
          variables: {
            ids,
          },
          refetchQueries: ['GetPaymentsByValueDate'],
          awaitRefetchQueries: true,
        });

        if (response?.data?.approvePayments?.success) {
          dispatch({ type: 'PAYMENTS_APPROVAL_SUCCESS' });
        } else {
          dispatch({ type: 'PAYMENTS_APPROVAL_FAILURE' });
        }
      }
      setLoading(false);
    }
  };

  const confirmPaymentshandler = async () => {
    if (valueDate) {
      setLoading(true);
      const selectedStatusId = statusData?.status?.find(
        (status) => status.name.toLowerCase() === 'confirmed'
      )?.id;

      if (!selectedStatusId) {
        dispatch({ type: 'STATUS_NOT_FOUND' });
        return;
      }

      const response = await confirmPayments({
        variables: {
          valueDate,
          statusId: selectedStatusId,
        },
        refetchQueries: ['GetPaymentsByValueDate'],
        awaitRefetchQueries: true,
      });

      if (response?.data?.update_payment?.affected_rows) {
        dispatch({ type: 'CONFIRM_PAYMENTS_SUCCESS' });
      } else {
        dispatch({ type: 'CONFIRM_PAYMENTS_FAILURE' });
      }
      setLoading(false);
    }
  };

  const cancel = () => {
    setOpenConfirmDialog(false);
    setValueDate('');
  };

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <Confirm
          title={title}
          message={message}
          confirm={{
            label: 'Yes, Proceed',
            function:
              type === 'approve'
                ? approvePaymentshandler
                : confirmPaymentshandler,
            loading: loading,
          }}
          cancel={{
            label: 'No, Cancel',
            function: cancel,
          }}
          icon={icon}
        />
      )}
      <SectionTitle title="Batched Payments" />
      <DataTable
        isLoading={loading}
        showControls
        showPagination
        data={payments}
        columns={columns}
        actions={[
          { label: 'Individual Payments', action: viewIndividualPayments },
          action === 'approve'
            ? { label: 'Approve', action: approve }
            : {
                label: 'Confirm',
                action: confirm,
              },
        ]}
        searchFilter={['value_date', 'total_amount']}
      />
    </React.Fragment>
  );
});

export default Approval;
