import React from 'react';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import Row from '../components/layout/Row';
import Summary from '../views/summary/Summary';
import { jsx } from '@emotion/core';
import Customers from '../views/customers/Customers';
import { Switch } from 'react-router-dom';
import Prospects from '../views/prospects/Prospects';
import Duration from '../views/assetDuration';
import Allocation from '../views/assetAllocation';
import ReviewProspect from '../views/prospects/ReviewProspect';
import PaymentProviders from '../views/payment-providers';
import Relationships from '../views/relationships';
import Genders from '../views/genders';
import Titles from '../views/titles';
import Goals from '../views/goals';
import Industries from '../views/industries';
import Advisor from '../views/advisors';
import BackOfficers from '../views/backOfficers';
import Admins from '../views/admins';
import PortfolioUsers from '../views/portfolio-users';
import ImportPayments from '../views/payments/import';
import ListPayments from '../views/payments/list';
import PaymentApproval from '../views/payments/approvals';
import ProductUnitPrices from '../views/product-unit-prices';
import Products from '../views/products';
import Notification from '../views/notifications/Notifications'
import ViewNotification from '../views/notifications/ViewNotification'
import { AuthRoute } from '../routes/AuthRoute';
import ViewCustomer from '../views/customers/ViewCustomer';

import AddIndividualCustomer from '../views/customers/individual/AddIndividualCustomer';
import EditIndividualCustomer from '../views/customers/individual/EditIndividualCustomer';

import AddTrustCustomer from '../views/customers/trust/AddTrustCustomer';
import EditTrustCustomer from '../views/customers/trust/EditTrustCustomer';

import AddJointCustomer from '../views/customers/joint/AddJointCustomer';
import EditJointCustomer from '../views/customers/joint/EditJointCustomer';

import DashboardSearch from '../views/dashboardSearch/Search';
import Apk from '../views/apks';
import IdentificationTypes from '../views/identification-types';
import IncomeSources from '../views/income-sources';
import ContributionSources from '../views/contribution-sources';
import MaritalStatus from '../views/marital-status';
import InsuranceType from '../views/insurance-type';
import { useAuthState } from '../context/auth';
import Logout from '../views/logout/Logout';
import Settings from '../views/settings/Settings';
import ApproveBatchedPayments from '../views/payments/ApproveBatchedPayments';
import AddProspect from '../views/prospects/AddProspect';

/**@jsx jsx*/

const Dashboard: React.FC = () => {
  const { role } = useAuthState();

  return (
    <div css={{ position: 'relative' }}>
      <Header />
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '220px 1fr',
          height: '100vh',
        }}>
        <div>
          <div
            css={{
              position: 'fixed',
              height: '100vh',
              zIndex: 0,
              width: '220px',
            }}>
            <Sidebar openCurrentSubMenuOnly={true} role={role} />
          </div>
        </div>
        <div css={{ marginTop: '96px', marginBottom: '100px' }}>
          <Row>
            <Switch>
              <AuthRoute
                path="/dashboard/settings"
                component={Settings}></AuthRoute>
              <AuthRoute
                path="/dashboard/logout"
                component={Logout}></AuthRoute>

              <AuthRoute
                path="/dashboard/customers/new/individual"
                component={AddIndividualCustomer}
              />
              <AuthRoute
                path="/dashboard/customers/new/trust"
                component={AddTrustCustomer}
              />
              <AuthRoute
                path="/dashboard/customers/new/joint"
                component={AddJointCustomer}
              />
              <AuthRoute
                path="/dashboard/customers/:id/edit/individual"
                component={EditIndividualCustomer}
              />
               <AuthRoute
                path="/dashboard/customers/:id/edit/trust"
                component={EditTrustCustomer}
              />
              <AuthRoute
                path="/dashboard/customers/:id/edit/joint"
                component={EditJointCustomer}
              />
              <AuthRoute
                path="/dashboard/customers/:id"
                component={ViewCustomer}
              />

              <AuthRoute path="/dashboard/customers" component={Customers} />
              <AuthRoute
                path="/dashboard/prospects/:id"
                component={ReviewProspect}
              />

              <AuthRoute path="/dashboard/prospects" component={Prospects} />
              <AuthRoute path="/dashboard/create-prospect" component={AddProspect} />
              <AuthRoute
                path="/dashboard/payment-providers"
                component={PaymentProviders}
              />
              <AuthRoute path="/dashboard/goals" component={Goals} />
              <AuthRoute path="/dashboard/genders" component={Genders} />
              <AuthRoute
                path="/dashboard/relationships"
                component={Relationships}
              />
              <AuthRoute path="/dashboard/titles" component={Titles} />
              <AuthRoute path="/dashboard/industries" component={Industries} />
              <AuthRoute path="/dashboard/products" component={Products} />
              <AuthRoute
                path="/dashboard/product-unit-prices"
                component={ProductUnitPrices}
              />
              <AuthRoute
                path="/dashboard/identification-types"
                component={IdentificationTypes}
              />
              <AuthRoute
                path="/dashboard/income-sources"
                component={IncomeSources}
              />
              <AuthRoute
                path="/dashboard/contribution-sources"
                component={ContributionSources}
              />
              <AuthRoute
                path="/dashboard/marital-status"
                component={MaritalStatus}
              />
              <AuthRoute
                path="/dashboard/insurance-type"
                component={InsuranceType}
              />
              <AuthRoute path="/dashboard/apk" component={Apk} />
              <AuthRoute path="/dashboard/durations" component={Duration} />
              <AuthRoute path="/dashboard/allocations" component={Allocation} />
              <AuthRoute path="/dashboard/advisors" component={Advisor} />
              <AuthRoute
                path="/dashboard/backofficers"
                component={BackOfficers}
              />
              <AuthRoute path="/dashboard/admins" component={Admins} />
              <AuthRoute path="/dashboard/portfolio-analysts" component={PortfolioUsers} />
              <AuthRoute
                path="/dashboard/import-payments"
                component={ImportPayments}
              />
              <AuthRoute
                path="/dashboard/list-payments"
                component={ListPayments}
              />
              <AuthRoute
                exact
                path="/dashboard/approve-payments"
                component={PaymentApproval}
              />
              <AuthRoute
                path="/dashboard/approve-payments/:valueDate/:statusId"
                component={ApproveBatchedPayments}
              />
              <AuthRoute
                path="/dashboard/notifications/:id"
                component={ViewNotification}
              />
              <AuthRoute path="/dashboard/notifications" component={Notification} />
              <AuthRoute path="/dashboard/search" component={DashboardSearch} />
              <AuthRoute path="/" component={Summary} />
            </Switch>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
