import React, { useCallback, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import {
  PaginatedBackgroundNotificationsQueryVariables,
  usePaginatedBackgroundNotificationsQuery
} from '../../generated/graphql';
import TableEx from '../../components/table/TableEx';
import Tag from '../../components/tag/Tag';
import Button from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import moment from 'moment';
/**@jsx jsx*/

const columns = [
  {
    Header: 'Notification ID',
    accessor: 'id',
  },
  {
    Header: 'Customer ID',
    accessor: 'pslCode',
  },
  {
    Header: 'Notification Type',
    accessor: 'type',
  },
  {
    Header: 'Notification Date',
    accessor: 'date',
  },
  {
    Header: 'Notification Status',
    accessor: 'status',
    Cell: (row: any) => {
      return <Tag>{row.value}</Tag>;
    },
  },
];

const Notifications: React.FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const { loading, fetchMore, data } = usePaginatedBackgroundNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 10,
      offset: 0,
    },
  });

  const placeholder = "notification type"
  const totalCount = data?.background_notifications_aggregate.aggregate?.count ?? 0;

  const history = useHistory();

  const aColumns = useMemo(
    () => [
      ...columns,
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <Button
              action={(e: any) => {
                e.stopPropagation();
                history.push({
                  pathname: `/dashboard/notifications/${row.value}`,
                  state: row.value,
                });
              }}>
              <span>View</span>
            </Button>
          );
        },
      },
    ],
    [history]
  );

  const transformData = useCallback((tData: any[]) => {
    return (
      tData.map((notif) => ({
        id: notif.id as string,
        date: moment(notif.created_at as string).format('dddd, Do MMMM, YYYY'),
        pslCode: notif.pslcode ?? '',
        type: notif.type ?? '',
        status: notif.status ?? '',
        action: notif.id as string,
      })) ?? []
    );
  }, []);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      const offset = pageIndex * pageSize;

      let fetchMoreMod = fetchMore;
      if (search) {
        fetchMoreMod = debounce(fetchMore, 300, { leading: true });
      }

      fetchMoreMod<keyof PaginatedBackgroundNotificationsQueryVariables>({
        variables: {
          offset,
          limit: pageSize,
          search: search ? `%${search}%` : null,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newNotifications = fetchMoreResult.background_notifications;
          setTableData(transformData(newNotifications));
          return {
            ...prev,
            background_notifications: newNotifications,
          };
        },
      });
    },
    [fetchMore, transformData]
  );

  return (
    <React.Fragment>
      <TableEx
        showPagination
        showControls
        data={tableData}
        columns={aColumns}
        loading={loading}
        fetchData={fetchData}
        totalCount={totalCount}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};

export default Notifications;
