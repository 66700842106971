import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
/**@jsx jsx*/

interface IProps {
  isInline?: boolean;
  label: string;
  value?: string;
}

const LabeledValue: React.FC<IProps> = ({ label, value, isInline }) => {
  return (
    <Flex stack={!isInline}>
      <p
        css={{
          color: theme.colors.gray[400],
          textTransform: 'uppercase',
          fontSize: '12px',
          fontFamily: theme.typography.fonts.semibold,
          marginBottom: '4px',
        }}>
        {label}
      </p>
      <p css={{ color: theme.colors.gray[600], fontSize: '14px' }}>{value}</p>
    </Flex>
  );
};

export default LabeledValue;
