import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useUpdate_ProductMutation,
  useAdd_ProductMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
  fund_code: string;
}

type IProductModal = IModal & {
  product?: { id: number; name: string; fundCode: string };
};

const ProductSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  fund_code: Yup.string().required('required'),
});

const ProductModal: React.FC<IProductModal> = ({ onClose, product }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
    fund_code: '',
  });

  const [
    addProduct,
    {
      data: addProductResult,
      loading: addProductLoading,
      error: addProductError,
    },
  ] = useAdd_ProductMutation();

  const [
    updateProduct,
    {
      data: updateProductResult,
      loading: updateProductLoading,
      error: updateProductError,
    },
  ] = useUpdate_ProductMutation();

  React.useEffect(() => {
    if (product) {
      setInitialValues({
        name: product.name,
        fund_code: product.fundCode,
      });
    }
  }, [product]);

  const addNewProduct = (values: ValueProps) => {
    addProduct({
      variables: {
        product_changes: [
          {
            name: values.name,
            fund_code: values.fund_code,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addProduct,
    }).catch(() => {});
  };

  const upateExistingProduct = (values: ValueProps) => {
    updateProduct({
      variables: {
        id: product?.id,
        changes: {
          name: values.name,
          fund_code: values.fund_code,
          modified_by: userId,
        },
      },
    }).catch(() => {});
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (product) {
      upateExistingProduct(values);
    } else {
      addNewProduct(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addProductLoading || updateProductLoading);
  const error = addProductError || updateProductError;
  const success = !!(
    addProductResult?.insert_product?.returning?.length ||
    updateProductResult?.update_product?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ProductSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${product ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${product ? 'Update' : 'Add'} Product`}>
            <React.Fragment>
              {!!error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500], marginBottom: '10px' }}
                  className="add-failed">
                  {error.message}
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Product ${product ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter name"
                    label="Product Name"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.name}
                  />
                  <ErrorMessage name="name" />
                </div>
                <div>
                  <Input
                    type="text"
                    name="fund_code"
                    placeholder="Enter code"
                    label="Fund Code"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.fund_code}
                  />
                  <ErrorMessage name="fund_code" />
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ProductModal;
