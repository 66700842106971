/* eslint-disable react/jsx-key */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { jsx } from '@emotion/core';
import Cell from './components/Cell';
import Card from '../card/Card';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import Stack from '../stack/Stack';
import Loader from '../loader/Loader';
import NativeSelect from '../select/NativeSelect';
import Input from '../input/Input';
import Icon from '../icon/Icon';
/**@jsx jsx*/

type TableProps = {
  data: any[];
  columns: { Header: string; accessor: string }[];
  fetchData: (args: {
    pageIndex: number;
    pageSize: number;
    search: string;
  }) => void;
  loading: boolean;
  totalCount: number;
  showPagination: boolean;
  showControls: boolean;
  placeholder?: string
};

const SearchControl = ({
  search,
  onChange,
  placeholder
}: {
  search: string;
  onChange: (arg: string) => void;
  placeholder?: string
}) => {
  return (
    <div css={{ position: 'relative', minWidth: '500px' }}>
      <Input
        value={search}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={`Search by ${placeholder}, status, psl code ...`}
        css={{ input: { paddingRight: '40px' } }}
      />
      <Icon
        size="sm"
        color={theme.colors.gray[300]}
        style={{ position: 'absolute', right: '16px', top: '12px' }}
        icon={['fas', 'magnifying-glass']}
      />
    </div>
  );
};

const TableEx = ({
  columns,
  data,
  fetchData,
  loading,
  showPagination,
  showControls,
  totalCount,
  placeholder
}: TableProps) => {
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);

  const pageCount = totalCount ?? 0 / pageSize

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount,
    },
    usePagination
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, search });
  }, [fetchData, pageIndex, pageSize, search]);

  const handleNextPage = () => {
    if (canNextPage) {
      nextPage();
    }
  };

  const handlePreviousPage = () => {
    if (canPreviousPage) {
      previousPage();
    }
  };

  const handleFirstPage = () => {
    gotoPage(0);
  };

  const handleLastPage = () => {
    const lastPageIndex = Math.ceil(pageCount / pageSize) - 1;
    gotoPage(lastPageIndex);
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
    gotoPage(0);
  };

  return (
    <Stack>
      {showControls && (
        <Stack isInline spacing={8}>
          <SearchControl {...{ search }} onChange={setSearch} placeholder={placeholder} />
        </Stack>
      )}
      <Card>
        <div
          css={{
            maxWidth: '100vw',
            width: '100%',
            overflowX: 'scroll',
            overflowY: 'visible',
          }}
        >
          <table
            css={{ width: '100%', borderCollapse: 'collapse' }}
            {...getTableProps()}
          >
            <thead
              css={{
                borderBottom: `1px solid ${theme.colors.gray[200]}`,
                borderRadius: '4px',
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Cell isHeader {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </Cell>
                  ))}
                </tr>
              ))}
            </thead>
            {!loading && (
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <Cell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Cell>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        {loading && (
          <Flex
            jc="center"
            ai="center"
            css={{ width: '100%', height: '100px' }}
          >
            <Loader
              size="small"
              color={theme.colors.primary}
              label="Loading Data"
            />
          </Flex>
        )}
      </Card>
      {showPagination && (
        <Stack isInline>
          <button onClick={handleFirstPage} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={handlePreviousPage} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={handleNextPage} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={handleLastPage} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
          <div>
            <NativeSelect
              css={{ width: '160px' }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size} Records
                </option>
              ))}
            </NativeSelect>
          </div>
          <div>
            <Flex ai="center" css={{ height: '36px' }}>
              <p css={{ fontSize: '14px' }}>
                Page{' '}
                <span css={{ fontFamily: theme.typography.fonts.bold }}>
                  {pageIndex + 1} of {Math.ceil(pageCount / pageSize)}
                </span>
              </p>
            </Flex>
          </div>
        </Stack>
      )}
    </Stack>
  );
};

export default TableEx;
