import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useAdd_Product_Unit_PriceMutation,
  useUpdate_Product_Unit_PriceMutation,
  useProductQuery,
  Product,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import NativeSelect from '../../../components/select/NativeSelect';
import DateInput from '../../../components/date/DateInput';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  product_id: number | string;
  unit_price: number | string;
  price_date: string;
}

type IProductUnitPriceModal = IModal & {
  price?: {
    id: number;
    unitPrice: number;
    priceDate: string;
    productId: number;
  };
};

const ProductUnitPriceSchema = Yup.object().shape({
  product_id: Yup.number().required('required').nullable(),
  unit_price: Yup.string()
    .required('required')
    .matches(/^\d+\.\d{6}$/, 'must have 6 decimal places'),
  price_date: Yup.string().required('required'),
});

const ProductUnitPriceModal: React.FC<IProductUnitPriceModal> = ({
  onClose,
  price,
}) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    product_id: '',
    unit_price: '',
    price_date: '',
  });

  const { data: products } = useProductQuery();

  const [
    addPrice,
    { data: addPriceResult, loading: addPriceLoading, error: addPriceError },
  ] = useAdd_Product_Unit_PriceMutation();

  const [
    updatePrice,
    {
      data: updatePriceResult,
      loading: updatePriceLoading,
      error: updatePriceError,
    },
  ] = useUpdate_Product_Unit_PriceMutation();

  React.useEffect(() => {
    if (price) {
      setInitialValues({
        product_id: price.productId,
        unit_price: price.unitPrice,
        price_date: price.priceDate,
      });
    }
  }, [price]);

  const addNewPrice = (values: ValueProps) => {
    addPrice({
      variables: {
        price_changes: [
          {
            product_id: values.product_id,
            unit_price: values.unit_price,
            price_date: values.price_date,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addProductUnitPrice,
    });
  };

  const upateExistingPrice = (values: ValueProps) => {
    updatePrice({
      variables: {
        id: price?.id,
        changes: {
          product_id: values.product_id,
          unit_price: values.unit_price,
          price_date: values.price_date,
          modified_by: userId,
        },
      },
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (price) {
      upateExistingPrice(values);
    } else {
      addNewPrice(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addPriceLoading || updatePriceLoading);
  const error = !!(addPriceError || updatePriceError);
  const success = !!(
    addPriceResult?.insert_price?.returning?.length ||
    updatePriceResult?.update_price?.returning?.length
  );

  const productsWithoutInsurance =
    products?.product.filter(
      (product) => product.name.toLowerCase() !== 'insurance'
    ) || [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ProductUnitPriceSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${price ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${price ? 'Update' : 'Add'} Product Unit Price`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Price ${price ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <Input
                    type="text"
                    name="unit_price"
                    placeholder="Enter unit price"
                    label="Unit Price"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.unit_price}
                  />
                  <ErrorMessage name="unit_price" />
                </div>
                <div>
                  <DateInput
                    label="Price Date"
                    name="price_date"
                    defaultDate={
                      formikProps.values.price_date
                        ? new Date(formikProps.values.price_date)
                        : undefined
                    }
                    onChange={(e: any) => {
                      const { name, value } = e.target;
                      if (value) {
                        const formattedDate = format(value, 'yyyy-MM-dd');
                        formikProps.setFieldValue(name, formattedDate);
                      }
                    }}
                  />
                  <ErrorMessage name="price_date" />
                </div>
                <div>
                  <NativeSelect
                    label="Product"
                    name="product_id"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.product_id}>
                    <option value="">select product</option>
                    {productsWithoutInsurance.map(
                      (product: Partial<Product>) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <ErrorMessage name="product_id" />
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ProductUnitPriceModal;
