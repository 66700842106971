import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Field, Form, FormikHelpers, FieldProps } from 'formik';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useGendersQuery,
  Gender,
  useIdentificationTypeQuery,
  Identification_Type,
  useAdd_AdvisorMutation,
  useUpdate_AdvisorMutation,
  useRegister_UserMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import { AdvisorSchema } from '../../../utils/yup-schema';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import Grid from '../../../components/layout/Grid';
import NativeSelect from '../../../components/select/NativeSelect';
import Separator from '../../../components/separator/Separator';
import DateInput from '../../../components/date/DateInput';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  otherName?: string | null;
  gender: string;
  dateOfBirth: Date;
  mobile: string;
  email: string;
  residentialAddress: string;
  identificationType: string;
  identificationNumber: string;
  advisorCode? : string | null;
}

interface IUser {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  otherName?: string | null;
  genderId: number;
  dateOfBirth: Date;
  mobile: string;
  email: string;
}

type IAdvisorModal = IModal & {
  advisor?: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    otherName: string;
    gender: string;
    dateOfBirth: Date;
    mobile: string;
    email: string;
    residentialAddress: string;
    identificationType: string;
    identificationNumber: string;
    advisorCode?: string | null;
  };
};

const AdvisorModal: React.FC<IAdvisorModal> = ({ onClose, advisor }) => {
  const { userId: currentUser } = useAuthState();
  const userRole = 'advisor';
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    firstName: '',
    lastName: '',
    otherName: null,
    gender: '',
    dateOfBirth: new Date(),
    mobile: '',
    email: '',
    residentialAddress: '',
    identificationType: '',
    identificationNumber: '',
    username: '',
    password: '',
    advisorCode: undefined,
  });

  const { data: genders } = useGendersQuery();
  const { data: identificationTypes } = useIdentificationTypeQuery();
  // const [
  //   registerUser,
  //   { error: registerUserError, data: registerUserResult },
  // ] = useRegister_UserMutation();
  const [
    registerUser,
    { data: registerUserResult, loading: registerUserLoading },
  ] = useRegister_UserMutation();

  const [
    addAdvisors,
    {
      data: addAdvisorResult,
      loading: addAdvisorLoading,
      error: addAdvisorError,
    },
  ] = useAdd_AdvisorMutation();
  const [
    updateAdvisors,
    {
      data: updateAssetAllocationResult,
      loading: updateAdvisorLoading,
      error: updateAdvisorError,
    },
  ] = useUpdate_AdvisorMutation();

  React.useEffect(() => {
    if (advisor) {
      setInitialValues({
        username: advisor.username,
        password: 'advisor',
        firstName: advisor.firstName,
        lastName: advisor.lastName,
        otherName: advisor.otherName,
        gender: advisor.gender,
        dateOfBirth: advisor.dateOfBirth,
        mobile: advisor.mobile,
        email: advisor.email,
        residentialAddress: advisor.residentialAddress,
        identificationType: advisor.identificationType,
        identificationNumber: advisor.identificationNumber,
        advisorCode: advisor.advisorCode
      });
    }
  }, [advisor]);

  const loading = !!(
    addAdvisorLoading ||
    updateAdvisorLoading ||
    registerUserLoading
  );
  let error = !!(
    addAdvisorError ||
    updateAdvisorError ||
    registerUserResult?.register.errors
  );
  let success = !!(
    addAdvisorResult?.insert_advisor?.returning?.length ||
    updateAssetAllocationResult?.update_advisor?.returning?.length
  );

  const addUser = async (user: IUser): Promise<number | null | undefined> => {
    const { data } = await registerUser({
      variables: {
        ...user,
        userRole,
      },
    });
    if (data?.register.success) {
      return data.register.id;
    }
  };

  const addAdvisor = async (values: ValueProps) => {
    try {
      const userId = await addUser({
        ...values,
        genderId: Number(values.gender),
      });

      if (userId) {
        addAdvisors({
          variables: {
            changes: [
              {
                id: userId,
                residential_address: values.residentialAddress,
                identification_type_id: values.identificationType,
                identification_number: values.identificationNumber,
                advisor_code: values.advisorCode,
                created_by: currentUser,
              },
            ],
          },
          update: UpdateHandlers.addAdvisor,
        });
      }
    } catch (error) {
      console.log('err', error);
    }
  };

  const updateAdvisor = (values: ValueProps) => {
    updateAdvisors({
      variables: {
        id: advisor?.id,
        advisor_changes: {
          residential_address: values.residentialAddress,
          identification_type_id: values.identificationType,
          identification_number: values.identificationNumber,
          advisor_code: values.advisorCode
        },
        user_changes: {
          first_name: values.firstName,
          last_name: values.lastName,
          other_name: values.otherName,
          gender_id: values.gender,
          date_of_birth: values.dateOfBirth,
          mobile: values.mobile,
          email: values.email,
          modified_by: currentUser,
        },
      },
      update: UpdateHandlers.updateAdvisor,
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (advisor) {
      updateAdvisor(values);
    } else {
      addAdvisor(values);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={AdvisorSchema}
      //validateOnMount={true}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${advisor ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name:'circle-user' }}
            title={`${advisor ? 'Update' : 'Add'} Advisor`}
            width="50%"
            top={70}>
            <React.Fragment>
              {error && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Advisor ${advisor ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              {/* <Stack> */}
              <Grid lg={3}>
                <div>
                  <Field name="firstName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="firstName"
                          placeholder="Enter First Name"
                          label="First Name"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="firstName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="otherName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="otherName"
                          placeholder="Enter other name"
                          label="Other Names"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="otherName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="lastName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          label="LastName"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="lastName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <NativeSelect
                    label="Gender"
                    name="gender"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.gender}>
                    <option value="">Select Gender</option>
                    {genders?.gender?.map((gender: Partial<Gender>) => (
                      <option key={gender.id} value={gender.id}>
                        {gender.name}
                      </option>
                    ))}
                  </NativeSelect>
                  <ErrorMessage name="gender" />
                </div>

                <div>
                  <Field name="dateOfBirth">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <DateInput
                          {...field}
                          type="text"
                          name="dateOfBirth"
                          placeholder="Enter date of birth"
                          label="Date Of Birth"
                          defaultDate={field.value}
                          minDate={new Date(1700, 5, 4)}
                          maxDate={new Date()}
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="DateOfBirth" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="mobile">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="mobile"
                          placeholder="Enter mobile"
                          label="Mobile"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="mobile" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="email"
                          placeholder="Enter email"
                          label="Email"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="email" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="residentialAddress">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="residentialAddress"
                          placeholder="Enter residential address"
                          label="Residential Address"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="residentialAddress" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <NativeSelect
                    label="Identification Type"
                    name="identificationType"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.identificationType}>
                    <option value="">ID Type</option>
                    {identificationTypes?.identification_type?.map(
                      (identification: Partial<Identification_Type>) => (
                        <option
                          key={identification.id}
                          value={identification.id}>
                          {identification.name}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <ErrorMessage name="identificationType" />
                </div>

                <div>
                  <Field name="identificationNumber">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="identificationNumber"
                          placeholder="Enter identification number"
                          label="ID Number"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="identificationNumber" />
                      </div>
                    )}
                  </Field>
                </div>

                 <div>
                  <Field name="advisorCode">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="advisorCode"
                          placeholder="Enter advisor code"
                          label="Advisor Code"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="advisorCode" />
                      </div>
                    )}
                  </Field>
                </div>
              </Grid>
              <br />
              <Separator />

              <Stack>
                <h3>Account Credentials</h3>
                <Grid lg={2}>
                  <div>
                    <Field name="username">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <Input
                            {...field}
                            type="text"
                            name="username"
                            disabled={advisor ? true : false}
                            placeholder="Enter user name"
                            label="username"
                            onChange={formikProps.handleChange}
                          />
                          <ErrorMessage name="username" />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div>
                    <Field name="password">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <Input
                            {...field}
                            type="password"
                            name="password"
                            disabled={advisor ? true : false}
                            placeholder="Enter password"
                            label="password"
                            onChange={formikProps.handleChange}
                          />
                          <ErrorMessage name="password" />
                        </div>
                      )}
                    </Field>
                  </div>
                </Grid>
              </Stack>

              {/* </Stack> */}
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default AdvisorModal;
