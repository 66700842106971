import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import ProfileOptions from './components/ProfileOptions';
import HeaderSearch from './components/HeaderSearch';
import logo from '../../assets/img/logo-white.png';
/**@jsx jsx*/

const Header: React.FC = () => {
  return (
    <header
      css={{
        background: theme.colors.primary,
        height: '48px',
        padding: '0 24px',
        color: theme.colors.white,
        position: 'fixed',
        width: '100vw',
        zIndex: 9999999999,
      }}>
      <Flex ai="center" jc="space-between" css={{ height: '100%' }}>
        <img width="80px" src={logo} alt="" />
        <Flex ai="center">
          <HeaderSearch />
          <div>
            <ProfileOptions />
          </div>
        </Flex>
      </Flex>
    </header>
  );
};

export default Header;
