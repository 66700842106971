import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import RelationshipsTable from './components/RelationshipsTable';
import RelationshipModal from './components/RelationshipModal';
import {
  useRelationshipsQuery,
  useDelete_RelationshipMutation,
} from '../../generated/graphql';
import { IRelationship } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/
import { testRow } from '../../shared/constants';

const Relationships: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedRelationship, setSelectedRelationship] = React.useState<
    number | undefined
  >();
  const { data: relationships, loading } = useRelationshipsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteRelationship] = useDelete_RelationshipMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedRelationship(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (relationship: IRelationship) => {
    setIsOpen(true);
    setSelectedRelationship(relationship.id);
  };

  const confirm = (relationship: IRelationship) => {
    setSelectedRelationship(relationship.id);
    setShowConfirm(true);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deleteRelationship({
      variables: {
        id: selectedRelationship,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteRelationship,
    });
    setShowConfirm(false);

    if (response.data?.update_relationship?.affected_rows) {
      notify(true, 'Relationship deleted successfully.');
    } else {
      notify(false, 'failed to delete relationship.');
    }
  };

  const getRelationship = (id?: number) => {
    if (id) {
      const relationship = relationships?.relationship?.find(
        (entry) => entry.id === id
      );
      if (relationship) {
        return {
          id: relationship.id,
          name: relationship.name,
        };
      }
    }
    return;
  };

  const getRelationships = (): IRelationship[] => {
    return (
      relationships?.relationship
        ?.filter((gender) => {
          return gender.name !== testRow;
        })
        .map((relationship) => ({
          id: relationship.id,
          name: relationship.name,
          createdAt: relationship.created_at,
          lastChanged: relationship.updated_at,
        })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <RelationshipModal
          onClose={close}
          relationship={getRelationship(selectedRelationship)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Relationship"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Relationships"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <RelationshipsTable
        isLoading={loading}
        relationships={getRelationships()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Relationships;
