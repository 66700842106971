import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import { IInputProps } from '../../utils/types';
/**@jsx jsx*/

interface IProps extends IInputProps {
  isChecked?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  className,
  onChange,
  name,
  id,
  value,
  isChecked = false,
  label,
}) => {
  return (
    <label htmlFor={id}>
      <div
        className={className}
        css={{
          display: 'inline-block',
          verticalAlign: 'middle',
        }}>
        <input
          onChange={onChange}
          id={id}
          value={value}
          type="checkbox"
          name={name}
          defaultChecked={isChecked}
          css={{
            border: 0,
            clip: 'rect(0 0 0 0)',
            clippath: 'inset(50%)',
            height: '1px',
            margin: '-1px',
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            whiteSpace: 'nowrap',
            width: '1px',
          }}
        />
        <div
          css={{
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '3px',
            transition: 'all 150ms',

            'input:checked + &': {
              background: theme.colors.secondary,
            },

            'input:not(:checked) + &': {
              border: `2px solid ${theme.colors.secondary}`,
              background: theme.colors.white,
            },

            'input:checked svg': {
              visibility: 'visible',
            },

            'input:not(:checked) svg': {
              visibility: 'hidden',
            },
          }}>
          <svg fill="none" stroke="white" strokeWidth="2px" viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      </div>
      <div
        css={{
          marginLeft: '10px',
          fontSize: '16px',
          color: theme.colors.gray[500],
        }}>
        {label}
      </div>
    </label>
  );
};

export default Checkbox;
