import React, { Dispatch, SetStateAction } from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import xslx from 'xlsx';
import Modal from '../../../components/modal/Modal';
import { IModal } from '../../../utils/types';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import FileUploader from '../../../components/uploader/FileUploader';
import { PaymentSchema } from '../../../utils/yup-schema';
import Flex from '../../../components/layout/Flex';
import theme from '../../../theme/theme';
import NativeSelect from '../../../components/select/NativeSelect';
import { usePaymentModesQuery } from '../../../generated/graphql';
/**@jsx jsx*/

export type PaymentTypes = 'migrated_payments' | 'new_payments';

interface Values {
  payment_file: File | string;
  payment_file_type: PaymentTypes;
}

interface IProp extends IModal {
  getData: (args: any) => void;
  onClose: () => void;
  paymentFileType: PaymentTypes;
  setPaymentFileType: Dispatch<SetStateAction<PaymentTypes>>;
}

const EXPECTED_COLUMNS = {
  new_payments: [
    'business date',
    'transaction date',
    'value date',
    'transaction amount',
    'transaction details',
    'payment mode',
  ],
  migrated_payments: [
    'fp id',
    'mf id',
    'customer',
    'transaction date',
    'value date',
    'amount',
    'split',
  ],
};

const initialValues: Values = {
  payment_file: '',
  payment_file_type: 'new_payments',
};

const PaymentModal: React.FC<IProp> = ({
  onClose,
  getData,
  paymentFileType,
  setPaymentFileType,
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');

  const { data: paymentModes } = usePaymentModesQuery();

  const handleSubmit = (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    const file = values.payment_file;
    if (file) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = (e) => {
        const bstr = e.target?.result;
        const wb = xslx.read(bstr, {
          type: rABS ? 'binary' : 'array',
          cellText: false,
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: Array<string[]> = xslx.utils.sheet_to_json(ws, {
          raw: false,
          header: 1,
          dateNF: 'yyyy-mm-dd',
        });

        let valid = true;
        const columns: { [key: string]: number } = {};
        if (data.length === 0) {
          setErrorMessage('File is empty...');
          valid = false;
        } else if (data.length >= 1) {
          const expectedColumns = EXPECTED_COLUMNS[values.payment_file_type];
          data[0]?.forEach((entry: string, index) => {
            const columnName = entry.trim().toLowerCase();
            const found = expectedColumns.includes(columnName);
            if (found) {
              columns[columnName] = index;
            }
          });
          const allExpectedColumnsPresent =
            expectedColumns.length === Object.keys(columns).length
              ? true
              : false;
          if (!allExpectedColumnsPresent) {
            setErrorMessage('File is missing required columns...');
            valid = false;
          } else if (!data?.[1]?.length && !data?.[2]?.length) {
            setErrorMessage('File has no data...');
            valid = false;
          }
        }

        if (valid) {
          setErrorMessage('');
          let actualData: { [key: string]: string }[] = [];
          for (let key in data) {
            if (key !== '0' && data[key].length) {
              const dataEntry: { [key: string]: string } = {};
              Object.keys(columns).forEach((columnName) => {
                const columnIndex = columns[columnName];
                if (columnName === 'split') {
                  const splitColumnValuesArray = data[key].splice(
                    columnIndex,
                    2
                  );
                  let splitColumnNumericValuesArray = [];
                  let count = 0;
                  for (const value of splitColumnValuesArray) {
                    const spitVal = Number(
                      value?.trim()?.replace(',', '')
                    ).toFixed(2);
                    if (!isNaN(Number(spitVal))) {
                      if (count === 0) {
                        splitColumnNumericValuesArray.push(`income|${spitVal}`);
                      } else {
                        splitColumnNumericValuesArray.push(
                          `balanced|${spitVal}`
                        );
                      }
                      count++;
                    }
                  }
                  const splitColumnValueStr = splitColumnNumericValuesArray.join(
                    ', '
                  );
                  dataEntry[columnName] = splitColumnValueStr;
                } else {
                  dataEntry[columnName] = data[key][columnIndex]?.trim();
                }
              });

              const modes =
                paymentModes?.payment_mode?.map((mode) =>
                  mode.name.toLowerCase()
                ) ?? [];

              const modeFound = modes.includes(
                dataEntry['payment mode']?.trim().toLowerCase()
              );

              if (!modeFound) {
                setErrorMessage('Some Data entries have invalid payment mode');
                valid = false;
                break;
              }

              const allHasValue = Object.values(dataEntry).every(
                (value) => !!value
              );

              if (allHasValue) {
                actualData.push(dataEntry);
              }
            }
          }

          if (valid) {
            getData(actualData);
            onClose?.();
          }

          setSubmitting(false);
        } else {
          setSubmitting(false);
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file as File);
      } else {
        reader.readAsArrayBuffer(file as File);
      }
    }
  };

  const onPaymentFileTypeChange = (value: PaymentTypes) => {
    setPaymentFileType(value);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        payment_file_type: paymentFileType,
      }}
      onSubmit={handleSubmit}
      validationSchema={PaymentSchema}>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: 'Import',
              loading: formikProps.isSubmitting,
            }}
            icon={{ name: 'building-columns' }}
            title="Upload Payment File">
            <React.Fragment>
              {errorMessage && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  {errorMessage}
                </Flex>
              )}
              <Stack>
                <div>
                  <ErrorMessage name="payment_file" />
                  <FileUploader
                    id="payment_file"
                    name="payment_file"
                    label="Upload payment file"
                    onChange={(e) =>
                      formikProps.setFieldValue(
                        'payment_file',
                        e.target.files[0]
                      )
                    }
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
                <NativeSelect
                  label="Type of payment file"
                  name="payment_file_type"
                  onChange={(e) => {
                    onPaymentFileTypeChange(e.target.value);
                    formikProps.setFieldValue(
                      'payment_file_type',
                      e.target.value
                    );
                  }}
                  onBlur={(e) => {
                    onPaymentFileTypeChange(e.target.value);
                    formikProps.setFieldValue(
                      'payment_file_type',
                      e.target.value
                    );
                  }}
                  value={formikProps.values.payment_file_type}>
                  <option value="new_payments">
                    File with new payment data
                  </option>
                  <option value="migrated_payments">
                    File with payment data being migrated
                  </option>
                </NativeSelect>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentModal;
