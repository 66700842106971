import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import { daysOfTheWeek, getDates, isSameMonth } from './calendarFns';
import { func } from '../../utils/types';
import Icon from '../icon/Icon';
import { isSameDay, format, isBefore, isAfter } from 'date-fns';
import theme from '../../theme/theme';
/**@jsx jsx */

export interface ICalenderEventObject {
  value: Date;
}
interface IProps {
  onChange?: (args: ICalenderEventObject) => void;
  onBlur?: func;
  left?: string;
  top?: string;
  width?: string;
  name?: string;
  selectedDate?: Date;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  defaultDate?: Date;
}

const DatePicker: React.FC<IProps> = ({
  onChange = () => {},
  className,
  top = 'calc(100% + 5px)',
  left = '0',
  width = '100%',
  minDate,
  maxDate,
  selectedDate = new Date(),
  defaultDate,
}) => {
  const date = new Date(selectedDate);

  function handleOnChange(date: Date) {
    setState({ ...state, date: date });
    onChange({ value: date });
  }

  const [state, setState] = React.useState({
    date: date,
    month: date.getMonth(),
    year: date.getFullYear(),
  });

  function goToNextMonth() {
    let month = state.month;
    let year = state.year;

    if (state.month === 11) {
      month = 0;
      year += 1;
    } else {
      month += 1;
    }

    setState({ ...state, month, year });
  }

  function goToPreviousMonth() {
    let month = state.month;
    let year = state.year;
    if (state.month === 0) {
      month = 11;
      year -= 1;
    } else {
      month -= 1;
    }

    setState({ ...state, month, year });
  }

  function goToNextYear() {
    setState({ ...state, year: state.year + 1 });
  }

  function goToPreviousYear() {
    setState({ ...state, year: state.year - 1 });
  }

  function handleKeyDown(e: any, date: Date) {
    if (e.keyCode === 13) {
      handleOnChange(date);
    }
  }

  function getDayColorScheme(day: Date) {
    if (isSameDay(new Date(state.date), new Date(day))) {
      return {
        background: theme.colors.cyan[100],
        color: theme.colors.primary,
      };
    }
    if (isBefore(new Date(day), minDate as Date)) {
      return {
        background: theme.colors.gray[50],
        color: theme.colors.gray[200],
      };
    }
    if (isAfter(new Date(day), maxDate as Date)) {
      return {
        background: theme.colors.gray[50],
        color: theme.colors.gray[200],
      };
    }
    if (!isSameMonth(day, state.month)) {
      return { background: 'none', color: theme.colors.gray[300] };
    }

    return { background: 'none', color: theme.colors.primary };
  }

  React.useEffect(() => {
    if (defaultDate) {
      setState({
        month: defaultDate.getMonth(),
        date: defaultDate,
        year: defaultDate.getFullYear(),
      });
    }
  }, [defaultDate]);

  return (
    <div
      className={className}
      css={{
        backgroundColor: theme.colors.white,
        position: 'absolute',
        top: top,
        left: left,
        boxShadow:
          ' 0 0 0 1px rgba(136, 152, 170, .1), 0 15px 35px 0 rgba(49, 49, 93, .1), 0 5px 15px 0 rgba(0, 0, 0, .08)',
        padding: '20px',
        borderRadius: '5px',
        width: width,
        minWidth: '300px',
        animation: `${theme.animations.scaleUp} .3s`,
        zIndex: 999999999,
        '&:focus': { outline: 'none' },
      }}
      tabIndex={0}
      onClick={(e) => e.stopPropagation()}>
      <Flex
        jc="space-between"
        css={{
          marginBottom: '16px',
          button: {
            background: 'transparent',
            border: 'none',
            '&:first-of-type': { marginRight: '12px' },
          },
        }}>
        <Flex>
          <button
            onClick={(e) => {
              e.preventDefault();
              goToPreviousYear();
            }}>
            <Icon
              size="1x"
              color={theme.colors.gray[500]}
              icon={['fas', 'angles-left']}></Icon>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              goToPreviousMonth();
            }}>
            <Icon
              size="1x"
              color={theme.colors.gray[500]}
              icon={['fas', 'chevron-left']}></Icon>
          </button>
        </Flex>
        <div
          css={{
            h4: {
              fontSize: '14px',
              fontFamily: theme.typography.fonts.semibold,
              color: theme.colors.primary,
            },
            p: { color: theme.colors.gray[300] },
          }}>
          <h4>{format(new Date(state.year, state.month), 'MMM yyyy')}</h4>
        </div>
        <Flex>
          <button
            onClick={(e) => {
              e.preventDefault();
              goToNextMonth();
            }}>
            <Icon
              size="1x"
              color={theme.colors.gray[500]}
              icon={['fas', 'chevron-right']}></Icon>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              goToNextYear();
            }}>
            <Icon
              size="1x"
              color={theme.colors.gray[500]}
              icon={['fas', 'angles-right']}></Icon>
          </button>
        </Flex>
      </Flex>

      <Flex css={{ marginBottom: '16px' }} jc="center">
        <p css={{ fontSize: '14px', color: theme.colors.gray[400] }}>
          {format(state.date, 'EEEE, dd MMM yyyy')}
        </p>
      </Flex>

      <div>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7,1fr)',
            marginBottom: '16px',
          }}>
          {daysOfTheWeek.map((day) => {
            return (
              <Flex
                key={day}
                jc="center"
                ai="center"
                styles={{
                  color: theme.colors.gray[300],
                  textTransform: 'capitalize',
                }}>
                {day.substring(0, 2)}
              </Flex>
            );
          })}
        </div>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            gridTemplateRows: 'repeat(6, 36px)',
          }}>
          {getDates(state.month, state.year).map((date, month) => {
            return (
              <div
                role="button"
                tabIndex={0}
                key={month}
                onClick={(e) => {
                  e.preventDefault();
                  handleOnChange(date);
                }}
                onKeyDown={(e) => {
                  handleKeyDown(e, date);
                }}
                css={{
                  ...getDayColorScheme(date),
                  border: 'none',
                  fontSize: '14px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '4px',
                  pointerEvents:
                    isBefore(new Date(date), minDate as Date) ||
                    isAfter(new Date(date), maxDate as Date)
                      ? 'none'
                      : 'all',
                  '&:hover, &:focus': {
                    background: theme.colors.gray[50],
                    outline: 'none',
                  },
                }}>
                {date.getDate()}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
