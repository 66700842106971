import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../components/layout/Flex';
import Stack from '../../components/stack/Stack';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import Grid from '../../components/layout/Grid';
import StatCard from '../../components/stat/StatCard';
import CustomerCard from '../../components/customerCard/CustomerCard';
import {
  useDashboardSummaryQuery,
  useRecentCustomersQuery,
} from '../../generated/graphql';
import theme from '../../theme/theme';
import Loader from '../../components/loader/Loader';
/**@jsx jsx*/

const Summary: React.FC = () => {
  const { data: summary, loading: summaryloading } = useDashboardSummaryQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: recentCustomers,
    loading: recentCustomerLoading,
  } = useRecentCustomersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const loading = !!(summaryloading || recentCustomerLoading);

  const summaryData = summary?.dashboard_summary[0];
  const recentCustomerData = recentCustomers?.member;

  return (
    <React.Fragment>
      {loading ? (
        <Flex css={{ height: '200px' }} ai="center" jc="center">
          <Loader
            size="small"
            color={theme.colors.primary}
            label="Loading Dashboard Data"
          />
        </Flex>
      ) : (
        <Stack spacing={48}>
          <section>
            <SectionTitle title="Dashboard" />
            <Grid lg={3}>
              <StatCard
                data={{
                  value: summaryData?.total_customer_count,
                  growth: summaryData?.percentage_customer_count_increase,
                  footnote: `You have ${summaryData?.last_thirty_days_customer_count} more customers in the last 30 days`,
                }}
                title="Customers"
              />
              <StatCard
                data={{
                  value: summaryData?.total_prospect_count,
                  growth: summaryData?.percentage_prospect_count_increase,
                  footnote: `You have ${summaryData?.last_thirty_days_prospect_count} new prospects in the last 30 days`,
                }}
                title="New Prospects"
              />
              <StatCard
                data={{ value: summaryData?.agent_count }}
                title="Agents"
              />
            </Grid>
          </section>
          <section>
            <SectionTitle
              action={{
                label: 'See All Customers',
                path: '/dashboard/customers',
                icon: {
                  name: 'arrow-right',
                  color: theme.colors.white,
                  position: 'after',
                },
              }}
              title="Recently Added Customers"></SectionTitle>
            <Grid lg={4}>
              {recentCustomerData?.map((customer, index) => (
                <CustomerCard
                  key={index}
                  customer={{
                    fullName: `${customer.user.first_name ?? ''} ${
                      customer.user.other_name ?? ''
                    } ${customer.user.last_name ?? ''}`,
                    email: customer.user.email as string,
                    mobile: customer.user.mobile as string,
                  }}
                />
              ))}
            </Grid>
          </section>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default Summary;
