import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import Modal from '../../../components/modal/Modal';
import { IAccountType, accountType, IModal } from '../../../utils/types';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import NativeSelect from '../../../components/select/NativeSelect';
import { account_types, AccountType } from '../../../shared/sections';
import { CustomerAccountTypeSchema } from '../../../utils/yup-schema';
/**@jsx jsx*/

interface ValueProps {
  name: accountType;
}

type ICustomerTypeModal = IModal & {
    title: string;
    handleSubmit: (values: ValueProps, { setSubmitting }: FormikHelpers<ValueProps> ) => void;
    render?(): void;
};


const CustomerTypeModal: React.FC<ICustomerTypeModal> = ({ onClose, title, handleSubmit }) => {

  const [initialValues, setInitialValues] = React.useState<ValueProps>({ name: AccountType.individual });

  const handleChange = (event: any) => {
      setInitialValues( item => ({
        ...item,
        ...{ name: event.target.value }
    }))
    
  }
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={CustomerAccountTypeSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: "Continue",
              loading: false,
            }}
            icon={{ name:'circle-user'}}
            title={title}>
            <React.Fragment>
              <Stack>
                <div>
                    <NativeSelect
                      label="account type"
                      name="name"
                      onChange={handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.name}>
                      <option>Select Account Type</option>
                      {account_types.map((item: Partial<IAccountType>) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <ErrorMessage name="name" />
                  </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerTypeModal;
