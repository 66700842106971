import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
/**@jsx jsx*/

interface IProps {
  color?: string;
}

const Tag: React.FC<IProps> = ({ children, color = 'blue' }) => {
  return (
    <Flex
      css={{
        padding: '2px 6px',
        display: 'inline-flex',
        background: theme.colors[color][100],
        color: theme.colors[color][600],
        fontSize: '12px',
        fontFamily: theme.typography.fonts.bold,
        borderRadius: '3px',
        textTransform: 'uppercase',
      }}>
      {children}
    </Flex>
  );
};

export default Tag;
