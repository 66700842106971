import React from 'react';
import { jsx } from '@emotion/core';
import { IInputProps } from '../../utils/types';
import Input from '../input/Input';
import useDropDown from '../../hooks/useDropDown';
import DatePicker, { ICalenderEventObject } from './DatePicker';
import { format, isValid } from 'date-fns';
import InputLabel from '../input/components/InputLabel';
/**@jsx jsx*/

interface IState {
  date: Date;
  inputState?: string;
}
interface IProps extends IInputProps {
  defaultDate?: Date;
  minDate?: Date;
  maxDate?: Date;
}

const DateInput: React.FC<IProps> = ({
  label,
  name,
  defaultDate,
  minDate,
  maxDate,
  onChange = () => {},
}) => {
  const [state, setState] = React.useState({
    date: new Date(),
    inputState: '',
  } as IState);
  const { elRef, isOpen, setIsOpen, handleVisibilityChange } = useDropDown(
    false
  );

  function onInputChange(e: any) {
    setState({ ...state, inputState: e.target.value });
  }

  function onInputBlur(e: any) {
    const { value } = e.target;
    const [day, month, year] = value.split('/');

    if (!isValid(new Date(`${month}/${day}/${year}`))) {
      setState({ ...state, inputState: '' });
      onChange({ target: { name: name, value: '' } });
      return;
    }

    setState({
      inputState: value,
      date: new Date(`${month}/${day}/${year}`),
    });
    onChange({
      target: { name: name, value: new Date(`${month}/${day}/${year}`) },
    });
  }

  function handleOnEnterKeyPress(e: any) {
    if (e.keyCode !== 13) {
      return;
    }
    e.target.blur();
    setIsOpen(false);
  }

  function selectDate(e: ICalenderEventObject) {
    const { value } = e;
    setState({
      date: value,
      inputState: format(new Date(value), 'dd/MM/yyyy'),
    });
    setIsOpen(false);
    onChange({ target: { name: name, value: value } });
  }

  function onInputFocus(e: React.FocusEvent) {
    if (isOpen) {
      return;
    }
    handleVisibilityChange(e);
  }

  React.useEffect(() => {
    if (defaultDate) {
      setState({
        date: defaultDate,
        inputState: format(defaultDate, 'dd/MM/yyyy'),
      });
    }
  }, [defaultDate]);

  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <div ref={elRef} css={{ position: 'relative', width: '100%' }}>
        <Input
          placeholder={'DD/MM/YYYY'}
          onBlur={onInputBlur}
          onKeyDown={handleOnEnterKeyPress}
          value={state.inputState}
          onChange={onInputChange}
          onFocus={onInputFocus}
        />
        {isOpen && (
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            onChange={selectDate}
            selectedDate={state.date}
            defaultDate={defaultDate}
          />
        )}
      </div>
    </div>
  );
};

export default DateInput;
