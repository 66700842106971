import React from 'react';

const useDropDownExp = (
  isOpenArg: boolean = false,
  shouldToggle: boolean = false
) => {
  const [isOpen, setIsOpen] = React.useState(isOpenArg);
  const elRef = React.useRef<HTMLDivElement>(null);
  const portalRef = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback((e: any) => {
    if (elRef.current && elRef.current.contains(e.target)) {
      return;
    }
    if (e.target.dataset.block) {
      return;
    }
    if (portalRef.current && portalRef.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  }, []);

  function handleVisibilityChange(e: any) {
    e.stopPropagation();
    if (isOpen && shouldToggle) {
      setIsOpen(false);
      return;
    }
    setIsOpen(true);
  }

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
      return;
    }
    document.removeEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, handleOutsideClick]);

  return {
    isOpen,
    setIsOpen,
    elRef,
    portalRef,
    handleVisibilityChange,
    handleOutsideClick,
  };
};

export default useDropDownExp;
