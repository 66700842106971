import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { IUser } from '../../../utils/types';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

type Actions = { label: string; action: (backOfficer: IUser) => void }[];

interface IProps {
  agents?: IUser[];
  actions: Actions;
  isLoading?: boolean;
}

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Date Of Birth',
    dataIndex: 'dateOfBirth',
    key: 'dateOfBirth',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: Date) => {
      return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Last Changed',
    dataIndex: 'lastChanged',
    key: 'lastChanged',
    render: (lastChanged: Date) => {
      return <span>{moment(lastChanged).format('DD/MM/YYYY')}</span>;
    },
  },
];

const AgentsTable: React.FC<IProps> = ({ agents = [], actions, isLoading }) => {
  return (
    <DataTable
      showControls
      showPagination
      data={agents}
      columns={columns}
      actions={actions}
      searchFilter="fullName"
      isLoading={isLoading}></DataTable>
  );
};

export default AgentsTable;
