import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../views/Login/Login';
import TestArea from '../views/testarea/TestArea';

class Home extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/test" component={TestArea} />
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    );
  }
}

export default Home;
