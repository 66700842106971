import React from 'react';
import { toast } from 'react-toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import AdvisorsTable from './components/AdvisorTable';
import AdvisorsModal from './components/AdvisorModal';
import {
  useAdvisorsQuery,
  useDelete_UserMutation,
} from '../../generated/graphql';
import { IAdvisor } from '../../utils/types';
import moment from 'moment';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN, BACKOFFICER } from '../../routes/routes';

const Advisors: React.FC = () => {
  const { userId } = useAuthState();
  const { data: advisors, loading } = useAdvisorsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAdvisor] = useDelete_UserMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedAdvisor(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (advisor: IAdvisor) => {
    setIsOpen(true);
    setSelectedAdvisor(advisor.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const deactivate = (advisor: IAdvisor) => {
    setSelectedAdvisor(advisor.id);
    setShowConfirm(true);
  };

  const deactivateAdvisor = async () => {
    const response = await deleteAdvisor({
      variables: {
        id: selectedAdvisor,
        doneBy: userId,
      },
      refetchQueries: ['Advisors'],
      awaitRefetchQueries: true,
    });
    setShowConfirm(false);

    if (response.data?.update_user?.affected_rows) {
      notify(true, 'Advisor deleted successfully.');
    } else {
      notify(false, 'failed to delete advisor.');
    }
  };

  const getAdvisor = (id?: number) => {
    if (id) {
      const advisor = advisors?.advisor?.find((advisor) => advisor.id === id);
      if (advisor) {
        return {
          id: advisor.id,
          advisorCode: advisor.advisor_code,
          firstName: advisor.userById?.first_name as string,
          lastName: advisor.userById?.last_name as string,
          otherName: advisor.userById?.other_name ?? '',
          gender: advisor.userById?.gender_id,
          dateOfBirth: moment(advisor.userById?.date_of_birth).toDate(),
          mobile: advisor.userById?.mobile as string,
          email: advisor.userById?.email as string,
          residentialAddress: advisor.residential_address,
          identificationType: advisor.identification_type_id,
          identificationNumber: advisor.identification_number,
          username: advisor.userById?.email as string,
          password: 'advisor',
        };
      }
    }
    return;
  };

  const getAdvisors = (): IAdvisor[] => {
    return (
      advisors?.advisor?.map((advisor) => ({
        id: advisor.id,
        advisorCode: advisor.advisor_code,
        fullName: `${advisor.userById?.first_name ?? ''} ${
          advisor.userById?.other_name ?? ''
        } ${advisor.userById?.last_name ?? ''}`,
        gender: advisor.userById?.gender.name as string,
        gender_id: advisor.userById?.gender_id,
        dateOfBirth: advisor.userById?.date_of_birth,
        mobile: advisor.userById?.mobile as string,
        email: advisor.userById?.email as string,
        residentialAddress: advisor.residential_address,
        identificationTypeId: advisor.identification_type_id,
        identificationType: advisor.identification_type.name as string,
        identificationNumber: advisor.identification_number,
        createdAt: advisor.userById?.created_at,
        lastChanged: advisor.userById?.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <AdvisorsModal onClose={close} advisor={getAdvisor(selectedAdvisor)} />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          onClose={close}
          confirm={{ label: 'ok', function: deactivateAdvisor }}
          title="Deactivate Advisor"
          message="Are you sure you want to deactivate?"
        />
      )}

      <SectionTitle
        title="Advisors"
        action={{
          label: 'Add new Advisor',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN, BACKOFFICER],
        }}></SectionTitle>
      <AdvisorsTable
        isLoading={loading}
        advisors={getAdvisors()}
        actions={[
          { label: 'Deactivate', action: deactivate },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Advisors;
