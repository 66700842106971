import React from 'react';
import { jsx } from '@emotion/core';
import Card from '../card/Card';
import Flex from '../layout/Flex';
import Avatar from '../avatar/Avatar';
import theme from '../../theme/theme';
import Tag from '../tag/Tag';
/**@jsx jsx*/

interface ICustomer {
  fullName: string;
  email: string;
  mobile: string;
}

interface IProp {
  customer: ICustomer;
}

const CustomerCard: React.FC<IProp> = ({ customer }) => {
  return (
    <Card css={{ padding: '24px' }}>
      <Flex css={{ marginBottom: '24px' }} ai="center" jc="space-between">
        <Avatar
          size="sm"
          background={theme.colors.cyan[50]}
          css={{ color: theme.colors.cyan[500] }}
          name={customer.fullName}></Avatar>
        <Tag color="green">Active</Tag>
      </Flex>
      <div
        css={{
          h3: { fontSize: '16px', fontFamily: theme.typography.fonts.semibold },
          p: { color: theme.colors.gray[400], fontSize: '14px' },
        }}>
        <h3>{customer.fullName}</h3>
        <p>{customer.email}</p>
        <p>{customer.mobile}</p>
      </div>
    </Card>
  );
};

export default CustomerCard;
