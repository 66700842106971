import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import Card from '../card/Card';
import CheckboxEx from '../checkbox/CheckboxEx';
import Icon from '../icon/Icon';
import { IIcon, IInputProps } from '../../utils/types';
import theme from '../../theme/theme';
/**@jsx jsx*/

interface IProps extends IInputProps {
  isChecked?: boolean;
  icon?: IIcon;
}

const BoxSelect: React.FC<IProps> = ({
  label,
  isChecked,
  icon,
  name,
  id,
  onChange = () => {},
}) => {
  const [internalIsChecked, setInternalIsChecked] = React.useState(isChecked);

  function handleOnChange(e: any) {
    setInternalIsChecked(e.target.checked);
    onChange(e);
  }
  return (
    <label htmlFor={id}>
      <Card
        css={{
          padding: '16px',
          cursor: 'pointer',
          borderRadius: '4px',
          border: internalIsChecked
            ? `1px solid ${theme.colors.blue[300]}`
            : `1px solid ${theme.colors.gray[200]}`,
        }}>
        <Flex ai="center" jc="space-between">
          <Flex ai="center">
            {icon && (
              <Icon
                color={theme.colors.gray[500]}
                style={{ marginRight: '16px' }}
                icon={['fas', icon.name]}></Icon>
            )}
            <p css={{ fontSize: '14px', color: theme.colors.darkBlue }}>
              {label}
            </p>
          </Flex>
          <CheckboxEx
            onChange={handleOnChange}
            name={name}
            id={id}
            isChecked={isChecked}
          />
        </Flex>
      </Card>
    </label>
  );
};

export default BoxSelect;
