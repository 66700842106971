import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import { IAction } from '../../utils/types';
import Button from '../button/Button';
import Stack from '../stack/Stack';
import theme from '../../theme/theme';
import Avatar from '../avatar/Avatar';
import BreadCrumb from '../breadcrumb/BreadCrumb';
import { IRoute } from '../../routes/routes';
import { useAuthState } from '../../context/auth';
/**@jsx jsx*/

export interface ISectionTitleProps {
  title?: string;
  action?: IAction | IAction[];
  className?: string;
  gap?: number;
  badgeCount?: number;
  useBreadCrumb?: boolean;
  routes?: IRoute[];
}

const SectionTitle: React.FC<ISectionTitleProps> = ({
  title,
  action,
  className,
  gap = 24,
  badgeCount,
  useBreadCrumb = false,
  routes,
}) => {
  const { role } = useAuthState();

  function buildActions() {
    if (!action) {
      return;
    }
    if (Array.isArray(action)) {
      return (
        <Stack isInline>
          {action.map((action, index) => {
            if (!action.allowed?.includes(role as string)) {
              return null;
            }
            return (
              <Button
                className={action.className}
                icon={action.icon}
                appearance={action.buttonType}
                isDisabled={action.disabled}
                isLoading={action.loading}
                path={action.path}
                action={action.function}
                key={index}>
                {action.label}
              </Button>
            );
          })}
        </Stack>
      );
    }

    if (!action.allowed?.includes(role as string)) {
      return;
    }

    return (
      <Button icon={action.icon} path={action.path} action={action.function}>
        {action.label}
      </Button>
    );
  }

  return (
    <Flex
      css={{ marginBottom: gap + 'px' }}
      className={className}
      ai="center"
      jc="space-between">
      <Flex ai="center">
        {useBreadCrumb ? (
          <BreadCrumb routes={routes} />
        ) : (
          <h1
            css={{
              color: theme.colors.darkBlue,
              fontFamily: theme.typography.fonts.semibold,
              fontSize: '16px',
            }}>
            {title}
          </h1>
        )}
        {badgeCount && (
          <Avatar
            css={{
              marginLeft: '16px',
              fontSize: '12px',
              color: theme.colors.white,
            }}
            size="xs"
            background={theme.colors.green[400]}>
            {badgeCount}
          </Avatar>
        )}
      </Flex>
      {action && buildActions()}
    </Flex>
  );
};

export default SectionTitle;
