import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { IProductUnitPrice } from '../../../utils/types';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

type Actions = { label: string; action: (goal: IProductUnitPrice) => void }[];

interface IProps {
  productUnitPrices?: IProductUnitPrice[];
  actions: Actions;
  isLoading?: boolean;
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Product Name',
    dataIndex: 'productName',
    key: 'productName',
  },
  {
    title: 'Unit Price',
    dataIndex: 'unitPrice',
    key: 'unitPrice',
  },
  {
    title: 'Price Date',
    dataIndex: 'priceDate',
    key: 'priceDate',
    render: (priceDate: Date) => {
      return <span>{moment(priceDate).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: Date) => {
      return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Last Changed',
    dataIndex: 'lastChanged',
    key: 'lastChanged',
    render: (lastChanged: Date) => {
      return <span>{moment(lastChanged).format('DD/MM/YYYY')}</span>;
    },
  },
];
const ProductUnitPricesTable: React.FC<IProps> = ({
  productUnitPrices = [],
  actions,
  isLoading,
}) => {
  return (
    <DataTable
      isLoading={isLoading}
      showControls
      showPagination
      data={productUnitPrices}
      columns={columns}
      actions={actions}
      searchFilter="productName"></DataTable>
  );
};

export default ProductUnitPricesTable;
