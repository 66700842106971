import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  faCircleUser,
} from '@fortawesome/free-regular-svg-icons';
import {
  faSortUp,
  faChevronDown,
  faEllipsisH,
  faSortDown,
  faCheck,
  faChevronRight,
  faChevronLeft,
  faAnglesLeft,
  faAnglesRight,
  faSearch,
  faMagnifyingGlass,
  faLock,
  faLockOpen,
  faHashtag,
  faQuestionCircle,
  faCalendar,
  faHouseUser,
  faPlus,
  faSignature,
  faFileDownload,
  faBell,
  faGear,
  faTimes,
  faHomeLg,
  faFileArrowUp,
  faMinus,
  faXmark,
  faUserCircle,
  faRectangleXmark,
  faPencil,
  faIdCard,
  faBuildingColumns,
  faTableColumns,
  faMoneyBill,
  faBuildingUser,
  faPersonWalking,
  faAt,
  faMobileRetro,
  faCertificate
} from '@fortawesome/free-solid-svg-icons';

import theme from '../../theme/theme';

interface IProps extends FontAwesomeIconProps {}

export function startIconLibrary(): void {
  library.add(
    faHomeLg,
    faCircleUser,
    faUserCircle,
    faHouseUser,
    faPlus,
    faSortDown,
    faSortUp,
    faChevronDown,
    faBell,
    faGear,
    faMoneyBill,
    faBuildingUser,
    faCertificate,
    faTableColumns,
    faPersonWalking,
    faAt,
    faMobileRetro,
    faTimes,
    faXmark,
    faBuildingColumns,
    faEllipsisH,
    faCheck,
    faFileArrowUp,
    faChevronLeft,
    faChevronRight,
    faAnglesLeft,
    faAnglesRight,
    faPencil,
    faSearch,
    faMagnifyingGlass,
    faLock,
    faLockOpen,
    faMinus,
    faIdCard,
    faHashtag,
    faCalendar,
    faFileDownload,
    faSignature,
    faQuestionCircle,
    faRectangleXmark
  );
}

const Icon: React.FC<IProps> = ({
  icon,
  size,
  color = theme.colors.gray[600],
  style,
  className,
  ...rest
}) => {
  return (
    <FontAwesomeIcon
      {...rest}
      className={className}
      style={style}
      color={color}
      size={size}
      icon={icon}></FontAwesomeIcon>
  );
};

export default Icon;
