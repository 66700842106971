import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import InputLabel from './components/InputLabel';
import { IInputProps } from '../../utils/types';
import theme from '../../theme/theme';
/**@jsx jsx*/

export const inputStyles: CSSObject = {
  height: '32px',
  width: '100%',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: '4px',
  padding: '0 12px',
  fontSize: '14px',
  overflow: 'hidden',
  background: theme.colors.white,
  appearance: 'none',
  WebkitAppearance: 'none',
  MozAppearance: 'none',
  color: theme.colors.darkBlue,
  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  '&[type=number]': { MozAppearance: 'textfield' },
  '&::placeholder': {
    color: theme.colors.gray[400],
    fontSize: '14px',
  },
  '&:focus': {
    border: `1px solid ${theme.colors.blue[200]}`,
    outline: 'none',
    boxShadow: `0 0 4px rgba(24,144,255,.4)`,
  },
};

export const inputErrorStyles: CSSObject = {
  marginTop: '8px',
  fontSize: '12px',
  color: theme.colors.red[400],
  transition: 'all .5s',
};

const Input: React.FC<IInputProps> = ({
  label,
  value,
  min,
  name,
  placeholder,
  disabled,
  id,
  type = 'text',
  onChange = () => { },
  onBlur = () => { },
  onFocus = () => { },
  onKeyDown = () => { },
  onKeyUp = () => { },
  onKeyPress = () => { },
  className,
  error,
  isNumberInput = false,
  ...rest
}) => {
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (isNumberInput) {
      if (isNaN(Number(e.target.value))) {
        e.preventDefault();
        return;
      }
    }
    onChange(e);
  }

  function handleKeyDown(e: any) {
    onKeyDown(e);
  }

  function handleKeyPress(e: any) {
    onKeyPress(e)
  }

  return (
    <div className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <input
        name={name}
        css={{
          ...inputStyles,
          background: disabled ? theme.colors.gray[100] : theme.colors.white,
        }}
        id={id}
        placeholder={placeholder}
        value={value}
        type={type}
        min={min}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={handleKeyPress}
        disabled={disabled}
        {...rest}
      />
      {error && (
        <p
          css={{
            ...inputErrorStyles,
          }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
