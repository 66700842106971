import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_IndustryMutation,
  useAdd_IndustryMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type IIndustryModal = IModal & {
  industry?: { id: number; name: string };
};

const IndustrySchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const IndustryModal: React.FC<IIndustryModal> = ({ onClose, industry }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addIndustry,
    {
      data: addIndustryResult,
      loading: addIndustryLoading,
      error: addIndustryError,
    },
  ] = useAdd_IndustryMutation();

  const [
    updateIndustry,
    {
      data: updateIndustryResult,
      loading: updateIndustryLoading,
      error: updateIndustryError,
    },
  ] = useUpdate_IndustryMutation();

  React.useEffect(() => {
    if (industry) {
      setInitialValues({
        name: industry.name,
      });
    }
  }, [industry]);

  const addNewIndustry = (values: ValueProps) => {
    addIndustry({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addIndustry,
    });
  };

  const upateExistingIndustry = (values: ValueProps) => {
    updateIndustry({
      variables: {
        id: industry?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (industry) {
      upateExistingIndustry(values);
    } else {
      addNewIndustry(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addIndustryLoading || updateIndustryLoading);
  const error = !!(addIndustryError || updateIndustryError);
  const success = !!(
    addIndustryResult?.insert_industry?.returning?.length ||
    updateIndustryResult?.update_industry?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={IndustrySchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${industry ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${industry ? 'Update' : 'Add'} Industry`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Industry ${industry ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Industry Name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default IndustryModal;
