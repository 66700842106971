import React from 'react';
import { jsx } from '@emotion/core';
import { toast } from 'react-toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import PaymentModal, { PaymentTypes } from './components/ImportPaymentsModal';
import DataTable from '../../components/table/DataTable';
import { IAction } from '../../utils/types';
import { useHistory } from 'react-router-dom';
import { useLoadPaymentsMutation } from '../../generated/graphql';
import { ADMIN } from '../../routes/routes';
import { capitalize, formatStringTo2dp } from '../../utils/helpers';
import Toastify from '../../components/toastify/Toastify';
/**@jsx jsx*/

type RowData = { [key: string]: string };

type NewPaymentsData = {
  business_date: string;
  transaction_amount: string;
  transaction_date: string;
  transaction_details: string;
  value_date: string;
  payment_mode: string;
};

type MigratedPaymentsData = {
  fp_id: string;
  mf_id: string;
  transaction_date: string;
  value_date: string;
  transaction_amount: string;
  split: string;
};

type ImportedData = NewPaymentsData | MigratedPaymentsData;

const newPaymentColumns = [
  {
    title: 'Business Date',
    dataIndex: 'business_date',
    key: 'business_date',
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    key: 'transaction_date',
  },
  {
    title: 'Value Date',
    dataIndex: 'value_date',
    key: 'value_date',
  },
  {
    title: 'Transaction Amount',
    dataIndex: 'transaction_amount',
    key: 'transaction_amount',
  },
  {
    title: 'Transaction Details',
    dataIndex: 'transaction_details',
    key: 'transaction_details',
  },
  {
    title: 'Payment Mode',
    dataIndex: 'payment_mode',
    key: 'payment_mode',
  },
];

const migratedPaymentColumns = [
  {
    title: 'FP ID',
    dataIndex: 'fp_id',
    key: 'fp_id',
  },
  {
    title: 'MF ID',
    dataIndex: 'mf_id',
    key: 'mf_id',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
  },
  {
    title: 'Transaction Date',
    dataIndex: 'transaction_date',
    key: 'transaction_date',
  },
  {
    title: 'Value Date',
    dataIndex: 'value_date',
    key: 'value_date',
  },
  {
    title: 'Amount',
    dataIndex: 'transaction_amount',
    key: 'amount',
  },
  {
    title: 'Split',
    dataIndex: 'split',
    key: 'split',
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
  },
];

function getSplitValues(value: string): string {
  const valueArray = value.split(',');
  const splitValues = [];
  for (const entry of valueArray) {
    const typeAndAmount = entry.split('|');
    splitValues.push(typeAndAmount[1] ? typeAndAmount[1] : '');
  }

  return splitValues.join(', ');
}

function getAccountType(value: string): string {
  const valueArray = value.split(',');
  let type = '';
  if (valueArray.length === 2) {
    type = 'Both';
  }

  if (valueArray.length === 1) {
    const typeAndAmount = valueArray[0].split('|');
    type = capitalize(typeAndAmount[0]) ?? '';
  }

  return type;
}

function notify(success: boolean, message: string) {
  toast(<Toastify {...{ success, message }} />, {
    position: 'top-right',
    autoClose: 3000,
    progressStyle: {
      background: success ? theme.colors.green[200] : theme.colors.red[200],
    },
  });
}

const Payments: React.FC = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const [imported, setImported] = React.useState(false);
  const [paymentFileType, setPaymentFileType] = React.useState<PaymentTypes>(
    'new_payments'
  );
  const [saving, setSaving] = React.useState(false);
  const [data, setData] = React.useState<ImportedData[]>([]);
  const [loadPayments] = useLoadPaymentsMutation();

  const onClose = () => {
    setIsOpen(false);
  };

  const getData = (entries: RowData[]) => {
    let rows: ImportedData[] = [];
    if (paymentFileType === 'new_payments') {
      rows = entries.map((entry) => ({
        business_date: entry['business date']?.trim() ?? '',
        transaction_date: entry['transaction date']?.trim() ?? '',
        value_date: entry['value date']?.trim() ?? '',
        transaction_amount: formatStringTo2dp(
          entry['transaction amount']
        ).toString(),
        transaction_details: entry['transaction details']?.trim() ?? '',
        payment_mode: entry['payment mode']?.trim() ?? '',
      }));
    }
    if (paymentFileType === 'migrated_payments') {
      rows = entries.map((entry) => ({
        fp_id: entry['fp id']?.trim() ?? '',
        mf_id: entry['mf id']?.trim() ?? '',
        value_date: entry['value date']?.trim() ?? '',
        transaction_date: entry['transaction date']?.trim() ?? '',
        transaction_amount: entry['amount']?.trim() ?? '',
        split: getSplitValues(entry['split']?.trim()),
        account: getAccountType(entry['split']?.trim()),
      }));
    }

    setData(rows);
    setImported(true);
  };

  const clearImportedPayments = () => {
    setData([]);
    setImported(false);
  };

  const savePayments = async () => {
    try {
      setSaving(true);
      const is_migrated = paymentFileType === 'migrated_payments';
      const response = await loadPayments({
        variables: {
          data: data.map((entry) => {
            return {
              ...entry,
              transaction_amount: Number(
                entry.transaction_amount.replace(',', '')
              ),
              is_migrated,
            };
          }),
        },
        refetchQueries: ['Payments', 'PaymentShares'],
        awaitRefetchQueries: true,
      });

      if (response?.data?.loadPayments?.success) {
        setSaving(false);
        history.push('/dashboard/list-payments');
        notify(true, 'Payments loaded successfully.');
      } else {
        setSaving(false);
        notify(false, 'Failed to load payments.');
      }
    } catch (error) {
      setSaving(false);
    }
  };

  const actions: IAction | IAction[] = imported
    ? [
        {
          label: 'Save',
          className: 'add-action',
          function: savePayments,
          loading: saving,
          allowed: [ADMIN],
        },
        {
          label: 'Clear',
          className: 'add-action',
          disabled: saving,
          function: clearImportedPayments,
          allowed: [ADMIN],
        },
      ]
    : {
        label: 'Import',
        className: 'add-action',
        icon: { name: 'plus', color: theme.colors.white, position: 'after' },
        function: () => {
          setIsOpen(true);
        },
        allowed: [ADMIN],
      };

  return (
    <React.Fragment>
      {isOpen && (
        <PaymentModal
          {...{
            onClose,
            getData,
            paymentFileType,
            setPaymentFileType,
          }}
        />
      )}
      <SectionTitle title="Payments Preview" action={actions} />
      <DataTable
        showControls
        showPagination
        data={data}
        columns={
          paymentFileType === 'migrated_payments'
            ? migratedPaymentColumns
            : newPaymentColumns
        }
        actions={[
          { label: 'Delete', action: () => {} },
          { label: 'Edit', action: () => {} },
        ]}
        searchFilter="value_date"
      />
    </React.Fragment>
  );
};

export default Payments;
