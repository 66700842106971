import React from 'react';
import { jsx } from '@emotion/core';
import InputLabel from '../input/components/InputLabel';
import { ISelectOption, IInputProps } from '../../utils/types';
import { inputStyles } from '../input/Input';
import Icon from '../icon/Icon';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
/**@jsx jsx*/

interface IProps extends IInputProps {
  options?: ISelectOption[];
  children?: React.ReactNode;
}

const NativeSelect = React.forwardRef<HTMLSelectElement, IProps>(
  ({ label, className, children, ...rest }, ref) => (
    <div css={{ width: '100%' }} className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <div css={{ width: '100%', position: 'relative' }}>
        <select
          ref={ref}
          css={{
            ...inputStyles,
            cursor: 'pointer',
            color: theme.colors.gray[700],
          }}
          {...rest}>
          {children}
        </select>
        <Flex
          ai="center"
          jc="center"
          css={{
            position: 'absolute',
            height: '32px',
            width: '32px',
            top: 0,
            right: 0,
            pointerEvents: 'none',
          }}>
          <Icon
            color={theme.colors.gray[400]}
            size="xs"
            icon={['fas', 'chevron-down']}></Icon>
        </Flex>
      </div>
    </div>
  )
);

export default NativeSelect;
