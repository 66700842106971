import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import IdentificationTypesTable from './components/IdentificationTypesTable';
import IdentificationTypeModal from './components/IdentificationTypeModal';
import {
  useIdentificationTypeQuery,
  useDelete_Identification_TypeMutation,
} from '../../generated/graphql';
import { IdentificationType } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const IdentificationTypes: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [
    selectedIdentificationType,
    setSelectedIdentificationType,
  ] = React.useState<number | undefined>();
  const { data: identificationTypes, loading } = useIdentificationTypeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteIdentificationType] = useDelete_Identification_TypeMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedIdentificationType(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (identificationType: IdentificationType) => {
    setIsOpen(true);
    setSelectedIdentificationType(identificationType.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    try {
      const response = await deleteIdentificationType({
        variables: {
          id: selectedIdentificationType,
          doneBy: userId,
        },
        update: UpdateHandlers.deleteIdentificationType,
      });
      setShowConfirm(false);

      if (response.data?.update_identification_type?.affected_rows) {
        notify(true, 'Identification Type deleted successfully.');
      } else {
        notify(false, 'failed to delete identification type.');
      }
    } catch (err) {}
  };

  const confirm = (identificationType: IdentificationType) => {
    setSelectedIdentificationType(identificationType.id);
    setShowConfirm(true);
  };

  const getIdentificationType = (id?: number) => {
    if (id) {
      const identificationType = identificationTypes?.identification_type?.find(
        (identificationType) => identificationType.id === id
      );
      if (identificationType) {
        return {
          id: identificationType.id,
          name: identificationType.name,
        };
      }
    }
    return;
  };

  const getIdentificationTypes = (): IdentificationType[] => {
    return (
      identificationTypes?.identification_type?.map((identificationType) => ({
        id: identificationType.id,
        name: identificationType.name,
        createdAt: identificationType.created_at,
        lastChanged: identificationType.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <IdentificationTypeModal
          onClose={close}
          identificationType={getIdentificationType(selectedIdentificationType)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Identification Type"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Identification Types"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <IdentificationTypesTable
        isLoading={loading}
        identificationTypes={getIdentificationTypes()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default IdentificationTypes;
