import { MutationUpdaterFn } from 'apollo-client';
import jwt from 'jwt-decode';
import {
  GoalsQuery,
  GoalsDocument,
  Add_GoalMutation,
  Add_GenderMutation,
  GendersQuery,
  GendersDocument,
  PaymentProviderDocument,
  PaymentProviderQuery,
  Add_Payment_ProviderMutation,
  Update_Payment_ProviderMutation,
  Add_RelationshipMutation,
  RelationshipsDocument,
  RelationshipsQuery,
  Add_TitleMutation,
  TitlesQuery,
  TitlesDocument,
  Add_IndustryMutation,
  IndustriesQuery,
  IndustriesDocument,
  Delete_GenderMutation,
  Delete_GoalMutation,
  Delete_IndustryMutation,
  Delete_Payment_ProviderMutation,
  Delete_RelationshipMutation,
  Delete_TitleMutation,
  MeQuery,
  MeDocument,
  LoginMutation,
  PasswordResetMutation,
  AssetDurationDocument,
  AssetDurationQuery,
  Add_Asset_DurationMutation,
  Update_Asset_DurationMutation,
  Delete_Asset_DurationMutation,
  AssetAllocationDocument,
  AssetAllocationQuery,
  Add_Asset_AllocationMutation,
  Update_Asset_AllocationMutation,
  Delete_Asset_AllocationMutation,
  AdvisorsDocument,
  AdvisorsQuery,
  Add_AdvisorMutation,
  Update_AdvisorMutation,
  Reject_ProspectMutation,
  ProspectDocument,
  ProspectQuery,
  UsersDocument,
  UsersQuery,
  Update_UserMutation,
  Delete_UserMutation,
  ProductUnitPriceQuery,
  ProductUnitPriceDocument,
  Delete_Product_Unit_PriceMutation,
  Add_Product_Unit_PriceMutation,
  Delete_ProductMutation,
  ProductDocument,
  ProductQuery,
  Add_ProductMutation,
  Add_ApkMutation,
  ApksQuery,
  ApksDocument,
  Delete_ApkMutation,
  Delete_Identification_TypeMutation,
  IdentificationTypeQuery,
  IdentificationTypeDocument,
  Delete_Income_SourceMutation,
  IncomeSourceQuery,
  IncomeSourceDocument,
  Add_Identification_TypeMutation,
  Add_Income_SourceMutation,
  Add_Contribution_SourceMutation,
  ContributionSourceQuery,
  ContributionSourceDocument,
  Delete_Contribution_SourceMutation,
  Add_Marital_StatusMutation,
  MaritalStatusQuery,
  MaritalStatusDocument,
  Delete_Marital_StatusMutation,
  Add_Insurance_TypeMutation,
  Insurance_TypeQuery,
  Insurance_TypeDocument,
  Delete_Insurance_TypeMutation,
} from '../generated/graphql';

const loginUpdateHandler: MutationUpdaterFn<LoginMutation> = (
  cache,
  { data }
) => {
  const decodedToken: any = jwt(data?.login.accessToken!);
  cache.writeQuery<MeQuery>({
    query: MeDocument,
    data: {
      me: {
        __typename: 'MeResponse',
        user_type: {
          __typename: 'UserType',
          id: data?.login.id,
          guid: decodedToken.sub,
          fullname: decodedToken.name,
          email: decodedToken.email,
        },
      },
    },
  });
};

const resetPasswordUpdateHandler: MutationUpdaterFn<PasswordResetMutation> = (
  cache,
  { data }
) => {
  const decodedToken: any = jwt(data?.resetPassword.accessToken!);
  cache.writeQuery<MeQuery>({
    query: MeDocument,
    data: {
      me: {
        __typename: 'MeResponse',
        user_type: {
          __typename: 'UserType',
          guid: decodedToken.sub,
          fullname: decodedToken.name,
          email: decodedToken.email,
        },
      },
    },
  });
};

const addGoalUpdateHandler: MutationUpdaterFn<Add_GoalMutation> = (
  cache,
  { data }
) => {
  try {
    const newGoal = data?.insert_goal?.returning[0];
    if (!newGoal) return;

    const oldData = cache.readQuery<GoalsQuery>({
      query: GoalsDocument,
    });

    let newData: typeof newGoal[] | undefined = [];
    const exists = oldData?.goal?.find((goal) => goal.id === newGoal.id);
    if (exists) {
      newData = oldData?.goal?.map((goal) => {
        if (goal.id === newGoal.id) {
          return newGoal;
        }
        return goal;
      });
    } else {
      newData = oldData?.goal.concat([newGoal]);
    }

    cache.writeQuery({
      query: GoalsDocument,
      data: {
        goal: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addProductUpdateHandler: MutationUpdaterFn<Add_ProductMutation> = (
  cache,
  { data }
) => {
  try {
    const newProduct = data?.insert_product?.returning[0];
    if (!newProduct) return;

    const oldData = cache.readQuery<ProductQuery>({
      query: ProductDocument,
    });

    let newData: typeof newProduct[] | undefined = [];
    const exists = oldData?.product?.find(
      (product) => product.id === newProduct.id
    );
    if (exists) {
      newData = oldData?.product?.map((product) => {
        if (product.id === newProduct.id) {
          return newProduct;
        }
        return product;
      });
    } else {
      newData = oldData?.product.concat([newProduct]);
    }

    cache.writeQuery({
      query: ProductDocument,
      data: {
        product: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addGenderUpdateHandler: MutationUpdaterFn<Add_GenderMutation> = (
  cache,
  { data }
) => {
  try {
    const newGender = data?.insert_gender?.returning[0];
    if (!newGender) return;

    const oldData = cache.readQuery<GendersQuery>({
      query: GendersDocument,
    });

    let newData: typeof newGender[] | undefined = [];
    const exists = oldData?.gender?.find(
      (gender) => gender.id === newGender.id
    );
    if (exists) {
      newData = oldData?.gender?.map((gender) => {
        if (gender.id === newGender.id) {
          return newGender;
        }
        return gender;
      });
    } else {
      newData = oldData?.gender.concat([newGender]);
    }

    cache.writeQuery({
      query: GendersDocument,
      data: {
        gender: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addIdentificationTypeUpdateHandler: MutationUpdaterFn<Add_Identification_TypeMutation> = (
  cache,
  { data }
) => {
  try {
    const newIdentificationType =
      data?.insert_identification_type?.returning[0];
    if (!newIdentificationType) return;

    const oldData = cache.readQuery<IdentificationTypeQuery>({
      query: IdentificationTypeDocument,
    });

    let newData: typeof newIdentificationType[] | undefined = [];
    const exists = oldData?.identification_type?.find(
      (identificationType) => identificationType.id === newIdentificationType.id
    );
    if (exists) {
      newData = oldData?.identification_type?.map((identificationType) => {
        if (identificationType.id === newIdentificationType.id) {
          return newIdentificationType;
        }
        return identificationType;
      });
    } else {
      newData = oldData?.identification_type.concat([newIdentificationType]);
    }

    cache.writeQuery({
      query: IdentificationTypeDocument,
      data: {
        identification_type: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addIncomeSourceUpdateHandler: MutationUpdaterFn<Add_Income_SourceMutation> = (
  cache,
  { data }
) => {
  try {
    const newIncomeSource = data?.insert_income_source?.returning[0];
    if (!newIncomeSource) return;

    const oldData = cache.readQuery<IncomeSourceQuery>({
      query: IncomeSourceDocument,
    });

    let newData: typeof newIncomeSource[] | undefined = [];
    const exists = oldData?.income_source?.find(
      (incomeSource) => incomeSource.id === newIncomeSource.id
    );
    if (exists) {
      newData = oldData?.income_source?.map((incomeSource) => {
        if (incomeSource.id === newIncomeSource.id) {
          return newIncomeSource;
        }
        return incomeSource;
      });
    } else {
      newData = oldData?.income_source.concat([newIncomeSource]);
    }

    cache.writeQuery({
      query: IncomeSourceDocument,
      data: {
        income_source: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addContributionSourceUpdateHandler: MutationUpdaterFn<Add_Contribution_SourceMutation> = (
  cache,
  { data }
) => {
  try {
    const newContributionSource =
      data?.insert_contribution_source?.returning[0];
    if (!newContributionSource) return;

    const oldData = cache.readQuery<ContributionSourceQuery>({
      query: ContributionSourceDocument,
    });

    let newData: typeof newContributionSource[] | undefined = [];
    const exists = oldData?.contribution_source?.find(
      (contributionSource) => contributionSource.id === newContributionSource.id
    );
    if (exists) {
      newData = oldData?.contribution_source?.map((contributionSource) => {
        if (contributionSource.id === newContributionSource.id) {
          return newContributionSource;
        }
        return contributionSource;
      });
    } else {
      newData = oldData?.contribution_source.concat([newContributionSource]);
    }

    cache.writeQuery({
      query: ContributionSourceDocument,
      data: {
        contribution_source: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addMaritalStatusUpdateHandler: MutationUpdaterFn<Add_Marital_StatusMutation> = (
  cache,
  { data }
) => {
  try {
    const newMaritalStatus = data?.insert_marital_status?.returning[0];
    if (!newMaritalStatus) return;

    const oldData = cache.readQuery<MaritalStatusQuery>({
      query: MaritalStatusDocument,
    });

    let newData: typeof newMaritalStatus[] | undefined = [];
    const exists = oldData?.marital_status?.find(
      (maritalStatus) => maritalStatus.id === newMaritalStatus.id
    );
    if (exists) {
      newData = oldData?.marital_status?.map((maritalStatus) => {
        if (maritalStatus.id === newMaritalStatus.id) {
          return newMaritalStatus;
        }
        return maritalStatus;
      });
    } else {
      newData = oldData?.marital_status.concat([newMaritalStatus]);
    }

    cache.writeQuery({
      query: MaritalStatusDocument,
      data: {
        marital_status: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addInsuranceTypeUpdateHandler: MutationUpdaterFn<Add_Insurance_TypeMutation> = (
  cache,
  { data }
) => {
  try {
    const newInsuranceType = data?.insert_insurance_type?.returning[0];
    if (!newInsuranceType) return;

    const oldData = cache.readQuery<Insurance_TypeQuery>({
      query: Insurance_TypeDocument,
    });

    let newData: typeof newInsuranceType[] | undefined = [];
    const exists = oldData?.insurance_type?.find(
      (insuranceType) => insuranceType.id === newInsuranceType.id
    );
    if (exists) {
      newData = oldData?.insurance_type?.map((insuranceType) => {
        if (insuranceType.id === newInsuranceType.id) {
          return newInsuranceType;
        }
        return insuranceType;
      });
    } else {
      newData = oldData?.insurance_type.concat([newInsuranceType]);
    }

    cache.writeQuery({
      query: Insurance_TypeDocument,
      data: {
        insurance_type: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addApkUpdateHandler: MutationUpdaterFn<Add_ApkMutation> = (
  cache,
  { data }
) => {
  try {
    const newApk = data?.insert_apk?.returning[0];
    if (!newApk) return;

    const oldData = cache.readQuery<ApksQuery>({
      query: ApksDocument,
    });

    let newData: typeof newApk[] | undefined = [];
    const exists = oldData?.apk?.find((apk) => apk.id === newApk.id);
    if (exists) {
      newData = oldData?.apk?.map((apk) => {
        if (apk.id === newApk.id) {
          return newApk;
        }
        return apk;
      });
    } else {
      newData = oldData?.apk.concat([newApk]);
    }

    cache.writeQuery({
      query: ApksDocument,
      data: {
        apk: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addPaymentProviderUpdateHandler: MutationUpdaterFn<Add_Payment_ProviderMutation> = (
  cache,
  { data }
) => {
  try {
    const newPaymentProvider = data?.insert_payment_provider?.returning[0];
    if (!newPaymentProvider) return;

    const oldData = cache.readQuery<PaymentProviderQuery>({
      query: PaymentProviderDocument,
    });

    let newData: typeof newPaymentProvider[] | undefined = [];
    const exists = oldData?.payment_provider?.find(
      (provider) => provider.id === newPaymentProvider.id
    );

    if (exists) {
      newData = oldData?.payment_provider?.map((provider) => {
        if (provider.id === newPaymentProvider.id) {
          return newPaymentProvider;
        }
        return provider;
      });
    } else {
      newData = oldData?.payment_provider.concat([newPaymentProvider]);
    }

    cache.writeQuery({
      query: PaymentProviderDocument,
      data: {
        payment_provider: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const updatePaymentProviderUpdateHandler: MutationUpdaterFn<Update_Payment_ProviderMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedPaymentProvider = data?.update_payment_provider?.returning[0];
    if (!updatedPaymentProvider) return;

    const oldData = cache.readQuery<PaymentProviderQuery>({
      query: PaymentProviderDocument,
    });

    const updatedData = oldData?.payment_provider?.map((provider) => {
      if (provider.id === updatedPaymentProvider.id) {
        return updatedPaymentProvider;
      }

      return provider;
    });

    cache.writeQuery({
      query: PaymentProviderDocument,
      data: {
        payment_provider: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addRelationshipUpdateHandler: MutationUpdaterFn<Add_RelationshipMutation> = (
  cache,
  { data }
) => {
  try {
    const newRelationship = data?.insert_relationship?.returning[0];
    if (!newRelationship) return;

    const oldData = cache.readQuery<RelationshipsQuery>({
      query: RelationshipsDocument,
    });

    let newData: typeof newRelationship[] | undefined = [];
    const exists = oldData?.relationship?.find(
      (relationship) => relationship.id === newRelationship.id
    );
    if (exists) {
      newData = oldData?.relationship?.map((relationship) => {
        if (relationship.id === newRelationship.id) {
          return newRelationship;
        }
        return relationship;
      });
    } else {
      newData = oldData?.relationship.concat([newRelationship]);
    }

    cache.writeQuery({
      query: RelationshipsDocument,
      data: {
        relationship: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addTitleUpdateHandler: MutationUpdaterFn<Add_TitleMutation> = (
  cache,
  { data }
) => {
  try {
    const newTitle = data?.insert_title?.returning[0];
    if (!newTitle) return;

    const oldData = cache.readQuery<TitlesQuery>({
      query: TitlesDocument,
    });

    let newData: typeof newTitle[] | undefined = [];
    const exists = oldData?.title?.find((title) => title.id === newTitle.id);
    if (exists) {
      newData = oldData?.title?.map((title) => {
        if (title.id === newTitle.id) {
          return newTitle;
        }
        return title;
      });
    } else {
      newData = oldData?.title.concat([newTitle]);
    }

    cache.writeQuery({
      query: TitlesDocument,
      data: {
        title: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addIndustryUpdateHandler: MutationUpdaterFn<Add_IndustryMutation> = (
  cache,
  { data }
) => {
  try {
    const newIndustry = data?.insert_industry?.returning[0];
    if (!newIndustry) return;

    const oldData = cache.readQuery<IndustriesQuery>({
      query: IndustriesDocument,
    });

    let newData: typeof newIndustry[] | undefined = [];
    const exists = oldData?.industry?.find(
      (industry) => industry.id === newIndustry.id
    );
    if (exists) {
      newData = oldData?.industry?.map((industry) => {
        if (industry.id === newIndustry.id) {
          return newIndustry;
        }
        return industry;
      });
    } else {
      newData = oldData?.industry.concat([newIndustry]);
    }

    cache.writeQuery({
      query: IndustriesDocument,
      data: {
        industry: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addProductUnitPriceHandler: MutationUpdaterFn<Add_Product_Unit_PriceMutation> = (
  cache,
  { data }
) => {
  try {
    const newPrice = data?.insert_price?.returning[0];
    if (!newPrice) return;

    const oldData = cache.readQuery<ProductUnitPriceQuery>({
      query: ProductUnitPriceDocument,
    });

    let newData: typeof newPrice[] | undefined = [];
    const exists = oldData?.price?.find((price) => price.id === newPrice.id);
    if (exists) {
      newData = oldData?.price?.map((price) => {
        if (price.id === newPrice.id) {
          return newPrice;
        }
        return price;
      });
    } else {
      newData = oldData?.price.concat([newPrice]);
    }

    cache.writeQuery({
      query: ProductUnitPriceDocument,
      data: {
        price: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteGenderUpdateHandler: MutationUpdaterFn<Delete_GenderMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedGender = data?.update_gender?.returning[0];
    if (!deletedGender) return;

    const oldData = cache.readQuery<GendersQuery>({
      query: GendersDocument,
    });

    const dataLeft = oldData?.gender?.filter(
      (gender) => gender.id !== deletedGender.id
    );

    cache.writeQuery({
      query: GendersDocument,
      data: {
        gender: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteIdentificationTypeUpdateHandler: MutationUpdaterFn<Delete_Identification_TypeMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedIdentificationType =
      data?.update_identification_type?.returning[0];
    if (!deletedIdentificationType) return;

    const oldData = cache.readQuery<IdentificationTypeQuery>({
      query: IdentificationTypeDocument,
    });

    const dataLeft = oldData?.identification_type?.filter(
      (identificationType) =>
        identificationType.id !== deletedIdentificationType.id
    );

    cache.writeQuery({
      query: IdentificationTypeDocument,
      data: {
        identification_type: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteIncomeSourceUpdateHandler: MutationUpdaterFn<Delete_Income_SourceMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedIncomeSource = data?.update_income_source?.returning[0];
    if (!deletedIncomeSource) return;

    const oldData = cache.readQuery<IncomeSourceQuery>({
      query: IncomeSourceDocument,
    });

    const dataLeft = oldData?.income_source?.filter(
      (incomeSource) => incomeSource.id !== deletedIncomeSource.id
    );

    cache.writeQuery({
      query: IncomeSourceDocument,
      data: {
        income_source: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteContributionSourceUpdateHandler: MutationUpdaterFn<Delete_Contribution_SourceMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedContributionSource =
      data?.update_contribution_source?.returning[0];
    if (!deletedContributionSource) return;

    const oldData = cache.readQuery<ContributionSourceQuery>({
      query: ContributionSourceDocument,
    });

    const dataLeft = oldData?.contribution_source?.filter(
      (contributionSource) =>
        contributionSource.id !== deletedContributionSource.id
    );

    cache.writeQuery({
      query: ContributionSourceDocument,
      data: {
        contribution_source: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteMaritalStatusUpdateHandler: MutationUpdaterFn<Delete_Marital_StatusMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedMaritalStatus = data?.update_marital_status?.returning[0];
    if (!deletedMaritalStatus) return;

    const oldData = cache.readQuery<MaritalStatusQuery>({
      query: MaritalStatusDocument,
    });

    const dataLeft = oldData?.marital_status?.filter(
      (maritalStatus) => maritalStatus.id !== deletedMaritalStatus.id
    );

    cache.writeQuery({
      query: MaritalStatusDocument,
      data: {
        marital_status: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteInsuranceTypeUpdateHandler: MutationUpdaterFn<Delete_Insurance_TypeMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedInsuranceType = data?.update_insurance_type?.returning[0];
    if (!deletedInsuranceType) return;

    const oldData = cache.readQuery<Insurance_TypeQuery>({
      query: Insurance_TypeDocument,
    });

    const dataLeft = oldData?.insurance_type?.filter(
      (insuranceType) => insuranceType.id !== deletedInsuranceType.id
    );

    cache.writeQuery({
      query: Insurance_TypeDocument,
      data: {
        insurance_type: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteApkUpdateHandler: MutationUpdaterFn<Delete_ApkMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedApk = data?.update_apk?.returning[0];
    if (!deletedApk) return;

    const oldData = cache.readQuery<ApksQuery>({
      query: ApksDocument,
    });

    const dataLeft = oldData?.apk?.filter((apk) => apk.id !== deletedApk.id);

    cache.writeQuery({
      query: ApksDocument,
      data: {
        apk: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteGoalUpdateHandler: MutationUpdaterFn<Delete_GoalMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedGoal = data?.update_goal?.returning[0];
    if (!deletedGoal) return;

    const oldData = cache.readQuery<GoalsQuery>({
      query: GoalsDocument,
    });

    const dataLeft = oldData?.goal?.filter(
      (goal) => goal.id !== deletedGoal.id
    );

    cache.writeQuery({
      query: GoalsDocument,
      data: {
        goal: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteProductUpdateHandler: MutationUpdaterFn<Delete_ProductMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedProduct = data?.update_product?.returning[0];
    if (!deletedProduct) return;

    const oldData = cache.readQuery<ProductQuery>({
      query: ProductDocument,
    });

    const dataLeft = oldData?.product?.filter(
      (product) => product.id !== deletedProduct.id
    );

    cache.writeQuery({
      query: ProductDocument,
      data: {
        product: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteIndustryUpdateHandler: MutationUpdaterFn<Delete_IndustryMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedIndustry = data?.update_industry?.returning[0];
    if (!deletedIndustry) return;

    const oldData = cache.readQuery<IndustriesQuery>({
      query: IndustriesDocument,
    });

    const dataLeft = oldData?.industry?.filter(
      (industry) => industry.id !== deletedIndustry.id
    );

    cache.writeQuery({
      query: IndustriesDocument,
      data: {
        industry: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deletePaymentProviderUpdateHandler: MutationUpdaterFn<Delete_Payment_ProviderMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedPaymentProvider = data?.update_payment_provider?.returning[0];
    if (!deletedPaymentProvider) return;

    const oldData = cache.readQuery<PaymentProviderQuery>({
      query: PaymentProviderDocument,
    });

    const dataLeft = oldData?.payment_provider?.filter(
      (provider) => provider.id !== deletedPaymentProvider.id
    );

    cache.writeQuery({
      query: PaymentProviderDocument,
      data: {
        payment_provider: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteRelationshipUpdateHandler: MutationUpdaterFn<Delete_RelationshipMutation> = (
  cache,
  { data }
) => {
  try {
    const deleteRelationship = data?.update_relationship?.returning[0];
    if (!deleteRelationship) return;

    const oldData = cache.readQuery<RelationshipsQuery>({
      query: RelationshipsDocument,
    });

    const dataLeft = oldData?.relationship?.filter(
      (relationship) => relationship.id !== deleteRelationship.id
    );

    cache.writeQuery({
      query: RelationshipsDocument,
      data: {
        relationship: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteTitleUpdateHandler: MutationUpdaterFn<Delete_TitleMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedTitle = data?.update_title?.returning[0];
    if (!deletedTitle) return;

    const oldData = cache.readQuery<TitlesQuery>({
      query: TitlesDocument,
    });

    const dataLeft = oldData?.title?.filter(
      (title) => title.id !== deletedTitle.id
    );

    cache.writeQuery({
      query: TitlesDocument,
      data: {
        title: dataLeft,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addAssetDurationUpdateHandler: MutationUpdaterFn<Add_Asset_DurationMutation> = (
  cache,
  { data }
) => {
  try {
    const newAssetDuration = data?.insert_asset_duration?.returning[0];
    if (!newAssetDuration) return;

    const oldData = cache.readQuery<AssetDurationQuery>({
      query: AssetDurationDocument,
    });

    let newData: typeof newAssetDuration[] | undefined = [];
    const exists = oldData?.asset_duration?.find(
      (duration) => duration.id === newAssetDuration.id
    );

    if (exists) {
      newData = oldData?.asset_duration?.map((duration) => {
        if (duration.id === newAssetDuration.id) {
          return newAssetDuration;
        }
        return duration;
      });
    } else {
      newData = oldData?.asset_duration.concat([newAssetDuration]);
    }

    cache.writeQuery({
      query: AssetDurationDocument,
      data: {
        asset_duration: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const updateAssetDurationUpdateHandler: MutationUpdaterFn<Update_Asset_DurationMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedAssetDuration = data?.update_asset_duration?.returning[0];
    if (!updatedAssetDuration) return;

    const oldData = cache.readQuery<AssetDurationQuery>({
      query: AssetDurationDocument,
    });

    const updatedData = oldData?.asset_duration?.map((duration) => {
      if (duration.id === updatedAssetDuration.id) {
        return updatedAssetDuration;
      }

      return duration;
    });

    cache.writeQuery({
      query: AssetDurationDocument,
      data: {
        asset_duration: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteAssetDurationHandler: MutationUpdaterFn<Delete_Asset_DurationMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedAssetDuration = data?.update_asset_duration?.returning[0];
    if (!deletedAssetDuration) return;

    const oldData = cache.readQuery<AssetDurationQuery>({
      query: AssetDurationDocument,
    });

    const updatedData = oldData?.asset_duration.filter(
      (duration) => duration.id !== deletedAssetDuration.id
    );

    cache.writeQuery({
      query: AssetDurationDocument,
      data: {
        asset_duration: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addAssetAllocationUpdateHandler: MutationUpdaterFn<Add_Asset_AllocationMutation> = (
  cache,
  { data }
) => {
  try {
    const newAssetAllocation = data?.insert_asset_allocation?.returning[0];
    if (!newAssetAllocation) return;

    const oldData = cache.readQuery<AssetAllocationQuery>({
      query: AssetAllocationDocument,
    });

    let newData: typeof newAssetAllocation[] | undefined = [];
    const exists = oldData?.asset_allocation?.find(
      (allocation) => allocation.id === newAssetAllocation.id
    );

    if (exists) {
      newData = oldData?.asset_allocation?.map((allocation) => {
        if (allocation.id === newAssetAllocation.id) {
          return newAssetAllocation;
        }
        return allocation;
      });
    } else {
      newData = oldData?.asset_allocation.concat([newAssetAllocation]);
    }

    cache.writeQuery({
      query: AssetAllocationDocument,
      data: {
        asset_allocation: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const updateAssetAllocationUpdateHandler: MutationUpdaterFn<Update_Asset_AllocationMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedAssetAllocation = data?.update_asset_allocation?.returning[0];
    if (!updatedAssetAllocation) return;

    const oldData = cache.readQuery<AssetDurationQuery>({
      query: AssetDurationDocument,
    });

    const updatedData = oldData?.asset_duration?.map((allocation) => {
      if (allocation.id === updatedAssetAllocation.id) {
        return updatedAssetAllocation;
      }

      return allocation;
    });

    cache.writeQuery({
      query: AssetAllocationDocument,
      data: {
        asset_allocation: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteAssetAllocationHandler: MutationUpdaterFn<Delete_Asset_AllocationMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedAssetAllocation = data?.update_asset_allocation?.returning[0];
    if (!deletedAssetAllocation) return;

    const oldData = cache.readQuery<AssetAllocationQuery>({
      query: AssetAllocationDocument,
    });

    const updatedData = oldData?.asset_allocation.filter(
      (allocation) => allocation.id !== deletedAssetAllocation.id
    );

    cache.writeQuery({
      query: AssetAllocationDocument,
      data: {
        asset_allocation: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const addAdvisorUpdateHandler: MutationUpdaterFn<Add_AdvisorMutation> = (
  cache,
  { data }
) => {
  try {
    const newAdvisor = data?.insert_advisor?.returning[0];
    if (!newAdvisor) return;

    const oldData = cache.readQuery<AdvisorsQuery>({
      query: AdvisorsDocument,
    });

    let newData: typeof newAdvisor[] | undefined = [];
    const exists = oldData?.advisor?.find(
      (advisor) => advisor.id === newAdvisor.id
    );

    if (exists) {
      newData = oldData?.advisor?.map((advisor) => {
        if (advisor.id === newAdvisor.id) {
          return newAdvisor;
        }
        return advisor;
      });
    } else {
      newData = oldData?.advisor.concat([newAdvisor]);
    }

    cache.writeQuery({
      query: AdvisorsDocument,
      data: {
        advisor: newData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const updateAdvisorUpdateHandler: MutationUpdaterFn<Update_AdvisorMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedAdvisor = data?.update_advisor?.returning[0];
    if (!updatedAdvisor) return;

    const oldData = cache.readQuery<AdvisorsQuery>({
      query: AdvisorsDocument,
    });

    const updatedData = oldData?.advisor?.map((advisor) => {
      if (advisor.id === updatedAdvisor.id) {
        return updatedAdvisor;
      }

      return advisor;
    });

    cache.writeQuery({
      query: AdvisorsDocument,
      data: {
        advisor: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteAdvisorUpdateHandler: MutationUpdaterFn<Delete_UserMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedAdvisor = data?.update_user?.returning[0];
    if (!deletedAdvisor) return;

    const oldData = cache.readQuery<AdvisorsQuery>({
      query: AdvisorsDocument,
    });

    const updatedData = oldData?.advisor.filter(
      (advisor) => advisor.id !== deletedAdvisor.id
    );

    cache.writeQuery({
      query: AdvisorsDocument,
      data: {
        advisor: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const rejectProspectUpdateHandler: MutationUpdaterFn<Reject_ProspectMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedMember = data?.update_member?.returning[0];
    if (!updatedMember) return;

    const oldData = cache.readQuery<ProspectQuery>({
      query: ProspectDocument,
    });

    const updatedData = oldData?.member?.map((member) => {
      if (member.id === updatedMember.id) {
        return updatedMember;
      }

      return member;
    });

    cache.writeQuery({
      query: ProspectDocument,
      data: {
        member: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const updateUserUpdateHandler: MutationUpdaterFn<Update_UserMutation> = (
  cache,
  { data }
) => {
  try {
    const updatedUser = data?.update_user?.returning[0];
    if (!updatedUser) return;

    const oldData = cache.readQuery<UsersQuery>({
      query: UsersDocument,
    });

    const updatedData = oldData?.user?.map((user) => {
      if (user.id === updatedUser.id) {
        return updatedUser;
      }

      return user;
    });

    cache.writeQuery({
      query: UsersDocument,
      data: {
        advisor: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteUserUpdateHandler: MutationUpdaterFn<Delete_UserMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedUser = data?.update_user?.returning[0];
    if (!deletedUser) return;

    const oldData = cache.readQuery<UsersQuery>({
      query: UsersDocument,
    });

    const updatedData = oldData?.user.filter(
      (user) => user.id !== deletedUser.id
    );

    cache.writeQuery({
      query: UsersDocument,
      data: {
        user: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

const deleteProductUnitPriceHandler: MutationUpdaterFn<Delete_Product_Unit_PriceMutation> = (
  cache,
  { data }
) => {
  try {
    const deletedPrice = data?.delete_price?.returning[0];
    if (!deletedPrice) return;

    const oldData = cache.readQuery<ProductUnitPriceQuery>({
      query: ProductUnitPriceDocument,
    });

    const updatedData = oldData?.price.filter(
      (price) => price.id !== deletedPrice.id
    );

    cache.writeQuery({
      query: ProductUnitPriceDocument,
      data: {
        price: updatedData,
      },
    });
  } catch (err) {
    console.log('err ', err);
  }
};

export default {
  login: loginUpdateHandler,
  resetPassword: resetPasswordUpdateHandler,
  addGoal: addGoalUpdateHandler,
  addProduct: addProductUpdateHandler,
  addGender: addGenderUpdateHandler,
  addIdentificationType: addIdentificationTypeUpdateHandler,
  addIncomeSource: addIncomeSourceUpdateHandler,
  addContributionSource: addContributionSourceUpdateHandler,
  addMaritalStatus: addMaritalStatusUpdateHandler,
  addInsuranceType: addInsuranceTypeUpdateHandler,
  addApk: addApkUpdateHandler,
  addPaymentProvider: addPaymentProviderUpdateHandler,
  updatePaymentProvider: updatePaymentProviderUpdateHandler,
  addRelationship: addRelationshipUpdateHandler,
  addTitle: addTitleUpdateHandler,
  addIndustry: addIndustryUpdateHandler,
  deleteGender: deleteGenderUpdateHandler,
  deleteIdentificationType: deleteIdentificationTypeUpdateHandler,
  deleteIncomeSource: deleteIncomeSourceUpdateHandler,
  deleteContributionSource: deleteContributionSourceUpdateHandler,
  deleteMaritalStatus: deleteMaritalStatusUpdateHandler,
  deleteInsuranceType: deleteInsuranceTypeUpdateHandler,
  deleteApk: deleteApkUpdateHandler,
  deleteGoal: deleteGoalUpdateHandler,
  deleteProduct: deleteProductUpdateHandler,
  deleteIndustry: deleteIndustryUpdateHandler,
  deletePaymentProvider: deletePaymentProviderUpdateHandler,
  deleteRelationship: deleteRelationshipUpdateHandler,
  deleteTitle: deleteTitleUpdateHandler,
  addAssetDuration: addAssetDurationUpdateHandler,
  updateAssetDuration: updateAssetDurationUpdateHandler,
  deleteAssetDuration: deleteAssetDurationHandler,
  addAssetAllocation: addAssetAllocationUpdateHandler,
  updateAssetAllocation: updateAssetAllocationUpdateHandler,
  deleteAssetAllocation: deleteAssetAllocationHandler,
  addAdvisor: addAdvisorUpdateHandler,
  updateAdvisor: updateAdvisorUpdateHandler,
  deleteAdvisor: deleteAdvisorUpdateHandler,
  rejectProspect: rejectProspectUpdateHandler,
  updateUser: updateUserUpdateHandler,
  deleteUser: deleteUserUpdateHandler,
  addProductUnitPrice: addProductUnitPriceHandler,
  deleteProductUnitPrice: deleteProductUnitPriceHandler,
};
