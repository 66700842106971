import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
/**@jsx jsx*/

export const SubHeading: React.FC<{ gap?: number }> = ({
  children,
  gap = 8,
}) => (
  <h4
    css={{
      color: theme.colors.gray[500],
      textTransform: 'uppercase',
      fontFamily: theme.typography.fonts.bold,
      marginBottom: `${gap}px`,
      fontSize: '12px',
      letterSpacing: '.2px',
    }}>
    {children}
  </h4>
);

export const FootNote: React.FC<{ gap?: number; className?: string }> = ({
  children,
  gap = 8,
  className,
}) => {
  return (
    <p
      className={className}
      css={{
        fontSize: '12px',
        color: theme.colors.gray[400],
        marginTop: gap + 'px',
      }}>
      {children}
    </p>
  );
};

export const HeadingStrip: React.FC<{ gap?: number }> = ({
  children,
  gap = 24,
}) => {
  return (
    <Flex
      css={{
        padding: '8px 16px',
        background: theme.colors.gray[50],
        marginBottom: gap + 'px',
        borderRadius: '4px',
        color: theme.colors.gray[500],
        textTransform: 'uppercase',
        fontFamily: theme.typography.fonts.semibold,
        fontSize: '12px',
      }}>
      {children}
    </Flex>
  );
};
