import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { IProduct } from '../../../utils/types';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

type Actions = { label: string; action: (product: IProduct) => void }[];

interface IProps {
  products?: IProduct[];
  actions: Actions;
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Fund Code',
    dataIndex: 'fundCode',
    key: 'fundCode',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: Date) => {
      return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Last Changed',
    dataIndex: 'lastChanged',
    key: 'lastChanged',
    render: (lastChanged: Date) => {
      return <span>{moment(lastChanged).format('DD/MM/YYYY')}</span>;
    },
  },
];
const ProductsTable: React.FC<IProps> = ({ products = [], actions }) => {
  return (
    <DataTable
      showControls
      showPagination
      data={products}
      columns={columns}
      actions={actions}
      searchFilter="name"></DataTable>
  );
};

export default ProductsTable;
