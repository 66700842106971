import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { IGoal } from '../../../utils/types';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

type Actions = { label: string; action: (goal: IGoal) => void }[];

interface IProps {
  goals?: IGoal[];
  actions: Actions;
  isLoading?: boolean;
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: Date) => {
      return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Last Changed',
    dataIndex: 'lastChanged',
    key: 'lastChanged',
    render: (lastChanged: Date) => {
      return <span>{moment(lastChanged).format('DD/MM/YYYY')}</span>;
    },
  },
];
const GoalsTable: React.FC<IProps> = ({
  goals = [],
  actions,
  isLoading = false,
}) => {
  return (
    <DataTable
      isLoading={isLoading}
      showControls
      showPagination
      data={goals}
      columns={columns}
      actions={actions}
      searchFilter="name"></DataTable>
  );
};

export default GoalsTable;
