import * as React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../components/layout/Flex';
import Icon from '../../components/icon/Icon';
import Avatar from '../../components/avatar/Avatar';
import theme from '../../theme/theme';
/**@jsx jsx*/

interface IProps {
  success: boolean;
  message: string;
}

const Toastify: React.FC<IProps> = ({ success, message }) => (
  <Flex>
    {success && (
      <Avatar
        size="xs"
        background={theme.colors.green[100]}
        styles={{ marginRight: '10px' }}>
        <Icon
          size="xs"
          color={theme.colors.green[500]}
          icon={['fas', 'check']}
        />
      </Avatar>
    )}
    {!success && (
      <Avatar
        size="xs"
        background={theme.colors.red[100]}
        styles={{ marginRight: '10px' }}>
        <Icon size="xs" color={theme.colors.red[500]} icon={['fas', 'xmark']} />
      </Avatar>
    )}
    <span>{message}</span>
  </Flex>
);

export default Toastify;
