import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Field, Form, FormikHelpers, FieldProps } from 'formik';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useGendersQuery,
  Gender,
  useUpdate_UserMutation,
  useRegister_UserMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import { UserSchema } from '../../../utils/yup-schema';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import Grid from '../../../components/layout/Grid';
import NativeSelect from '../../../components/select/NativeSelect';
import Separator from '../../../components/separator/Separator';
import DateInput from '../../../components/date/DateInput';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  otherName?: string | null;
  gender: string;
  dateOfBirth: Date;
  mobile: string;
  email: string;
}

interface AddPortfolioUserResultProps {
  success: boolean;
  error: boolean;
}

interface IUser {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  otherName?: string | null;
  genderId: number;
  dateOfBirth: Date;
  mobile: string;
  email: string;
}

type IPortfolioUserModal = IModal & {
  portfolioUser?: {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    otherName: string;
    gender: string;
    dateOfBirth: Date;
    mobile: string;
    email: string;
  };
};

const PortfolioUserModal: React.FC<IPortfolioUserModal> = ({ onClose, portfolioUser }) => {
  const { userId } = useAuthState();
  const userRole = 'portfolio-analyst';
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    firstName: '',
    lastName: '',
    otherName: null,
    gender: '',
    dateOfBirth: new Date(),
    mobile: '',
    email: '',
    username: '',
    password: '',
  });

  const [addPortfolioUserResult, setAddPortfolioUserResult] = React.useState<
    AddPortfolioUserResultProps
  >({
    success: false,
    error: false,
  });

  const { data: genders } = useGendersQuery();
  const [
    registerUser,
    { loading: registerUserLoading },
  ] = useRegister_UserMutation();

  const [
    updatePortfolioUsers,
    {
      data: updateUserResult,
      loading: updateUserLoading,
      error: updateUserError,
    },
  ] = useUpdate_UserMutation();

  React.useEffect(() => {
    if (portfolioUser) {
      setInitialValues({
        username: portfolioUser.username,
        password: 'admin6_',
        firstName: portfolioUser.firstName,
        lastName: portfolioUser.lastName,
        otherName: portfolioUser.otherName,
        gender: portfolioUser.gender,
        dateOfBirth: portfolioUser.dateOfBirth,
        mobile: portfolioUser.mobile,
        email: portfolioUser.email,
      });
    }
  }, [portfolioUser]);

  const loading = !!(updateUserLoading || registerUserLoading);
  let error = !!(updateUserError || addPortfolioUserResult.error);
  let success = !!(
    updateUserResult?.update_user?.returning?.length || addPortfolioUserResult.success
  );

  const addUser = async (user: IUser): Promise<number | null | undefined> => {
    const { data } = await registerUser({
      variables: {
        ...user,
        userRole,
      },
      refetchQueries: ['PortfolioAnalysts'],
      awaitRefetchQueries: true,
    });
    if (data?.register.success) {
      setAddPortfolioUserResult({
        success: true,
        error: false,
      });
      return data.register.id;
    } else {
      setAddPortfolioUserResult({
        success: false,
        error: true,
      });
    }
  };

  const addPortfolioUser = async (values: ValueProps) => {
    try {
      await addUser({
        ...values,
        genderId: Number(values.gender),
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  const updatePortfolioUser = (values: ValueProps) => {
    updatePortfolioUsers({
      variables: {
        id: portfolioUser?.id,
        user_changes: {
          first_name: values.firstName,
          last_name: values.lastName,
          other_name: values.otherName,
          gender_id: values.gender,
          mobile: values.mobile,
          date_of_birth: values.dateOfBirth,
          email: values.email,
          modified_by: userId,
        },
      },
      update: UpdateHandlers.updateUser,
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (portfolioUser) {
      updatePortfolioUser(values);
    } else {
      addPortfolioUser(values);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={UserSchema}
      validateOnMount={true}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${portfolioUser ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name:'circle-user' }}
            title={`${portfolioUser ? 'Update' : 'Add'} Portfolio Analyst`}
            width="50%">
            <React.Fragment>
              {error && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Portfolio Analyst ${portfolioUser ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              {/* <Stack> */}
              <Grid lg={3}>
                <div>
                  <Field name="firstName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="firstName"
                          placeholder="Enter First Name"
                          label="First Name"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="firstName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="otherName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="otherName"
                          placeholder="Enter other name"
                          label="Other Names"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="otherName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="lastName">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          label="LastName"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="lastName" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <NativeSelect
                    label="Gender"
                    name="gender"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.gender}>
                    <option value="">Select Gender</option>
                    {genders?.gender?.map((gender: Partial<Gender>) => (
                      <option key={gender.id} value={gender.id}>
                        {gender.name}
                      </option>
                    ))}
                  </NativeSelect>
                  <ErrorMessage name="gender" />
                </div>

                <div>
                  <Field name="dateOfBirth">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <DateInput
                          {...field}
                          type="text"
                          name="dateOfBirth"
                          placeholder="Enter date of birth"
                          label="Date Of Birth"
                          defaultDate={field.value}
                          minDate={new Date(1700, 5, 4)}
                          maxDate={new Date()}
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="DateOfBirth" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="mobile">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="mobile"
                          placeholder="Enter mobile"
                          label="Mobile"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="mobile" />
                      </div>
                    )}
                  </Field>
                </div>

                <div>
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          {...field}
                          type="text"
                          name="email"
                          placeholder="Enter email"
                          label="Email"
                          onChange={formikProps.handleChange}
                        />
                        <ErrorMessage name="email" />
                      </div>
                    )}
                  </Field>
                </div>
              </Grid>
              <br />
              <Separator />

              <Stack>
                <h3>Account Credentials</h3>
                <Grid lg={2}>
                  <div>
                    <Field name="username">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <Input
                            {...field}
                            type="text"
                            name="username"
                            disabled={portfolioUser ? true : false}
                            placeholder="Enter user name"
                            label="username"
                            onChange={formikProps.handleChange}
                          />
                          <ErrorMessage name="username" />
                        </div>
                      )}
                    </Field>
                  </div>

                  <div>
                    <Field name="password">
                      {({ field, meta }: FieldProps) => (
                        <div>
                          <Input
                            {...field}
                            type="password"
                            name="password"
                            disabled={portfolioUser ? true : false}
                            placeholder="Enter password"
                            label="password"
                            onChange={formikProps.handleChange}
                          />
                          <ErrorMessage name="password" />
                        </div>
                      )}
                    </Field>
                  </div>
                </Grid>
              </Stack>

              {/* </Stack> */}
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default PortfolioUserModal;
