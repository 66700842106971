import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, FormikHelpers, Form } from 'formik';
import * as Yup from 'yup';
import Flex from '../../components/layout/Flex';
import theme from '../../theme/theme';
import logo from '../../assets/img/logo-blue.png';
import Input from '../../components/input/Input';
import Stack from '../../components/stack/Stack';
import Button from '../../components/button/Button';
import ErrorMessage from '../../components/ErrorMessage';
import {
  useLoginMutation,
  usePasswordResetMutation,
} from '../../generated/graphql';
import UpdateHandlers from '../../utils/handlers';
import { useHistory } from 'react-router-dom';
import { getAccessToken, setAccessToken } from '../../accessToken';
import Grid, { GridItem } from '../../components/layout/Grid';
import loginImage from '../../assets/img/landing.jpg';
import jwtDecode from 'jwt-decode';
import { useAuthDispatch } from '../../context/auth';
import { ls } from '../..';
import { allRoutes } from '../../routes/routes';

/**@jsx jsx*/

interface ValueProps {
  email: string;
  password: string;
  newPassword: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('required'),
  password: Yup.string().required('required'),
  newPassword: Yup.string().min(6, 'must be at least 6 characters'),
});

const initialValues: ValueProps = {
  email: '',
  password: '',
  newPassword: '',
};

const navigateTo = (role: string) => {
  const routeFound = allRoutes.find(
    (route) => route.allowed?.includes(role) && route.route !== '#'
  );

  if (routeFound) {
    return routeFound.route;
  }

  return '/login';
};

const Login: React.FC = () => {
  const dispatch = useAuthDispatch();
  const [login, { loading }] = useLoginMutation();
  const [
    resetPassword,
    { loading: passwordResetLoading },
  ] = usePasswordResetMutation();
  const [showNewPassword, setShowNewPassword] = React.useState<boolean>(false);
  const [showLoginfailed, setShowLogInFailed] = React.useState<boolean>(false);
  const history = useHistory();
  // const location = useLocation();
  // const state = location.state as any;

  React.useEffect(() => {
    if (getAccessToken()) {
      history.push('/dashboard');
    }
  }, [history]);

  const getRole = (token: string) => {
    const decodedToken = jwtDecode(token);
    // @ts-ignore
    const role = (decodedToken as any)?.['resource_access']?.[
      'hasura-keycloak-connector'
    ]?.['roles']?.[0];
    return role;
  };

  const handleSubmit = async (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    const response = await login({
      variables: {
        usernameOrEmail: values.email,
        password: values.password,
      },
      update: UpdateHandlers.login,
    });
    if (response && response.data?.login.accessToken) {
      setSubmitting(false);

      const role = getRole(response.data.login.accessToken);
      if (role) {
        dispatch({ type: 'SET_ROLE', role });
      }

      const id = response.data.login.id;
      if (id) {
        dispatch({ type: 'SET_USER_ID', userId: id });
      }

      if (role && id) {
        ls.set('user_id_role', { role, userId: id });
      }

      setAccessToken(response.data.login.accessToken!);

      const path = navigateTo(role);
      history.push(path);

      // if (state?.next) {
      //   history.push(state.next);
      // } else {
      // }
    } else if (response.data?.login.shouldUpdatePassword) {
      setSubmitting(false);
      setShowNewPassword(true);
    } else {
      setSubmitting(false);
      setShowLogInFailed(true);
    }
  };

  const handleReset = async (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    const response = await resetPassword({
      variables: {
        usernameOrEmail: values.email,
        newPassword: values.newPassword,
      },
      update: UpdateHandlers.resetPassword,
    });
    if (response && response.data?.resetPassword.accessToken) {
      setSubmitting(false);

      const role = getRole(response.data.resetPassword.accessToken);
      if (role) {
        dispatch({ type: 'SET_ROLE', role });
      }

      if (response.data.resetPassword.id) {
        dispatch({
          type: 'SET_USER_ID',
          userId: response.data.resetPassword.id,
        });
      }

      setAccessToken(response.data.resetPassword.accessToken!);
      history.push('/dashboard');
    } else {
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Grid gap={0}>
        <GridItem span={9}>
          <div css={{ width: '100%', height: '100vh', position: 'relative' }}>
            <Flex
              ai="flex-end"
              jc="flex-end"
              css={{
                width: '100%',
                height: '100vh',
                position: 'absolute',
                left: 0,
                top: 0,
                padding: '24px',
                backgroundImage:
                  'linear-gradient(245deg, rgba(0,33,64,0.60) 0%, rgba(7,71,166,0.20) 100%)',
              }}>
              <Flex stack ai="flex-end">
                <p
                  css={{
                    color: theme.colors.white,
                    width: '300px',
                    fontSize: '22px',
                    marginBottom: '16px',
                    fontFamily: 'klavika medium',
                    textAlign: 'right',
                  }}>
                  Having a vision for what you want is not enough. Vision
                  without execution is hallucination
                </p>
                <p
                  css={{
                    color: theme.colors.white,
                    fontSize: '14px',
                    fontFamily: 'klavika light',
                    textAlign: 'right',
                  }}>
                  - Thomas Edison
                </p>
              </Flex>
            </Flex>
            <Flex
              css={{
                height: '100vh',
                zIndex: 0,
                width: '100%',
                backgroundImage: `url(${loginImage})`,
                backgroundColor: theme.colors.primary,
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
              }}></Flex>
          </div>
        </GridItem>
        <GridItem span={3}>
          <Flex
            stack
            jc="center"
            // ai="center"
            css={{
              maxHeight: '100vh',
              height: '100vh',
              width: '100%',
              position: 'relative',
              padding: '0 12px',
            }}>
            <div css={{ paddingLeft: '24px', marginBottom: '16px' }}>
              <img width="120px" src={logo} alt="" />
            </div>

            <div
              css={{
                width: '100%',
                background: theme.colors.white,
                borderRadius: '4px',
                padding: '24px',
                zIndex: 1,
              }}>
              {/* <h3 css={{ marginBottom: "24px" }}>Login to your account</h3> */}

              {showLoginfailed && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Login failed...please try again
                </Flex>
              )}

              <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={showNewPassword ? handleReset : handleSubmit}>
                {(formikProps) => (
                  <Form>
                    <Stack>
                      <div>
                        <Input
                          type="text"
                          name="email"
                          label="Email Address"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.email}
                        />
                        <ErrorMessage name="email" />
                      </div>
                      <div>
                        <Input
                          type="password"
                          name="password"
                          label="Password"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.password}
                        />
                        <ErrorMessage name="password" />
                      </div>

                      {showNewPassword && (
                        <div>
                          <Input
                            type="password"
                            name="newPassword"
                            label="new Password"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.newPassword}
                          />
                          <ErrorMessage name="newPassword" />
                        </div>
                      )}

                      <Button
                        type="submit"
                        appearance="secondary"
                        className="submit-login"
                        isLoading={
                          formikProps.isSubmitting ||
                          loading ||
                          passwordResetLoading
                        }>
                        Login
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </div>
          </Flex>
        </GridItem>
      </Grid>
    </React.Fragment>
  );
};

export default Login;
