import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import logo from '../../assets/img/psl-logo-new.png';
import banner from '../../assets/img/statementbanner.jpg';
import Stack from '../stack/Stack';
import { StatementData } from '../../utils/types';
/**@jsx jsx*/

interface IProps {
  statementPayload: StatementData | undefined;
}
interface ISummaryItem {
  label: string;
  amount: string | number;
}

const SummaryItem: React.FC<{
  item: ISummaryItem;
}> = ({ item }) => {
  return (
    <Flex jc="space-between">
      <p>{item.label}</p>
      <p>{item.amount}</p>
    </Flex>
  );
};

const Statement: React.FC<IProps> = ({ statementPayload }) => {
  const {
    customerDetails,
    startDate,
    endDate,
    beginningBalance,
    contributions,
    totalPeriodNetIncome,
    endOfPeriodOfBalance,
    redemptions,
    payments,
    goalAllocations,
    goalSummary,
    goalSummaryTotal,
  } = statementPayload || {};

  const hasData = !!statementPayload;

  const summaryItems: ISummaryItem[] = [
    {
      label: 'Beginning Balance',
      amount: beginningBalance ? beginningBalance : '0.00',
    },
    {
      label: 'Contribution',
      amount: contributions ? contributions : '0.00',
    },
    {
      label: 'Total Period Net Income',
      amount: totalPeriodNetIncome ? totalPeriodNetIncome : '0.00',
    },
    {
      label: 'Redemptions',
      amount: redemptions ? redemptions : '0.00',
    },
  ];
  return (
    <div
      css={{
        position: 'relative',
        '*': { fontFamily: 'serif' },
        h4: { fontWeight: 600 },
      }}>
      <div css={{ padding: '48px' }}>
        <Stack>
          <Stack spacing={48}>
            <Flex jc="center">
              <img width="200px" src={logo} alt="" />
            </Flex>
            <Flex jc="center">
              <h3
                css={{
                  fontFamily: 'serif',
                  textTransform: 'uppercase',
                  fontWeight: 800,
                }}>
                Wealth Planner Statement
              </h3>
            </Flex>
          </Stack>
          <Flex jc="space-between">
            <div>
              <h4>{hasData ? customerDetails![0].fullName : '{fullname}'}</h4>
              <p>
                {hasData
                  ? (!["", null, undefined].includes(customerDetails![0].postalAddress) ? customerDetails![0].postalAddress+", " : "")
                  : '{postal Address}'}
                 {hasData ? customerDetails![0].city : '{city}'} <br />{' '}
                {hasData ? customerDetails![0].homeAddress : '{home Address}'}{' '}
                <br /> Greater Accra
              </p>
              <p>
                Customer ID:{' '}
                {hasData ? customerDetails![0].pslCode : '{Customer Id}'}
              </p>
            </div>
            <div>
              <div>
                <h4>Statement Period</h4>
                <p>
                  {hasData ? `${startDate} ${endDate}` : '{StartDate EndDate}'}
                </p>
              </div>

              <div>
                <h4>Report Currency</h4>
                <p>GHS</p>
              </div>
            </div>
          </Flex>
          <div>
            <Flex
              css={{ borderBottom: '1px solid #333', paddingBottom: '16px' }}
              jc="center">
              <h4>SUMMARY STATEMENT</h4>
            </Flex>
            <div css={{ borderBottom: '3px solid #333' }}>
              {summaryItems.map((item, index) => {
                return <SummaryItem key={index} item={item} />;
              })}
            </div>
            <div css={{ borderBottom: '1px solid #333' }}>
              <SummaryItem
                item={{
                  label: 'End of Period Balance',
                  amount: endOfPeriodOfBalance ? endOfPeriodOfBalance : '0.00',
                }}
              />
            </div>
          </div>
          <Flex jc="center">
            <h4>DETAILED STATEMENT</h4>
          </Flex>
          <div>
            <table
              css={{
                borderTop: '1px solid #333',
                borderBottom: '1px solid #333',
                width: '100%',
                borderCollapse: 'collapse',
                marginBottom: '24px',
                th: { fontWeight: 600, fontSize: '12px', textAlign: 'left' },
              }}>
              <thead>
                <tr css={{ borderBottom: '1px solid #333' }}>
                  <th>DATE</th>
                  <th>DETAIL</th>
                  <th>AMOUNT</th>
                  <th></th>
                  <th>TRANSACTION TYPE</th>
                  <th>ALLOCATIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{hasData ? `${startDate}` : '{StartDate}'}</td>
                  <td>Opening balance</td>
                  <td>0.00</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                {/* eslint-disable */}
                {payments &&
                  payments!.map((payment) => (
                    <React.Fragment>
                      <tr>
                        <td>{payment.firstPaymentDate}</td>
                        <td>{payment.verboseDate}</td>
                        <td>{payment.total}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>

                      {payment.payments.map((paymentShare) => (
                        <tr>
                          <td></td>
                          <td>{paymentShare.product}</td>
                          <td>{paymentShare.amount}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {/* eslint-disable */}
                {goalAllocations &&
                  goalAllocations.map((allocation) => (
                    <tr>
                      <td></td>
                      <td>{allocation.goal}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{allocation.allocation}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div>
            <table
              css={{
                width: '100%',
                borderCollapse: 'collapse',
                borderBottom: '3px solid #333',
                th: {
                  fontWeight: 600,
                  fontSize: '12px',
                  textAlign: 'left',
                  textTransform: 'uppercase',
                },
              }}>
              <thead>
                <tr css={{ borderBottom: '1px solid #333' }}>
                  <th></th>
                  <th>SUMMARY OF GOALS</th>
                  <th>% OF GOAL ACHIEVED</th>
                  <th>Withdrawal/Claims</th>
                  <th>Outstandings</th>
                  <th>Goal Balances</th>
                </tr>
              </thead>
              <tbody>
                {/* eslint-disable */}
                {goalSummary &&
                  goalSummary.map((summary, index) => (
                    <tr>
                      <td>{index}</td>
                      <td>{summary.goal}</td>
                      <td>{summary.percentageGoalAchieved}</td>
                      <td></td>
                      <td></td>
                      <td>{summary.goalBalance}</td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td>{'End of Period Balance as at ' + endDate}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{goalSummaryTotal}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Stack>
        <Stack>
          <Flex>
            <p css={{ fontSize: '12px' }}>
              <b>NB</b> % achieved is based on current contribution as against
              the expected goal amount.
            </p>
          </Flex>
          <Flex>
            <div css={{ p: { fontSize: '12px' } }}>
              <p>
                For any enquiries or clarifications regarding this statement,
                contact us on:
              </p>
              <p>E:customerexperience@petrasales.com</p>
              <p>T:0242435037(EXT 1)/ 0302763908</p>
              <p>
                P.O.Box CT 3194, Cantoments,113 Airport West, Dzorwulu, Accra
              </p>
            </div>
          </Flex>
        </Stack>
      </div>
      <div css={{ position: 'relative', bottom: 0 }}>
        <Flex ai="flex-end" css={{ height: '150px' }}>
          <img width="100%" src={banner} alt="" />
        </Flex>
      </div>
    </div>
  );
};

export default Statement;
