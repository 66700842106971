import { IRoute } from '../routes/routes';
import { subYears } from 'date-fns';
import moment from 'moment';

export function getInitials(name: string): string {
  const [firstName, lastName] = name.split(' ');
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`;
  }

  return firstName[0];
}

export function getDate18YearsAgo() {
  return subYears(new Date(), 18);
}

export function formatDuration(years?: number, months?: number) {
  let durationString = '';
  if (years && years > 0) {
    durationString = `${years} years `;
  }

  if (months && months > 0) {
    durationString = `${durationString}${months} months`;
  }

  return durationString;
}

export function getIdImageName(
  id: number,
  ext: string,
  firstName?: string | null,
  lastName?: string | null
) {
  let name = '';

  if (firstName) {
    name = `${firstName}_`;
  }

  if (lastName) {
    name = `${name}${lastName}_${id}.${ext}`;
  }

  return name;
}

export function capitalize(words: string | null | undefined) {
  return words
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
}

export const getAccountType = (value: String) =>{
  switch(value){
    case 'individual':
      return 'Individual';
    case 'joint':
      return 'Joint';
    case 'trust':
      return 'Trust';
    default: 
      return 'N/A';
  }
}

export function formatMoney(amount: number | string) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GHS',
  }).format(Number(amount));
}

export function calculateAge(dob: string) {
  var diff_ms = Date.now() - new Date(dob).getTime();
  var age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export function formatStringTo2dp(value: string) {
  if (isNaN(Number(value))) {
    return 0.0;
  }
  const valueTo2dp = Number(value.trim().replace(',', '')).toFixed(2);
  return Number(valueTo2dp);
}

export const formatFilenameForS3IDUpload = (
  fileExt: string,
  customer_id: string
) => {
  const date = moment().format('YYYYMMDD');
  const randomString = Math.random().toString(36).substring(2, 7);
  const cleanFileName = customer_id
    .toLocaleLowerCase()
    .replace(/[^a-z0-9]/g, '-');
  const newFilename = `ID-images/${date}-${randomString}-${cleanFileName}`;
  return `${newFilename.substring(0, 60)}.${fileExt}`;
};

export const formatFilenameForAPKUpload = (filename: string) => {
  return `APK-mobile/${filename}`;
};

export const paymentsDump = {
  data: {
    payment: [
      {
        id: 26,
        member: { mf_code: 'PF00000362', __typename: 'member' },
        payment_date: '2020-06-04',
        value_date: '2020-02-19',
        amount: 5000,
        status: { name: 'Pending Approval', __typename: 'status' },
        payment_shares: [
          {
            goal: { id: 1, name: 'Retirement', __typename: 'goal' },
            product: { name: 'Plus Income Fund', __typename: 'product' },
            product_amount: 1000,
            __typename: 'payment_share',
          },
          {
            goal: { id: 1, name: 'Retirement', __typename: 'goal' },
            product: { name: 'Balanced Income Fund', __typename: 'product' },
            product_amount: 4000,
            __typename: 'payment_share',
          },
        ],
        __typename: 'payment',
      },
      {
        id: 25,
        member: { mf_code: 'PF00000362', __typename: 'member' },
        payment_date: '2020-06-04',
        value_date: '2020-02-19',
        amount: 5000,
        status: { name: 'Pending Approval', __typename: 'status' },
        payment_shares: [
          {
            goal: { id: 1, name: 'Retirement', __typename: 'goal' },
            product: { name: 'Plus Income Fund', __typename: 'product' },
            product_amount: 1000,
            __typename: 'payment_share',
          },
          {
            goal: { id: 1, name: 'Retirement', __typename: 'goal' },
            product: { name: 'Balanced Income Fund', __typename: 'product' },
            product_amount: 4000,
            __typename: 'payment_share',
          },
        ],
        __typename: 'payment',
      },
    ],
  },
};

export function getGoalCategories(): any {
  const goalsSet = new Set();
  const dump = paymentsDump.data.payment;
  dump.forEach((dump) => {
    dump.payment_shares.forEach((share) => {
      goalsSet.add(share.goal.name);
    });
  });
  return Array.from(goalsSet);
}

export function flattenRoutes(route: IRoute): IRoute[] {
  if (!route.children) {
    return [route];
  }
  const children: IRoute[] = route.children
    .map((r: IRoute) => {
      if (!r.children) {
        return [r];
      }
      const flat: IRoute[] = flattenRoutes(r);
      return flat;
    })
    .flat();
  return [route, ...children];
}

export function groupBy<T>(list: T[], keyGetter: (item: T) => string) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function mapToObj(map: Map<string, any>): Object {
  const obj: { [key: string]: any } = {};
  map.forEach((value, key) => (obj[key] = value));
  return obj;
}

export const customerDump = {
  id: 4,
  user: {
    id: 4,
    first_name: 'Maame Koama',
    last_name: 'Gyasi',
    other_name: 'Nana',
    mobile: '0552123456',
    email: 'asdewrrtrt@gmail.com',
    date_of_birth: '2002-06-01',
    gender: {
      id: 2,
      name: 'Female',
    },
  },
  age: 31,
  signature: 'data:image/png;base64,',
  city: 'Accra',
  psl_code: 'PSL_930024',
  nationality: 'Ghanaian',
  tin: '12356809533u',
  occupation: 'Caterer',
  employer: 'GDS',
  mobile_secondary: null,
  home_address: 'Accra',
  postal_address: 'Accra',
  assigned_to_user: null,
  status: {
    id: 3,
    name: 'Pending Approval',
  },
  industry: {
    id: 13,
    name: 'Bars and Restaurants',
  },
  next_of_kin: {
    id: 1,
    first_name: 'David',
    last_name: 'Daniels',
    other_names: null,
    relationship_id: 5,
    date_of_birth: '2020-06-01',
    mobile: '0244123456',
    email: 'werere@gmail.com',
  },
  member_income_sources: [
    {
      income_source_id: 1,
    },
  ],
  member_contribution_sources: [
    {
      contribution_source_id: 1,
    },
  ],
  beneficiaries: [
    {
      id: 1,
      guid: 'f6f01969-f733-46f7-9f22-5e8fca2f5fe5',
      first_name: 'Meri',
      last_name: 'Adams',
      mobile: '0244898989',
      other_names: '',
      home_address: 'Accra',
      date_of_birth: '2020-06-02',
      allocation: 100,
      relationship_id: 2,
    },
  ],
  member_dependents: [
    {
      id: 6,
      guid: '6b8e8e1f-e853-4fb8-88d0-0d3f1d9510be',
      name: 'Andy',
      age: 10,
    },
    {
      id: 7,
      guid: '2e5d9e9c-2644-42d8-8ecb-10ede7e77753',
      name: 'Abdul ',
      age: 15,
    },
  ],
  member_payment_instructions: [
    {
      id: 1,
      guid: 'd1fa8e10-cd3e-43d8-b570-304008b40652',
      account_name: 'Maame Konama',
      account_number: '1256677837363637',
      branch: 'Osu',
      payment_provider: {
        id: 8,
        name: 'CAL Bank Limited',
        payment_channel_id: 1,
      },
      preferred: true,
      mobile_money_number: null,
    },
  ],
  member_mode_of_instruction_communications: [],
  member_identifications: [
    {
      id: 7,
      identification_type: {
        id: 1,
        name: 'Voters ID',
      },
      identification_number: '123456789',
      identification_card_image_url: 'Maame Koama_4.jpg',
      identification_expiry_date: '2020-07-31',
      identification_issue_date: '2020-06-01',
      country: {
        id: 4,
        name: 'Algeria',
      },
    },
  ],
  member_goals: [
    {
      id: 10,
      guid: '3d69538e-327a-4127-abd9-af30550006a6',
      member_id: 4,
      goal_for: 'Maame Koama',
      accepted: true,
      current_value: 0,
      future_value: 22943.67,
      duration_years: 29,
      duration_months: 1,
      administrative_fees: 0.03,
      goal_id: 5,
      goal: {
        id: 5,
        name: 'Life Insurance',
      },
      monthly_contribution: 1.36,
      expected_return: 19.55,
      has_insurance: false,
    },
    {
      id: 11,
      guid: '567d0359-454e-4666-90b1-e56d8c4207c7',
      member_id: 4,
      goal_for: 'Maame Koama',
      accepted: true,
      current_value: 0,
      future_value: 7642.13,
      duration_years: 1,
      duration_months: 1,
      administrative_fees: 10.96,
      goal_id: 3,
      goal: {
        id: 3,
        name: 'Emergency',
      },
      monthly_contribution: 548.02,
      expected_return: 17.9,
      has_insurance: false,
      goal_insurance: null,
    },
    {
      id: 12,
      guid: '6a5b9ca8-d23e-4e67-acc2-1c57be4ba648',
      member_id: 4,
      goal_for: 'Andy',
      accepted: true,
      current_value: 0,
      future_value: 205419.84,
      duration_years: 5,
      duration_months: 1,
      administrative_fees: 40.68,
      goal_id: 2,
      goal: {
        id: 2,
        name: 'Education',
      },
      monthly_contribution: 2033.97,
      expected_return: 19.53,
      has_insurance: true,
    },
    {
      id: 13,
      guid: '3c5b2f3c-6727-46bb-80b2-f26b5db57e67',
      member_id: 4,
      goal_for: 'Abdul ',
      accepted: true,
      current_value: 0,
      future_value: 101901.72,
      duration_years: 10,
      duration_months: 1,
      administrative_fees: 5.58,
      goal_id: 2,
      goal: {
        id: 2,
        name: 'Education',
      },
      monthly_contribution: 279.23,
      expected_return: 19.55,
      has_insurance: true,
      goal_insurance: null,
    },
    {
      id: 9,
      guid: 'a947ad85-a721-4ea0-8d4e-1bbf898e1da6',
      member_id: 4,
      goal_for: 'Maame Koama',
      accepted: true,
      current_value: 0,
      future_value: 908076.06,
      duration_years: 40,
      duration_months: 0,
      administrative_fees: 0.13,
      goal_id: 1,
      goal: {
        id: 1,
        name: 'Retirement',
      },
      monthly_contribution: 6.46,
      expected_return: 19.55,
      has_insurance: false,
      goal_insurance: null,
    },
  ],
};
