import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../layout/Flex';
import theme from '../../../theme/theme';
import Avatar from '../../avatar/Avatar';
import Icon from '../../icon/Icon';
import Separator from '../../separator/Separator';
import useDropDown from '../../../hooks/useDropDown';
import { useMeQuery } from '../../../generated/graphql';
import { capitalize } from '../../../utils/helpers';
import { Link } from 'react-router-dom';
/**@jsx jsx*/

const ProfileOptions: React.FC = () => {
  const { isOpen, elRef, handleVisibilityChange } = useDropDown(false, true);
  const { data: me } = useMeQuery();

  return (
    <div ref={elRef} css={{ position: 'relative' }}>
      {isOpen && (
        <div
          css={{
            animation: `${theme.animations.scaleUp} .5s`,
            position: 'absolute',
            width: '240px',
            right: 0,
            top: 'calc(100% + 8px)',
            background: theme.colors.white,
            boxShadow: theme.shadows.min,
            border: `1px solid ${theme.colors.gray[100]}`,
            borderRadius: '4px',
          }}>
          <div css={{ padding: '16px 16px 0 16px' }}>
            <h4 css={{ fontSize: '14px' }}>{`${
              capitalize(me?.me?.user_type?.fullname) ?? ''
            }`}</h4>
            <p css={{ color: theme.colors.gray[400], fontSize: '14px' }}>
              {`${me?.me?.user_type?.email ?? ''}`}
            </p>
          </div>

          <Separator />

          <ul
            css={{
              padding: '0 16px 16px 16px',
              li: {
                color: theme.colors.darkBlue,
                fontSize: '14px',
                borderRadius: '4px',
                padding: '12px 8px',
                '&:hover': { background: theme.colors.gray[100] },
              },
            }}>
            {/* <li>Profile</li> */}
            <Link onClick={handleVisibilityChange} to="/dashboard/logout">
              <li>Sign out</li>
            </Link>
          </ul>
        </div>
      )}
      <Flex
        onClick={handleVisibilityChange}
        css={{
          borderRadius: '4px',
          padding: '6px 8px',
          '&:hover': {
            background: theme.colors.whiteAlpha[300],
          },
        }}
        ai="center">
        <Flex css={{ marginRight: '8px' }} ai="center">
          <Avatar
            css={{ marginRight: '8px' }}
            name={me?.me.user_type?.fullname!}
            size="xs"
          />
          <p css={{ color: theme.colors.white, fontSize: '12px' }}>
            {me?.me.user_type?.fullname}
          </p>
        </Flex>
        <Icon
          size="xs"
          color={theme.colors.white}
          icon={['fas', 'chevron-down']}></Icon>
      </Flex>
    </div>
  );
};

export default ProfileOptions;
