import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  bigint: any;
  date: any;
  float8: any;
  inet: any;
  json: any;
  jsonb: any;
  numeric: any;
  oid: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AggregatedPayment = {
  __typename?: 'AggregatedPayment';
  amount: Scalars['String'];
  product: Scalars['String'];
};

export type ApprovePaymentInput = {
  ids: Array<Scalars['Int']>;
};

export type ApprovePaymentResponse = {
  __typename?: 'ApprovePaymentResponse';
  errors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean'];
};

export type ArgPermissionType = {
  columns?: Maybe<Array<Scalars['String']>>;
};

export type ArgsType = {
  permission?: Maybe<ArgPermissionType>;
  role: Scalars['String'];
  table: Scalars['String'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ContributionsInput = {
  memberId: Scalars['Int'];
};

export type ContributionsResponse = {
  __typename?: 'ContributionsResponse';
  amount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
};

export type CreateCustomerInput = {
  id: Scalars['Int'];
  isDirect?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  errors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean'];
};

export type CustomerDetail = {
  __typename?: 'CustomerDetail';
  city: Scalars['String'];
  fullName: Scalars['String'];
  homeAddress: Scalars['String'];
  postalAddress: Scalars['String'];
  pslCode: Scalars['String'];
};

export type CustomerRegisterInput = {
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  genderId: Scalars['Float'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  otherName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};


export type DependentType = {
  age: Scalars['Float'];
  name: Scalars['String'];
};

export type EmailAvailabilityResponse = {
  __typename?: 'EmailAvailabilityResponse';
  errors?: Maybe<Array<FieldError>>;
  isEmailAvailable: Scalars['Boolean'];
};

export type EmailInput = {
  email: Scalars['String'];
};

export type ExpectedReturnInput = {
  durationInYears: Scalars['Float'];
};

export type ExpectedReturnResponse = {
  __typename?: 'ExpectedReturnResponse';
  errors?: Maybe<Array<FieldError>>;
  expected_return?: Maybe<Scalars['Float']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  message: Scalars['String'];
  path: Scalars['String'];
};

export type GenerateStatementInput = {
  customerId: Scalars['Float'];
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type GenerateStatementResponse = {
  __typename?: 'GenerateStatementResponse';
  errors?: Maybe<Array<FieldError>>;
  results?: Maybe<StatementType>;
  success: Scalars['Boolean'];
};

export type GoalAllocation = {
  __typename?: 'GoalAllocation';
  allocation: Scalars['String'];
  goal: Scalars['String'];
};

export type GoalAmountsType = {
  amount: Scalars['Float'];
  id: Scalars['Int'];
};

export type GoalBalance = {
  __typename?: 'GoalBalance';
  balance: Scalars['Float'];
  id: Scalars['Int'];
};

export type GoalBalancesInput = {
  memberGoalIds: Array<Scalars['Int']>;
};

export type GoalBalancesResponse = {
  __typename?: 'GoalBalancesResponse';
  balances?: Maybe<Array<GoalBalance>>;
  errors?: Maybe<Array<FieldError>>;
};

export type GoalCalculationInput = {
  calcFor: Scalars['String'];
  currentValue?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expectedReturn?: Maybe<Scalars['Float']>;
  futureValue?: Maybe<Scalars['Float']>;
  goalType?: Maybe<Scalars['String']>;
  monthlyContribution?: Maybe<Scalars['Float']>;
  yearsToFund?: Maybe<Scalars['Float']>;
};

export type GoalCalculationResponse = {
  __typename?: 'GoalCalculationResponse';
  errors?: Maybe<Array<FieldError>>;
  value?: Maybe<Scalars['Float']>;
};

export type GoalContributionsInput = {
  memberGoalId: Scalars['Int'];
};

export type GoalContributionsResponse = {
  __typename?: 'GoalContributionsResponse';
  amount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
};

export type GoalSummary = {
  __typename?: 'GoalSummary';
  goal: Scalars['String'];
  goalBalance: Scalars['String'];
  percentageGoalAchieved: Scalars['String'];
};

export type GoalType = {
  __typename?: 'GoalType';
  durationMonths: Scalars['Float'];
  durationYears: Scalars['Float'];
  for: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type HelpMeDecideEducationInput = {
  amountSaved?: Maybe<Scalars['Float']>;
  dependentAge: Scalars['Int'];
  level: Scalars['String'];
  universityAge?: Maybe<Scalars['Int']>;
  universityId: Scalars['Int'];
  yearsToFund: Scalars['Int'];
};

export type HelpMeDecideEducationResponse = {
  __typename?: 'HelpMeDecideEducationResponse';
  durationInMonths?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
  futureValue?: Maybe<Scalars['Float']>;
  monthlyContribution?: Maybe<Scalars['Float']>;
};

export type HelpMeDecideRetirementInput = {
  age: Scalars['Int'];
  ageToRetire?: Maybe<Scalars['Int']>;
  amountSaved?: Maybe<Scalars['Float']>;
  lifestyleId: Scalars['Int'];
  regionId: Scalars['Int'];
  yearsToFund: Scalars['Int'];
};

export type HelpMeDecideRetirementResponse = {
  __typename?: 'HelpMeDecideRetirementResponse';
  durationInMonths?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
  futureValue?: Maybe<Scalars['Float']>;
  monthlyContribution?: Maybe<Scalars['Float']>;
};

export type InsurancePremiumInput = {
  age: Scalars['Int'];
  contribution?: Maybe<Scalars['Float']>;
  duration: Scalars['Float'];
  insuranceTypeId: Scalars['Int'];
  sumAssured?: Maybe<Scalars['Float']>;
};

export type InsurancePremiumResponse = {
  __typename?: 'InsurancePremiumResponse';
  errors?: Maybe<Array<FieldError>>;
  monthlyPremium?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  sumAssured?: Maybe<Scalars['Float']>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LoadPaymentsInput = {
  account?: Maybe<Scalars['String']>;
  business_date?: Maybe<Scalars['String']>;
  fp_id?: Maybe<Scalars['String']>;
  is_migrated: Scalars['Boolean'];
  mf_id?: Maybe<Scalars['String']>;
  payment_mode?: Maybe<Scalars['String']>;
  split?: Maybe<Scalars['String']>;
  transaction_amount: Scalars['Float'];
  transaction_date: Scalars['String'];
  transaction_details?: Maybe<Scalars['String']>;
  value_date: Scalars['String'];
};

export type LoadPaymentsResponse = {
  __typename?: 'LoadPaymentsResponse';
  errors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean'];
};

export type LoginInput = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<FieldError>>;
  id?: Maybe<Scalars['Float']>;
  shouldUpdatePassword?: Maybe<Scalars['Boolean']>;
};

export type MeResponse = {
  __typename?: 'MeResponse';
  errors?: Maybe<Array<FieldError>>;
  user_type?: Maybe<UserType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approvePayments: ApprovePaymentResponse;
  calculateExpectedReturn: ExpectedReturnResponse;
  calculateGoalBalances: GoalBalancesResponse;
  calculateGoalContributions: GoalContributionsResponse;
  calculateInsurancePremium: InsurancePremiumResponse;
  calculateTotalBalance: TotalBalanceResponse;
  calculateTotalContributions: ContributionsResponse;
  createCustomer: CreateCustomerResponse;
  createDeletePermission: PermissionResponse;
  createInsertPermission: PermissionResponse;
  createSelectPermission: PermissionResponse;
  createUpdatePermission: PermissionResponse;
  dropDeletePermission: PermissionResponse;
  dropInsertPermission: PermissionResponse;
  dropSelectPermission: PermissionResponse;
  dropUpdatePermission: PermissionResponse;
  generateSignedS3RequestForDownload: S3PayloadResponse;
  generateSignedS3RequestForUpload: S3PayloadResponse;
  generateStatement: GenerateStatementResponse;
  goalCalculations: GoalCalculationResponse;
  help_me_decide_education: HelpMeDecideEducationResponse;
  help_me_decide_retirement: HelpMeDecideRetirementResponse;
  loadPayments: LoadPaymentsResponse;
  login: LoginResponse;
  logout: Scalars['Boolean'];
  onlinePayments: OnlinePaymentResponse;
  proposeGoals: ProposeGoalsResponse;
  redeem: RedeemResponse;
  register: RegisterResponse;
  registerCustomer: RegisterResponse;
  resetPassword: PasswordResetResponse;
  sendWelcomePack: SendWelcomePackResponse;
};


export type MutationApprovePaymentsArgs = {
  data: ApprovePaymentInput;
};


export type MutationCalculateExpectedReturnArgs = {
  data: ExpectedReturnInput;
};


export type MutationCalculateGoalBalancesArgs = {
  data: GoalBalancesInput;
};


export type MutationCalculateGoalContributionsArgs = {
  data: GoalContributionsInput;
};


export type MutationCalculateInsurancePremiumArgs = {
  data: InsurancePremiumInput;
};


export type MutationCalculateTotalBalanceArgs = {
  data: TotalBalanceInput;
};


export type MutationCalculateTotalContributionsArgs = {
  data: ContributionsInput;
};


export type MutationCreateCustomerArgs = {
  data: CreateCustomerInput;
};


export type MutationCreateDeletePermissionArgs = {
  data: PermissionInput;
};


export type MutationCreateInsertPermissionArgs = {
  data: PermissionInput;
};


export type MutationCreateSelectPermissionArgs = {
  data: PermissionInput;
};


export type MutationCreateUpdatePermissionArgs = {
  data: PermissionInput;
};


export type MutationDropDeletePermissionArgs = {
  data: PermissionInput;
};


export type MutationDropInsertPermissionArgs = {
  data: PermissionInput;
};


export type MutationDropSelectPermissionArgs = {
  data: PermissionInput;
};


export type MutationDropUpdatePermissionArgs = {
  data: PermissionInput;
};


export type MutationGenerateSignedS3RequestForDownloadArgs = {
  data: S3FileInput;
};


export type MutationGenerateSignedS3RequestForUploadArgs = {
  data: S3FileInput;
};


export type MutationGenerateStatementArgs = {
  data: GenerateStatementInput;
};


export type MutationGoalCalculationsArgs = {
  data: GoalCalculationInput;
};


export type MutationHelp_Me_Decide_EducationArgs = {
  data: HelpMeDecideEducationInput;
};


export type MutationHelp_Me_Decide_RetirementArgs = {
  data: HelpMeDecideRetirementInput;
};


export type MutationLoadPaymentsArgs = {
  data: Array<LoadPaymentsInput>;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationOnlinePaymentsArgs = {
  data: OnlinePaymentInput;
};


export type MutationProposeGoalsArgs = {
  data: ProposeGoalsInput;
};


export type MutationRedeemArgs = {
  data: RedeemInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationRegisterCustomerArgs = {
  data: CustomerRegisterInput;
};


export type MutationResetPasswordArgs = {
  data: PasswordResetInput;
};


export type MutationSendWelcomePackArgs = {
  data: SendWelcomePackInput;
};

export type OnlinePaymentInput = {
  amount: Scalars['Float'];
  customerId: Scalars['Float'];
};

export type OnlinePaymentResponse = {
  __typename?: 'OnlinePaymentResponse';
  errors?: Maybe<Array<FieldError>>;
  paymentUrl?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type PasswordInput = {
  password: Scalars['String'];
};

export type PasswordResetInput = {
  newPassword: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type PasswordResetResponse = {
  __typename?: 'PasswordResetResponse';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<FieldError>>;
  id?: Maybe<Scalars['Float']>;
};

export type PermissionInput = {
  args: ArgsType;
};

export type PermissionResponse = {
  __typename?: 'PermissionResponse';
  errors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean'];
};

export type ProductPayment = {
  __typename?: 'ProductPayment';
  date: Scalars['String'];
  firstPaymentDate: Scalars['String'];
  payments: Array<AggregatedPayment>;
  total: Scalars['String'];
  verboseDate: Scalars['String'];
};

export type ProposeGoalsInput = {
  age?: Maybe<Scalars['Float']>;
  currentSavings?: Maybe<Scalars['Float']>;
  dependents?: Maybe<Array<DependentType>>;
  isMarried?: Maybe<Scalars['Boolean']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  monthlyIncome?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type ProposeGoalsResponse = {
  __typename?: 'ProposeGoalsResponse';
  errors?: Maybe<Array<FieldError>>;
  goals?: Maybe<Array<GoalType>>;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  isEmailAvailable: EmailAvailabilityResponse;
  me: MeResponse;
};


export type QueryIsEmailAvailableArgs = {
  data: EmailInput;
};

export type RedeemInput = {
  action?: Maybe<Scalars['String']>;
  goalAmounts?: Maybe<Array<GoalAmountsType>>;
  memberId: Scalars['Int'];
  redemptionId?: Maybe<Scalars['Int']>;
};

export type RedeemResponse = {
  __typename?: 'RedeemResponse';
  charges?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
  netAmount?: Maybe<Scalars['Float']>;
  redemptionId?: Maybe<Scalars['Int']>;
};

export type RegisterInput = {
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  genderId: Scalars['Float'];
  isPasswordPermanent?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  otherName?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  userRole?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  errors?: Maybe<Array<FieldError>>;
  id?: Maybe<Scalars['Float']>;
  success: Scalars['Boolean'];
};

export type S3FileInput = {
  fileType?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
};

export type S3PayloadResponse = {
  __typename?: 'S3PayloadResponse';
  errors?: Maybe<Array<FieldError>>;
  signedRequest?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SendWelcomePackInput = {
  id: Scalars['Int'];
};

export type SendWelcomePackResponse = {
  __typename?: 'SendWelcomePackResponse';
  errors?: Maybe<Array<FieldError>>;
  success: Scalars['Boolean'];
};

export type StatementType = {
  __typename?: 'StatementType';
  beginningBalance: Scalars['String'];
  contributions: Scalars['String'];
  customerDetails: Array<CustomerDetail>;
  endDate?: Maybe<Scalars['String']>;
  endOfPeriodOfBalance: Scalars['String'];
  goalAllocations: Array<GoalAllocation>;
  goalSummary: Array<GoalSummary>;
  goalSummaryTotal: Scalars['String'];
  payments: Array<ProductPayment>;
  redemptions: Scalars['String'];
  startDate: Scalars['String'];
  totalPeriodNetIncome: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type TotalBalanceInput = {
  memberId: Scalars['Int'];
};

export type TotalBalanceResponse = {
  __typename?: 'TotalBalanceResponse';
  balance?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserType = {
  __typename?: 'UserType';
  email: Scalars['String'];
  fullname: Scalars['String'];
  guid: Scalars['String'];
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "advisor" */
export type Advisor = {
  __typename?: 'advisor';
  advisor_code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  identification_number: Scalars['String'];
  /** An object relationship */
  identification_type: Identification_Type;
  identification_type_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  residential_address: Scalars['String'];
  sequence?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
  /** An object relationship */
  userById: User;
};

/** aggregated selection of "advisor" */
export type Advisor_Aggregate = {
  __typename?: 'advisor_aggregate';
  aggregate?: Maybe<Advisor_Aggregate_Fields>;
  nodes: Array<Advisor>;
};

/** aggregate fields of "advisor" */
export type Advisor_Aggregate_Fields = {
  __typename?: 'advisor_aggregate_fields';
  avg?: Maybe<Advisor_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Advisor_Max_Fields>;
  min?: Maybe<Advisor_Min_Fields>;
  stddev?: Maybe<Advisor_Stddev_Fields>;
  stddev_pop?: Maybe<Advisor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Advisor_Stddev_Samp_Fields>;
  sum?: Maybe<Advisor_Sum_Fields>;
  var_pop?: Maybe<Advisor_Var_Pop_Fields>;
  var_samp?: Maybe<Advisor_Var_Samp_Fields>;
  variance?: Maybe<Advisor_Variance_Fields>;
};


/** aggregate fields of "advisor" */
export type Advisor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Advisor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "advisor" */
export type Advisor_Aggregate_Order_By = {
  avg?: Maybe<Advisor_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Advisor_Max_Order_By>;
  min?: Maybe<Advisor_Min_Order_By>;
  stddev?: Maybe<Advisor_Stddev_Order_By>;
  stddev_pop?: Maybe<Advisor_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Advisor_Stddev_Samp_Order_By>;
  sum?: Maybe<Advisor_Sum_Order_By>;
  var_pop?: Maybe<Advisor_Var_Pop_Order_By>;
  var_samp?: Maybe<Advisor_Var_Samp_Order_By>;
  variance?: Maybe<Advisor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "advisor" */
export type Advisor_Arr_Rel_Insert_Input = {
  data: Array<Advisor_Insert_Input>;
  on_conflict?: Maybe<Advisor_On_Conflict>;
};

/** aggregate avg on columns */
export type Advisor_Avg_Fields = {
  __typename?: 'advisor_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "advisor" */
export type Advisor_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "advisor". All fields are combined with a logical 'AND'. */
export type Advisor_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Advisor_Bool_Exp>>>;
  _not?: Maybe<Advisor_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Advisor_Bool_Exp>>>;
  advisor_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  identification_number?: Maybe<String_Comparison_Exp>;
  identification_type?: Maybe<Identification_Type_Bool_Exp>;
  identification_type_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  residential_address?: Maybe<String_Comparison_Exp>;
  sequence?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
  userById?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "advisor" */
export enum Advisor_Constraint {
  /** unique or primary key constraint */
  AdvisorAdvisorCodeKey = 'advisor_advisor_code_key',
  /** unique or primary key constraint */
  AdvisorPkey = 'advisor_pkey'
}

/** input type for incrementing integer column in table "advisor" */
export type Advisor_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "advisor" */
export type Advisor_Insert_Input = {
  advisor_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type?: Maybe<Identification_Type_Obj_Rel_Insert_Input>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  residential_address?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
  userById?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Advisor_Max_Fields = {
  __typename?: 'advisor_max_fields';
  advisor_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  residential_address?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "advisor" */
export type Advisor_Max_Order_By = {
  advisor_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  residential_address?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Advisor_Min_Fields = {
  __typename?: 'advisor_min_fields';
  advisor_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  residential_address?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "advisor" */
export type Advisor_Min_Order_By = {
  advisor_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  residential_address?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "advisor" */
export type Advisor_Mutation_Response = {
  __typename?: 'advisor_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Advisor>;
};

/** input type for inserting object relation for remote table "advisor" */
export type Advisor_Obj_Rel_Insert_Input = {
  data: Advisor_Insert_Input;
  on_conflict?: Maybe<Advisor_On_Conflict>;
};

/** on conflict condition type for table "advisor" */
export type Advisor_On_Conflict = {
  constraint: Advisor_Constraint;
  update_columns: Array<Advisor_Update_Column>;
  where?: Maybe<Advisor_Bool_Exp>;
};

/** ordering options when selecting data from "advisor" */
export type Advisor_Order_By = {
  advisor_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type?: Maybe<Identification_Type_Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  residential_address?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
  userById?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "advisor" */
export type Advisor_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "advisor" */
export enum Advisor_Select_Column {
  /** column name */
  AdvisorCode = 'advisor_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  IdentificationTypeId = 'identification_type_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ResidentialAddress = 'residential_address',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "advisor" */
export type Advisor_Set_Input = {
  advisor_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  residential_address?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Advisor_Stddev_Fields = {
  __typename?: 'advisor_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "advisor" */
export type Advisor_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Advisor_Stddev_Pop_Fields = {
  __typename?: 'advisor_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "advisor" */
export type Advisor_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Advisor_Stddev_Samp_Fields = {
  __typename?: 'advisor_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "advisor" */
export type Advisor_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Advisor_Sum_Fields = {
  __typename?: 'advisor_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  sequence?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "advisor" */
export type Advisor_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** update columns of table "advisor" */
export enum Advisor_Update_Column {
  /** column name */
  AdvisorCode = 'advisor_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  IdentificationTypeId = 'identification_type_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ResidentialAddress = 'residential_address',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Advisor_Var_Pop_Fields = {
  __typename?: 'advisor_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "advisor" */
export type Advisor_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Advisor_Var_Samp_Fields = {
  __typename?: 'advisor_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "advisor" */
export type Advisor_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Advisor_Variance_Fields = {
  __typename?: 'advisor_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "advisor" */
export type Advisor_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
};

/** columns and relationships of "all_tables_template" */
export type All_Tables_Template = {
  __typename?: 'all_tables_template';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};

/** aggregated selection of "all_tables_template" */
export type All_Tables_Template_Aggregate = {
  __typename?: 'all_tables_template_aggregate';
  aggregate?: Maybe<All_Tables_Template_Aggregate_Fields>;
  nodes: Array<All_Tables_Template>;
};

/** aggregate fields of "all_tables_template" */
export type All_Tables_Template_Aggregate_Fields = {
  __typename?: 'all_tables_template_aggregate_fields';
  avg?: Maybe<All_Tables_Template_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<All_Tables_Template_Max_Fields>;
  min?: Maybe<All_Tables_Template_Min_Fields>;
  stddev?: Maybe<All_Tables_Template_Stddev_Fields>;
  stddev_pop?: Maybe<All_Tables_Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<All_Tables_Template_Stddev_Samp_Fields>;
  sum?: Maybe<All_Tables_Template_Sum_Fields>;
  var_pop?: Maybe<All_Tables_Template_Var_Pop_Fields>;
  var_samp?: Maybe<All_Tables_Template_Var_Samp_Fields>;
  variance?: Maybe<All_Tables_Template_Variance_Fields>;
};


/** aggregate fields of "all_tables_template" */
export type All_Tables_Template_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<All_Tables_Template_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "all_tables_template" */
export type All_Tables_Template_Aggregate_Order_By = {
  avg?: Maybe<All_Tables_Template_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<All_Tables_Template_Max_Order_By>;
  min?: Maybe<All_Tables_Template_Min_Order_By>;
  stddev?: Maybe<All_Tables_Template_Stddev_Order_By>;
  stddev_pop?: Maybe<All_Tables_Template_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<All_Tables_Template_Stddev_Samp_Order_By>;
  sum?: Maybe<All_Tables_Template_Sum_Order_By>;
  var_pop?: Maybe<All_Tables_Template_Var_Pop_Order_By>;
  var_samp?: Maybe<All_Tables_Template_Var_Samp_Order_By>;
  variance?: Maybe<All_Tables_Template_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "all_tables_template" */
export type All_Tables_Template_Arr_Rel_Insert_Input = {
  data: Array<All_Tables_Template_Insert_Input>;
  on_conflict?: Maybe<All_Tables_Template_On_Conflict>;
};

/** aggregate avg on columns */
export type All_Tables_Template_Avg_Fields = {
  __typename?: 'all_tables_template_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "all_tables_template" */
export type All_Tables_Template_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "all_tables_template". All fields are combined with a logical 'AND'. */
export type All_Tables_Template_Bool_Exp = {
  _and?: Maybe<Array<Maybe<All_Tables_Template_Bool_Exp>>>;
  _not?: Maybe<All_Tables_Template_Bool_Exp>;
  _or?: Maybe<Array<Maybe<All_Tables_Template_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "all_tables_template" */
export enum All_Tables_Template_Constraint {
  /** unique or primary key constraint */
  AllTablesTemplatePkey = 'all_tables_template_pkey'
}

/** input type for incrementing integer column in table "all_tables_template" */
export type All_Tables_Template_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "all_tables_template" */
export type All_Tables_Template_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type All_Tables_Template_Max_Fields = {
  __typename?: 'all_tables_template_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "all_tables_template" */
export type All_Tables_Template_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type All_Tables_Template_Min_Fields = {
  __typename?: 'all_tables_template_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "all_tables_template" */
export type All_Tables_Template_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "all_tables_template" */
export type All_Tables_Template_Mutation_Response = {
  __typename?: 'all_tables_template_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<All_Tables_Template>;
};

/** input type for inserting object relation for remote table "all_tables_template" */
export type All_Tables_Template_Obj_Rel_Insert_Input = {
  data: All_Tables_Template_Insert_Input;
  on_conflict?: Maybe<All_Tables_Template_On_Conflict>;
};

/** on conflict condition type for table "all_tables_template" */
export type All_Tables_Template_On_Conflict = {
  constraint: All_Tables_Template_Constraint;
  update_columns: Array<All_Tables_Template_Update_Column>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};

/** ordering options when selecting data from "all_tables_template" */
export type All_Tables_Template_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "all_tables_template" */
export type All_Tables_Template_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "all_tables_template" */
export enum All_Tables_Template_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "all_tables_template" */
export type All_Tables_Template_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type All_Tables_Template_Stddev_Fields = {
  __typename?: 'all_tables_template_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "all_tables_template" */
export type All_Tables_Template_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type All_Tables_Template_Stddev_Pop_Fields = {
  __typename?: 'all_tables_template_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "all_tables_template" */
export type All_Tables_Template_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type All_Tables_Template_Stddev_Samp_Fields = {
  __typename?: 'all_tables_template_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "all_tables_template" */
export type All_Tables_Template_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type All_Tables_Template_Sum_Fields = {
  __typename?: 'all_tables_template_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "all_tables_template" */
export type All_Tables_Template_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "all_tables_template" */
export enum All_Tables_Template_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type All_Tables_Template_Var_Pop_Fields = {
  __typename?: 'all_tables_template_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "all_tables_template" */
export type All_Tables_Template_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type All_Tables_Template_Var_Samp_Fields = {
  __typename?: 'all_tables_template_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "all_tables_template" */
export type All_Tables_Template_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type All_Tables_Template_Variance_Fields = {
  __typename?: 'all_tables_template_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "all_tables_template" */
export type All_Tables_Template_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "apk" */
export type Apk = {
  __typename?: 'apk';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['bigint'];
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** aggregated selection of "apk" */
export type Apk_Aggregate = {
  __typename?: 'apk_aggregate';
  aggregate?: Maybe<Apk_Aggregate_Fields>;
  nodes: Array<Apk>;
};

/** aggregate fields of "apk" */
export type Apk_Aggregate_Fields = {
  __typename?: 'apk_aggregate_fields';
  avg?: Maybe<Apk_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Apk_Max_Fields>;
  min?: Maybe<Apk_Min_Fields>;
  stddev?: Maybe<Apk_Stddev_Fields>;
  stddev_pop?: Maybe<Apk_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Apk_Stddev_Samp_Fields>;
  sum?: Maybe<Apk_Sum_Fields>;
  var_pop?: Maybe<Apk_Var_Pop_Fields>;
  var_samp?: Maybe<Apk_Var_Samp_Fields>;
  variance?: Maybe<Apk_Variance_Fields>;
};


/** aggregate fields of "apk" */
export type Apk_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Apk_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "apk" */
export type Apk_Aggregate_Order_By = {
  avg?: Maybe<Apk_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Apk_Max_Order_By>;
  min?: Maybe<Apk_Min_Order_By>;
  stddev?: Maybe<Apk_Stddev_Order_By>;
  stddev_pop?: Maybe<Apk_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Apk_Stddev_Samp_Order_By>;
  sum?: Maybe<Apk_Sum_Order_By>;
  var_pop?: Maybe<Apk_Var_Pop_Order_By>;
  var_samp?: Maybe<Apk_Var_Samp_Order_By>;
  variance?: Maybe<Apk_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "apk" */
export type Apk_Arr_Rel_Insert_Input = {
  data: Array<Apk_Insert_Input>;
  on_conflict?: Maybe<Apk_On_Conflict>;
};

/** aggregate avg on columns */
export type Apk_Avg_Fields = {
  __typename?: 'apk_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "apk" */
export type Apk_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "apk". All fields are combined with a logical 'AND'. */
export type Apk_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Apk_Bool_Exp>>>;
  _not?: Maybe<Apk_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Apk_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "apk" */
export enum Apk_Constraint {
  /** unique or primary key constraint */
  ApkPkey = 'apk_pkey',
  /** unique or primary key constraint */
  ApkVersionKey = 'apk_version_key'
}

/** input type for incrementing integer column in table "apk" */
export type Apk_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "apk" */
export type Apk_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Apk_Max_Fields = {
  __typename?: 'apk_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "apk" */
export type Apk_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Apk_Min_Fields = {
  __typename?: 'apk_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "apk" */
export type Apk_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "apk" */
export type Apk_Mutation_Response = {
  __typename?: 'apk_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Apk>;
};

/** input type for inserting object relation for remote table "apk" */
export type Apk_Obj_Rel_Insert_Input = {
  data: Apk_Insert_Input;
  on_conflict?: Maybe<Apk_On_Conflict>;
};

/** on conflict condition type for table "apk" */
export type Apk_On_Conflict = {
  constraint: Apk_Constraint;
  update_columns: Array<Apk_Update_Column>;
  where?: Maybe<Apk_Bool_Exp>;
};

/** ordering options when selecting data from "apk" */
export type Apk_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: "apk" */
export type Apk_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "apk" */
export enum Apk_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "apk" */
export type Apk_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Apk_Stddev_Fields = {
  __typename?: 'apk_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "apk" */
export type Apk_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Apk_Stddev_Pop_Fields = {
  __typename?: 'apk_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "apk" */
export type Apk_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Apk_Stddev_Samp_Fields = {
  __typename?: 'apk_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "apk" */
export type Apk_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Apk_Sum_Fields = {
  __typename?: 'apk_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "apk" */
export type Apk_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "apk" */
export enum Apk_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type Apk_Var_Pop_Fields = {
  __typename?: 'apk_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "apk" */
export type Apk_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Apk_Var_Samp_Fields = {
  __typename?: 'apk_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "apk" */
export type Apk_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Apk_Variance_Fields = {
  __typename?: 'apk_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "apk" */
export type Apk_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "app_config" */
export type App_Config = {
  __typename?: 'app_config';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "app_config" */
export type App_Config_Aggregate = {
  __typename?: 'app_config_aggregate';
  aggregate?: Maybe<App_Config_Aggregate_Fields>;
  nodes: Array<App_Config>;
};

/** aggregate fields of "app_config" */
export type App_Config_Aggregate_Fields = {
  __typename?: 'app_config_aggregate_fields';
  avg?: Maybe<App_Config_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<App_Config_Max_Fields>;
  min?: Maybe<App_Config_Min_Fields>;
  stddev?: Maybe<App_Config_Stddev_Fields>;
  stddev_pop?: Maybe<App_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<App_Config_Stddev_Samp_Fields>;
  sum?: Maybe<App_Config_Sum_Fields>;
  var_pop?: Maybe<App_Config_Var_Pop_Fields>;
  var_samp?: Maybe<App_Config_Var_Samp_Fields>;
  variance?: Maybe<App_Config_Variance_Fields>;
};


/** aggregate fields of "app_config" */
export type App_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<App_Config_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_config" */
export type App_Config_Aggregate_Order_By = {
  avg?: Maybe<App_Config_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<App_Config_Max_Order_By>;
  min?: Maybe<App_Config_Min_Order_By>;
  stddev?: Maybe<App_Config_Stddev_Order_By>;
  stddev_pop?: Maybe<App_Config_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<App_Config_Stddev_Samp_Order_By>;
  sum?: Maybe<App_Config_Sum_Order_By>;
  var_pop?: Maybe<App_Config_Var_Pop_Order_By>;
  var_samp?: Maybe<App_Config_Var_Samp_Order_By>;
  variance?: Maybe<App_Config_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "app_config" */
export type App_Config_Arr_Rel_Insert_Input = {
  data: Array<App_Config_Insert_Input>;
  on_conflict?: Maybe<App_Config_On_Conflict>;
};

/** aggregate avg on columns */
export type App_Config_Avg_Fields = {
  __typename?: 'app_config_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "app_config" */
export type App_Config_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "app_config". All fields are combined with a logical 'AND'. */
export type App_Config_Bool_Exp = {
  _and?: Maybe<Array<Maybe<App_Config_Bool_Exp>>>;
  _not?: Maybe<App_Config_Bool_Exp>;
  _or?: Maybe<Array<Maybe<App_Config_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Bigint_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "app_config" */
export enum App_Config_Constraint {
  /** unique or primary key constraint */
  AppConfigNameKey = 'app_config_name_key',
  /** unique or primary key constraint */
  AppConfigPkey = 'app_config_pkey'
}

/** input type for incrementing integer column in table "app_config" */
export type App_Config_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "app_config" */
export type App_Config_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type App_Config_Max_Fields = {
  __typename?: 'app_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "app_config" */
export type App_Config_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type App_Config_Min_Fields = {
  __typename?: 'app_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "app_config" */
export type App_Config_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "app_config" */
export type App_Config_Mutation_Response = {
  __typename?: 'app_config_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<App_Config>;
};

/** input type for inserting object relation for remote table "app_config" */
export type App_Config_Obj_Rel_Insert_Input = {
  data: App_Config_Insert_Input;
  on_conflict?: Maybe<App_Config_On_Conflict>;
};

/** on conflict condition type for table "app_config" */
export type App_Config_On_Conflict = {
  constraint: App_Config_Constraint;
  update_columns: Array<App_Config_Update_Column>;
  where?: Maybe<App_Config_Bool_Exp>;
};

/** ordering options when selecting data from "app_config" */
export type App_Config_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "app_config" */
export type App_Config_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "app_config" */
export enum App_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "app_config" */
export type App_Config_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type App_Config_Stddev_Fields = {
  __typename?: 'app_config_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "app_config" */
export type App_Config_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type App_Config_Stddev_Pop_Fields = {
  __typename?: 'app_config_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "app_config" */
export type App_Config_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type App_Config_Stddev_Samp_Fields = {
  __typename?: 'app_config_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "app_config" */
export type App_Config_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type App_Config_Sum_Fields = {
  __typename?: 'app_config_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "app_config" */
export type App_Config_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** update columns of table "app_config" */
export enum App_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type App_Config_Var_Pop_Fields = {
  __typename?: 'app_config_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "app_config" */
export type App_Config_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type App_Config_Var_Samp_Fields = {
  __typename?: 'app_config_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "app_config" */
export type App_Config_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type App_Config_Variance_Fields = {
  __typename?: 'app_config_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "app_config" */
export type App_Config_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** columns and relationships of "asset_allocation" */
export type Asset_Allocation = {
  __typename?: 'asset_allocation';
  /** An object relationship */
  asset_duration: Asset_Duration;
  asset_duration_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  weight: Scalars['numeric'];
};

/** aggregated selection of "asset_allocation" */
export type Asset_Allocation_Aggregate = {
  __typename?: 'asset_allocation_aggregate';
  aggregate?: Maybe<Asset_Allocation_Aggregate_Fields>;
  nodes: Array<Asset_Allocation>;
};

/** aggregate fields of "asset_allocation" */
export type Asset_Allocation_Aggregate_Fields = {
  __typename?: 'asset_allocation_aggregate_fields';
  avg?: Maybe<Asset_Allocation_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Asset_Allocation_Max_Fields>;
  min?: Maybe<Asset_Allocation_Min_Fields>;
  stddev?: Maybe<Asset_Allocation_Stddev_Fields>;
  stddev_pop?: Maybe<Asset_Allocation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Asset_Allocation_Stddev_Samp_Fields>;
  sum?: Maybe<Asset_Allocation_Sum_Fields>;
  var_pop?: Maybe<Asset_Allocation_Var_Pop_Fields>;
  var_samp?: Maybe<Asset_Allocation_Var_Samp_Fields>;
  variance?: Maybe<Asset_Allocation_Variance_Fields>;
};


/** aggregate fields of "asset_allocation" */
export type Asset_Allocation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Asset_Allocation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_allocation" */
export type Asset_Allocation_Aggregate_Order_By = {
  avg?: Maybe<Asset_Allocation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Asset_Allocation_Max_Order_By>;
  min?: Maybe<Asset_Allocation_Min_Order_By>;
  stddev?: Maybe<Asset_Allocation_Stddev_Order_By>;
  stddev_pop?: Maybe<Asset_Allocation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Asset_Allocation_Stddev_Samp_Order_By>;
  sum?: Maybe<Asset_Allocation_Sum_Order_By>;
  var_pop?: Maybe<Asset_Allocation_Var_Pop_Order_By>;
  var_samp?: Maybe<Asset_Allocation_Var_Samp_Order_By>;
  variance?: Maybe<Asset_Allocation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "asset_allocation" */
export type Asset_Allocation_Arr_Rel_Insert_Input = {
  data: Array<Asset_Allocation_Insert_Input>;
  on_conflict?: Maybe<Asset_Allocation_On_Conflict>;
};

/** aggregate avg on columns */
export type Asset_Allocation_Avg_Fields = {
  __typename?: 'asset_allocation_avg_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "asset_allocation" */
export type Asset_Allocation_Avg_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "asset_allocation". All fields are combined with a logical 'AND'. */
export type Asset_Allocation_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Asset_Allocation_Bool_Exp>>>;
  _not?: Maybe<Asset_Allocation_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Asset_Allocation_Bool_Exp>>>;
  asset_duration?: Maybe<Asset_Duration_Bool_Exp>;
  asset_duration_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  weight?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "asset_allocation" */
export enum Asset_Allocation_Constraint {
  /** unique or primary key constraint */
  AssetAllocationPkey = 'asset_allocation_pkey'
}

/** input type for incrementing integer column in table "asset_allocation" */
export type Asset_Allocation_Inc_Input = {
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "asset_allocation" */
export type Asset_Allocation_Insert_Input = {
  asset_duration?: Maybe<Asset_Duration_Obj_Rel_Insert_Input>;
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Asset_Allocation_Max_Fields = {
  __typename?: 'asset_allocation_max_fields';
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "asset_allocation" */
export type Asset_Allocation_Max_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Asset_Allocation_Min_Fields = {
  __typename?: 'asset_allocation_min_fields';
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "asset_allocation" */
export type Asset_Allocation_Min_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** response of any mutation on the table "asset_allocation" */
export type Asset_Allocation_Mutation_Response = {
  __typename?: 'asset_allocation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Asset_Allocation>;
};

/** input type for inserting object relation for remote table "asset_allocation" */
export type Asset_Allocation_Obj_Rel_Insert_Input = {
  data: Asset_Allocation_Insert_Input;
  on_conflict?: Maybe<Asset_Allocation_On_Conflict>;
};

/** on conflict condition type for table "asset_allocation" */
export type Asset_Allocation_On_Conflict = {
  constraint: Asset_Allocation_Constraint;
  update_columns: Array<Asset_Allocation_Update_Column>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};

/** ordering options when selecting data from "asset_allocation" */
export type Asset_Allocation_Order_By = {
  asset_duration?: Maybe<Asset_Duration_Order_By>;
  asset_duration_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** primary key columns input for table: "asset_allocation" */
export type Asset_Allocation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "asset_allocation" */
export enum Asset_Allocation_Select_Column {
  /** column name */
  AssetDurationId = 'asset_duration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

/** input type for updating data in table "asset_allocation" */
export type Asset_Allocation_Set_Input = {
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Asset_Allocation_Stddev_Fields = {
  __typename?: 'asset_allocation_stddev_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "asset_allocation" */
export type Asset_Allocation_Stddev_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Asset_Allocation_Stddev_Pop_Fields = {
  __typename?: 'asset_allocation_stddev_pop_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "asset_allocation" */
export type Asset_Allocation_Stddev_Pop_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Asset_Allocation_Stddev_Samp_Fields = {
  __typename?: 'asset_allocation_stddev_samp_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "asset_allocation" */
export type Asset_Allocation_Stddev_Samp_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Asset_Allocation_Sum_Fields = {
  __typename?: 'asset_allocation_sum_fields';
  asset_duration_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  weight?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "asset_allocation" */
export type Asset_Allocation_Sum_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** update columns of table "asset_allocation" */
export enum Asset_Allocation_Update_Column {
  /** column name */
  AssetDurationId = 'asset_duration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight'
}

/** aggregate var_pop on columns */
export type Asset_Allocation_Var_Pop_Fields = {
  __typename?: 'asset_allocation_var_pop_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "asset_allocation" */
export type Asset_Allocation_Var_Pop_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Asset_Allocation_Var_Samp_Fields = {
  __typename?: 'asset_allocation_var_samp_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "asset_allocation" */
export type Asset_Allocation_Var_Samp_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Asset_Allocation_Variance_Fields = {
  __typename?: 'asset_allocation_variance_fields';
  asset_duration_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "asset_allocation" */
export type Asset_Allocation_Variance_Order_By = {
  asset_duration_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  weight?: Maybe<Order_By>;
};

/** columns and relationships of "asset_duration" */
export type Asset_Duration = {
  __typename?: 'asset_duration';
  /** An array relationship */
  asset_allocations: Array<Asset_Allocation>;
  /** An aggregated array relationship */
  asset_allocations_aggregate: Asset_Allocation_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  duration_end: Scalars['Int'];
  duration_start: Scalars['Int'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};


/** columns and relationships of "asset_duration" */
export type Asset_DurationAsset_AllocationsArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** columns and relationships of "asset_duration" */
export type Asset_DurationAsset_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};

/** aggregated selection of "asset_duration" */
export type Asset_Duration_Aggregate = {
  __typename?: 'asset_duration_aggregate';
  aggregate?: Maybe<Asset_Duration_Aggregate_Fields>;
  nodes: Array<Asset_Duration>;
};

/** aggregate fields of "asset_duration" */
export type Asset_Duration_Aggregate_Fields = {
  __typename?: 'asset_duration_aggregate_fields';
  avg?: Maybe<Asset_Duration_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Asset_Duration_Max_Fields>;
  min?: Maybe<Asset_Duration_Min_Fields>;
  stddev?: Maybe<Asset_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Asset_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Asset_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Asset_Duration_Sum_Fields>;
  var_pop?: Maybe<Asset_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Asset_Duration_Var_Samp_Fields>;
  variance?: Maybe<Asset_Duration_Variance_Fields>;
};


/** aggregate fields of "asset_duration" */
export type Asset_Duration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Asset_Duration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "asset_duration" */
export type Asset_Duration_Aggregate_Order_By = {
  avg?: Maybe<Asset_Duration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Asset_Duration_Max_Order_By>;
  min?: Maybe<Asset_Duration_Min_Order_By>;
  stddev?: Maybe<Asset_Duration_Stddev_Order_By>;
  stddev_pop?: Maybe<Asset_Duration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Asset_Duration_Stddev_Samp_Order_By>;
  sum?: Maybe<Asset_Duration_Sum_Order_By>;
  var_pop?: Maybe<Asset_Duration_Var_Pop_Order_By>;
  var_samp?: Maybe<Asset_Duration_Var_Samp_Order_By>;
  variance?: Maybe<Asset_Duration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "asset_duration" */
export type Asset_Duration_Arr_Rel_Insert_Input = {
  data: Array<Asset_Duration_Insert_Input>;
  on_conflict?: Maybe<Asset_Duration_On_Conflict>;
};

/** aggregate avg on columns */
export type Asset_Duration_Avg_Fields = {
  __typename?: 'asset_duration_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "asset_duration" */
export type Asset_Duration_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "asset_duration". All fields are combined with a logical 'AND'. */
export type Asset_Duration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Asset_Duration_Bool_Exp>>>;
  _not?: Maybe<Asset_Duration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Asset_Duration_Bool_Exp>>>;
  asset_allocations?: Maybe<Asset_Allocation_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  duration_end?: Maybe<Int_Comparison_Exp>;
  duration_start?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "asset_duration" */
export enum Asset_Duration_Constraint {
  /** unique or primary key constraint */
  AssetDurationDurationStartDurationEndKey = 'asset_duration_duration_start_duration_end_key',
  /** unique or primary key constraint */
  AssetDurationPkey = 'asset_duration_pkey'
}

/** input type for incrementing integer column in table "asset_duration" */
export type Asset_Duration_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "asset_duration" */
export type Asset_Duration_Insert_Input = {
  asset_allocations?: Maybe<Asset_Allocation_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Asset_Duration_Max_Fields = {
  __typename?: 'asset_duration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "asset_duration" */
export type Asset_Duration_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Asset_Duration_Min_Fields = {
  __typename?: 'asset_duration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "asset_duration" */
export type Asset_Duration_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "asset_duration" */
export type Asset_Duration_Mutation_Response = {
  __typename?: 'asset_duration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Asset_Duration>;
};

/** input type for inserting object relation for remote table "asset_duration" */
export type Asset_Duration_Obj_Rel_Insert_Input = {
  data: Asset_Duration_Insert_Input;
  on_conflict?: Maybe<Asset_Duration_On_Conflict>;
};

/** on conflict condition type for table "asset_duration" */
export type Asset_Duration_On_Conflict = {
  constraint: Asset_Duration_Constraint;
  update_columns: Array<Asset_Duration_Update_Column>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};

/** ordering options when selecting data from "asset_duration" */
export type Asset_Duration_Order_By = {
  asset_allocations_aggregate?: Maybe<Asset_Allocation_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "asset_duration" */
export type Asset_Duration_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "asset_duration" */
export enum Asset_Duration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DurationEnd = 'duration_end',
  /** column name */
  DurationStart = 'duration_start',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "asset_duration" */
export type Asset_Duration_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Asset_Duration_Stddev_Fields = {
  __typename?: 'asset_duration_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "asset_duration" */
export type Asset_Duration_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Asset_Duration_Stddev_Pop_Fields = {
  __typename?: 'asset_duration_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "asset_duration" */
export type Asset_Duration_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Asset_Duration_Stddev_Samp_Fields = {
  __typename?: 'asset_duration_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "asset_duration" */
export type Asset_Duration_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Asset_Duration_Sum_Fields = {
  __typename?: 'asset_duration_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  duration_end?: Maybe<Scalars['Int']>;
  duration_start?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "asset_duration" */
export type Asset_Duration_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "asset_duration" */
export enum Asset_Duration_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DurationEnd = 'duration_end',
  /** column name */
  DurationStart = 'duration_start',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Asset_Duration_Var_Pop_Fields = {
  __typename?: 'asset_duration_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "asset_duration" */
export type Asset_Duration_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Asset_Duration_Var_Samp_Fields = {
  __typename?: 'asset_duration_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "asset_duration" */
export type Asset_Duration_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Asset_Duration_Variance_Fields = {
  __typename?: 'asset_duration_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration_end?: Maybe<Scalars['Float']>;
  duration_start?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "asset_duration" */
export type Asset_Duration_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  duration_end?: Maybe<Order_By>;
  duration_start?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "audit_log" */
export type Audit_Log = {
  __typename?: 'audit_log';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  data?: Maybe<Scalars['json']>;
  deleted: Scalars['Boolean'];
  /** table being logged */
  entity_type?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "audit_log" */
export type Audit_LogDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "audit_log" */
export type Audit_Log_Aggregate = {
  __typename?: 'audit_log_aggregate';
  aggregate?: Maybe<Audit_Log_Aggregate_Fields>;
  nodes: Array<Audit_Log>;
};

/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_Fields = {
  __typename?: 'audit_log_aggregate_fields';
  avg?: Maybe<Audit_Log_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Audit_Log_Max_Fields>;
  min?: Maybe<Audit_Log_Min_Fields>;
  stddev?: Maybe<Audit_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Log_Sum_Fields>;
  var_pop?: Maybe<Audit_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Log_Var_Samp_Fields>;
  variance?: Maybe<Audit_Log_Variance_Fields>;
};


/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audit_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audit_log" */
export type Audit_Log_Aggregate_Order_By = {
  avg?: Maybe<Audit_Log_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audit_Log_Max_Order_By>;
  min?: Maybe<Audit_Log_Min_Order_By>;
  stddev?: Maybe<Audit_Log_Stddev_Order_By>;
  stddev_pop?: Maybe<Audit_Log_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audit_Log_Stddev_Samp_Order_By>;
  sum?: Maybe<Audit_Log_Sum_Order_By>;
  var_pop?: Maybe<Audit_Log_Var_Pop_Order_By>;
  var_samp?: Maybe<Audit_Log_Var_Samp_Order_By>;
  variance?: Maybe<Audit_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "audit_log" */
export type Audit_Log_Arr_Rel_Insert_Input = {
  data: Array<Audit_Log_Insert_Input>;
  on_conflict?: Maybe<Audit_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Audit_Log_Avg_Fields = {
  __typename?: 'audit_log_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audit_log" */
export type Audit_Log_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audit_log". All fields are combined with a logical 'AND'. */
export type Audit_Log_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Audit_Log_Bool_Exp>>>;
  _not?: Maybe<Audit_Log_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Audit_Log_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  data?: Maybe<Json_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  entity_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_log" */
export enum Audit_Log_Constraint {
  /** unique or primary key constraint */
  AuditLogPkey = 'audit_log_pkey'
}

/** input type for incrementing integer column in table "audit_log" */
export type Audit_Log_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit_log" */
export type Audit_Log_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  data?: Maybe<Scalars['json']>;
  deleted?: Maybe<Scalars['Boolean']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Audit_Log_Max_Fields = {
  __typename?: 'audit_log_max_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "audit_log" */
export type Audit_Log_Max_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  entity_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Log_Min_Fields = {
  __typename?: 'audit_log_min_fields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "audit_log" */
export type Audit_Log_Min_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  entity_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "audit_log" */
export type Audit_Log_Mutation_Response = {
  __typename?: 'audit_log_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Audit_Log>;
};

/** input type for inserting object relation for remote table "audit_log" */
export type Audit_Log_Obj_Rel_Insert_Input = {
  data: Audit_Log_Insert_Input;
  on_conflict?: Maybe<Audit_Log_On_Conflict>;
};

/** on conflict condition type for table "audit_log" */
export type Audit_Log_On_Conflict = {
  constraint: Audit_Log_Constraint;
  update_columns: Array<Audit_Log_Update_Column>;
  where?: Maybe<Audit_Log_Bool_Exp>;
};

/** ordering options when selecting data from "audit_log" */
export type Audit_Log_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  entity_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "audit_log" */
export type Audit_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "audit_log" */
export enum Audit_Log_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "audit_log" */
export type Audit_Log_Set_Input = {
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  data?: Maybe<Scalars['json']>;
  deleted?: Maybe<Scalars['Boolean']>;
  entity_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Audit_Log_Stddev_Fields = {
  __typename?: 'audit_log_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audit_log" */
export type Audit_Log_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audit_Log_Stddev_Pop_Fields = {
  __typename?: 'audit_log_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audit_log" */
export type Audit_Log_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audit_Log_Stddev_Samp_Fields = {
  __typename?: 'audit_log_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audit_log" */
export type Audit_Log_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Audit_Log_Sum_Fields = {
  __typename?: 'audit_log_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "audit_log" */
export type Audit_Log_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "audit_log" */
export enum Audit_Log_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Audit_Log_Var_Pop_Fields = {
  __typename?: 'audit_log_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audit_log" */
export type Audit_Log_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audit_Log_Var_Samp_Fields = {
  __typename?: 'audit_log_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audit_log" */
export type Audit_Log_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audit_Log_Variance_Fields = {
  __typename?: 'audit_log_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audit_log" */
export type Audit_Log_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 * 
 * 
 * columns and relationships of "audit.logged_actions"
 */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String'];
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk: Scalars['timestamptz'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz'];
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx: Scalars['timestamptz'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']>;
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
  hasura_user?: Maybe<Scalars['jsonb']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']>;
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String'];
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only: Scalars['Boolean'];
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 * 
 * 
 * columns and relationships of "audit.logged_actions"
 */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 * 
 * 
 * columns and relationships of "audit.logged_actions"
 */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * History of auditable actions on audited tables, from audit.if_modified_func()
 * 
 * 
 * columns and relationships of "audit.logged_actions"
 */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Audit_Logged_Actions>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg?: Maybe<Audit_Logged_Actions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Audit_Logged_Actions_Max_Fields>;
  min?: Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Fields>;
};


/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Order_By = {
  avg?: Maybe<Audit_Logged_Actions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Audit_Logged_Actions_Max_Order_By>;
  min?: Maybe<Audit_Logged_Actions_Min_Order_By>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Order_By>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Order_By>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Order_By>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Order_By>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Order_By>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "audit.logged_actions" */
export type Audit_Logged_Actions_Arr_Rel_Insert_Input = {
  data: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Audit_Logged_Actions_Bool_Exp>>>;
  _not?: Maybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Audit_Logged_Actions_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint */
  LoggedActionsPkey = 'logged_actions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasura_user?: Maybe<Array<Maybe<Scalars['String']>>>;
  row_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Audit_Logged_Actions>;
};

/** input type for inserting object relation for remote table "audit.logged_actions" */
export type Audit_Logged_Actions_Obj_Rel_Insert_Input = {
  data: Audit_Logged_Actions_Insert_Input;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};

/** on conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns: Array<Audit_Logged_Actions_Update_Column>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};

/** ordering options when selecting data from "audit.logged_actions" */
export type Audit_Logged_Actions_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "audit.logged_actions" */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audit.logged_actions" */
export type Audit_Logged_Actions_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** columns and relationships of "background_notifications" */
export type Background_Notifications = {
  __typename?: 'background_notifications';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  pslcode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "background_notifications" */
export type Background_NotificationsResponseArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "background_notifications" */
export type Background_Notifications_Aggregate = {
  __typename?: 'background_notifications_aggregate';
  aggregate?: Maybe<Background_Notifications_Aggregate_Fields>;
  nodes: Array<Background_Notifications>;
};

/** aggregate fields of "background_notifications" */
export type Background_Notifications_Aggregate_Fields = {
  __typename?: 'background_notifications_aggregate_fields';
  avg?: Maybe<Background_Notifications_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Background_Notifications_Max_Fields>;
  min?: Maybe<Background_Notifications_Min_Fields>;
  stddev?: Maybe<Background_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Background_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Background_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Background_Notifications_Sum_Fields>;
  var_pop?: Maybe<Background_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Background_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Background_Notifications_Variance_Fields>;
};


/** aggregate fields of "background_notifications" */
export type Background_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Background_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "background_notifications" */
export type Background_Notifications_Aggregate_Order_By = {
  avg?: Maybe<Background_Notifications_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Background_Notifications_Max_Order_By>;
  min?: Maybe<Background_Notifications_Min_Order_By>;
  stddev?: Maybe<Background_Notifications_Stddev_Order_By>;
  stddev_pop?: Maybe<Background_Notifications_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Background_Notifications_Stddev_Samp_Order_By>;
  sum?: Maybe<Background_Notifications_Sum_Order_By>;
  var_pop?: Maybe<Background_Notifications_Var_Pop_Order_By>;
  var_samp?: Maybe<Background_Notifications_Var_Samp_Order_By>;
  variance?: Maybe<Background_Notifications_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Background_Notifications_Append_Input = {
  response?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "background_notifications" */
export type Background_Notifications_Arr_Rel_Insert_Input = {
  data: Array<Background_Notifications_Insert_Input>;
  on_conflict?: Maybe<Background_Notifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Background_Notifications_Avg_Fields = {
  __typename?: 'background_notifications_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "background_notifications" */
export type Background_Notifications_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "background_notifications". All fields are combined with a logical 'AND'. */
export type Background_Notifications_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Background_Notifications_Bool_Exp>>>;
  _not?: Maybe<Background_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Background_Notifications_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  pslcode?: Maybe<String_Comparison_Exp>;
  response?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "background_notifications" */
export enum Background_Notifications_Constraint {
  /** unique or primary key constraint */
  BackgroundNotificationsPkey = 'background_notifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Background_Notifications_Delete_At_Path_Input = {
  response?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Background_Notifications_Delete_Elem_Input = {
  response?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Background_Notifications_Delete_Key_Input = {
  response?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "background_notifications" */
export type Background_Notifications_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "background_notifications" */
export type Background_Notifications_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pslcode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Background_Notifications_Max_Fields = {
  __typename?: 'background_notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pslcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "background_notifications" */
export type Background_Notifications_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pslcode?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Background_Notifications_Min_Fields = {
  __typename?: 'background_notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pslcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "background_notifications" */
export type Background_Notifications_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pslcode?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "background_notifications" */
export type Background_Notifications_Mutation_Response = {
  __typename?: 'background_notifications_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Background_Notifications>;
};

/** input type for inserting object relation for remote table "background_notifications" */
export type Background_Notifications_Obj_Rel_Insert_Input = {
  data: Background_Notifications_Insert_Input;
  on_conflict?: Maybe<Background_Notifications_On_Conflict>;
};

/** on conflict condition type for table "background_notifications" */
export type Background_Notifications_On_Conflict = {
  constraint: Background_Notifications_Constraint;
  update_columns: Array<Background_Notifications_Update_Column>;
  where?: Maybe<Background_Notifications_Bool_Exp>;
};

/** ordering options when selecting data from "background_notifications" */
export type Background_Notifications_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pslcode?: Maybe<Order_By>;
  response?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "background_notifications" */
export type Background_Notifications_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Background_Notifications_Prepend_Input = {
  response?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "background_notifications" */
export enum Background_Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Pslcode = 'pslcode',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "background_notifications" */
export type Background_Notifications_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  pslcode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Background_Notifications_Stddev_Fields = {
  __typename?: 'background_notifications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "background_notifications" */
export type Background_Notifications_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Background_Notifications_Stddev_Pop_Fields = {
  __typename?: 'background_notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "background_notifications" */
export type Background_Notifications_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Background_Notifications_Stddev_Samp_Fields = {
  __typename?: 'background_notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "background_notifications" */
export type Background_Notifications_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Background_Notifications_Sum_Fields = {
  __typename?: 'background_notifications_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "background_notifications" */
export type Background_Notifications_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "background_notifications" */
export enum Background_Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Pslcode = 'pslcode',
  /** column name */
  Response = 'response',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Background_Notifications_Var_Pop_Fields = {
  __typename?: 'background_notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "background_notifications" */
export type Background_Notifications_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Background_Notifications_Var_Samp_Fields = {
  __typename?: 'background_notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "background_notifications" */
export type Background_Notifications_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Background_Notifications_Variance_Fields = {
  __typename?: 'background_notifications_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "background_notifications" */
export type Background_Notifications_Variance_Order_By = {
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "branch" */
export type Branch = {
  __typename?: 'branch';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  /** An array relationship */
  member_payment_instructions: Array<Member_Payment_Instruction>;
  /** An aggregated array relationship */
  member_payment_instructions_aggregate: Member_Payment_Instruction_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An object relationship */
  payment_provider: Payment_Provider;
  payment_provider_id: Scalars['bigint'];
  sort_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "branch" */
export type BranchMember_Payment_InstructionsArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** columns and relationships of "branch" */
export type BranchMember_Payment_Instructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};

/** aggregated selection of "branch" */
export type Branch_Aggregate = {
  __typename?: 'branch_aggregate';
  aggregate?: Maybe<Branch_Aggregate_Fields>;
  nodes: Array<Branch>;
};

/** aggregate fields of "branch" */
export type Branch_Aggregate_Fields = {
  __typename?: 'branch_aggregate_fields';
  avg?: Maybe<Branch_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Branch_Max_Fields>;
  min?: Maybe<Branch_Min_Fields>;
  stddev?: Maybe<Branch_Stddev_Fields>;
  stddev_pop?: Maybe<Branch_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Branch_Stddev_Samp_Fields>;
  sum?: Maybe<Branch_Sum_Fields>;
  var_pop?: Maybe<Branch_Var_Pop_Fields>;
  var_samp?: Maybe<Branch_Var_Samp_Fields>;
  variance?: Maybe<Branch_Variance_Fields>;
};


/** aggregate fields of "branch" */
export type Branch_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Branch_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "branch" */
export type Branch_Aggregate_Order_By = {
  avg?: Maybe<Branch_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Branch_Max_Order_By>;
  min?: Maybe<Branch_Min_Order_By>;
  stddev?: Maybe<Branch_Stddev_Order_By>;
  stddev_pop?: Maybe<Branch_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Branch_Stddev_Samp_Order_By>;
  sum?: Maybe<Branch_Sum_Order_By>;
  var_pop?: Maybe<Branch_Var_Pop_Order_By>;
  var_samp?: Maybe<Branch_Var_Samp_Order_By>;
  variance?: Maybe<Branch_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "branch" */
export type Branch_Arr_Rel_Insert_Input = {
  data: Array<Branch_Insert_Input>;
  on_conflict?: Maybe<Branch_On_Conflict>;
};

/** aggregate avg on columns */
export type Branch_Avg_Fields = {
  __typename?: 'branch_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "branch" */
export type Branch_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "branch". All fields are combined with a logical 'AND'. */
export type Branch_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Branch_Bool_Exp>>>;
  _not?: Maybe<Branch_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Branch_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_provider?: Maybe<Payment_Provider_Bool_Exp>;
  payment_provider_id?: Maybe<Bigint_Comparison_Exp>;
  sort_code?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "branch" */
export enum Branch_Constraint {
  /** unique or primary key constraint */
  BranchPkey = 'branch_pkey'
}

/** input type for incrementing integer column in table "branch" */
export type Branch_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "branch" */
export type Branch_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_provider?: Maybe<Payment_Provider_Obj_Rel_Insert_Input>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Branch_Max_Fields = {
  __typename?: 'branch_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "branch" */
export type Branch_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Branch_Min_Fields = {
  __typename?: 'branch_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "branch" */
export type Branch_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "branch" */
export type Branch_Mutation_Response = {
  __typename?: 'branch_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Branch>;
};

/** input type for inserting object relation for remote table "branch" */
export type Branch_Obj_Rel_Insert_Input = {
  data: Branch_Insert_Input;
  on_conflict?: Maybe<Branch_On_Conflict>;
};

/** on conflict condition type for table "branch" */
export type Branch_On_Conflict = {
  constraint: Branch_Constraint;
  update_columns: Array<Branch_Update_Column>;
  where?: Maybe<Branch_Bool_Exp>;
};

/** ordering options when selecting data from "branch" */
export type Branch_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_payment_instructions_aggregate?: Maybe<Member_Payment_Instruction_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_provider?: Maybe<Payment_Provider_Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "branch" */
export type Branch_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "branch" */
export enum Branch_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentProviderId = 'payment_provider_id',
  /** column name */
  SortCode = 'sort_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "branch" */
export type Branch_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Branch_Stddev_Fields = {
  __typename?: 'branch_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "branch" */
export type Branch_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Branch_Stddev_Pop_Fields = {
  __typename?: 'branch_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "branch" */
export type Branch_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Branch_Stddev_Samp_Fields = {
  __typename?: 'branch_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "branch" */
export type Branch_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Branch_Sum_Fields = {
  __typename?: 'branch_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "branch" */
export type Branch_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** update columns of table "branch" */
export enum Branch_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentProviderId = 'payment_provider_id',
  /** column name */
  SortCode = 'sort_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Branch_Var_Pop_Fields = {
  __typename?: 'branch_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "branch" */
export type Branch_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Branch_Var_Samp_Fields = {
  __typename?: 'branch_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "branch" */
export type Branch_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Branch_Variance_Fields = {
  __typename?: 'branch_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "branch" */
export type Branch_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** columns and relationships of "client_investment_profile" */
export type Client_Investment_Profile = {
  __typename?: 'client_investment_profile';
  approximate_annual_income?: Maybe<Scalars['String']>;
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  investment_horizon?: Maybe<Scalars['String']>;
  investment_knowledge?: Maybe<Scalars['String']>;
  investment_objective?: Maybe<Scalars['String']>;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  risk_tolerance?: Maybe<Scalars['String']>;
};


/** columns and relationships of "client_investment_profile" */
export type Client_Investment_ProfileMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "client_investment_profile" */
export type Client_Investment_ProfileMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};

/** aggregated selection of "client_investment_profile" */
export type Client_Investment_Profile_Aggregate = {
  __typename?: 'client_investment_profile_aggregate';
  aggregate?: Maybe<Client_Investment_Profile_Aggregate_Fields>;
  nodes: Array<Client_Investment_Profile>;
};

/** aggregate fields of "client_investment_profile" */
export type Client_Investment_Profile_Aggregate_Fields = {
  __typename?: 'client_investment_profile_aggregate_fields';
  avg?: Maybe<Client_Investment_Profile_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Client_Investment_Profile_Max_Fields>;
  min?: Maybe<Client_Investment_Profile_Min_Fields>;
  stddev?: Maybe<Client_Investment_Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Investment_Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Investment_Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Investment_Profile_Sum_Fields>;
  var_pop?: Maybe<Client_Investment_Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Investment_Profile_Var_Samp_Fields>;
  variance?: Maybe<Client_Investment_Profile_Variance_Fields>;
};


/** aggregate fields of "client_investment_profile" */
export type Client_Investment_Profile_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Investment_Profile_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_investment_profile" */
export type Client_Investment_Profile_Aggregate_Order_By = {
  avg?: Maybe<Client_Investment_Profile_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Investment_Profile_Max_Order_By>;
  min?: Maybe<Client_Investment_Profile_Min_Order_By>;
  stddev?: Maybe<Client_Investment_Profile_Stddev_Order_By>;
  stddev_pop?: Maybe<Client_Investment_Profile_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Client_Investment_Profile_Stddev_Samp_Order_By>;
  sum?: Maybe<Client_Investment_Profile_Sum_Order_By>;
  var_pop?: Maybe<Client_Investment_Profile_Var_Pop_Order_By>;
  var_samp?: Maybe<Client_Investment_Profile_Var_Samp_Order_By>;
  variance?: Maybe<Client_Investment_Profile_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_investment_profile" */
export type Client_Investment_Profile_Arr_Rel_Insert_Input = {
  data: Array<Client_Investment_Profile_Insert_Input>;
  on_conflict?: Maybe<Client_Investment_Profile_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Investment_Profile_Avg_Fields = {
  __typename?: 'client_investment_profile_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_investment_profile". All fields are combined with a logical 'AND'. */
export type Client_Investment_Profile_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Client_Investment_Profile_Bool_Exp>>>;
  _not?: Maybe<Client_Investment_Profile_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Client_Investment_Profile_Bool_Exp>>>;
  approximate_annual_income?: Maybe<String_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  investment_horizon?: Maybe<String_Comparison_Exp>;
  investment_knowledge?: Maybe<String_Comparison_Exp>;
  investment_objective?: Maybe<String_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  risk_tolerance?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_investment_profile" */
export enum Client_Investment_Profile_Constraint {
  /** unique or primary key constraint */
  ClientInvestmentProfileGuidKey = 'client_investment_profile_guid_key',
  /** unique or primary key constraint */
  ClientInvestmentProfilePkey = 'client_investment_profile_pkey'
}

/** input type for incrementing integer column in table "client_investment_profile" */
export type Client_Investment_Profile_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "client_investment_profile" */
export type Client_Investment_Profile_Insert_Input = {
  approximate_annual_income?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  investment_horizon?: Maybe<Scalars['String']>;
  investment_knowledge?: Maybe<Scalars['String']>;
  investment_objective?: Maybe<Scalars['String']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  risk_tolerance?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Client_Investment_Profile_Max_Fields = {
  __typename?: 'client_investment_profile_max_fields';
  approximate_annual_income?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  investment_horizon?: Maybe<Scalars['String']>;
  investment_knowledge?: Maybe<Scalars['String']>;
  investment_objective?: Maybe<Scalars['String']>;
  risk_tolerance?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Max_Order_By = {
  approximate_annual_income?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  investment_horizon?: Maybe<Order_By>;
  investment_knowledge?: Maybe<Order_By>;
  investment_objective?: Maybe<Order_By>;
  risk_tolerance?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Investment_Profile_Min_Fields = {
  __typename?: 'client_investment_profile_min_fields';
  approximate_annual_income?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  investment_horizon?: Maybe<Scalars['String']>;
  investment_knowledge?: Maybe<Scalars['String']>;
  investment_objective?: Maybe<Scalars['String']>;
  risk_tolerance?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Min_Order_By = {
  approximate_annual_income?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  investment_horizon?: Maybe<Order_By>;
  investment_knowledge?: Maybe<Order_By>;
  investment_objective?: Maybe<Order_By>;
  risk_tolerance?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_investment_profile" */
export type Client_Investment_Profile_Mutation_Response = {
  __typename?: 'client_investment_profile_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Client_Investment_Profile>;
};

/** input type for inserting object relation for remote table "client_investment_profile" */
export type Client_Investment_Profile_Obj_Rel_Insert_Input = {
  data: Client_Investment_Profile_Insert_Input;
  on_conflict?: Maybe<Client_Investment_Profile_On_Conflict>;
};

/** on conflict condition type for table "client_investment_profile" */
export type Client_Investment_Profile_On_Conflict = {
  constraint: Client_Investment_Profile_Constraint;
  update_columns: Array<Client_Investment_Profile_Update_Column>;
  where?: Maybe<Client_Investment_Profile_Bool_Exp>;
};

/** ordering options when selecting data from "client_investment_profile" */
export type Client_Investment_Profile_Order_By = {
  approximate_annual_income?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  investment_horizon?: Maybe<Order_By>;
  investment_knowledge?: Maybe<Order_By>;
  investment_objective?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  risk_tolerance?: Maybe<Order_By>;
};

/** primary key columns input for table: "client_investment_profile" */
export type Client_Investment_Profile_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "client_investment_profile" */
export enum Client_Investment_Profile_Select_Column {
  /** column name */
  ApproximateAnnualIncome = 'approximate_annual_income',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  InvestmentHorizon = 'investment_horizon',
  /** column name */
  InvestmentKnowledge = 'investment_knowledge',
  /** column name */
  InvestmentObjective = 'investment_objective',
  /** column name */
  RiskTolerance = 'risk_tolerance'
}

/** input type for updating data in table "client_investment_profile" */
export type Client_Investment_Profile_Set_Input = {
  approximate_annual_income?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  investment_horizon?: Maybe<Scalars['String']>;
  investment_knowledge?: Maybe<Scalars['String']>;
  investment_objective?: Maybe<Scalars['String']>;
  risk_tolerance?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Client_Investment_Profile_Stddev_Fields = {
  __typename?: 'client_investment_profile_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Investment_Profile_Stddev_Pop_Fields = {
  __typename?: 'client_investment_profile_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Investment_Profile_Stddev_Samp_Fields = {
  __typename?: 'client_investment_profile_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Client_Investment_Profile_Sum_Fields = {
  __typename?: 'client_investment_profile_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "client_investment_profile" */
export enum Client_Investment_Profile_Update_Column {
  /** column name */
  ApproximateAnnualIncome = 'approximate_annual_income',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  InvestmentHorizon = 'investment_horizon',
  /** column name */
  InvestmentKnowledge = 'investment_knowledge',
  /** column name */
  InvestmentObjective = 'investment_objective',
  /** column name */
  RiskTolerance = 'risk_tolerance'
}

/** aggregate var_pop on columns */
export type Client_Investment_Profile_Var_Pop_Fields = {
  __typename?: 'client_investment_profile_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Investment_Profile_Var_Samp_Fields = {
  __typename?: 'client_investment_profile_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Investment_Profile_Variance_Fields = {
  __typename?: 'client_investment_profile_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_investment_profile" */
export type Client_Investment_Profile_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "contribution_source" */
export type Contribution_Source = {
  __typename?: 'contribution_source';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_contribution_sources: Array<Member_Contribution_Source>;
  /** An aggregated array relationship */
  member_contribution_sources_aggregate: Member_Contribution_Source_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "contribution_source" */
export type Contribution_SourceMember_Contribution_SourcesArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** columns and relationships of "contribution_source" */
export type Contribution_SourceMember_Contribution_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};

/** aggregated selection of "contribution_source" */
export type Contribution_Source_Aggregate = {
  __typename?: 'contribution_source_aggregate';
  aggregate?: Maybe<Contribution_Source_Aggregate_Fields>;
  nodes: Array<Contribution_Source>;
};

/** aggregate fields of "contribution_source" */
export type Contribution_Source_Aggregate_Fields = {
  __typename?: 'contribution_source_aggregate_fields';
  avg?: Maybe<Contribution_Source_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contribution_Source_Max_Fields>;
  min?: Maybe<Contribution_Source_Min_Fields>;
  stddev?: Maybe<Contribution_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Contribution_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contribution_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Contribution_Source_Sum_Fields>;
  var_pop?: Maybe<Contribution_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Contribution_Source_Var_Samp_Fields>;
  variance?: Maybe<Contribution_Source_Variance_Fields>;
};


/** aggregate fields of "contribution_source" */
export type Contribution_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contribution_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contribution_source" */
export type Contribution_Source_Aggregate_Order_By = {
  avg?: Maybe<Contribution_Source_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contribution_Source_Max_Order_By>;
  min?: Maybe<Contribution_Source_Min_Order_By>;
  stddev?: Maybe<Contribution_Source_Stddev_Order_By>;
  stddev_pop?: Maybe<Contribution_Source_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contribution_Source_Stddev_Samp_Order_By>;
  sum?: Maybe<Contribution_Source_Sum_Order_By>;
  var_pop?: Maybe<Contribution_Source_Var_Pop_Order_By>;
  var_samp?: Maybe<Contribution_Source_Var_Samp_Order_By>;
  variance?: Maybe<Contribution_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contribution_source" */
export type Contribution_Source_Arr_Rel_Insert_Input = {
  data: Array<Contribution_Source_Insert_Input>;
  on_conflict?: Maybe<Contribution_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Contribution_Source_Avg_Fields = {
  __typename?: 'contribution_source_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contribution_source" */
export type Contribution_Source_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contribution_source". All fields are combined with a logical 'AND'. */
export type Contribution_Source_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contribution_Source_Bool_Exp>>>;
  _not?: Maybe<Contribution_Source_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contribution_Source_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_contribution_sources?: Maybe<Member_Contribution_Source_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contribution_source" */
export enum Contribution_Source_Constraint {
  /** unique or primary key constraint */
  ContributionSourceNameKey = 'contribution_source_name_key',
  /** unique or primary key constraint */
  ContributionSourcePkey = 'contribution_source_pkey'
}

/** input type for incrementing integer column in table "contribution_source" */
export type Contribution_Source_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "contribution_source" */
export type Contribution_Source_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_contribution_sources?: Maybe<Member_Contribution_Source_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contribution_Source_Max_Fields = {
  __typename?: 'contribution_source_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contribution_source" */
export type Contribution_Source_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contribution_Source_Min_Fields = {
  __typename?: 'contribution_source_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contribution_source" */
export type Contribution_Source_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contribution_source" */
export type Contribution_Source_Mutation_Response = {
  __typename?: 'contribution_source_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contribution_Source>;
};

/** input type for inserting object relation for remote table "contribution_source" */
export type Contribution_Source_Obj_Rel_Insert_Input = {
  data: Contribution_Source_Insert_Input;
  on_conflict?: Maybe<Contribution_Source_On_Conflict>;
};

/** on conflict condition type for table "contribution_source" */
export type Contribution_Source_On_Conflict = {
  constraint: Contribution_Source_Constraint;
  update_columns: Array<Contribution_Source_Update_Column>;
  where?: Maybe<Contribution_Source_Bool_Exp>;
};

/** ordering options when selecting data from "contribution_source" */
export type Contribution_Source_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_contribution_sources_aggregate?: Maybe<Member_Contribution_Source_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "contribution_source" */
export type Contribution_Source_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "contribution_source" */
export enum Contribution_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contribution_source" */
export type Contribution_Source_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Contribution_Source_Stddev_Fields = {
  __typename?: 'contribution_source_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contribution_source" */
export type Contribution_Source_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contribution_Source_Stddev_Pop_Fields = {
  __typename?: 'contribution_source_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contribution_source" */
export type Contribution_Source_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contribution_Source_Stddev_Samp_Fields = {
  __typename?: 'contribution_source_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contribution_source" */
export type Contribution_Source_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contribution_Source_Sum_Fields = {
  __typename?: 'contribution_source_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "contribution_source" */
export type Contribution_Source_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "contribution_source" */
export enum Contribution_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Contribution_Source_Var_Pop_Fields = {
  __typename?: 'contribution_source_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contribution_source" */
export type Contribution_Source_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contribution_Source_Var_Samp_Fields = {
  __typename?: 'contribution_source_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contribution_source" */
export type Contribution_Source_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contribution_Source_Variance_Fields = {
  __typename?: 'contribution_source_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contribution_source" */
export type Contribution_Source_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_beneficiaries: Array<Member_Beneficiary>;
  /** An aggregated array relationship */
  member_beneficiaries_aggregate: Member_Beneficiary_Aggregate;
  /** An array relationship */
  member_identifications: Array<Member_Identification>;
  /** An aggregated array relationship */
  member_identifications_aggregate: Member_Identification_Aggregate;
  /** An array relationship */
  member_in_trust_for_origin: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_for_origin_aggregate: Member_In_Trust_For_Aggregate;
  /** An array relationship */
  member_in_trust_for_residence: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_for_residence_aggregate: Member_In_Trust_For_Aggregate;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  /** An array relationship */
  members_by_residence: Array<Member>;
  /** An aggregated array relationship */
  members_by_residence_aggregate: Member_Aggregate;
  /** An array relationship */
  members_by_residence_jt: Array<Member>;
  /** An aggregated array relationship */
  members_by_residence_jt_aggregate: Member_Aggregate;
  /** An array relationship */
  members_jt: Array<Member>;
  /** An aggregated array relationship */
  members_jt_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  next_of_kins: Array<Next_Of_Kin>;
  /** An aggregated array relationship */
  next_of_kins_aggregate: Next_Of_Kin_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "country" */
export type CountryMember_BeneficiariesArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_Beneficiaries_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_IdentificationsArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_Identifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_In_Trust_For_OriginArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_In_Trust_For_Origin_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_In_Trust_For_ResidenceArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMember_In_Trust_For_Residence_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_By_ResidenceArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_By_Residence_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_By_Residence_JtArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_By_Residence_Jt_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_JtArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryMembers_Jt_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryNext_Of_KinsArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryNext_Of_Kins_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  avg?: Maybe<Country_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
  stddev?: Maybe<Country_Stddev_Fields>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Fields>;
  sum?: Maybe<Country_Sum_Fields>;
  var_pop?: Maybe<Country_Var_Pop_Fields>;
  var_samp?: Maybe<Country_Var_Samp_Fields>;
  variance?: Maybe<Country_Variance_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Country_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "country" */
export type Country_Aggregate_Order_By = {
  avg?: Maybe<Country_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Country_Max_Order_By>;
  min?: Maybe<Country_Min_Order_By>;
  stddev?: Maybe<Country_Stddev_Order_By>;
  stddev_pop?: Maybe<Country_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Country_Stddev_Samp_Order_By>;
  sum?: Maybe<Country_Sum_Order_By>;
  var_pop?: Maybe<Country_Var_Pop_Order_By>;
  var_samp?: Maybe<Country_Var_Samp_Order_By>;
  variance?: Maybe<Country_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "country" */
export type Country_Arr_Rel_Insert_Input = {
  data: Array<Country_Insert_Input>;
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** aggregate avg on columns */
export type Country_Avg_Fields = {
  __typename?: 'country_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "country" */
export type Country_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Country_Bool_Exp>>>;
  _not?: Maybe<Country_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Country_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Bool_Exp>;
  member_identifications?: Maybe<Member_Identification_Bool_Exp>;
  member_in_trust_for_origin?: Maybe<Member_In_Trust_For_Bool_Exp>;
  member_in_trust_for_residence?: Maybe<Member_In_Trust_For_Bool_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  members_by_residence?: Maybe<Member_Bool_Exp>;
  members_by_residence_jt?: Maybe<Member_Bool_Exp>;
  members_jt?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  next_of_kins?: Maybe<Next_Of_Kin_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint */
  CountryNameKey = 'country_name_key',
  /** unique or primary key constraint */
  CountryPkey = 'country_pkey'
}

/** input type for incrementing integer column in table "country" */
export type Country_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Arr_Rel_Insert_Input>;
  member_identifications?: Maybe<Member_Identification_Arr_Rel_Insert_Input>;
  member_in_trust_for_origin?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  member_in_trust_for_residence?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  members_by_residence?: Maybe<Member_Arr_Rel_Insert_Input>;
  members_by_residence_jt?: Maybe<Member_Arr_Rel_Insert_Input>;
  members_jt?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  next_of_kins?: Maybe<Next_Of_Kin_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "country" */
export type Country_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "country" */
export type Country_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** on conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns: Array<Country_Update_Column>;
  where?: Maybe<Country_Bool_Exp>;
};

/** ordering options when selecting data from "country" */
export type Country_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_beneficiaries_aggregate?: Maybe<Member_Beneficiary_Aggregate_Order_By>;
  member_identifications_aggregate?: Maybe<Member_Identification_Aggregate_Order_By>;
  member_in_trust_for_origin_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  member_in_trust_for_residence_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_by_residence_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_by_residence_jt_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_jt_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  next_of_kins_aggregate?: Maybe<Next_Of_Kin_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "country" */
export type Country_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Country_Stddev_Fields = {
  __typename?: 'country_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "country" */
export type Country_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Country_Stddev_Pop_Fields = {
  __typename?: 'country_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "country" */
export type Country_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Country_Stddev_Samp_Fields = {
  __typename?: 'country_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "country" */
export type Country_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Country_Sum_Fields = {
  __typename?: 'country_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "country" */
export type Country_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Country_Var_Pop_Fields = {
  __typename?: 'country_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "country" */
export type Country_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Country_Var_Samp_Fields = {
  __typename?: 'country_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "country" */
export type Country_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Country_Variance_Fields = {
  __typename?: 'country_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "country" */
export type Country_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "dashboard_summary" */
export type Dashboard_Summary = {
  __typename?: 'dashboard_summary';
  agent_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_customer_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['bigint']>;
  percentage_customer_count_increase?: Maybe<Scalars['bigint']>;
  percentage_prospect_count_increase?: Maybe<Scalars['bigint']>;
  total_customer_count?: Maybe<Scalars['bigint']>;
  total_prospect_count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dashboard_summary" */
export type Dashboard_Summary_Aggregate = {
  __typename?: 'dashboard_summary_aggregate';
  aggregate?: Maybe<Dashboard_Summary_Aggregate_Fields>;
  nodes: Array<Dashboard_Summary>;
};

/** aggregate fields of "dashboard_summary" */
export type Dashboard_Summary_Aggregate_Fields = {
  __typename?: 'dashboard_summary_aggregate_fields';
  avg?: Maybe<Dashboard_Summary_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Dashboard_Summary_Max_Fields>;
  min?: Maybe<Dashboard_Summary_Min_Fields>;
  stddev?: Maybe<Dashboard_Summary_Stddev_Fields>;
  stddev_pop?: Maybe<Dashboard_Summary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dashboard_Summary_Stddev_Samp_Fields>;
  sum?: Maybe<Dashboard_Summary_Sum_Fields>;
  var_pop?: Maybe<Dashboard_Summary_Var_Pop_Fields>;
  var_samp?: Maybe<Dashboard_Summary_Var_Samp_Fields>;
  variance?: Maybe<Dashboard_Summary_Variance_Fields>;
};


/** aggregate fields of "dashboard_summary" */
export type Dashboard_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dashboard_Summary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dashboard_summary" */
export type Dashboard_Summary_Aggregate_Order_By = {
  avg?: Maybe<Dashboard_Summary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dashboard_Summary_Max_Order_By>;
  min?: Maybe<Dashboard_Summary_Min_Order_By>;
  stddev?: Maybe<Dashboard_Summary_Stddev_Order_By>;
  stddev_pop?: Maybe<Dashboard_Summary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dashboard_Summary_Stddev_Samp_Order_By>;
  sum?: Maybe<Dashboard_Summary_Sum_Order_By>;
  var_pop?: Maybe<Dashboard_Summary_Var_Pop_Order_By>;
  var_samp?: Maybe<Dashboard_Summary_Var_Samp_Order_By>;
  variance?: Maybe<Dashboard_Summary_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Dashboard_Summary_Avg_Fields = {
  __typename?: 'dashboard_summary_avg_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Avg_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dashboard_summary". All fields are combined with a logical 'AND'. */
export type Dashboard_Summary_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Dashboard_Summary_Bool_Exp>>>;
  _not?: Maybe<Dashboard_Summary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Dashboard_Summary_Bool_Exp>>>;
  agent_count?: Maybe<Bigint_Comparison_Exp>;
  last_thirty_days_customer_count?: Maybe<Bigint_Comparison_Exp>;
  last_thirty_days_prospect_count?: Maybe<Bigint_Comparison_Exp>;
  percentage_customer_count_increase?: Maybe<Bigint_Comparison_Exp>;
  percentage_prospect_count_increase?: Maybe<Bigint_Comparison_Exp>;
  total_customer_count?: Maybe<Bigint_Comparison_Exp>;
  total_prospect_count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dashboard_Summary_Max_Fields = {
  __typename?: 'dashboard_summary_max_fields';
  agent_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_customer_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['bigint']>;
  percentage_customer_count_increase?: Maybe<Scalars['bigint']>;
  percentage_prospect_count_increase?: Maybe<Scalars['bigint']>;
  total_customer_count?: Maybe<Scalars['bigint']>;
  total_prospect_count?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Max_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dashboard_Summary_Min_Fields = {
  __typename?: 'dashboard_summary_min_fields';
  agent_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_customer_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['bigint']>;
  percentage_customer_count_increase?: Maybe<Scalars['bigint']>;
  percentage_prospect_count_increase?: Maybe<Scalars['bigint']>;
  total_customer_count?: Maybe<Scalars['bigint']>;
  total_prospect_count?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Min_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** ordering options when selecting data from "dashboard_summary" */
export type Dashboard_Summary_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** select columns of table "dashboard_summary" */
export enum Dashboard_Summary_Select_Column {
  /** column name */
  AgentCount = 'agent_count',
  /** column name */
  LastThirtyDaysCustomerCount = 'last_thirty_days_customer_count',
  /** column name */
  LastThirtyDaysProspectCount = 'last_thirty_days_prospect_count',
  /** column name */
  PercentageCustomerCountIncrease = 'percentage_customer_count_increase',
  /** column name */
  PercentageProspectCountIncrease = 'percentage_prospect_count_increase',
  /** column name */
  TotalCustomerCount = 'total_customer_count',
  /** column name */
  TotalProspectCount = 'total_prospect_count'
}

/** aggregate stddev on columns */
export type Dashboard_Summary_Stddev_Fields = {
  __typename?: 'dashboard_summary_stddev_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Stddev_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dashboard_Summary_Stddev_Pop_Fields = {
  __typename?: 'dashboard_summary_stddev_pop_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Stddev_Pop_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dashboard_Summary_Stddev_Samp_Fields = {
  __typename?: 'dashboard_summary_stddev_samp_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Stddev_Samp_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dashboard_Summary_Sum_Fields = {
  __typename?: 'dashboard_summary_sum_fields';
  agent_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_customer_count?: Maybe<Scalars['bigint']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['bigint']>;
  percentage_customer_count_increase?: Maybe<Scalars['bigint']>;
  percentage_prospect_count_increase?: Maybe<Scalars['bigint']>;
  total_customer_count?: Maybe<Scalars['bigint']>;
  total_prospect_count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Sum_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Dashboard_Summary_Var_Pop_Fields = {
  __typename?: 'dashboard_summary_var_pop_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Var_Pop_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dashboard_Summary_Var_Samp_Fields = {
  __typename?: 'dashboard_summary_var_samp_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Var_Samp_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dashboard_Summary_Variance_Fields = {
  __typename?: 'dashboard_summary_variance_fields';
  agent_count?: Maybe<Scalars['Float']>;
  last_thirty_days_customer_count?: Maybe<Scalars['Float']>;
  last_thirty_days_prospect_count?: Maybe<Scalars['Float']>;
  percentage_customer_count_increase?: Maybe<Scalars['Float']>;
  percentage_prospect_count_increase?: Maybe<Scalars['Float']>;
  total_customer_count?: Maybe<Scalars['Float']>;
  total_prospect_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dashboard_summary" */
export type Dashboard_Summary_Variance_Order_By = {
  agent_count?: Maybe<Order_By>;
  last_thirty_days_customer_count?: Maybe<Order_By>;
  last_thirty_days_prospect_count?: Maybe<Order_By>;
  percentage_customer_count_increase?: Maybe<Order_By>;
  percentage_prospect_count_increase?: Maybe<Order_By>;
  total_customer_count?: Maybe<Order_By>;
  total_prospect_count?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "deal" */
export type Deal = {
  __typename?: 'deal';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  deal_shares: Array<Deal_Share>;
  /** An aggregated array relationship */
  deal_shares_aggregate: Deal_Share_Aggregate;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  member_goal: Member_Goal;
  member_goal_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  payment: Payment;
  payment_reference: Scalars['uuid'];
  units: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};


/** columns and relationships of "deal" */
export type DealDeal_SharesArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** columns and relationships of "deal" */
export type DealDeal_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};

/** aggregated selection of "deal" */
export type Deal_Aggregate = {
  __typename?: 'deal_aggregate';
  aggregate?: Maybe<Deal_Aggregate_Fields>;
  nodes: Array<Deal>;
};

/** aggregate fields of "deal" */
export type Deal_Aggregate_Fields = {
  __typename?: 'deal_aggregate_fields';
  avg?: Maybe<Deal_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Deal_Max_Fields>;
  min?: Maybe<Deal_Min_Fields>;
  stddev?: Maybe<Deal_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Sum_Fields>;
  var_pop?: Maybe<Deal_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Var_Samp_Fields>;
  variance?: Maybe<Deal_Variance_Fields>;
};


/** aggregate fields of "deal" */
export type Deal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal" */
export type Deal_Aggregate_Order_By = {
  avg?: Maybe<Deal_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deal_Max_Order_By>;
  min?: Maybe<Deal_Min_Order_By>;
  stddev?: Maybe<Deal_Stddev_Order_By>;
  stddev_pop?: Maybe<Deal_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deal_Stddev_Samp_Order_By>;
  sum?: Maybe<Deal_Sum_Order_By>;
  var_pop?: Maybe<Deal_Var_Pop_Order_By>;
  var_samp?: Maybe<Deal_Var_Samp_Order_By>;
  variance?: Maybe<Deal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal" */
export type Deal_Arr_Rel_Insert_Input = {
  data: Array<Deal_Insert_Input>;
  on_conflict?: Maybe<Deal_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Avg_Fields = {
  __typename?: 'deal_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal" */
export type Deal_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
export type Deal_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Deal_Bool_Exp>>>;
  _not?: Maybe<Deal_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Deal_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deal_shares?: Maybe<Deal_Share_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_goal?: Maybe<Member_Goal_Bool_Exp>;
  member_goal_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  payment?: Maybe<Payment_Bool_Exp>;
  payment_reference?: Maybe<Uuid_Comparison_Exp>;
  units?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "deal" */
export enum Deal_Constraint {
  /** unique or primary key constraint */
  DealPaymentReferenceMemberGoalIdKey = 'deal_payment_reference_member_goal_id_key',
  /** unique or primary key constraint */
  DealPkey = 'deal_pkey'
}

/** input type for incrementing integer column in table "deal" */
export type Deal_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "deal" */
export type Deal_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal_shares?: Maybe<Deal_Share_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal?: Maybe<Member_Goal_Obj_Rel_Insert_Input>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment?: Maybe<Payment_Obj_Rel_Insert_Input>;
  payment_reference?: Maybe<Scalars['uuid']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Deal_Max_Fields = {
  __typename?: 'deal_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_reference?: Maybe<Scalars['uuid']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "deal" */
export type Deal_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Min_Fields = {
  __typename?: 'deal_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_reference?: Maybe<Scalars['uuid']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "deal" */
export type Deal_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "deal" */
export type Deal_Mutation_Response = {
  __typename?: 'deal_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Deal>;
};

/** input type for inserting object relation for remote table "deal" */
export type Deal_Obj_Rel_Insert_Input = {
  data: Deal_Insert_Input;
  on_conflict?: Maybe<Deal_On_Conflict>;
};

/** on conflict condition type for table "deal" */
export type Deal_On_Conflict = {
  constraint: Deal_Constraint;
  update_columns: Array<Deal_Update_Column>;
  where?: Maybe<Deal_Bool_Exp>;
};

/** ordering options when selecting data from "deal" */
export type Deal_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deal_shares_aggregate?: Maybe<Deal_Share_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal?: Maybe<Member_Goal_Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment?: Maybe<Payment_Order_By>;
  payment_reference?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "deal" */
export type Deal_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "deal" */
export enum Deal_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "deal" */
export type Deal_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_reference?: Maybe<Scalars['uuid']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "deal_share" */
export type Deal_Share = {
  __typename?: 'deal_share';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  deal: Deal;
  deal_id: Scalars['bigint'];
  deal_type?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  fund_code: Scalars['String'];
  id: Scalars['bigint'];
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Product;
  units: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  visible?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "deal_share" */
export type Deal_Share_Aggregate = {
  __typename?: 'deal_share_aggregate';
  aggregate?: Maybe<Deal_Share_Aggregate_Fields>;
  nodes: Array<Deal_Share>;
};

/** aggregate fields of "deal_share" */
export type Deal_Share_Aggregate_Fields = {
  __typename?: 'deal_share_aggregate_fields';
  avg?: Maybe<Deal_Share_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Deal_Share_Max_Fields>;
  min?: Maybe<Deal_Share_Min_Fields>;
  stddev?: Maybe<Deal_Share_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Share_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Share_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Share_Sum_Fields>;
  var_pop?: Maybe<Deal_Share_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Share_Var_Samp_Fields>;
  variance?: Maybe<Deal_Share_Variance_Fields>;
};


/** aggregate fields of "deal_share" */
export type Deal_Share_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Share_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal_share" */
export type Deal_Share_Aggregate_Order_By = {
  avg?: Maybe<Deal_Share_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Deal_Share_Max_Order_By>;
  min?: Maybe<Deal_Share_Min_Order_By>;
  stddev?: Maybe<Deal_Share_Stddev_Order_By>;
  stddev_pop?: Maybe<Deal_Share_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Deal_Share_Stddev_Samp_Order_By>;
  sum?: Maybe<Deal_Share_Sum_Order_By>;
  var_pop?: Maybe<Deal_Share_Var_Pop_Order_By>;
  var_samp?: Maybe<Deal_Share_Var_Samp_Order_By>;
  variance?: Maybe<Deal_Share_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "deal_share" */
export type Deal_Share_Arr_Rel_Insert_Input = {
  data: Array<Deal_Share_Insert_Input>;
  on_conflict?: Maybe<Deal_Share_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Share_Avg_Fields = {
  __typename?: 'deal_share_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal_share" */
export type Deal_Share_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal_share". All fields are combined with a logical 'AND'. */
export type Deal_Share_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Deal_Share_Bool_Exp>>>;
  _not?: Maybe<Deal_Share_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Deal_Share_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deal?: Maybe<Deal_Bool_Exp>;
  deal_id?: Maybe<Bigint_Comparison_Exp>;
  deal_type?: Maybe<String_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  fund_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  mf_deal_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  units?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  visible?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "deal_share" */
export enum Deal_Share_Constraint {
  /** unique or primary key constraint */
  DealSharePkey = 'deal_share_pkey'
}

/** input type for incrementing integer column in table "deal_share" */
export type Deal_Share_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  deal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "deal_share" */
export type Deal_Share_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal?: Maybe<Deal_Obj_Rel_Insert_Input>;
  deal_id?: Maybe<Scalars['bigint']>;
  deal_type?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visible?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Deal_Share_Max_Fields = {
  __typename?: 'deal_share_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal_id?: Maybe<Scalars['bigint']>;
  deal_type?: Maybe<Scalars['String']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "deal_share" */
export type Deal_Share_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  deal_type?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Share_Min_Fields = {
  __typename?: 'deal_share_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal_id?: Maybe<Scalars['bigint']>;
  deal_type?: Maybe<Scalars['String']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "deal_share" */
export type Deal_Share_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  deal_type?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "deal_share" */
export type Deal_Share_Mutation_Response = {
  __typename?: 'deal_share_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Deal_Share>;
};

/** input type for inserting object relation for remote table "deal_share" */
export type Deal_Share_Obj_Rel_Insert_Input = {
  data: Deal_Share_Insert_Input;
  on_conflict?: Maybe<Deal_Share_On_Conflict>;
};

/** on conflict condition type for table "deal_share" */
export type Deal_Share_On_Conflict = {
  constraint: Deal_Share_Constraint;
  update_columns: Array<Deal_Share_Update_Column>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};

/** ordering options when selecting data from "deal_share" */
export type Deal_Share_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deal?: Maybe<Deal_Order_By>;
  deal_id?: Maybe<Order_By>;
  deal_type?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  units?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  visible?: Maybe<Order_By>;
};

/** primary key columns input for table: "deal_share" */
export type Deal_Share_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "deal_share" */
export enum Deal_Share_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DealType = 'deal_type',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FundCode = 'fund_code',
  /** column name */
  Id = 'id',
  /** column name */
  MfDealId = 'mf_deal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "deal_share" */
export type Deal_Share_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal_id?: Maybe<Scalars['bigint']>;
  deal_type?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  visible?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Deal_Share_Stddev_Fields = {
  __typename?: 'deal_share_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal_share" */
export type Deal_Share_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Share_Stddev_Pop_Fields = {
  __typename?: 'deal_share_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal_share" */
export type Deal_Share_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Share_Stddev_Samp_Fields = {
  __typename?: 'deal_share_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal_share" */
export type Deal_Share_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Deal_Share_Sum_Fields = {
  __typename?: 'deal_share_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  deal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  mf_deal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "deal_share" */
export type Deal_Share_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** update columns of table "deal_share" */
export enum Deal_Share_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DealType = 'deal_type',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FundCode = 'fund_code',
  /** column name */
  Id = 'id',
  /** column name */
  MfDealId = 'mf_deal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Visible = 'visible'
}

/** aggregate var_pop on columns */
export type Deal_Share_Var_Pop_Fields = {
  __typename?: 'deal_share_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal_share" */
export type Deal_Share_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Share_Var_Samp_Fields = {
  __typename?: 'deal_share_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal_share" */
export type Deal_Share_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Share_Variance_Fields = {
  __typename?: 'deal_share_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  deal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  mf_deal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal_share" */
export type Deal_Share_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  deal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mf_deal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Deal_Stddev_Fields = {
  __typename?: 'deal_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal" */
export type Deal_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Stddev_Pop_Fields = {
  __typename?: 'deal_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal" */
export type Deal_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Stddev_Samp_Fields = {
  __typename?: 'deal_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal" */
export type Deal_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Deal_Sum_Fields = {
  __typename?: 'deal_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  units?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "deal" */
export type Deal_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** update columns of table "deal" */
export enum Deal_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  Units = 'units',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Deal_Var_Pop_Fields = {
  __typename?: 'deal_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal" */
export type Deal_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Var_Samp_Fields = {
  __typename?: 'deal_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal" */
export type Deal_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Variance_Fields = {
  __typename?: 'deal_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal" */
export type Deal_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  units?: Maybe<Order_By>;
};

/** columns and relationships of "direct_debit_charge" */
export type Direct_Debit_Charge = {
  __typename?: 'direct_debit_charge';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  start_date: Scalars['date'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "direct_debit_charge" */
export type Direct_Debit_Charge_Aggregate = {
  __typename?: 'direct_debit_charge_aggregate';
  aggregate?: Maybe<Direct_Debit_Charge_Aggregate_Fields>;
  nodes: Array<Direct_Debit_Charge>;
};

/** aggregate fields of "direct_debit_charge" */
export type Direct_Debit_Charge_Aggregate_Fields = {
  __typename?: 'direct_debit_charge_aggregate_fields';
  avg?: Maybe<Direct_Debit_Charge_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Direct_Debit_Charge_Max_Fields>;
  min?: Maybe<Direct_Debit_Charge_Min_Fields>;
  stddev?: Maybe<Direct_Debit_Charge_Stddev_Fields>;
  stddev_pop?: Maybe<Direct_Debit_Charge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Direct_Debit_Charge_Stddev_Samp_Fields>;
  sum?: Maybe<Direct_Debit_Charge_Sum_Fields>;
  var_pop?: Maybe<Direct_Debit_Charge_Var_Pop_Fields>;
  var_samp?: Maybe<Direct_Debit_Charge_Var_Samp_Fields>;
  variance?: Maybe<Direct_Debit_Charge_Variance_Fields>;
};


/** aggregate fields of "direct_debit_charge" */
export type Direct_Debit_Charge_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Direct_Debit_Charge_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "direct_debit_charge" */
export type Direct_Debit_Charge_Aggregate_Order_By = {
  avg?: Maybe<Direct_Debit_Charge_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Direct_Debit_Charge_Max_Order_By>;
  min?: Maybe<Direct_Debit_Charge_Min_Order_By>;
  stddev?: Maybe<Direct_Debit_Charge_Stddev_Order_By>;
  stddev_pop?: Maybe<Direct_Debit_Charge_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Direct_Debit_Charge_Stddev_Samp_Order_By>;
  sum?: Maybe<Direct_Debit_Charge_Sum_Order_By>;
  var_pop?: Maybe<Direct_Debit_Charge_Var_Pop_Order_By>;
  var_samp?: Maybe<Direct_Debit_Charge_Var_Samp_Order_By>;
  variance?: Maybe<Direct_Debit_Charge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "direct_debit_charge" */
export type Direct_Debit_Charge_Arr_Rel_Insert_Input = {
  data: Array<Direct_Debit_Charge_Insert_Input>;
  on_conflict?: Maybe<Direct_Debit_Charge_On_Conflict>;
};

/** aggregate avg on columns */
export type Direct_Debit_Charge_Avg_Fields = {
  __typename?: 'direct_debit_charge_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "direct_debit_charge". All fields are combined with a logical 'AND'. */
export type Direct_Debit_Charge_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Direct_Debit_Charge_Bool_Exp>>>;
  _not?: Maybe<Direct_Debit_Charge_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Direct_Debit_Charge_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  end_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_by?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "direct_debit_charge" */
export enum Direct_Debit_Charge_Constraint {
  /** unique or primary key constraint */
  DirectDebitChargePkey = 'direct_debit_charge_pkey'
}

/** input type for incrementing integer column in table "direct_debit_charge" */
export type Direct_Debit_Charge_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "direct_debit_charge" */
export type Direct_Debit_Charge_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Direct_Debit_Charge_Max_Fields = {
  __typename?: 'direct_debit_charge_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Direct_Debit_Charge_Min_Fields = {
  __typename?: 'direct_debit_charge_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "direct_debit_charge" */
export type Direct_Debit_Charge_Mutation_Response = {
  __typename?: 'direct_debit_charge_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Direct_Debit_Charge>;
};

/** input type for inserting object relation for remote table "direct_debit_charge" */
export type Direct_Debit_Charge_Obj_Rel_Insert_Input = {
  data: Direct_Debit_Charge_Insert_Input;
  on_conflict?: Maybe<Direct_Debit_Charge_On_Conflict>;
};

/** on conflict condition type for table "direct_debit_charge" */
export type Direct_Debit_Charge_On_Conflict = {
  constraint: Direct_Debit_Charge_Constraint;
  update_columns: Array<Direct_Debit_Charge_Update_Column>;
  where?: Maybe<Direct_Debit_Charge_Bool_Exp>;
};

/** ordering options when selecting data from "direct_debit_charge" */
export type Direct_Debit_Charge_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** primary key columns input for table: "direct_debit_charge" */
export type Direct_Debit_Charge_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "direct_debit_charge" */
export enum Direct_Debit_Charge_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "direct_debit_charge" */
export type Direct_Debit_Charge_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Direct_Debit_Charge_Stddev_Fields = {
  __typename?: 'direct_debit_charge_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Direct_Debit_Charge_Stddev_Pop_Fields = {
  __typename?: 'direct_debit_charge_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Direct_Debit_Charge_Stddev_Samp_Fields = {
  __typename?: 'direct_debit_charge_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Direct_Debit_Charge_Sum_Fields = {
  __typename?: 'direct_debit_charge_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  updated_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** update columns of table "direct_debit_charge" */
export enum Direct_Debit_Charge_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Direct_Debit_Charge_Var_Pop_Fields = {
  __typename?: 'direct_debit_charge_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Direct_Debit_Charge_Var_Samp_Fields = {
  __typename?: 'direct_debit_charge_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Direct_Debit_Charge_Variance_Fields = {
  __typename?: 'direct_debit_charge_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  updated_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "direct_debit_charge" */
export type Direct_Debit_Charge_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_by?: Maybe<Order_By>;
};

/** columns and relationships of "economic_indicator" */
export type Economic_Indicator = {
  __typename?: 'economic_indicator';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  inflation_rate?: Maybe<Scalars['float8']>;
  interest_rate?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "economic_indicator" */
export type Economic_Indicator_Aggregate = {
  __typename?: 'economic_indicator_aggregate';
  aggregate?: Maybe<Economic_Indicator_Aggregate_Fields>;
  nodes: Array<Economic_Indicator>;
};

/** aggregate fields of "economic_indicator" */
export type Economic_Indicator_Aggregate_Fields = {
  __typename?: 'economic_indicator_aggregate_fields';
  avg?: Maybe<Economic_Indicator_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Economic_Indicator_Max_Fields>;
  min?: Maybe<Economic_Indicator_Min_Fields>;
  stddev?: Maybe<Economic_Indicator_Stddev_Fields>;
  stddev_pop?: Maybe<Economic_Indicator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Economic_Indicator_Stddev_Samp_Fields>;
  sum?: Maybe<Economic_Indicator_Sum_Fields>;
  var_pop?: Maybe<Economic_Indicator_Var_Pop_Fields>;
  var_samp?: Maybe<Economic_Indicator_Var_Samp_Fields>;
  variance?: Maybe<Economic_Indicator_Variance_Fields>;
};


/** aggregate fields of "economic_indicator" */
export type Economic_Indicator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Economic_Indicator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "economic_indicator" */
export type Economic_Indicator_Aggregate_Order_By = {
  avg?: Maybe<Economic_Indicator_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Economic_Indicator_Max_Order_By>;
  min?: Maybe<Economic_Indicator_Min_Order_By>;
  stddev?: Maybe<Economic_Indicator_Stddev_Order_By>;
  stddev_pop?: Maybe<Economic_Indicator_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Economic_Indicator_Stddev_Samp_Order_By>;
  sum?: Maybe<Economic_Indicator_Sum_Order_By>;
  var_pop?: Maybe<Economic_Indicator_Var_Pop_Order_By>;
  var_samp?: Maybe<Economic_Indicator_Var_Samp_Order_By>;
  variance?: Maybe<Economic_Indicator_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "economic_indicator" */
export type Economic_Indicator_Arr_Rel_Insert_Input = {
  data: Array<Economic_Indicator_Insert_Input>;
  on_conflict?: Maybe<Economic_Indicator_On_Conflict>;
};

/** aggregate avg on columns */
export type Economic_Indicator_Avg_Fields = {
  __typename?: 'economic_indicator_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "economic_indicator" */
export type Economic_Indicator_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "economic_indicator". All fields are combined with a logical 'AND'. */
export type Economic_Indicator_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Economic_Indicator_Bool_Exp>>>;
  _not?: Maybe<Economic_Indicator_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Economic_Indicator_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  inflation_rate?: Maybe<Float8_Comparison_Exp>;
  interest_rate?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "economic_indicator" */
export enum Economic_Indicator_Constraint {
  /** unique or primary key constraint */
  EconomicIndicatorPkey = 'economic_indicator_pkey'
}

/** input type for incrementing integer column in table "economic_indicator" */
export type Economic_Indicator_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "economic_indicator" */
export type Economic_Indicator_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  interest_rate?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Economic_Indicator_Max_Fields = {
  __typename?: 'economic_indicator_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  interest_rate?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "economic_indicator" */
export type Economic_Indicator_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Economic_Indicator_Min_Fields = {
  __typename?: 'economic_indicator_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  interest_rate?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "economic_indicator" */
export type Economic_Indicator_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "economic_indicator" */
export type Economic_Indicator_Mutation_Response = {
  __typename?: 'economic_indicator_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Economic_Indicator>;
};

/** input type for inserting object relation for remote table "economic_indicator" */
export type Economic_Indicator_Obj_Rel_Insert_Input = {
  data: Economic_Indicator_Insert_Input;
  on_conflict?: Maybe<Economic_Indicator_On_Conflict>;
};

/** on conflict condition type for table "economic_indicator" */
export type Economic_Indicator_On_Conflict = {
  constraint: Economic_Indicator_Constraint;
  update_columns: Array<Economic_Indicator_Update_Column>;
  where?: Maybe<Economic_Indicator_Bool_Exp>;
};

/** ordering options when selecting data from "economic_indicator" */
export type Economic_Indicator_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "economic_indicator" */
export type Economic_Indicator_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "economic_indicator" */
export enum Economic_Indicator_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InflationRate = 'inflation_rate',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "economic_indicator" */
export type Economic_Indicator_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  interest_rate?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Economic_Indicator_Stddev_Fields = {
  __typename?: 'economic_indicator_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "economic_indicator" */
export type Economic_Indicator_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Economic_Indicator_Stddev_Pop_Fields = {
  __typename?: 'economic_indicator_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "economic_indicator" */
export type Economic_Indicator_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Economic_Indicator_Stddev_Samp_Fields = {
  __typename?: 'economic_indicator_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "economic_indicator" */
export type Economic_Indicator_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Economic_Indicator_Sum_Fields = {
  __typename?: 'economic_indicator_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  inflation_rate?: Maybe<Scalars['float8']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "economic_indicator" */
export type Economic_Indicator_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "economic_indicator" */
export enum Economic_Indicator_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InflationRate = 'inflation_rate',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Economic_Indicator_Var_Pop_Fields = {
  __typename?: 'economic_indicator_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "economic_indicator" */
export type Economic_Indicator_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Economic_Indicator_Var_Samp_Fields = {
  __typename?: 'economic_indicator_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "economic_indicator" */
export type Economic_Indicator_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Economic_Indicator_Variance_Fields = {
  __typename?: 'economic_indicator_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inflation_rate?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "economic_indicator" */
export type Economic_Indicator_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inflation_rate?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "expected_return" */
export type Expected_Return = {
  __typename?: 'expected_return';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  duration: Scalars['numeric'];
  expected_return: Scalars['numeric'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};

/** aggregated selection of "expected_return" */
export type Expected_Return_Aggregate = {
  __typename?: 'expected_return_aggregate';
  aggregate?: Maybe<Expected_Return_Aggregate_Fields>;
  nodes: Array<Expected_Return>;
};

/** aggregate fields of "expected_return" */
export type Expected_Return_Aggregate_Fields = {
  __typename?: 'expected_return_aggregate_fields';
  avg?: Maybe<Expected_Return_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Expected_Return_Max_Fields>;
  min?: Maybe<Expected_Return_Min_Fields>;
  stddev?: Maybe<Expected_Return_Stddev_Fields>;
  stddev_pop?: Maybe<Expected_Return_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Expected_Return_Stddev_Samp_Fields>;
  sum?: Maybe<Expected_Return_Sum_Fields>;
  var_pop?: Maybe<Expected_Return_Var_Pop_Fields>;
  var_samp?: Maybe<Expected_Return_Var_Samp_Fields>;
  variance?: Maybe<Expected_Return_Variance_Fields>;
};


/** aggregate fields of "expected_return" */
export type Expected_Return_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Expected_Return_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "expected_return" */
export type Expected_Return_Aggregate_Order_By = {
  avg?: Maybe<Expected_Return_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Expected_Return_Max_Order_By>;
  min?: Maybe<Expected_Return_Min_Order_By>;
  stddev?: Maybe<Expected_Return_Stddev_Order_By>;
  stddev_pop?: Maybe<Expected_Return_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Expected_Return_Stddev_Samp_Order_By>;
  sum?: Maybe<Expected_Return_Sum_Order_By>;
  var_pop?: Maybe<Expected_Return_Var_Pop_Order_By>;
  var_samp?: Maybe<Expected_Return_Var_Samp_Order_By>;
  variance?: Maybe<Expected_Return_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "expected_return" */
export type Expected_Return_Arr_Rel_Insert_Input = {
  data: Array<Expected_Return_Insert_Input>;
  on_conflict?: Maybe<Expected_Return_On_Conflict>;
};

/** aggregate avg on columns */
export type Expected_Return_Avg_Fields = {
  __typename?: 'expected_return_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "expected_return" */
export type Expected_Return_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "expected_return". All fields are combined with a logical 'AND'. */
export type Expected_Return_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Expected_Return_Bool_Exp>>>;
  _not?: Maybe<Expected_Return_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Expected_Return_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  duration?: Maybe<Numeric_Comparison_Exp>;
  expected_return?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "expected_return" */
export enum Expected_Return_Constraint {
  /** unique or primary key constraint */
  ExpectedReturnDurationKey = 'expected_return_duration_key',
  /** unique or primary key constraint */
  ExpectedReturnPkey = 'expected_return_pkey'
}

/** input type for incrementing integer column in table "expected_return" */
export type Expected_Return_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "expected_return" */
export type Expected_Return_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Expected_Return_Max_Fields = {
  __typename?: 'expected_return_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "expected_return" */
export type Expected_Return_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Expected_Return_Min_Fields = {
  __typename?: 'expected_return_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "expected_return" */
export type Expected_Return_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "expected_return" */
export type Expected_Return_Mutation_Response = {
  __typename?: 'expected_return_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Expected_Return>;
};

/** input type for inserting object relation for remote table "expected_return" */
export type Expected_Return_Obj_Rel_Insert_Input = {
  data: Expected_Return_Insert_Input;
  on_conflict?: Maybe<Expected_Return_On_Conflict>;
};

/** on conflict condition type for table "expected_return" */
export type Expected_Return_On_Conflict = {
  constraint: Expected_Return_Constraint;
  update_columns: Array<Expected_Return_Update_Column>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};

/** ordering options when selecting data from "expected_return" */
export type Expected_Return_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "expected_return" */
export type Expected_Return_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "expected_return" */
export enum Expected_Return_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExpectedReturn = 'expected_return',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "expected_return" */
export type Expected_Return_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Expected_Return_Stddev_Fields = {
  __typename?: 'expected_return_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "expected_return" */
export type Expected_Return_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Expected_Return_Stddev_Pop_Fields = {
  __typename?: 'expected_return_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "expected_return" */
export type Expected_Return_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Expected_Return_Stddev_Samp_Fields = {
  __typename?: 'expected_return_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "expected_return" */
export type Expected_Return_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Expected_Return_Sum_Fields = {
  __typename?: 'expected_return_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['numeric']>;
  expected_return?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "expected_return" */
export type Expected_Return_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "expected_return" */
export enum Expected_Return_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Duration = 'duration',
  /** column name */
  ExpectedReturn = 'expected_return',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Expected_Return_Var_Pop_Fields = {
  __typename?: 'expected_return_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "expected_return" */
export type Expected_Return_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Expected_Return_Var_Samp_Fields = {
  __typename?: 'expected_return_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "expected_return" */
export type Expected_Return_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Expected_Return_Variance_Fields = {
  __typename?: 'expected_return_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "expected_return" */
export type Expected_Return_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};


/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "gender" */
export type Gender = {
  __typename?: 'gender';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_beneficiaries: Array<Member_Beneficiary>;
  /** An aggregated array relationship */
  member_beneficiaries_aggregate: Member_Beneficiary_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  next_of_kins: Array<Next_Of_Kin>;
  /** An aggregated array relationship */
  next_of_kins_aggregate: Next_Of_Kin_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregated array relationship */
  users_aggregate: User_Aggregate;
  /** An array relationship */
  users_jt: Array<User>;
  /** An aggregated array relationship */
  users_jt_aggregate: User_Aggregate;
};


/** columns and relationships of "gender" */
export type GenderMember_BeneficiariesArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderMember_Beneficiaries_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderNext_Of_KinsArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderNext_Of_Kins_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderUsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderUsers_JtArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** columns and relationships of "gender" */
export type GenderUsers_Jt_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** aggregated selection of "gender" */
export type Gender_Aggregate = {
  __typename?: 'gender_aggregate';
  aggregate?: Maybe<Gender_Aggregate_Fields>;
  nodes: Array<Gender>;
};

/** aggregate fields of "gender" */
export type Gender_Aggregate_Fields = {
  __typename?: 'gender_aggregate_fields';
  avg?: Maybe<Gender_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Gender_Max_Fields>;
  min?: Maybe<Gender_Min_Fields>;
  stddev?: Maybe<Gender_Stddev_Fields>;
  stddev_pop?: Maybe<Gender_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gender_Stddev_Samp_Fields>;
  sum?: Maybe<Gender_Sum_Fields>;
  var_pop?: Maybe<Gender_Var_Pop_Fields>;
  var_samp?: Maybe<Gender_Var_Samp_Fields>;
  variance?: Maybe<Gender_Variance_Fields>;
};


/** aggregate fields of "gender" */
export type Gender_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gender_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gender" */
export type Gender_Aggregate_Order_By = {
  avg?: Maybe<Gender_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Gender_Max_Order_By>;
  min?: Maybe<Gender_Min_Order_By>;
  stddev?: Maybe<Gender_Stddev_Order_By>;
  stddev_pop?: Maybe<Gender_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Gender_Stddev_Samp_Order_By>;
  sum?: Maybe<Gender_Sum_Order_By>;
  var_pop?: Maybe<Gender_Var_Pop_Order_By>;
  var_samp?: Maybe<Gender_Var_Samp_Order_By>;
  variance?: Maybe<Gender_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gender" */
export type Gender_Arr_Rel_Insert_Input = {
  data: Array<Gender_Insert_Input>;
  on_conflict?: Maybe<Gender_On_Conflict>;
};

/** aggregate avg on columns */
export type Gender_Avg_Fields = {
  __typename?: 'gender_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gender" */
export type Gender_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gender". All fields are combined with a logical 'AND'. */
export type Gender_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Gender_Bool_Exp>>>;
  _not?: Maybe<Gender_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Gender_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  next_of_kins?: Maybe<Next_Of_Kin_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<User_Bool_Exp>;
  users_jt?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "gender" */
export enum Gender_Constraint {
  /** unique or primary key constraint */
  GenderNameKey = 'gender_name_key',
  /** unique or primary key constraint */
  GenderPkey = 'gender_pkey'
}

/** input type for incrementing integer column in table "gender" */
export type Gender_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "gender" */
export type Gender_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  next_of_kins?: Maybe<Next_Of_Kin_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<User_Arr_Rel_Insert_Input>;
  users_jt?: Maybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Gender_Max_Fields = {
  __typename?: 'gender_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "gender" */
export type Gender_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gender_Min_Fields = {
  __typename?: 'gender_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "gender" */
export type Gender_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "gender" */
export type Gender_Mutation_Response = {
  __typename?: 'gender_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Gender>;
};

/** input type for inserting object relation for remote table "gender" */
export type Gender_Obj_Rel_Insert_Input = {
  data: Gender_Insert_Input;
  on_conflict?: Maybe<Gender_On_Conflict>;
};

/** on conflict condition type for table "gender" */
export type Gender_On_Conflict = {
  constraint: Gender_Constraint;
  update_columns: Array<Gender_Update_Column>;
  where?: Maybe<Gender_Bool_Exp>;
};

/** ordering options when selecting data from "gender" */
export type Gender_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_beneficiaries_aggregate?: Maybe<Member_Beneficiary_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  next_of_kins_aggregate?: Maybe<Next_Of_Kin_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<User_Aggregate_Order_By>;
  users_jt_aggregate?: Maybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: "gender" */
export type Gender_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "gender" */
export enum Gender_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gender" */
export type Gender_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Gender_Stddev_Fields = {
  __typename?: 'gender_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gender" */
export type Gender_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gender_Stddev_Pop_Fields = {
  __typename?: 'gender_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gender" */
export type Gender_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gender_Stddev_Samp_Fields = {
  __typename?: 'gender_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gender" */
export type Gender_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Gender_Sum_Fields = {
  __typename?: 'gender_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "gender" */
export type Gender_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "gender" */
export enum Gender_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Gender_Var_Pop_Fields = {
  __typename?: 'gender_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gender" */
export type Gender_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gender_Var_Samp_Fields = {
  __typename?: 'gender_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gender" */
export type Gender_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Gender_Variance_Fields = {
  __typename?: 'gender_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gender" */
export type Gender_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "goal" */
export type Goal = {
  __typename?: 'goal';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An array relationship */
  goal_products: Array<Goal_Product>;
  /** An aggregated array relationship */
  goal_products_aggregate: Goal_Product_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  member_goals: Array<Member_Goal>;
  /** An aggregated array relationship */
  member_goals_aggregate: Member_Goal_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "goal" */
export type GoalGoal_ProductsArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** columns and relationships of "goal" */
export type GoalGoal_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** columns and relationships of "goal" */
export type GoalMember_GoalsArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** columns and relationships of "goal" */
export type GoalMember_Goals_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};

/** aggregated selection of "goal" */
export type Goal_Aggregate = {
  __typename?: 'goal_aggregate';
  aggregate?: Maybe<Goal_Aggregate_Fields>;
  nodes: Array<Goal>;
};

/** aggregate fields of "goal" */
export type Goal_Aggregate_Fields = {
  __typename?: 'goal_aggregate_fields';
  avg?: Maybe<Goal_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Goal_Max_Fields>;
  min?: Maybe<Goal_Min_Fields>;
  stddev?: Maybe<Goal_Stddev_Fields>;
  stddev_pop?: Maybe<Goal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Goal_Stddev_Samp_Fields>;
  sum?: Maybe<Goal_Sum_Fields>;
  var_pop?: Maybe<Goal_Var_Pop_Fields>;
  var_samp?: Maybe<Goal_Var_Samp_Fields>;
  variance?: Maybe<Goal_Variance_Fields>;
};


/** aggregate fields of "goal" */
export type Goal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Goal_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goal" */
export type Goal_Aggregate_Order_By = {
  avg?: Maybe<Goal_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Goal_Max_Order_By>;
  min?: Maybe<Goal_Min_Order_By>;
  stddev?: Maybe<Goal_Stddev_Order_By>;
  stddev_pop?: Maybe<Goal_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Goal_Stddev_Samp_Order_By>;
  sum?: Maybe<Goal_Sum_Order_By>;
  var_pop?: Maybe<Goal_Var_Pop_Order_By>;
  var_samp?: Maybe<Goal_Var_Samp_Order_By>;
  variance?: Maybe<Goal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "goal" */
export type Goal_Arr_Rel_Insert_Input = {
  data: Array<Goal_Insert_Input>;
  on_conflict?: Maybe<Goal_On_Conflict>;
};

/** aggregate avg on columns */
export type Goal_Avg_Fields = {
  __typename?: 'goal_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "goal" */
export type Goal_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "goal". All fields are combined with a logical 'AND'. */
export type Goal_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Goal_Bool_Exp>>>;
  _not?: Maybe<Goal_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Goal_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  goal_products?: Maybe<Goal_Product_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_goals?: Maybe<Member_Goal_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "goal" */
export enum Goal_Constraint {
  /** unique or primary key constraint */
  GoalNameKey = 'goal_name_key',
  /** unique or primary key constraint */
  GoalPkey = 'goal_pkey'
}

/** input type for incrementing integer column in table "goal" */
export type Goal_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "goal" */
export type Goal_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal_products?: Maybe<Goal_Product_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  member_goals?: Maybe<Member_Goal_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "goal_insurance" */
export type Goal_Insurance = {
  __typename?: 'goal_insurance';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  insurance_premium: Scalars['numeric'];
  insurance_rate_per_1000: Scalars['numeric'];
  /** An object relationship */
  insurance_type?: Maybe<Insurance_Type>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  member_goal: Member_Goal;
  member_goal_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "goal_insurance" */
export type Goal_Insurance_Aggregate = {
  __typename?: 'goal_insurance_aggregate';
  aggregate?: Maybe<Goal_Insurance_Aggregate_Fields>;
  nodes: Array<Goal_Insurance>;
};

/** aggregate fields of "goal_insurance" */
export type Goal_Insurance_Aggregate_Fields = {
  __typename?: 'goal_insurance_aggregate_fields';
  avg?: Maybe<Goal_Insurance_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Goal_Insurance_Max_Fields>;
  min?: Maybe<Goal_Insurance_Min_Fields>;
  stddev?: Maybe<Goal_Insurance_Stddev_Fields>;
  stddev_pop?: Maybe<Goal_Insurance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Goal_Insurance_Stddev_Samp_Fields>;
  sum?: Maybe<Goal_Insurance_Sum_Fields>;
  var_pop?: Maybe<Goal_Insurance_Var_Pop_Fields>;
  var_samp?: Maybe<Goal_Insurance_Var_Samp_Fields>;
  variance?: Maybe<Goal_Insurance_Variance_Fields>;
};


/** aggregate fields of "goal_insurance" */
export type Goal_Insurance_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Goal_Insurance_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goal_insurance" */
export type Goal_Insurance_Aggregate_Order_By = {
  avg?: Maybe<Goal_Insurance_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Goal_Insurance_Max_Order_By>;
  min?: Maybe<Goal_Insurance_Min_Order_By>;
  stddev?: Maybe<Goal_Insurance_Stddev_Order_By>;
  stddev_pop?: Maybe<Goal_Insurance_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Goal_Insurance_Stddev_Samp_Order_By>;
  sum?: Maybe<Goal_Insurance_Sum_Order_By>;
  var_pop?: Maybe<Goal_Insurance_Var_Pop_Order_By>;
  var_samp?: Maybe<Goal_Insurance_Var_Samp_Order_By>;
  variance?: Maybe<Goal_Insurance_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "goal_insurance" */
export type Goal_Insurance_Arr_Rel_Insert_Input = {
  data: Array<Goal_Insurance_Insert_Input>;
  on_conflict?: Maybe<Goal_Insurance_On_Conflict>;
};

/** aggregate avg on columns */
export type Goal_Insurance_Avg_Fields = {
  __typename?: 'goal_insurance_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "goal_insurance" */
export type Goal_Insurance_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "goal_insurance". All fields are combined with a logical 'AND'. */
export type Goal_Insurance_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Goal_Insurance_Bool_Exp>>>;
  _not?: Maybe<Goal_Insurance_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Goal_Insurance_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  insurance_premium?: Maybe<Numeric_Comparison_Exp>;
  insurance_rate_per_1000?: Maybe<Numeric_Comparison_Exp>;
  insurance_type?: Maybe<Insurance_Type_Bool_Exp>;
  insurance_type_id?: Maybe<Bigint_Comparison_Exp>;
  member_goal?: Maybe<Member_Goal_Bool_Exp>;
  member_goal_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "goal_insurance" */
export enum Goal_Insurance_Constraint {
  /** unique or primary key constraint */
  GoalInsurancePkey = 'goal_insurance_pkey'
}

/** input type for incrementing integer column in table "goal_insurance" */
export type Goal_Insurance_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "goal_insurance" */
export type Goal_Insurance_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type?: Maybe<Insurance_Type_Obj_Rel_Insert_Input>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal?: Maybe<Member_Goal_Obj_Rel_Insert_Input>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Goal_Insurance_Max_Fields = {
  __typename?: 'goal_insurance_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "goal_insurance" */
export type Goal_Insurance_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Goal_Insurance_Min_Fields = {
  __typename?: 'goal_insurance_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "goal_insurance" */
export type Goal_Insurance_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "goal_insurance" */
export type Goal_Insurance_Mutation_Response = {
  __typename?: 'goal_insurance_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Goal_Insurance>;
};

/** input type for inserting object relation for remote table "goal_insurance" */
export type Goal_Insurance_Obj_Rel_Insert_Input = {
  data: Goal_Insurance_Insert_Input;
  on_conflict?: Maybe<Goal_Insurance_On_Conflict>;
};

/** on conflict condition type for table "goal_insurance" */
export type Goal_Insurance_On_Conflict = {
  constraint: Goal_Insurance_Constraint;
  update_columns: Array<Goal_Insurance_Update_Column>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};

/** ordering options when selecting data from "goal_insurance" */
export type Goal_Insurance_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type?: Maybe<Insurance_Type_Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal?: Maybe<Member_Goal_Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "goal_insurance" */
export type Goal_Insurance_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "goal_insurance" */
export enum Goal_Insurance_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePremium = 'insurance_premium',
  /** column name */
  InsuranceRatePer_1000 = 'insurance_rate_per_1000',
  /** column name */
  InsuranceTypeId = 'insurance_type_id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "goal_insurance" */
export type Goal_Insurance_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Goal_Insurance_Stddev_Fields = {
  __typename?: 'goal_insurance_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "goal_insurance" */
export type Goal_Insurance_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Goal_Insurance_Stddev_Pop_Fields = {
  __typename?: 'goal_insurance_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "goal_insurance" */
export type Goal_Insurance_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Goal_Insurance_Stddev_Samp_Fields = {
  __typename?: 'goal_insurance_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "goal_insurance" */
export type Goal_Insurance_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Goal_Insurance_Sum_Fields = {
  __typename?: 'goal_insurance_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_premium?: Maybe<Scalars['numeric']>;
  insurance_rate_per_1000?: Maybe<Scalars['numeric']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "goal_insurance" */
export type Goal_Insurance_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "goal_insurance" */
export enum Goal_Insurance_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InsurancePremium = 'insurance_premium',
  /** column name */
  InsuranceRatePer_1000 = 'insurance_rate_per_1000',
  /** column name */
  InsuranceTypeId = 'insurance_type_id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Goal_Insurance_Var_Pop_Fields = {
  __typename?: 'goal_insurance_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "goal_insurance" */
export type Goal_Insurance_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Goal_Insurance_Var_Samp_Fields = {
  __typename?: 'goal_insurance_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "goal_insurance" */
export type Goal_Insurance_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Goal_Insurance_Variance_Fields = {
  __typename?: 'goal_insurance_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_premium?: Maybe<Scalars['Float']>;
  insurance_rate_per_1000?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "goal_insurance" */
export type Goal_Insurance_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_premium?: Maybe<Order_By>;
  insurance_rate_per_1000?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Goal_Max_Fields = {
  __typename?: 'goal_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "goal" */
export type Goal_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Goal_Min_Fields = {
  __typename?: 'goal_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "goal" */
export type Goal_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "goal" */
export type Goal_Mutation_Response = {
  __typename?: 'goal_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Goal>;
};

/** input type for inserting object relation for remote table "goal" */
export type Goal_Obj_Rel_Insert_Input = {
  data: Goal_Insert_Input;
  on_conflict?: Maybe<Goal_On_Conflict>;
};

/** on conflict condition type for table "goal" */
export type Goal_On_Conflict = {
  constraint: Goal_Constraint;
  update_columns: Array<Goal_Update_Column>;
  where?: Maybe<Goal_Bool_Exp>;
};

/** ordering options when selecting data from "goal" */
export type Goal_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  goal_products_aggregate?: Maybe<Goal_Product_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  member_goals_aggregate?: Maybe<Member_Goal_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "goal" */
export type Goal_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "goal_product" */
export type Goal_Product = {
  __typename?: 'goal_product';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An object relationship */
  goal: Goal;
  goal_id: Scalars['bigint'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "goal_product" */
export type Goal_Product_Aggregate = {
  __typename?: 'goal_product_aggregate';
  aggregate?: Maybe<Goal_Product_Aggregate_Fields>;
  nodes: Array<Goal_Product>;
};

/** aggregate fields of "goal_product" */
export type Goal_Product_Aggregate_Fields = {
  __typename?: 'goal_product_aggregate_fields';
  avg?: Maybe<Goal_Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Goal_Product_Max_Fields>;
  min?: Maybe<Goal_Product_Min_Fields>;
  stddev?: Maybe<Goal_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Goal_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Goal_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Goal_Product_Sum_Fields>;
  var_pop?: Maybe<Goal_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Goal_Product_Var_Samp_Fields>;
  variance?: Maybe<Goal_Product_Variance_Fields>;
};


/** aggregate fields of "goal_product" */
export type Goal_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Goal_Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "goal_product" */
export type Goal_Product_Aggregate_Order_By = {
  avg?: Maybe<Goal_Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Goal_Product_Max_Order_By>;
  min?: Maybe<Goal_Product_Min_Order_By>;
  stddev?: Maybe<Goal_Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Goal_Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Goal_Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Goal_Product_Sum_Order_By>;
  var_pop?: Maybe<Goal_Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Goal_Product_Var_Samp_Order_By>;
  variance?: Maybe<Goal_Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "goal_product" */
export type Goal_Product_Arr_Rel_Insert_Input = {
  data: Array<Goal_Product_Insert_Input>;
  on_conflict?: Maybe<Goal_Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Goal_Product_Avg_Fields = {
  __typename?: 'goal_product_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "goal_product" */
export type Goal_Product_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "goal_product". All fields are combined with a logical 'AND'. */
export type Goal_Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Goal_Product_Bool_Exp>>>;
  _not?: Maybe<Goal_Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Goal_Product_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  goal?: Maybe<Goal_Bool_Exp>;
  goal_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "goal_product" */
export enum Goal_Product_Constraint {
  /** unique or primary key constraint */
  GoalProductPkey = 'goal_product_pkey'
}

/** input type for incrementing integer column in table "goal_product" */
export type Goal_Product_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "goal_product" */
export type Goal_Product_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal?: Maybe<Goal_Obj_Rel_Insert_Input>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Goal_Product_Max_Fields = {
  __typename?: 'goal_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "goal_product" */
export type Goal_Product_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Goal_Product_Min_Fields = {
  __typename?: 'goal_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "goal_product" */
export type Goal_Product_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "goal_product" */
export type Goal_Product_Mutation_Response = {
  __typename?: 'goal_product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Goal_Product>;
};

/** input type for inserting object relation for remote table "goal_product" */
export type Goal_Product_Obj_Rel_Insert_Input = {
  data: Goal_Product_Insert_Input;
  on_conflict?: Maybe<Goal_Product_On_Conflict>;
};

/** on conflict condition type for table "goal_product" */
export type Goal_Product_On_Conflict = {
  constraint: Goal_Product_Constraint;
  update_columns: Array<Goal_Product_Update_Column>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};

/** ordering options when selecting data from "goal_product" */
export type Goal_Product_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  goal?: Maybe<Goal_Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "goal_product" */
export type Goal_Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "goal_product" */
export enum Goal_Product_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoalId = 'goal_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "goal_product" */
export type Goal_Product_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Goal_Product_Stddev_Fields = {
  __typename?: 'goal_product_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "goal_product" */
export type Goal_Product_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Goal_Product_Stddev_Pop_Fields = {
  __typename?: 'goal_product_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "goal_product" */
export type Goal_Product_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Goal_Product_Stddev_Samp_Fields = {
  __typename?: 'goal_product_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "goal_product" */
export type Goal_Product_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Goal_Product_Sum_Fields = {
  __typename?: 'goal_product_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "goal_product" */
export type Goal_Product_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** update columns of table "goal_product" */
export enum Goal_Product_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoalId = 'goal_id',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Goal_Product_Var_Pop_Fields = {
  __typename?: 'goal_product_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "goal_product" */
export type Goal_Product_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Goal_Product_Var_Samp_Fields = {
  __typename?: 'goal_product_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "goal_product" */
export type Goal_Product_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Goal_Product_Variance_Fields = {
  __typename?: 'goal_product_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "goal_product" */
export type Goal_Product_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** select columns of table "goal" */
export enum Goal_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "goal" */
export type Goal_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Goal_Stddev_Fields = {
  __typename?: 'goal_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "goal" */
export type Goal_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Goal_Stddev_Pop_Fields = {
  __typename?: 'goal_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "goal" */
export type Goal_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Goal_Stddev_Samp_Fields = {
  __typename?: 'goal_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "goal" */
export type Goal_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Goal_Sum_Fields = {
  __typename?: 'goal_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "goal" */
export type Goal_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "goal" */
export enum Goal_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Goal_Var_Pop_Fields = {
  __typename?: 'goal_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "goal" */
export type Goal_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Goal_Var_Samp_Fields = {
  __typename?: 'goal_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "goal" */
export type Goal_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Goal_Variance_Fields = {
  __typename?: 'goal_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "goal" */
export type Goal_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "hmd_education" */
export type Hmd_Education = {
  __typename?: 'hmd_education';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted: Scalars['Boolean'];
  graduate_fees_base_value: Scalars['numeric'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value: Scalars['numeric'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "hmd_education" */
export type Hmd_Education_Aggregate = {
  __typename?: 'hmd_education_aggregate';
  aggregate?: Maybe<Hmd_Education_Aggregate_Fields>;
  nodes: Array<Hmd_Education>;
};

/** aggregate fields of "hmd_education" */
export type Hmd_Education_Aggregate_Fields = {
  __typename?: 'hmd_education_aggregate_fields';
  avg?: Maybe<Hmd_Education_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hmd_Education_Max_Fields>;
  min?: Maybe<Hmd_Education_Min_Fields>;
  stddev?: Maybe<Hmd_Education_Stddev_Fields>;
  stddev_pop?: Maybe<Hmd_Education_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hmd_Education_Stddev_Samp_Fields>;
  sum?: Maybe<Hmd_Education_Sum_Fields>;
  var_pop?: Maybe<Hmd_Education_Var_Pop_Fields>;
  var_samp?: Maybe<Hmd_Education_Var_Samp_Fields>;
  variance?: Maybe<Hmd_Education_Variance_Fields>;
};


/** aggregate fields of "hmd_education" */
export type Hmd_Education_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hmd_Education_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hmd_education" */
export type Hmd_Education_Aggregate_Order_By = {
  avg?: Maybe<Hmd_Education_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hmd_Education_Max_Order_By>;
  min?: Maybe<Hmd_Education_Min_Order_By>;
  stddev?: Maybe<Hmd_Education_Stddev_Order_By>;
  stddev_pop?: Maybe<Hmd_Education_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hmd_Education_Stddev_Samp_Order_By>;
  sum?: Maybe<Hmd_Education_Sum_Order_By>;
  var_pop?: Maybe<Hmd_Education_Var_Pop_Order_By>;
  var_samp?: Maybe<Hmd_Education_Var_Samp_Order_By>;
  variance?: Maybe<Hmd_Education_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hmd_education" */
export type Hmd_Education_Arr_Rel_Insert_Input = {
  data: Array<Hmd_Education_Insert_Input>;
  on_conflict?: Maybe<Hmd_Education_On_Conflict>;
};

/** aggregate avg on columns */
export type Hmd_Education_Avg_Fields = {
  __typename?: 'hmd_education_avg_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hmd_education" */
export type Hmd_Education_Avg_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hmd_education". All fields are combined with a logical 'AND'. */
export type Hmd_Education_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hmd_Education_Bool_Exp>>>;
  _not?: Maybe<Hmd_Education_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hmd_Education_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  graduate_fees_base_value?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  semester_fees_base_value?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hmd_education" */
export enum Hmd_Education_Constraint {
  /** unique or primary key constraint */
  HmdEducationPkey = 'hmd_education_pkey'
}

/** input type for incrementing integer column in table "hmd_education" */
export type Hmd_Education_Inc_Input = {
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "hmd_education" */
export type Hmd_Education_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hmd_Education_Max_Fields = {
  __typename?: 'hmd_education_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hmd_education" */
export type Hmd_Education_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hmd_Education_Min_Fields = {
  __typename?: 'hmd_education_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hmd_education" */
export type Hmd_Education_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hmd_education" */
export type Hmd_Education_Mutation_Response = {
  __typename?: 'hmd_education_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hmd_Education>;
};

/** input type for inserting object relation for remote table "hmd_education" */
export type Hmd_Education_Obj_Rel_Insert_Input = {
  data: Hmd_Education_Insert_Input;
  on_conflict?: Maybe<Hmd_Education_On_Conflict>;
};

/** on conflict condition type for table "hmd_education" */
export type Hmd_Education_On_Conflict = {
  constraint: Hmd_Education_Constraint;
  update_columns: Array<Hmd_Education_Update_Column>;
  where?: Maybe<Hmd_Education_Bool_Exp>;
};

/** ordering options when selecting data from "hmd_education" */
export type Hmd_Education_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hmd_education" */
export type Hmd_Education_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "hmd_education" */
export enum Hmd_Education_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GraduateFeesBaseValue = 'graduate_fees_base_value',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  SemesterFeesBaseValue = 'semester_fees_base_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hmd_education" */
export type Hmd_Education_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Hmd_Education_Stddev_Fields = {
  __typename?: 'hmd_education_stddev_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hmd_education" */
export type Hmd_Education_Stddev_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hmd_Education_Stddev_Pop_Fields = {
  __typename?: 'hmd_education_stddev_pop_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hmd_education" */
export type Hmd_Education_Stddev_Pop_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hmd_Education_Stddev_Samp_Fields = {
  __typename?: 'hmd_education_stddev_samp_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hmd_education" */
export type Hmd_Education_Stddev_Samp_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hmd_Education_Sum_Fields = {
  __typename?: 'hmd_education_sum_fields';
  graduate_fees_base_value?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  semester_fees_base_value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "hmd_education" */
export type Hmd_Education_Sum_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** update columns of table "hmd_education" */
export enum Hmd_Education_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GraduateFeesBaseValue = 'graduate_fees_base_value',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  SemesterFeesBaseValue = 'semester_fees_base_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Hmd_Education_Var_Pop_Fields = {
  __typename?: 'hmd_education_var_pop_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hmd_education" */
export type Hmd_Education_Var_Pop_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hmd_Education_Var_Samp_Fields = {
  __typename?: 'hmd_education_var_samp_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hmd_education" */
export type Hmd_Education_Var_Samp_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hmd_Education_Variance_Fields = {
  __typename?: 'hmd_education_variance_fields';
  graduate_fees_base_value?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  semester_fees_base_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hmd_education" */
export type Hmd_Education_Variance_Order_By = {
  graduate_fees_base_value?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  semester_fees_base_value?: Maybe<Order_By>;
};

/** columns and relationships of "hmd_expense_base" */
export type Hmd_Expense_Base = {
  __typename?: 'hmd_expense_base';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  hmd_lifestyle: Hmd_Lifestyle;
  id: Scalars['bigint'];
  lifestyle_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  region: Region;
  region_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "hmd_expense_base" */
export type Hmd_Expense_Base_Aggregate = {
  __typename?: 'hmd_expense_base_aggregate';
  aggregate?: Maybe<Hmd_Expense_Base_Aggregate_Fields>;
  nodes: Array<Hmd_Expense_Base>;
};

/** aggregate fields of "hmd_expense_base" */
export type Hmd_Expense_Base_Aggregate_Fields = {
  __typename?: 'hmd_expense_base_aggregate_fields';
  avg?: Maybe<Hmd_Expense_Base_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hmd_Expense_Base_Max_Fields>;
  min?: Maybe<Hmd_Expense_Base_Min_Fields>;
  stddev?: Maybe<Hmd_Expense_Base_Stddev_Fields>;
  stddev_pop?: Maybe<Hmd_Expense_Base_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hmd_Expense_Base_Stddev_Samp_Fields>;
  sum?: Maybe<Hmd_Expense_Base_Sum_Fields>;
  var_pop?: Maybe<Hmd_Expense_Base_Var_Pop_Fields>;
  var_samp?: Maybe<Hmd_Expense_Base_Var_Samp_Fields>;
  variance?: Maybe<Hmd_Expense_Base_Variance_Fields>;
};


/** aggregate fields of "hmd_expense_base" */
export type Hmd_Expense_Base_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hmd_expense_base" */
export type Hmd_Expense_Base_Aggregate_Order_By = {
  avg?: Maybe<Hmd_Expense_Base_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hmd_Expense_Base_Max_Order_By>;
  min?: Maybe<Hmd_Expense_Base_Min_Order_By>;
  stddev?: Maybe<Hmd_Expense_Base_Stddev_Order_By>;
  stddev_pop?: Maybe<Hmd_Expense_Base_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hmd_Expense_Base_Stddev_Samp_Order_By>;
  sum?: Maybe<Hmd_Expense_Base_Sum_Order_By>;
  var_pop?: Maybe<Hmd_Expense_Base_Var_Pop_Order_By>;
  var_samp?: Maybe<Hmd_Expense_Base_Var_Samp_Order_By>;
  variance?: Maybe<Hmd_Expense_Base_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hmd_expense_base" */
export type Hmd_Expense_Base_Arr_Rel_Insert_Input = {
  data: Array<Hmd_Expense_Base_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Base_On_Conflict>;
};

/** aggregate avg on columns */
export type Hmd_Expense_Base_Avg_Fields = {
  __typename?: 'hmd_expense_base_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hmd_expense_base". All fields are combined with a logical 'AND'. */
export type Hmd_Expense_Base_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hmd_Expense_Base_Bool_Exp>>>;
  _not?: Maybe<Hmd_Expense_Base_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hmd_Expense_Base_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  lifestyle_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hmd_expense_base" */
export enum Hmd_Expense_Base_Constraint {
  /** unique or primary key constraint */
  HmdExpenseBasePkey = 'hmd_expense_base_pkey'
}

/** input type for incrementing integer column in table "hmd_expense_base" */
export type Hmd_Expense_Base_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "hmd_expense_base" */
export type Hmd_Expense_Base_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hmd_Expense_Base_Max_Fields = {
  __typename?: 'hmd_expense_base_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hmd_Expense_Base_Min_Fields = {
  __typename?: 'hmd_expense_base_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hmd_expense_base" */
export type Hmd_Expense_Base_Mutation_Response = {
  __typename?: 'hmd_expense_base_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hmd_Expense_Base>;
};

/** input type for inserting object relation for remote table "hmd_expense_base" */
export type Hmd_Expense_Base_Obj_Rel_Insert_Input = {
  data: Hmd_Expense_Base_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Base_On_Conflict>;
};

/** on conflict condition type for table "hmd_expense_base" */
export type Hmd_Expense_Base_On_Conflict = {
  constraint: Hmd_Expense_Base_Constraint;
  update_columns: Array<Hmd_Expense_Base_Update_Column>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};

/** ordering options when selecting data from "hmd_expense_base" */
export type Hmd_Expense_Base_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hmd_expense_base" */
export type Hmd_Expense_Base_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "hmd_expense_base" */
export enum Hmd_Expense_Base_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LifestyleId = 'lifestyle_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hmd_expense_base" */
export type Hmd_Expense_Base_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Hmd_Expense_Base_Stddev_Fields = {
  __typename?: 'hmd_expense_base_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hmd_Expense_Base_Stddev_Pop_Fields = {
  __typename?: 'hmd_expense_base_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hmd_Expense_Base_Stddev_Samp_Fields = {
  __typename?: 'hmd_expense_base_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hmd_Expense_Base_Sum_Fields = {
  __typename?: 'hmd_expense_base_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** update columns of table "hmd_expense_base" */
export enum Hmd_Expense_Base_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LifestyleId = 'lifestyle_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Hmd_Expense_Base_Var_Pop_Fields = {
  __typename?: 'hmd_expense_base_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hmd_Expense_Base_Var_Samp_Fields = {
  __typename?: 'hmd_expense_base_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hmd_Expense_Base_Variance_Fields = {
  __typename?: 'hmd_expense_base_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hmd_expense_base" */
export type Hmd_Expense_Base_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
};

/** columns and relationships of "hmd_expense_category" */
export type Hmd_Expense_Category = {
  __typename?: 'hmd_expense_category';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An array relationship */
  hmd_expense_multipliers: Array<Hmd_Expense_Multiplier>;
  /** An aggregated array relationship */
  hmd_expense_multipliers_aggregate: Hmd_Expense_Multiplier_Aggregate;
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "hmd_expense_category" */
export type Hmd_Expense_CategoryHmd_Expense_MultipliersArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** columns and relationships of "hmd_expense_category" */
export type Hmd_Expense_CategoryHmd_Expense_Multipliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};

/** aggregated selection of "hmd_expense_category" */
export type Hmd_Expense_Category_Aggregate = {
  __typename?: 'hmd_expense_category_aggregate';
  aggregate?: Maybe<Hmd_Expense_Category_Aggregate_Fields>;
  nodes: Array<Hmd_Expense_Category>;
};

/** aggregate fields of "hmd_expense_category" */
export type Hmd_Expense_Category_Aggregate_Fields = {
  __typename?: 'hmd_expense_category_aggregate_fields';
  avg?: Maybe<Hmd_Expense_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hmd_Expense_Category_Max_Fields>;
  min?: Maybe<Hmd_Expense_Category_Min_Fields>;
  stddev?: Maybe<Hmd_Expense_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Hmd_Expense_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hmd_Expense_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Hmd_Expense_Category_Sum_Fields>;
  var_pop?: Maybe<Hmd_Expense_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Hmd_Expense_Category_Var_Samp_Fields>;
  variance?: Maybe<Hmd_Expense_Category_Variance_Fields>;
};


/** aggregate fields of "hmd_expense_category" */
export type Hmd_Expense_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hmd_Expense_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hmd_expense_category" */
export type Hmd_Expense_Category_Aggregate_Order_By = {
  avg?: Maybe<Hmd_Expense_Category_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hmd_Expense_Category_Max_Order_By>;
  min?: Maybe<Hmd_Expense_Category_Min_Order_By>;
  stddev?: Maybe<Hmd_Expense_Category_Stddev_Order_By>;
  stddev_pop?: Maybe<Hmd_Expense_Category_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hmd_Expense_Category_Stddev_Samp_Order_By>;
  sum?: Maybe<Hmd_Expense_Category_Sum_Order_By>;
  var_pop?: Maybe<Hmd_Expense_Category_Var_Pop_Order_By>;
  var_samp?: Maybe<Hmd_Expense_Category_Var_Samp_Order_By>;
  variance?: Maybe<Hmd_Expense_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hmd_expense_category" */
export type Hmd_Expense_Category_Arr_Rel_Insert_Input = {
  data: Array<Hmd_Expense_Category_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Hmd_Expense_Category_Avg_Fields = {
  __typename?: 'hmd_expense_category_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hmd_expense_category". All fields are combined with a logical 'AND'. */
export type Hmd_Expense_Category_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hmd_Expense_Category_Bool_Exp>>>;
  _not?: Maybe<Hmd_Expense_Category_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hmd_Expense_Category_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hmd_expense_category" */
export enum Hmd_Expense_Category_Constraint {
  /** unique or primary key constraint */
  HmdExpenseCategoryPkey = 'hmd_expense_category_pkey'
}

/** input type for incrementing integer column in table "hmd_expense_category" */
export type Hmd_Expense_Category_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "hmd_expense_category" */
export type Hmd_Expense_Category_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hmd_Expense_Category_Max_Fields = {
  __typename?: 'hmd_expense_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hmd_Expense_Category_Min_Fields = {
  __typename?: 'hmd_expense_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hmd_expense_category" */
export type Hmd_Expense_Category_Mutation_Response = {
  __typename?: 'hmd_expense_category_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hmd_Expense_Category>;
};

/** input type for inserting object relation for remote table "hmd_expense_category" */
export type Hmd_Expense_Category_Obj_Rel_Insert_Input = {
  data: Hmd_Expense_Category_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Category_On_Conflict>;
};

/** on conflict condition type for table "hmd_expense_category" */
export type Hmd_Expense_Category_On_Conflict = {
  constraint: Hmd_Expense_Category_Constraint;
  update_columns: Array<Hmd_Expense_Category_Update_Column>;
  where?: Maybe<Hmd_Expense_Category_Bool_Exp>;
};

/** ordering options when selecting data from "hmd_expense_category" */
export type Hmd_Expense_Category_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  hmd_expense_multipliers_aggregate?: Maybe<Hmd_Expense_Multiplier_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hmd_expense_category" */
export type Hmd_Expense_Category_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "hmd_expense_category" */
export enum Hmd_Expense_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hmd_expense_category" */
export type Hmd_Expense_Category_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Hmd_Expense_Category_Stddev_Fields = {
  __typename?: 'hmd_expense_category_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hmd_Expense_Category_Stddev_Pop_Fields = {
  __typename?: 'hmd_expense_category_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hmd_Expense_Category_Stddev_Samp_Fields = {
  __typename?: 'hmd_expense_category_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hmd_Expense_Category_Sum_Fields = {
  __typename?: 'hmd_expense_category_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "hmd_expense_category" */
export enum Hmd_Expense_Category_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Hmd_Expense_Category_Var_Pop_Fields = {
  __typename?: 'hmd_expense_category_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hmd_Expense_Category_Var_Samp_Fields = {
  __typename?: 'hmd_expense_category_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hmd_Expense_Category_Variance_Fields = {
  __typename?: 'hmd_expense_category_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hmd_expense_category" */
export type Hmd_Expense_Category_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier = {
  __typename?: 'hmd_expense_multiplier';
  category_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  hmd_expense_category: Hmd_Expense_Category;
  /** An object relationship */
  hmd_lifestyle: Hmd_Lifestyle;
  id: Scalars['bigint'];
  lifestyle_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  region: Region;
  region_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['numeric'];
};

/** aggregated selection of "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Aggregate = {
  __typename?: 'hmd_expense_multiplier_aggregate';
  aggregate?: Maybe<Hmd_Expense_Multiplier_Aggregate_Fields>;
  nodes: Array<Hmd_Expense_Multiplier>;
};

/** aggregate fields of "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Aggregate_Fields = {
  __typename?: 'hmd_expense_multiplier_aggregate_fields';
  avg?: Maybe<Hmd_Expense_Multiplier_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hmd_Expense_Multiplier_Max_Fields>;
  min?: Maybe<Hmd_Expense_Multiplier_Min_Fields>;
  stddev?: Maybe<Hmd_Expense_Multiplier_Stddev_Fields>;
  stddev_pop?: Maybe<Hmd_Expense_Multiplier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hmd_Expense_Multiplier_Stddev_Samp_Fields>;
  sum?: Maybe<Hmd_Expense_Multiplier_Sum_Fields>;
  var_pop?: Maybe<Hmd_Expense_Multiplier_Var_Pop_Fields>;
  var_samp?: Maybe<Hmd_Expense_Multiplier_Var_Samp_Fields>;
  variance?: Maybe<Hmd_Expense_Multiplier_Variance_Fields>;
};


/** aggregate fields of "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Aggregate_Order_By = {
  avg?: Maybe<Hmd_Expense_Multiplier_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hmd_Expense_Multiplier_Max_Order_By>;
  min?: Maybe<Hmd_Expense_Multiplier_Min_Order_By>;
  stddev?: Maybe<Hmd_Expense_Multiplier_Stddev_Order_By>;
  stddev_pop?: Maybe<Hmd_Expense_Multiplier_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hmd_Expense_Multiplier_Stddev_Samp_Order_By>;
  sum?: Maybe<Hmd_Expense_Multiplier_Sum_Order_By>;
  var_pop?: Maybe<Hmd_Expense_Multiplier_Var_Pop_Order_By>;
  var_samp?: Maybe<Hmd_Expense_Multiplier_Var_Samp_Order_By>;
  variance?: Maybe<Hmd_Expense_Multiplier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Arr_Rel_Insert_Input = {
  data: Array<Hmd_Expense_Multiplier_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Multiplier_On_Conflict>;
};

/** aggregate avg on columns */
export type Hmd_Expense_Multiplier_Avg_Fields = {
  __typename?: 'hmd_expense_multiplier_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Avg_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hmd_expense_multiplier". All fields are combined with a logical 'AND'. */
export type Hmd_Expense_Multiplier_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hmd_Expense_Multiplier_Bool_Exp>>>;
  _not?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hmd_Expense_Multiplier_Bool_Exp>>>;
  category_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  hmd_expense_category?: Maybe<Hmd_Expense_Category_Bool_Exp>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  lifestyle_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "hmd_expense_multiplier" */
export enum Hmd_Expense_Multiplier_Constraint {
  /** unique or primary key constraint */
  HmdExpenseMultiplierPkey = 'hmd_expense_multiplier_pkey'
}

/** input type for incrementing integer column in table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Inc_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Insert_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  hmd_expense_category?: Maybe<Hmd_Expense_Category_Obj_Rel_Insert_Input>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Hmd_Expense_Multiplier_Max_Fields = {
  __typename?: 'hmd_expense_multiplier_max_fields';
  category_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hmd_Expense_Multiplier_Min_Fields = {
  __typename?: 'hmd_expense_multiplier_min_fields';
  category_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Mutation_Response = {
  __typename?: 'hmd_expense_multiplier_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hmd_Expense_Multiplier>;
};

/** input type for inserting object relation for remote table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Obj_Rel_Insert_Input = {
  data: Hmd_Expense_Multiplier_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Multiplier_On_Conflict>;
};

/** on conflict condition type for table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_On_Conflict = {
  constraint: Hmd_Expense_Multiplier_Constraint;
  update_columns: Array<Hmd_Expense_Multiplier_Update_Column>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};

/** ordering options when selecting data from "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Order_By = {
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  hmd_expense_category?: Maybe<Hmd_Expense_Category_Order_By>;
  hmd_lifestyle?: Maybe<Hmd_Lifestyle_Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "hmd_expense_multiplier" */
export enum Hmd_Expense_Multiplier_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LifestyleId = 'lifestyle_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Set_Input = {
  category_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Hmd_Expense_Multiplier_Stddev_Fields = {
  __typename?: 'hmd_expense_multiplier_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Stddev_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hmd_Expense_Multiplier_Stddev_Pop_Fields = {
  __typename?: 'hmd_expense_multiplier_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Stddev_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hmd_Expense_Multiplier_Stddev_Samp_Fields = {
  __typename?: 'hmd_expense_multiplier_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Stddev_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hmd_Expense_Multiplier_Sum_Fields = {
  __typename?: 'hmd_expense_multiplier_sum_fields';
  category_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  lifestyle_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Sum_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** update columns of table "hmd_expense_multiplier" */
export enum Hmd_Expense_Multiplier_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LifestyleId = 'lifestyle_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Hmd_Expense_Multiplier_Var_Pop_Fields = {
  __typename?: 'hmd_expense_multiplier_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Var_Pop_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hmd_Expense_Multiplier_Var_Samp_Fields = {
  __typename?: 'hmd_expense_multiplier_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Var_Samp_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hmd_Expense_Multiplier_Variance_Fields = {
  __typename?: 'hmd_expense_multiplier_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lifestyle_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hmd_expense_multiplier" */
export type Hmd_Expense_Multiplier_Variance_Order_By = {
  category_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lifestyle_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** columns and relationships of "hmd_lifestyle" */
export type Hmd_Lifestyle = {
  __typename?: 'hmd_lifestyle';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An array relationship */
  hmd_expense_bases: Array<Hmd_Expense_Base>;
  /** An aggregated array relationship */
  hmd_expense_bases_aggregate: Hmd_Expense_Base_Aggregate;
  /** An array relationship */
  hmd_expense_multipliers: Array<Hmd_Expense_Multiplier>;
  /** An aggregated array relationship */
  hmd_expense_multipliers_aggregate: Hmd_Expense_Multiplier_Aggregate;
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "hmd_lifestyle" */
export type Hmd_LifestyleHmd_Expense_BasesArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** columns and relationships of "hmd_lifestyle" */
export type Hmd_LifestyleHmd_Expense_Bases_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** columns and relationships of "hmd_lifestyle" */
export type Hmd_LifestyleHmd_Expense_MultipliersArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** columns and relationships of "hmd_lifestyle" */
export type Hmd_LifestyleHmd_Expense_Multipliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};

/** aggregated selection of "hmd_lifestyle" */
export type Hmd_Lifestyle_Aggregate = {
  __typename?: 'hmd_lifestyle_aggregate';
  aggregate?: Maybe<Hmd_Lifestyle_Aggregate_Fields>;
  nodes: Array<Hmd_Lifestyle>;
};

/** aggregate fields of "hmd_lifestyle" */
export type Hmd_Lifestyle_Aggregate_Fields = {
  __typename?: 'hmd_lifestyle_aggregate_fields';
  avg?: Maybe<Hmd_Lifestyle_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hmd_Lifestyle_Max_Fields>;
  min?: Maybe<Hmd_Lifestyle_Min_Fields>;
  stddev?: Maybe<Hmd_Lifestyle_Stddev_Fields>;
  stddev_pop?: Maybe<Hmd_Lifestyle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hmd_Lifestyle_Stddev_Samp_Fields>;
  sum?: Maybe<Hmd_Lifestyle_Sum_Fields>;
  var_pop?: Maybe<Hmd_Lifestyle_Var_Pop_Fields>;
  var_samp?: Maybe<Hmd_Lifestyle_Var_Samp_Fields>;
  variance?: Maybe<Hmd_Lifestyle_Variance_Fields>;
};


/** aggregate fields of "hmd_lifestyle" */
export type Hmd_Lifestyle_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hmd_Lifestyle_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Aggregate_Order_By = {
  avg?: Maybe<Hmd_Lifestyle_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hmd_Lifestyle_Max_Order_By>;
  min?: Maybe<Hmd_Lifestyle_Min_Order_By>;
  stddev?: Maybe<Hmd_Lifestyle_Stddev_Order_By>;
  stddev_pop?: Maybe<Hmd_Lifestyle_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hmd_Lifestyle_Stddev_Samp_Order_By>;
  sum?: Maybe<Hmd_Lifestyle_Sum_Order_By>;
  var_pop?: Maybe<Hmd_Lifestyle_Var_Pop_Order_By>;
  var_samp?: Maybe<Hmd_Lifestyle_Var_Samp_Order_By>;
  variance?: Maybe<Hmd_Lifestyle_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hmd_lifestyle" */
export type Hmd_Lifestyle_Arr_Rel_Insert_Input = {
  data: Array<Hmd_Lifestyle_Insert_Input>;
  on_conflict?: Maybe<Hmd_Lifestyle_On_Conflict>;
};

/** aggregate avg on columns */
export type Hmd_Lifestyle_Avg_Fields = {
  __typename?: 'hmd_lifestyle_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hmd_lifestyle". All fields are combined with a logical 'AND'. */
export type Hmd_Lifestyle_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hmd_Lifestyle_Bool_Exp>>>;
  _not?: Maybe<Hmd_Lifestyle_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hmd_Lifestyle_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  hmd_expense_bases?: Maybe<Hmd_Expense_Base_Bool_Exp>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hmd_lifestyle" */
export enum Hmd_Lifestyle_Constraint {
  /** unique or primary key constraint */
  HmdLifestylePkey = 'hmd_lifestyle_pkey'
}

/** input type for incrementing integer column in table "hmd_lifestyle" */
export type Hmd_Lifestyle_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "hmd_lifestyle" */
export type Hmd_Lifestyle_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  hmd_expense_bases?: Maybe<Hmd_Expense_Base_Arr_Rel_Insert_Input>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hmd_Lifestyle_Max_Fields = {
  __typename?: 'hmd_lifestyle_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hmd_Lifestyle_Min_Fields = {
  __typename?: 'hmd_lifestyle_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hmd_lifestyle" */
export type Hmd_Lifestyle_Mutation_Response = {
  __typename?: 'hmd_lifestyle_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hmd_Lifestyle>;
};

/** input type for inserting object relation for remote table "hmd_lifestyle" */
export type Hmd_Lifestyle_Obj_Rel_Insert_Input = {
  data: Hmd_Lifestyle_Insert_Input;
  on_conflict?: Maybe<Hmd_Lifestyle_On_Conflict>;
};

/** on conflict condition type for table "hmd_lifestyle" */
export type Hmd_Lifestyle_On_Conflict = {
  constraint: Hmd_Lifestyle_Constraint;
  update_columns: Array<Hmd_Lifestyle_Update_Column>;
  where?: Maybe<Hmd_Lifestyle_Bool_Exp>;
};

/** ordering options when selecting data from "hmd_lifestyle" */
export type Hmd_Lifestyle_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  hmd_expense_bases_aggregate?: Maybe<Hmd_Expense_Base_Aggregate_Order_By>;
  hmd_expense_multipliers_aggregate?: Maybe<Hmd_Expense_Multiplier_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hmd_lifestyle" */
export type Hmd_Lifestyle_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "hmd_lifestyle" */
export enum Hmd_Lifestyle_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hmd_lifestyle" */
export type Hmd_Lifestyle_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Hmd_Lifestyle_Stddev_Fields = {
  __typename?: 'hmd_lifestyle_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hmd_Lifestyle_Stddev_Pop_Fields = {
  __typename?: 'hmd_lifestyle_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hmd_Lifestyle_Stddev_Samp_Fields = {
  __typename?: 'hmd_lifestyle_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hmd_Lifestyle_Sum_Fields = {
  __typename?: 'hmd_lifestyle_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "hmd_lifestyle" */
export enum Hmd_Lifestyle_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Hmd_Lifestyle_Var_Pop_Fields = {
  __typename?: 'hmd_lifestyle_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hmd_Lifestyle_Var_Samp_Fields = {
  __typename?: 'hmd_lifestyle_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hmd_Lifestyle_Variance_Fields = {
  __typename?: 'hmd_lifestyle_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hmd_lifestyle" */
export type Hmd_Lifestyle_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "identification_type" */
export type Identification_Type = {
  __typename?: 'identification_type';
  /** An array relationship */
  advisors: Array<Advisor>;
  /** An aggregated array relationship */
  advisors_aggregate: Advisor_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  expiry_date_required: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_identifications: Array<Member_Identification>;
  /** An aggregated array relationship */
  member_identifications_aggregate: Member_Identification_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "identification_type" */
export type Identification_TypeAdvisorsArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "identification_type" */
export type Identification_TypeAdvisors_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "identification_type" */
export type Identification_TypeMember_IdentificationsArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** columns and relationships of "identification_type" */
export type Identification_TypeMember_Identifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};

/** aggregated selection of "identification_type" */
export type Identification_Type_Aggregate = {
  __typename?: 'identification_type_aggregate';
  aggregate?: Maybe<Identification_Type_Aggregate_Fields>;
  nodes: Array<Identification_Type>;
};

/** aggregate fields of "identification_type" */
export type Identification_Type_Aggregate_Fields = {
  __typename?: 'identification_type_aggregate_fields';
  avg?: Maybe<Identification_Type_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Identification_Type_Max_Fields>;
  min?: Maybe<Identification_Type_Min_Fields>;
  stddev?: Maybe<Identification_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Identification_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Identification_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Identification_Type_Sum_Fields>;
  var_pop?: Maybe<Identification_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Identification_Type_Var_Samp_Fields>;
  variance?: Maybe<Identification_Type_Variance_Fields>;
};


/** aggregate fields of "identification_type" */
export type Identification_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Identification_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "identification_type" */
export type Identification_Type_Aggregate_Order_By = {
  avg?: Maybe<Identification_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Identification_Type_Max_Order_By>;
  min?: Maybe<Identification_Type_Min_Order_By>;
  stddev?: Maybe<Identification_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Identification_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Identification_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Identification_Type_Sum_Order_By>;
  var_pop?: Maybe<Identification_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Identification_Type_Var_Samp_Order_By>;
  variance?: Maybe<Identification_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "identification_type" */
export type Identification_Type_Arr_Rel_Insert_Input = {
  data: Array<Identification_Type_Insert_Input>;
  on_conflict?: Maybe<Identification_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Identification_Type_Avg_Fields = {
  __typename?: 'identification_type_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "identification_type" */
export type Identification_Type_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "identification_type". All fields are combined with a logical 'AND'. */
export type Identification_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Identification_Type_Bool_Exp>>>;
  _not?: Maybe<Identification_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Identification_Type_Bool_Exp>>>;
  advisors?: Maybe<Advisor_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  expiry_date_required?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_identifications?: Maybe<Member_Identification_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "identification_type" */
export enum Identification_Type_Constraint {
  /** unique or primary key constraint */
  IdentificationTypeNameKey = 'identification_type_name_key',
  /** unique or primary key constraint */
  IdentificationTypePkey = 'identification_type_pkey'
}

/** input type for incrementing integer column in table "identification_type" */
export type Identification_Type_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "identification_type" */
export type Identification_Type_Insert_Input = {
  advisors?: Maybe<Advisor_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  expiry_date_required?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_identifications?: Maybe<Member_Identification_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Identification_Type_Max_Fields = {
  __typename?: 'identification_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "identification_type" */
export type Identification_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Identification_Type_Min_Fields = {
  __typename?: 'identification_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "identification_type" */
export type Identification_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "identification_type" */
export type Identification_Type_Mutation_Response = {
  __typename?: 'identification_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Identification_Type>;
};

/** input type for inserting object relation for remote table "identification_type" */
export type Identification_Type_Obj_Rel_Insert_Input = {
  data: Identification_Type_Insert_Input;
  on_conflict?: Maybe<Identification_Type_On_Conflict>;
};

/** on conflict condition type for table "identification_type" */
export type Identification_Type_On_Conflict = {
  constraint: Identification_Type_Constraint;
  update_columns: Array<Identification_Type_Update_Column>;
  where?: Maybe<Identification_Type_Bool_Exp>;
};

/** ordering options when selecting data from "identification_type" */
export type Identification_Type_Order_By = {
  advisors_aggregate?: Maybe<Advisor_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  expiry_date_required?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_identifications_aggregate?: Maybe<Member_Identification_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "identification_type" */
export type Identification_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "identification_type" */
export enum Identification_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  ExpiryDateRequired = 'expiry_date_required',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "identification_type" */
export type Identification_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  expiry_date_required?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Identification_Type_Stddev_Fields = {
  __typename?: 'identification_type_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "identification_type" */
export type Identification_Type_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Identification_Type_Stddev_Pop_Fields = {
  __typename?: 'identification_type_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "identification_type" */
export type Identification_Type_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Identification_Type_Stddev_Samp_Fields = {
  __typename?: 'identification_type_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "identification_type" */
export type Identification_Type_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Identification_Type_Sum_Fields = {
  __typename?: 'identification_type_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "identification_type" */
export type Identification_Type_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "identification_type" */
export enum Identification_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  ExpiryDateRequired = 'expiry_date_required',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Identification_Type_Var_Pop_Fields = {
  __typename?: 'identification_type_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "identification_type" */
export type Identification_Type_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Identification_Type_Var_Samp_Fields = {
  __typename?: 'identification_type_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "identification_type" */
export type Identification_Type_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Identification_Type_Variance_Fields = {
  __typename?: 'identification_type_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "identification_type" */
export type Identification_Type_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "income_source" */
export type Income_Source = {
  __typename?: 'income_source';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_income_sources: Array<Member_Income_Source>;
  /** An aggregated array relationship */
  member_income_sources_aggregate: Member_Income_Source_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "income_source" */
export type Income_SourceMember_Income_SourcesArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** columns and relationships of "income_source" */
export type Income_SourceMember_Income_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};

/** aggregated selection of "income_source" */
export type Income_Source_Aggregate = {
  __typename?: 'income_source_aggregate';
  aggregate?: Maybe<Income_Source_Aggregate_Fields>;
  nodes: Array<Income_Source>;
};

/** aggregate fields of "income_source" */
export type Income_Source_Aggregate_Fields = {
  __typename?: 'income_source_aggregate_fields';
  avg?: Maybe<Income_Source_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Income_Source_Max_Fields>;
  min?: Maybe<Income_Source_Min_Fields>;
  stddev?: Maybe<Income_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Income_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Income_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Income_Source_Sum_Fields>;
  var_pop?: Maybe<Income_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Income_Source_Var_Samp_Fields>;
  variance?: Maybe<Income_Source_Variance_Fields>;
};


/** aggregate fields of "income_source" */
export type Income_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Income_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "income_source" */
export type Income_Source_Aggregate_Order_By = {
  avg?: Maybe<Income_Source_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Income_Source_Max_Order_By>;
  min?: Maybe<Income_Source_Min_Order_By>;
  stddev?: Maybe<Income_Source_Stddev_Order_By>;
  stddev_pop?: Maybe<Income_Source_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Income_Source_Stddev_Samp_Order_By>;
  sum?: Maybe<Income_Source_Sum_Order_By>;
  var_pop?: Maybe<Income_Source_Var_Pop_Order_By>;
  var_samp?: Maybe<Income_Source_Var_Samp_Order_By>;
  variance?: Maybe<Income_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "income_source" */
export type Income_Source_Arr_Rel_Insert_Input = {
  data: Array<Income_Source_Insert_Input>;
  on_conflict?: Maybe<Income_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Income_Source_Avg_Fields = {
  __typename?: 'income_source_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "income_source" */
export type Income_Source_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "income_source". All fields are combined with a logical 'AND'. */
export type Income_Source_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Income_Source_Bool_Exp>>>;
  _not?: Maybe<Income_Source_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Income_Source_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_income_sources?: Maybe<Member_Income_Source_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "income_source" */
export enum Income_Source_Constraint {
  /** unique or primary key constraint */
  IncomeSourceNameKey = 'income_source_name_key',
  /** unique or primary key constraint */
  IncomeSourcePkey = 'income_source_pkey'
}

/** input type for incrementing integer column in table "income_source" */
export type Income_Source_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "income_source" */
export type Income_Source_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_income_sources?: Maybe<Member_Income_Source_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Income_Source_Max_Fields = {
  __typename?: 'income_source_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "income_source" */
export type Income_Source_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Income_Source_Min_Fields = {
  __typename?: 'income_source_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "income_source" */
export type Income_Source_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "income_source" */
export type Income_Source_Mutation_Response = {
  __typename?: 'income_source_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Income_Source>;
};

/** input type for inserting object relation for remote table "income_source" */
export type Income_Source_Obj_Rel_Insert_Input = {
  data: Income_Source_Insert_Input;
  on_conflict?: Maybe<Income_Source_On_Conflict>;
};

/** on conflict condition type for table "income_source" */
export type Income_Source_On_Conflict = {
  constraint: Income_Source_Constraint;
  update_columns: Array<Income_Source_Update_Column>;
  where?: Maybe<Income_Source_Bool_Exp>;
};

/** ordering options when selecting data from "income_source" */
export type Income_Source_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_income_sources_aggregate?: Maybe<Member_Income_Source_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "income_source" */
export type Income_Source_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "income_source" */
export enum Income_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "income_source" */
export type Income_Source_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Income_Source_Stddev_Fields = {
  __typename?: 'income_source_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "income_source" */
export type Income_Source_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Income_Source_Stddev_Pop_Fields = {
  __typename?: 'income_source_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "income_source" */
export type Income_Source_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Income_Source_Stddev_Samp_Fields = {
  __typename?: 'income_source_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "income_source" */
export type Income_Source_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Income_Source_Sum_Fields = {
  __typename?: 'income_source_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "income_source" */
export type Income_Source_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "income_source" */
export enum Income_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Income_Source_Var_Pop_Fields = {
  __typename?: 'income_source_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "income_source" */
export type Income_Source_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Income_Source_Var_Samp_Fields = {
  __typename?: 'income_source_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "income_source" */
export type Income_Source_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Income_Source_Variance_Fields = {
  __typename?: 'income_source_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "income_source" */
export type Income_Source_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "industry" */
export type Industry = {
  __typename?: 'industry';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "industry" */
export type IndustryMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "industry" */
export type IndustryMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};

/** aggregated selection of "industry" */
export type Industry_Aggregate = {
  __typename?: 'industry_aggregate';
  aggregate?: Maybe<Industry_Aggregate_Fields>;
  nodes: Array<Industry>;
};

/** aggregate fields of "industry" */
export type Industry_Aggregate_Fields = {
  __typename?: 'industry_aggregate_fields';
  avg?: Maybe<Industry_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Industry_Max_Fields>;
  min?: Maybe<Industry_Min_Fields>;
  stddev?: Maybe<Industry_Stddev_Fields>;
  stddev_pop?: Maybe<Industry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Industry_Stddev_Samp_Fields>;
  sum?: Maybe<Industry_Sum_Fields>;
  var_pop?: Maybe<Industry_Var_Pop_Fields>;
  var_samp?: Maybe<Industry_Var_Samp_Fields>;
  variance?: Maybe<Industry_Variance_Fields>;
};


/** aggregate fields of "industry" */
export type Industry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Industry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "industry" */
export type Industry_Aggregate_Order_By = {
  avg?: Maybe<Industry_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Industry_Max_Order_By>;
  min?: Maybe<Industry_Min_Order_By>;
  stddev?: Maybe<Industry_Stddev_Order_By>;
  stddev_pop?: Maybe<Industry_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Industry_Stddev_Samp_Order_By>;
  sum?: Maybe<Industry_Sum_Order_By>;
  var_pop?: Maybe<Industry_Var_Pop_Order_By>;
  var_samp?: Maybe<Industry_Var_Samp_Order_By>;
  variance?: Maybe<Industry_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "industry" */
export type Industry_Arr_Rel_Insert_Input = {
  data: Array<Industry_Insert_Input>;
  on_conflict?: Maybe<Industry_On_Conflict>;
};

/** aggregate avg on columns */
export type Industry_Avg_Fields = {
  __typename?: 'industry_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "industry" */
export type Industry_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "industry". All fields are combined with a logical 'AND'. */
export type Industry_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Industry_Bool_Exp>>>;
  _not?: Maybe<Industry_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Industry_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "industry" */
export enum Industry_Constraint {
  /** unique or primary key constraint */
  IndustryNameKey = 'industry_name_key',
  /** unique or primary key constraint */
  IndustryPkey = 'industry_pkey'
}

/** input type for incrementing integer column in table "industry" */
export type Industry_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "industry" */
export type Industry_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Industry_Max_Fields = {
  __typename?: 'industry_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "industry" */
export type Industry_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Industry_Min_Fields = {
  __typename?: 'industry_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "industry" */
export type Industry_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "industry" */
export type Industry_Mutation_Response = {
  __typename?: 'industry_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Industry>;
};

/** input type for inserting object relation for remote table "industry" */
export type Industry_Obj_Rel_Insert_Input = {
  data: Industry_Insert_Input;
  on_conflict?: Maybe<Industry_On_Conflict>;
};

/** on conflict condition type for table "industry" */
export type Industry_On_Conflict = {
  constraint: Industry_Constraint;
  update_columns: Array<Industry_Update_Column>;
  where?: Maybe<Industry_Bool_Exp>;
};

/** ordering options when selecting data from "industry" */
export type Industry_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "industry" */
export type Industry_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "industry" */
export enum Industry_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "industry" */
export type Industry_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Industry_Stddev_Fields = {
  __typename?: 'industry_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "industry" */
export type Industry_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Industry_Stddev_Pop_Fields = {
  __typename?: 'industry_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "industry" */
export type Industry_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Industry_Stddev_Samp_Fields = {
  __typename?: 'industry_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "industry" */
export type Industry_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Industry_Sum_Fields = {
  __typename?: 'industry_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "industry" */
export type Industry_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "industry" */
export enum Industry_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Industry_Var_Pop_Fields = {
  __typename?: 'industry_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "industry" */
export type Industry_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Industry_Var_Samp_Fields = {
  __typename?: 'industry_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "industry" */
export type Industry_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Industry_Variance_Fields = {
  __typename?: 'industry_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "industry" */
export type Industry_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};


/** expression to compare columns of type inet. All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: Maybe<Scalars['inet']>;
  _gt?: Maybe<Scalars['inet']>;
  _gte?: Maybe<Scalars['inet']>;
  _in?: Maybe<Array<Scalars['inet']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['inet']>;
  _lte?: Maybe<Scalars['inet']>;
  _neq?: Maybe<Scalars['inet']>;
  _nin?: Maybe<Array<Scalars['inet']>>;
};

/** columns and relationships of "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range = {
  __typename?: 'insurance_rate_age_range';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  insurance_rate_durations: Array<Insurance_Rate_Duration>;
  /** An aggregated array relationship */
  insurance_rate_durations_aggregate: Insurance_Rate_Duration_Aggregate;
  /** An object relationship */
  insurance_type: Insurance_Type;
  insurance_type_id: Scalars['bigint'];
  lower_age_range: Scalars['Int'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
  upper_age_range: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};


/** columns and relationships of "insurance_rate_age_range" */
export type Insurance_Rate_Age_RangeInsurance_Rate_DurationsArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** columns and relationships of "insurance_rate_age_range" */
export type Insurance_Rate_Age_RangeInsurance_Rate_Durations_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};

/** aggregated selection of "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Aggregate = {
  __typename?: 'insurance_rate_age_range_aggregate';
  aggregate?: Maybe<Insurance_Rate_Age_Range_Aggregate_Fields>;
  nodes: Array<Insurance_Rate_Age_Range>;
};

/** aggregate fields of "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Aggregate_Fields = {
  __typename?: 'insurance_rate_age_range_aggregate_fields';
  avg?: Maybe<Insurance_Rate_Age_Range_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Insurance_Rate_Age_Range_Max_Fields>;
  min?: Maybe<Insurance_Rate_Age_Range_Min_Fields>;
  stddev?: Maybe<Insurance_Rate_Age_Range_Stddev_Fields>;
  stddev_pop?: Maybe<Insurance_Rate_Age_Range_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurance_Rate_Age_Range_Stddev_Samp_Fields>;
  sum?: Maybe<Insurance_Rate_Age_Range_Sum_Fields>;
  var_pop?: Maybe<Insurance_Rate_Age_Range_Var_Pop_Fields>;
  var_samp?: Maybe<Insurance_Rate_Age_Range_Var_Samp_Fields>;
  variance?: Maybe<Insurance_Rate_Age_Range_Variance_Fields>;
};


/** aggregate fields of "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Rate_Age_Range_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Rate_Age_Range_Max_Order_By>;
  min?: Maybe<Insurance_Rate_Age_Range_Min_Order_By>;
  stddev?: Maybe<Insurance_Rate_Age_Range_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Rate_Age_Range_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Rate_Age_Range_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Rate_Age_Range_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Rate_Age_Range_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Rate_Age_Range_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Rate_Age_Range_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Arr_Rel_Insert_Input = {
  data: Array<Insurance_Rate_Age_Range_Insert_Input>;
  on_conflict?: Maybe<Insurance_Rate_Age_Range_On_Conflict>;
};

/** aggregate avg on columns */
export type Insurance_Rate_Age_Range_Avg_Fields = {
  __typename?: 'insurance_rate_age_range_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_rate_age_range". All fields are combined with a logical 'AND'. */
export type Insurance_Rate_Age_Range_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Insurance_Rate_Age_Range_Bool_Exp>>>;
  _not?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Insurance_Rate_Age_Range_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  insurance_rate_durations?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
  insurance_type?: Maybe<Insurance_Type_Bool_Exp>;
  insurance_type_id?: Maybe<Bigint_Comparison_Exp>;
  lower_age_range?: Maybe<Int_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  upper_age_range?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "insurance_rate_age_range" */
export enum Insurance_Rate_Age_Range_Constraint {
  /** unique or primary key constraint */
  InsuranceRateAgeRangePkey = 'insurance_rate_age_range_pkey'
}

/** input type for incrementing integer column in table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  upper_age_range?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_durations?: Maybe<Insurance_Rate_Duration_Arr_Rel_Insert_Input>;
  insurance_type?: Maybe<Insurance_Type_Obj_Rel_Insert_Input>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_age_range?: Maybe<Scalars['Int']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Insurance_Rate_Age_Range_Max_Fields = {
  __typename?: 'insurance_rate_age_range_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_age_range?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Rate_Age_Range_Min_Fields = {
  __typename?: 'insurance_rate_age_range_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_age_range?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** response of any mutation on the table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Mutation_Response = {
  __typename?: 'insurance_rate_age_range_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Insurance_Rate_Age_Range>;
};

/** input type for inserting object relation for remote table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Obj_Rel_Insert_Input = {
  data: Insurance_Rate_Age_Range_Insert_Input;
  on_conflict?: Maybe<Insurance_Rate_Age_Range_On_Conflict>;
};

/** on conflict condition type for table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_On_Conflict = {
  constraint: Insurance_Rate_Age_Range_Constraint;
  update_columns: Array<Insurance_Rate_Age_Range_Update_Column>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};

/** ordering options when selecting data from "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_durations_aggregate?: Maybe<Insurance_Rate_Duration_Aggregate_Order_By>;
  insurance_type?: Maybe<Insurance_Type_Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "insurance_rate_age_range" */
export enum Insurance_Rate_Age_Range_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceTypeId = 'insurance_type_id',
  /** column name */
  LowerAgeRange = 'lower_age_range',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpperAgeRange = 'upper_age_range'
}

/** input type for updating data in table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upper_age_range?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Insurance_Rate_Age_Range_Stddev_Fields = {
  __typename?: 'insurance_rate_age_range_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Insurance_Rate_Age_Range_Stddev_Pop_Fields = {
  __typename?: 'insurance_rate_age_range_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Insurance_Rate_Age_Range_Stddev_Samp_Fields = {
  __typename?: 'insurance_rate_age_range_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Insurance_Rate_Age_Range_Sum_Fields = {
  __typename?: 'insurance_rate_age_range_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_type_id?: Maybe<Scalars['bigint']>;
  lower_age_range?: Maybe<Scalars['Int']>;
  modified_by?: Maybe<Scalars['bigint']>;
  upper_age_range?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** update columns of table "insurance_rate_age_range" */
export enum Insurance_Rate_Age_Range_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceTypeId = 'insurance_type_id',
  /** column name */
  LowerAgeRange = 'lower_age_range',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpperAgeRange = 'upper_age_range'
}

/** aggregate var_pop on columns */
export type Insurance_Rate_Age_Range_Var_Pop_Fields = {
  __typename?: 'insurance_rate_age_range_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Insurance_Rate_Age_Range_Var_Samp_Fields = {
  __typename?: 'insurance_rate_age_range_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Insurance_Rate_Age_Range_Variance_Fields = {
  __typename?: 'insurance_rate_age_range_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_type_id?: Maybe<Scalars['Float']>;
  lower_age_range?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  upper_age_range?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insurance_rate_age_range" */
export type Insurance_Rate_Age_Range_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_type_id?: Maybe<Order_By>;
  lower_age_range?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  upper_age_range?: Maybe<Order_By>;
};

/** columns and relationships of "insurance_rate_duration" */
export type Insurance_Rate_Duration = {
  __typename?: 'insurance_rate_duration';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  duration: Scalars['Int'];
  id: Scalars['bigint'];
  /** An object relationship */
  insurance_rate_age_range: Insurance_Rate_Age_Range;
  insurance_rate_age_range_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  rate: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "insurance_rate_duration" */
export type Insurance_Rate_Duration_Aggregate = {
  __typename?: 'insurance_rate_duration_aggregate';
  aggregate?: Maybe<Insurance_Rate_Duration_Aggregate_Fields>;
  nodes: Array<Insurance_Rate_Duration>;
};

/** aggregate fields of "insurance_rate_duration" */
export type Insurance_Rate_Duration_Aggregate_Fields = {
  __typename?: 'insurance_rate_duration_aggregate_fields';
  avg?: Maybe<Insurance_Rate_Duration_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Insurance_Rate_Duration_Max_Fields>;
  min?: Maybe<Insurance_Rate_Duration_Min_Fields>;
  stddev?: Maybe<Insurance_Rate_Duration_Stddev_Fields>;
  stddev_pop?: Maybe<Insurance_Rate_Duration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurance_Rate_Duration_Stddev_Samp_Fields>;
  sum?: Maybe<Insurance_Rate_Duration_Sum_Fields>;
  var_pop?: Maybe<Insurance_Rate_Duration_Var_Pop_Fields>;
  var_samp?: Maybe<Insurance_Rate_Duration_Var_Samp_Fields>;
  variance?: Maybe<Insurance_Rate_Duration_Variance_Fields>;
};


/** aggregate fields of "insurance_rate_duration" */
export type Insurance_Rate_Duration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Rate_Duration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Rate_Duration_Max_Order_By>;
  min?: Maybe<Insurance_Rate_Duration_Min_Order_By>;
  stddev?: Maybe<Insurance_Rate_Duration_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Rate_Duration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Rate_Duration_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Rate_Duration_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Rate_Duration_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Rate_Duration_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Rate_Duration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Arr_Rel_Insert_Input = {
  data: Array<Insurance_Rate_Duration_Insert_Input>;
  on_conflict?: Maybe<Insurance_Rate_Duration_On_Conflict>;
};

/** aggregate avg on columns */
export type Insurance_Rate_Duration_Avg_Fields = {
  __typename?: 'insurance_rate_duration_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_rate_duration". All fields are combined with a logical 'AND'. */
export type Insurance_Rate_Duration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Insurance_Rate_Duration_Bool_Exp>>>;
  _not?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Insurance_Rate_Duration_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
  insurance_rate_age_range_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  rate?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "insurance_rate_duration" */
export enum Insurance_Rate_Duration_Constraint {
  /** unique or primary key constraint */
  InsuranceRateDurationPkey = 'insurance_rate_duration_pkey'
}

/** input type for incrementing integer column in table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Obj_Rel_Insert_Input>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Insurance_Rate_Duration_Max_Fields = {
  __typename?: 'insurance_rate_duration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Rate_Duration_Min_Fields = {
  __typename?: 'insurance_rate_duration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Mutation_Response = {
  __typename?: 'insurance_rate_duration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Insurance_Rate_Duration>;
};

/** input type for inserting object relation for remote table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Obj_Rel_Insert_Input = {
  data: Insurance_Rate_Duration_Insert_Input;
  on_conflict?: Maybe<Insurance_Rate_Duration_On_Conflict>;
};

/** on conflict condition type for table "insurance_rate_duration" */
export type Insurance_Rate_Duration_On_Conflict = {
  constraint: Insurance_Rate_Duration_Constraint;
  update_columns: Array<Insurance_Rate_Duration_Update_Column>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};

/** ordering options when selecting data from "insurance_rate_duration" */
export type Insurance_Rate_Duration_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "insurance_rate_duration" */
export type Insurance_Rate_Duration_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "insurance_rate_duration" */
export enum Insurance_Rate_Duration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceRateAgeRangeId = 'insurance_rate_age_range_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Insurance_Rate_Duration_Stddev_Fields = {
  __typename?: 'insurance_rate_duration_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Insurance_Rate_Duration_Stddev_Pop_Fields = {
  __typename?: 'insurance_rate_duration_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Insurance_Rate_Duration_Stddev_Samp_Fields = {
  __typename?: 'insurance_rate_duration_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Insurance_Rate_Duration_Sum_Fields = {
  __typename?: 'insurance_rate_duration_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_range_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  rate?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** update columns of table "insurance_rate_duration" */
export enum Insurance_Rate_Duration_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceRateAgeRangeId = 'insurance_rate_age_range_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Rate = 'rate',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Insurance_Rate_Duration_Var_Pop_Fields = {
  __typename?: 'insurance_rate_duration_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Insurance_Rate_Duration_Var_Samp_Fields = {
  __typename?: 'insurance_rate_duration_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Insurance_Rate_Duration_Variance_Fields = {
  __typename?: 'insurance_rate_duration_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  insurance_rate_age_range_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insurance_rate_duration" */
export type Insurance_Rate_Duration_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_range_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  rate?: Maybe<Order_By>;
};

/** columns and relationships of "insurance_type" */
export type Insurance_Type = {
  __typename?: 'insurance_type';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An array relationship */
  goal_insurances: Array<Goal_Insurance>;
  /** An aggregated array relationship */
  goal_insurances_aggregate: Goal_Insurance_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  insurance_rate_age_ranges: Array<Insurance_Rate_Age_Range>;
  /** An aggregated array relationship */
  insurance_rate_age_ranges_aggregate: Insurance_Rate_Age_Range_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "insurance_type" */
export type Insurance_TypeGoal_InsurancesArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** columns and relationships of "insurance_type" */
export type Insurance_TypeGoal_Insurances_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** columns and relationships of "insurance_type" */
export type Insurance_TypeInsurance_Rate_Age_RangesArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** columns and relationships of "insurance_type" */
export type Insurance_TypeInsurance_Rate_Age_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};

/** aggregated selection of "insurance_type" */
export type Insurance_Type_Aggregate = {
  __typename?: 'insurance_type_aggregate';
  aggregate?: Maybe<Insurance_Type_Aggregate_Fields>;
  nodes: Array<Insurance_Type>;
};

/** aggregate fields of "insurance_type" */
export type Insurance_Type_Aggregate_Fields = {
  __typename?: 'insurance_type_aggregate_fields';
  avg?: Maybe<Insurance_Type_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Insurance_Type_Max_Fields>;
  min?: Maybe<Insurance_Type_Min_Fields>;
  stddev?: Maybe<Insurance_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Insurance_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurance_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Insurance_Type_Sum_Fields>;
  var_pop?: Maybe<Insurance_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Insurance_Type_Var_Samp_Fields>;
  variance?: Maybe<Insurance_Type_Variance_Fields>;
};


/** aggregate fields of "insurance_type" */
export type Insurance_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_type" */
export type Insurance_Type_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Type_Max_Order_By>;
  min?: Maybe<Insurance_Type_Min_Order_By>;
  stddev?: Maybe<Insurance_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Type_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Type_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "insurance_type" */
export type Insurance_Type_Arr_Rel_Insert_Input = {
  data: Array<Insurance_Type_Insert_Input>;
  on_conflict?: Maybe<Insurance_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Insurance_Type_Avg_Fields = {
  __typename?: 'insurance_type_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insurance_type" */
export type Insurance_Type_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_type". All fields are combined with a logical 'AND'. */
export type Insurance_Type_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Insurance_Type_Bool_Exp>>>;
  _not?: Maybe<Insurance_Type_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Insurance_Type_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  goal_insurances?: Maybe<Goal_Insurance_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  insurance_rate_age_ranges?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_type" */
export enum Insurance_Type_Constraint {
  /** unique or primary key constraint */
  InsuranceTypeNameKey = 'insurance_type_name_key',
  /** unique or primary key constraint */
  InsuranceTypePkey = 'insurance_type_pkey'
}

/** input type for incrementing integer column in table "insurance_type" */
export type Insurance_Type_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "insurance_type" */
export type Insurance_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal_insurances?: Maybe<Goal_Insurance_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  insurance_rate_age_ranges?: Maybe<Insurance_Rate_Age_Range_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Insurance_Type_Max_Fields = {
  __typename?: 'insurance_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "insurance_type" */
export type Insurance_Type_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Type_Min_Fields = {
  __typename?: 'insurance_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "insurance_type" */
export type Insurance_Type_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "insurance_type" */
export type Insurance_Type_Mutation_Response = {
  __typename?: 'insurance_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Insurance_Type>;
};

/** input type for inserting object relation for remote table "insurance_type" */
export type Insurance_Type_Obj_Rel_Insert_Input = {
  data: Insurance_Type_Insert_Input;
  on_conflict?: Maybe<Insurance_Type_On_Conflict>;
};

/** on conflict condition type for table "insurance_type" */
export type Insurance_Type_On_Conflict = {
  constraint: Insurance_Type_Constraint;
  update_columns: Array<Insurance_Type_Update_Column>;
  where?: Maybe<Insurance_Type_Bool_Exp>;
};

/** ordering options when selecting data from "insurance_type" */
export type Insurance_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  goal_insurances_aggregate?: Maybe<Goal_Insurance_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  insurance_rate_age_ranges_aggregate?: Maybe<Insurance_Rate_Age_Range_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "insurance_type" */
export type Insurance_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "insurance_type" */
export enum Insurance_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "insurance_type" */
export type Insurance_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Insurance_Type_Stddev_Fields = {
  __typename?: 'insurance_type_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insurance_type" */
export type Insurance_Type_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Insurance_Type_Stddev_Pop_Fields = {
  __typename?: 'insurance_type_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insurance_type" */
export type Insurance_Type_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Insurance_Type_Stddev_Samp_Fields = {
  __typename?: 'insurance_type_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insurance_type" */
export type Insurance_Type_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Insurance_Type_Sum_Fields = {
  __typename?: 'insurance_type_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "insurance_type" */
export type Insurance_Type_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "insurance_type" */
export enum Insurance_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Insurance_Type_Var_Pop_Fields = {
  __typename?: 'insurance_type_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insurance_type" */
export type Insurance_Type_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Insurance_Type_Var_Samp_Fields = {
  __typename?: 'insurance_type_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insurance_type" */
export type Insurance_Type_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Insurance_Type_Variance_Fields = {
  __typename?: 'insurance_type_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insurance_type" */
export type Insurance_Type_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "marital_status" */
export type Marital_Status = {
  __typename?: 'marital_status';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_in_trust_for: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_for_aggregate: Member_In_Trust_For_Aggregate;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  /** An array relationship */
  members_jt: Array<Member>;
  /** An aggregated array relationship */
  members_jt_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMember_In_Trust_ForArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMember_In_Trust_For_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMembers_JtArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "marital_status" */
export type Marital_StatusMembers_Jt_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};

/** aggregated selection of "marital_status" */
export type Marital_Status_Aggregate = {
  __typename?: 'marital_status_aggregate';
  aggregate?: Maybe<Marital_Status_Aggregate_Fields>;
  nodes: Array<Marital_Status>;
};

/** aggregate fields of "marital_status" */
export type Marital_Status_Aggregate_Fields = {
  __typename?: 'marital_status_aggregate_fields';
  avg?: Maybe<Marital_Status_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Marital_Status_Max_Fields>;
  min?: Maybe<Marital_Status_Min_Fields>;
  stddev?: Maybe<Marital_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Marital_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Marital_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Marital_Status_Sum_Fields>;
  var_pop?: Maybe<Marital_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Marital_Status_Var_Samp_Fields>;
  variance?: Maybe<Marital_Status_Variance_Fields>;
};


/** aggregate fields of "marital_status" */
export type Marital_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Marital_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "marital_status" */
export type Marital_Status_Aggregate_Order_By = {
  avg?: Maybe<Marital_Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Marital_Status_Max_Order_By>;
  min?: Maybe<Marital_Status_Min_Order_By>;
  stddev?: Maybe<Marital_Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Marital_Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Marital_Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Marital_Status_Sum_Order_By>;
  var_pop?: Maybe<Marital_Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Marital_Status_Var_Samp_Order_By>;
  variance?: Maybe<Marital_Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "marital_status" */
export type Marital_Status_Arr_Rel_Insert_Input = {
  data: Array<Marital_Status_Insert_Input>;
  on_conflict?: Maybe<Marital_Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Marital_Status_Avg_Fields = {
  __typename?: 'marital_status_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "marital_status" */
export type Marital_Status_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "marital_status". All fields are combined with a logical 'AND'. */
export type Marital_Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Marital_Status_Bool_Exp>>>;
  _not?: Maybe<Marital_Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Marital_Status_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_in_trust_for?: Maybe<Member_In_Trust_For_Bool_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  members_jt?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "marital_status" */
export enum Marital_Status_Constraint {
  /** unique or primary key constraint */
  MaritalStatusNameKey = 'marital_status_name_key',
  /** unique or primary key constraint */
  MaritalStatusPkey = 'marital_status_pkey'
}

/** input type for incrementing integer column in table "marital_status" */
export type Marital_Status_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "marital_status" */
export type Marital_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_in_trust_for?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  members_jt?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Marital_Status_Max_Fields = {
  __typename?: 'marital_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "marital_status" */
export type Marital_Status_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Marital_Status_Min_Fields = {
  __typename?: 'marital_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "marital_status" */
export type Marital_Status_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "marital_status" */
export type Marital_Status_Mutation_Response = {
  __typename?: 'marital_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Marital_Status>;
};

/** input type for inserting object relation for remote table "marital_status" */
export type Marital_Status_Obj_Rel_Insert_Input = {
  data: Marital_Status_Insert_Input;
  on_conflict?: Maybe<Marital_Status_On_Conflict>;
};

/** on conflict condition type for table "marital_status" */
export type Marital_Status_On_Conflict = {
  constraint: Marital_Status_Constraint;
  update_columns: Array<Marital_Status_Update_Column>;
  where?: Maybe<Marital_Status_Bool_Exp>;
};

/** ordering options when selecting data from "marital_status" */
export type Marital_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_in_trust_for_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_jt_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "marital_status" */
export type Marital_Status_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "marital_status" */
export enum Marital_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "marital_status" */
export type Marital_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Marital_Status_Stddev_Fields = {
  __typename?: 'marital_status_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "marital_status" */
export type Marital_Status_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Marital_Status_Stddev_Pop_Fields = {
  __typename?: 'marital_status_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "marital_status" */
export type Marital_Status_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Marital_Status_Stddev_Samp_Fields = {
  __typename?: 'marital_status_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "marital_status" */
export type Marital_Status_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Marital_Status_Sum_Fields = {
  __typename?: 'marital_status_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "marital_status" */
export type Marital_Status_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "marital_status" */
export enum Marital_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Marital_Status_Var_Pop_Fields = {
  __typename?: 'marital_status_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "marital_status" */
export type Marital_Status_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Marital_Status_Var_Samp_Fields = {
  __typename?: 'marital_status_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "marital_status" */
export type Marital_Status_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Marital_Status_Variance_Fields = {
  __typename?: 'marital_status_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "marital_status" */
export type Marital_Status_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "member" */
export type Member = {
  __typename?: 'member';
  account_mandate?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  anticipated_investment_activity_top_ups: Scalars['String'];
  anticipated_investment_activity_top_ups_other?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals: Scalars['String'];
  anticipated_investment_activity_withdrawals_other?: Maybe<Scalars['String']>;
  approved_on?: Maybe<Scalars['timestamp']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  assigned_to_user?: Maybe<User>;
  /** An array relationship */
  beneficiaries: Array<Member_Beneficiary>;
  /** An aggregated array relationship */
  beneficiaries_aggregate: Member_Beneficiary_Aggregate;
  city?: Maybe<Scalars['String']>;
  /** An object relationship */
  client_investment_profile?: Maybe<Client_Investment_Profile>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  country_of_origin?: Maybe<Country>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  country_of_origin_jt?: Maybe<Country>;
  /** An object relationship */
  country_of_residence?: Maybe<Country>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  country_of_residence_jt?: Maybe<Country>;
  current_savings?: Maybe<Scalars['numeric']>;
  employer?: Maybe<Scalars['String']>;
  employer_address: Scalars['String'];
  employer_address_jt?: Maybe<Scalars['String']>;
  employer_city?: Maybe<Scalars['String']>;
  employer_city_jt?: Maybe<Scalars['String']>;
  employer_contact?: Maybe<Scalars['String']>;
  employer_contact_jt?: Maybe<Scalars['String']>;
  employer_email?: Maybe<Scalars['String']>;
  employer_email_jt?: Maybe<Scalars['String']>;
  employer_jt?: Maybe<Scalars['String']>;
  employment_status: Scalars['String'];
  employment_status_jt?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  gps_address_jt?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  home_address_jt?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  id_expiry_date_jt?: Maybe<Scalars['date']>;
  id_issue_date_jt?: Maybe<Scalars['date']>;
  id_number_jt?: Maybe<Scalars['String']>;
  id_type_jt?: Maybe<Scalars['String']>;
  /** An object relationship */
  industry?: Maybe<Industry>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount: Scalars['numeric'];
  is_customer: Scalars['Boolean'];
  is_retired: Scalars['Boolean'];
  /** An object relationship */
  marital_status?: Maybe<Marital_Status>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  marital_status_jt?: Maybe<Marital_Status>;
  /** An array relationship */
  member_contribution_sources: Array<Member_Contribution_Source>;
  /** An aggregated array relationship */
  member_contribution_sources_aggregate: Member_Contribution_Source_Aggregate;
  /** An array relationship */
  member_dependents: Array<Member_Dependent>;
  /** An aggregated array relationship */
  member_dependents_aggregate: Member_Dependent_Aggregate;
  /** An array relationship */
  member_goals: Array<Member_Goal>;
  /** An aggregated array relationship */
  member_goals_aggregate: Member_Goal_Aggregate;
  /** An array relationship */
  member_identifications: Array<Member_Identification>;
  /** An aggregated array relationship */
  member_identifications_aggregate: Member_Identification_Aggregate;
  /** An array relationship */
  member_in_trust_fors: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_fors_aggregate: Member_In_Trust_For_Aggregate;
  /** An array relationship */
  member_income_sources: Array<Member_Income_Source>;
  /** An aggregated array relationship */
  member_income_sources_aggregate: Member_Income_Source_Aggregate;
  /** An array relationship */
  member_mode_of_instruction_communications: Array<Member_Mode_Of_Instruction_Communication>;
  /** An aggregated array relationship */
  member_mode_of_instruction_communications_aggregate: Member_Mode_Of_Instruction_Communication_Aggregate;
  /** An array relationship */
  member_payment_instructions: Array<Member_Payment_Instruction>;
  /** An aggregated array relationship */
  member_payment_instructions_aggregate: Member_Payment_Instruction_Aggregate;
  /** An array relationship */
  member_redemptions: Array<Member_Redemption>;
  /** An aggregated array relationship */
  member_redemptions_aggregate: Member_Redemption_Aggregate;
  /** An array relationship */
  member_rejects: Array<Member_Reject>;
  /** An aggregated array relationship */
  member_rejects_aggregate: Member_Reject_Aggregate;
  mf_code?: Maybe<Scalars['String']>;
  mobile_secondary?: Maybe<Scalars['String']>;
  mobile_secondary_jt?: Maybe<Scalars['String']>;
  mode_of_statement_delivery?: Maybe<Scalars['String']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  nationality?: Maybe<Scalars['String']>;
  nature_of_business?: Maybe<Scalars['String']>;
  nature_of_business_jt?: Maybe<Scalars['String']>;
  /** An object relationship */
  next_of_kin?: Maybe<Next_Of_Kin>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  occupation_jt?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregated array relationship */
  payments_aggregate: Payment_Aggregate;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_expiry_date_jt?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  permit_issue_date_jt?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  place_of_birth_jt?: Maybe<Scalars['String']>;
  place_of_issue_jt?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  profession_jt?: Maybe<Scalars['String']>;
  professional_license_number?: Maybe<Scalars['String']>;
  professional_license_number_jt?: Maybe<Scalars['String']>;
  psl_code: Scalars['String'];
  /** An object relationship */
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount: Scalars['numeric'];
  regular_withdrawal_amount: Scalars['numeric'];
  relationship_jt?: Maybe<Scalars['String']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_permit_number_jt?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_place_of_issue_jt?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  residential_status_jt?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  statement_frequency?: Maybe<Scalars['String']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  status?: Maybe<Status>;
  status_id?: Maybe<Scalars['bigint']>;
  tin?: Maybe<Scalars['String']>;
  tin_jt?: Maybe<Scalars['String']>;
  /** An object relationship */
  title?: Maybe<Title>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  title_jt?: Maybe<Title>;
  /** An object relationship */
  user: User;
  welcome_pack_sent_at?: Maybe<Scalars['timestamp']>;
  welcome_pack_url?: Maybe<Scalars['String']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "member" */
export type MemberBeneficiariesArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberBeneficiaries_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Contribution_SourcesArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Contribution_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_DependentsArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Dependents_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_GoalsArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Goals_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_IdentificationsArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Identifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_In_Trust_ForsArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_In_Trust_Fors_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Income_SourcesArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Income_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Mode_Of_Instruction_CommunicationsArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Mode_Of_Instruction_Communications_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Payment_InstructionsArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Payment_Instructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_RedemptionsArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Redemptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_RejectsArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberMember_Rejects_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "member" */
export type MemberPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** aggregated selection of "member" */
export type Member_Aggregate = {
  __typename?: 'member_aggregate';
  aggregate?: Maybe<Member_Aggregate_Fields>;
  nodes: Array<Member>;
};

/** aggregate fields of "member" */
export type Member_Aggregate_Fields = {
  __typename?: 'member_aggregate_fields';
  avg?: Maybe<Member_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Max_Fields>;
  min?: Maybe<Member_Min_Fields>;
  stddev?: Maybe<Member_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Sum_Fields>;
  var_pop?: Maybe<Member_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Var_Samp_Fields>;
  variance?: Maybe<Member_Variance_Fields>;
};


/** aggregate fields of "member" */
export type Member_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member" */
export type Member_Aggregate_Order_By = {
  avg?: Maybe<Member_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Max_Order_By>;
  min?: Maybe<Member_Min_Order_By>;
  stddev?: Maybe<Member_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Sum_Order_By>;
  var_pop?: Maybe<Member_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Var_Samp_Order_By>;
  variance?: Maybe<Member_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member" */
export type Member_Arr_Rel_Insert_Input = {
  data: Array<Member_Insert_Input>;
  on_conflict?: Maybe<Member_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Avg_Fields = {
  __typename?: 'member_avg_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member" */
export type Member_Avg_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** columns and relationships of "member_beneficiary" */
export type Member_Beneficiary = {
  __typename?: 'member_beneficiary';
  allocation: Scalars['Int'];
  /** An object relationship */
  country_of_origin?: Maybe<Country>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth: Scalars['date'];
  deleted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  /** An object relationship */
  gender?: Maybe<Gender>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid: Scalars['uuid'];
  home_address: Scalars['String'];
  id: Scalars['bigint'];
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  marital_status?: Maybe<Scalars['String']>;
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  mobile: Scalars['String'];
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  /** An object relationship */
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  relationship: Relationship;
  relationship_id: Scalars['bigint'];
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_beneficiary" */
export type Member_Beneficiary_Aggregate = {
  __typename?: 'member_beneficiary_aggregate';
  aggregate?: Maybe<Member_Beneficiary_Aggregate_Fields>;
  nodes: Array<Member_Beneficiary>;
};

/** aggregate fields of "member_beneficiary" */
export type Member_Beneficiary_Aggregate_Fields = {
  __typename?: 'member_beneficiary_aggregate_fields';
  avg?: Maybe<Member_Beneficiary_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Beneficiary_Max_Fields>;
  min?: Maybe<Member_Beneficiary_Min_Fields>;
  stddev?: Maybe<Member_Beneficiary_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Beneficiary_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Beneficiary_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Beneficiary_Sum_Fields>;
  var_pop?: Maybe<Member_Beneficiary_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Beneficiary_Var_Samp_Fields>;
  variance?: Maybe<Member_Beneficiary_Variance_Fields>;
};


/** aggregate fields of "member_beneficiary" */
export type Member_Beneficiary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_beneficiary" */
export type Member_Beneficiary_Aggregate_Order_By = {
  avg?: Maybe<Member_Beneficiary_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Beneficiary_Max_Order_By>;
  min?: Maybe<Member_Beneficiary_Min_Order_By>;
  stddev?: Maybe<Member_Beneficiary_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Beneficiary_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Beneficiary_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Beneficiary_Sum_Order_By>;
  var_pop?: Maybe<Member_Beneficiary_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Beneficiary_Var_Samp_Order_By>;
  variance?: Maybe<Member_Beneficiary_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_beneficiary" */
export type Member_Beneficiary_Arr_Rel_Insert_Input = {
  data: Array<Member_Beneficiary_Insert_Input>;
  on_conflict?: Maybe<Member_Beneficiary_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Beneficiary_Avg_Fields = {
  __typename?: 'member_beneficiary_avg_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Avg_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_beneficiary". All fields are combined with a logical 'AND'. */
export type Member_Beneficiary_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Beneficiary_Bool_Exp>>>;
  _not?: Maybe<Member_Beneficiary_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Beneficiary_Bool_Exp>>>;
  allocation?: Maybe<Int_Comparison_Exp>;
  country_of_origin?: Maybe<Country_Bool_Exp>;
  country_of_origin_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<Gender_Bool_Exp>;
  gender_id?: Maybe<Bigint_Comparison_Exp>;
  gps_address?: Maybe<String_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  home_address?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  id_expiry_date?: Maybe<Date_Comparison_Exp>;
  id_issue_date?: Maybe<Date_Comparison_Exp>;
  id_number?: Maybe<String_Comparison_Exp>;
  id_type?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  marital_status?: Maybe<String_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  mobile2?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  occupation?: Maybe<String_Comparison_Exp>;
  other_names?: Maybe<String_Comparison_Exp>;
  permit_expiry_date?: Maybe<Date_Comparison_Exp>;
  permit_issue_date?: Maybe<Date_Comparison_Exp>;
  place_of_birth?: Maybe<String_Comparison_Exp>;
  profession?: Maybe<String_Comparison_Exp>;
  professional_license?: Maybe<String_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  relationship?: Maybe<Relationship_Bool_Exp>;
  relationship_id?: Maybe<Bigint_Comparison_Exp>;
  residential_permit_number?: Maybe<String_Comparison_Exp>;
  residential_place_of_issue?: Maybe<String_Comparison_Exp>;
  residential_status?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_beneficiary" */
export enum Member_Beneficiary_Constraint {
  /** unique or primary key constraint */
  BeneficiaryPkey = 'beneficiary_pkey',
  /** unique or primary key constraint */
  MemberBeneficiaryGuidKey = 'member_beneficiary_guid_key'
}

/** input type for incrementing integer column in table "member_beneficiary" */
export type Member_Beneficiary_Inc_Input = {
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_beneficiary" */
export type Member_Beneficiary_Insert_Input = {
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender_Obj_Rel_Insert_Input>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship?: Maybe<Relationship_Obj_Rel_Insert_Input>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Beneficiary_Max_Fields = {
  __typename?: 'member_beneficiary_max_fields';
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Max_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Beneficiary_Min_Fields = {
  __typename?: 'member_beneficiary_min_fields';
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Min_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_beneficiary" */
export type Member_Beneficiary_Mutation_Response = {
  __typename?: 'member_beneficiary_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Beneficiary>;
};

/** input type for inserting object relation for remote table "member_beneficiary" */
export type Member_Beneficiary_Obj_Rel_Insert_Input = {
  data: Member_Beneficiary_Insert_Input;
  on_conflict?: Maybe<Member_Beneficiary_On_Conflict>;
};

/** on conflict condition type for table "member_beneficiary" */
export type Member_Beneficiary_On_Conflict = {
  constraint: Member_Beneficiary_Constraint;
  update_columns: Array<Member_Beneficiary_Update_Column>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};

/** ordering options when selecting data from "member_beneficiary" */
export type Member_Beneficiary_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin?: Maybe<Country_Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Gender_Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  relationship?: Maybe<Relationship_Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_beneficiary" */
export type Member_Beneficiary_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_beneficiary" */
export enum Member_Beneficiary_Select_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNames = 'other_names',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_beneficiary" */
export type Member_Beneficiary_Set_Input = {
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Beneficiary_Stddev_Fields = {
  __typename?: 'member_beneficiary_stddev_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Stddev_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Beneficiary_Stddev_Pop_Fields = {
  __typename?: 'member_beneficiary_stddev_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Stddev_Pop_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Beneficiary_Stddev_Samp_Fields = {
  __typename?: 'member_beneficiary_stddev_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Stddev_Samp_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Beneficiary_Sum_Fields = {
  __typename?: 'member_beneficiary_sum_fields';
  allocation?: Maybe<Scalars['Int']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Sum_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** update columns of table "member_beneficiary" */
export enum Member_Beneficiary_Update_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNames = 'other_names',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Beneficiary_Var_Pop_Fields = {
  __typename?: 'member_beneficiary_var_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Var_Pop_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Beneficiary_Var_Samp_Fields = {
  __typename?: 'member_beneficiary_var_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Var_Samp_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Beneficiary_Variance_Fields = {
  __typename?: 'member_beneficiary_variance_fields';
  allocation?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_beneficiary" */
export type Member_Beneficiary_Variance_Order_By = {
  allocation?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member". All fields are combined with a logical 'AND'. */
export type Member_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Bool_Exp>>>;
  _not?: Maybe<Member_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Bool_Exp>>>;
  account_mandate?: Maybe<String_Comparison_Exp>;
  account_type?: Maybe<String_Comparison_Exp>;
  age?: Maybe<Int_Comparison_Exp>;
  anticipated_investment_activity_top_ups?: Maybe<String_Comparison_Exp>;
  anticipated_investment_activity_top_ups_other?: Maybe<String_Comparison_Exp>;
  anticipated_investment_activity_withdrawals?: Maybe<String_Comparison_Exp>;
  anticipated_investment_activity_withdrawals_other?: Maybe<String_Comparison_Exp>;
  approved_on?: Maybe<Timestamp_Comparison_Exp>;
  assigned_to?: Maybe<Bigint_Comparison_Exp>;
  assigned_to_user?: Maybe<User_Bool_Exp>;
  beneficiaries?: Maybe<Member_Beneficiary_Bool_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client_investment_profile?: Maybe<Client_Investment_Profile_Bool_Exp>;
  client_investment_profile_id?: Maybe<Bigint_Comparison_Exp>;
  country_of_origin?: Maybe<Country_Bool_Exp>;
  country_of_origin_id?: Maybe<Bigint_Comparison_Exp>;
  country_of_origin_id_jt?: Maybe<Bigint_Comparison_Exp>;
  country_of_origin_jt?: Maybe<Country_Bool_Exp>;
  country_of_residence?: Maybe<Country_Bool_Exp>;
  country_of_residence_id?: Maybe<Bigint_Comparison_Exp>;
  country_of_residence_id_jt?: Maybe<Bigint_Comparison_Exp>;
  country_of_residence_jt?: Maybe<Country_Bool_Exp>;
  current_savings?: Maybe<Numeric_Comparison_Exp>;
  employer?: Maybe<String_Comparison_Exp>;
  employer_address?: Maybe<String_Comparison_Exp>;
  employer_address_jt?: Maybe<String_Comparison_Exp>;
  employer_city?: Maybe<String_Comparison_Exp>;
  employer_city_jt?: Maybe<String_Comparison_Exp>;
  employer_contact?: Maybe<String_Comparison_Exp>;
  employer_contact_jt?: Maybe<String_Comparison_Exp>;
  employer_email?: Maybe<String_Comparison_Exp>;
  employer_email_jt?: Maybe<String_Comparison_Exp>;
  employer_jt?: Maybe<String_Comparison_Exp>;
  employment_status?: Maybe<String_Comparison_Exp>;
  employment_status_jt?: Maybe<String_Comparison_Exp>;
  gps_address?: Maybe<String_Comparison_Exp>;
  gps_address_jt?: Maybe<String_Comparison_Exp>;
  home_address?: Maybe<String_Comparison_Exp>;
  home_address_jt?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  id_expiry_date_jt?: Maybe<Date_Comparison_Exp>;
  id_issue_date_jt?: Maybe<Date_Comparison_Exp>;
  id_number_jt?: Maybe<String_Comparison_Exp>;
  id_type_jt?: Maybe<String_Comparison_Exp>;
  industry?: Maybe<Industry_Bool_Exp>;
  industry_id?: Maybe<Bigint_Comparison_Exp>;
  initial_investment_amount?: Maybe<Numeric_Comparison_Exp>;
  is_customer?: Maybe<Boolean_Comparison_Exp>;
  is_retired?: Maybe<Boolean_Comparison_Exp>;
  marital_status?: Maybe<Marital_Status_Bool_Exp>;
  marital_status_id?: Maybe<Bigint_Comparison_Exp>;
  marital_status_id_jt?: Maybe<Bigint_Comparison_Exp>;
  marital_status_jt?: Maybe<Marital_Status_Bool_Exp>;
  member_contribution_sources?: Maybe<Member_Contribution_Source_Bool_Exp>;
  member_dependents?: Maybe<Member_Dependent_Bool_Exp>;
  member_goals?: Maybe<Member_Goal_Bool_Exp>;
  member_identifications?: Maybe<Member_Identification_Bool_Exp>;
  member_in_trust_fors?: Maybe<Member_In_Trust_For_Bool_Exp>;
  member_income_sources?: Maybe<Member_Income_Source_Bool_Exp>;
  member_mode_of_instruction_communications?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Bool_Exp>;
  member_redemptions?: Maybe<Member_Redemption_Bool_Exp>;
  member_rejects?: Maybe<Member_Reject_Bool_Exp>;
  mf_code?: Maybe<String_Comparison_Exp>;
  mobile_secondary?: Maybe<String_Comparison_Exp>;
  mobile_secondary_jt?: Maybe<String_Comparison_Exp>;
  mode_of_statement_delivery?: Maybe<String_Comparison_Exp>;
  monthly_income?: Maybe<Numeric_Comparison_Exp>;
  nationality?: Maybe<String_Comparison_Exp>;
  nature_of_business?: Maybe<String_Comparison_Exp>;
  nature_of_business_jt?: Maybe<String_Comparison_Exp>;
  next_of_kin?: Maybe<Next_Of_Kin_Bool_Exp>;
  next_of_kin_id?: Maybe<Bigint_Comparison_Exp>;
  occupation?: Maybe<String_Comparison_Exp>;
  occupation_jt?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  permit_expiry_date?: Maybe<Date_Comparison_Exp>;
  permit_expiry_date_jt?: Maybe<Date_Comparison_Exp>;
  permit_issue_date?: Maybe<Date_Comparison_Exp>;
  permit_issue_date_jt?: Maybe<Date_Comparison_Exp>;
  place_of_birth?: Maybe<String_Comparison_Exp>;
  place_of_birth_jt?: Maybe<String_Comparison_Exp>;
  place_of_issue_jt?: Maybe<String_Comparison_Exp>;
  postal_address?: Maybe<String_Comparison_Exp>;
  profession?: Maybe<String_Comparison_Exp>;
  profession_jt?: Maybe<String_Comparison_Exp>;
  professional_license_number?: Maybe<String_Comparison_Exp>;
  professional_license_number_jt?: Maybe<String_Comparison_Exp>;
  psl_code?: Maybe<String_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  regular_top_up_amount?: Maybe<Numeric_Comparison_Exp>;
  regular_withdrawal_amount?: Maybe<Numeric_Comparison_Exp>;
  relationship_jt?: Maybe<String_Comparison_Exp>;
  residential_permit_number?: Maybe<String_Comparison_Exp>;
  residential_permit_number_jt?: Maybe<String_Comparison_Exp>;
  residential_place_of_issue?: Maybe<String_Comparison_Exp>;
  residential_place_of_issue_jt?: Maybe<String_Comparison_Exp>;
  residential_status?: Maybe<String_Comparison_Exp>;
  residential_status_jt?: Maybe<String_Comparison_Exp>;
  sequence?: Maybe<Int_Comparison_Exp>;
  signature?: Maybe<String_Comparison_Exp>;
  statement_frequency?: Maybe<String_Comparison_Exp>;
  statement_service_id?: Maybe<Bigint_Comparison_Exp>;
  status?: Maybe<Status_Bool_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  tin_jt?: Maybe<String_Comparison_Exp>;
  title?: Maybe<Title_Bool_Exp>;
  title_id?: Maybe<Bigint_Comparison_Exp>;
  title_id_jt?: Maybe<Bigint_Comparison_Exp>;
  title_jt?: Maybe<Title_Bool_Exp>;
  user?: Maybe<User_Bool_Exp>;
  welcome_pack_sent_at?: Maybe<Timestamp_Comparison_Exp>;
  welcome_pack_url?: Maybe<String_Comparison_Exp>;
  years_of_current_employment?: Maybe<Numeric_Comparison_Exp>;
  years_of_current_employment_jt?: Maybe<Numeric_Comparison_Exp>;
  years_of_employment?: Maybe<Numeric_Comparison_Exp>;
  years_of_employment_jt?: Maybe<Numeric_Comparison_Exp>;
  years_of_previous_employment?: Maybe<Numeric_Comparison_Exp>;
  years_of_previous_employment_jt?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "member" */
export enum Member_Constraint {
  /** unique or primary key constraint */
  MemberMfCodeKey = 'member_mf_code_key',
  /** unique or primary key constraint */
  MemberPkey = 'member_pkey',
  /** unique or primary key constraint */
  MemberPslCodeKey = 'member_psl_code_key'
}

/** columns and relationships of "member_contribution_source" */
export type Member_Contribution_Source = {
  __typename?: 'member_contribution_source';
  /** An object relationship */
  contribution_source: Contribution_Source;
  contribution_source_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_contribution_source" */
export type Member_Contribution_Source_Aggregate = {
  __typename?: 'member_contribution_source_aggregate';
  aggregate?: Maybe<Member_Contribution_Source_Aggregate_Fields>;
  nodes: Array<Member_Contribution_Source>;
};

/** aggregate fields of "member_contribution_source" */
export type Member_Contribution_Source_Aggregate_Fields = {
  __typename?: 'member_contribution_source_aggregate_fields';
  avg?: Maybe<Member_Contribution_Source_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Contribution_Source_Max_Fields>;
  min?: Maybe<Member_Contribution_Source_Min_Fields>;
  stddev?: Maybe<Member_Contribution_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Contribution_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Contribution_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Contribution_Source_Sum_Fields>;
  var_pop?: Maybe<Member_Contribution_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Contribution_Source_Var_Samp_Fields>;
  variance?: Maybe<Member_Contribution_Source_Variance_Fields>;
};


/** aggregate fields of "member_contribution_source" */
export type Member_Contribution_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_contribution_source" */
export type Member_Contribution_Source_Aggregate_Order_By = {
  avg?: Maybe<Member_Contribution_Source_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Contribution_Source_Max_Order_By>;
  min?: Maybe<Member_Contribution_Source_Min_Order_By>;
  stddev?: Maybe<Member_Contribution_Source_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Contribution_Source_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Contribution_Source_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Contribution_Source_Sum_Order_By>;
  var_pop?: Maybe<Member_Contribution_Source_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Contribution_Source_Var_Samp_Order_By>;
  variance?: Maybe<Member_Contribution_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_contribution_source" */
export type Member_Contribution_Source_Arr_Rel_Insert_Input = {
  data: Array<Member_Contribution_Source_Insert_Input>;
  on_conflict?: Maybe<Member_Contribution_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Contribution_Source_Avg_Fields = {
  __typename?: 'member_contribution_source_avg_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Avg_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_contribution_source". All fields are combined with a logical 'AND'. */
export type Member_Contribution_Source_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Contribution_Source_Bool_Exp>>>;
  _not?: Maybe<Member_Contribution_Source_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Contribution_Source_Bool_Exp>>>;
  contribution_source?: Maybe<Contribution_Source_Bool_Exp>;
  contribution_source_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_contribution_source" */
export enum Member_Contribution_Source_Constraint {
  /** unique or primary key constraint */
  MemberContributionSourceContributionSourceIdMemberIdKey = 'member_contribution_source_contribution_source_id_member_id_key',
  /** unique or primary key constraint */
  MemberContributionSourceGuidKey = 'member_contribution_source_guid_key',
  /** unique or primary key constraint */
  MemberContributionSourcePkey = 'member_contribution_source_pkey'
}

/** input type for incrementing integer column in table "member_contribution_source" */
export type Member_Contribution_Source_Inc_Input = {
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_contribution_source" */
export type Member_Contribution_Source_Insert_Input = {
  contribution_source?: Maybe<Contribution_Source_Obj_Rel_Insert_Input>;
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Contribution_Source_Max_Fields = {
  __typename?: 'member_contribution_source_max_fields';
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Max_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Contribution_Source_Min_Fields = {
  __typename?: 'member_contribution_source_min_fields';
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Min_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_contribution_source" */
export type Member_Contribution_Source_Mutation_Response = {
  __typename?: 'member_contribution_source_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Contribution_Source>;
};

/** input type for inserting object relation for remote table "member_contribution_source" */
export type Member_Contribution_Source_Obj_Rel_Insert_Input = {
  data: Member_Contribution_Source_Insert_Input;
  on_conflict?: Maybe<Member_Contribution_Source_On_Conflict>;
};

/** on conflict condition type for table "member_contribution_source" */
export type Member_Contribution_Source_On_Conflict = {
  constraint: Member_Contribution_Source_Constraint;
  update_columns: Array<Member_Contribution_Source_Update_Column>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};

/** ordering options when selecting data from "member_contribution_source" */
export type Member_Contribution_Source_Order_By = {
  contribution_source?: Maybe<Contribution_Source_Order_By>;
  contribution_source_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_contribution_source" */
export type Member_Contribution_Source_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_contribution_source" */
export enum Member_Contribution_Source_Select_Column {
  /** column name */
  ContributionSourceId = 'contribution_source_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_contribution_source" */
export type Member_Contribution_Source_Set_Input = {
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Contribution_Source_Stddev_Fields = {
  __typename?: 'member_contribution_source_stddev_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Stddev_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Contribution_Source_Stddev_Pop_Fields = {
  __typename?: 'member_contribution_source_stddev_pop_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Stddev_Pop_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Contribution_Source_Stddev_Samp_Fields = {
  __typename?: 'member_contribution_source_stddev_samp_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Stddev_Samp_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Contribution_Source_Sum_Fields = {
  __typename?: 'member_contribution_source_sum_fields';
  contribution_source_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Sum_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_contribution_source" */
export enum Member_Contribution_Source_Update_Column {
  /** column name */
  ContributionSourceId = 'contribution_source_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Contribution_Source_Var_Pop_Fields = {
  __typename?: 'member_contribution_source_var_pop_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Var_Pop_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Contribution_Source_Var_Samp_Fields = {
  __typename?: 'member_contribution_source_var_samp_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Var_Samp_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Contribution_Source_Variance_Fields = {
  __typename?: 'member_contribution_source_variance_fields';
  contribution_source_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_contribution_source" */
export type Member_Contribution_Source_Variance_Order_By = {
  contribution_source_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "member_dependent" */
export type Member_Dependent = {
  __typename?: 'member_dependent';
  age?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "member_dependent" */
export type Member_Dependent_Aggregate = {
  __typename?: 'member_dependent_aggregate';
  aggregate?: Maybe<Member_Dependent_Aggregate_Fields>;
  nodes: Array<Member_Dependent>;
};

/** aggregate fields of "member_dependent" */
export type Member_Dependent_Aggregate_Fields = {
  __typename?: 'member_dependent_aggregate_fields';
  avg?: Maybe<Member_Dependent_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Dependent_Max_Fields>;
  min?: Maybe<Member_Dependent_Min_Fields>;
  stddev?: Maybe<Member_Dependent_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Dependent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Dependent_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Dependent_Sum_Fields>;
  var_pop?: Maybe<Member_Dependent_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Dependent_Var_Samp_Fields>;
  variance?: Maybe<Member_Dependent_Variance_Fields>;
};


/** aggregate fields of "member_dependent" */
export type Member_Dependent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Dependent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_dependent" */
export type Member_Dependent_Aggregate_Order_By = {
  avg?: Maybe<Member_Dependent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Dependent_Max_Order_By>;
  min?: Maybe<Member_Dependent_Min_Order_By>;
  stddev?: Maybe<Member_Dependent_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Dependent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Dependent_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Dependent_Sum_Order_By>;
  var_pop?: Maybe<Member_Dependent_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Dependent_Var_Samp_Order_By>;
  variance?: Maybe<Member_Dependent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_dependent" */
export type Member_Dependent_Arr_Rel_Insert_Input = {
  data: Array<Member_Dependent_Insert_Input>;
  on_conflict?: Maybe<Member_Dependent_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Dependent_Avg_Fields = {
  __typename?: 'member_dependent_avg_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_dependent" */
export type Member_Dependent_Avg_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_dependent". All fields are combined with a logical 'AND'. */
export type Member_Dependent_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Dependent_Bool_Exp>>>;
  _not?: Maybe<Member_Dependent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Dependent_Bool_Exp>>>;
  age?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_dependent" */
export enum Member_Dependent_Constraint {
  /** unique or primary key constraint */
  MemberDepdendentPkey = 'member_depdendent_pkey',
  /** unique or primary key constraint */
  MemberDependentGuidKey = 'member_dependent_guid_key'
}

/** input type for incrementing integer column in table "member_dependent" */
export type Member_Dependent_Inc_Input = {
  age?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_dependent" */
export type Member_Dependent_Insert_Input = {
  age?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Dependent_Max_Fields = {
  __typename?: 'member_dependent_max_fields';
  age?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_dependent" */
export type Member_Dependent_Max_Order_By = {
  age?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Dependent_Min_Fields = {
  __typename?: 'member_dependent_min_fields';
  age?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_dependent" */
export type Member_Dependent_Min_Order_By = {
  age?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_dependent" */
export type Member_Dependent_Mutation_Response = {
  __typename?: 'member_dependent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Dependent>;
};

/** input type for inserting object relation for remote table "member_dependent" */
export type Member_Dependent_Obj_Rel_Insert_Input = {
  data: Member_Dependent_Insert_Input;
  on_conflict?: Maybe<Member_Dependent_On_Conflict>;
};

/** on conflict condition type for table "member_dependent" */
export type Member_Dependent_On_Conflict = {
  constraint: Member_Dependent_Constraint;
  update_columns: Array<Member_Dependent_Update_Column>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};

/** ordering options when selecting data from "member_dependent" */
export type Member_Dependent_Order_By = {
  age?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_dependent" */
export type Member_Dependent_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_dependent" */
export enum Member_Dependent_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_dependent" */
export type Member_Dependent_Set_Input = {
  age?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Dependent_Stddev_Fields = {
  __typename?: 'member_dependent_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_dependent" */
export type Member_Dependent_Stddev_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Dependent_Stddev_Pop_Fields = {
  __typename?: 'member_dependent_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_dependent" */
export type Member_Dependent_Stddev_Pop_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Dependent_Stddev_Samp_Fields = {
  __typename?: 'member_dependent_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_dependent" */
export type Member_Dependent_Stddev_Samp_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Dependent_Sum_Fields = {
  __typename?: 'member_dependent_sum_fields';
  age?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_dependent" */
export type Member_Dependent_Sum_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_dependent" */
export enum Member_Dependent_Update_Column {
  /** column name */
  Age = 'age',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Dependent_Var_Pop_Fields = {
  __typename?: 'member_dependent_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_dependent" */
export type Member_Dependent_Var_Pop_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Dependent_Var_Samp_Fields = {
  __typename?: 'member_dependent_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_dependent" */
export type Member_Dependent_Var_Samp_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Dependent_Variance_Fields = {
  __typename?: 'member_dependent_variance_fields';
  age?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_dependent" */
export type Member_Dependent_Variance_Order_By = {
  age?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "member_goal" */
export type Member_Goal = {
  __typename?: 'member_goal';
  accepted: Scalars['Boolean'];
  accepted_on?: Maybe<Scalars['date']>;
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  current_value: Scalars['numeric'];
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregated array relationship */
  deals_aggregate: Deal_Aggregate;
  deleted: Scalars['Boolean'];
  duration_months: Scalars['Int'];
  duration_years: Scalars['Int'];
  expected_return: Scalars['numeric'];
  future_value: Scalars['numeric'];
  /** An object relationship */
  goal: Goal;
  goal_for?: Maybe<Scalars['String']>;
  goal_id: Scalars['bigint'];
  /** An array relationship */
  goal_insurances: Array<Goal_Insurance>;
  /** An aggregated array relationship */
  goal_insurances_aggregate: Goal_Insurance_Aggregate;
  guid: Scalars['uuid'];
  has_insurance: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution: Scalars['numeric'];
  /** An array relationship */
  payment_shares: Array<Payment_Share>;
  /** An aggregated array relationship */
  payment_shares_aggregate: Payment_Share_Aggregate;
  /** An array relationship */
  redemption_shares: Array<Redemption_Share>;
  /** An aggregated array relationship */
  redemption_shares_aggregate: Redemption_Share_Aggregate;
  updated_at: Scalars['timestamptz'];
  years_to_fund: Scalars['numeric'];
};


/** columns and relationships of "member_goal" */
export type Member_GoalDealsArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalGoal_InsurancesArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalGoal_Insurances_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalPayment_SharesArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalPayment_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalRedemption_SharesArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** columns and relationships of "member_goal" */
export type Member_GoalRedemption_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};

/** aggregated selection of "member_goal" */
export type Member_Goal_Aggregate = {
  __typename?: 'member_goal_aggregate';
  aggregate?: Maybe<Member_Goal_Aggregate_Fields>;
  nodes: Array<Member_Goal>;
};

/** aggregate fields of "member_goal" */
export type Member_Goal_Aggregate_Fields = {
  __typename?: 'member_goal_aggregate_fields';
  avg?: Maybe<Member_Goal_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Goal_Max_Fields>;
  min?: Maybe<Member_Goal_Min_Fields>;
  stddev?: Maybe<Member_Goal_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Goal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Goal_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Goal_Sum_Fields>;
  var_pop?: Maybe<Member_Goal_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Goal_Var_Samp_Fields>;
  variance?: Maybe<Member_Goal_Variance_Fields>;
};


/** aggregate fields of "member_goal" */
export type Member_Goal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Goal_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_goal" */
export type Member_Goal_Aggregate_Order_By = {
  avg?: Maybe<Member_Goal_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Goal_Max_Order_By>;
  min?: Maybe<Member_Goal_Min_Order_By>;
  stddev?: Maybe<Member_Goal_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Goal_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Goal_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Goal_Sum_Order_By>;
  var_pop?: Maybe<Member_Goal_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Goal_Var_Samp_Order_By>;
  variance?: Maybe<Member_Goal_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_goal" */
export type Member_Goal_Arr_Rel_Insert_Input = {
  data: Array<Member_Goal_Insert_Input>;
  on_conflict?: Maybe<Member_Goal_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Goal_Avg_Fields = {
  __typename?: 'member_goal_avg_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_goal" */
export type Member_Goal_Avg_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_goal". All fields are combined with a logical 'AND'. */
export type Member_Goal_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Goal_Bool_Exp>>>;
  _not?: Maybe<Member_Goal_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Goal_Bool_Exp>>>;
  accepted?: Maybe<Boolean_Comparison_Exp>;
  accepted_on?: Maybe<Date_Comparison_Exp>;
  administrative_fees?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  current_value?: Maybe<Numeric_Comparison_Exp>;
  deals?: Maybe<Deal_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  duration_months?: Maybe<Int_Comparison_Exp>;
  duration_years?: Maybe<Int_Comparison_Exp>;
  expected_return?: Maybe<Numeric_Comparison_Exp>;
  future_value?: Maybe<Numeric_Comparison_Exp>;
  goal?: Maybe<Goal_Bool_Exp>;
  goal_for?: Maybe<String_Comparison_Exp>;
  goal_id?: Maybe<Bigint_Comparison_Exp>;
  goal_insurances?: Maybe<Goal_Insurance_Bool_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  has_insurance?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  monthly_contribution?: Maybe<Numeric_Comparison_Exp>;
  payment_shares?: Maybe<Payment_Share_Bool_Exp>;
  redemption_shares?: Maybe<Redemption_Share_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  years_to_fund?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_goal" */
export enum Member_Goal_Constraint {
  /** unique or primary key constraint */
  MemberGoalGuidKey = 'member_goal_guid_key',
  /** unique or primary key constraint */
  MemberGoalPkey = 'member_goal_pkey'
}

/** input type for incrementing integer column in table "member_goal" */
export type Member_Goal_Inc_Input = {
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "member_goal" */
export type Member_Goal_Insert_Input = {
  accepted?: Maybe<Scalars['Boolean']>;
  accepted_on?: Maybe<Scalars['date']>;
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  deals?: Maybe<Deal_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal?: Maybe<Goal_Obj_Rel_Insert_Input>;
  goal_for?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['bigint']>;
  goal_insurances?: Maybe<Goal_Insurance_Arr_Rel_Insert_Input>;
  guid?: Maybe<Scalars['uuid']>;
  has_insurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  payment_shares?: Maybe<Payment_Share_Arr_Rel_Insert_Input>;
  redemption_shares?: Maybe<Redemption_Share_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Member_Goal_Max_Fields = {
  __typename?: 'member_goal_max_fields';
  accepted_on?: Maybe<Scalars['date']>;
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal_for?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "member_goal" */
export type Member_Goal_Max_Order_By = {
  accepted_on?: Maybe<Order_By>;
  administrative_fees?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_for?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Goal_Min_Fields = {
  __typename?: 'member_goal_min_fields';
  accepted_on?: Maybe<Scalars['date']>;
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal_for?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "member_goal" */
export type Member_Goal_Min_Order_By = {
  accepted_on?: Maybe<Order_By>;
  administrative_fees?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_for?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_goal" */
export type Member_Goal_Mutation_Response = {
  __typename?: 'member_goal_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Goal>;
};

/** input type for inserting object relation for remote table "member_goal" */
export type Member_Goal_Obj_Rel_Insert_Input = {
  data: Member_Goal_Insert_Input;
  on_conflict?: Maybe<Member_Goal_On_Conflict>;
};

/** on conflict condition type for table "member_goal" */
export type Member_Goal_On_Conflict = {
  constraint: Member_Goal_Constraint;
  update_columns: Array<Member_Goal_Update_Column>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};

/** ordering options when selecting data from "member_goal" */
export type Member_Goal_Order_By = {
  accepted?: Maybe<Order_By>;
  accepted_on?: Maybe<Order_By>;
  administrative_fees?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  deals_aggregate?: Maybe<Deal_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal?: Maybe<Goal_Order_By>;
  goal_for?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  goal_insurances_aggregate?: Maybe<Goal_Insurance_Aggregate_Order_By>;
  guid?: Maybe<Order_By>;
  has_insurance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  payment_shares_aggregate?: Maybe<Payment_Share_Aggregate_Order_By>;
  redemption_shares_aggregate?: Maybe<Redemption_Share_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_goal" */
export type Member_Goal_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_goal" */
export enum Member_Goal_Select_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  AcceptedOn = 'accepted_on',
  /** column name */
  AdministrativeFees = 'administrative_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentValue = 'current_value',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DurationMonths = 'duration_months',
  /** column name */
  DurationYears = 'duration_years',
  /** column name */
  ExpectedReturn = 'expected_return',
  /** column name */
  FutureValue = 'future_value',
  /** column name */
  GoalFor = 'goal_for',
  /** column name */
  GoalId = 'goal_id',
  /** column name */
  Guid = 'guid',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  MonthlyContribution = 'monthly_contribution',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsToFund = 'years_to_fund'
}

/** input type for updating data in table "member_goal" */
export type Member_Goal_Set_Input = {
  accepted?: Maybe<Scalars['Boolean']>;
  accepted_on?: Maybe<Scalars['date']>;
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  deleted?: Maybe<Scalars['Boolean']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal_for?: Maybe<Scalars['String']>;
  goal_id?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  has_insurance?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Member_Goal_Stddev_Fields = {
  __typename?: 'member_goal_stddev_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_goal" */
export type Member_Goal_Stddev_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Goal_Stddev_Pop_Fields = {
  __typename?: 'member_goal_stddev_pop_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_goal" */
export type Member_Goal_Stddev_Pop_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Goal_Stddev_Samp_Fields = {
  __typename?: 'member_goal_stddev_samp_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_goal" */
export type Member_Goal_Stddev_Samp_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Goal_Sum_Fields = {
  __typename?: 'member_goal_sum_fields';
  administrative_fees?: Maybe<Scalars['numeric']>;
  created_by?: Maybe<Scalars['bigint']>;
  current_value?: Maybe<Scalars['numeric']>;
  duration_months?: Maybe<Scalars['Int']>;
  duration_years?: Maybe<Scalars['Int']>;
  expected_return?: Maybe<Scalars['numeric']>;
  future_value?: Maybe<Scalars['numeric']>;
  goal_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  monthly_contribution?: Maybe<Scalars['numeric']>;
  years_to_fund?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "member_goal" */
export type Member_Goal_Sum_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** update columns of table "member_goal" */
export enum Member_Goal_Update_Column {
  /** column name */
  Accepted = 'accepted',
  /** column name */
  AcceptedOn = 'accepted_on',
  /** column name */
  AdministrativeFees = 'administrative_fees',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrentValue = 'current_value',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DurationMonths = 'duration_months',
  /** column name */
  DurationYears = 'duration_years',
  /** column name */
  ExpectedReturn = 'expected_return',
  /** column name */
  FutureValue = 'future_value',
  /** column name */
  GoalFor = 'goal_for',
  /** column name */
  GoalId = 'goal_id',
  /** column name */
  Guid = 'guid',
  /** column name */
  HasInsurance = 'has_insurance',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  MonthlyContribution = 'monthly_contribution',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YearsToFund = 'years_to_fund'
}

/** aggregate var_pop on columns */
export type Member_Goal_Var_Pop_Fields = {
  __typename?: 'member_goal_var_pop_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_goal" */
export type Member_Goal_Var_Pop_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Goal_Var_Samp_Fields = {
  __typename?: 'member_goal_var_samp_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_goal" */
export type Member_Goal_Var_Samp_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Goal_Variance_Fields = {
  __typename?: 'member_goal_variance_fields';
  administrative_fees?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  current_value?: Maybe<Scalars['Float']>;
  duration_months?: Maybe<Scalars['Float']>;
  duration_years?: Maybe<Scalars['Float']>;
  expected_return?: Maybe<Scalars['Float']>;
  future_value?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  monthly_contribution?: Maybe<Scalars['Float']>;
  years_to_fund?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_goal" */
export type Member_Goal_Variance_Order_By = {
  administrative_fees?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  current_value?: Maybe<Order_By>;
  duration_months?: Maybe<Order_By>;
  duration_years?: Maybe<Order_By>;
  expected_return?: Maybe<Order_By>;
  future_value?: Maybe<Order_By>;
  goal_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  monthly_contribution?: Maybe<Order_By>;
  years_to_fund?: Maybe<Order_By>;
};

/** columns and relationships of "member_identification" */
export type Member_Identification = {
  __typename?: 'member_identification';
  /** An object relationship */
  country?: Maybe<Country>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  identification_card_image_url?: Maybe<Scalars['String']>;
  identification_expiry_date?: Maybe<Scalars['date']>;
  identification_issue_date?: Maybe<Scalars['date']>;
  identification_number: Scalars['String'];
  /** An object relationship */
  identification_type: Identification_Type;
  identification_type_id: Scalars['bigint'];
  issue_country_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  place_of_issue?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_identification" */
export type Member_Identification_Aggregate = {
  __typename?: 'member_identification_aggregate';
  aggregate?: Maybe<Member_Identification_Aggregate_Fields>;
  nodes: Array<Member_Identification>;
};

/** aggregate fields of "member_identification" */
export type Member_Identification_Aggregate_Fields = {
  __typename?: 'member_identification_aggregate_fields';
  avg?: Maybe<Member_Identification_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Identification_Max_Fields>;
  min?: Maybe<Member_Identification_Min_Fields>;
  stddev?: Maybe<Member_Identification_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Identification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Identification_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Identification_Sum_Fields>;
  var_pop?: Maybe<Member_Identification_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Identification_Var_Samp_Fields>;
  variance?: Maybe<Member_Identification_Variance_Fields>;
};


/** aggregate fields of "member_identification" */
export type Member_Identification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Identification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_identification" */
export type Member_Identification_Aggregate_Order_By = {
  avg?: Maybe<Member_Identification_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Identification_Max_Order_By>;
  min?: Maybe<Member_Identification_Min_Order_By>;
  stddev?: Maybe<Member_Identification_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Identification_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Identification_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Identification_Sum_Order_By>;
  var_pop?: Maybe<Member_Identification_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Identification_Var_Samp_Order_By>;
  variance?: Maybe<Member_Identification_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_identification" */
export type Member_Identification_Arr_Rel_Insert_Input = {
  data: Array<Member_Identification_Insert_Input>;
  on_conflict?: Maybe<Member_Identification_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Identification_Avg_Fields = {
  __typename?: 'member_identification_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_identification" */
export type Member_Identification_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_identification". All fields are combined with a logical 'AND'. */
export type Member_Identification_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Identification_Bool_Exp>>>;
  _not?: Maybe<Member_Identification_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Identification_Bool_Exp>>>;
  country?: Maybe<Country_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  identification_card_image_url?: Maybe<String_Comparison_Exp>;
  identification_expiry_date?: Maybe<Date_Comparison_Exp>;
  identification_issue_date?: Maybe<Date_Comparison_Exp>;
  identification_number?: Maybe<String_Comparison_Exp>;
  identification_type?: Maybe<Identification_Type_Bool_Exp>;
  identification_type_id?: Maybe<Bigint_Comparison_Exp>;
  issue_country_id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  place_of_issue?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_identification" */
export enum Member_Identification_Constraint {
  /** unique or primary key constraint */
  MemberIdentificationGuidKey = 'member_identification_guid_key',
  /** unique or primary key constraint */
  MemberIdentificationPkey = 'member_identification_pkey'
}

/** input type for incrementing integer column in table "member_identification" */
export type Member_Identification_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_identification" */
export type Member_Identification_Insert_Input = {
  country?: Maybe<Country_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  identification_card_image_url?: Maybe<Scalars['String']>;
  identification_expiry_date?: Maybe<Scalars['date']>;
  identification_issue_date?: Maybe<Scalars['date']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type?: Maybe<Identification_Type_Obj_Rel_Insert_Input>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  place_of_issue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Identification_Max_Fields = {
  __typename?: 'member_identification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  identification_card_image_url?: Maybe<Scalars['String']>;
  identification_expiry_date?: Maybe<Scalars['date']>;
  identification_issue_date?: Maybe<Scalars['date']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  place_of_issue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_identification" */
export type Member_Identification_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_card_image_url?: Maybe<Order_By>;
  identification_expiry_date?: Maybe<Order_By>;
  identification_issue_date?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  place_of_issue?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Identification_Min_Fields = {
  __typename?: 'member_identification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  identification_card_image_url?: Maybe<Scalars['String']>;
  identification_expiry_date?: Maybe<Scalars['date']>;
  identification_issue_date?: Maybe<Scalars['date']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  place_of_issue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_identification" */
export type Member_Identification_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_card_image_url?: Maybe<Order_By>;
  identification_expiry_date?: Maybe<Order_By>;
  identification_issue_date?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  place_of_issue?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_identification" */
export type Member_Identification_Mutation_Response = {
  __typename?: 'member_identification_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Identification>;
};

/** input type for inserting object relation for remote table "member_identification" */
export type Member_Identification_Obj_Rel_Insert_Input = {
  data: Member_Identification_Insert_Input;
  on_conflict?: Maybe<Member_Identification_On_Conflict>;
};

/** on conflict condition type for table "member_identification" */
export type Member_Identification_On_Conflict = {
  constraint: Member_Identification_Constraint;
  update_columns: Array<Member_Identification_Update_Column>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};

/** ordering options when selecting data from "member_identification" */
export type Member_Identification_Order_By = {
  country?: Maybe<Country_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_card_image_url?: Maybe<Order_By>;
  identification_expiry_date?: Maybe<Order_By>;
  identification_issue_date?: Maybe<Order_By>;
  identification_number?: Maybe<Order_By>;
  identification_type?: Maybe<Identification_Type_Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  place_of_issue?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_identification" */
export type Member_Identification_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_identification" */
export enum Member_Identification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationCardImageUrl = 'identification_card_image_url',
  /** column name */
  IdentificationExpiryDate = 'identification_expiry_date',
  /** column name */
  IdentificationIssueDate = 'identification_issue_date',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  IdentificationTypeId = 'identification_type_id',
  /** column name */
  IssueCountryId = 'issue_country_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PlaceOfIssue = 'place_of_issue',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_identification" */
export type Member_Identification_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  identification_card_image_url?: Maybe<Scalars['String']>;
  identification_expiry_date?: Maybe<Scalars['date']>;
  identification_issue_date?: Maybe<Scalars['date']>;
  identification_number?: Maybe<Scalars['String']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  place_of_issue?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Identification_Stddev_Fields = {
  __typename?: 'member_identification_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_identification" */
export type Member_Identification_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Identification_Stddev_Pop_Fields = {
  __typename?: 'member_identification_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_identification" */
export type Member_Identification_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Identification_Stddev_Samp_Fields = {
  __typename?: 'member_identification_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_identification" */
export type Member_Identification_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Identification_Sum_Fields = {
  __typename?: 'member_identification_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  identification_type_id?: Maybe<Scalars['bigint']>;
  issue_country_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_identification" */
export type Member_Identification_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_identification" */
export enum Member_Identification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationCardImageUrl = 'identification_card_image_url',
  /** column name */
  IdentificationExpiryDate = 'identification_expiry_date',
  /** column name */
  IdentificationIssueDate = 'identification_issue_date',
  /** column name */
  IdentificationNumber = 'identification_number',
  /** column name */
  IdentificationTypeId = 'identification_type_id',
  /** column name */
  IssueCountryId = 'issue_country_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PlaceOfIssue = 'place_of_issue',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Identification_Var_Pop_Fields = {
  __typename?: 'member_identification_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_identification" */
export type Member_Identification_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Identification_Var_Samp_Fields = {
  __typename?: 'member_identification_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_identification" */
export type Member_Identification_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Identification_Variance_Fields = {
  __typename?: 'member_identification_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  identification_type_id?: Maybe<Scalars['Float']>;
  issue_country_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_identification" */
export type Member_Identification_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_type_id?: Maybe<Order_By>;
  issue_country_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "member_in_trust_for" */
export type Member_In_Trust_For = {
  __typename?: 'member_in_trust_for';
  /** An object relationship */
  country_of_origin?: Maybe<Country>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  country_of_residence?: Maybe<Country>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamp'];
  created_by?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  marital_status?: Maybe<Marital_Status>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  /** An object relationship */
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  relationship: Relationship;
  relationship_id: Scalars['bigint'];
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "member_in_trust_for" */
export type Member_In_Trust_For_Aggregate = {
  __typename?: 'member_in_trust_for_aggregate';
  aggregate?: Maybe<Member_In_Trust_For_Aggregate_Fields>;
  nodes: Array<Member_In_Trust_For>;
};

/** aggregate fields of "member_in_trust_for" */
export type Member_In_Trust_For_Aggregate_Fields = {
  __typename?: 'member_in_trust_for_aggregate_fields';
  avg?: Maybe<Member_In_Trust_For_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_In_Trust_For_Max_Fields>;
  min?: Maybe<Member_In_Trust_For_Min_Fields>;
  stddev?: Maybe<Member_In_Trust_For_Stddev_Fields>;
  stddev_pop?: Maybe<Member_In_Trust_For_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_In_Trust_For_Stddev_Samp_Fields>;
  sum?: Maybe<Member_In_Trust_For_Sum_Fields>;
  var_pop?: Maybe<Member_In_Trust_For_Var_Pop_Fields>;
  var_samp?: Maybe<Member_In_Trust_For_Var_Samp_Fields>;
  variance?: Maybe<Member_In_Trust_For_Variance_Fields>;
};


/** aggregate fields of "member_in_trust_for" */
export type Member_In_Trust_For_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_in_trust_for" */
export type Member_In_Trust_For_Aggregate_Order_By = {
  avg?: Maybe<Member_In_Trust_For_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_In_Trust_For_Max_Order_By>;
  min?: Maybe<Member_In_Trust_For_Min_Order_By>;
  stddev?: Maybe<Member_In_Trust_For_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_In_Trust_For_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_In_Trust_For_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_In_Trust_For_Sum_Order_By>;
  var_pop?: Maybe<Member_In_Trust_For_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_In_Trust_For_Var_Samp_Order_By>;
  variance?: Maybe<Member_In_Trust_For_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_in_trust_for" */
export type Member_In_Trust_For_Arr_Rel_Insert_Input = {
  data: Array<Member_In_Trust_For_Insert_Input>;
  on_conflict?: Maybe<Member_In_Trust_For_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_In_Trust_For_Avg_Fields = {
  __typename?: 'member_in_trust_for_avg_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Avg_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_in_trust_for". All fields are combined with a logical 'AND'. */
export type Member_In_Trust_For_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_In_Trust_For_Bool_Exp>>>;
  _not?: Maybe<Member_In_Trust_For_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_In_Trust_For_Bool_Exp>>>;
  country_of_origin?: Maybe<Country_Bool_Exp>;
  country_of_origin_id?: Maybe<Bigint_Comparison_Exp>;
  country_of_residence?: Maybe<Country_Bool_Exp>;
  country_of_residence_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gps_address?: Maybe<String_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  id_expiry_date?: Maybe<Date_Comparison_Exp>;
  id_issue_date?: Maybe<Date_Comparison_Exp>;
  id_number?: Maybe<String_Comparison_Exp>;
  id_type?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  marital_status?: Maybe<Marital_Status_Bool_Exp>;
  marital_status_id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  mobile2?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  occupation?: Maybe<String_Comparison_Exp>;
  permit_expiry_date?: Maybe<Date_Comparison_Exp>;
  permit_issue_date?: Maybe<Date_Comparison_Exp>;
  place_of_birth?: Maybe<String_Comparison_Exp>;
  profession?: Maybe<String_Comparison_Exp>;
  professional_license?: Maybe<String_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  relationship?: Maybe<Relationship_Bool_Exp>;
  relationship_id?: Maybe<Bigint_Comparison_Exp>;
  residential_permit_number?: Maybe<String_Comparison_Exp>;
  residential_place_of_issue?: Maybe<String_Comparison_Exp>;
  residential_status?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_in_trust_for" */
export enum Member_In_Trust_For_Constraint {
  /** unique or primary key constraint */
  MemberInTrustForGuidKey = 'member_in_trust_for_guid_key',
  /** unique or primary key constraint */
  MemberInTrustForPkey = 'member_in_trust_for_pkey'
}

/** input type for incrementing integer column in table "member_in_trust_for" */
export type Member_In_Trust_For_Inc_Input = {
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_in_trust_for" */
export type Member_In_Trust_For_Insert_Input = {
  country_of_origin?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Marital_Status_Obj_Rel_Insert_Input>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship?: Maybe<Relationship_Obj_Rel_Insert_Input>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Member_In_Trust_For_Max_Fields = {
  __typename?: 'member_in_trust_for_max_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Max_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_In_Trust_For_Min_Fields = {
  __typename?: 'member_in_trust_for_min_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Min_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_in_trust_for" */
export type Member_In_Trust_For_Mutation_Response = {
  __typename?: 'member_in_trust_for_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_In_Trust_For>;
};

/** input type for inserting object relation for remote table "member_in_trust_for" */
export type Member_In_Trust_For_Obj_Rel_Insert_Input = {
  data: Member_In_Trust_For_Insert_Input;
  on_conflict?: Maybe<Member_In_Trust_For_On_Conflict>;
};

/** on conflict condition type for table "member_in_trust_for" */
export type Member_In_Trust_For_On_Conflict = {
  constraint: Member_In_Trust_For_Constraint;
  update_columns: Array<Member_In_Trust_For_Update_Column>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};

/** ordering options when selecting data from "member_in_trust_for" */
export type Member_In_Trust_For_Order_By = {
  country_of_origin?: Maybe<Country_Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence?: Maybe<Country_Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Marital_Status_Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  relationship?: Maybe<Relationship_Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_in_trust_for" */
export type Member_In_Trust_For_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_in_trust_for" */
export enum Member_In_Trust_For_Select_Column {
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CountryOfResidenceId = 'country_of_residence_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatusId = 'marital_status_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_in_trust_for" */
export type Member_In_Trust_For_Set_Input = {
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  created_by?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Member_In_Trust_For_Stddev_Fields = {
  __typename?: 'member_in_trust_for_stddev_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Stddev_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_In_Trust_For_Stddev_Pop_Fields = {
  __typename?: 'member_in_trust_for_stddev_pop_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Stddev_Pop_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_In_Trust_For_Stddev_Samp_Fields = {
  __typename?: 'member_in_trust_for_stddev_samp_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Stddev_Samp_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_In_Trust_For_Sum_Fields = {
  __typename?: 'member_in_trust_for_sum_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Sum_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** update columns of table "member_in_trust_for" */
export enum Member_In_Trust_For_Update_Column {
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CountryOfResidenceId = 'country_of_residence_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatusId = 'marital_status_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_In_Trust_For_Var_Pop_Fields = {
  __typename?: 'member_in_trust_for_var_pop_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Var_Pop_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_In_Trust_For_Var_Samp_Fields = {
  __typename?: 'member_in_trust_for_var_samp_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Var_Samp_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_In_Trust_For_Variance_Fields = {
  __typename?: 'member_in_trust_for_variance_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_in_trust_for" */
export type Member_In_Trust_For_Variance_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "member" */
export type Member_Inc_Input = {
  age?: Maybe<Scalars['Int']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  current_savings?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['Int']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "member_income_source" */
export type Member_Income_Source = {
  __typename?: 'member_income_source';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  /** An object relationship */
  income_source: Income_Source;
  income_source_id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_income_source" */
export type Member_Income_Source_Aggregate = {
  __typename?: 'member_income_source_aggregate';
  aggregate?: Maybe<Member_Income_Source_Aggregate_Fields>;
  nodes: Array<Member_Income_Source>;
};

/** aggregate fields of "member_income_source" */
export type Member_Income_Source_Aggregate_Fields = {
  __typename?: 'member_income_source_aggregate_fields';
  avg?: Maybe<Member_Income_Source_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Income_Source_Max_Fields>;
  min?: Maybe<Member_Income_Source_Min_Fields>;
  stddev?: Maybe<Member_Income_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Income_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Income_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Income_Source_Sum_Fields>;
  var_pop?: Maybe<Member_Income_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Income_Source_Var_Samp_Fields>;
  variance?: Maybe<Member_Income_Source_Variance_Fields>;
};


/** aggregate fields of "member_income_source" */
export type Member_Income_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Income_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_income_source" */
export type Member_Income_Source_Aggregate_Order_By = {
  avg?: Maybe<Member_Income_Source_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Income_Source_Max_Order_By>;
  min?: Maybe<Member_Income_Source_Min_Order_By>;
  stddev?: Maybe<Member_Income_Source_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Income_Source_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Income_Source_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Income_Source_Sum_Order_By>;
  var_pop?: Maybe<Member_Income_Source_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Income_Source_Var_Samp_Order_By>;
  variance?: Maybe<Member_Income_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_income_source" */
export type Member_Income_Source_Arr_Rel_Insert_Input = {
  data: Array<Member_Income_Source_Insert_Input>;
  on_conflict?: Maybe<Member_Income_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Income_Source_Avg_Fields = {
  __typename?: 'member_income_source_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_income_source" */
export type Member_Income_Source_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_income_source". All fields are combined with a logical 'AND'. */
export type Member_Income_Source_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Income_Source_Bool_Exp>>>;
  _not?: Maybe<Member_Income_Source_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Income_Source_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  income_source?: Maybe<Income_Source_Bool_Exp>;
  income_source_id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_income_source" */
export enum Member_Income_Source_Constraint {
  /** unique or primary key constraint */
  MemberIncomeSourceGuidKey = 'member_income_source_guid_key',
  /** unique or primary key constraint */
  MemberIncomeSourceIncomeSourceIdMemberIdKey = 'member_income_source_income_source_id_member_id_key',
  /** unique or primary key constraint */
  MemberIncomeSourcePkey = 'member_income_source_pkey'
}

/** input type for incrementing integer column in table "member_income_source" */
export type Member_Income_Source_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_income_source" */
export type Member_Income_Source_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  income_source?: Maybe<Income_Source_Obj_Rel_Insert_Input>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Income_Source_Max_Fields = {
  __typename?: 'member_income_source_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_income_source" */
export type Member_Income_Source_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Income_Source_Min_Fields = {
  __typename?: 'member_income_source_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_income_source" */
export type Member_Income_Source_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_income_source" */
export type Member_Income_Source_Mutation_Response = {
  __typename?: 'member_income_source_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Income_Source>;
};

/** input type for inserting object relation for remote table "member_income_source" */
export type Member_Income_Source_Obj_Rel_Insert_Input = {
  data: Member_Income_Source_Insert_Input;
  on_conflict?: Maybe<Member_Income_Source_On_Conflict>;
};

/** on conflict condition type for table "member_income_source" */
export type Member_Income_Source_On_Conflict = {
  constraint: Member_Income_Source_Constraint;
  update_columns: Array<Member_Income_Source_Update_Column>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};

/** ordering options when selecting data from "member_income_source" */
export type Member_Income_Source_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source?: Maybe<Income_Source_Order_By>;
  income_source_id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_income_source" */
export type Member_Income_Source_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_income_source" */
export enum Member_Income_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IncomeSourceId = 'income_source_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_income_source" */
export type Member_Income_Source_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Income_Source_Stddev_Fields = {
  __typename?: 'member_income_source_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_income_source" */
export type Member_Income_Source_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Income_Source_Stddev_Pop_Fields = {
  __typename?: 'member_income_source_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_income_source" */
export type Member_Income_Source_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Income_Source_Stddev_Samp_Fields = {
  __typename?: 'member_income_source_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_income_source" */
export type Member_Income_Source_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Income_Source_Sum_Fields = {
  __typename?: 'member_income_source_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  income_source_id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_income_source" */
export type Member_Income_Source_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_income_source" */
export enum Member_Income_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IncomeSourceId = 'income_source_id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Income_Source_Var_Pop_Fields = {
  __typename?: 'member_income_source_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_income_source" */
export type Member_Income_Source_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Income_Source_Var_Samp_Fields = {
  __typename?: 'member_income_source_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_income_source" */
export type Member_Income_Source_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Income_Source_Variance_Fields = {
  __typename?: 'member_income_source_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  income_source_id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_income_source" */
export type Member_Income_Source_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  income_source_id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** input type for inserting data into table "member" */
export type Member_Insert_Input = {
  account_mandate?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  anticipated_investment_activity_top_ups?: Maybe<Scalars['String']>;
  anticipated_investment_activity_top_ups_other?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Scalars['String']>;
  approved_on?: Maybe<Scalars['timestamp']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  assigned_to_user?: Maybe<User_Obj_Rel_Insert_Input>;
  beneficiaries?: Maybe<Member_Beneficiary_Arr_Rel_Insert_Input>;
  city?: Maybe<Scalars['String']>;
  client_investment_profile?: Maybe<Client_Investment_Profile_Obj_Rel_Insert_Input>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_origin_jt?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_residence?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_jt?: Maybe<Country_Obj_Rel_Insert_Input>;
  current_savings?: Maybe<Scalars['numeric']>;
  employer?: Maybe<Scalars['String']>;
  employer_address?: Maybe<Scalars['String']>;
  employer_address_jt?: Maybe<Scalars['String']>;
  employer_city?: Maybe<Scalars['String']>;
  employer_city_jt?: Maybe<Scalars['String']>;
  employer_contact?: Maybe<Scalars['String']>;
  employer_contact_jt?: Maybe<Scalars['String']>;
  employer_email?: Maybe<Scalars['String']>;
  employer_email_jt?: Maybe<Scalars['String']>;
  employer_jt?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  employment_status_jt?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  gps_address_jt?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  home_address_jt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date_jt?: Maybe<Scalars['date']>;
  id_issue_date_jt?: Maybe<Scalars['date']>;
  id_number_jt?: Maybe<Scalars['String']>;
  id_type_jt?: Maybe<Scalars['String']>;
  industry?: Maybe<Industry_Obj_Rel_Insert_Input>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  is_customer?: Maybe<Scalars['Boolean']>;
  is_retired?: Maybe<Scalars['Boolean']>;
  marital_status?: Maybe<Marital_Status_Obj_Rel_Insert_Input>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  marital_status_jt?: Maybe<Marital_Status_Obj_Rel_Insert_Input>;
  member_contribution_sources?: Maybe<Member_Contribution_Source_Arr_Rel_Insert_Input>;
  member_dependents?: Maybe<Member_Dependent_Arr_Rel_Insert_Input>;
  member_goals?: Maybe<Member_Goal_Arr_Rel_Insert_Input>;
  member_identifications?: Maybe<Member_Identification_Arr_Rel_Insert_Input>;
  member_in_trust_fors?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  member_income_sources?: Maybe<Member_Income_Source_Arr_Rel_Insert_Input>;
  member_mode_of_instruction_communications?: Maybe<Member_Mode_Of_Instruction_Communication_Arr_Rel_Insert_Input>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Arr_Rel_Insert_Input>;
  member_redemptions?: Maybe<Member_Redemption_Arr_Rel_Insert_Input>;
  member_rejects?: Maybe<Member_Reject_Arr_Rel_Insert_Input>;
  mf_code?: Maybe<Scalars['String']>;
  mobile_secondary?: Maybe<Scalars['String']>;
  mobile_secondary_jt?: Maybe<Scalars['String']>;
  mode_of_statement_delivery?: Maybe<Scalars['String']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  nationality?: Maybe<Scalars['String']>;
  nature_of_business?: Maybe<Scalars['String']>;
  nature_of_business_jt?: Maybe<Scalars['String']>;
  next_of_kin?: Maybe<Next_Of_Kin_Obj_Rel_Insert_Input>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  occupation_jt?: Maybe<Scalars['String']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_expiry_date_jt?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  permit_issue_date_jt?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  place_of_birth_jt?: Maybe<Scalars['String']>;
  place_of_issue_jt?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  profession_jt?: Maybe<Scalars['String']>;
  professional_license_number?: Maybe<Scalars['String']>;
  professional_license_number_jt?: Maybe<Scalars['String']>;
  psl_code?: Maybe<Scalars['String']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  relationship_jt?: Maybe<Scalars['String']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_permit_number_jt?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_place_of_issue_jt?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  residential_status_jt?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  statement_frequency?: Maybe<Scalars['String']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Status_Obj_Rel_Insert_Input>;
  status_id?: Maybe<Scalars['bigint']>;
  tin?: Maybe<Scalars['String']>;
  tin_jt?: Maybe<Scalars['String']>;
  title?: Maybe<Title_Obj_Rel_Insert_Input>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  title_jt?: Maybe<Title_Obj_Rel_Insert_Input>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  welcome_pack_sent_at?: Maybe<Scalars['timestamp']>;
  welcome_pack_url?: Maybe<Scalars['String']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Member_Max_Fields = {
  __typename?: 'member_max_fields';
  account_mandate?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  anticipated_investment_activity_top_ups?: Maybe<Scalars['String']>;
  anticipated_investment_activity_top_ups_other?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Scalars['String']>;
  approved_on?: Maybe<Scalars['timestamp']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  current_savings?: Maybe<Scalars['numeric']>;
  employer?: Maybe<Scalars['String']>;
  employer_address?: Maybe<Scalars['String']>;
  employer_address_jt?: Maybe<Scalars['String']>;
  employer_city?: Maybe<Scalars['String']>;
  employer_city_jt?: Maybe<Scalars['String']>;
  employer_contact?: Maybe<Scalars['String']>;
  employer_contact_jt?: Maybe<Scalars['String']>;
  employer_email?: Maybe<Scalars['String']>;
  employer_email_jt?: Maybe<Scalars['String']>;
  employer_jt?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  employment_status_jt?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  gps_address_jt?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  home_address_jt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date_jt?: Maybe<Scalars['date']>;
  id_issue_date_jt?: Maybe<Scalars['date']>;
  id_number_jt?: Maybe<Scalars['String']>;
  id_type_jt?: Maybe<Scalars['String']>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  mf_code?: Maybe<Scalars['String']>;
  mobile_secondary?: Maybe<Scalars['String']>;
  mobile_secondary_jt?: Maybe<Scalars['String']>;
  mode_of_statement_delivery?: Maybe<Scalars['String']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  nationality?: Maybe<Scalars['String']>;
  nature_of_business?: Maybe<Scalars['String']>;
  nature_of_business_jt?: Maybe<Scalars['String']>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  occupation_jt?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_expiry_date_jt?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  permit_issue_date_jt?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  place_of_birth_jt?: Maybe<Scalars['String']>;
  place_of_issue_jt?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  profession_jt?: Maybe<Scalars['String']>;
  professional_license_number?: Maybe<Scalars['String']>;
  professional_license_number_jt?: Maybe<Scalars['String']>;
  psl_code?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  relationship_jt?: Maybe<Scalars['String']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_permit_number_jt?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_place_of_issue_jt?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  residential_status_jt?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  statement_frequency?: Maybe<Scalars['String']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  tin?: Maybe<Scalars['String']>;
  tin_jt?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  welcome_pack_sent_at?: Maybe<Scalars['timestamp']>;
  welcome_pack_url?: Maybe<Scalars['String']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "member" */
export type Member_Max_Order_By = {
  account_mandate?: Maybe<Order_By>;
  account_type?: Maybe<Order_By>;
  age?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups_other?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Order_By>;
  approved_on?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  employer?: Maybe<Order_By>;
  employer_address?: Maybe<Order_By>;
  employer_address_jt?: Maybe<Order_By>;
  employer_city?: Maybe<Order_By>;
  employer_city_jt?: Maybe<Order_By>;
  employer_contact?: Maybe<Order_By>;
  employer_contact_jt?: Maybe<Order_By>;
  employer_email?: Maybe<Order_By>;
  employer_email_jt?: Maybe<Order_By>;
  employer_jt?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  employment_status_jt?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  gps_address_jt?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  home_address_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date_jt?: Maybe<Order_By>;
  id_issue_date_jt?: Maybe<Order_By>;
  id_number_jt?: Maybe<Order_By>;
  id_type_jt?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  mf_code?: Maybe<Order_By>;
  mobile_secondary?: Maybe<Order_By>;
  mobile_secondary_jt?: Maybe<Order_By>;
  mode_of_statement_delivery?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  nationality?: Maybe<Order_By>;
  nature_of_business?: Maybe<Order_By>;
  nature_of_business_jt?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  occupation_jt?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_expiry_date_jt?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  permit_issue_date_jt?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  place_of_birth_jt?: Maybe<Order_By>;
  place_of_issue_jt?: Maybe<Order_By>;
  postal_address?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  profession_jt?: Maybe<Order_By>;
  professional_license_number?: Maybe<Order_By>;
  professional_license_number_jt?: Maybe<Order_By>;
  psl_code?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  relationship_jt?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_permit_number_jt?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_place_of_issue_jt?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  residential_status_jt?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  statement_frequency?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_jt?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  welcome_pack_sent_at?: Maybe<Order_By>;
  welcome_pack_url?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Min_Fields = {
  __typename?: 'member_min_fields';
  account_mandate?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  anticipated_investment_activity_top_ups?: Maybe<Scalars['String']>;
  anticipated_investment_activity_top_ups_other?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Scalars['String']>;
  approved_on?: Maybe<Scalars['timestamp']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  current_savings?: Maybe<Scalars['numeric']>;
  employer?: Maybe<Scalars['String']>;
  employer_address?: Maybe<Scalars['String']>;
  employer_address_jt?: Maybe<Scalars['String']>;
  employer_city?: Maybe<Scalars['String']>;
  employer_city_jt?: Maybe<Scalars['String']>;
  employer_contact?: Maybe<Scalars['String']>;
  employer_contact_jt?: Maybe<Scalars['String']>;
  employer_email?: Maybe<Scalars['String']>;
  employer_email_jt?: Maybe<Scalars['String']>;
  employer_jt?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  employment_status_jt?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  gps_address_jt?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  home_address_jt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date_jt?: Maybe<Scalars['date']>;
  id_issue_date_jt?: Maybe<Scalars['date']>;
  id_number_jt?: Maybe<Scalars['String']>;
  id_type_jt?: Maybe<Scalars['String']>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  mf_code?: Maybe<Scalars['String']>;
  mobile_secondary?: Maybe<Scalars['String']>;
  mobile_secondary_jt?: Maybe<Scalars['String']>;
  mode_of_statement_delivery?: Maybe<Scalars['String']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  nationality?: Maybe<Scalars['String']>;
  nature_of_business?: Maybe<Scalars['String']>;
  nature_of_business_jt?: Maybe<Scalars['String']>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  occupation_jt?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_expiry_date_jt?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  permit_issue_date_jt?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  place_of_birth_jt?: Maybe<Scalars['String']>;
  place_of_issue_jt?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  profession_jt?: Maybe<Scalars['String']>;
  professional_license_number?: Maybe<Scalars['String']>;
  professional_license_number_jt?: Maybe<Scalars['String']>;
  psl_code?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  relationship_jt?: Maybe<Scalars['String']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_permit_number_jt?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_place_of_issue_jt?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  residential_status_jt?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  statement_frequency?: Maybe<Scalars['String']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  tin?: Maybe<Scalars['String']>;
  tin_jt?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  welcome_pack_sent_at?: Maybe<Scalars['timestamp']>;
  welcome_pack_url?: Maybe<Scalars['String']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "member" */
export type Member_Min_Order_By = {
  account_mandate?: Maybe<Order_By>;
  account_type?: Maybe<Order_By>;
  age?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups_other?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Order_By>;
  approved_on?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  employer?: Maybe<Order_By>;
  employer_address?: Maybe<Order_By>;
  employer_address_jt?: Maybe<Order_By>;
  employer_city?: Maybe<Order_By>;
  employer_city_jt?: Maybe<Order_By>;
  employer_contact?: Maybe<Order_By>;
  employer_contact_jt?: Maybe<Order_By>;
  employer_email?: Maybe<Order_By>;
  employer_email_jt?: Maybe<Order_By>;
  employer_jt?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  employment_status_jt?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  gps_address_jt?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  home_address_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date_jt?: Maybe<Order_By>;
  id_issue_date_jt?: Maybe<Order_By>;
  id_number_jt?: Maybe<Order_By>;
  id_type_jt?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  mf_code?: Maybe<Order_By>;
  mobile_secondary?: Maybe<Order_By>;
  mobile_secondary_jt?: Maybe<Order_By>;
  mode_of_statement_delivery?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  nationality?: Maybe<Order_By>;
  nature_of_business?: Maybe<Order_By>;
  nature_of_business_jt?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  occupation_jt?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_expiry_date_jt?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  permit_issue_date_jt?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  place_of_birth_jt?: Maybe<Order_By>;
  place_of_issue_jt?: Maybe<Order_By>;
  postal_address?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  profession_jt?: Maybe<Order_By>;
  professional_license_number?: Maybe<Order_By>;
  professional_license_number_jt?: Maybe<Order_By>;
  psl_code?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  relationship_jt?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_permit_number_jt?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_place_of_issue_jt?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  residential_status_jt?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  statement_frequency?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_jt?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  welcome_pack_sent_at?: Maybe<Order_By>;
  welcome_pack_url?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** columns and relationships of "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication = {
  __typename?: 'member_mode_of_instruction_communication';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  /** An object relationship */
  mode_of_instruction_communication: Mode_Of_Instruction_Communication;
  mode_of_instruction_communication_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Aggregate = {
  __typename?: 'member_mode_of_instruction_communication_aggregate';
  aggregate?: Maybe<Member_Mode_Of_Instruction_Communication_Aggregate_Fields>;
  nodes: Array<Member_Mode_Of_Instruction_Communication>;
};

/** aggregate fields of "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Aggregate_Fields = {
  __typename?: 'member_mode_of_instruction_communication_aggregate_fields';
  avg?: Maybe<Member_Mode_Of_Instruction_Communication_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Mode_Of_Instruction_Communication_Max_Fields>;
  min?: Maybe<Member_Mode_Of_Instruction_Communication_Min_Fields>;
  stddev?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Mode_Of_Instruction_Communication_Sum_Fields>;
  var_pop?: Maybe<Member_Mode_Of_Instruction_Communication_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Mode_Of_Instruction_Communication_Var_Samp_Fields>;
  variance?: Maybe<Member_Mode_Of_Instruction_Communication_Variance_Fields>;
};


/** aggregate fields of "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Aggregate_Order_By = {
  avg?: Maybe<Member_Mode_Of_Instruction_Communication_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Mode_Of_Instruction_Communication_Max_Order_By>;
  min?: Maybe<Member_Mode_Of_Instruction_Communication_Min_Order_By>;
  stddev?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Mode_Of_Instruction_Communication_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Mode_Of_Instruction_Communication_Sum_Order_By>;
  var_pop?: Maybe<Member_Mode_Of_Instruction_Communication_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Mode_Of_Instruction_Communication_Var_Samp_Order_By>;
  variance?: Maybe<Member_Mode_Of_Instruction_Communication_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Arr_Rel_Insert_Input = {
  data: Array<Member_Mode_Of_Instruction_Communication_Insert_Input>;
  on_conflict?: Maybe<Member_Mode_Of_Instruction_Communication_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Mode_Of_Instruction_Communication_Avg_Fields = {
  __typename?: 'member_mode_of_instruction_communication_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_mode_of_instruction_communication". All fields are combined with a logical 'AND'. */
export type Member_Mode_Of_Instruction_Communication_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>>>;
  _not?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
  mode_of_instruction_communication_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_mode_of_instruction_communication" */
export enum Member_Mode_Of_Instruction_Communication_Constraint {
  /** unique or primary key constraint */
  MemberModeOfInstructionCommunicationGuidKey = 'member_mode_of_instruction_communication_guid_key',
  /** unique or primary key constraint */
  MemberModeOfInstructionCommunicationModeOfInstructionCo = 'member_mode_of_instruction_communication_mode_of_instruction_co',
  /** unique or primary key constraint */
  MemberModeOfInstructionCommunicationPkey = 'member_mode_of_instruction_communication_pkey'
}

/** input type for incrementing integer column in table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Obj_Rel_Insert_Input>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Mode_Of_Instruction_Communication_Max_Fields = {
  __typename?: 'member_mode_of_instruction_communication_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Mode_Of_Instruction_Communication_Min_Fields = {
  __typename?: 'member_mode_of_instruction_communication_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Mutation_Response = {
  __typename?: 'member_mode_of_instruction_communication_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Mode_Of_Instruction_Communication>;
};

/** input type for inserting object relation for remote table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Obj_Rel_Insert_Input = {
  data: Member_Mode_Of_Instruction_Communication_Insert_Input;
  on_conflict?: Maybe<Member_Mode_Of_Instruction_Communication_On_Conflict>;
};

/** on conflict condition type for table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_On_Conflict = {
  constraint: Member_Mode_Of_Instruction_Communication_Constraint;
  update_columns: Array<Member_Mode_Of_Instruction_Communication_Update_Column>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};

/** ordering options when selecting data from "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_mode_of_instruction_communication" */
export enum Member_Mode_Of_Instruction_Communication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModeOfInstructionCommunicationId = 'mode_of_instruction_communication_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Mode_Of_Instruction_Communication_Stddev_Fields = {
  __typename?: 'member_mode_of_instruction_communication_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Mode_Of_Instruction_Communication_Stddev_Pop_Fields = {
  __typename?: 'member_mode_of_instruction_communication_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Mode_Of_Instruction_Communication_Stddev_Samp_Fields = {
  __typename?: 'member_mode_of_instruction_communication_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Mode_Of_Instruction_Communication_Sum_Fields = {
  __typename?: 'member_mode_of_instruction_communication_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_mode_of_instruction_communication" */
export enum Member_Mode_Of_Instruction_Communication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModeOfInstructionCommunicationId = 'mode_of_instruction_communication_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Mode_Of_Instruction_Communication_Var_Pop_Fields = {
  __typename?: 'member_mode_of_instruction_communication_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Mode_Of_Instruction_Communication_Var_Samp_Fields = {
  __typename?: 'member_mode_of_instruction_communication_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Mode_Of_Instruction_Communication_Variance_Fields = {
  __typename?: 'member_mode_of_instruction_communication_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  mode_of_instruction_communication_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_mode_of_instruction_communication" */
export type Member_Mode_Of_Instruction_Communication_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mode_of_instruction_communication_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** response of any mutation on the table "member" */
export type Member_Mutation_Response = {
  __typename?: 'member_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member>;
};

/** input type for inserting object relation for remote table "member" */
export type Member_Obj_Rel_Insert_Input = {
  data: Member_Insert_Input;
  on_conflict?: Maybe<Member_On_Conflict>;
};

/** on conflict condition type for table "member" */
export type Member_On_Conflict = {
  constraint: Member_Constraint;
  update_columns: Array<Member_Update_Column>;
  where?: Maybe<Member_Bool_Exp>;
};

/** ordering options when selecting data from "member" */
export type Member_Order_By = {
  account_mandate?: Maybe<Order_By>;
  account_type?: Maybe<Order_By>;
  age?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups?: Maybe<Order_By>;
  anticipated_investment_activity_top_ups_other?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals?: Maybe<Order_By>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Order_By>;
  approved_on?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  assigned_to_user?: Maybe<User_Order_By>;
  beneficiaries_aggregate?: Maybe<Member_Beneficiary_Aggregate_Order_By>;
  city?: Maybe<Order_By>;
  client_investment_profile?: Maybe<Client_Investment_Profile_Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin?: Maybe<Country_Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_origin_jt?: Maybe<Country_Order_By>;
  country_of_residence?: Maybe<Country_Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  country_of_residence_jt?: Maybe<Country_Order_By>;
  current_savings?: Maybe<Order_By>;
  employer?: Maybe<Order_By>;
  employer_address?: Maybe<Order_By>;
  employer_address_jt?: Maybe<Order_By>;
  employer_city?: Maybe<Order_By>;
  employer_city_jt?: Maybe<Order_By>;
  employer_contact?: Maybe<Order_By>;
  employer_contact_jt?: Maybe<Order_By>;
  employer_email?: Maybe<Order_By>;
  employer_email_jt?: Maybe<Order_By>;
  employer_jt?: Maybe<Order_By>;
  employment_status?: Maybe<Order_By>;
  employment_status_jt?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  gps_address_jt?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  home_address_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date_jt?: Maybe<Order_By>;
  id_issue_date_jt?: Maybe<Order_By>;
  id_number_jt?: Maybe<Order_By>;
  id_type_jt?: Maybe<Order_By>;
  industry?: Maybe<Industry_Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  is_customer?: Maybe<Order_By>;
  is_retired?: Maybe<Order_By>;
  marital_status?: Maybe<Marital_Status_Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  marital_status_jt?: Maybe<Marital_Status_Order_By>;
  member_contribution_sources_aggregate?: Maybe<Member_Contribution_Source_Aggregate_Order_By>;
  member_dependents_aggregate?: Maybe<Member_Dependent_Aggregate_Order_By>;
  member_goals_aggregate?: Maybe<Member_Goal_Aggregate_Order_By>;
  member_identifications_aggregate?: Maybe<Member_Identification_Aggregate_Order_By>;
  member_in_trust_fors_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  member_income_sources_aggregate?: Maybe<Member_Income_Source_Aggregate_Order_By>;
  member_mode_of_instruction_communications_aggregate?: Maybe<Member_Mode_Of_Instruction_Communication_Aggregate_Order_By>;
  member_payment_instructions_aggregate?: Maybe<Member_Payment_Instruction_Aggregate_Order_By>;
  member_redemptions_aggregate?: Maybe<Member_Redemption_Aggregate_Order_By>;
  member_rejects_aggregate?: Maybe<Member_Reject_Aggregate_Order_By>;
  mf_code?: Maybe<Order_By>;
  mobile_secondary?: Maybe<Order_By>;
  mobile_secondary_jt?: Maybe<Order_By>;
  mode_of_statement_delivery?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  nationality?: Maybe<Order_By>;
  nature_of_business?: Maybe<Order_By>;
  nature_of_business_jt?: Maybe<Order_By>;
  next_of_kin?: Maybe<Next_Of_Kin_Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  occupation_jt?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_expiry_date_jt?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  permit_issue_date_jt?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  place_of_birth_jt?: Maybe<Order_By>;
  place_of_issue_jt?: Maybe<Order_By>;
  postal_address?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  profession_jt?: Maybe<Order_By>;
  professional_license_number?: Maybe<Order_By>;
  professional_license_number_jt?: Maybe<Order_By>;
  psl_code?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  relationship_jt?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_permit_number_jt?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_place_of_issue_jt?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  residential_status_jt?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  statement_frequency?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status?: Maybe<Status_Order_By>;
  status_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_jt?: Maybe<Order_By>;
  title?: Maybe<Title_Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  title_jt?: Maybe<Title_Order_By>;
  user?: Maybe<User_Order_By>;
  welcome_pack_sent_at?: Maybe<Order_By>;
  welcome_pack_url?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** columns and relationships of "member_payment_instruction" */
export type Member_Payment_Instruction = {
  __typename?: 'member_payment_instruction';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  /** An object relationship */
  branchByBranchId?: Maybe<Branch>;
  branch_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  guid: Scalars['uuid'];
  id: Scalars['bigint'];
  is_source_of_deduction: Scalars['Boolean'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  mobile_money_number?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  payment_provider: Payment_Provider;
  payment_provider_id: Scalars['bigint'];
  preferred: Scalars['Boolean'];
  sort_code?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "member_payment_instruction" */
export type Member_Payment_Instruction_Aggregate = {
  __typename?: 'member_payment_instruction_aggregate';
  aggregate?: Maybe<Member_Payment_Instruction_Aggregate_Fields>;
  nodes: Array<Member_Payment_Instruction>;
};

/** aggregate fields of "member_payment_instruction" */
export type Member_Payment_Instruction_Aggregate_Fields = {
  __typename?: 'member_payment_instruction_aggregate_fields';
  avg?: Maybe<Member_Payment_Instruction_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Payment_Instruction_Max_Fields>;
  min?: Maybe<Member_Payment_Instruction_Min_Fields>;
  stddev?: Maybe<Member_Payment_Instruction_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Payment_Instruction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Payment_Instruction_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Payment_Instruction_Sum_Fields>;
  var_pop?: Maybe<Member_Payment_Instruction_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Payment_Instruction_Var_Samp_Fields>;
  variance?: Maybe<Member_Payment_Instruction_Variance_Fields>;
};


/** aggregate fields of "member_payment_instruction" */
export type Member_Payment_Instruction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_payment_instruction" */
export type Member_Payment_Instruction_Aggregate_Order_By = {
  avg?: Maybe<Member_Payment_Instruction_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Payment_Instruction_Max_Order_By>;
  min?: Maybe<Member_Payment_Instruction_Min_Order_By>;
  stddev?: Maybe<Member_Payment_Instruction_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Payment_Instruction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Payment_Instruction_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Payment_Instruction_Sum_Order_By>;
  var_pop?: Maybe<Member_Payment_Instruction_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Payment_Instruction_Var_Samp_Order_By>;
  variance?: Maybe<Member_Payment_Instruction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_payment_instruction" */
export type Member_Payment_Instruction_Arr_Rel_Insert_Input = {
  data: Array<Member_Payment_Instruction_Insert_Input>;
  on_conflict?: Maybe<Member_Payment_Instruction_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Payment_Instruction_Avg_Fields = {
  __typename?: 'member_payment_instruction_avg_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Avg_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_payment_instruction". All fields are combined with a logical 'AND'. */
export type Member_Payment_Instruction_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Payment_Instruction_Bool_Exp>>>;
  _not?: Maybe<Member_Payment_Instruction_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Payment_Instruction_Bool_Exp>>>;
  account_name?: Maybe<String_Comparison_Exp>;
  account_number?: Maybe<String_Comparison_Exp>;
  branch?: Maybe<String_Comparison_Exp>;
  branchByBranchId?: Maybe<Branch_Bool_Exp>;
  branch_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_source_of_deduction?: Maybe<Boolean_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  mobile_money_number?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  payment_provider?: Maybe<Payment_Provider_Bool_Exp>;
  payment_provider_id?: Maybe<Bigint_Comparison_Exp>;
  preferred?: Maybe<Boolean_Comparison_Exp>;
  sort_code?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_payment_instruction" */
export enum Member_Payment_Instruction_Constraint {
  /** unique or primary key constraint */
  MemberPaymentInstructionGuidKey = 'member_payment_instruction_guid_key',
  /** unique or primary key constraint */
  MemberPaymentInstructionPkey = 'member_payment_instruction_pkey'
}

/** input type for incrementing integer column in table "member_payment_instruction" */
export type Member_Payment_Instruction_Inc_Input = {
  branch_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_payment_instruction" */
export type Member_Payment_Instruction_Insert_Input = {
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  branchByBranchId?: Maybe<Branch_Obj_Rel_Insert_Input>;
  branch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  is_source_of_deduction?: Maybe<Scalars['Boolean']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile_money_number?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider?: Maybe<Payment_Provider_Obj_Rel_Insert_Input>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  preferred?: Maybe<Scalars['Boolean']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Payment_Instruction_Max_Fields = {
  __typename?: 'member_payment_instruction_max_fields';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  branch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile_money_number?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Max_Order_By = {
  account_name?: Maybe<Order_By>;
  account_number?: Maybe<Order_By>;
  branch?: Maybe<Order_By>;
  branch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile_money_number?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Payment_Instruction_Min_Fields = {
  __typename?: 'member_payment_instruction_min_fields';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  branch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile_money_number?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Min_Order_By = {
  account_name?: Maybe<Order_By>;
  account_number?: Maybe<Order_By>;
  branch?: Maybe<Order_By>;
  branch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mobile_money_number?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_payment_instruction" */
export type Member_Payment_Instruction_Mutation_Response = {
  __typename?: 'member_payment_instruction_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Payment_Instruction>;
};

/** input type for inserting object relation for remote table "member_payment_instruction" */
export type Member_Payment_Instruction_Obj_Rel_Insert_Input = {
  data: Member_Payment_Instruction_Insert_Input;
  on_conflict?: Maybe<Member_Payment_Instruction_On_Conflict>;
};

/** on conflict condition type for table "member_payment_instruction" */
export type Member_Payment_Instruction_On_Conflict = {
  constraint: Member_Payment_Instruction_Constraint;
  update_columns: Array<Member_Payment_Instruction_Update_Column>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};

/** ordering options when selecting data from "member_payment_instruction" */
export type Member_Payment_Instruction_Order_By = {
  account_name?: Maybe<Order_By>;
  account_number?: Maybe<Order_By>;
  branch?: Maybe<Order_By>;
  branchByBranchId?: Maybe<Branch_Order_By>;
  branch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_source_of_deduction?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  mobile_money_number?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider?: Maybe<Payment_Provider_Order_By>;
  payment_provider_id?: Maybe<Order_By>;
  preferred?: Maybe<Order_By>;
  sort_code?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_payment_instruction" */
export type Member_Payment_Instruction_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_payment_instruction" */
export enum Member_Payment_Instruction_Select_Column {
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Branch = 'branch',
  /** column name */
  BranchId = 'branch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IsSourceOfDeduction = 'is_source_of_deduction',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MobileMoneyNumber = 'mobile_money_number',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentProviderId = 'payment_provider_id',
  /** column name */
  Preferred = 'preferred',
  /** column name */
  SortCode = 'sort_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_payment_instruction" */
export type Member_Payment_Instruction_Set_Input = {
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  branch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  is_source_of_deduction?: Maybe<Scalars['Boolean']>;
  member_id?: Maybe<Scalars['bigint']>;
  mobile_money_number?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
  preferred?: Maybe<Scalars['Boolean']>;
  sort_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Payment_Instruction_Stddev_Fields = {
  __typename?: 'member_payment_instruction_stddev_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Stddev_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Payment_Instruction_Stddev_Pop_Fields = {
  __typename?: 'member_payment_instruction_stddev_pop_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Stddev_Pop_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Payment_Instruction_Stddev_Samp_Fields = {
  __typename?: 'member_payment_instruction_stddev_samp_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Stddev_Samp_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Payment_Instruction_Sum_Fields = {
  __typename?: 'member_payment_instruction_sum_fields';
  branch_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_provider_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Sum_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** update columns of table "member_payment_instruction" */
export enum Member_Payment_Instruction_Update_Column {
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  Branch = 'branch',
  /** column name */
  BranchId = 'branch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  IsSourceOfDeduction = 'is_source_of_deduction',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MobileMoneyNumber = 'mobile_money_number',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentProviderId = 'payment_provider_id',
  /** column name */
  Preferred = 'preferred',
  /** column name */
  SortCode = 'sort_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Payment_Instruction_Var_Pop_Fields = {
  __typename?: 'member_payment_instruction_var_pop_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Var_Pop_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Payment_Instruction_Var_Samp_Fields = {
  __typename?: 'member_payment_instruction_var_samp_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Var_Samp_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Payment_Instruction_Variance_Fields = {
  __typename?: 'member_payment_instruction_variance_fields';
  branch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_provider_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_payment_instruction" */
export type Member_Payment_Instruction_Variance_Order_By = {
  branch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_provider_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "member" */
export type Member_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "member_redemption" */
export type Member_Redemption = {
  __typename?: 'member_redemption';
  amount: Scalars['numeric'];
  charges: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  /** An array relationship */
  redemption_shares: Array<Redemption_Share>;
  /** An aggregated array relationship */
  redemption_shares_aggregate: Redemption_Share_Aggregate;
  status_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "member_redemption" */
export type Member_RedemptionRedemption_SharesArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** columns and relationships of "member_redemption" */
export type Member_RedemptionRedemption_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};

/** aggregated selection of "member_redemption" */
export type Member_Redemption_Aggregate = {
  __typename?: 'member_redemption_aggregate';
  aggregate?: Maybe<Member_Redemption_Aggregate_Fields>;
  nodes: Array<Member_Redemption>;
};

/** aggregate fields of "member_redemption" */
export type Member_Redemption_Aggregate_Fields = {
  __typename?: 'member_redemption_aggregate_fields';
  avg?: Maybe<Member_Redemption_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Redemption_Max_Fields>;
  min?: Maybe<Member_Redemption_Min_Fields>;
  stddev?: Maybe<Member_Redemption_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Redemption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Redemption_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Redemption_Sum_Fields>;
  var_pop?: Maybe<Member_Redemption_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Redemption_Var_Samp_Fields>;
  variance?: Maybe<Member_Redemption_Variance_Fields>;
};


/** aggregate fields of "member_redemption" */
export type Member_Redemption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Redemption_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_redemption" */
export type Member_Redemption_Aggregate_Order_By = {
  avg?: Maybe<Member_Redemption_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Redemption_Max_Order_By>;
  min?: Maybe<Member_Redemption_Min_Order_By>;
  stddev?: Maybe<Member_Redemption_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Redemption_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Redemption_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Redemption_Sum_Order_By>;
  var_pop?: Maybe<Member_Redemption_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Redemption_Var_Samp_Order_By>;
  variance?: Maybe<Member_Redemption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_redemption" */
export type Member_Redemption_Arr_Rel_Insert_Input = {
  data: Array<Member_Redemption_Insert_Input>;
  on_conflict?: Maybe<Member_Redemption_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Redemption_Avg_Fields = {
  __typename?: 'member_redemption_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_redemption" */
export type Member_Redemption_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_redemption". All fields are combined with a logical 'AND'. */
export type Member_Redemption_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Redemption_Bool_Exp>>>;
  _not?: Maybe<Member_Redemption_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Redemption_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  charges?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  redemption_shares?: Maybe<Redemption_Share_Bool_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_redemption" */
export enum Member_Redemption_Constraint {
  /** unique or primary key constraint */
  MemberRedemptionPkey = 'member_redemption_pkey'
}

/** input type for incrementing integer column in table "member_redemption" */
export type Member_Redemption_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_redemption" */
export type Member_Redemption_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  redemption_shares?: Maybe<Redemption_Share_Arr_Rel_Insert_Input>;
  status_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Redemption_Max_Fields = {
  __typename?: 'member_redemption_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_redemption" */
export type Member_Redemption_Max_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Redemption_Min_Fields = {
  __typename?: 'member_redemption_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_redemption" */
export type Member_Redemption_Min_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_redemption" */
export type Member_Redemption_Mutation_Response = {
  __typename?: 'member_redemption_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Redemption>;
};

/** input type for inserting object relation for remote table "member_redemption" */
export type Member_Redemption_Obj_Rel_Insert_Input = {
  data: Member_Redemption_Insert_Input;
  on_conflict?: Maybe<Member_Redemption_On_Conflict>;
};

/** on conflict condition type for table "member_redemption" */
export type Member_Redemption_On_Conflict = {
  constraint: Member_Redemption_Constraint;
  update_columns: Array<Member_Redemption_Update_Column>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};

/** ordering options when selecting data from "member_redemption" */
export type Member_Redemption_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  redemption_shares_aggregate?: Maybe<Redemption_Share_Aggregate_Order_By>;
  status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_redemption" */
export type Member_Redemption_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_redemption" */
export enum Member_Redemption_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Charges = 'charges',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_redemption" */
export type Member_Redemption_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Redemption_Stddev_Fields = {
  __typename?: 'member_redemption_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_redemption" */
export type Member_Redemption_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Redemption_Stddev_Pop_Fields = {
  __typename?: 'member_redemption_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_redemption" */
export type Member_Redemption_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Redemption_Stddev_Samp_Fields = {
  __typename?: 'member_redemption_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_redemption" */
export type Member_Redemption_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Redemption_Sum_Fields = {
  __typename?: 'member_redemption_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  charges?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_redemption" */
export type Member_Redemption_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** update columns of table "member_redemption" */
export enum Member_Redemption_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Charges = 'charges',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Redemption_Var_Pop_Fields = {
  __typename?: 'member_redemption_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_redemption" */
export type Member_Redemption_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Redemption_Var_Samp_Fields = {
  __typename?: 'member_redemption_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_redemption" */
export type Member_Redemption_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Redemption_Variance_Fields = {
  __typename?: 'member_redemption_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  charges?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_redemption" */
export type Member_Redemption_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  charges?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "member_reject" */
export type Member_Reject = {
  __typename?: 'member_reject';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An object relationship */
  member: Member;
  member_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "member_reject" */
export type Member_Reject_Aggregate = {
  __typename?: 'member_reject_aggregate';
  aggregate?: Maybe<Member_Reject_Aggregate_Fields>;
  nodes: Array<Member_Reject>;
};

/** aggregate fields of "member_reject" */
export type Member_Reject_Aggregate_Fields = {
  __typename?: 'member_reject_aggregate_fields';
  avg?: Maybe<Member_Reject_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Member_Reject_Max_Fields>;
  min?: Maybe<Member_Reject_Min_Fields>;
  stddev?: Maybe<Member_Reject_Stddev_Fields>;
  stddev_pop?: Maybe<Member_Reject_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Member_Reject_Stddev_Samp_Fields>;
  sum?: Maybe<Member_Reject_Sum_Fields>;
  var_pop?: Maybe<Member_Reject_Var_Pop_Fields>;
  var_samp?: Maybe<Member_Reject_Var_Samp_Fields>;
  variance?: Maybe<Member_Reject_Variance_Fields>;
};


/** aggregate fields of "member_reject" */
export type Member_Reject_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Reject_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member_reject" */
export type Member_Reject_Aggregate_Order_By = {
  avg?: Maybe<Member_Reject_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Reject_Max_Order_By>;
  min?: Maybe<Member_Reject_Min_Order_By>;
  stddev?: Maybe<Member_Reject_Stddev_Order_By>;
  stddev_pop?: Maybe<Member_Reject_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Member_Reject_Stddev_Samp_Order_By>;
  sum?: Maybe<Member_Reject_Sum_Order_By>;
  var_pop?: Maybe<Member_Reject_Var_Pop_Order_By>;
  var_samp?: Maybe<Member_Reject_Var_Samp_Order_By>;
  variance?: Maybe<Member_Reject_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "member_reject" */
export type Member_Reject_Arr_Rel_Insert_Input = {
  data: Array<Member_Reject_Insert_Input>;
  on_conflict?: Maybe<Member_Reject_On_Conflict>;
};

/** aggregate avg on columns */
export type Member_Reject_Avg_Fields = {
  __typename?: 'member_reject_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "member_reject" */
export type Member_Reject_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "member_reject". All fields are combined with a logical 'AND'. */
export type Member_Reject_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Member_Reject_Bool_Exp>>>;
  _not?: Maybe<Member_Reject_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Member_Reject_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "member_reject" */
export enum Member_Reject_Constraint {
  /** unique or primary key constraint */
  MemberRejectIdKey = 'member_reject_id_key',
  /** unique or primary key constraint */
  MemberRejectPkey = 'member_reject_pkey'
}

/** input type for incrementing integer column in table "member_reject" */
export type Member_Reject_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "member_reject" */
export type Member_Reject_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Member_Reject_Max_Fields = {
  __typename?: 'member_reject_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "member_reject" */
export type Member_Reject_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Reject_Min_Fields = {
  __typename?: 'member_reject_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "member_reject" */
export type Member_Reject_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "member_reject" */
export type Member_Reject_Mutation_Response = {
  __typename?: 'member_reject_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Member_Reject>;
};

/** input type for inserting object relation for remote table "member_reject" */
export type Member_Reject_Obj_Rel_Insert_Input = {
  data: Member_Reject_Insert_Input;
  on_conflict?: Maybe<Member_Reject_On_Conflict>;
};

/** on conflict condition type for table "member_reject" */
export type Member_Reject_On_Conflict = {
  constraint: Member_Reject_Constraint;
  update_columns: Array<Member_Reject_Update_Column>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};

/** ordering options when selecting data from "member_reject" */
export type Member_Reject_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "member_reject" */
export type Member_Reject_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "member_reject" */
export enum Member_Reject_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "member_reject" */
export type Member_Reject_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Member_Reject_Stddev_Fields = {
  __typename?: 'member_reject_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member_reject" */
export type Member_Reject_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Reject_Stddev_Pop_Fields = {
  __typename?: 'member_reject_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member_reject" */
export type Member_Reject_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Reject_Stddev_Samp_Fields = {
  __typename?: 'member_reject_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member_reject" */
export type Member_Reject_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Reject_Sum_Fields = {
  __typename?: 'member_reject_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "member_reject" */
export type Member_Reject_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "member_reject" */
export enum Member_Reject_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Member_Reject_Var_Pop_Fields = {
  __typename?: 'member_reject_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member_reject" */
export type Member_Reject_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Reject_Var_Samp_Fields = {
  __typename?: 'member_reject_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member_reject" */
export type Member_Reject_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Reject_Variance_Fields = {
  __typename?: 'member_reject_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member_reject" */
export type Member_Reject_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** select columns of table "member" */
export enum Member_Select_Column {
  /** column name */
  AccountMandate = 'account_mandate',
  /** column name */
  AccountType = 'account_type',
  /** column name */
  Age = 'age',
  /** column name */
  AnticipatedInvestmentActivityTopUps = 'anticipated_investment_activity_top_ups',
  /** column name */
  AnticipatedInvestmentActivityTopUpsOther = 'anticipated_investment_activity_top_ups_other',
  /** column name */
  AnticipatedInvestmentActivityWithdrawals = 'anticipated_investment_activity_withdrawals',
  /** column name */
  AnticipatedInvestmentActivityWithdrawalsOther = 'anticipated_investment_activity_withdrawals_other',
  /** column name */
  ApprovedOn = 'approved_on',
  /** column name */
  AssignedTo = 'assigned_to',
  /** column name */
  City = 'city',
  /** column name */
  ClientInvestmentProfileId = 'client_investment_profile_id',
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CountryOfOriginIdJt = 'country_of_origin_id_jt',
  /** column name */
  CountryOfResidenceId = 'country_of_residence_id',
  /** column name */
  CountryOfResidenceIdJt = 'country_of_residence_id_jt',
  /** column name */
  CurrentSavings = 'current_savings',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmployerAddress = 'employer_address',
  /** column name */
  EmployerAddressJt = 'employer_address_jt',
  /** column name */
  EmployerCity = 'employer_city',
  /** column name */
  EmployerCityJt = 'employer_city_jt',
  /** column name */
  EmployerContact = 'employer_contact',
  /** column name */
  EmployerContactJt = 'employer_contact_jt',
  /** column name */
  EmployerEmail = 'employer_email',
  /** column name */
  EmployerEmailJt = 'employer_email_jt',
  /** column name */
  EmployerJt = 'employer_jt',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  EmploymentStatusJt = 'employment_status_jt',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  GpsAddressJt = 'gps_address_jt',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  HomeAddressJt = 'home_address_jt',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDateJt = 'id_expiry_date_jt',
  /** column name */
  IdIssueDateJt = 'id_issue_date_jt',
  /** column name */
  IdNumberJt = 'id_number_jt',
  /** column name */
  IdTypeJt = 'id_type_jt',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  InitialInvestmentAmount = 'initial_investment_amount',
  /** column name */
  IsCustomer = 'is_customer',
  /** column name */
  IsRetired = 'is_retired',
  /** column name */
  MaritalStatusId = 'marital_status_id',
  /** column name */
  MaritalStatusIdJt = 'marital_status_id_jt',
  /** column name */
  MfCode = 'mf_code',
  /** column name */
  MobileSecondary = 'mobile_secondary',
  /** column name */
  MobileSecondaryJt = 'mobile_secondary_jt',
  /** column name */
  ModeOfStatementDelivery = 'mode_of_statement_delivery',
  /** column name */
  MonthlyIncome = 'monthly_income',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  NatureOfBusiness = 'nature_of_business',
  /** column name */
  NatureOfBusinessJt = 'nature_of_business_jt',
  /** column name */
  NextOfKinId = 'next_of_kin_id',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OccupationJt = 'occupation_jt',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitExpiryDateJt = 'permit_expiry_date_jt',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PermitIssueDateJt = 'permit_issue_date_jt',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  PlaceOfBirthJt = 'place_of_birth_jt',
  /** column name */
  PlaceOfIssueJt = 'place_of_issue_jt',
  /** column name */
  PostalAddress = 'postal_address',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionJt = 'profession_jt',
  /** column name */
  ProfessionalLicenseNumber = 'professional_license_number',
  /** column name */
  ProfessionalLicenseNumberJt = 'professional_license_number_jt',
  /** column name */
  PslCode = 'psl_code',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RegularTopUpAmount = 'regular_top_up_amount',
  /** column name */
  RegularWithdrawalAmount = 'regular_withdrawal_amount',
  /** column name */
  RelationshipJt = 'relationship_jt',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPermitNumberJt = 'residential_permit_number_jt',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialPlaceOfIssueJt = 'residential_place_of_issue_jt',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  ResidentialStatusJt = 'residential_status_jt',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Signature = 'signature',
  /** column name */
  StatementFrequency = 'statement_frequency',
  /** column name */
  StatementServiceId = 'statement_service_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  TinJt = 'tin_jt',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  TitleIdJt = 'title_id_jt',
  /** column name */
  WelcomePackSentAt = 'welcome_pack_sent_at',
  /** column name */
  WelcomePackUrl = 'welcome_pack_url',
  /** column name */
  YearsOfCurrentEmployment = 'years_of_current_employment',
  /** column name */
  YearsOfCurrentEmploymentJt = 'years_of_current_employment_jt',
  /** column name */
  YearsOfEmployment = 'years_of_employment',
  /** column name */
  YearsOfEmploymentJt = 'years_of_employment_jt',
  /** column name */
  YearsOfPreviousEmployment = 'years_of_previous_employment',
  /** column name */
  YearsOfPreviousEmploymentJt = 'years_of_previous_employment_jt'
}

/** input type for updating data in table "member" */
export type Member_Set_Input = {
  account_mandate?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  anticipated_investment_activity_top_ups?: Maybe<Scalars['String']>;
  anticipated_investment_activity_top_ups_other?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals?: Maybe<Scalars['String']>;
  anticipated_investment_activity_withdrawals_other?: Maybe<Scalars['String']>;
  approved_on?: Maybe<Scalars['timestamp']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  city?: Maybe<Scalars['String']>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  current_savings?: Maybe<Scalars['numeric']>;
  employer?: Maybe<Scalars['String']>;
  employer_address?: Maybe<Scalars['String']>;
  employer_address_jt?: Maybe<Scalars['String']>;
  employer_city?: Maybe<Scalars['String']>;
  employer_city_jt?: Maybe<Scalars['String']>;
  employer_contact?: Maybe<Scalars['String']>;
  employer_contact_jt?: Maybe<Scalars['String']>;
  employer_email?: Maybe<Scalars['String']>;
  employer_email_jt?: Maybe<Scalars['String']>;
  employer_jt?: Maybe<Scalars['String']>;
  employment_status?: Maybe<Scalars['String']>;
  employment_status_jt?: Maybe<Scalars['String']>;
  gps_address?: Maybe<Scalars['String']>;
  gps_address_jt?: Maybe<Scalars['String']>;
  home_address?: Maybe<Scalars['String']>;
  home_address_jt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date_jt?: Maybe<Scalars['date']>;
  id_issue_date_jt?: Maybe<Scalars['date']>;
  id_number_jt?: Maybe<Scalars['String']>;
  id_type_jt?: Maybe<Scalars['String']>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  is_customer?: Maybe<Scalars['Boolean']>;
  is_retired?: Maybe<Scalars['Boolean']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  mf_code?: Maybe<Scalars['String']>;
  mobile_secondary?: Maybe<Scalars['String']>;
  mobile_secondary_jt?: Maybe<Scalars['String']>;
  mode_of_statement_delivery?: Maybe<Scalars['String']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  nationality?: Maybe<Scalars['String']>;
  nature_of_business?: Maybe<Scalars['String']>;
  nature_of_business_jt?: Maybe<Scalars['String']>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  occupation_jt?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_expiry_date_jt?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  permit_issue_date_jt?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  place_of_birth_jt?: Maybe<Scalars['String']>;
  place_of_issue_jt?: Maybe<Scalars['String']>;
  postal_address?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  profession_jt?: Maybe<Scalars['String']>;
  professional_license_number?: Maybe<Scalars['String']>;
  professional_license_number_jt?: Maybe<Scalars['String']>;
  psl_code?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  relationship_jt?: Maybe<Scalars['String']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_permit_number_jt?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_place_of_issue_jt?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  residential_status_jt?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  statement_frequency?: Maybe<Scalars['String']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  tin?: Maybe<Scalars['String']>;
  tin_jt?: Maybe<Scalars['String']>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  welcome_pack_sent_at?: Maybe<Scalars['timestamp']>;
  welcome_pack_url?: Maybe<Scalars['String']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Member_Stddev_Fields = {
  __typename?: 'member_stddev_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "member" */
export type Member_Stddev_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Member_Stddev_Pop_Fields = {
  __typename?: 'member_stddev_pop_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "member" */
export type Member_Stddev_Pop_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Member_Stddev_Samp_Fields = {
  __typename?: 'member_stddev_samp_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "member" */
export type Member_Stddev_Samp_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Member_Sum_Fields = {
  __typename?: 'member_sum_fields';
  age?: Maybe<Scalars['Int']>;
  assigned_to?: Maybe<Scalars['bigint']>;
  client_investment_profile_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  country_of_origin_id_jt?: Maybe<Scalars['bigint']>;
  country_of_residence_id?: Maybe<Scalars['bigint']>;
  country_of_residence_id_jt?: Maybe<Scalars['bigint']>;
  current_savings?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  industry_id?: Maybe<Scalars['bigint']>;
  initial_investment_amount?: Maybe<Scalars['numeric']>;
  marital_status_id?: Maybe<Scalars['bigint']>;
  marital_status_id_jt?: Maybe<Scalars['bigint']>;
  monthly_income?: Maybe<Scalars['numeric']>;
  next_of_kin_id?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  regular_top_up_amount?: Maybe<Scalars['numeric']>;
  regular_withdrawal_amount?: Maybe<Scalars['numeric']>;
  sequence?: Maybe<Scalars['Int']>;
  statement_service_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  title_id?: Maybe<Scalars['bigint']>;
  title_id_jt?: Maybe<Scalars['bigint']>;
  years_of_current_employment?: Maybe<Scalars['numeric']>;
  years_of_current_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_employment?: Maybe<Scalars['numeric']>;
  years_of_employment_jt?: Maybe<Scalars['numeric']>;
  years_of_previous_employment?: Maybe<Scalars['numeric']>;
  years_of_previous_employment_jt?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "member" */
export type Member_Sum_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** update columns of table "member" */
export enum Member_Update_Column {
  /** column name */
  AccountMandate = 'account_mandate',
  /** column name */
  AccountType = 'account_type',
  /** column name */
  Age = 'age',
  /** column name */
  AnticipatedInvestmentActivityTopUps = 'anticipated_investment_activity_top_ups',
  /** column name */
  AnticipatedInvestmentActivityTopUpsOther = 'anticipated_investment_activity_top_ups_other',
  /** column name */
  AnticipatedInvestmentActivityWithdrawals = 'anticipated_investment_activity_withdrawals',
  /** column name */
  AnticipatedInvestmentActivityWithdrawalsOther = 'anticipated_investment_activity_withdrawals_other',
  /** column name */
  ApprovedOn = 'approved_on',
  /** column name */
  AssignedTo = 'assigned_to',
  /** column name */
  City = 'city',
  /** column name */
  ClientInvestmentProfileId = 'client_investment_profile_id',
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CountryOfOriginIdJt = 'country_of_origin_id_jt',
  /** column name */
  CountryOfResidenceId = 'country_of_residence_id',
  /** column name */
  CountryOfResidenceIdJt = 'country_of_residence_id_jt',
  /** column name */
  CurrentSavings = 'current_savings',
  /** column name */
  Employer = 'employer',
  /** column name */
  EmployerAddress = 'employer_address',
  /** column name */
  EmployerAddressJt = 'employer_address_jt',
  /** column name */
  EmployerCity = 'employer_city',
  /** column name */
  EmployerCityJt = 'employer_city_jt',
  /** column name */
  EmployerContact = 'employer_contact',
  /** column name */
  EmployerContactJt = 'employer_contact_jt',
  /** column name */
  EmployerEmail = 'employer_email',
  /** column name */
  EmployerEmailJt = 'employer_email_jt',
  /** column name */
  EmployerJt = 'employer_jt',
  /** column name */
  EmploymentStatus = 'employment_status',
  /** column name */
  EmploymentStatusJt = 'employment_status_jt',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  GpsAddressJt = 'gps_address_jt',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  HomeAddressJt = 'home_address_jt',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDateJt = 'id_expiry_date_jt',
  /** column name */
  IdIssueDateJt = 'id_issue_date_jt',
  /** column name */
  IdNumberJt = 'id_number_jt',
  /** column name */
  IdTypeJt = 'id_type_jt',
  /** column name */
  IndustryId = 'industry_id',
  /** column name */
  InitialInvestmentAmount = 'initial_investment_amount',
  /** column name */
  IsCustomer = 'is_customer',
  /** column name */
  IsRetired = 'is_retired',
  /** column name */
  MaritalStatusId = 'marital_status_id',
  /** column name */
  MaritalStatusIdJt = 'marital_status_id_jt',
  /** column name */
  MfCode = 'mf_code',
  /** column name */
  MobileSecondary = 'mobile_secondary',
  /** column name */
  MobileSecondaryJt = 'mobile_secondary_jt',
  /** column name */
  ModeOfStatementDelivery = 'mode_of_statement_delivery',
  /** column name */
  MonthlyIncome = 'monthly_income',
  /** column name */
  Nationality = 'nationality',
  /** column name */
  NatureOfBusiness = 'nature_of_business',
  /** column name */
  NatureOfBusinessJt = 'nature_of_business_jt',
  /** column name */
  NextOfKinId = 'next_of_kin_id',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OccupationJt = 'occupation_jt',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitExpiryDateJt = 'permit_expiry_date_jt',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PermitIssueDateJt = 'permit_issue_date_jt',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  PlaceOfBirthJt = 'place_of_birth_jt',
  /** column name */
  PlaceOfIssueJt = 'place_of_issue_jt',
  /** column name */
  PostalAddress = 'postal_address',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionJt = 'profession_jt',
  /** column name */
  ProfessionalLicenseNumber = 'professional_license_number',
  /** column name */
  ProfessionalLicenseNumberJt = 'professional_license_number_jt',
  /** column name */
  PslCode = 'psl_code',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RegularTopUpAmount = 'regular_top_up_amount',
  /** column name */
  RegularWithdrawalAmount = 'regular_withdrawal_amount',
  /** column name */
  RelationshipJt = 'relationship_jt',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPermitNumberJt = 'residential_permit_number_jt',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialPlaceOfIssueJt = 'residential_place_of_issue_jt',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  ResidentialStatusJt = 'residential_status_jt',
  /** column name */
  Sequence = 'sequence',
  /** column name */
  Signature = 'signature',
  /** column name */
  StatementFrequency = 'statement_frequency',
  /** column name */
  StatementServiceId = 'statement_service_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  TinJt = 'tin_jt',
  /** column name */
  TitleId = 'title_id',
  /** column name */
  TitleIdJt = 'title_id_jt',
  /** column name */
  WelcomePackSentAt = 'welcome_pack_sent_at',
  /** column name */
  WelcomePackUrl = 'welcome_pack_url',
  /** column name */
  YearsOfCurrentEmployment = 'years_of_current_employment',
  /** column name */
  YearsOfCurrentEmploymentJt = 'years_of_current_employment_jt',
  /** column name */
  YearsOfEmployment = 'years_of_employment',
  /** column name */
  YearsOfEmploymentJt = 'years_of_employment_jt',
  /** column name */
  YearsOfPreviousEmployment = 'years_of_previous_employment',
  /** column name */
  YearsOfPreviousEmploymentJt = 'years_of_previous_employment_jt'
}

/** aggregate var_pop on columns */
export type Member_Var_Pop_Fields = {
  __typename?: 'member_var_pop_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "member" */
export type Member_Var_Pop_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Member_Var_Samp_Fields = {
  __typename?: 'member_var_samp_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "member" */
export type Member_Var_Samp_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Member_Variance_Fields = {
  __typename?: 'member_variance_fields';
  age?: Maybe<Scalars['Float']>;
  assigned_to?: Maybe<Scalars['Float']>;
  client_investment_profile_id?: Maybe<Scalars['Float']>;
  country_of_origin_id?: Maybe<Scalars['Float']>;
  country_of_origin_id_jt?: Maybe<Scalars['Float']>;
  country_of_residence_id?: Maybe<Scalars['Float']>;
  country_of_residence_id_jt?: Maybe<Scalars['Float']>;
  current_savings?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  industry_id?: Maybe<Scalars['Float']>;
  initial_investment_amount?: Maybe<Scalars['Float']>;
  marital_status_id?: Maybe<Scalars['Float']>;
  marital_status_id_jt?: Maybe<Scalars['Float']>;
  monthly_income?: Maybe<Scalars['Float']>;
  next_of_kin_id?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  regular_top_up_amount?: Maybe<Scalars['Float']>;
  regular_withdrawal_amount?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  statement_service_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  title_id?: Maybe<Scalars['Float']>;
  title_id_jt?: Maybe<Scalars['Float']>;
  years_of_current_employment?: Maybe<Scalars['Float']>;
  years_of_current_employment_jt?: Maybe<Scalars['Float']>;
  years_of_employment?: Maybe<Scalars['Float']>;
  years_of_employment_jt?: Maybe<Scalars['Float']>;
  years_of_previous_employment?: Maybe<Scalars['Float']>;
  years_of_previous_employment_jt?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "member" */
export type Member_Variance_Order_By = {
  age?: Maybe<Order_By>;
  assigned_to?: Maybe<Order_By>;
  client_investment_profile_id?: Maybe<Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  country_of_origin_id_jt?: Maybe<Order_By>;
  country_of_residence_id?: Maybe<Order_By>;
  country_of_residence_id_jt?: Maybe<Order_By>;
  current_savings?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry_id?: Maybe<Order_By>;
  initial_investment_amount?: Maybe<Order_By>;
  marital_status_id?: Maybe<Order_By>;
  marital_status_id_jt?: Maybe<Order_By>;
  monthly_income?: Maybe<Order_By>;
  next_of_kin_id?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  regular_top_up_amount?: Maybe<Order_By>;
  regular_withdrawal_amount?: Maybe<Order_By>;
  sequence?: Maybe<Order_By>;
  statement_service_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  title_id?: Maybe<Order_By>;
  title_id_jt?: Maybe<Order_By>;
  years_of_current_employment?: Maybe<Order_By>;
  years_of_current_employment_jt?: Maybe<Order_By>;
  years_of_employment?: Maybe<Order_By>;
  years_of_employment_jt?: Maybe<Order_By>;
  years_of_previous_employment?: Maybe<Order_By>;
  years_of_previous_employment_jt?: Maybe<Order_By>;
};

/** columns and relationships of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication = {
  __typename?: 'mode_of_instruction_communication';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_mode_of_instruction_communications: Array<Member_Mode_Of_Instruction_Communication>;
  /** An aggregated array relationship */
  member_mode_of_instruction_communications_aggregate: Member_Mode_Of_Instruction_Communication_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_CommunicationMember_Mode_Of_Instruction_CommunicationsArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** columns and relationships of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_CommunicationMember_Mode_Of_Instruction_Communications_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};

/** aggregated selection of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Aggregate = {
  __typename?: 'mode_of_instruction_communication_aggregate';
  aggregate?: Maybe<Mode_Of_Instruction_Communication_Aggregate_Fields>;
  nodes: Array<Mode_Of_Instruction_Communication>;
};

/** aggregate fields of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Aggregate_Fields = {
  __typename?: 'mode_of_instruction_communication_aggregate_fields';
  avg?: Maybe<Mode_Of_Instruction_Communication_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Mode_Of_Instruction_Communication_Max_Fields>;
  min?: Maybe<Mode_Of_Instruction_Communication_Min_Fields>;
  stddev?: Maybe<Mode_Of_Instruction_Communication_Stddev_Fields>;
  stddev_pop?: Maybe<Mode_Of_Instruction_Communication_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mode_Of_Instruction_Communication_Stddev_Samp_Fields>;
  sum?: Maybe<Mode_Of_Instruction_Communication_Sum_Fields>;
  var_pop?: Maybe<Mode_Of_Instruction_Communication_Var_Pop_Fields>;
  var_samp?: Maybe<Mode_Of_Instruction_Communication_Var_Samp_Fields>;
  variance?: Maybe<Mode_Of_Instruction_Communication_Variance_Fields>;
};


/** aggregate fields of "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mode_Of_Instruction_Communication_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Aggregate_Order_By = {
  avg?: Maybe<Mode_Of_Instruction_Communication_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mode_Of_Instruction_Communication_Max_Order_By>;
  min?: Maybe<Mode_Of_Instruction_Communication_Min_Order_By>;
  stddev?: Maybe<Mode_Of_Instruction_Communication_Stddev_Order_By>;
  stddev_pop?: Maybe<Mode_Of_Instruction_Communication_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mode_Of_Instruction_Communication_Stddev_Samp_Order_By>;
  sum?: Maybe<Mode_Of_Instruction_Communication_Sum_Order_By>;
  var_pop?: Maybe<Mode_Of_Instruction_Communication_Var_Pop_Order_By>;
  var_samp?: Maybe<Mode_Of_Instruction_Communication_Var_Samp_Order_By>;
  variance?: Maybe<Mode_Of_Instruction_Communication_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Arr_Rel_Insert_Input = {
  data: Array<Mode_Of_Instruction_Communication_Insert_Input>;
  on_conflict?: Maybe<Mode_Of_Instruction_Communication_On_Conflict>;
};

/** aggregate avg on columns */
export type Mode_Of_Instruction_Communication_Avg_Fields = {
  __typename?: 'mode_of_instruction_communication_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mode_of_instruction_communication". All fields are combined with a logical 'AND'. */
export type Mode_Of_Instruction_Communication_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Mode_Of_Instruction_Communication_Bool_Exp>>>;
  _not?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mode_Of_Instruction_Communication_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_mode_of_instruction_communications?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mode_of_instruction_communication" */
export enum Mode_Of_Instruction_Communication_Constraint {
  /** unique or primary key constraint */
  ModeOfInstructionCommunicationNameKey = 'mode_of_instruction_communication_name_key',
  /** unique or primary key constraint */
  ModeOfInstructionCommunicationPkey = 'mode_of_instruction_communication_pkey'
}

/** input type for incrementing integer column in table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_mode_of_instruction_communications?: Maybe<Member_Mode_Of_Instruction_Communication_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mode_Of_Instruction_Communication_Max_Fields = {
  __typename?: 'mode_of_instruction_communication_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mode_Of_Instruction_Communication_Min_Fields = {
  __typename?: 'mode_of_instruction_communication_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Mutation_Response = {
  __typename?: 'mode_of_instruction_communication_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Mode_Of_Instruction_Communication>;
};

/** input type for inserting object relation for remote table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Obj_Rel_Insert_Input = {
  data: Mode_Of_Instruction_Communication_Insert_Input;
  on_conflict?: Maybe<Mode_Of_Instruction_Communication_On_Conflict>;
};

/** on conflict condition type for table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_On_Conflict = {
  constraint: Mode_Of_Instruction_Communication_Constraint;
  update_columns: Array<Mode_Of_Instruction_Communication_Update_Column>;
  where?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
};

/** ordering options when selecting data from "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_mode_of_instruction_communications_aggregate?: Maybe<Member_Mode_Of_Instruction_Communication_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "mode_of_instruction_communication" */
export enum Mode_Of_Instruction_Communication_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mode_Of_Instruction_Communication_Stddev_Fields = {
  __typename?: 'mode_of_instruction_communication_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mode_Of_Instruction_Communication_Stddev_Pop_Fields = {
  __typename?: 'mode_of_instruction_communication_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mode_Of_Instruction_Communication_Stddev_Samp_Fields = {
  __typename?: 'mode_of_instruction_communication_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mode_Of_Instruction_Communication_Sum_Fields = {
  __typename?: 'mode_of_instruction_communication_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "mode_of_instruction_communication" */
export enum Mode_Of_Instruction_Communication_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Mode_Of_Instruction_Communication_Var_Pop_Fields = {
  __typename?: 'mode_of_instruction_communication_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mode_Of_Instruction_Communication_Var_Samp_Fields = {
  __typename?: 'mode_of_instruction_communication_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mode_Of_Instruction_Communication_Variance_Fields = {
  __typename?: 'mode_of_instruction_communication_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mode_of_instruction_communication" */
export type Mode_Of_Instruction_Communication_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "mp_product_split" */
export type Mp_Product_Split = {
  __typename?: 'mp_product_split';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  payment?: Maybe<Payment>;
  payment_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "mp_product_split" */
export type Mp_Product_Split_Aggregate = {
  __typename?: 'mp_product_split_aggregate';
  aggregate?: Maybe<Mp_Product_Split_Aggregate_Fields>;
  nodes: Array<Mp_Product_Split>;
};

/** aggregate fields of "mp_product_split" */
export type Mp_Product_Split_Aggregate_Fields = {
  __typename?: 'mp_product_split_aggregate_fields';
  avg?: Maybe<Mp_Product_Split_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Mp_Product_Split_Max_Fields>;
  min?: Maybe<Mp_Product_Split_Min_Fields>;
  stddev?: Maybe<Mp_Product_Split_Stddev_Fields>;
  stddev_pop?: Maybe<Mp_Product_Split_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mp_Product_Split_Stddev_Samp_Fields>;
  sum?: Maybe<Mp_Product_Split_Sum_Fields>;
  var_pop?: Maybe<Mp_Product_Split_Var_Pop_Fields>;
  var_samp?: Maybe<Mp_Product_Split_Var_Samp_Fields>;
  variance?: Maybe<Mp_Product_Split_Variance_Fields>;
};


/** aggregate fields of "mp_product_split" */
export type Mp_Product_Split_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mp_product_split" */
export type Mp_Product_Split_Aggregate_Order_By = {
  avg?: Maybe<Mp_Product_Split_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Mp_Product_Split_Max_Order_By>;
  min?: Maybe<Mp_Product_Split_Min_Order_By>;
  stddev?: Maybe<Mp_Product_Split_Stddev_Order_By>;
  stddev_pop?: Maybe<Mp_Product_Split_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Mp_Product_Split_Stddev_Samp_Order_By>;
  sum?: Maybe<Mp_Product_Split_Sum_Order_By>;
  var_pop?: Maybe<Mp_Product_Split_Var_Pop_Order_By>;
  var_samp?: Maybe<Mp_Product_Split_Var_Samp_Order_By>;
  variance?: Maybe<Mp_Product_Split_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "mp_product_split" */
export type Mp_Product_Split_Arr_Rel_Insert_Input = {
  data: Array<Mp_Product_Split_Insert_Input>;
  on_conflict?: Maybe<Mp_Product_Split_On_Conflict>;
};

/** aggregate avg on columns */
export type Mp_Product_Split_Avg_Fields = {
  __typename?: 'mp_product_split_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mp_product_split" */
export type Mp_Product_Split_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mp_product_split". All fields are combined with a logical 'AND'. */
export type Mp_Product_Split_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Mp_Product_Split_Bool_Exp>>>;
  _not?: Maybe<Mp_Product_Split_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Mp_Product_Split_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  payment?: Maybe<Payment_Bool_Exp>;
  payment_id?: Maybe<Bigint_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "mp_product_split" */
export enum Mp_Product_Split_Constraint {
  /** unique or primary key constraint */
  MpProductSplitPkey = 'mp_product_split_pkey'
}

/** input type for incrementing integer column in table "mp_product_split" */
export type Mp_Product_Split_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "mp_product_split" */
export type Mp_Product_Split_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment?: Maybe<Payment_Obj_Rel_Insert_Input>;
  payment_id?: Maybe<Scalars['bigint']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Mp_Product_Split_Max_Fields = {
  __typename?: 'mp_product_split_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "mp_product_split" */
export type Mp_Product_Split_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Mp_Product_Split_Min_Fields = {
  __typename?: 'mp_product_split_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "mp_product_split" */
export type Mp_Product_Split_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "mp_product_split" */
export type Mp_Product_Split_Mutation_Response = {
  __typename?: 'mp_product_split_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Mp_Product_Split>;
};

/** input type for inserting object relation for remote table "mp_product_split" */
export type Mp_Product_Split_Obj_Rel_Insert_Input = {
  data: Mp_Product_Split_Insert_Input;
  on_conflict?: Maybe<Mp_Product_Split_On_Conflict>;
};

/** on conflict condition type for table "mp_product_split" */
export type Mp_Product_Split_On_Conflict = {
  constraint: Mp_Product_Split_Constraint;
  update_columns: Array<Mp_Product_Split_Update_Column>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};

/** ordering options when selecting data from "mp_product_split" */
export type Mp_Product_Split_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment?: Maybe<Payment_Order_By>;
  payment_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "mp_product_split" */
export type Mp_Product_Split_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "mp_product_split" */
export enum Mp_Product_Split_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "mp_product_split" */
export type Mp_Product_Split_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Mp_Product_Split_Stddev_Fields = {
  __typename?: 'mp_product_split_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mp_product_split" */
export type Mp_Product_Split_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mp_Product_Split_Stddev_Pop_Fields = {
  __typename?: 'mp_product_split_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mp_product_split" */
export type Mp_Product_Split_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mp_Product_Split_Stddev_Samp_Fields = {
  __typename?: 'mp_product_split_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mp_product_split" */
export type Mp_Product_Split_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Mp_Product_Split_Sum_Fields = {
  __typename?: 'mp_product_split_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "mp_product_split" */
export type Mp_Product_Split_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** update columns of table "mp_product_split" */
export enum Mp_Product_Split_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Mp_Product_Split_Var_Pop_Fields = {
  __typename?: 'mp_product_split_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mp_product_split" */
export type Mp_Product_Split_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mp_Product_Split_Var_Samp_Fields = {
  __typename?: 'mp_product_split_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mp_product_split" */
export type Mp_Product_Split_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Mp_Product_Split_Variance_Fields = {
  __typename?: 'mp_product_split_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mp_product_split" */
export type Mp_Product_Split_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  approvePayments: ApprovePaymentResponse;
  calculateExpectedReturn: ExpectedReturnResponse;
  calculateGoalBalances: GoalBalancesResponse;
  calculateGoalContributions: GoalContributionsResponse;
  calculateInsurancePremium: InsurancePremiumResponse;
  calculateTotalBalance: TotalBalanceResponse;
  calculateTotalContributions: ContributionsResponse;
  createCustomer: CreateCustomerResponse;
  createDeletePermission: PermissionResponse;
  createInsertPermission: PermissionResponse;
  createSelectPermission: PermissionResponse;
  createUpdatePermission: PermissionResponse;
  /** delete data from the table: "advisor" */
  delete_advisor?: Maybe<Advisor_Mutation_Response>;
  /** delete single row from the table: "advisor" */
  delete_advisor_by_pk?: Maybe<Advisor>;
  /** delete data from the table: "all_tables_template" */
  delete_all_tables_template?: Maybe<All_Tables_Template_Mutation_Response>;
  /** delete single row from the table: "all_tables_template" */
  delete_all_tables_template_by_pk?: Maybe<All_Tables_Template>;
  /** delete data from the table: "apk" */
  delete_apk?: Maybe<Apk_Mutation_Response>;
  /** delete single row from the table: "apk" */
  delete_apk_by_pk?: Maybe<Apk>;
  /** delete data from the table: "app_config" */
  delete_app_config?: Maybe<App_Config_Mutation_Response>;
  /** delete single row from the table: "app_config" */
  delete_app_config_by_pk?: Maybe<App_Config>;
  /** delete data from the table: "asset_allocation" */
  delete_asset_allocation?: Maybe<Asset_Allocation_Mutation_Response>;
  /** delete single row from the table: "asset_allocation" */
  delete_asset_allocation_by_pk?: Maybe<Asset_Allocation>;
  /** delete data from the table: "asset_duration" */
  delete_asset_duration?: Maybe<Asset_Duration_Mutation_Response>;
  /** delete single row from the table: "asset_duration" */
  delete_asset_duration_by_pk?: Maybe<Asset_Duration>;
  /** delete data from the table: "audit_log" */
  delete_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** delete single row from the table: "audit_log" */
  delete_audit_log_by_pk?: Maybe<Audit_Log>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "background_notifications" */
  delete_background_notifications?: Maybe<Background_Notifications_Mutation_Response>;
  /** delete single row from the table: "background_notifications" */
  delete_background_notifications_by_pk?: Maybe<Background_Notifications>;
  /** delete data from the table: "branch" */
  delete_branch?: Maybe<Branch_Mutation_Response>;
  /** delete single row from the table: "branch" */
  delete_branch_by_pk?: Maybe<Branch>;
  /** delete data from the table: "client_investment_profile" */
  delete_client_investment_profile?: Maybe<Client_Investment_Profile_Mutation_Response>;
  /** delete single row from the table: "client_investment_profile" */
  delete_client_investment_profile_by_pk?: Maybe<Client_Investment_Profile>;
  /** delete data from the table: "contribution_source" */
  delete_contribution_source?: Maybe<Contribution_Source_Mutation_Response>;
  /** delete single row from the table: "contribution_source" */
  delete_contribution_source_by_pk?: Maybe<Contribution_Source>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "deal" */
  delete_deal?: Maybe<Deal_Mutation_Response>;
  /** delete single row from the table: "deal" */
  delete_deal_by_pk?: Maybe<Deal>;
  /** delete data from the table: "deal_share" */
  delete_deal_share?: Maybe<Deal_Share_Mutation_Response>;
  /** delete single row from the table: "deal_share" */
  delete_deal_share_by_pk?: Maybe<Deal_Share>;
  /** delete data from the table: "direct_debit_charge" */
  delete_direct_debit_charge?: Maybe<Direct_Debit_Charge_Mutation_Response>;
  /** delete single row from the table: "direct_debit_charge" */
  delete_direct_debit_charge_by_pk?: Maybe<Direct_Debit_Charge>;
  /** delete data from the table: "economic_indicator" */
  delete_economic_indicator?: Maybe<Economic_Indicator_Mutation_Response>;
  /** delete single row from the table: "economic_indicator" */
  delete_economic_indicator_by_pk?: Maybe<Economic_Indicator>;
  /** delete data from the table: "expected_return" */
  delete_expected_return?: Maybe<Expected_Return_Mutation_Response>;
  /** delete single row from the table: "expected_return" */
  delete_expected_return_by_pk?: Maybe<Expected_Return>;
  /** delete data from the table: "gender" */
  delete_gender?: Maybe<Gender_Mutation_Response>;
  /** delete single row from the table: "gender" */
  delete_gender_by_pk?: Maybe<Gender>;
  /** delete data from the table: "goal" */
  delete_goal?: Maybe<Goal_Mutation_Response>;
  /** delete single row from the table: "goal" */
  delete_goal_by_pk?: Maybe<Goal>;
  /** delete data from the table: "goal_insurance" */
  delete_goal_insurance?: Maybe<Goal_Insurance_Mutation_Response>;
  /** delete single row from the table: "goal_insurance" */
  delete_goal_insurance_by_pk?: Maybe<Goal_Insurance>;
  /** delete data from the table: "goal_product" */
  delete_goal_product?: Maybe<Goal_Product_Mutation_Response>;
  /** delete single row from the table: "goal_product" */
  delete_goal_product_by_pk?: Maybe<Goal_Product>;
  /** delete data from the table: "hmd_education" */
  delete_hmd_education?: Maybe<Hmd_Education_Mutation_Response>;
  /** delete single row from the table: "hmd_education" */
  delete_hmd_education_by_pk?: Maybe<Hmd_Education>;
  /** delete data from the table: "hmd_expense_base" */
  delete_hmd_expense_base?: Maybe<Hmd_Expense_Base_Mutation_Response>;
  /** delete single row from the table: "hmd_expense_base" */
  delete_hmd_expense_base_by_pk?: Maybe<Hmd_Expense_Base>;
  /** delete data from the table: "hmd_expense_category" */
  delete_hmd_expense_category?: Maybe<Hmd_Expense_Category_Mutation_Response>;
  /** delete single row from the table: "hmd_expense_category" */
  delete_hmd_expense_category_by_pk?: Maybe<Hmd_Expense_Category>;
  /** delete data from the table: "hmd_expense_multiplier" */
  delete_hmd_expense_multiplier?: Maybe<Hmd_Expense_Multiplier_Mutation_Response>;
  /** delete single row from the table: "hmd_expense_multiplier" */
  delete_hmd_expense_multiplier_by_pk?: Maybe<Hmd_Expense_Multiplier>;
  /** delete data from the table: "hmd_lifestyle" */
  delete_hmd_lifestyle?: Maybe<Hmd_Lifestyle_Mutation_Response>;
  /** delete single row from the table: "hmd_lifestyle" */
  delete_hmd_lifestyle_by_pk?: Maybe<Hmd_Lifestyle>;
  /** delete data from the table: "identification_type" */
  delete_identification_type?: Maybe<Identification_Type_Mutation_Response>;
  /** delete single row from the table: "identification_type" */
  delete_identification_type_by_pk?: Maybe<Identification_Type>;
  /** delete data from the table: "income_source" */
  delete_income_source?: Maybe<Income_Source_Mutation_Response>;
  /** delete single row from the table: "income_source" */
  delete_income_source_by_pk?: Maybe<Income_Source>;
  /** delete data from the table: "industry" */
  delete_industry?: Maybe<Industry_Mutation_Response>;
  /** delete single row from the table: "industry" */
  delete_industry_by_pk?: Maybe<Industry>;
  /** delete data from the table: "insurance_rate_age_range" */
  delete_insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Mutation_Response>;
  /** delete single row from the table: "insurance_rate_age_range" */
  delete_insurance_rate_age_range_by_pk?: Maybe<Insurance_Rate_Age_Range>;
  /** delete data from the table: "insurance_rate_duration" */
  delete_insurance_rate_duration?: Maybe<Insurance_Rate_Duration_Mutation_Response>;
  /** delete single row from the table: "insurance_rate_duration" */
  delete_insurance_rate_duration_by_pk?: Maybe<Insurance_Rate_Duration>;
  /** delete data from the table: "insurance_type" */
  delete_insurance_type?: Maybe<Insurance_Type_Mutation_Response>;
  /** delete single row from the table: "insurance_type" */
  delete_insurance_type_by_pk?: Maybe<Insurance_Type>;
  /** delete data from the table: "marital_status" */
  delete_marital_status?: Maybe<Marital_Status_Mutation_Response>;
  /** delete single row from the table: "marital_status" */
  delete_marital_status_by_pk?: Maybe<Marital_Status>;
  /** delete data from the table: "member" */
  delete_member?: Maybe<Member_Mutation_Response>;
  /** delete data from the table: "member_beneficiary" */
  delete_member_beneficiary?: Maybe<Member_Beneficiary_Mutation_Response>;
  /** delete single row from the table: "member_beneficiary" */
  delete_member_beneficiary_by_pk?: Maybe<Member_Beneficiary>;
  /** delete single row from the table: "member" */
  delete_member_by_pk?: Maybe<Member>;
  /** delete data from the table: "member_contribution_source" */
  delete_member_contribution_source?: Maybe<Member_Contribution_Source_Mutation_Response>;
  /** delete single row from the table: "member_contribution_source" */
  delete_member_contribution_source_by_pk?: Maybe<Member_Contribution_Source>;
  /** delete data from the table: "member_dependent" */
  delete_member_dependent?: Maybe<Member_Dependent_Mutation_Response>;
  /** delete single row from the table: "member_dependent" */
  delete_member_dependent_by_pk?: Maybe<Member_Dependent>;
  /** delete data from the table: "member_goal" */
  delete_member_goal?: Maybe<Member_Goal_Mutation_Response>;
  /** delete single row from the table: "member_goal" */
  delete_member_goal_by_pk?: Maybe<Member_Goal>;
  /** delete data from the table: "member_identification" */
  delete_member_identification?: Maybe<Member_Identification_Mutation_Response>;
  /** delete single row from the table: "member_identification" */
  delete_member_identification_by_pk?: Maybe<Member_Identification>;
  /** delete data from the table: "member_in_trust_for" */
  delete_member_in_trust_for?: Maybe<Member_In_Trust_For_Mutation_Response>;
  /** delete single row from the table: "member_in_trust_for" */
  delete_member_in_trust_for_by_pk?: Maybe<Member_In_Trust_For>;
  /** delete data from the table: "member_income_source" */
  delete_member_income_source?: Maybe<Member_Income_Source_Mutation_Response>;
  /** delete single row from the table: "member_income_source" */
  delete_member_income_source_by_pk?: Maybe<Member_Income_Source>;
  /** delete data from the table: "member_mode_of_instruction_communication" */
  delete_member_mode_of_instruction_communication?: Maybe<Member_Mode_Of_Instruction_Communication_Mutation_Response>;
  /** delete single row from the table: "member_mode_of_instruction_communication" */
  delete_member_mode_of_instruction_communication_by_pk?: Maybe<Member_Mode_Of_Instruction_Communication>;
  /** delete data from the table: "member_payment_instruction" */
  delete_member_payment_instruction?: Maybe<Member_Payment_Instruction_Mutation_Response>;
  /** delete single row from the table: "member_payment_instruction" */
  delete_member_payment_instruction_by_pk?: Maybe<Member_Payment_Instruction>;
  /** delete data from the table: "member_redemption" */
  delete_member_redemption?: Maybe<Member_Redemption_Mutation_Response>;
  /** delete single row from the table: "member_redemption" */
  delete_member_redemption_by_pk?: Maybe<Member_Redemption>;
  /** delete data from the table: "member_reject" */
  delete_member_reject?: Maybe<Member_Reject_Mutation_Response>;
  /** delete single row from the table: "member_reject" */
  delete_member_reject_by_pk?: Maybe<Member_Reject>;
  /** delete data from the table: "mode_of_instruction_communication" */
  delete_mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Mutation_Response>;
  /** delete single row from the table: "mode_of_instruction_communication" */
  delete_mode_of_instruction_communication_by_pk?: Maybe<Mode_Of_Instruction_Communication>;
  /** delete data from the table: "mp_product_split" */
  delete_mp_product_split?: Maybe<Mp_Product_Split_Mutation_Response>;
  /** delete single row from the table: "mp_product_split" */
  delete_mp_product_split_by_pk?: Maybe<Mp_Product_Split>;
  /** delete data from the table: "next_of_kin" */
  delete_next_of_kin?: Maybe<Next_Of_Kin_Mutation_Response>;
  /** delete single row from the table: "next_of_kin" */
  delete_next_of_kin_by_pk?: Maybe<Next_Of_Kin>;
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "payment_channel" */
  delete_payment_channel?: Maybe<Payment_Channel_Mutation_Response>;
  /** delete single row from the table: "payment_channel" */
  delete_payment_channel_by_pk?: Maybe<Payment_Channel>;
  /** delete data from the table: "payment_mode" */
  delete_payment_mode?: Maybe<Payment_Mode_Mutation_Response>;
  /** delete single row from the table: "payment_mode" */
  delete_payment_mode_by_pk?: Maybe<Payment_Mode>;
  /** delete data from the table: "payment_provider" */
  delete_payment_provider?: Maybe<Payment_Provider_Mutation_Response>;
  /** delete single row from the table: "payment_provider" */
  delete_payment_provider_by_pk?: Maybe<Payment_Provider>;
  /** delete data from the table: "payment_share" */
  delete_payment_share?: Maybe<Payment_Share_Mutation_Response>;
  /** delete single row from the table: "payment_share" */
  delete_payment_share_by_pk?: Maybe<Payment_Share>;
  /** delete data from the table: "permission" */
  delete_permission?: Maybe<Permission_Mutation_Response>;
  /** delete single row from the table: "permission" */
  delete_permission_by_pk?: Maybe<Permission>;
  /** delete data from the table: "price" */
  delete_price?: Maybe<Price_Mutation_Response>;
  /** delete single row from the table: "price" */
  delete_price_by_pk?: Maybe<Price>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "redemption_share" */
  delete_redemption_share?: Maybe<Redemption_Share_Mutation_Response>;
  /** delete single row from the table: "redemption_share" */
  delete_redemption_share_by_pk?: Maybe<Redemption_Share>;
  /** delete data from the table: "region" */
  delete_region?: Maybe<Region_Mutation_Response>;
  /** delete single row from the table: "region" */
  delete_region_by_pk?: Maybe<Region>;
  /** delete data from the table: "relationship" */
  delete_relationship?: Maybe<Relationship_Mutation_Response>;
  /** delete single row from the table: "relationship" */
  delete_relationship_by_pk?: Maybe<Relationship>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "role_permission" */
  delete_role_permission?: Maybe<Role_Permission_Mutation_Response>;
  /** delete single row from the table: "role_permission" */
  delete_role_permission_by_pk?: Maybe<Role_Permission>;
  /** delete data from the table: "status" */
  delete_status?: Maybe<Status_Mutation_Response>;
  /** delete single row from the table: "status" */
  delete_status_by_pk?: Maybe<Status>;
  /** delete data from the table: "title" */
  delete_title?: Maybe<Title_Mutation_Response>;
  /** delete single row from the table: "title" */
  delete_title_by_pk?: Maybe<Title>;
  /** delete data from the table: "university" */
  delete_university?: Maybe<University_Mutation_Response>;
  /** delete single row from the table: "university" */
  delete_university_by_pk?: Maybe<University>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "user_activity_log" */
  delete_user_activity_log?: Maybe<User_Activity_Log_Mutation_Response>;
  /** delete single row from the table: "user_activity_log" */
  delete_user_activity_log_by_pk?: Maybe<User_Activity_Log>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>;
  dropDeletePermission: PermissionResponse;
  dropInsertPermission: PermissionResponse;
  dropSelectPermission: PermissionResponse;
  dropUpdatePermission: PermissionResponse;
  generateSignedS3RequestForDownload: S3PayloadResponse;
  generateSignedS3RequestForUpload: S3PayloadResponse;
  generateStatement: GenerateStatementResponse;
  goalCalculations: GoalCalculationResponse;
  help_me_decide_education: HelpMeDecideEducationResponse;
  help_me_decide_retirement: HelpMeDecideRetirementResponse;
  /** insert data into the table: "advisor" */
  insert_advisor?: Maybe<Advisor_Mutation_Response>;
  /** insert a single row into the table: "advisor" */
  insert_advisor_one?: Maybe<Advisor>;
  /** insert data into the table: "all_tables_template" */
  insert_all_tables_template?: Maybe<All_Tables_Template_Mutation_Response>;
  /** insert a single row into the table: "all_tables_template" */
  insert_all_tables_template_one?: Maybe<All_Tables_Template>;
  /** insert data into the table: "apk" */
  insert_apk?: Maybe<Apk_Mutation_Response>;
  /** insert a single row into the table: "apk" */
  insert_apk_one?: Maybe<Apk>;
  /** insert data into the table: "app_config" */
  insert_app_config?: Maybe<App_Config_Mutation_Response>;
  /** insert a single row into the table: "app_config" */
  insert_app_config_one?: Maybe<App_Config>;
  /** insert data into the table: "asset_allocation" */
  insert_asset_allocation?: Maybe<Asset_Allocation_Mutation_Response>;
  /** insert a single row into the table: "asset_allocation" */
  insert_asset_allocation_one?: Maybe<Asset_Allocation>;
  /** insert data into the table: "asset_duration" */
  insert_asset_duration?: Maybe<Asset_Duration_Mutation_Response>;
  /** insert a single row into the table: "asset_duration" */
  insert_asset_duration_one?: Maybe<Asset_Duration>;
  /** insert data into the table: "audit_log" */
  insert_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** insert a single row into the table: "audit_log" */
  insert_audit_log_one?: Maybe<Audit_Log>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "background_notifications" */
  insert_background_notifications?: Maybe<Background_Notifications_Mutation_Response>;
  /** insert a single row into the table: "background_notifications" */
  insert_background_notifications_one?: Maybe<Background_Notifications>;
  /** insert data into the table: "branch" */
  insert_branch?: Maybe<Branch_Mutation_Response>;
  /** insert a single row into the table: "branch" */
  insert_branch_one?: Maybe<Branch>;
  /** insert data into the table: "client_investment_profile" */
  insert_client_investment_profile?: Maybe<Client_Investment_Profile_Mutation_Response>;
  /** insert a single row into the table: "client_investment_profile" */
  insert_client_investment_profile_one?: Maybe<Client_Investment_Profile>;
  /** insert data into the table: "contribution_source" */
  insert_contribution_source?: Maybe<Contribution_Source_Mutation_Response>;
  /** insert a single row into the table: "contribution_source" */
  insert_contribution_source_one?: Maybe<Contribution_Source>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "deal" */
  insert_deal?: Maybe<Deal_Mutation_Response>;
  /** insert a single row into the table: "deal" */
  insert_deal_one?: Maybe<Deal>;
  /** insert data into the table: "deal_share" */
  insert_deal_share?: Maybe<Deal_Share_Mutation_Response>;
  /** insert a single row into the table: "deal_share" */
  insert_deal_share_one?: Maybe<Deal_Share>;
  /** insert data into the table: "direct_debit_charge" */
  insert_direct_debit_charge?: Maybe<Direct_Debit_Charge_Mutation_Response>;
  /** insert a single row into the table: "direct_debit_charge" */
  insert_direct_debit_charge_one?: Maybe<Direct_Debit_Charge>;
  /** insert data into the table: "economic_indicator" */
  insert_economic_indicator?: Maybe<Economic_Indicator_Mutation_Response>;
  /** insert a single row into the table: "economic_indicator" */
  insert_economic_indicator_one?: Maybe<Economic_Indicator>;
  /** insert data into the table: "expected_return" */
  insert_expected_return?: Maybe<Expected_Return_Mutation_Response>;
  /** insert a single row into the table: "expected_return" */
  insert_expected_return_one?: Maybe<Expected_Return>;
  /** insert data into the table: "gender" */
  insert_gender?: Maybe<Gender_Mutation_Response>;
  /** insert a single row into the table: "gender" */
  insert_gender_one?: Maybe<Gender>;
  /** insert data into the table: "goal" */
  insert_goal?: Maybe<Goal_Mutation_Response>;
  /** insert data into the table: "goal_insurance" */
  insert_goal_insurance?: Maybe<Goal_Insurance_Mutation_Response>;
  /** insert a single row into the table: "goal_insurance" */
  insert_goal_insurance_one?: Maybe<Goal_Insurance>;
  /** insert a single row into the table: "goal" */
  insert_goal_one?: Maybe<Goal>;
  /** insert data into the table: "goal_product" */
  insert_goal_product?: Maybe<Goal_Product_Mutation_Response>;
  /** insert a single row into the table: "goal_product" */
  insert_goal_product_one?: Maybe<Goal_Product>;
  /** insert data into the table: "hmd_education" */
  insert_hmd_education?: Maybe<Hmd_Education_Mutation_Response>;
  /** insert a single row into the table: "hmd_education" */
  insert_hmd_education_one?: Maybe<Hmd_Education>;
  /** insert data into the table: "hmd_expense_base" */
  insert_hmd_expense_base?: Maybe<Hmd_Expense_Base_Mutation_Response>;
  /** insert a single row into the table: "hmd_expense_base" */
  insert_hmd_expense_base_one?: Maybe<Hmd_Expense_Base>;
  /** insert data into the table: "hmd_expense_category" */
  insert_hmd_expense_category?: Maybe<Hmd_Expense_Category_Mutation_Response>;
  /** insert a single row into the table: "hmd_expense_category" */
  insert_hmd_expense_category_one?: Maybe<Hmd_Expense_Category>;
  /** insert data into the table: "hmd_expense_multiplier" */
  insert_hmd_expense_multiplier?: Maybe<Hmd_Expense_Multiplier_Mutation_Response>;
  /** insert a single row into the table: "hmd_expense_multiplier" */
  insert_hmd_expense_multiplier_one?: Maybe<Hmd_Expense_Multiplier>;
  /** insert data into the table: "hmd_lifestyle" */
  insert_hmd_lifestyle?: Maybe<Hmd_Lifestyle_Mutation_Response>;
  /** insert a single row into the table: "hmd_lifestyle" */
  insert_hmd_lifestyle_one?: Maybe<Hmd_Lifestyle>;
  /** insert data into the table: "identification_type" */
  insert_identification_type?: Maybe<Identification_Type_Mutation_Response>;
  /** insert a single row into the table: "identification_type" */
  insert_identification_type_one?: Maybe<Identification_Type>;
  /** insert data into the table: "income_source" */
  insert_income_source?: Maybe<Income_Source_Mutation_Response>;
  /** insert a single row into the table: "income_source" */
  insert_income_source_one?: Maybe<Income_Source>;
  /** insert data into the table: "industry" */
  insert_industry?: Maybe<Industry_Mutation_Response>;
  /** insert a single row into the table: "industry" */
  insert_industry_one?: Maybe<Industry>;
  /** insert data into the table: "insurance_rate_age_range" */
  insert_insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Mutation_Response>;
  /** insert a single row into the table: "insurance_rate_age_range" */
  insert_insurance_rate_age_range_one?: Maybe<Insurance_Rate_Age_Range>;
  /** insert data into the table: "insurance_rate_duration" */
  insert_insurance_rate_duration?: Maybe<Insurance_Rate_Duration_Mutation_Response>;
  /** insert a single row into the table: "insurance_rate_duration" */
  insert_insurance_rate_duration_one?: Maybe<Insurance_Rate_Duration>;
  /** insert data into the table: "insurance_type" */
  insert_insurance_type?: Maybe<Insurance_Type_Mutation_Response>;
  /** insert a single row into the table: "insurance_type" */
  insert_insurance_type_one?: Maybe<Insurance_Type>;
  /** insert data into the table: "marital_status" */
  insert_marital_status?: Maybe<Marital_Status_Mutation_Response>;
  /** insert a single row into the table: "marital_status" */
  insert_marital_status_one?: Maybe<Marital_Status>;
  /** insert data into the table: "member" */
  insert_member?: Maybe<Member_Mutation_Response>;
  /** insert data into the table: "member_beneficiary" */
  insert_member_beneficiary?: Maybe<Member_Beneficiary_Mutation_Response>;
  /** insert a single row into the table: "member_beneficiary" */
  insert_member_beneficiary_one?: Maybe<Member_Beneficiary>;
  /** insert data into the table: "member_contribution_source" */
  insert_member_contribution_source?: Maybe<Member_Contribution_Source_Mutation_Response>;
  /** insert a single row into the table: "member_contribution_source" */
  insert_member_contribution_source_one?: Maybe<Member_Contribution_Source>;
  /** insert data into the table: "member_dependent" */
  insert_member_dependent?: Maybe<Member_Dependent_Mutation_Response>;
  /** insert a single row into the table: "member_dependent" */
  insert_member_dependent_one?: Maybe<Member_Dependent>;
  /** insert data into the table: "member_goal" */
  insert_member_goal?: Maybe<Member_Goal_Mutation_Response>;
  /** insert a single row into the table: "member_goal" */
  insert_member_goal_one?: Maybe<Member_Goal>;
  /** insert data into the table: "member_identification" */
  insert_member_identification?: Maybe<Member_Identification_Mutation_Response>;
  /** insert a single row into the table: "member_identification" */
  insert_member_identification_one?: Maybe<Member_Identification>;
  /** insert data into the table: "member_in_trust_for" */
  insert_member_in_trust_for?: Maybe<Member_In_Trust_For_Mutation_Response>;
  /** insert a single row into the table: "member_in_trust_for" */
  insert_member_in_trust_for_one?: Maybe<Member_In_Trust_For>;
  /** insert data into the table: "member_income_source" */
  insert_member_income_source?: Maybe<Member_Income_Source_Mutation_Response>;
  /** insert a single row into the table: "member_income_source" */
  insert_member_income_source_one?: Maybe<Member_Income_Source>;
  /** insert data into the table: "member_mode_of_instruction_communication" */
  insert_member_mode_of_instruction_communication?: Maybe<Member_Mode_Of_Instruction_Communication_Mutation_Response>;
  /** insert a single row into the table: "member_mode_of_instruction_communication" */
  insert_member_mode_of_instruction_communication_one?: Maybe<Member_Mode_Of_Instruction_Communication>;
  /** insert a single row into the table: "member" */
  insert_member_one?: Maybe<Member>;
  /** insert data into the table: "member_payment_instruction" */
  insert_member_payment_instruction?: Maybe<Member_Payment_Instruction_Mutation_Response>;
  /** insert a single row into the table: "member_payment_instruction" */
  insert_member_payment_instruction_one?: Maybe<Member_Payment_Instruction>;
  /** insert data into the table: "member_redemption" */
  insert_member_redemption?: Maybe<Member_Redemption_Mutation_Response>;
  /** insert a single row into the table: "member_redemption" */
  insert_member_redemption_one?: Maybe<Member_Redemption>;
  /** insert data into the table: "member_reject" */
  insert_member_reject?: Maybe<Member_Reject_Mutation_Response>;
  /** insert a single row into the table: "member_reject" */
  insert_member_reject_one?: Maybe<Member_Reject>;
  /** insert data into the table: "mode_of_instruction_communication" */
  insert_mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Mutation_Response>;
  /** insert a single row into the table: "mode_of_instruction_communication" */
  insert_mode_of_instruction_communication_one?: Maybe<Mode_Of_Instruction_Communication>;
  /** insert data into the table: "mp_product_split" */
  insert_mp_product_split?: Maybe<Mp_Product_Split_Mutation_Response>;
  /** insert a single row into the table: "mp_product_split" */
  insert_mp_product_split_one?: Maybe<Mp_Product_Split>;
  /** insert data into the table: "next_of_kin" */
  insert_next_of_kin?: Maybe<Next_Of_Kin_Mutation_Response>;
  /** insert a single row into the table: "next_of_kin" */
  insert_next_of_kin_one?: Maybe<Next_Of_Kin>;
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>;
  /** insert data into the table: "payment_channel" */
  insert_payment_channel?: Maybe<Payment_Channel_Mutation_Response>;
  /** insert a single row into the table: "payment_channel" */
  insert_payment_channel_one?: Maybe<Payment_Channel>;
  /** insert data into the table: "payment_mode" */
  insert_payment_mode?: Maybe<Payment_Mode_Mutation_Response>;
  /** insert a single row into the table: "payment_mode" */
  insert_payment_mode_one?: Maybe<Payment_Mode>;
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>;
  /** insert data into the table: "payment_provider" */
  insert_payment_provider?: Maybe<Payment_Provider_Mutation_Response>;
  /** insert a single row into the table: "payment_provider" */
  insert_payment_provider_one?: Maybe<Payment_Provider>;
  /** insert data into the table: "payment_share" */
  insert_payment_share?: Maybe<Payment_Share_Mutation_Response>;
  /** insert a single row into the table: "payment_share" */
  insert_payment_share_one?: Maybe<Payment_Share>;
  /** insert data into the table: "permission" */
  insert_permission?: Maybe<Permission_Mutation_Response>;
  /** insert a single row into the table: "permission" */
  insert_permission_one?: Maybe<Permission>;
  /** insert data into the table: "price" */
  insert_price?: Maybe<Price_Mutation_Response>;
  /** insert a single row into the table: "price" */
  insert_price_one?: Maybe<Price>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "redemption_share" */
  insert_redemption_share?: Maybe<Redemption_Share_Mutation_Response>;
  /** insert a single row into the table: "redemption_share" */
  insert_redemption_share_one?: Maybe<Redemption_Share>;
  /** insert data into the table: "region" */
  insert_region?: Maybe<Region_Mutation_Response>;
  /** insert a single row into the table: "region" */
  insert_region_one?: Maybe<Region>;
  /** insert data into the table: "relationship" */
  insert_relationship?: Maybe<Relationship_Mutation_Response>;
  /** insert a single row into the table: "relationship" */
  insert_relationship_one?: Maybe<Relationship>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "role_permission" */
  insert_role_permission?: Maybe<Role_Permission_Mutation_Response>;
  /** insert a single row into the table: "role_permission" */
  insert_role_permission_one?: Maybe<Role_Permission>;
  /** insert data into the table: "status" */
  insert_status?: Maybe<Status_Mutation_Response>;
  /** insert a single row into the table: "status" */
  insert_status_one?: Maybe<Status>;
  /** insert data into the table: "title" */
  insert_title?: Maybe<Title_Mutation_Response>;
  /** insert a single row into the table: "title" */
  insert_title_one?: Maybe<Title>;
  /** insert data into the table: "university" */
  insert_university?: Maybe<University_Mutation_Response>;
  /** insert a single row into the table: "university" */
  insert_university_one?: Maybe<University>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "user_activity_log" */
  insert_user_activity_log?: Maybe<User_Activity_Log_Mutation_Response>;
  /** insert a single row into the table: "user_activity_log" */
  insert_user_activity_log_one?: Maybe<User_Activity_Log>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>;
  loadPayments: LoadPaymentsResponse;
  login: LoginResponse;
  logout: Scalars['Boolean'];
  onlinePayments: OnlinePaymentResponse;
  proposeGoals: ProposeGoalsResponse;
  redeem: RedeemResponse;
  register: RegisterResponse;
  registerCustomer: RegisterResponse;
  resetPassword: PasswordResetResponse;
  sendWelcomePack: SendWelcomePackResponse;
  /** update data of the table: "advisor" */
  update_advisor?: Maybe<Advisor_Mutation_Response>;
  /** update single row of the table: "advisor" */
  update_advisor_by_pk?: Maybe<Advisor>;
  /** update data of the table: "all_tables_template" */
  update_all_tables_template?: Maybe<All_Tables_Template_Mutation_Response>;
  /** update single row of the table: "all_tables_template" */
  update_all_tables_template_by_pk?: Maybe<All_Tables_Template>;
  /** update data of the table: "apk" */
  update_apk?: Maybe<Apk_Mutation_Response>;
  /** update single row of the table: "apk" */
  update_apk_by_pk?: Maybe<Apk>;
  /** update data of the table: "app_config" */
  update_app_config?: Maybe<App_Config_Mutation_Response>;
  /** update single row of the table: "app_config" */
  update_app_config_by_pk?: Maybe<App_Config>;
  /** update data of the table: "asset_allocation" */
  update_asset_allocation?: Maybe<Asset_Allocation_Mutation_Response>;
  /** update single row of the table: "asset_allocation" */
  update_asset_allocation_by_pk?: Maybe<Asset_Allocation>;
  /** update data of the table: "asset_duration" */
  update_asset_duration?: Maybe<Asset_Duration_Mutation_Response>;
  /** update single row of the table: "asset_duration" */
  update_asset_duration_by_pk?: Maybe<Asset_Duration>;
  /** update data of the table: "audit_log" */
  update_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** update single row of the table: "audit_log" */
  update_audit_log_by_pk?: Maybe<Audit_Log>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** update data of the table: "background_notifications" */
  update_background_notifications?: Maybe<Background_Notifications_Mutation_Response>;
  /** update single row of the table: "background_notifications" */
  update_background_notifications_by_pk?: Maybe<Background_Notifications>;
  /** update data of the table: "branch" */
  update_branch?: Maybe<Branch_Mutation_Response>;
  /** update single row of the table: "branch" */
  update_branch_by_pk?: Maybe<Branch>;
  /** update data of the table: "client_investment_profile" */
  update_client_investment_profile?: Maybe<Client_Investment_Profile_Mutation_Response>;
  /** update single row of the table: "client_investment_profile" */
  update_client_investment_profile_by_pk?: Maybe<Client_Investment_Profile>;
  /** update data of the table: "contribution_source" */
  update_contribution_source?: Maybe<Contribution_Source_Mutation_Response>;
  /** update single row of the table: "contribution_source" */
  update_contribution_source_by_pk?: Maybe<Contribution_Source>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update data of the table: "deal" */
  update_deal?: Maybe<Deal_Mutation_Response>;
  /** update single row of the table: "deal" */
  update_deal_by_pk?: Maybe<Deal>;
  /** update data of the table: "deal_share" */
  update_deal_share?: Maybe<Deal_Share_Mutation_Response>;
  /** update single row of the table: "deal_share" */
  update_deal_share_by_pk?: Maybe<Deal_Share>;
  /** update data of the table: "direct_debit_charge" */
  update_direct_debit_charge?: Maybe<Direct_Debit_Charge_Mutation_Response>;
  /** update single row of the table: "direct_debit_charge" */
  update_direct_debit_charge_by_pk?: Maybe<Direct_Debit_Charge>;
  /** update data of the table: "economic_indicator" */
  update_economic_indicator?: Maybe<Economic_Indicator_Mutation_Response>;
  /** update single row of the table: "economic_indicator" */
  update_economic_indicator_by_pk?: Maybe<Economic_Indicator>;
  /** update data of the table: "expected_return" */
  update_expected_return?: Maybe<Expected_Return_Mutation_Response>;
  /** update single row of the table: "expected_return" */
  update_expected_return_by_pk?: Maybe<Expected_Return>;
  /** update data of the table: "gender" */
  update_gender?: Maybe<Gender_Mutation_Response>;
  /** update single row of the table: "gender" */
  update_gender_by_pk?: Maybe<Gender>;
  /** update data of the table: "goal" */
  update_goal?: Maybe<Goal_Mutation_Response>;
  /** update single row of the table: "goal" */
  update_goal_by_pk?: Maybe<Goal>;
  /** update data of the table: "goal_insurance" */
  update_goal_insurance?: Maybe<Goal_Insurance_Mutation_Response>;
  /** update single row of the table: "goal_insurance" */
  update_goal_insurance_by_pk?: Maybe<Goal_Insurance>;
  /** update data of the table: "goal_product" */
  update_goal_product?: Maybe<Goal_Product_Mutation_Response>;
  /** update single row of the table: "goal_product" */
  update_goal_product_by_pk?: Maybe<Goal_Product>;
  /** update data of the table: "hmd_education" */
  update_hmd_education?: Maybe<Hmd_Education_Mutation_Response>;
  /** update single row of the table: "hmd_education" */
  update_hmd_education_by_pk?: Maybe<Hmd_Education>;
  /** update data of the table: "hmd_expense_base" */
  update_hmd_expense_base?: Maybe<Hmd_Expense_Base_Mutation_Response>;
  /** update single row of the table: "hmd_expense_base" */
  update_hmd_expense_base_by_pk?: Maybe<Hmd_Expense_Base>;
  /** update data of the table: "hmd_expense_category" */
  update_hmd_expense_category?: Maybe<Hmd_Expense_Category_Mutation_Response>;
  /** update single row of the table: "hmd_expense_category" */
  update_hmd_expense_category_by_pk?: Maybe<Hmd_Expense_Category>;
  /** update data of the table: "hmd_expense_multiplier" */
  update_hmd_expense_multiplier?: Maybe<Hmd_Expense_Multiplier_Mutation_Response>;
  /** update single row of the table: "hmd_expense_multiplier" */
  update_hmd_expense_multiplier_by_pk?: Maybe<Hmd_Expense_Multiplier>;
  /** update data of the table: "hmd_lifestyle" */
  update_hmd_lifestyle?: Maybe<Hmd_Lifestyle_Mutation_Response>;
  /** update single row of the table: "hmd_lifestyle" */
  update_hmd_lifestyle_by_pk?: Maybe<Hmd_Lifestyle>;
  /** update data of the table: "identification_type" */
  update_identification_type?: Maybe<Identification_Type_Mutation_Response>;
  /** update single row of the table: "identification_type" */
  update_identification_type_by_pk?: Maybe<Identification_Type>;
  /** update data of the table: "income_source" */
  update_income_source?: Maybe<Income_Source_Mutation_Response>;
  /** update single row of the table: "income_source" */
  update_income_source_by_pk?: Maybe<Income_Source>;
  /** update data of the table: "industry" */
  update_industry?: Maybe<Industry_Mutation_Response>;
  /** update single row of the table: "industry" */
  update_industry_by_pk?: Maybe<Industry>;
  /** update data of the table: "insurance_rate_age_range" */
  update_insurance_rate_age_range?: Maybe<Insurance_Rate_Age_Range_Mutation_Response>;
  /** update single row of the table: "insurance_rate_age_range" */
  update_insurance_rate_age_range_by_pk?: Maybe<Insurance_Rate_Age_Range>;
  /** update data of the table: "insurance_rate_duration" */
  update_insurance_rate_duration?: Maybe<Insurance_Rate_Duration_Mutation_Response>;
  /** update single row of the table: "insurance_rate_duration" */
  update_insurance_rate_duration_by_pk?: Maybe<Insurance_Rate_Duration>;
  /** update data of the table: "insurance_type" */
  update_insurance_type?: Maybe<Insurance_Type_Mutation_Response>;
  /** update single row of the table: "insurance_type" */
  update_insurance_type_by_pk?: Maybe<Insurance_Type>;
  /** update data of the table: "marital_status" */
  update_marital_status?: Maybe<Marital_Status_Mutation_Response>;
  /** update single row of the table: "marital_status" */
  update_marital_status_by_pk?: Maybe<Marital_Status>;
  /** update data of the table: "member" */
  update_member?: Maybe<Member_Mutation_Response>;
  /** update data of the table: "member_beneficiary" */
  update_member_beneficiary?: Maybe<Member_Beneficiary_Mutation_Response>;
  /** update single row of the table: "member_beneficiary" */
  update_member_beneficiary_by_pk?: Maybe<Member_Beneficiary>;
  /** update single row of the table: "member" */
  update_member_by_pk?: Maybe<Member>;
  /** update data of the table: "member_contribution_source" */
  update_member_contribution_source?: Maybe<Member_Contribution_Source_Mutation_Response>;
  /** update single row of the table: "member_contribution_source" */
  update_member_contribution_source_by_pk?: Maybe<Member_Contribution_Source>;
  /** update data of the table: "member_dependent" */
  update_member_dependent?: Maybe<Member_Dependent_Mutation_Response>;
  /** update single row of the table: "member_dependent" */
  update_member_dependent_by_pk?: Maybe<Member_Dependent>;
  /** update data of the table: "member_goal" */
  update_member_goal?: Maybe<Member_Goal_Mutation_Response>;
  /** update single row of the table: "member_goal" */
  update_member_goal_by_pk?: Maybe<Member_Goal>;
  /** update data of the table: "member_identification" */
  update_member_identification?: Maybe<Member_Identification_Mutation_Response>;
  /** update single row of the table: "member_identification" */
  update_member_identification_by_pk?: Maybe<Member_Identification>;
  /** update data of the table: "member_in_trust_for" */
  update_member_in_trust_for?: Maybe<Member_In_Trust_For_Mutation_Response>;
  /** update single row of the table: "member_in_trust_for" */
  update_member_in_trust_for_by_pk?: Maybe<Member_In_Trust_For>;
  /** update data of the table: "member_income_source" */
  update_member_income_source?: Maybe<Member_Income_Source_Mutation_Response>;
  /** update single row of the table: "member_income_source" */
  update_member_income_source_by_pk?: Maybe<Member_Income_Source>;
  /** update data of the table: "member_mode_of_instruction_communication" */
  update_member_mode_of_instruction_communication?: Maybe<Member_Mode_Of_Instruction_Communication_Mutation_Response>;
  /** update single row of the table: "member_mode_of_instruction_communication" */
  update_member_mode_of_instruction_communication_by_pk?: Maybe<Member_Mode_Of_Instruction_Communication>;
  /** update data of the table: "member_payment_instruction" */
  update_member_payment_instruction?: Maybe<Member_Payment_Instruction_Mutation_Response>;
  /** update single row of the table: "member_payment_instruction" */
  update_member_payment_instruction_by_pk?: Maybe<Member_Payment_Instruction>;
  /** update data of the table: "member_redemption" */
  update_member_redemption?: Maybe<Member_Redemption_Mutation_Response>;
  /** update single row of the table: "member_redemption" */
  update_member_redemption_by_pk?: Maybe<Member_Redemption>;
  /** update data of the table: "member_reject" */
  update_member_reject?: Maybe<Member_Reject_Mutation_Response>;
  /** update single row of the table: "member_reject" */
  update_member_reject_by_pk?: Maybe<Member_Reject>;
  /** update data of the table: "mode_of_instruction_communication" */
  update_mode_of_instruction_communication?: Maybe<Mode_Of_Instruction_Communication_Mutation_Response>;
  /** update single row of the table: "mode_of_instruction_communication" */
  update_mode_of_instruction_communication_by_pk?: Maybe<Mode_Of_Instruction_Communication>;
  /** update data of the table: "mp_product_split" */
  update_mp_product_split?: Maybe<Mp_Product_Split_Mutation_Response>;
  /** update single row of the table: "mp_product_split" */
  update_mp_product_split_by_pk?: Maybe<Mp_Product_Split>;
  /** update data of the table: "next_of_kin" */
  update_next_of_kin?: Maybe<Next_Of_Kin_Mutation_Response>;
  /** update single row of the table: "next_of_kin" */
  update_next_of_kin_by_pk?: Maybe<Next_Of_Kin>;
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>;
  /** update data of the table: "payment_channel" */
  update_payment_channel?: Maybe<Payment_Channel_Mutation_Response>;
  /** update single row of the table: "payment_channel" */
  update_payment_channel_by_pk?: Maybe<Payment_Channel>;
  /** update data of the table: "payment_mode" */
  update_payment_mode?: Maybe<Payment_Mode_Mutation_Response>;
  /** update single row of the table: "payment_mode" */
  update_payment_mode_by_pk?: Maybe<Payment_Mode>;
  /** update data of the table: "payment_provider" */
  update_payment_provider?: Maybe<Payment_Provider_Mutation_Response>;
  /** update single row of the table: "payment_provider" */
  update_payment_provider_by_pk?: Maybe<Payment_Provider>;
  /** update data of the table: "payment_share" */
  update_payment_share?: Maybe<Payment_Share_Mutation_Response>;
  /** update single row of the table: "payment_share" */
  update_payment_share_by_pk?: Maybe<Payment_Share>;
  /** update data of the table: "permission" */
  update_permission?: Maybe<Permission_Mutation_Response>;
  /** update single row of the table: "permission" */
  update_permission_by_pk?: Maybe<Permission>;
  /** update data of the table: "price" */
  update_price?: Maybe<Price_Mutation_Response>;
  /** update single row of the table: "price" */
  update_price_by_pk?: Maybe<Price>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "redemption_share" */
  update_redemption_share?: Maybe<Redemption_Share_Mutation_Response>;
  /** update single row of the table: "redemption_share" */
  update_redemption_share_by_pk?: Maybe<Redemption_Share>;
  /** update data of the table: "region" */
  update_region?: Maybe<Region_Mutation_Response>;
  /** update single row of the table: "region" */
  update_region_by_pk?: Maybe<Region>;
  /** update data of the table: "relationship" */
  update_relationship?: Maybe<Relationship_Mutation_Response>;
  /** update single row of the table: "relationship" */
  update_relationship_by_pk?: Maybe<Relationship>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update data of the table: "role_permission" */
  update_role_permission?: Maybe<Role_Permission_Mutation_Response>;
  /** update single row of the table: "role_permission" */
  update_role_permission_by_pk?: Maybe<Role_Permission>;
  /** update data of the table: "status" */
  update_status?: Maybe<Status_Mutation_Response>;
  /** update single row of the table: "status" */
  update_status_by_pk?: Maybe<Status>;
  /** update data of the table: "title" */
  update_title?: Maybe<Title_Mutation_Response>;
  /** update single row of the table: "title" */
  update_title_by_pk?: Maybe<Title>;
  /** update data of the table: "university" */
  update_university?: Maybe<University_Mutation_Response>;
  /** update single row of the table: "university" */
  update_university_by_pk?: Maybe<University>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "user_activity_log" */
  update_user_activity_log?: Maybe<User_Activity_Log_Mutation_Response>;
  /** update single row of the table: "user_activity_log" */
  update_user_activity_log_by_pk?: Maybe<User_Activity_Log>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>;
};


/** mutation root */
export type Mutation_RootApprovePaymentsArgs = {
  data: ApprovePaymentInput;
};


/** mutation root */
export type Mutation_RootCalculateExpectedReturnArgs = {
  data: ExpectedReturnInput;
};


/** mutation root */
export type Mutation_RootCalculateGoalBalancesArgs = {
  data: GoalBalancesInput;
};


/** mutation root */
export type Mutation_RootCalculateGoalContributionsArgs = {
  data: GoalContributionsInput;
};


/** mutation root */
export type Mutation_RootCalculateInsurancePremiumArgs = {
  data: InsurancePremiumInput;
};


/** mutation root */
export type Mutation_RootCalculateTotalBalanceArgs = {
  data: TotalBalanceInput;
};


/** mutation root */
export type Mutation_RootCalculateTotalContributionsArgs = {
  data: ContributionsInput;
};


/** mutation root */
export type Mutation_RootCreateCustomerArgs = {
  data: CreateCustomerInput;
};


/** mutation root */
export type Mutation_RootCreateDeletePermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootCreateInsertPermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootCreateSelectPermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootCreateUpdatePermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootDelete_AdvisorArgs = {
  where: Advisor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Advisor_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_All_Tables_TemplateArgs = {
  where: All_Tables_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_All_Tables_Template_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ApkArgs = {
  where: Apk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Apk_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_App_ConfigArgs = {
  where: App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_App_Config_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Asset_AllocationArgs = {
  where: Asset_Allocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Asset_Allocation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Asset_DurationArgs = {
  where: Asset_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Asset_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Audit_LogArgs = {
  where: Audit_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Background_NotificationsArgs = {
  where: Background_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Background_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BranchArgs = {
  where: Branch_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Branch_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Client_Investment_ProfileArgs = {
  where: Client_Investment_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Investment_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Contribution_SourceArgs = {
  where: Contribution_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_DealArgs = {
  where: Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Deal_ShareArgs = {
  where: Deal_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Deal_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Direct_Debit_ChargeArgs = {
  where: Direct_Debit_Charge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Direct_Debit_Charge_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Economic_IndicatorArgs = {
  where: Economic_Indicator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Economic_Indicator_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Expected_ReturnArgs = {
  where: Expected_Return_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Expected_Return_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_GenderArgs = {
  where: Gender_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gender_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_GoalArgs = {
  where: Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Goal_InsuranceArgs = {
  where: Goal_Insurance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goal_Insurance_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Goal_ProductArgs = {
  where: Goal_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Goal_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Hmd_EducationArgs = {
  where: Hmd_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_BaseArgs = {
  where: Hmd_Expense_Base_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_Base_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_CategoryArgs = {
  where: Hmd_Expense_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_Category_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_MultiplierArgs = {
  where: Hmd_Expense_Multiplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Expense_Multiplier_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Hmd_LifestyleArgs = {
  where: Hmd_Lifestyle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hmd_Lifestyle_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Identification_TypeArgs = {
  where: Identification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Identification_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Income_SourceArgs = {
  where: Income_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Income_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_IndustryArgs = {
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Industry_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Rate_Age_RangeArgs = {
  where: Insurance_Rate_Age_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Rate_Age_Range_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Rate_DurationArgs = {
  where: Insurance_Rate_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Rate_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_TypeArgs = {
  where: Insurance_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Marital_StatusArgs = {
  where: Marital_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Marital_Status_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_MemberArgs = {
  where: Member_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_BeneficiaryArgs = {
  where: Member_Beneficiary_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Beneficiary_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_Contribution_SourceArgs = {
  where: Member_Contribution_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Contribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_DependentArgs = {
  where: Member_Dependent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Dependent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_GoalArgs = {
  where: Member_Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Goal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_IdentificationArgs = {
  where: Member_Identification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Identification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_In_Trust_ForArgs = {
  where: Member_In_Trust_For_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_In_Trust_For_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_Income_SourceArgs = {
  where: Member_Income_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Income_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_Mode_Of_Instruction_CommunicationArgs = {
  where: Member_Mode_Of_Instruction_Communication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Mode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_Payment_InstructionArgs = {
  where: Member_Payment_Instruction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Payment_Instruction_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_RedemptionArgs = {
  where: Member_Redemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Redemption_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Member_RejectArgs = {
  where: Member_Reject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Member_Reject_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Mode_Of_Instruction_CommunicationArgs = {
  where: Mode_Of_Instruction_Communication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Mp_Product_SplitArgs = {
  where: Mp_Product_Split_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Mp_Product_Split_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Next_Of_KinArgs = {
  where: Next_Of_Kin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Next_Of_Kin_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_ChannelArgs = {
  where: Payment_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Channel_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_ModeArgs = {
  where: Payment_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Mode_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_ProviderArgs = {
  where: Payment_Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Provider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_ShareArgs = {
  where: Payment_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PermissionArgs = {
  where: Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Permission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_PriceArgs = {
  where: Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Price_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Redemption_ShareArgs = {
  where: Redemption_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Redemption_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_RegionArgs = {
  where: Region_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Region_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_RelationshipArgs = {
  where: Relationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Relationship_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Role_PermissionArgs = {
  where: Role_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Permission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_StatusArgs = {
  where: Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Status_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_TitleArgs = {
  where: Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Title_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UniversityArgs = {
  where: University_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_University_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Activity_LogArgs = {
  where: User_Activity_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Activity_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDropDeletePermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootDropInsertPermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootDropSelectPermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootDropUpdatePermissionArgs = {
  data: PermissionInput;
};


/** mutation root */
export type Mutation_RootGenerateSignedS3RequestForDownloadArgs = {
  data: S3FileInput;
};


/** mutation root */
export type Mutation_RootGenerateSignedS3RequestForUploadArgs = {
  data: S3FileInput;
};


/** mutation root */
export type Mutation_RootGenerateStatementArgs = {
  data: GenerateStatementInput;
};


/** mutation root */
export type Mutation_RootGoalCalculationsArgs = {
  data: GoalCalculationInput;
};


/** mutation root */
export type Mutation_RootHelp_Me_Decide_EducationArgs = {
  data: HelpMeDecideEducationInput;
};


/** mutation root */
export type Mutation_RootHelp_Me_Decide_RetirementArgs = {
  data: HelpMeDecideRetirementInput;
};


/** mutation root */
export type Mutation_RootInsert_AdvisorArgs = {
  objects: Array<Advisor_Insert_Input>;
  on_conflict?: Maybe<Advisor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Advisor_OneArgs = {
  object: Advisor_Insert_Input;
  on_conflict?: Maybe<Advisor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_All_Tables_TemplateArgs = {
  objects: Array<All_Tables_Template_Insert_Input>;
  on_conflict?: Maybe<All_Tables_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_All_Tables_Template_OneArgs = {
  object: All_Tables_Template_Insert_Input;
  on_conflict?: Maybe<All_Tables_Template_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ApkArgs = {
  objects: Array<Apk_Insert_Input>;
  on_conflict?: Maybe<Apk_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Apk_OneArgs = {
  object: Apk_Insert_Input;
  on_conflict?: Maybe<Apk_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_ConfigArgs = {
  objects: Array<App_Config_Insert_Input>;
  on_conflict?: Maybe<App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_App_Config_OneArgs = {
  object: App_Config_Insert_Input;
  on_conflict?: Maybe<App_Config_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_AllocationArgs = {
  objects: Array<Asset_Allocation_Insert_Input>;
  on_conflict?: Maybe<Asset_Allocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_Allocation_OneArgs = {
  object: Asset_Allocation_Insert_Input;
  on_conflict?: Maybe<Asset_Allocation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_DurationArgs = {
  objects: Array<Asset_Duration_Insert_Input>;
  on_conflict?: Maybe<Asset_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Asset_Duration_OneArgs = {
  object: Asset_Duration_Insert_Input;
  on_conflict?: Maybe<Asset_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_LogArgs = {
  objects: Array<Audit_Log_Insert_Input>;
  on_conflict?: Maybe<Audit_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Log_OneArgs = {
  object: Audit_Log_Insert_Input;
  on_conflict?: Maybe<Audit_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input;
  on_conflict?: Maybe<Audit_Logged_Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Background_NotificationsArgs = {
  objects: Array<Background_Notifications_Insert_Input>;
  on_conflict?: Maybe<Background_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Background_Notifications_OneArgs = {
  object: Background_Notifications_Insert_Input;
  on_conflict?: Maybe<Background_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BranchArgs = {
  objects: Array<Branch_Insert_Input>;
  on_conflict?: Maybe<Branch_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Branch_OneArgs = {
  object: Branch_Insert_Input;
  on_conflict?: Maybe<Branch_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Investment_ProfileArgs = {
  objects: Array<Client_Investment_Profile_Insert_Input>;
  on_conflict?: Maybe<Client_Investment_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Investment_Profile_OneArgs = {
  object: Client_Investment_Profile_Insert_Input;
  on_conflict?: Maybe<Client_Investment_Profile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contribution_SourceArgs = {
  objects: Array<Contribution_Source_Insert_Input>;
  on_conflict?: Maybe<Contribution_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contribution_Source_OneArgs = {
  object: Contribution_Source_Insert_Input;
  on_conflict?: Maybe<Contribution_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: Maybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: Maybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DealArgs = {
  objects: Array<Deal_Insert_Input>;
  on_conflict?: Maybe<Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_OneArgs = {
  object: Deal_Insert_Input;
  on_conflict?: Maybe<Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_ShareArgs = {
  objects: Array<Deal_Share_Insert_Input>;
  on_conflict?: Maybe<Deal_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Deal_Share_OneArgs = {
  object: Deal_Share_Insert_Input;
  on_conflict?: Maybe<Deal_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Direct_Debit_ChargeArgs = {
  objects: Array<Direct_Debit_Charge_Insert_Input>;
  on_conflict?: Maybe<Direct_Debit_Charge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Direct_Debit_Charge_OneArgs = {
  object: Direct_Debit_Charge_Insert_Input;
  on_conflict?: Maybe<Direct_Debit_Charge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Economic_IndicatorArgs = {
  objects: Array<Economic_Indicator_Insert_Input>;
  on_conflict?: Maybe<Economic_Indicator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Economic_Indicator_OneArgs = {
  object: Economic_Indicator_Insert_Input;
  on_conflict?: Maybe<Economic_Indicator_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expected_ReturnArgs = {
  objects: Array<Expected_Return_Insert_Input>;
  on_conflict?: Maybe<Expected_Return_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expected_Return_OneArgs = {
  object: Expected_Return_Insert_Input;
  on_conflict?: Maybe<Expected_Return_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GenderArgs = {
  objects: Array<Gender_Insert_Input>;
  on_conflict?: Maybe<Gender_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gender_OneArgs = {
  object: Gender_Insert_Input;
  on_conflict?: Maybe<Gender_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GoalArgs = {
  objects: Array<Goal_Insert_Input>;
  on_conflict?: Maybe<Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_InsuranceArgs = {
  objects: Array<Goal_Insurance_Insert_Input>;
  on_conflict?: Maybe<Goal_Insurance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_Insurance_OneArgs = {
  object: Goal_Insurance_Insert_Input;
  on_conflict?: Maybe<Goal_Insurance_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_OneArgs = {
  object: Goal_Insert_Input;
  on_conflict?: Maybe<Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_ProductArgs = {
  objects: Array<Goal_Product_Insert_Input>;
  on_conflict?: Maybe<Goal_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Goal_Product_OneArgs = {
  object: Goal_Product_Insert_Input;
  on_conflict?: Maybe<Goal_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_EducationArgs = {
  objects: Array<Hmd_Education_Insert_Input>;
  on_conflict?: Maybe<Hmd_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Education_OneArgs = {
  object: Hmd_Education_Insert_Input;
  on_conflict?: Maybe<Hmd_Education_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_BaseArgs = {
  objects: Array<Hmd_Expense_Base_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Base_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_Base_OneArgs = {
  object: Hmd_Expense_Base_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Base_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_CategoryArgs = {
  objects: Array<Hmd_Expense_Category_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_Category_OneArgs = {
  object: Hmd_Expense_Category_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_MultiplierArgs = {
  objects: Array<Hmd_Expense_Multiplier_Insert_Input>;
  on_conflict?: Maybe<Hmd_Expense_Multiplier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Expense_Multiplier_OneArgs = {
  object: Hmd_Expense_Multiplier_Insert_Input;
  on_conflict?: Maybe<Hmd_Expense_Multiplier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_LifestyleArgs = {
  objects: Array<Hmd_Lifestyle_Insert_Input>;
  on_conflict?: Maybe<Hmd_Lifestyle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hmd_Lifestyle_OneArgs = {
  object: Hmd_Lifestyle_Insert_Input;
  on_conflict?: Maybe<Hmd_Lifestyle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identification_TypeArgs = {
  objects: Array<Identification_Type_Insert_Input>;
  on_conflict?: Maybe<Identification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Identification_Type_OneArgs = {
  object: Identification_Type_Insert_Input;
  on_conflict?: Maybe<Identification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Income_SourceArgs = {
  objects: Array<Income_Source_Insert_Input>;
  on_conflict?: Maybe<Income_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Income_Source_OneArgs = {
  object: Income_Source_Insert_Input;
  on_conflict?: Maybe<Income_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_IndustryArgs = {
  objects: Array<Industry_Insert_Input>;
  on_conflict?: Maybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Industry_OneArgs = {
  object: Industry_Insert_Input;
  on_conflict?: Maybe<Industry_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Rate_Age_RangeArgs = {
  objects: Array<Insurance_Rate_Age_Range_Insert_Input>;
  on_conflict?: Maybe<Insurance_Rate_Age_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Rate_Age_Range_OneArgs = {
  object: Insurance_Rate_Age_Range_Insert_Input;
  on_conflict?: Maybe<Insurance_Rate_Age_Range_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Rate_DurationArgs = {
  objects: Array<Insurance_Rate_Duration_Insert_Input>;
  on_conflict?: Maybe<Insurance_Rate_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Rate_Duration_OneArgs = {
  object: Insurance_Rate_Duration_Insert_Input;
  on_conflict?: Maybe<Insurance_Rate_Duration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_TypeArgs = {
  objects: Array<Insurance_Type_Insert_Input>;
  on_conflict?: Maybe<Insurance_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Type_OneArgs = {
  object: Insurance_Type_Insert_Input;
  on_conflict?: Maybe<Insurance_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Marital_StatusArgs = {
  objects: Array<Marital_Status_Insert_Input>;
  on_conflict?: Maybe<Marital_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Marital_Status_OneArgs = {
  object: Marital_Status_Insert_Input;
  on_conflict?: Maybe<Marital_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MemberArgs = {
  objects: Array<Member_Insert_Input>;
  on_conflict?: Maybe<Member_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_BeneficiaryArgs = {
  objects: Array<Member_Beneficiary_Insert_Input>;
  on_conflict?: Maybe<Member_Beneficiary_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Beneficiary_OneArgs = {
  object: Member_Beneficiary_Insert_Input;
  on_conflict?: Maybe<Member_Beneficiary_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Contribution_SourceArgs = {
  objects: Array<Member_Contribution_Source_Insert_Input>;
  on_conflict?: Maybe<Member_Contribution_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Contribution_Source_OneArgs = {
  object: Member_Contribution_Source_Insert_Input;
  on_conflict?: Maybe<Member_Contribution_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_DependentArgs = {
  objects: Array<Member_Dependent_Insert_Input>;
  on_conflict?: Maybe<Member_Dependent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Dependent_OneArgs = {
  object: Member_Dependent_Insert_Input;
  on_conflict?: Maybe<Member_Dependent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_GoalArgs = {
  objects: Array<Member_Goal_Insert_Input>;
  on_conflict?: Maybe<Member_Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Goal_OneArgs = {
  object: Member_Goal_Insert_Input;
  on_conflict?: Maybe<Member_Goal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_IdentificationArgs = {
  objects: Array<Member_Identification_Insert_Input>;
  on_conflict?: Maybe<Member_Identification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Identification_OneArgs = {
  object: Member_Identification_Insert_Input;
  on_conflict?: Maybe<Member_Identification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_In_Trust_ForArgs = {
  objects: Array<Member_In_Trust_For_Insert_Input>;
  on_conflict?: Maybe<Member_In_Trust_For_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_In_Trust_For_OneArgs = {
  object: Member_In_Trust_For_Insert_Input;
  on_conflict?: Maybe<Member_In_Trust_For_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Income_SourceArgs = {
  objects: Array<Member_Income_Source_Insert_Input>;
  on_conflict?: Maybe<Member_Income_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Income_Source_OneArgs = {
  object: Member_Income_Source_Insert_Input;
  on_conflict?: Maybe<Member_Income_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Mode_Of_Instruction_CommunicationArgs = {
  objects: Array<Member_Mode_Of_Instruction_Communication_Insert_Input>;
  on_conflict?: Maybe<Member_Mode_Of_Instruction_Communication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Mode_Of_Instruction_Communication_OneArgs = {
  object: Member_Mode_Of_Instruction_Communication_Insert_Input;
  on_conflict?: Maybe<Member_Mode_Of_Instruction_Communication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_OneArgs = {
  object: Member_Insert_Input;
  on_conflict?: Maybe<Member_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Payment_InstructionArgs = {
  objects: Array<Member_Payment_Instruction_Insert_Input>;
  on_conflict?: Maybe<Member_Payment_Instruction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Payment_Instruction_OneArgs = {
  object: Member_Payment_Instruction_Insert_Input;
  on_conflict?: Maybe<Member_Payment_Instruction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_RedemptionArgs = {
  objects: Array<Member_Redemption_Insert_Input>;
  on_conflict?: Maybe<Member_Redemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Redemption_OneArgs = {
  object: Member_Redemption_Insert_Input;
  on_conflict?: Maybe<Member_Redemption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_RejectArgs = {
  objects: Array<Member_Reject_Insert_Input>;
  on_conflict?: Maybe<Member_Reject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Member_Reject_OneArgs = {
  object: Member_Reject_Insert_Input;
  on_conflict?: Maybe<Member_Reject_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mode_Of_Instruction_CommunicationArgs = {
  objects: Array<Mode_Of_Instruction_Communication_Insert_Input>;
  on_conflict?: Maybe<Mode_Of_Instruction_Communication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mode_Of_Instruction_Communication_OneArgs = {
  object: Mode_Of_Instruction_Communication_Insert_Input;
  on_conflict?: Maybe<Mode_Of_Instruction_Communication_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mp_Product_SplitArgs = {
  objects: Array<Mp_Product_Split_Insert_Input>;
  on_conflict?: Maybe<Mp_Product_Split_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Mp_Product_Split_OneArgs = {
  object: Mp_Product_Split_Insert_Input;
  on_conflict?: Maybe<Mp_Product_Split_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Next_Of_KinArgs = {
  objects: Array<Next_Of_Kin_Insert_Input>;
  on_conflict?: Maybe<Next_Of_Kin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Next_Of_Kin_OneArgs = {
  object: Next_Of_Kin_Insert_Input;
  on_conflict?: Maybe<Next_Of_Kin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_ChannelArgs = {
  objects: Array<Payment_Channel_Insert_Input>;
  on_conflict?: Maybe<Payment_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Channel_OneArgs = {
  object: Payment_Channel_Insert_Input;
  on_conflict?: Maybe<Payment_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_ModeArgs = {
  objects: Array<Payment_Mode_Insert_Input>;
  on_conflict?: Maybe<Payment_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Mode_OneArgs = {
  object: Payment_Mode_Insert_Input;
  on_conflict?: Maybe<Payment_Mode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_ProviderArgs = {
  objects: Array<Payment_Provider_Insert_Input>;
  on_conflict?: Maybe<Payment_Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Provider_OneArgs = {
  object: Payment_Provider_Insert_Input;
  on_conflict?: Maybe<Payment_Provider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_ShareArgs = {
  objects: Array<Payment_Share_Insert_Input>;
  on_conflict?: Maybe<Payment_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Share_OneArgs = {
  object: Payment_Share_Insert_Input;
  on_conflict?: Maybe<Payment_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PermissionArgs = {
  objects: Array<Permission_Insert_Input>;
  on_conflict?: Maybe<Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Permission_OneArgs = {
  object: Permission_Insert_Input;
  on_conflict?: Maybe<Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PriceArgs = {
  objects: Array<Price_Insert_Input>;
  on_conflict?: Maybe<Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Price_OneArgs = {
  object: Price_Insert_Input;
  on_conflict?: Maybe<Price_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Redemption_ShareArgs = {
  objects: Array<Redemption_Share_Insert_Input>;
  on_conflict?: Maybe<Redemption_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Redemption_Share_OneArgs = {
  object: Redemption_Share_Insert_Input;
  on_conflict?: Maybe<Redemption_Share_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RegionArgs = {
  objects: Array<Region_Insert_Input>;
  on_conflict?: Maybe<Region_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Region_OneArgs = {
  object: Region_Insert_Input;
  on_conflict?: Maybe<Region_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RelationshipArgs = {
  objects: Array<Relationship_Insert_Input>;
  on_conflict?: Maybe<Relationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Relationship_OneArgs = {
  object: Relationship_Insert_Input;
  on_conflict?: Maybe<Relationship_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_PermissionArgs = {
  objects: Array<Role_Permission_Insert_Input>;
  on_conflict?: Maybe<Role_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Permission_OneArgs = {
  object: Role_Permission_Insert_Input;
  on_conflict?: Maybe<Role_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_StatusArgs = {
  objects: Array<Status_Insert_Input>;
  on_conflict?: Maybe<Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Status_OneArgs = {
  object: Status_Insert_Input;
  on_conflict?: Maybe<Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TitleArgs = {
  objects: Array<Title_Insert_Input>;
  on_conflict?: Maybe<Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Title_OneArgs = {
  object: Title_Insert_Input;
  on_conflict?: Maybe<Title_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UniversityArgs = {
  objects: Array<University_Insert_Input>;
  on_conflict?: Maybe<University_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_University_OneArgs = {
  object: University_Insert_Input;
  on_conflict?: Maybe<University_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Activity_LogArgs = {
  objects: Array<User_Activity_Log_Insert_Input>;
  on_conflict?: Maybe<User_Activity_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Activity_Log_OneArgs = {
  object: User_Activity_Log_Insert_Input;
  on_conflict?: Maybe<User_Activity_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoadPaymentsArgs = {
  data: Array<LoadPaymentsInput>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  data: LoginInput;
};


/** mutation root */
export type Mutation_RootOnlinePaymentsArgs = {
  data: OnlinePaymentInput;
};


/** mutation root */
export type Mutation_RootProposeGoalsArgs = {
  data: ProposeGoalsInput;
};


/** mutation root */
export type Mutation_RootRedeemArgs = {
  data: RedeemInput;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  data: RegisterInput;
};


/** mutation root */
export type Mutation_RootRegisterCustomerArgs = {
  data: CustomerRegisterInput;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  data: PasswordResetInput;
};


/** mutation root */
export type Mutation_RootSendWelcomePackArgs = {
  data: SendWelcomePackInput;
};


/** mutation root */
export type Mutation_RootUpdate_AdvisorArgs = {
  _inc?: Maybe<Advisor_Inc_Input>;
  _set?: Maybe<Advisor_Set_Input>;
  where: Advisor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Advisor_By_PkArgs = {
  _inc?: Maybe<Advisor_Inc_Input>;
  _set?: Maybe<Advisor_Set_Input>;
  pk_columns: Advisor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_All_Tables_TemplateArgs = {
  _inc?: Maybe<All_Tables_Template_Inc_Input>;
  _set?: Maybe<All_Tables_Template_Set_Input>;
  where: All_Tables_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_All_Tables_Template_By_PkArgs = {
  _inc?: Maybe<All_Tables_Template_Inc_Input>;
  _set?: Maybe<All_Tables_Template_Set_Input>;
  pk_columns: All_Tables_Template_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ApkArgs = {
  _inc?: Maybe<Apk_Inc_Input>;
  _set?: Maybe<Apk_Set_Input>;
  where: Apk_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Apk_By_PkArgs = {
  _inc?: Maybe<Apk_Inc_Input>;
  _set?: Maybe<Apk_Set_Input>;
  pk_columns: Apk_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_App_ConfigArgs = {
  _inc?: Maybe<App_Config_Inc_Input>;
  _set?: Maybe<App_Config_Set_Input>;
  where: App_Config_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_App_Config_By_PkArgs = {
  _inc?: Maybe<App_Config_Inc_Input>;
  _set?: Maybe<App_Config_Set_Input>;
  pk_columns: App_Config_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_AllocationArgs = {
  _inc?: Maybe<Asset_Allocation_Inc_Input>;
  _set?: Maybe<Asset_Allocation_Set_Input>;
  where: Asset_Allocation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_Allocation_By_PkArgs = {
  _inc?: Maybe<Asset_Allocation_Inc_Input>;
  _set?: Maybe<Asset_Allocation_Set_Input>;
  pk_columns: Asset_Allocation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_DurationArgs = {
  _inc?: Maybe<Asset_Duration_Inc_Input>;
  _set?: Maybe<Asset_Duration_Set_Input>;
  where: Asset_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Asset_Duration_By_PkArgs = {
  _inc?: Maybe<Asset_Duration_Inc_Input>;
  _set?: Maybe<Asset_Duration_Set_Input>;
  pk_columns: Asset_Duration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_LogArgs = {
  _inc?: Maybe<Audit_Log_Inc_Input>;
  _set?: Maybe<Audit_Log_Set_Input>;
  where: Audit_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Log_By_PkArgs = {
  _inc?: Maybe<Audit_Log_Inc_Input>;
  _set?: Maybe<Audit_Log_Set_Input>;
  pk_columns: Audit_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: Maybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: Maybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: Maybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: Maybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: Maybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: Maybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: Maybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: Maybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: Maybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: Maybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: Maybe<Audit_Logged_Actions_Set_Input>;
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Background_NotificationsArgs = {
  _append?: Maybe<Background_Notifications_Append_Input>;
  _delete_at_path?: Maybe<Background_Notifications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Background_Notifications_Delete_Elem_Input>;
  _delete_key?: Maybe<Background_Notifications_Delete_Key_Input>;
  _inc?: Maybe<Background_Notifications_Inc_Input>;
  _prepend?: Maybe<Background_Notifications_Prepend_Input>;
  _set?: Maybe<Background_Notifications_Set_Input>;
  where: Background_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Background_Notifications_By_PkArgs = {
  _append?: Maybe<Background_Notifications_Append_Input>;
  _delete_at_path?: Maybe<Background_Notifications_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Background_Notifications_Delete_Elem_Input>;
  _delete_key?: Maybe<Background_Notifications_Delete_Key_Input>;
  _inc?: Maybe<Background_Notifications_Inc_Input>;
  _prepend?: Maybe<Background_Notifications_Prepend_Input>;
  _set?: Maybe<Background_Notifications_Set_Input>;
  pk_columns: Background_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BranchArgs = {
  _inc?: Maybe<Branch_Inc_Input>;
  _set?: Maybe<Branch_Set_Input>;
  where: Branch_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Branch_By_PkArgs = {
  _inc?: Maybe<Branch_Inc_Input>;
  _set?: Maybe<Branch_Set_Input>;
  pk_columns: Branch_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Investment_ProfileArgs = {
  _inc?: Maybe<Client_Investment_Profile_Inc_Input>;
  _set?: Maybe<Client_Investment_Profile_Set_Input>;
  where: Client_Investment_Profile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Investment_Profile_By_PkArgs = {
  _inc?: Maybe<Client_Investment_Profile_Inc_Input>;
  _set?: Maybe<Client_Investment_Profile_Set_Input>;
  pk_columns: Client_Investment_Profile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contribution_SourceArgs = {
  _inc?: Maybe<Contribution_Source_Inc_Input>;
  _set?: Maybe<Contribution_Source_Set_Input>;
  where: Contribution_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contribution_Source_By_PkArgs = {
  _inc?: Maybe<Contribution_Source_Inc_Input>;
  _set?: Maybe<Contribution_Source_Set_Input>;
  pk_columns: Contribution_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _inc?: Maybe<Country_Inc_Input>;
  _set?: Maybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _inc?: Maybe<Country_Inc_Input>;
  _set?: Maybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DealArgs = {
  _inc?: Maybe<Deal_Inc_Input>;
  _set?: Maybe<Deal_Set_Input>;
  where: Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_By_PkArgs = {
  _inc?: Maybe<Deal_Inc_Input>;
  _set?: Maybe<Deal_Set_Input>;
  pk_columns: Deal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_ShareArgs = {
  _inc?: Maybe<Deal_Share_Inc_Input>;
  _set?: Maybe<Deal_Share_Set_Input>;
  where: Deal_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Deal_Share_By_PkArgs = {
  _inc?: Maybe<Deal_Share_Inc_Input>;
  _set?: Maybe<Deal_Share_Set_Input>;
  pk_columns: Deal_Share_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Direct_Debit_ChargeArgs = {
  _inc?: Maybe<Direct_Debit_Charge_Inc_Input>;
  _set?: Maybe<Direct_Debit_Charge_Set_Input>;
  where: Direct_Debit_Charge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Direct_Debit_Charge_By_PkArgs = {
  _inc?: Maybe<Direct_Debit_Charge_Inc_Input>;
  _set?: Maybe<Direct_Debit_Charge_Set_Input>;
  pk_columns: Direct_Debit_Charge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Economic_IndicatorArgs = {
  _inc?: Maybe<Economic_Indicator_Inc_Input>;
  _set?: Maybe<Economic_Indicator_Set_Input>;
  where: Economic_Indicator_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Economic_Indicator_By_PkArgs = {
  _inc?: Maybe<Economic_Indicator_Inc_Input>;
  _set?: Maybe<Economic_Indicator_Set_Input>;
  pk_columns: Economic_Indicator_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Expected_ReturnArgs = {
  _inc?: Maybe<Expected_Return_Inc_Input>;
  _set?: Maybe<Expected_Return_Set_Input>;
  where: Expected_Return_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Expected_Return_By_PkArgs = {
  _inc?: Maybe<Expected_Return_Inc_Input>;
  _set?: Maybe<Expected_Return_Set_Input>;
  pk_columns: Expected_Return_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GenderArgs = {
  _inc?: Maybe<Gender_Inc_Input>;
  _set?: Maybe<Gender_Set_Input>;
  where: Gender_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gender_By_PkArgs = {
  _inc?: Maybe<Gender_Inc_Input>;
  _set?: Maybe<Gender_Set_Input>;
  pk_columns: Gender_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_GoalArgs = {
  _inc?: Maybe<Goal_Inc_Input>;
  _set?: Maybe<Goal_Set_Input>;
  where: Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_By_PkArgs = {
  _inc?: Maybe<Goal_Inc_Input>;
  _set?: Maybe<Goal_Set_Input>;
  pk_columns: Goal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_InsuranceArgs = {
  _inc?: Maybe<Goal_Insurance_Inc_Input>;
  _set?: Maybe<Goal_Insurance_Set_Input>;
  where: Goal_Insurance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_Insurance_By_PkArgs = {
  _inc?: Maybe<Goal_Insurance_Inc_Input>;
  _set?: Maybe<Goal_Insurance_Set_Input>;
  pk_columns: Goal_Insurance_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_ProductArgs = {
  _inc?: Maybe<Goal_Product_Inc_Input>;
  _set?: Maybe<Goal_Product_Set_Input>;
  where: Goal_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Goal_Product_By_PkArgs = {
  _inc?: Maybe<Goal_Product_Inc_Input>;
  _set?: Maybe<Goal_Product_Set_Input>;
  pk_columns: Goal_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_EducationArgs = {
  _inc?: Maybe<Hmd_Education_Inc_Input>;
  _set?: Maybe<Hmd_Education_Set_Input>;
  where: Hmd_Education_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Education_By_PkArgs = {
  _inc?: Maybe<Hmd_Education_Inc_Input>;
  _set?: Maybe<Hmd_Education_Set_Input>;
  pk_columns: Hmd_Education_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_BaseArgs = {
  _inc?: Maybe<Hmd_Expense_Base_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Base_Set_Input>;
  where: Hmd_Expense_Base_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_Base_By_PkArgs = {
  _inc?: Maybe<Hmd_Expense_Base_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Base_Set_Input>;
  pk_columns: Hmd_Expense_Base_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_CategoryArgs = {
  _inc?: Maybe<Hmd_Expense_Category_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Category_Set_Input>;
  where: Hmd_Expense_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_Category_By_PkArgs = {
  _inc?: Maybe<Hmd_Expense_Category_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Category_Set_Input>;
  pk_columns: Hmd_Expense_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_MultiplierArgs = {
  _inc?: Maybe<Hmd_Expense_Multiplier_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Multiplier_Set_Input>;
  where: Hmd_Expense_Multiplier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Expense_Multiplier_By_PkArgs = {
  _inc?: Maybe<Hmd_Expense_Multiplier_Inc_Input>;
  _set?: Maybe<Hmd_Expense_Multiplier_Set_Input>;
  pk_columns: Hmd_Expense_Multiplier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_LifestyleArgs = {
  _inc?: Maybe<Hmd_Lifestyle_Inc_Input>;
  _set?: Maybe<Hmd_Lifestyle_Set_Input>;
  where: Hmd_Lifestyle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hmd_Lifestyle_By_PkArgs = {
  _inc?: Maybe<Hmd_Lifestyle_Inc_Input>;
  _set?: Maybe<Hmd_Lifestyle_Set_Input>;
  pk_columns: Hmd_Lifestyle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Identification_TypeArgs = {
  _inc?: Maybe<Identification_Type_Inc_Input>;
  _set?: Maybe<Identification_Type_Set_Input>;
  where: Identification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Identification_Type_By_PkArgs = {
  _inc?: Maybe<Identification_Type_Inc_Input>;
  _set?: Maybe<Identification_Type_Set_Input>;
  pk_columns: Identification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Income_SourceArgs = {
  _inc?: Maybe<Income_Source_Inc_Input>;
  _set?: Maybe<Income_Source_Set_Input>;
  where: Income_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Income_Source_By_PkArgs = {
  _inc?: Maybe<Income_Source_Inc_Input>;
  _set?: Maybe<Income_Source_Set_Input>;
  pk_columns: Income_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_IndustryArgs = {
  _inc?: Maybe<Industry_Inc_Input>;
  _set?: Maybe<Industry_Set_Input>;
  where: Industry_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Industry_By_PkArgs = {
  _inc?: Maybe<Industry_Inc_Input>;
  _set?: Maybe<Industry_Set_Input>;
  pk_columns: Industry_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Rate_Age_RangeArgs = {
  _inc?: Maybe<Insurance_Rate_Age_Range_Inc_Input>;
  _set?: Maybe<Insurance_Rate_Age_Range_Set_Input>;
  where: Insurance_Rate_Age_Range_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Rate_Age_Range_By_PkArgs = {
  _inc?: Maybe<Insurance_Rate_Age_Range_Inc_Input>;
  _set?: Maybe<Insurance_Rate_Age_Range_Set_Input>;
  pk_columns: Insurance_Rate_Age_Range_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Rate_DurationArgs = {
  _inc?: Maybe<Insurance_Rate_Duration_Inc_Input>;
  _set?: Maybe<Insurance_Rate_Duration_Set_Input>;
  where: Insurance_Rate_Duration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Rate_Duration_By_PkArgs = {
  _inc?: Maybe<Insurance_Rate_Duration_Inc_Input>;
  _set?: Maybe<Insurance_Rate_Duration_Set_Input>;
  pk_columns: Insurance_Rate_Duration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_TypeArgs = {
  _inc?: Maybe<Insurance_Type_Inc_Input>;
  _set?: Maybe<Insurance_Type_Set_Input>;
  where: Insurance_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Type_By_PkArgs = {
  _inc?: Maybe<Insurance_Type_Inc_Input>;
  _set?: Maybe<Insurance_Type_Set_Input>;
  pk_columns: Insurance_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Marital_StatusArgs = {
  _inc?: Maybe<Marital_Status_Inc_Input>;
  _set?: Maybe<Marital_Status_Set_Input>;
  where: Marital_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Marital_Status_By_PkArgs = {
  _inc?: Maybe<Marital_Status_Inc_Input>;
  _set?: Maybe<Marital_Status_Set_Input>;
  pk_columns: Marital_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MemberArgs = {
  _inc?: Maybe<Member_Inc_Input>;
  _set?: Maybe<Member_Set_Input>;
  where: Member_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_BeneficiaryArgs = {
  _inc?: Maybe<Member_Beneficiary_Inc_Input>;
  _set?: Maybe<Member_Beneficiary_Set_Input>;
  where: Member_Beneficiary_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Beneficiary_By_PkArgs = {
  _inc?: Maybe<Member_Beneficiary_Inc_Input>;
  _set?: Maybe<Member_Beneficiary_Set_Input>;
  pk_columns: Member_Beneficiary_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_By_PkArgs = {
  _inc?: Maybe<Member_Inc_Input>;
  _set?: Maybe<Member_Set_Input>;
  pk_columns: Member_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Contribution_SourceArgs = {
  _inc?: Maybe<Member_Contribution_Source_Inc_Input>;
  _set?: Maybe<Member_Contribution_Source_Set_Input>;
  where: Member_Contribution_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Contribution_Source_By_PkArgs = {
  _inc?: Maybe<Member_Contribution_Source_Inc_Input>;
  _set?: Maybe<Member_Contribution_Source_Set_Input>;
  pk_columns: Member_Contribution_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_DependentArgs = {
  _inc?: Maybe<Member_Dependent_Inc_Input>;
  _set?: Maybe<Member_Dependent_Set_Input>;
  where: Member_Dependent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Dependent_By_PkArgs = {
  _inc?: Maybe<Member_Dependent_Inc_Input>;
  _set?: Maybe<Member_Dependent_Set_Input>;
  pk_columns: Member_Dependent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_GoalArgs = {
  _inc?: Maybe<Member_Goal_Inc_Input>;
  _set?: Maybe<Member_Goal_Set_Input>;
  where: Member_Goal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Goal_By_PkArgs = {
  _inc?: Maybe<Member_Goal_Inc_Input>;
  _set?: Maybe<Member_Goal_Set_Input>;
  pk_columns: Member_Goal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_IdentificationArgs = {
  _inc?: Maybe<Member_Identification_Inc_Input>;
  _set?: Maybe<Member_Identification_Set_Input>;
  where: Member_Identification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Identification_By_PkArgs = {
  _inc?: Maybe<Member_Identification_Inc_Input>;
  _set?: Maybe<Member_Identification_Set_Input>;
  pk_columns: Member_Identification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_In_Trust_ForArgs = {
  _inc?: Maybe<Member_In_Trust_For_Inc_Input>;
  _set?: Maybe<Member_In_Trust_For_Set_Input>;
  where: Member_In_Trust_For_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_In_Trust_For_By_PkArgs = {
  _inc?: Maybe<Member_In_Trust_For_Inc_Input>;
  _set?: Maybe<Member_In_Trust_For_Set_Input>;
  pk_columns: Member_In_Trust_For_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Income_SourceArgs = {
  _inc?: Maybe<Member_Income_Source_Inc_Input>;
  _set?: Maybe<Member_Income_Source_Set_Input>;
  where: Member_Income_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Income_Source_By_PkArgs = {
  _inc?: Maybe<Member_Income_Source_Inc_Input>;
  _set?: Maybe<Member_Income_Source_Set_Input>;
  pk_columns: Member_Income_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Mode_Of_Instruction_CommunicationArgs = {
  _inc?: Maybe<Member_Mode_Of_Instruction_Communication_Inc_Input>;
  _set?: Maybe<Member_Mode_Of_Instruction_Communication_Set_Input>;
  where: Member_Mode_Of_Instruction_Communication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Mode_Of_Instruction_Communication_By_PkArgs = {
  _inc?: Maybe<Member_Mode_Of_Instruction_Communication_Inc_Input>;
  _set?: Maybe<Member_Mode_Of_Instruction_Communication_Set_Input>;
  pk_columns: Member_Mode_Of_Instruction_Communication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Payment_InstructionArgs = {
  _inc?: Maybe<Member_Payment_Instruction_Inc_Input>;
  _set?: Maybe<Member_Payment_Instruction_Set_Input>;
  where: Member_Payment_Instruction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Payment_Instruction_By_PkArgs = {
  _inc?: Maybe<Member_Payment_Instruction_Inc_Input>;
  _set?: Maybe<Member_Payment_Instruction_Set_Input>;
  pk_columns: Member_Payment_Instruction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_RedemptionArgs = {
  _inc?: Maybe<Member_Redemption_Inc_Input>;
  _set?: Maybe<Member_Redemption_Set_Input>;
  where: Member_Redemption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Redemption_By_PkArgs = {
  _inc?: Maybe<Member_Redemption_Inc_Input>;
  _set?: Maybe<Member_Redemption_Set_Input>;
  pk_columns: Member_Redemption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Member_RejectArgs = {
  _inc?: Maybe<Member_Reject_Inc_Input>;
  _set?: Maybe<Member_Reject_Set_Input>;
  where: Member_Reject_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Member_Reject_By_PkArgs = {
  _inc?: Maybe<Member_Reject_Inc_Input>;
  _set?: Maybe<Member_Reject_Set_Input>;
  pk_columns: Member_Reject_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mode_Of_Instruction_CommunicationArgs = {
  _inc?: Maybe<Mode_Of_Instruction_Communication_Inc_Input>;
  _set?: Maybe<Mode_Of_Instruction_Communication_Set_Input>;
  where: Mode_Of_Instruction_Communication_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mode_Of_Instruction_Communication_By_PkArgs = {
  _inc?: Maybe<Mode_Of_Instruction_Communication_Inc_Input>;
  _set?: Maybe<Mode_Of_Instruction_Communication_Set_Input>;
  pk_columns: Mode_Of_Instruction_Communication_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Mp_Product_SplitArgs = {
  _inc?: Maybe<Mp_Product_Split_Inc_Input>;
  _set?: Maybe<Mp_Product_Split_Set_Input>;
  where: Mp_Product_Split_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Mp_Product_Split_By_PkArgs = {
  _inc?: Maybe<Mp_Product_Split_Inc_Input>;
  _set?: Maybe<Mp_Product_Split_Set_Input>;
  pk_columns: Mp_Product_Split_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Next_Of_KinArgs = {
  _inc?: Maybe<Next_Of_Kin_Inc_Input>;
  _set?: Maybe<Next_Of_Kin_Set_Input>;
  where: Next_Of_Kin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Next_Of_Kin_By_PkArgs = {
  _inc?: Maybe<Next_Of_Kin_Inc_Input>;
  _set?: Maybe<Next_Of_Kin_Set_Input>;
  pk_columns: Next_Of_Kin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ChannelArgs = {
  _inc?: Maybe<Payment_Channel_Inc_Input>;
  _set?: Maybe<Payment_Channel_Set_Input>;
  where: Payment_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Channel_By_PkArgs = {
  _inc?: Maybe<Payment_Channel_Inc_Input>;
  _set?: Maybe<Payment_Channel_Set_Input>;
  pk_columns: Payment_Channel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ModeArgs = {
  _inc?: Maybe<Payment_Mode_Inc_Input>;
  _set?: Maybe<Payment_Mode_Set_Input>;
  where: Payment_Mode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Mode_By_PkArgs = {
  _inc?: Maybe<Payment_Mode_Inc_Input>;
  _set?: Maybe<Payment_Mode_Set_Input>;
  pk_columns: Payment_Mode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ProviderArgs = {
  _inc?: Maybe<Payment_Provider_Inc_Input>;
  _set?: Maybe<Payment_Provider_Set_Input>;
  where: Payment_Provider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Provider_By_PkArgs = {
  _inc?: Maybe<Payment_Provider_Inc_Input>;
  _set?: Maybe<Payment_Provider_Set_Input>;
  pk_columns: Payment_Provider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ShareArgs = {
  _inc?: Maybe<Payment_Share_Inc_Input>;
  _set?: Maybe<Payment_Share_Set_Input>;
  where: Payment_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Share_By_PkArgs = {
  _inc?: Maybe<Payment_Share_Inc_Input>;
  _set?: Maybe<Payment_Share_Set_Input>;
  pk_columns: Payment_Share_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PermissionArgs = {
  _inc?: Maybe<Permission_Inc_Input>;
  _set?: Maybe<Permission_Set_Input>;
  where: Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Permission_By_PkArgs = {
  _inc?: Maybe<Permission_Inc_Input>;
  _set?: Maybe<Permission_Set_Input>;
  pk_columns: Permission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PriceArgs = {
  _inc?: Maybe<Price_Inc_Input>;
  _set?: Maybe<Price_Set_Input>;
  where: Price_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Price_By_PkArgs = {
  _inc?: Maybe<Price_Inc_Input>;
  _set?: Maybe<Price_Set_Input>;
  pk_columns: Price_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Redemption_ShareArgs = {
  _inc?: Maybe<Redemption_Share_Inc_Input>;
  _set?: Maybe<Redemption_Share_Set_Input>;
  where: Redemption_Share_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Redemption_Share_By_PkArgs = {
  _inc?: Maybe<Redemption_Share_Inc_Input>;
  _set?: Maybe<Redemption_Share_Set_Input>;
  pk_columns: Redemption_Share_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RegionArgs = {
  _inc?: Maybe<Region_Inc_Input>;
  _set?: Maybe<Region_Set_Input>;
  where: Region_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Region_By_PkArgs = {
  _inc?: Maybe<Region_Inc_Input>;
  _set?: Maybe<Region_Set_Input>;
  pk_columns: Region_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RelationshipArgs = {
  _inc?: Maybe<Relationship_Inc_Input>;
  _set?: Maybe<Relationship_Set_Input>;
  where: Relationship_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Relationship_By_PkArgs = {
  _inc?: Maybe<Relationship_Inc_Input>;
  _set?: Maybe<Relationship_Set_Input>;
  pk_columns: Relationship_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _inc?: Maybe<Role_Inc_Input>;
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _inc?: Maybe<Role_Inc_Input>;
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_PermissionArgs = {
  _inc?: Maybe<Role_Permission_Inc_Input>;
  _set?: Maybe<Role_Permission_Set_Input>;
  where: Role_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Permission_By_PkArgs = {
  _inc?: Maybe<Role_Permission_Inc_Input>;
  _set?: Maybe<Role_Permission_Set_Input>;
  pk_columns: Role_Permission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_StatusArgs = {
  _inc?: Maybe<Status_Inc_Input>;
  _set?: Maybe<Status_Set_Input>;
  where: Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Status_By_PkArgs = {
  _inc?: Maybe<Status_Inc_Input>;
  _set?: Maybe<Status_Set_Input>;
  pk_columns: Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TitleArgs = {
  _inc?: Maybe<Title_Inc_Input>;
  _set?: Maybe<Title_Set_Input>;
  where: Title_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Title_By_PkArgs = {
  _inc?: Maybe<Title_Inc_Input>;
  _set?: Maybe<Title_Set_Input>;
  pk_columns: Title_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UniversityArgs = {
  _inc?: Maybe<University_Inc_Input>;
  _set?: Maybe<University_Set_Input>;
  where: University_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_University_By_PkArgs = {
  _inc?: Maybe<University_Inc_Input>;
  _set?: Maybe<University_Set_Input>;
  pk_columns: University_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Activity_LogArgs = {
  _inc?: Maybe<User_Activity_Log_Inc_Input>;
  _set?: Maybe<User_Activity_Log_Set_Input>;
  where: User_Activity_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Activity_Log_By_PkArgs = {
  _inc?: Maybe<User_Activity_Log_Inc_Input>;
  _set?: Maybe<User_Activity_Log_Set_Input>;
  pk_columns: User_Activity_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _inc?: Maybe<User_Role_Inc_Input>;
  _set?: Maybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _inc?: Maybe<User_Role_Inc_Input>;
  _set?: Maybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};

/** columns and relationships of "next_of_kin" */
export type Next_Of_Kin = {
  __typename?: 'next_of_kin';
  /** An object relationship */
  country_of_origin?: Maybe<Country>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  gender?: Maybe<Gender>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid: Scalars['uuid'];
  home_address?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  /** An object relationship */
  region?: Maybe<Region>;
  region_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  relationship?: Maybe<Relationship>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "next_of_kin" */
export type Next_Of_KinMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "next_of_kin" */
export type Next_Of_KinMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};

/** aggregated selection of "next_of_kin" */
export type Next_Of_Kin_Aggregate = {
  __typename?: 'next_of_kin_aggregate';
  aggregate?: Maybe<Next_Of_Kin_Aggregate_Fields>;
  nodes: Array<Next_Of_Kin>;
};

/** aggregate fields of "next_of_kin" */
export type Next_Of_Kin_Aggregate_Fields = {
  __typename?: 'next_of_kin_aggregate_fields';
  avg?: Maybe<Next_Of_Kin_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Next_Of_Kin_Max_Fields>;
  min?: Maybe<Next_Of_Kin_Min_Fields>;
  stddev?: Maybe<Next_Of_Kin_Stddev_Fields>;
  stddev_pop?: Maybe<Next_Of_Kin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Next_Of_Kin_Stddev_Samp_Fields>;
  sum?: Maybe<Next_Of_Kin_Sum_Fields>;
  var_pop?: Maybe<Next_Of_Kin_Var_Pop_Fields>;
  var_samp?: Maybe<Next_Of_Kin_Var_Samp_Fields>;
  variance?: Maybe<Next_Of_Kin_Variance_Fields>;
};


/** aggregate fields of "next_of_kin" */
export type Next_Of_Kin_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "next_of_kin" */
export type Next_Of_Kin_Aggregate_Order_By = {
  avg?: Maybe<Next_Of_Kin_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Next_Of_Kin_Max_Order_By>;
  min?: Maybe<Next_Of_Kin_Min_Order_By>;
  stddev?: Maybe<Next_Of_Kin_Stddev_Order_By>;
  stddev_pop?: Maybe<Next_Of_Kin_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Next_Of_Kin_Stddev_Samp_Order_By>;
  sum?: Maybe<Next_Of_Kin_Sum_Order_By>;
  var_pop?: Maybe<Next_Of_Kin_Var_Pop_Order_By>;
  var_samp?: Maybe<Next_Of_Kin_Var_Samp_Order_By>;
  variance?: Maybe<Next_Of_Kin_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "next_of_kin" */
export type Next_Of_Kin_Arr_Rel_Insert_Input = {
  data: Array<Next_Of_Kin_Insert_Input>;
  on_conflict?: Maybe<Next_Of_Kin_On_Conflict>;
};

/** aggregate avg on columns */
export type Next_Of_Kin_Avg_Fields = {
  __typename?: 'next_of_kin_avg_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "next_of_kin" */
export type Next_Of_Kin_Avg_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "next_of_kin". All fields are combined with a logical 'AND'. */
export type Next_Of_Kin_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Next_Of_Kin_Bool_Exp>>>;
  _not?: Maybe<Next_Of_Kin_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Next_Of_Kin_Bool_Exp>>>;
  country_of_origin?: Maybe<Country_Bool_Exp>;
  country_of_origin_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<Gender_Bool_Exp>;
  gender_id?: Maybe<Bigint_Comparison_Exp>;
  gps_address?: Maybe<String_Comparison_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  home_address?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  id_expiry_date?: Maybe<Date_Comparison_Exp>;
  id_issue_date?: Maybe<Date_Comparison_Exp>;
  id_number?: Maybe<String_Comparison_Exp>;
  id_type?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  marital_status?: Maybe<String_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  mobile2?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  occupation?: Maybe<String_Comparison_Exp>;
  other_names?: Maybe<String_Comparison_Exp>;
  permit_expiry_date?: Maybe<Date_Comparison_Exp>;
  permit_issue_date?: Maybe<Date_Comparison_Exp>;
  place_of_birth?: Maybe<String_Comparison_Exp>;
  profession?: Maybe<String_Comparison_Exp>;
  professional_license?: Maybe<String_Comparison_Exp>;
  region?: Maybe<Region_Bool_Exp>;
  region_id?: Maybe<Bigint_Comparison_Exp>;
  relationship?: Maybe<Relationship_Bool_Exp>;
  relationship_id?: Maybe<Bigint_Comparison_Exp>;
  residential_permit_number?: Maybe<String_Comparison_Exp>;
  residential_place_of_issue?: Maybe<String_Comparison_Exp>;
  residential_status?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "next_of_kin" */
export enum Next_Of_Kin_Constraint {
  /** unique or primary key constraint */
  NextOfKinGuidKey = 'next_of_kin_guid_key',
  /** unique or primary key constraint */
  NextOfKinPkey = 'next_of_kin_pkey'
}

/** input type for incrementing integer column in table "next_of_kin" */
export type Next_Of_Kin_Inc_Input = {
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "next_of_kin" */
export type Next_Of_Kin_Insert_Input = {
  country_of_origin?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender_Obj_Rel_Insert_Input>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region?: Maybe<Region_Obj_Rel_Insert_Input>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship?: Maybe<Relationship_Obj_Rel_Insert_Input>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Next_Of_Kin_Max_Fields = {
  __typename?: 'next_of_kin_max_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "next_of_kin" */
export type Next_Of_Kin_Max_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Next_Of_Kin_Min_Fields = {
  __typename?: 'next_of_kin_min_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "next_of_kin" */
export type Next_Of_Kin_Min_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "next_of_kin" */
export type Next_Of_Kin_Mutation_Response = {
  __typename?: 'next_of_kin_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Next_Of_Kin>;
};

/** input type for inserting object relation for remote table "next_of_kin" */
export type Next_Of_Kin_Obj_Rel_Insert_Input = {
  data: Next_Of_Kin_Insert_Input;
  on_conflict?: Maybe<Next_Of_Kin_On_Conflict>;
};

/** on conflict condition type for table "next_of_kin" */
export type Next_Of_Kin_On_Conflict = {
  constraint: Next_Of_Kin_Constraint;
  update_columns: Array<Next_Of_Kin_Update_Column>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};

/** ordering options when selecting data from "next_of_kin" */
export type Next_Of_Kin_Order_By = {
  country_of_origin?: Maybe<Country_Order_By>;
  country_of_origin_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  gender?: Maybe<Gender_Order_By>;
  gender_id?: Maybe<Order_By>;
  gps_address?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  home_address?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  id_expiry_date?: Maybe<Order_By>;
  id_issue_date?: Maybe<Order_By>;
  id_number?: Maybe<Order_By>;
  id_type?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  marital_status?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  mobile?: Maybe<Order_By>;
  mobile2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  occupation?: Maybe<Order_By>;
  other_names?: Maybe<Order_By>;
  permit_expiry_date?: Maybe<Order_By>;
  permit_issue_date?: Maybe<Order_By>;
  place_of_birth?: Maybe<Order_By>;
  profession?: Maybe<Order_By>;
  professional_license?: Maybe<Order_By>;
  region?: Maybe<Region_Order_By>;
  region_id?: Maybe<Order_By>;
  relationship?: Maybe<Relationship_Order_By>;
  relationship_id?: Maybe<Order_By>;
  residential_permit_number?: Maybe<Order_By>;
  residential_place_of_issue?: Maybe<Order_By>;
  residential_status?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "next_of_kin" */
export type Next_Of_Kin_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "next_of_kin" */
export enum Next_Of_Kin_Select_Column {
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNames = 'other_names',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "next_of_kin" */
export type Next_Of_Kin_Set_Input = {
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gps_address?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['uuid']>;
  home_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  id_expiry_date?: Maybe<Scalars['date']>;
  id_issue_date?: Maybe<Scalars['date']>;
  id_number?: Maybe<Scalars['String']>;
  id_type?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  marital_status?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  occupation?: Maybe<Scalars['String']>;
  other_names?: Maybe<Scalars['String']>;
  permit_expiry_date?: Maybe<Scalars['date']>;
  permit_issue_date?: Maybe<Scalars['date']>;
  place_of_birth?: Maybe<Scalars['String']>;
  profession?: Maybe<Scalars['String']>;
  professional_license?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
  residential_permit_number?: Maybe<Scalars['String']>;
  residential_place_of_issue?: Maybe<Scalars['String']>;
  residential_status?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Next_Of_Kin_Stddev_Fields = {
  __typename?: 'next_of_kin_stddev_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "next_of_kin" */
export type Next_Of_Kin_Stddev_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Next_Of_Kin_Stddev_Pop_Fields = {
  __typename?: 'next_of_kin_stddev_pop_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "next_of_kin" */
export type Next_Of_Kin_Stddev_Pop_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Next_Of_Kin_Stddev_Samp_Fields = {
  __typename?: 'next_of_kin_stddev_samp_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "next_of_kin" */
export type Next_Of_Kin_Stddev_Samp_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Next_Of_Kin_Sum_Fields = {
  __typename?: 'next_of_kin_sum_fields';
  country_of_origin_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  region_id?: Maybe<Scalars['bigint']>;
  relationship_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "next_of_kin" */
export type Next_Of_Kin_Sum_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** update columns of table "next_of_kin" */
export enum Next_Of_Kin_Update_Column {
  /** column name */
  CountryOfOriginId = 'country_of_origin_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GpsAddress = 'gps_address',
  /** column name */
  Guid = 'guid',
  /** column name */
  HomeAddress = 'home_address',
  /** column name */
  Id = 'id',
  /** column name */
  IdExpiryDate = 'id_expiry_date',
  /** column name */
  IdIssueDate = 'id_issue_date',
  /** column name */
  IdNumber = 'id_number',
  /** column name */
  IdType = 'id_type',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MaritalStatus = 'marital_status',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Mobile2 = 'mobile2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  OtherNames = 'other_names',
  /** column name */
  PermitExpiryDate = 'permit_expiry_date',
  /** column name */
  PermitIssueDate = 'permit_issue_date',
  /** column name */
  PlaceOfBirth = 'place_of_birth',
  /** column name */
  Profession = 'profession',
  /** column name */
  ProfessionalLicense = 'professional_license',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  RelationshipId = 'relationship_id',
  /** column name */
  ResidentialPermitNumber = 'residential_permit_number',
  /** column name */
  ResidentialPlaceOfIssue = 'residential_place_of_issue',
  /** column name */
  ResidentialStatus = 'residential_status',
  /** column name */
  Tin = 'tin',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Next_Of_Kin_Var_Pop_Fields = {
  __typename?: 'next_of_kin_var_pop_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "next_of_kin" */
export type Next_Of_Kin_Var_Pop_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Next_Of_Kin_Var_Samp_Fields = {
  __typename?: 'next_of_kin_var_samp_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "next_of_kin" */
export type Next_Of_Kin_Var_Samp_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Next_Of_Kin_Variance_Fields = {
  __typename?: 'next_of_kin_variance_fields';
  country_of_origin_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  relationship_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "next_of_kin" */
export type Next_Of_Kin_Variance_Order_By = {
  country_of_origin_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  region_id?: Maybe<Order_By>;
  relationship_id?: Maybe<Order_By>;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};


/** expression to compare columns of type oid. All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: Maybe<Scalars['oid']>;
  _gt?: Maybe<Scalars['oid']>;
  _gte?: Maybe<Scalars['oid']>;
  _in?: Maybe<Array<Scalars['oid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['oid']>;
  _lte?: Maybe<Scalars['oid']>;
  _neq?: Maybe<Scalars['oid']>;
  _nin?: Maybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment';
  amount: Scalars['numeric'];
  batch_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregated array relationship */
  deals_aggregate: Deal_Aggregate;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  is_deals_migrated?: Maybe<Scalars['Boolean']>;
  is_migrated: Scalars['Boolean'];
  /** An object relationship */
  member?: Maybe<Member>;
  member_id?: Maybe<Scalars['bigint']>;
  mf_id?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  mp_product_splits: Array<Mp_Product_Split>;
  /** An aggregated array relationship */
  mp_product_splits_aggregate: Mp_Product_Split_Aggregate;
  payment_date: Scalars['date'];
  /** An object relationship */
  payment_mode?: Maybe<Payment_Mode>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  payment_shares: Array<Payment_Share>;
  /** An aggregated array relationship */
  payment_shares_aggregate: Payment_Share_Aggregate;
  psl_id?: Maybe<Scalars['String']>;
  reference: Scalars['uuid'];
  /** An object relationship */
  status: Status;
  status_id: Scalars['bigint'];
  transaction_details?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
  value_date: Scalars['date'];
};


/** columns and relationships of "payment" */
export type PaymentDealsArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentMp_Product_SplitsArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentMp_Product_Splits_AggregateArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentPayment_SharesArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentPayment_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};

/** aggregated selection of "payment" */
export type Payment_Aggregate = {
  __typename?: 'payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

/** aggregate fields of "payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: Maybe<Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Max_Order_By>;
  min?: Maybe<Payment_Min_Order_By>;
  stddev?: Maybe<Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Sum_Order_By>;
  var_pop?: Maybe<Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'payment_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payment_Bool_Exp>>>;
  _not?: Maybe<Payment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  batch_id?: Maybe<Bigint_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deals?: Maybe<Deal_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  is_deals_migrated?: Maybe<Boolean_Comparison_Exp>;
  is_migrated?: Maybe<Boolean_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  member_id?: Maybe<Bigint_Comparison_Exp>;
  mf_id?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  mp_product_splits?: Maybe<Mp_Product_Split_Bool_Exp>;
  payment_date?: Maybe<Date_Comparison_Exp>;
  payment_mode?: Maybe<Payment_Mode_Bool_Exp>;
  payment_mode_id?: Maybe<Bigint_Comparison_Exp>;
  payment_shares?: Maybe<Payment_Share_Bool_Exp>;
  psl_id?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Status_Bool_Exp>;
  status_id?: Maybe<Bigint_Comparison_Exp>;
  transaction_details?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
  value_date?: Maybe<Date_Comparison_Exp>;
};

/** columns and relationships of "payment_channel" */
export type Payment_Channel = {
  __typename?: 'payment_channel';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  has_branch: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  payment_providers: Array<Payment_Provider>;
  /** An aggregated array relationship */
  payment_providers_aggregate: Payment_Provider_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "payment_channel" */
export type Payment_ChannelPayment_ProvidersArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};


/** columns and relationships of "payment_channel" */
export type Payment_ChannelPayment_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};

/** aggregated selection of "payment_channel" */
export type Payment_Channel_Aggregate = {
  __typename?: 'payment_channel_aggregate';
  aggregate?: Maybe<Payment_Channel_Aggregate_Fields>;
  nodes: Array<Payment_Channel>;
};

/** aggregate fields of "payment_channel" */
export type Payment_Channel_Aggregate_Fields = {
  __typename?: 'payment_channel_aggregate_fields';
  avg?: Maybe<Payment_Channel_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Channel_Max_Fields>;
  min?: Maybe<Payment_Channel_Min_Fields>;
  stddev?: Maybe<Payment_Channel_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Channel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Channel_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Channel_Sum_Fields>;
  var_pop?: Maybe<Payment_Channel_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Channel_Var_Samp_Fields>;
  variance?: Maybe<Payment_Channel_Variance_Fields>;
};


/** aggregate fields of "payment_channel" */
export type Payment_Channel_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Channel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_channel" */
export type Payment_Channel_Aggregate_Order_By = {
  avg?: Maybe<Payment_Channel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Channel_Max_Order_By>;
  min?: Maybe<Payment_Channel_Min_Order_By>;
  stddev?: Maybe<Payment_Channel_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Channel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Channel_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Channel_Sum_Order_By>;
  var_pop?: Maybe<Payment_Channel_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Channel_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Channel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_channel" */
export type Payment_Channel_Arr_Rel_Insert_Input = {
  data: Array<Payment_Channel_Insert_Input>;
  on_conflict?: Maybe<Payment_Channel_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Channel_Avg_Fields = {
  __typename?: 'payment_channel_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_channel" */
export type Payment_Channel_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_channel". All fields are combined with a logical 'AND'. */
export type Payment_Channel_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payment_Channel_Bool_Exp>>>;
  _not?: Maybe<Payment_Channel_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Channel_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  has_branch?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_providers?: Maybe<Payment_Provider_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_channel" */
export enum Payment_Channel_Constraint {
  /** unique or primary key constraint */
  PaymentChannelNameKey = 'payment_channel_name_key',
  /** unique or primary key constraint */
  PaymentChannelPkey = 'payment_channel_pkey'
}

/** input type for incrementing integer column in table "payment_channel" */
export type Payment_Channel_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment_channel" */
export type Payment_Channel_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  has_branch?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_providers?: Maybe<Payment_Provider_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payment_Channel_Max_Fields = {
  __typename?: 'payment_channel_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment_channel" */
export type Payment_Channel_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Channel_Min_Fields = {
  __typename?: 'payment_channel_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment_channel" */
export type Payment_Channel_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment_channel" */
export type Payment_Channel_Mutation_Response = {
  __typename?: 'payment_channel_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment_Channel>;
};

/** input type for inserting object relation for remote table "payment_channel" */
export type Payment_Channel_Obj_Rel_Insert_Input = {
  data: Payment_Channel_Insert_Input;
  on_conflict?: Maybe<Payment_Channel_On_Conflict>;
};

/** on conflict condition type for table "payment_channel" */
export type Payment_Channel_On_Conflict = {
  constraint: Payment_Channel_Constraint;
  update_columns: Array<Payment_Channel_Update_Column>;
  where?: Maybe<Payment_Channel_Bool_Exp>;
};

/** ordering options when selecting data from "payment_channel" */
export type Payment_Channel_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  has_branch?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_providers_aggregate?: Maybe<Payment_Provider_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "payment_channel" */
export type Payment_Channel_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "payment_channel" */
export enum Payment_Channel_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  HasBranch = 'has_branch',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_channel" */
export type Payment_Channel_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  has_branch?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payment_Channel_Stddev_Fields = {
  __typename?: 'payment_channel_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_channel" */
export type Payment_Channel_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Channel_Stddev_Pop_Fields = {
  __typename?: 'payment_channel_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_channel" */
export type Payment_Channel_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Channel_Stddev_Samp_Fields = {
  __typename?: 'payment_channel_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_channel" */
export type Payment_Channel_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Channel_Sum_Fields = {
  __typename?: 'payment_channel_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment_channel" */
export type Payment_Channel_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "payment_channel" */
export enum Payment_Channel_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  HasBranch = 'has_branch',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Payment_Channel_Var_Pop_Fields = {
  __typename?: 'payment_channel_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_channel" */
export type Payment_Channel_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Channel_Var_Samp_Fields = {
  __typename?: 'payment_channel_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_channel" */
export type Payment_Channel_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Channel_Variance_Fields = {
  __typename?: 'payment_channel_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_channel" */
export type Payment_Channel_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint */
  PaymentPkey = 'payment_pkey',
  /** unique or primary key constraint */
  PaymentReferenceKey = 'payment_reference_key'
}

/** input type for incrementing integer column in table "payment" */
export type Payment_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment" */
export type Payment_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deals?: Maybe<Deal_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_deals_migrated?: Maybe<Scalars['Boolean']>;
  is_migrated?: Maybe<Scalars['Boolean']>;
  member?: Maybe<Member_Obj_Rel_Insert_Input>;
  member_id?: Maybe<Scalars['bigint']>;
  mf_id?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  mp_product_splits?: Maybe<Mp_Product_Split_Arr_Rel_Insert_Input>;
  payment_date?: Maybe<Scalars['date']>;
  payment_mode?: Maybe<Payment_Mode_Obj_Rel_Insert_Input>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  payment_shares?: Maybe<Payment_Share_Arr_Rel_Insert_Input>;
  psl_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['uuid']>;
  status?: Maybe<Status_Obj_Rel_Insert_Input>;
  status_id?: Maybe<Scalars['bigint']>;
  transaction_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
  value_date?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'payment_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mf_id?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_date?: Maybe<Scalars['date']>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  psl_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['bigint']>;
  transaction_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mf_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_date?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  psl_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  transaction_details?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'payment_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  mf_id?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_date?: Maybe<Scalars['date']>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  psl_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['bigint']>;
  transaction_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  mf_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_date?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  psl_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
  transaction_details?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value_date?: Maybe<Order_By>;
};

/** columns and relationships of "payment_mode" */
export type Payment_Mode = {
  __typename?: 'payment_mode';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregated array relationship */
  payments_aggregate: Payment_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "payment_mode" */
export type Payment_ModePaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "payment_mode" */
export type Payment_ModePayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** aggregated selection of "payment_mode" */
export type Payment_Mode_Aggregate = {
  __typename?: 'payment_mode_aggregate';
  aggregate?: Maybe<Payment_Mode_Aggregate_Fields>;
  nodes: Array<Payment_Mode>;
};

/** aggregate fields of "payment_mode" */
export type Payment_Mode_Aggregate_Fields = {
  __typename?: 'payment_mode_aggregate_fields';
  avg?: Maybe<Payment_Mode_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Mode_Max_Fields>;
  min?: Maybe<Payment_Mode_Min_Fields>;
  stddev?: Maybe<Payment_Mode_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Mode_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Mode_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Mode_Sum_Fields>;
  var_pop?: Maybe<Payment_Mode_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Mode_Var_Samp_Fields>;
  variance?: Maybe<Payment_Mode_Variance_Fields>;
};


/** aggregate fields of "payment_mode" */
export type Payment_Mode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Mode_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_mode" */
export type Payment_Mode_Aggregate_Order_By = {
  avg?: Maybe<Payment_Mode_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Mode_Max_Order_By>;
  min?: Maybe<Payment_Mode_Min_Order_By>;
  stddev?: Maybe<Payment_Mode_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Mode_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Mode_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Mode_Sum_Order_By>;
  var_pop?: Maybe<Payment_Mode_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Mode_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Mode_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_mode" */
export type Payment_Mode_Arr_Rel_Insert_Input = {
  data: Array<Payment_Mode_Insert_Input>;
  on_conflict?: Maybe<Payment_Mode_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Mode_Avg_Fields = {
  __typename?: 'payment_mode_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_mode" */
export type Payment_Mode_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_mode". All fields are combined with a logical 'AND'. */
export type Payment_Mode_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payment_Mode_Bool_Exp>>>;
  _not?: Maybe<Payment_Mode_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Mode_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_mode" */
export enum Payment_Mode_Constraint {
  /** unique or primary key constraint */
  PaymentModePkey = 'payment_mode_pkey'
}

/** input type for incrementing integer column in table "payment_mode" */
export type Payment_Mode_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment_mode" */
export type Payment_Mode_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payment_Mode_Max_Fields = {
  __typename?: 'payment_mode_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment_mode" */
export type Payment_Mode_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Mode_Min_Fields = {
  __typename?: 'payment_mode_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment_mode" */
export type Payment_Mode_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment_mode" */
export type Payment_Mode_Mutation_Response = {
  __typename?: 'payment_mode_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment_Mode>;
};

/** input type for inserting object relation for remote table "payment_mode" */
export type Payment_Mode_Obj_Rel_Insert_Input = {
  data: Payment_Mode_Insert_Input;
  on_conflict?: Maybe<Payment_Mode_On_Conflict>;
};

/** on conflict condition type for table "payment_mode" */
export type Payment_Mode_On_Conflict = {
  constraint: Payment_Mode_Constraint;
  update_columns: Array<Payment_Mode_Update_Column>;
  where?: Maybe<Payment_Mode_Bool_Exp>;
};

/** ordering options when selecting data from "payment_mode" */
export type Payment_Mode_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "payment_mode" */
export type Payment_Mode_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "payment_mode" */
export enum Payment_Mode_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_mode" */
export type Payment_Mode_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payment_Mode_Stddev_Fields = {
  __typename?: 'payment_mode_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_mode" */
export type Payment_Mode_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Mode_Stddev_Pop_Fields = {
  __typename?: 'payment_mode_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_mode" */
export type Payment_Mode_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Mode_Stddev_Samp_Fields = {
  __typename?: 'payment_mode_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_mode" */
export type Payment_Mode_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Mode_Sum_Fields = {
  __typename?: 'payment_mode_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment_mode" */
export type Payment_Mode_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "payment_mode" */
export enum Payment_Mode_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Payment_Mode_Var_Pop_Fields = {
  __typename?: 'payment_mode_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_mode" */
export type Payment_Mode_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Mode_Var_Samp_Fields = {
  __typename?: 'payment_mode_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_mode" */
export type Payment_Mode_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Mode_Variance_Fields = {
  __typename?: 'payment_mode_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_mode" */
export type Payment_Mode_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment" */
export type Payment_Mutation_Response = {
  __typename?: 'payment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "payment" */
export type Payment_Obj_Rel_Insert_Input = {
  data: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** on conflict condition type for table "payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns: Array<Payment_Update_Column>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** ordering options when selecting data from "payment" */
export type Payment_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deals_aggregate?: Maybe<Deal_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deals_migrated?: Maybe<Order_By>;
  is_migrated?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  member_id?: Maybe<Order_By>;
  mf_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  mp_product_splits_aggregate?: Maybe<Mp_Product_Split_Aggregate_Order_By>;
  payment_date?: Maybe<Order_By>;
  payment_mode?: Maybe<Payment_Mode_Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  payment_shares_aggregate?: Maybe<Payment_Share_Aggregate_Order_By>;
  psl_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  status?: Maybe<Status_Order_By>;
  status_id?: Maybe<Order_By>;
  transaction_details?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
  value_date?: Maybe<Order_By>;
};

/** primary key columns input for table: "payment" */
export type Payment_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "payment_provider" */
export type Payment_Provider = {
  __typename?: 'payment_provider';
  /** An array relationship */
  branches: Array<Branch>;
  /** An aggregated array relationship */
  branches_aggregate: Branch_Aggregate;
  code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_payment_instructions: Array<Member_Payment_Instruction>;
  /** An aggregated array relationship */
  member_payment_instructions_aggregate: Member_Payment_Instruction_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An object relationship */
  payment_channel: Payment_Channel;
  payment_channel_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "payment_provider" */
export type Payment_ProviderBranchesArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** columns and relationships of "payment_provider" */
export type Payment_ProviderBranches_AggregateArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** columns and relationships of "payment_provider" */
export type Payment_ProviderMember_Payment_InstructionsArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** columns and relationships of "payment_provider" */
export type Payment_ProviderMember_Payment_Instructions_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};

/** aggregated selection of "payment_provider" */
export type Payment_Provider_Aggregate = {
  __typename?: 'payment_provider_aggregate';
  aggregate?: Maybe<Payment_Provider_Aggregate_Fields>;
  nodes: Array<Payment_Provider>;
};

/** aggregate fields of "payment_provider" */
export type Payment_Provider_Aggregate_Fields = {
  __typename?: 'payment_provider_aggregate_fields';
  avg?: Maybe<Payment_Provider_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Provider_Max_Fields>;
  min?: Maybe<Payment_Provider_Min_Fields>;
  stddev?: Maybe<Payment_Provider_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Provider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Provider_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Provider_Sum_Fields>;
  var_pop?: Maybe<Payment_Provider_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Provider_Var_Samp_Fields>;
  variance?: Maybe<Payment_Provider_Variance_Fields>;
};


/** aggregate fields of "payment_provider" */
export type Payment_Provider_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Provider_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_provider" */
export type Payment_Provider_Aggregate_Order_By = {
  avg?: Maybe<Payment_Provider_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Provider_Max_Order_By>;
  min?: Maybe<Payment_Provider_Min_Order_By>;
  stddev?: Maybe<Payment_Provider_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Provider_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Provider_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Provider_Sum_Order_By>;
  var_pop?: Maybe<Payment_Provider_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Provider_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Provider_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_provider" */
export type Payment_Provider_Arr_Rel_Insert_Input = {
  data: Array<Payment_Provider_Insert_Input>;
  on_conflict?: Maybe<Payment_Provider_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Provider_Avg_Fields = {
  __typename?: 'payment_provider_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_provider" */
export type Payment_Provider_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_provider". All fields are combined with a logical 'AND'. */
export type Payment_Provider_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payment_Provider_Bool_Exp>>>;
  _not?: Maybe<Payment_Provider_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Provider_Bool_Exp>>>;
  branches?: Maybe<Branch_Bool_Exp>;
  code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_channel?: Maybe<Payment_Channel_Bool_Exp>;
  payment_channel_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_provider" */
export enum Payment_Provider_Constraint {
  /** unique or primary key constraint */
  PaymentProviderCodeKey = 'payment_provider_code_key',
  /** unique or primary key constraint */
  PaymentProviderNameKey = 'payment_provider_name_key',
  /** unique or primary key constraint */
  PaymentProviderPkey = 'payment_provider_pkey'
}

/** input type for incrementing integer column in table "payment_provider" */
export type Payment_Provider_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment_provider" */
export type Payment_Provider_Insert_Input = {
  branches?: Maybe<Branch_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_payment_instructions?: Maybe<Member_Payment_Instruction_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_channel?: Maybe<Payment_Channel_Obj_Rel_Insert_Input>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payment_Provider_Max_Fields = {
  __typename?: 'payment_provider_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment_provider" */
export type Payment_Provider_Max_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Provider_Min_Fields = {
  __typename?: 'payment_provider_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment_provider" */
export type Payment_Provider_Min_Order_By = {
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment_provider" */
export type Payment_Provider_Mutation_Response = {
  __typename?: 'payment_provider_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment_Provider>;
};

/** input type for inserting object relation for remote table "payment_provider" */
export type Payment_Provider_Obj_Rel_Insert_Input = {
  data: Payment_Provider_Insert_Input;
  on_conflict?: Maybe<Payment_Provider_On_Conflict>;
};

/** on conflict condition type for table "payment_provider" */
export type Payment_Provider_On_Conflict = {
  constraint: Payment_Provider_Constraint;
  update_columns: Array<Payment_Provider_Update_Column>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};

/** ordering options when selecting data from "payment_provider" */
export type Payment_Provider_Order_By = {
  branches_aggregate?: Maybe<Branch_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_payment_instructions_aggregate?: Maybe<Member_Payment_Instruction_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payment_channel?: Maybe<Payment_Channel_Order_By>;
  payment_channel_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "payment_provider" */
export type Payment_Provider_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "payment_provider" */
export enum Payment_Provider_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentChannelId = 'payment_channel_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_provider" */
export type Payment_Provider_Set_Input = {
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payment_Provider_Stddev_Fields = {
  __typename?: 'payment_provider_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_provider" */
export type Payment_Provider_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Provider_Stddev_Pop_Fields = {
  __typename?: 'payment_provider_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_provider" */
export type Payment_Provider_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Provider_Stddev_Samp_Fields = {
  __typename?: 'payment_provider_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_provider" */
export type Payment_Provider_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Provider_Sum_Fields = {
  __typename?: 'payment_provider_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_channel_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment_provider" */
export type Payment_Provider_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** update columns of table "payment_provider" */
export enum Payment_Provider_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentChannelId = 'payment_channel_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Payment_Provider_Var_Pop_Fields = {
  __typename?: 'payment_provider_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_provider" */
export type Payment_Provider_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Provider_Var_Samp_Fields = {
  __typename?: 'payment_provider_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_provider" */
export type Payment_Provider_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Provider_Variance_Fields = {
  __typename?: 'payment_provider_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_channel_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_provider" */
export type Payment_Provider_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_channel_id?: Maybe<Order_By>;
};

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  IsDealsMigrated = 'is_deals_migrated',
  /** column name */
  IsMigrated = 'is_migrated',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MfId = 'mf_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  PaymentModeId = 'payment_mode_id',
  /** column name */
  PslId = 'psl_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TransactionDetails = 'transaction_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValueDate = 'value_date'
}

/** input type for updating data in table "payment" */
export type Payment_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  is_deals_migrated?: Maybe<Scalars['Boolean']>;
  is_migrated?: Maybe<Scalars['Boolean']>;
  member_id?: Maybe<Scalars['bigint']>;
  mf_id?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_date?: Maybe<Scalars['date']>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  psl_id?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['uuid']>;
  status_id?: Maybe<Scalars['bigint']>;
  transaction_details?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value_date?: Maybe<Scalars['date']>;
};

/** columns and relationships of "payment_share" */
export type Payment_Share = {
  __typename?: 'payment_share';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  goal_amount?: Maybe<Scalars['numeric']>;
  id: Scalars['bigint'];
  /** An object relationship */
  member_goal: Member_Goal;
  member_goal_id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  payment: Payment;
  payment_id: Scalars['bigint'];
  /** An object relationship */
  product: Product;
  product_amount: Scalars['numeric'];
  product_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "payment_share" */
export type Payment_Share_Aggregate = {
  __typename?: 'payment_share_aggregate';
  aggregate?: Maybe<Payment_Share_Aggregate_Fields>;
  nodes: Array<Payment_Share>;
};

/** aggregate fields of "payment_share" */
export type Payment_Share_Aggregate_Fields = {
  __typename?: 'payment_share_aggregate_fields';
  avg?: Maybe<Payment_Share_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Share_Max_Fields>;
  min?: Maybe<Payment_Share_Min_Fields>;
  stddev?: Maybe<Payment_Share_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Share_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Share_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Share_Sum_Fields>;
  var_pop?: Maybe<Payment_Share_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Share_Var_Samp_Fields>;
  variance?: Maybe<Payment_Share_Variance_Fields>;
};


/** aggregate fields of "payment_share" */
export type Payment_Share_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Share_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_share" */
export type Payment_Share_Aggregate_Order_By = {
  avg?: Maybe<Payment_Share_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Share_Max_Order_By>;
  min?: Maybe<Payment_Share_Min_Order_By>;
  stddev?: Maybe<Payment_Share_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Share_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Share_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Share_Sum_Order_By>;
  var_pop?: Maybe<Payment_Share_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Share_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Share_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_share" */
export type Payment_Share_Arr_Rel_Insert_Input = {
  data: Array<Payment_Share_Insert_Input>;
  on_conflict?: Maybe<Payment_Share_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Share_Avg_Fields = {
  __typename?: 'payment_share_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_share" */
export type Payment_Share_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_share". All fields are combined with a logical 'AND'. */
export type Payment_Share_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payment_Share_Bool_Exp>>>;
  _not?: Maybe<Payment_Share_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Share_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  goal_amount?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_goal?: Maybe<Member_Goal_Bool_Exp>;
  member_goal_id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  payment?: Maybe<Payment_Bool_Exp>;
  payment_id?: Maybe<Bigint_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_amount?: Maybe<Numeric_Comparison_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_share" */
export enum Payment_Share_Constraint {
  /** unique or primary key constraint */
  PaymentSharePkey = 'payment_share_pkey'
}

/** input type for incrementing integer column in table "payment_share" */
export type Payment_Share_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payment_share" */
export type Payment_Share_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal?: Maybe<Member_Goal_Obj_Rel_Insert_Input>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment?: Maybe<Payment_Obj_Rel_Insert_Input>;
  payment_id?: Maybe<Scalars['bigint']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payment_Share_Max_Fields = {
  __typename?: 'payment_share_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payment_share" */
export type Payment_Share_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Share_Min_Fields = {
  __typename?: 'payment_share_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payment_share" */
export type Payment_Share_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment_share" */
export type Payment_Share_Mutation_Response = {
  __typename?: 'payment_share_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment_Share>;
};

/** input type for inserting object relation for remote table "payment_share" */
export type Payment_Share_Obj_Rel_Insert_Input = {
  data: Payment_Share_Insert_Input;
  on_conflict?: Maybe<Payment_Share_On_Conflict>;
};

/** on conflict condition type for table "payment_share" */
export type Payment_Share_On_Conflict = {
  constraint: Payment_Share_Constraint;
  update_columns: Array<Payment_Share_Update_Column>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};

/** ordering options when selecting data from "payment_share" */
export type Payment_Share_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal?: Maybe<Member_Goal_Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment?: Maybe<Payment_Order_By>;
  payment_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "payment_share" */
export type Payment_Share_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "payment_share" */
export enum Payment_Share_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoalAmount = 'goal_amount',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  ProductAmount = 'product_amount',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment_share" */
export type Payment_Share_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payment_Share_Stddev_Fields = {
  __typename?: 'payment_share_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_share" */
export type Payment_Share_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Share_Stddev_Pop_Fields = {
  __typename?: 'payment_share_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_share" */
export type Payment_Share_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Share_Stddev_Samp_Fields = {
  __typename?: 'payment_share_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_share" */
export type Payment_Share_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Share_Sum_Fields = {
  __typename?: 'payment_share_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_id?: Maybe<Scalars['bigint']>;
  product_amount?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment_share" */
export type Payment_Share_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** update columns of table "payment_share" */
export enum Payment_Share_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GoalAmount = 'goal_amount',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  ProductAmount = 'product_amount',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Payment_Share_Var_Pop_Fields = {
  __typename?: 'payment_share_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_share" */
export type Payment_Share_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Share_Var_Samp_Fields = {
  __typename?: 'payment_share_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_share" */
export type Payment_Share_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Share_Variance_Fields = {
  __typename?: 'payment_share_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_id?: Maybe<Scalars['Float']>;
  product_amount?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_share" */
export type Payment_Share_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  product_amount?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'payment_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'payment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'payment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'payment_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  batch_id?: Maybe<Scalars['bigint']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  member_id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  payment_mode_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  IsDealsMigrated = 'is_deals_migrated',
  /** column name */
  IsMigrated = 'is_migrated',
  /** column name */
  MemberId = 'member_id',
  /** column name */
  MfId = 'mf_id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PaymentDate = 'payment_date',
  /** column name */
  PaymentModeId = 'payment_mode_id',
  /** column name */
  PslId = 'psl_id',
  /** column name */
  Reference = 'reference',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TransactionDetails = 'transaction_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValueDate = 'value_date'
}

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'payment_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'payment_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'payment_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  batch_id?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  payment_mode_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  batch_id?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  payment_mode_id?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** columns and relationships of "permission" */
export type Permission = {
  __typename?: 'permission';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  role_permissions: Array<Role_Permission>;
  /** An aggregated array relationship */
  role_permissions_aggregate: Role_Permission_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "permission" */
export type PermissionRole_PermissionsArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** columns and relationships of "permission" */
export type PermissionRole_Permissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};

/** aggregated selection of "permission" */
export type Permission_Aggregate = {
  __typename?: 'permission_aggregate';
  aggregate?: Maybe<Permission_Aggregate_Fields>;
  nodes: Array<Permission>;
};

/** aggregate fields of "permission" */
export type Permission_Aggregate_Fields = {
  __typename?: 'permission_aggregate_fields';
  avg?: Maybe<Permission_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Permission_Max_Fields>;
  min?: Maybe<Permission_Min_Fields>;
  stddev?: Maybe<Permission_Stddev_Fields>;
  stddev_pop?: Maybe<Permission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Permission_Stddev_Samp_Fields>;
  sum?: Maybe<Permission_Sum_Fields>;
  var_pop?: Maybe<Permission_Var_Pop_Fields>;
  var_samp?: Maybe<Permission_Var_Samp_Fields>;
  variance?: Maybe<Permission_Variance_Fields>;
};


/** aggregate fields of "permission" */
export type Permission_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Permission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "permission" */
export type Permission_Aggregate_Order_By = {
  avg?: Maybe<Permission_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Permission_Max_Order_By>;
  min?: Maybe<Permission_Min_Order_By>;
  stddev?: Maybe<Permission_Stddev_Order_By>;
  stddev_pop?: Maybe<Permission_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Permission_Stddev_Samp_Order_By>;
  sum?: Maybe<Permission_Sum_Order_By>;
  var_pop?: Maybe<Permission_Var_Pop_Order_By>;
  var_samp?: Maybe<Permission_Var_Samp_Order_By>;
  variance?: Maybe<Permission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "permission" */
export type Permission_Arr_Rel_Insert_Input = {
  data: Array<Permission_Insert_Input>;
  on_conflict?: Maybe<Permission_On_Conflict>;
};

/** aggregate avg on columns */
export type Permission_Avg_Fields = {
  __typename?: 'permission_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "permission" */
export type Permission_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "permission". All fields are combined with a logical 'AND'. */
export type Permission_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Permission_Bool_Exp>>>;
  _not?: Maybe<Permission_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Permission_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  role_permissions?: Maybe<Role_Permission_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "permission" */
export enum Permission_Constraint {
  /** unique or primary key constraint */
  PermissionPkey = 'permission_pkey'
}

/** input type for incrementing integer column in table "permission" */
export type Permission_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "permission" */
export type Permission_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  role_permissions?: Maybe<Role_Permission_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Permission_Max_Fields = {
  __typename?: 'permission_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "permission" */
export type Permission_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Permission_Min_Fields = {
  __typename?: 'permission_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "permission" */
export type Permission_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "permission" */
export type Permission_Mutation_Response = {
  __typename?: 'permission_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Permission>;
};

/** input type for inserting object relation for remote table "permission" */
export type Permission_Obj_Rel_Insert_Input = {
  data: Permission_Insert_Input;
  on_conflict?: Maybe<Permission_On_Conflict>;
};

/** on conflict condition type for table "permission" */
export type Permission_On_Conflict = {
  constraint: Permission_Constraint;
  update_columns: Array<Permission_Update_Column>;
  where?: Maybe<Permission_Bool_Exp>;
};

/** ordering options when selecting data from "permission" */
export type Permission_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  role_permissions_aggregate?: Maybe<Role_Permission_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "permission" */
export type Permission_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "permission" */
export enum Permission_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "permission" */
export type Permission_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Permission_Stddev_Fields = {
  __typename?: 'permission_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "permission" */
export type Permission_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Permission_Stddev_Pop_Fields = {
  __typename?: 'permission_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "permission" */
export type Permission_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Permission_Stddev_Samp_Fields = {
  __typename?: 'permission_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "permission" */
export type Permission_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Permission_Sum_Fields = {
  __typename?: 'permission_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "permission" */
export type Permission_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "permission" */
export enum Permission_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Permission_Var_Pop_Fields = {
  __typename?: 'permission_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "permission" */
export type Permission_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Permission_Var_Samp_Fields = {
  __typename?: 'permission_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "permission" */
export type Permission_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Permission_Variance_Fields = {
  __typename?: 'permission_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "permission" */
export type Permission_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "price" */
export type Price = {
  __typename?: 'price';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  price_date: Scalars['date'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  unit_price: Scalars['float8'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByCreatedBy?: Maybe<User>;
};

/** aggregated selection of "price" */
export type Price_Aggregate = {
  __typename?: 'price_aggregate';
  aggregate?: Maybe<Price_Aggregate_Fields>;
  nodes: Array<Price>;
};

/** aggregate fields of "price" */
export type Price_Aggregate_Fields = {
  __typename?: 'price_aggregate_fields';
  avg?: Maybe<Price_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Price_Max_Fields>;
  min?: Maybe<Price_Min_Fields>;
  stddev?: Maybe<Price_Stddev_Fields>;
  stddev_pop?: Maybe<Price_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Price_Stddev_Samp_Fields>;
  sum?: Maybe<Price_Sum_Fields>;
  var_pop?: Maybe<Price_Var_Pop_Fields>;
  var_samp?: Maybe<Price_Var_Samp_Fields>;
  variance?: Maybe<Price_Variance_Fields>;
};


/** aggregate fields of "price" */
export type Price_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Price_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "price" */
export type Price_Aggregate_Order_By = {
  avg?: Maybe<Price_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Price_Max_Order_By>;
  min?: Maybe<Price_Min_Order_By>;
  stddev?: Maybe<Price_Stddev_Order_By>;
  stddev_pop?: Maybe<Price_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Price_Stddev_Samp_Order_By>;
  sum?: Maybe<Price_Sum_Order_By>;
  var_pop?: Maybe<Price_Var_Pop_Order_By>;
  var_samp?: Maybe<Price_Var_Samp_Order_By>;
  variance?: Maybe<Price_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "price" */
export type Price_Arr_Rel_Insert_Input = {
  data: Array<Price_Insert_Input>;
  on_conflict?: Maybe<Price_On_Conflict>;
};

/** aggregate avg on columns */
export type Price_Avg_Fields = {
  __typename?: 'price_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "price" */
export type Price_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "price". All fields are combined with a logical 'AND'. */
export type Price_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Price_Bool_Exp>>>;
  _not?: Maybe<Price_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Price_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  price_date?: Maybe<Date_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  unit_price?: Maybe<Float8_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userByCreatedBy?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "price" */
export enum Price_Constraint {
  /** unique or primary key constraint */
  PricePkey = 'price_pkey',
  /** unique or primary key constraint */
  PriceProductIdPriceDateKey = 'price_product_id_price_date_key'
}

/** input type for incrementing integer column in table "price" */
export type Price_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "price" */
export type Price_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  price_date?: Maybe<Scalars['date']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  userByCreatedBy?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Price_Max_Fields = {
  __typename?: 'price_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  price_date?: Maybe<Scalars['date']>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "price" */
export type Price_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  price_date?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Price_Min_Fields = {
  __typename?: 'price_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  price_date?: Maybe<Scalars['date']>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "price" */
export type Price_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  price_date?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "price" */
export type Price_Mutation_Response = {
  __typename?: 'price_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Price>;
};

/** input type for inserting object relation for remote table "price" */
export type Price_Obj_Rel_Insert_Input = {
  data: Price_Insert_Input;
  on_conflict?: Maybe<Price_On_Conflict>;
};

/** on conflict condition type for table "price" */
export type Price_On_Conflict = {
  constraint: Price_Constraint;
  update_columns: Array<Price_Update_Column>;
  where?: Maybe<Price_Bool_Exp>;
};

/** ordering options when selecting data from "price" */
export type Price_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  price_date?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userByCreatedBy?: Maybe<User_Order_By>;
};

/** primary key columns input for table: "price" */
export type Price_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "price" */
export enum Price_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PriceDate = 'price_date',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UnitPrice = 'unit_price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "price" */
export type Price_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  price_date?: Maybe<Scalars['date']>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Price_Stddev_Fields = {
  __typename?: 'price_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "price" */
export type Price_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Price_Stddev_Pop_Fields = {
  __typename?: 'price_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "price" */
export type Price_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Price_Stddev_Samp_Fields = {
  __typename?: 'price_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "price" */
export type Price_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Price_Sum_Fields = {
  __typename?: 'price_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  unit_price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "price" */
export type Price_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** update columns of table "price" */
export enum Price_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PriceDate = 'price_date',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UnitPrice = 'unit_price',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Price_Var_Pop_Fields = {
  __typename?: 'price_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "price" */
export type Price_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Price_Var_Samp_Fields = {
  __typename?: 'price_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "price" */
export type Price_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Price_Variance_Fields = {
  __typename?: 'price_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "price" */
export type Price_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  unit_price?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  /** An array relationship */
  asset_allocations: Array<Asset_Allocation>;
  /** An aggregated array relationship */
  asset_allocations_aggregate: Asset_Allocation_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  deal_shares: Array<Deal_Share>;
  /** An aggregated array relationship */
  deal_shares_aggregate: Deal_Share_Aggregate;
  deleted: Scalars['Boolean'];
  fund_code?: Maybe<Scalars['String']>;
  /** An array relationship */
  goal_products: Array<Goal_Product>;
  /** An aggregated array relationship */
  goal_products_aggregate: Goal_Product_Aggregate;
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  mp_product_splits: Array<Mp_Product_Split>;
  /** An aggregated array relationship */
  mp_product_splits_aggregate: Mp_Product_Split_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  payment_shares: Array<Payment_Share>;
  /** An aggregated array relationship */
  payment_shares_aggregate: Payment_Share_Aggregate;
  /** An array relationship */
  prices: Array<Price>;
  /** An aggregated array relationship */
  prices_aggregate: Price_Aggregate;
  /** An array relationship */
  redemption_shares: Array<Redemption_Share>;
  /** An aggregated array relationship */
  redemption_shares_aggregate: Redemption_Share_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "product" */
export type ProductAsset_AllocationsArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductAsset_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductDeal_SharesArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductDeal_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductGoal_ProductsArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductGoal_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMp_Product_SplitsArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMp_Product_Splits_AggregateArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPayment_SharesArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPayment_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPricesArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductRedemption_SharesArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductRedemption_Shares_AggregateArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: Maybe<Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
  stddev?: Maybe<Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Sum_Order_By>;
  var_pop?: Maybe<Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Product_Bool_Exp>>>;
  asset_allocations?: Maybe<Asset_Allocation_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deal_shares?: Maybe<Deal_Share_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  fund_code?: Maybe<String_Comparison_Exp>;
  goal_products?: Maybe<Goal_Product_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  mp_product_splits?: Maybe<Mp_Product_Split_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payment_shares?: Maybe<Payment_Share_Bool_Exp>;
  prices?: Maybe<Price_Bool_Exp>;
  redemption_shares?: Maybe<Redemption_Share_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductFundCodeKey = 'product_fund_code_key',
  /** unique or primary key constraint */
  ProductNameKey = 'product_name_key',
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey'
}

/** input type for incrementing integer column in table "product" */
export type Product_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  asset_allocations?: Maybe<Asset_Allocation_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deal_shares?: Maybe<Deal_Share_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  fund_code?: Maybe<Scalars['String']>;
  goal_products?: Maybe<Goal_Product_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  mp_product_splits?: Maybe<Mp_Product_Split_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  payment_shares?: Maybe<Payment_Share_Arr_Rel_Insert_Input>;
  prices?: Maybe<Price_Arr_Rel_Insert_Input>;
  redemption_shares?: Maybe<Redemption_Share_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** ordering options when selecting data from "product" */
export type Product_Order_By = {
  asset_allocations_aggregate?: Maybe<Asset_Allocation_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deal_shares_aggregate?: Maybe<Deal_Share_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  fund_code?: Maybe<Order_By>;
  goal_products_aggregate?: Maybe<Goal_Product_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  mp_product_splits_aggregate?: Maybe<Mp_Product_Split_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  payment_shares_aggregate?: Maybe<Payment_Share_Aggregate_Order_By>;
  prices_aggregate?: Maybe<Price_Aggregate_Order_By>;
  redemption_shares_aggregate?: Maybe<Redemption_Share_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "product" */
export type Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FundCode = 'fund_code',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  fund_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  FundCode = 'fund_code',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "advisor" */
  advisor: Array<Advisor>;
  /** fetch aggregated fields from the table: "advisor" */
  advisor_aggregate: Advisor_Aggregate;
  /** fetch data from the table: "advisor" using primary key columns */
  advisor_by_pk?: Maybe<Advisor>;
  /** fetch data from the table: "all_tables_template" */
  all_tables_template: Array<All_Tables_Template>;
  /** fetch aggregated fields from the table: "all_tables_template" */
  all_tables_template_aggregate: All_Tables_Template_Aggregate;
  /** fetch data from the table: "all_tables_template" using primary key columns */
  all_tables_template_by_pk?: Maybe<All_Tables_Template>;
  /** fetch data from the table: "apk" */
  apk: Array<Apk>;
  /** fetch aggregated fields from the table: "apk" */
  apk_aggregate: Apk_Aggregate;
  /** fetch data from the table: "apk" using primary key columns */
  apk_by_pk?: Maybe<Apk>;
  /** fetch data from the table: "app_config" */
  app_config: Array<App_Config>;
  /** fetch aggregated fields from the table: "app_config" */
  app_config_aggregate: App_Config_Aggregate;
  /** fetch data from the table: "app_config" using primary key columns */
  app_config_by_pk?: Maybe<App_Config>;
  /** fetch data from the table: "asset_allocation" */
  asset_allocation: Array<Asset_Allocation>;
  /** fetch aggregated fields from the table: "asset_allocation" */
  asset_allocation_aggregate: Asset_Allocation_Aggregate;
  /** fetch data from the table: "asset_allocation" using primary key columns */
  asset_allocation_by_pk?: Maybe<Asset_Allocation>;
  /** fetch data from the table: "asset_duration" */
  asset_duration: Array<Asset_Duration>;
  /** fetch aggregated fields from the table: "asset_duration" */
  asset_duration_aggregate: Asset_Duration_Aggregate;
  /** fetch data from the table: "asset_duration" using primary key columns */
  asset_duration_by_pk?: Maybe<Asset_Duration>;
  /** fetch data from the table: "audit_log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" using primary key columns */
  audit_log_by_pk?: Maybe<Audit_Log>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "background_notifications" */
  background_notifications: Array<Background_Notifications>;
  /** fetch aggregated fields from the table: "background_notifications" */
  background_notifications_aggregate: Background_Notifications_Aggregate;
  /** fetch data from the table: "background_notifications" using primary key columns */
  background_notifications_by_pk?: Maybe<Background_Notifications>;
  /** fetch data from the table: "branch" */
  branch: Array<Branch>;
  /** fetch aggregated fields from the table: "branch" */
  branch_aggregate: Branch_Aggregate;
  /** fetch data from the table: "branch" using primary key columns */
  branch_by_pk?: Maybe<Branch>;
  /** fetch data from the table: "client_investment_profile" */
  client_investment_profile: Array<Client_Investment_Profile>;
  /** fetch aggregated fields from the table: "client_investment_profile" */
  client_investment_profile_aggregate: Client_Investment_Profile_Aggregate;
  /** fetch data from the table: "client_investment_profile" using primary key columns */
  client_investment_profile_by_pk?: Maybe<Client_Investment_Profile>;
  /** fetch data from the table: "contribution_source" */
  contribution_source: Array<Contribution_Source>;
  /** fetch aggregated fields from the table: "contribution_source" */
  contribution_source_aggregate: Contribution_Source_Aggregate;
  /** fetch data from the table: "contribution_source" using primary key columns */
  contribution_source_by_pk?: Maybe<Contribution_Source>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "dashboard_summary" */
  dashboard_summary: Array<Dashboard_Summary>;
  /** fetch aggregated fields from the table: "dashboard_summary" */
  dashboard_summary_aggregate: Dashboard_Summary_Aggregate;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_share" */
  deal_share: Array<Deal_Share>;
  /** fetch aggregated fields from the table: "deal_share" */
  deal_share_aggregate: Deal_Share_Aggregate;
  /** fetch data from the table: "deal_share" using primary key columns */
  deal_share_by_pk?: Maybe<Deal_Share>;
  /** fetch data from the table: "direct_debit_charge" */
  direct_debit_charge: Array<Direct_Debit_Charge>;
  /** fetch aggregated fields from the table: "direct_debit_charge" */
  direct_debit_charge_aggregate: Direct_Debit_Charge_Aggregate;
  /** fetch data from the table: "direct_debit_charge" using primary key columns */
  direct_debit_charge_by_pk?: Maybe<Direct_Debit_Charge>;
  /** fetch data from the table: "economic_indicator" */
  economic_indicator: Array<Economic_Indicator>;
  /** fetch aggregated fields from the table: "economic_indicator" */
  economic_indicator_aggregate: Economic_Indicator_Aggregate;
  /** fetch data from the table: "economic_indicator" using primary key columns */
  economic_indicator_by_pk?: Maybe<Economic_Indicator>;
  /** fetch data from the table: "expected_return" */
  expected_return: Array<Expected_Return>;
  /** fetch aggregated fields from the table: "expected_return" */
  expected_return_aggregate: Expected_Return_Aggregate;
  /** fetch data from the table: "expected_return" using primary key columns */
  expected_return_by_pk?: Maybe<Expected_Return>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gender_aggregate: Gender_Aggregate;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** fetch data from the table: "goal" */
  goal: Array<Goal>;
  /** fetch aggregated fields from the table: "goal" */
  goal_aggregate: Goal_Aggregate;
  /** fetch data from the table: "goal" using primary key columns */
  goal_by_pk?: Maybe<Goal>;
  /** fetch data from the table: "goal_insurance" */
  goal_insurance: Array<Goal_Insurance>;
  /** fetch aggregated fields from the table: "goal_insurance" */
  goal_insurance_aggregate: Goal_Insurance_Aggregate;
  /** fetch data from the table: "goal_insurance" using primary key columns */
  goal_insurance_by_pk?: Maybe<Goal_Insurance>;
  /** fetch data from the table: "goal_product" */
  goal_product: Array<Goal_Product>;
  /** fetch aggregated fields from the table: "goal_product" */
  goal_product_aggregate: Goal_Product_Aggregate;
  /** fetch data from the table: "goal_product" using primary key columns */
  goal_product_by_pk?: Maybe<Goal_Product>;
  hello: Scalars['String'];
  /** fetch data from the table: "hmd_education" */
  hmd_education: Array<Hmd_Education>;
  /** fetch aggregated fields from the table: "hmd_education" */
  hmd_education_aggregate: Hmd_Education_Aggregate;
  /** fetch data from the table: "hmd_education" using primary key columns */
  hmd_education_by_pk?: Maybe<Hmd_Education>;
  /** fetch data from the table: "hmd_expense_base" */
  hmd_expense_base: Array<Hmd_Expense_Base>;
  /** fetch aggregated fields from the table: "hmd_expense_base" */
  hmd_expense_base_aggregate: Hmd_Expense_Base_Aggregate;
  /** fetch data from the table: "hmd_expense_base" using primary key columns */
  hmd_expense_base_by_pk?: Maybe<Hmd_Expense_Base>;
  /** fetch data from the table: "hmd_expense_category" */
  hmd_expense_category: Array<Hmd_Expense_Category>;
  /** fetch aggregated fields from the table: "hmd_expense_category" */
  hmd_expense_category_aggregate: Hmd_Expense_Category_Aggregate;
  /** fetch data from the table: "hmd_expense_category" using primary key columns */
  hmd_expense_category_by_pk?: Maybe<Hmd_Expense_Category>;
  /** fetch data from the table: "hmd_expense_multiplier" */
  hmd_expense_multiplier: Array<Hmd_Expense_Multiplier>;
  /** fetch aggregated fields from the table: "hmd_expense_multiplier" */
  hmd_expense_multiplier_aggregate: Hmd_Expense_Multiplier_Aggregate;
  /** fetch data from the table: "hmd_expense_multiplier" using primary key columns */
  hmd_expense_multiplier_by_pk?: Maybe<Hmd_Expense_Multiplier>;
  /** fetch data from the table: "hmd_lifestyle" */
  hmd_lifestyle: Array<Hmd_Lifestyle>;
  /** fetch aggregated fields from the table: "hmd_lifestyle" */
  hmd_lifestyle_aggregate: Hmd_Lifestyle_Aggregate;
  /** fetch data from the table: "hmd_lifestyle" using primary key columns */
  hmd_lifestyle_by_pk?: Maybe<Hmd_Lifestyle>;
  /** fetch data from the table: "identification_type" */
  identification_type: Array<Identification_Type>;
  /** fetch aggregated fields from the table: "identification_type" */
  identification_type_aggregate: Identification_Type_Aggregate;
  /** fetch data from the table: "identification_type" using primary key columns */
  identification_type_by_pk?: Maybe<Identification_Type>;
  /** fetch data from the table: "income_source" */
  income_source: Array<Income_Source>;
  /** fetch aggregated fields from the table: "income_source" */
  income_source_aggregate: Income_Source_Aggregate;
  /** fetch data from the table: "income_source" using primary key columns */
  income_source_by_pk?: Maybe<Income_Source>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table: "insurance_rate_age_range" */
  insurance_rate_age_range: Array<Insurance_Rate_Age_Range>;
  /** fetch aggregated fields from the table: "insurance_rate_age_range" */
  insurance_rate_age_range_aggregate: Insurance_Rate_Age_Range_Aggregate;
  /** fetch data from the table: "insurance_rate_age_range" using primary key columns */
  insurance_rate_age_range_by_pk?: Maybe<Insurance_Rate_Age_Range>;
  /** fetch data from the table: "insurance_rate_duration" */
  insurance_rate_duration: Array<Insurance_Rate_Duration>;
  /** fetch aggregated fields from the table: "insurance_rate_duration" */
  insurance_rate_duration_aggregate: Insurance_Rate_Duration_Aggregate;
  /** fetch data from the table: "insurance_rate_duration" using primary key columns */
  insurance_rate_duration_by_pk?: Maybe<Insurance_Rate_Duration>;
  /** fetch data from the table: "insurance_type" */
  insurance_type: Array<Insurance_Type>;
  /** fetch aggregated fields from the table: "insurance_type" */
  insurance_type_aggregate: Insurance_Type_Aggregate;
  /** fetch data from the table: "insurance_type" using primary key columns */
  insurance_type_by_pk?: Maybe<Insurance_Type>;
  isEmailAvailable: EmailAvailabilityResponse;
  /** fetch data from the table: "marital_status" */
  marital_status: Array<Marital_Status>;
  /** fetch aggregated fields from the table: "marital_status" */
  marital_status_aggregate: Marital_Status_Aggregate;
  /** fetch data from the table: "marital_status" using primary key columns */
  marital_status_by_pk?: Maybe<Marital_Status>;
  me: MeResponse;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member_beneficiary" */
  member_beneficiary: Array<Member_Beneficiary>;
  /** fetch aggregated fields from the table: "member_beneficiary" */
  member_beneficiary_aggregate: Member_Beneficiary_Aggregate;
  /** fetch data from the table: "member_beneficiary" using primary key columns */
  member_beneficiary_by_pk?: Maybe<Member_Beneficiary>;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "member_contribution_source" */
  member_contribution_source: Array<Member_Contribution_Source>;
  /** fetch aggregated fields from the table: "member_contribution_source" */
  member_contribution_source_aggregate: Member_Contribution_Source_Aggregate;
  /** fetch data from the table: "member_contribution_source" using primary key columns */
  member_contribution_source_by_pk?: Maybe<Member_Contribution_Source>;
  /** fetch data from the table: "member_dependent" */
  member_dependent: Array<Member_Dependent>;
  /** fetch aggregated fields from the table: "member_dependent" */
  member_dependent_aggregate: Member_Dependent_Aggregate;
  /** fetch data from the table: "member_dependent" using primary key columns */
  member_dependent_by_pk?: Maybe<Member_Dependent>;
  /** fetch data from the table: "member_goal" */
  member_goal: Array<Member_Goal>;
  /** fetch aggregated fields from the table: "member_goal" */
  member_goal_aggregate: Member_Goal_Aggregate;
  /** fetch data from the table: "member_goal" using primary key columns */
  member_goal_by_pk?: Maybe<Member_Goal>;
  /** fetch data from the table: "member_identification" */
  member_identification: Array<Member_Identification>;
  /** fetch aggregated fields from the table: "member_identification" */
  member_identification_aggregate: Member_Identification_Aggregate;
  /** fetch data from the table: "member_identification" using primary key columns */
  member_identification_by_pk?: Maybe<Member_Identification>;
  /** fetch data from the table: "member_in_trust_for" */
  member_in_trust_for: Array<Member_In_Trust_For>;
  /** fetch aggregated fields from the table: "member_in_trust_for" */
  member_in_trust_for_aggregate: Member_In_Trust_For_Aggregate;
  /** fetch data from the table: "member_in_trust_for" using primary key columns */
  member_in_trust_for_by_pk?: Maybe<Member_In_Trust_For>;
  /** fetch data from the table: "member_income_source" */
  member_income_source: Array<Member_Income_Source>;
  /** fetch aggregated fields from the table: "member_income_source" */
  member_income_source_aggregate: Member_Income_Source_Aggregate;
  /** fetch data from the table: "member_income_source" using primary key columns */
  member_income_source_by_pk?: Maybe<Member_Income_Source>;
  /** fetch data from the table: "member_mode_of_instruction_communication" */
  member_mode_of_instruction_communication: Array<Member_Mode_Of_Instruction_Communication>;
  /** fetch aggregated fields from the table: "member_mode_of_instruction_communication" */
  member_mode_of_instruction_communication_aggregate: Member_Mode_Of_Instruction_Communication_Aggregate;
  /** fetch data from the table: "member_mode_of_instruction_communication" using primary key columns */
  member_mode_of_instruction_communication_by_pk?: Maybe<Member_Mode_Of_Instruction_Communication>;
  /** fetch data from the table: "member_payment_instruction" */
  member_payment_instruction: Array<Member_Payment_Instruction>;
  /** fetch aggregated fields from the table: "member_payment_instruction" */
  member_payment_instruction_aggregate: Member_Payment_Instruction_Aggregate;
  /** fetch data from the table: "member_payment_instruction" using primary key columns */
  member_payment_instruction_by_pk?: Maybe<Member_Payment_Instruction>;
  /** fetch data from the table: "member_redemption" */
  member_redemption: Array<Member_Redemption>;
  /** fetch aggregated fields from the table: "member_redemption" */
  member_redemption_aggregate: Member_Redemption_Aggregate;
  /** fetch data from the table: "member_redemption" using primary key columns */
  member_redemption_by_pk?: Maybe<Member_Redemption>;
  /** fetch data from the table: "member_reject" */
  member_reject: Array<Member_Reject>;
  /** fetch aggregated fields from the table: "member_reject" */
  member_reject_aggregate: Member_Reject_Aggregate;
  /** fetch data from the table: "member_reject" using primary key columns */
  member_reject_by_pk?: Maybe<Member_Reject>;
  /** fetch data from the table: "mode_of_instruction_communication" */
  mode_of_instruction_communication: Array<Mode_Of_Instruction_Communication>;
  /** fetch aggregated fields from the table: "mode_of_instruction_communication" */
  mode_of_instruction_communication_aggregate: Mode_Of_Instruction_Communication_Aggregate;
  /** fetch data from the table: "mode_of_instruction_communication" using primary key columns */
  mode_of_instruction_communication_by_pk?: Maybe<Mode_Of_Instruction_Communication>;
  /** fetch data from the table: "mp_product_split" */
  mp_product_split: Array<Mp_Product_Split>;
  /** fetch aggregated fields from the table: "mp_product_split" */
  mp_product_split_aggregate: Mp_Product_Split_Aggregate;
  /** fetch data from the table: "mp_product_split" using primary key columns */
  mp_product_split_by_pk?: Maybe<Mp_Product_Split>;
  /** fetch data from the table: "next_of_kin" */
  next_of_kin: Array<Next_Of_Kin>;
  /** fetch aggregated fields from the table: "next_of_kin" */
  next_of_kin_aggregate: Next_Of_Kin_Aggregate;
  /** fetch data from the table: "next_of_kin" using primary key columns */
  next_of_kin_by_pk?: Maybe<Next_Of_Kin>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_channel" */
  payment_channel: Array<Payment_Channel>;
  /** fetch aggregated fields from the table: "payment_channel" */
  payment_channel_aggregate: Payment_Channel_Aggregate;
  /** fetch data from the table: "payment_channel" using primary key columns */
  payment_channel_by_pk?: Maybe<Payment_Channel>;
  /** fetch data from the table: "payment_mode" */
  payment_mode: Array<Payment_Mode>;
  /** fetch aggregated fields from the table: "payment_mode" */
  payment_mode_aggregate: Payment_Mode_Aggregate;
  /** fetch data from the table: "payment_mode" using primary key columns */
  payment_mode_by_pk?: Maybe<Payment_Mode>;
  /** fetch data from the table: "payment_provider" */
  payment_provider: Array<Payment_Provider>;
  /** fetch aggregated fields from the table: "payment_provider" */
  payment_provider_aggregate: Payment_Provider_Aggregate;
  /** fetch data from the table: "payment_provider" using primary key columns */
  payment_provider_by_pk?: Maybe<Payment_Provider>;
  /** fetch data from the table: "payment_share" */
  payment_share: Array<Payment_Share>;
  /** fetch aggregated fields from the table: "payment_share" */
  payment_share_aggregate: Payment_Share_Aggregate;
  /** fetch data from the table: "payment_share" using primary key columns */
  payment_share_by_pk?: Maybe<Payment_Share>;
  /** fetch data from the table: "permission" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "permission" */
  permission_aggregate: Permission_Aggregate;
  /** fetch data from the table: "permission" using primary key columns */
  permission_by_pk?: Maybe<Permission>;
  /** fetch data from the table: "price" */
  price: Array<Price>;
  /** fetch aggregated fields from the table: "price" */
  price_aggregate: Price_Aggregate;
  /** fetch data from the table: "price" using primary key columns */
  price_by_pk?: Maybe<Price>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "redemption_share" */
  redemption_share: Array<Redemption_Share>;
  /** fetch aggregated fields from the table: "redemption_share" */
  redemption_share_aggregate: Redemption_Share_Aggregate;
  /** fetch data from the table: "redemption_share" using primary key columns */
  redemption_share_by_pk?: Maybe<Redemption_Share>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: Region_Aggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table: "relationship" */
  relationship: Array<Relationship>;
  /** fetch aggregated fields from the table: "relationship" */
  relationship_aggregate: Relationship_Aggregate;
  /** fetch data from the table: "relationship" using primary key columns */
  relationship_by_pk?: Maybe<Relationship>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "role_permission" */
  role_permission: Array<Role_Permission>;
  /** fetch aggregated fields from the table: "role_permission" */
  role_permission_aggregate: Role_Permission_Aggregate;
  /** fetch data from the table: "role_permission" using primary key columns */
  role_permission_by_pk?: Maybe<Role_Permission>;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  status_aggregate: Status_Aggregate;
  /** fetch data from the table: "status" using primary key columns */
  status_by_pk?: Maybe<Status>;
  /** fetch data from the table: "title" */
  title: Array<Title>;
  /** fetch aggregated fields from the table: "title" */
  title_aggregate: Title_Aggregate;
  /** fetch data from the table: "title" using primary key columns */
  title_by_pk?: Maybe<Title>;
  /** fetch data from the table: "university" */
  university: Array<University>;
  /** fetch aggregated fields from the table: "university" */
  university_aggregate: University_Aggregate;
  /** fetch data from the table: "university" using primary key columns */
  university_by_pk?: Maybe<University>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_activity_log" */
  user_activity_log: Array<User_Activity_Log>;
  /** fetch aggregated fields from the table: "user_activity_log" */
  user_activity_log_aggregate: User_Activity_Log_Aggregate;
  /** fetch data from the table: "user_activity_log" using primary key columns */
  user_activity_log_by_pk?: Maybe<User_Activity_Log>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
};


/** query root */
export type Query_RootAdvisorArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** query root */
export type Query_RootAdvisor_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** query root */
export type Query_RootAdvisor_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootAll_Tables_TemplateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** query root */
export type Query_RootAll_Tables_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** query root */
export type Query_RootAll_Tables_Template_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootApkArgs = {
  distinct_on?: Maybe<Array<Apk_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apk_Order_By>>;
  where?: Maybe<Apk_Bool_Exp>;
};


/** query root */
export type Query_RootApk_AggregateArgs = {
  distinct_on?: Maybe<Array<Apk_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apk_Order_By>>;
  where?: Maybe<Apk_Bool_Exp>;
};


/** query root */
export type Query_RootApk_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootApp_ConfigArgs = {
  distinct_on?: Maybe<Array<App_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Config_Order_By>>;
  where?: Maybe<App_Config_Bool_Exp>;
};


/** query root */
export type Query_RootApp_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Config_Order_By>>;
  where?: Maybe<App_Config_Bool_Exp>;
};


/** query root */
export type Query_RootApp_Config_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootAsset_AllocationArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** query root */
export type Query_RootAsset_Allocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** query root */
export type Query_RootAsset_Allocation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootAsset_DurationArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** query root */
export type Query_RootAsset_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** query root */
export type Query_RootAsset_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootAudit_LogArgs = {
  distinct_on?: Maybe<Array<Audit_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Log_Order_By>>;
  where?: Maybe<Audit_Log_Bool_Exp>;
};


/** query root */
export type Query_RootAudit_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Log_Order_By>>;
  where?: Maybe<Audit_Log_Bool_Exp>;
};


/** query root */
export type Query_RootAudit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** query root */
export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** query root */
export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


/** query root */
export type Query_RootBackground_NotificationsArgs = {
  distinct_on?: Maybe<Array<Background_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Background_Notifications_Order_By>>;
  where?: Maybe<Background_Notifications_Bool_Exp>;
};


/** query root */
export type Query_RootBackground_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Background_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Background_Notifications_Order_By>>;
  where?: Maybe<Background_Notifications_Bool_Exp>;
};


/** query root */
export type Query_RootBackground_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBranchArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** query root */
export type Query_RootBranch_AggregateArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** query root */
export type Query_RootBranch_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootClient_Investment_ProfileArgs = {
  distinct_on?: Maybe<Array<Client_Investment_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Investment_Profile_Order_By>>;
  where?: Maybe<Client_Investment_Profile_Bool_Exp>;
};


/** query root */
export type Query_RootClient_Investment_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Investment_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Investment_Profile_Order_By>>;
  where?: Maybe<Client_Investment_Profile_Bool_Exp>;
};


/** query root */
export type Query_RootClient_Investment_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootContribution_SourceArgs = {
  distinct_on?: Maybe<Array<Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contribution_Source_Order_By>>;
  where?: Maybe<Contribution_Source_Bool_Exp>;
};


/** query root */
export type Query_RootContribution_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contribution_Source_Order_By>>;
  where?: Maybe<Contribution_Source_Bool_Exp>;
};


/** query root */
export type Query_RootContribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


/** query root */
export type Query_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


/** query root */
export type Query_RootCountry_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootDashboard_SummaryArgs = {
  distinct_on?: Maybe<Array<Dashboard_Summary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dashboard_Summary_Order_By>>;
  where?: Maybe<Dashboard_Summary_Bool_Exp>;
};


/** query root */
export type Query_RootDashboard_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Dashboard_Summary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dashboard_Summary_Order_By>>;
  where?: Maybe<Dashboard_Summary_Bool_Exp>;
};


/** query root */
export type Query_RootDealArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** query root */
export type Query_RootDeal_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** query root */
export type Query_RootDeal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootDeal_ShareArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** query root */
export type Query_RootDeal_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** query root */
export type Query_RootDeal_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootDirect_Debit_ChargeArgs = {
  distinct_on?: Maybe<Array<Direct_Debit_Charge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Debit_Charge_Order_By>>;
  where?: Maybe<Direct_Debit_Charge_Bool_Exp>;
};


/** query root */
export type Query_RootDirect_Debit_Charge_AggregateArgs = {
  distinct_on?: Maybe<Array<Direct_Debit_Charge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Debit_Charge_Order_By>>;
  where?: Maybe<Direct_Debit_Charge_Bool_Exp>;
};


/** query root */
export type Query_RootDirect_Debit_Charge_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootEconomic_IndicatorArgs = {
  distinct_on?: Maybe<Array<Economic_Indicator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Economic_Indicator_Order_By>>;
  where?: Maybe<Economic_Indicator_Bool_Exp>;
};


/** query root */
export type Query_RootEconomic_Indicator_AggregateArgs = {
  distinct_on?: Maybe<Array<Economic_Indicator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Economic_Indicator_Order_By>>;
  where?: Maybe<Economic_Indicator_Bool_Exp>;
};


/** query root */
export type Query_RootEconomic_Indicator_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootExpected_ReturnArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** query root */
export type Query_RootExpected_Return_AggregateArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** query root */
export type Query_RootExpected_Return_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootGenderArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** query root */
export type Query_RootGender_AggregateArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** query root */
export type Query_RootGender_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootGoalArgs = {
  distinct_on?: Maybe<Array<Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Order_By>>;
  where?: Maybe<Goal_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Order_By>>;
  where?: Maybe<Goal_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootGoal_InsuranceArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_Insurance_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_Insurance_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootGoal_ProductArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** query root */
export type Query_RootGoal_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootHmd_EducationArgs = {
  distinct_on?: Maybe<Array<Hmd_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Education_Order_By>>;
  where?: Maybe<Hmd_Education_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Education_Order_By>>;
  where?: Maybe<Hmd_Education_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootHmd_Expense_BaseArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Base_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Base_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootHmd_Expense_CategoryArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Category_Order_By>>;
  where?: Maybe<Hmd_Expense_Category_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Category_Order_By>>;
  where?: Maybe<Hmd_Expense_Category_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Category_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootHmd_Expense_MultiplierArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Multiplier_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Expense_Multiplier_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootHmd_LifestyleArgs = {
  distinct_on?: Maybe<Array<Hmd_Lifestyle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Lifestyle_Order_By>>;
  where?: Maybe<Hmd_Lifestyle_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Lifestyle_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Lifestyle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Lifestyle_Order_By>>;
  where?: Maybe<Hmd_Lifestyle_Bool_Exp>;
};


/** query root */
export type Query_RootHmd_Lifestyle_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootIdentification_TypeArgs = {
  distinct_on?: Maybe<Array<Identification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identification_Type_Order_By>>;
  where?: Maybe<Identification_Type_Bool_Exp>;
};


/** query root */
export type Query_RootIdentification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Identification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identification_Type_Order_By>>;
  where?: Maybe<Identification_Type_Bool_Exp>;
};


/** query root */
export type Query_RootIdentification_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootIncome_SourceArgs = {
  distinct_on?: Maybe<Array<Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Income_Source_Order_By>>;
  where?: Maybe<Income_Source_Bool_Exp>;
};


/** query root */
export type Query_RootIncome_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Income_Source_Order_By>>;
  where?: Maybe<Income_Source_Bool_Exp>;
};


/** query root */
export type Query_RootIncome_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootIndustryArgs = {
  distinct_on?: Maybe<Array<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** query root */
export type Query_RootIndustry_AggregateArgs = {
  distinct_on?: Maybe<Array<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** query root */
export type Query_RootIndustry_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootInsurance_Rate_Age_RangeArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Rate_Age_Range_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Rate_Age_Range_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootInsurance_Rate_DurationArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Rate_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Rate_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootInsurance_TypeArgs = {
  distinct_on?: Maybe<Array<Insurance_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Type_Order_By>>;
  where?: Maybe<Insurance_Type_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Type_Order_By>>;
  where?: Maybe<Insurance_Type_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootIsEmailAvailableArgs = {
  data: EmailInput;
};


/** query root */
export type Query_RootMarital_StatusArgs = {
  distinct_on?: Maybe<Array<Marital_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Marital_Status_Order_By>>;
  where?: Maybe<Marital_Status_Bool_Exp>;
};


/** query root */
export type Query_RootMarital_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Marital_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Marital_Status_Order_By>>;
  where?: Maybe<Marital_Status_Bool_Exp>;
};


/** query root */
export type Query_RootMarital_Status_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMemberArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** query root */
export type Query_RootMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** query root */
export type Query_RootMember_BeneficiaryArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Beneficiary_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Beneficiary_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_Contribution_SourceArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Contribution_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Contribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_DependentArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Dependent_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Dependent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_GoalArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Goal_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Goal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_IdentificationArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Identification_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Identification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_In_Trust_ForArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** query root */
export type Query_RootMember_In_Trust_For_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** query root */
export type Query_RootMember_In_Trust_For_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_Income_SourceArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Income_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Income_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_Mode_Of_Instruction_CommunicationArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Mode_Of_Instruction_Communication_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Mode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_Payment_InstructionArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Payment_Instruction_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Payment_Instruction_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_RedemptionArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Redemption_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMember_RejectArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Reject_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** query root */
export type Query_RootMember_Reject_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMode_Of_Instruction_CommunicationArgs = {
  distinct_on?: Maybe<Array<Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** query root */
export type Query_RootMode_Of_Instruction_Communication_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** query root */
export type Query_RootMode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootMp_Product_SplitArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** query root */
export type Query_RootMp_Product_Split_AggregateArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** query root */
export type Query_RootMp_Product_Split_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootNext_Of_KinArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** query root */
export type Query_RootNext_Of_Kin_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** query root */
export type Query_RootNext_Of_Kin_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPayment_ChannelArgs = {
  distinct_on?: Maybe<Array<Payment_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Channel_Order_By>>;
  where?: Maybe<Payment_Channel_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Channel_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Channel_Order_By>>;
  where?: Maybe<Payment_Channel_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Channel_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPayment_ModeArgs = {
  distinct_on?: Maybe<Array<Payment_Mode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Mode_Order_By>>;
  where?: Maybe<Payment_Mode_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Mode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Mode_Order_By>>;
  where?: Maybe<Payment_Mode_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Mode_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPayment_ProviderArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Provider_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Provider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPayment_ShareArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPermissionArgs = {
  distinct_on?: Maybe<Array<Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permission_Order_By>>;
  where?: Maybe<Permission_Bool_Exp>;
};


/** query root */
export type Query_RootPermission_AggregateArgs = {
  distinct_on?: Maybe<Array<Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permission_Order_By>>;
  where?: Maybe<Permission_Bool_Exp>;
};


/** query root */
export type Query_RootPermission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootPriceArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** query root */
export type Query_RootPrice_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** query root */
export type Query_RootPrice_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootRedemption_ShareArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** query root */
export type Query_RootRedemption_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** query root */
export type Query_RootRedemption_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootRegionArgs = {
  distinct_on?: Maybe<Array<Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Region_Order_By>>;
  where?: Maybe<Region_Bool_Exp>;
};


/** query root */
export type Query_RootRegion_AggregateArgs = {
  distinct_on?: Maybe<Array<Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Region_Order_By>>;
  where?: Maybe<Region_Bool_Exp>;
};


/** query root */
export type Query_RootRegion_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootRelationshipArgs = {
  distinct_on?: Maybe<Array<Relationship_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Relationship_Order_By>>;
  where?: Maybe<Relationship_Bool_Exp>;
};


/** query root */
export type Query_RootRelationship_AggregateArgs = {
  distinct_on?: Maybe<Array<Relationship_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Relationship_Order_By>>;
  where?: Maybe<Relationship_Bool_Exp>;
};


/** query root */
export type Query_RootRelationship_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


/** query root */
export type Query_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


/** query root */
export type Query_RootRole_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootRole_PermissionArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** query root */
export type Query_RootRole_Permission_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** query root */
export type Query_RootRole_Permission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStatusArgs = {
  distinct_on?: Maybe<Array<Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Status_Order_By>>;
  where?: Maybe<Status_Bool_Exp>;
};


/** query root */
export type Query_RootStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Status_Order_By>>;
  where?: Maybe<Status_Bool_Exp>;
};


/** query root */
export type Query_RootStatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootTitleArgs = {
  distinct_on?: Maybe<Array<Title_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Title_Order_By>>;
  where?: Maybe<Title_Bool_Exp>;
};


/** query root */
export type Query_RootTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<Title_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Title_Order_By>>;
  where?: Maybe<Title_Bool_Exp>;
};


/** query root */
export type Query_RootTitle_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootUniversityArgs = {
  distinct_on?: Maybe<Array<University_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<University_Order_By>>;
  where?: Maybe<University_Bool_Exp>;
};


/** query root */
export type Query_RootUniversity_AggregateArgs = {
  distinct_on?: Maybe<Array<University_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<University_Order_By>>;
  where?: Maybe<University_Bool_Exp>;
};


/** query root */
export type Query_RootUniversity_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Activity_LogArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Activity_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Activity_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars['bigint'];
};

/** columns and relationships of "redemption_share" */
export type Redemption_Share = {
  __typename?: 'redemption_share';
  created_at: Scalars['timestamptz'];
  ending_balance: Scalars['numeric'];
  goal_amount: Scalars['numeric'];
  goal_current_balance: Scalars['numeric'];
  id: Scalars['bigint'];
  /** An object relationship */
  member_goal: Member_Goal;
  member_goal_id: Scalars['bigint'];
  /** An object relationship */
  member_redemption: Member_Redemption;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  ratio: Scalars['numeric'];
  redeemable_split: Scalars['numeric'];
  redemption_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "redemption_share" */
export type Redemption_Share_Aggregate = {
  __typename?: 'redemption_share_aggregate';
  aggregate?: Maybe<Redemption_Share_Aggregate_Fields>;
  nodes: Array<Redemption_Share>;
};

/** aggregate fields of "redemption_share" */
export type Redemption_Share_Aggregate_Fields = {
  __typename?: 'redemption_share_aggregate_fields';
  avg?: Maybe<Redemption_Share_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Redemption_Share_Max_Fields>;
  min?: Maybe<Redemption_Share_Min_Fields>;
  stddev?: Maybe<Redemption_Share_Stddev_Fields>;
  stddev_pop?: Maybe<Redemption_Share_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Redemption_Share_Stddev_Samp_Fields>;
  sum?: Maybe<Redemption_Share_Sum_Fields>;
  var_pop?: Maybe<Redemption_Share_Var_Pop_Fields>;
  var_samp?: Maybe<Redemption_Share_Var_Samp_Fields>;
  variance?: Maybe<Redemption_Share_Variance_Fields>;
};


/** aggregate fields of "redemption_share" */
export type Redemption_Share_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Redemption_Share_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "redemption_share" */
export type Redemption_Share_Aggregate_Order_By = {
  avg?: Maybe<Redemption_Share_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Redemption_Share_Max_Order_By>;
  min?: Maybe<Redemption_Share_Min_Order_By>;
  stddev?: Maybe<Redemption_Share_Stddev_Order_By>;
  stddev_pop?: Maybe<Redemption_Share_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Redemption_Share_Stddev_Samp_Order_By>;
  sum?: Maybe<Redemption_Share_Sum_Order_By>;
  var_pop?: Maybe<Redemption_Share_Var_Pop_Order_By>;
  var_samp?: Maybe<Redemption_Share_Var_Samp_Order_By>;
  variance?: Maybe<Redemption_Share_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "redemption_share" */
export type Redemption_Share_Arr_Rel_Insert_Input = {
  data: Array<Redemption_Share_Insert_Input>;
  on_conflict?: Maybe<Redemption_Share_On_Conflict>;
};

/** aggregate avg on columns */
export type Redemption_Share_Avg_Fields = {
  __typename?: 'redemption_share_avg_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "redemption_share" */
export type Redemption_Share_Avg_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "redemption_share". All fields are combined with a logical 'AND'. */
export type Redemption_Share_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Redemption_Share_Bool_Exp>>>;
  _not?: Maybe<Redemption_Share_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Redemption_Share_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  ending_balance?: Maybe<Numeric_Comparison_Exp>;
  goal_amount?: Maybe<Numeric_Comparison_Exp>;
  goal_current_balance?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_goal?: Maybe<Member_Goal_Bool_Exp>;
  member_goal_id?: Maybe<Bigint_Comparison_Exp>;
  member_redemption?: Maybe<Member_Redemption_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Bigint_Comparison_Exp>;
  ratio?: Maybe<Numeric_Comparison_Exp>;
  redeemable_split?: Maybe<Numeric_Comparison_Exp>;
  redemption_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "redemption_share" */
export enum Redemption_Share_Constraint {
  /** unique or primary key constraint */
  RedemptionSharePkey = 'redemption_share_pkey'
}

/** input type for incrementing integer column in table "redemption_share" */
export type Redemption_Share_Inc_Input = {
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "redemption_share" */
export type Redemption_Share_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal?: Maybe<Member_Goal_Obj_Rel_Insert_Input>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  member_redemption?: Maybe<Member_Redemption_Obj_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Redemption_Share_Max_Fields = {
  __typename?: 'redemption_share_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "redemption_share" */
export type Redemption_Share_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Redemption_Share_Min_Fields = {
  __typename?: 'redemption_share_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "redemption_share" */
export type Redemption_Share_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "redemption_share" */
export type Redemption_Share_Mutation_Response = {
  __typename?: 'redemption_share_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Redemption_Share>;
};

/** input type for inserting object relation for remote table "redemption_share" */
export type Redemption_Share_Obj_Rel_Insert_Input = {
  data: Redemption_Share_Insert_Input;
  on_conflict?: Maybe<Redemption_Share_On_Conflict>;
};

/** on conflict condition type for table "redemption_share" */
export type Redemption_Share_On_Conflict = {
  constraint: Redemption_Share_Constraint;
  update_columns: Array<Redemption_Share_Update_Column>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};

/** ordering options when selecting data from "redemption_share" */
export type Redemption_Share_Order_By = {
  created_at?: Maybe<Order_By>;
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal?: Maybe<Member_Goal_Order_By>;
  member_goal_id?: Maybe<Order_By>;
  member_redemption?: Maybe<Member_Redemption_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "redemption_share" */
export type Redemption_Share_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "redemption_share" */
export enum Redemption_Share_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndingBalance = 'ending_balance',
  /** column name */
  GoalAmount = 'goal_amount',
  /** column name */
  GoalCurrentBalance = 'goal_current_balance',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  RedeemableSplit = 'redeemable_split',
  /** column name */
  RedemptionId = 'redemption_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "redemption_share" */
export type Redemption_Share_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Redemption_Share_Stddev_Fields = {
  __typename?: 'redemption_share_stddev_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "redemption_share" */
export type Redemption_Share_Stddev_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Redemption_Share_Stddev_Pop_Fields = {
  __typename?: 'redemption_share_stddev_pop_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "redemption_share" */
export type Redemption_Share_Stddev_Pop_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Redemption_Share_Stddev_Samp_Fields = {
  __typename?: 'redemption_share_stddev_samp_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "redemption_share" */
export type Redemption_Share_Stddev_Samp_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Redemption_Share_Sum_Fields = {
  __typename?: 'redemption_share_sum_fields';
  ending_balance?: Maybe<Scalars['numeric']>;
  goal_amount?: Maybe<Scalars['numeric']>;
  goal_current_balance?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  member_goal_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  ratio?: Maybe<Scalars['numeric']>;
  redeemable_split?: Maybe<Scalars['numeric']>;
  redemption_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "redemption_share" */
export type Redemption_Share_Sum_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** update columns of table "redemption_share" */
export enum Redemption_Share_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndingBalance = 'ending_balance',
  /** column name */
  GoalAmount = 'goal_amount',
  /** column name */
  GoalCurrentBalance = 'goal_current_balance',
  /** column name */
  Id = 'id',
  /** column name */
  MemberGoalId = 'member_goal_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  RedeemableSplit = 'redeemable_split',
  /** column name */
  RedemptionId = 'redemption_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Redemption_Share_Var_Pop_Fields = {
  __typename?: 'redemption_share_var_pop_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "redemption_share" */
export type Redemption_Share_Var_Pop_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Redemption_Share_Var_Samp_Fields = {
  __typename?: 'redemption_share_var_samp_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "redemption_share" */
export type Redemption_Share_Var_Samp_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Redemption_Share_Variance_Fields = {
  __typename?: 'redemption_share_variance_fields';
  ending_balance?: Maybe<Scalars['Float']>;
  goal_amount?: Maybe<Scalars['Float']>;
  goal_current_balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  member_goal_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
  redeemable_split?: Maybe<Scalars['Float']>;
  redemption_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "redemption_share" */
export type Redemption_Share_Variance_Order_By = {
  ending_balance?: Maybe<Order_By>;
  goal_amount?: Maybe<Order_By>;
  goal_current_balance?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_goal_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  ratio?: Maybe<Order_By>;
  redeemable_split?: Maybe<Order_By>;
  redemption_id?: Maybe<Order_By>;
};

/** columns and relationships of "region" */
export type Region = {
  __typename?: 'region';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  /** An array relationship */
  hmd_expense_bases: Array<Hmd_Expense_Base>;
  /** An aggregated array relationship */
  hmd_expense_bases_aggregate: Hmd_Expense_Base_Aggregate;
  /** An array relationship */
  hmd_expense_multipliers: Array<Hmd_Expense_Multiplier>;
  /** An aggregated array relationship */
  hmd_expense_multipliers_aggregate: Hmd_Expense_Multiplier_Aggregate;
  id: Scalars['bigint'];
  /** An array relationship */
  member_beneficiaries: Array<Member_Beneficiary>;
  /** An aggregated array relationship */
  member_beneficiaries_aggregate: Member_Beneficiary_Aggregate;
  /** An array relationship */
  member_in_trust_for: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_for_aggregate: Member_In_Trust_For_Aggregate;
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  next_of_kins: Array<Next_Of_Kin>;
  /** An aggregated array relationship */
  next_of_kins_aggregate: Next_Of_Kin_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "region" */
export type RegionHmd_Expense_BasesArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionHmd_Expense_Bases_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionHmd_Expense_MultipliersArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionHmd_Expense_Multipliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMember_BeneficiariesArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMember_Beneficiaries_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMember_In_Trust_ForArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMember_In_Trust_For_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionNext_Of_KinsArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** columns and relationships of "region" */
export type RegionNext_Of_Kins_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};

/** aggregated selection of "region" */
export type Region_Aggregate = {
  __typename?: 'region_aggregate';
  aggregate?: Maybe<Region_Aggregate_Fields>;
  nodes: Array<Region>;
};

/** aggregate fields of "region" */
export type Region_Aggregate_Fields = {
  __typename?: 'region_aggregate_fields';
  avg?: Maybe<Region_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Region_Max_Fields>;
  min?: Maybe<Region_Min_Fields>;
  stddev?: Maybe<Region_Stddev_Fields>;
  stddev_pop?: Maybe<Region_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Region_Stddev_Samp_Fields>;
  sum?: Maybe<Region_Sum_Fields>;
  var_pop?: Maybe<Region_Var_Pop_Fields>;
  var_samp?: Maybe<Region_Var_Samp_Fields>;
  variance?: Maybe<Region_Variance_Fields>;
};


/** aggregate fields of "region" */
export type Region_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Region_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "region" */
export type Region_Aggregate_Order_By = {
  avg?: Maybe<Region_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Region_Max_Order_By>;
  min?: Maybe<Region_Min_Order_By>;
  stddev?: Maybe<Region_Stddev_Order_By>;
  stddev_pop?: Maybe<Region_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Region_Stddev_Samp_Order_By>;
  sum?: Maybe<Region_Sum_Order_By>;
  var_pop?: Maybe<Region_Var_Pop_Order_By>;
  var_samp?: Maybe<Region_Var_Samp_Order_By>;
  variance?: Maybe<Region_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "region" */
export type Region_Arr_Rel_Insert_Input = {
  data: Array<Region_Insert_Input>;
  on_conflict?: Maybe<Region_On_Conflict>;
};

/** aggregate avg on columns */
export type Region_Avg_Fields = {
  __typename?: 'region_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "region" */
export type Region_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "region". All fields are combined with a logical 'AND'. */
export type Region_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Region_Bool_Exp>>>;
  _not?: Maybe<Region_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Region_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  hmd_expense_bases?: Maybe<Hmd_Expense_Base_Bool_Exp>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Bool_Exp>;
  member_in_trust_for?: Maybe<Member_In_Trust_For_Bool_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  next_of_kins?: Maybe<Next_Of_Kin_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "region" */
export enum Region_Constraint {
  /** unique or primary key constraint */
  RegionPkey = 'region_pkey'
}

/** input type for incrementing integer column in table "region" */
export type Region_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "region" */
export type Region_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  hmd_expense_bases?: Maybe<Hmd_Expense_Base_Arr_Rel_Insert_Input>;
  hmd_expense_multipliers?: Maybe<Hmd_Expense_Multiplier_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['bigint']>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Arr_Rel_Insert_Input>;
  member_in_trust_for?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  next_of_kins?: Maybe<Next_Of_Kin_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Region_Max_Fields = {
  __typename?: 'region_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "region" */
export type Region_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Region_Min_Fields = {
  __typename?: 'region_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "region" */
export type Region_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "region" */
export type Region_Mutation_Response = {
  __typename?: 'region_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Region>;
};

/** input type for inserting object relation for remote table "region" */
export type Region_Obj_Rel_Insert_Input = {
  data: Region_Insert_Input;
  on_conflict?: Maybe<Region_On_Conflict>;
};

/** on conflict condition type for table "region" */
export type Region_On_Conflict = {
  constraint: Region_Constraint;
  update_columns: Array<Region_Update_Column>;
  where?: Maybe<Region_Bool_Exp>;
};

/** ordering options when selecting data from "region" */
export type Region_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  hmd_expense_bases_aggregate?: Maybe<Hmd_Expense_Base_Aggregate_Order_By>;
  hmd_expense_multipliers_aggregate?: Maybe<Hmd_Expense_Multiplier_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  member_beneficiaries_aggregate?: Maybe<Member_Beneficiary_Aggregate_Order_By>;
  member_in_trust_for_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  next_of_kins_aggregate?: Maybe<Next_Of_Kin_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "region" */
export type Region_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "region" */
export enum Region_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "region" */
export type Region_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Region_Stddev_Fields = {
  __typename?: 'region_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "region" */
export type Region_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Region_Stddev_Pop_Fields = {
  __typename?: 'region_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "region" */
export type Region_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Region_Stddev_Samp_Fields = {
  __typename?: 'region_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "region" */
export type Region_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Region_Sum_Fields = {
  __typename?: 'region_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "region" */
export type Region_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "region" */
export enum Region_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Region_Var_Pop_Fields = {
  __typename?: 'region_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "region" */
export type Region_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Region_Var_Samp_Fields = {
  __typename?: 'region_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "region" */
export type Region_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Region_Variance_Fields = {
  __typename?: 'region_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "region" */
export type Region_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "relationship" */
export type Relationship = {
  __typename?: 'relationship';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  member_beneficiaries: Array<Member_Beneficiary>;
  /** An aggregated array relationship */
  member_beneficiaries_aggregate: Member_Beneficiary_Aggregate;
  /** An array relationship */
  member_in_trust_fors: Array<Member_In_Trust_For>;
  /** An aggregated array relationship */
  member_in_trust_fors_aggregate: Member_In_Trust_For_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  next_of_kins: Array<Next_Of_Kin>;
  /** An aggregated array relationship */
  next_of_kins_aggregate: Next_Of_Kin_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "relationship" */
export type RelationshipMember_BeneficiariesArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "relationship" */
export type RelationshipMember_Beneficiaries_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** columns and relationships of "relationship" */
export type RelationshipMember_In_Trust_ForsArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "relationship" */
export type RelationshipMember_In_Trust_Fors_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** columns and relationships of "relationship" */
export type RelationshipNext_Of_KinsArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** columns and relationships of "relationship" */
export type RelationshipNext_Of_Kins_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};

/** aggregated selection of "relationship" */
export type Relationship_Aggregate = {
  __typename?: 'relationship_aggregate';
  aggregate?: Maybe<Relationship_Aggregate_Fields>;
  nodes: Array<Relationship>;
};

/** aggregate fields of "relationship" */
export type Relationship_Aggregate_Fields = {
  __typename?: 'relationship_aggregate_fields';
  avg?: Maybe<Relationship_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Relationship_Max_Fields>;
  min?: Maybe<Relationship_Min_Fields>;
  stddev?: Maybe<Relationship_Stddev_Fields>;
  stddev_pop?: Maybe<Relationship_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Relationship_Stddev_Samp_Fields>;
  sum?: Maybe<Relationship_Sum_Fields>;
  var_pop?: Maybe<Relationship_Var_Pop_Fields>;
  var_samp?: Maybe<Relationship_Var_Samp_Fields>;
  variance?: Maybe<Relationship_Variance_Fields>;
};


/** aggregate fields of "relationship" */
export type Relationship_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Relationship_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "relationship" */
export type Relationship_Aggregate_Order_By = {
  avg?: Maybe<Relationship_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Relationship_Max_Order_By>;
  min?: Maybe<Relationship_Min_Order_By>;
  stddev?: Maybe<Relationship_Stddev_Order_By>;
  stddev_pop?: Maybe<Relationship_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Relationship_Stddev_Samp_Order_By>;
  sum?: Maybe<Relationship_Sum_Order_By>;
  var_pop?: Maybe<Relationship_Var_Pop_Order_By>;
  var_samp?: Maybe<Relationship_Var_Samp_Order_By>;
  variance?: Maybe<Relationship_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "relationship" */
export type Relationship_Arr_Rel_Insert_Input = {
  data: Array<Relationship_Insert_Input>;
  on_conflict?: Maybe<Relationship_On_Conflict>;
};

/** aggregate avg on columns */
export type Relationship_Avg_Fields = {
  __typename?: 'relationship_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "relationship" */
export type Relationship_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "relationship". All fields are combined with a logical 'AND'. */
export type Relationship_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Relationship_Bool_Exp>>>;
  _not?: Maybe<Relationship_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Relationship_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Bool_Exp>;
  member_in_trust_fors?: Maybe<Member_In_Trust_For_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  next_of_kins?: Maybe<Next_Of_Kin_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "relationship" */
export enum Relationship_Constraint {
  /** unique or primary key constraint */
  RelationshipNameKey = 'relationship_name_key',
  /** unique or primary key constraint */
  RelationshipPkey = 'relationship_pkey'
}

/** input type for incrementing integer column in table "relationship" */
export type Relationship_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "relationship" */
export type Relationship_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  member_beneficiaries?: Maybe<Member_Beneficiary_Arr_Rel_Insert_Input>;
  member_in_trust_fors?: Maybe<Member_In_Trust_For_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  next_of_kins?: Maybe<Next_Of_Kin_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Relationship_Max_Fields = {
  __typename?: 'relationship_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "relationship" */
export type Relationship_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Relationship_Min_Fields = {
  __typename?: 'relationship_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "relationship" */
export type Relationship_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "relationship" */
export type Relationship_Mutation_Response = {
  __typename?: 'relationship_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Relationship>;
};

/** input type for inserting object relation for remote table "relationship" */
export type Relationship_Obj_Rel_Insert_Input = {
  data: Relationship_Insert_Input;
  on_conflict?: Maybe<Relationship_On_Conflict>;
};

/** on conflict condition type for table "relationship" */
export type Relationship_On_Conflict = {
  constraint: Relationship_Constraint;
  update_columns: Array<Relationship_Update_Column>;
  where?: Maybe<Relationship_Bool_Exp>;
};

/** ordering options when selecting data from "relationship" */
export type Relationship_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  member_beneficiaries_aggregate?: Maybe<Member_Beneficiary_Aggregate_Order_By>;
  member_in_trust_fors_aggregate?: Maybe<Member_In_Trust_For_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  next_of_kins_aggregate?: Maybe<Next_Of_Kin_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "relationship" */
export type Relationship_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "relationship" */
export enum Relationship_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "relationship" */
export type Relationship_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Relationship_Stddev_Fields = {
  __typename?: 'relationship_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "relationship" */
export type Relationship_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Relationship_Stddev_Pop_Fields = {
  __typename?: 'relationship_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "relationship" */
export type Relationship_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Relationship_Stddev_Samp_Fields = {
  __typename?: 'relationship_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "relationship" */
export type Relationship_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Relationship_Sum_Fields = {
  __typename?: 'relationship_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "relationship" */
export type Relationship_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "relationship" */
export enum Relationship_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Relationship_Var_Pop_Fields = {
  __typename?: 'relationship_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "relationship" */
export type Relationship_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Relationship_Var_Samp_Fields = {
  __typename?: 'relationship_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "relationship" */
export type Relationship_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Relationship_Variance_Fields = {
  __typename?: 'relationship_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "relationship" */
export type Relationship_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  role_permissions: Array<Role_Permission>;
  /** An aggregated array relationship */
  role_permissions_aggregate: Role_Permission_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregated array relationship */
  user_roles_aggregate: User_Role_Aggregate;
};


/** columns and relationships of "role" */
export type RoleRole_PermissionsArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleRole_Permissions_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  avg?: Maybe<Role_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
  stddev?: Maybe<Role_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Sum_Fields>;
  var_pop?: Maybe<Role_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Var_Samp_Fields>;
  variance?: Maybe<Role_Variance_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "role" */
export type Role_Aggregate_Order_By = {
  avg?: Maybe<Role_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Role_Max_Order_By>;
  min?: Maybe<Role_Min_Order_By>;
  stddev?: Maybe<Role_Stddev_Order_By>;
  stddev_pop?: Maybe<Role_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Role_Stddev_Samp_Order_By>;
  sum?: Maybe<Role_Sum_Order_By>;
  var_pop?: Maybe<Role_Var_Pop_Order_By>;
  var_samp?: Maybe<Role_Var_Samp_Order_By>;
  variance?: Maybe<Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role" */
export type Role_Arr_Rel_Insert_Input = {
  data: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Avg_Fields = {
  __typename?: 'role_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "role" */
export type Role_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Role_Bool_Exp>>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Role_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  role_permissions?: Maybe<Role_Permission_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_roles?: Maybe<User_Role_Bool_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint */
  RoleNameKey = 'role_name_key',
  /** unique or primary key constraint */
  RolePkey = 'role_pkey'
}

/** input type for incrementing integer column in table "role" */
export type Role_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  role_permissions?: Maybe<Role_Permission_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_roles?: Maybe<User_Role_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "role" */
export type Role_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "role" */
export type Role_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};

/** on conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
};

/** ordering options when selecting data from "role" */
export type Role_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  role_permissions_aggregate?: Maybe<Role_Permission_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Order_By>;
};

/** columns and relationships of "role_permission" */
export type Role_Permission = {
  __typename?: 'role_permission';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  permission?: Maybe<Permission>;
  permission_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "role_permission" */
export type Role_Permission_Aggregate = {
  __typename?: 'role_permission_aggregate';
  aggregate?: Maybe<Role_Permission_Aggregate_Fields>;
  nodes: Array<Role_Permission>;
};

/** aggregate fields of "role_permission" */
export type Role_Permission_Aggregate_Fields = {
  __typename?: 'role_permission_aggregate_fields';
  avg?: Maybe<Role_Permission_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Role_Permission_Max_Fields>;
  min?: Maybe<Role_Permission_Min_Fields>;
  stddev?: Maybe<Role_Permission_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Permission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Permission_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Permission_Sum_Fields>;
  var_pop?: Maybe<Role_Permission_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Permission_Var_Samp_Fields>;
  variance?: Maybe<Role_Permission_Variance_Fields>;
};


/** aggregate fields of "role_permission" */
export type Role_Permission_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Permission_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "role_permission" */
export type Role_Permission_Aggregate_Order_By = {
  avg?: Maybe<Role_Permission_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Role_Permission_Max_Order_By>;
  min?: Maybe<Role_Permission_Min_Order_By>;
  stddev?: Maybe<Role_Permission_Stddev_Order_By>;
  stddev_pop?: Maybe<Role_Permission_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Role_Permission_Stddev_Samp_Order_By>;
  sum?: Maybe<Role_Permission_Sum_Order_By>;
  var_pop?: Maybe<Role_Permission_Var_Pop_Order_By>;
  var_samp?: Maybe<Role_Permission_Var_Samp_Order_By>;
  variance?: Maybe<Role_Permission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role_permission" */
export type Role_Permission_Arr_Rel_Insert_Input = {
  data: Array<Role_Permission_Insert_Input>;
  on_conflict?: Maybe<Role_Permission_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Permission_Avg_Fields = {
  __typename?: 'role_permission_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "role_permission" */
export type Role_Permission_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "role_permission". All fields are combined with a logical 'AND'. */
export type Role_Permission_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Role_Permission_Bool_Exp>>>;
  _not?: Maybe<Role_Permission_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Role_Permission_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  permission?: Maybe<Permission_Bool_Exp>;
  permission_id?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<Role_Bool_Exp>;
  role_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_permission" */
export enum Role_Permission_Constraint {
  /** unique or primary key constraint */
  RolePermissionPkey = 'role_permission_pkey'
}

/** input type for incrementing integer column in table "role_permission" */
export type Role_Permission_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "role_permission" */
export type Role_Permission_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission?: Maybe<Permission_Obj_Rel_Insert_Input>;
  permission_id?: Maybe<Scalars['bigint']>;
  role?: Maybe<Role_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Role_Permission_Max_Fields = {
  __typename?: 'role_permission_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "role_permission" */
export type Role_Permission_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Permission_Min_Fields = {
  __typename?: 'role_permission_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "role_permission" */
export type Role_Permission_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "role_permission" */
export type Role_Permission_Mutation_Response = {
  __typename?: 'role_permission_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Role_Permission>;
};

/** input type for inserting object relation for remote table "role_permission" */
export type Role_Permission_Obj_Rel_Insert_Input = {
  data: Role_Permission_Insert_Input;
  on_conflict?: Maybe<Role_Permission_On_Conflict>;
};

/** on conflict condition type for table "role_permission" */
export type Role_Permission_On_Conflict = {
  constraint: Role_Permission_Constraint;
  update_columns: Array<Role_Permission_Update_Column>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};

/** ordering options when selecting data from "role_permission" */
export type Role_Permission_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission?: Maybe<Permission_Order_By>;
  permission_id?: Maybe<Order_By>;
  role?: Maybe<Role_Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "role_permission" */
export type Role_Permission_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "role_permission" */
export enum Role_Permission_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "role_permission" */
export type Role_Permission_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Role_Permission_Stddev_Fields = {
  __typename?: 'role_permission_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "role_permission" */
export type Role_Permission_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Permission_Stddev_Pop_Fields = {
  __typename?: 'role_permission_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "role_permission" */
export type Role_Permission_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Permission_Stddev_Samp_Fields = {
  __typename?: 'role_permission_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "role_permission" */
export type Role_Permission_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Role_Permission_Sum_Fields = {
  __typename?: 'role_permission_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "role_permission" */
export type Role_Permission_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** update columns of table "role_permission" */
export enum Role_Permission_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Role_Permission_Var_Pop_Fields = {
  __typename?: 'role_permission_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "role_permission" */
export type Role_Permission_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Permission_Var_Samp_Fields = {
  __typename?: 'role_permission_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "role_permission" */
export type Role_Permission_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Permission_Variance_Fields = {
  __typename?: 'role_permission_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "role_permission" */
export type Role_Permission_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  permission_id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "role" */
export type Role_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Role_Stddev_Fields = {
  __typename?: 'role_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "role" */
export type Role_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Stddev_Pop_Fields = {
  __typename?: 'role_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "role" */
export type Role_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Stddev_Samp_Fields = {
  __typename?: 'role_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "role" */
export type Role_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Role_Sum_Fields = {
  __typename?: 'role_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "role" */
export type Role_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Role_Var_Pop_Fields = {
  __typename?: 'role_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "role" */
export type Role_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Var_Samp_Fields = {
  __typename?: 'role_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "role" */
export type Role_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Variance_Fields = {
  __typename?: 'role_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "role" */
export type Role_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "status" */
export type Status = {
  __typename?: 'status';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregated array relationship */
  payments_aggregate: Payment_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "status" */
export type StatusMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "status" */
export type StatusMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "status" */
export type StatusPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "status" */
export type StatusPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** aggregated selection of "status" */
export type Status_Aggregate = {
  __typename?: 'status_aggregate';
  aggregate?: Maybe<Status_Aggregate_Fields>;
  nodes: Array<Status>;
};

/** aggregate fields of "status" */
export type Status_Aggregate_Fields = {
  __typename?: 'status_aggregate_fields';
  avg?: Maybe<Status_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Status_Max_Fields>;
  min?: Maybe<Status_Min_Fields>;
  stddev?: Maybe<Status_Stddev_Fields>;
  stddev_pop?: Maybe<Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Status_Stddev_Samp_Fields>;
  sum?: Maybe<Status_Sum_Fields>;
  var_pop?: Maybe<Status_Var_Pop_Fields>;
  var_samp?: Maybe<Status_Var_Samp_Fields>;
  variance?: Maybe<Status_Variance_Fields>;
};


/** aggregate fields of "status" */
export type Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "status" */
export type Status_Aggregate_Order_By = {
  avg?: Maybe<Status_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Status_Max_Order_By>;
  min?: Maybe<Status_Min_Order_By>;
  stddev?: Maybe<Status_Stddev_Order_By>;
  stddev_pop?: Maybe<Status_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Status_Stddev_Samp_Order_By>;
  sum?: Maybe<Status_Sum_Order_By>;
  var_pop?: Maybe<Status_Var_Pop_Order_By>;
  var_samp?: Maybe<Status_Var_Samp_Order_By>;
  variance?: Maybe<Status_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "status" */
export type Status_Arr_Rel_Insert_Input = {
  data: Array<Status_Insert_Input>;
  on_conflict?: Maybe<Status_On_Conflict>;
};

/** aggregate avg on columns */
export type Status_Avg_Fields = {
  __typename?: 'status_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "status" */
export type Status_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "status". All fields are combined with a logical 'AND'. */
export type Status_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Status_Bool_Exp>>>;
  _not?: Maybe<Status_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Status_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "status" */
export enum Status_Constraint {
  /** unique or primary key constraint */
  StatusNameKey = 'status_name_key',
  /** unique or primary key constraint */
  StatusPkey = 'status_pkey'
}

/** input type for incrementing integer column in table "status" */
export type Status_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "status" */
export type Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Status_Max_Fields = {
  __typename?: 'status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "status" */
export type Status_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Status_Min_Fields = {
  __typename?: 'status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "status" */
export type Status_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "status" */
export type Status_Mutation_Response = {
  __typename?: 'status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Status>;
};

/** input type for inserting object relation for remote table "status" */
export type Status_Obj_Rel_Insert_Input = {
  data: Status_Insert_Input;
  on_conflict?: Maybe<Status_On_Conflict>;
};

/** on conflict condition type for table "status" */
export type Status_On_Conflict = {
  constraint: Status_Constraint;
  update_columns: Array<Status_Update_Column>;
  where?: Maybe<Status_Bool_Exp>;
};

/** ordering options when selecting data from "status" */
export type Status_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "status" */
export type Status_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "status" */
export enum Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "status" */
export type Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Status_Stddev_Fields = {
  __typename?: 'status_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "status" */
export type Status_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Status_Stddev_Pop_Fields = {
  __typename?: 'status_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "status" */
export type Status_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Status_Stddev_Samp_Fields = {
  __typename?: 'status_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "status" */
export type Status_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Status_Sum_Fields = {
  __typename?: 'status_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "status" */
export type Status_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "status" */
export enum Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Status_Var_Pop_Fields = {
  __typename?: 'status_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "status" */
export type Status_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Status_Var_Samp_Fields = {
  __typename?: 'status_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "status" */
export type Status_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Status_Variance_Fields = {
  __typename?: 'status_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "status" */
export type Status_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "advisor" */
  advisor: Array<Advisor>;
  /** fetch aggregated fields from the table: "advisor" */
  advisor_aggregate: Advisor_Aggregate;
  /** fetch data from the table: "advisor" using primary key columns */
  advisor_by_pk?: Maybe<Advisor>;
  /** fetch data from the table: "all_tables_template" */
  all_tables_template: Array<All_Tables_Template>;
  /** fetch aggregated fields from the table: "all_tables_template" */
  all_tables_template_aggregate: All_Tables_Template_Aggregate;
  /** fetch data from the table: "all_tables_template" using primary key columns */
  all_tables_template_by_pk?: Maybe<All_Tables_Template>;
  /** fetch data from the table: "apk" */
  apk: Array<Apk>;
  /** fetch aggregated fields from the table: "apk" */
  apk_aggregate: Apk_Aggregate;
  /** fetch data from the table: "apk" using primary key columns */
  apk_by_pk?: Maybe<Apk>;
  /** fetch data from the table: "app_config" */
  app_config: Array<App_Config>;
  /** fetch aggregated fields from the table: "app_config" */
  app_config_aggregate: App_Config_Aggregate;
  /** fetch data from the table: "app_config" using primary key columns */
  app_config_by_pk?: Maybe<App_Config>;
  /** fetch data from the table: "asset_allocation" */
  asset_allocation: Array<Asset_Allocation>;
  /** fetch aggregated fields from the table: "asset_allocation" */
  asset_allocation_aggregate: Asset_Allocation_Aggregate;
  /** fetch data from the table: "asset_allocation" using primary key columns */
  asset_allocation_by_pk?: Maybe<Asset_Allocation>;
  /** fetch data from the table: "asset_duration" */
  asset_duration: Array<Asset_Duration>;
  /** fetch aggregated fields from the table: "asset_duration" */
  asset_duration_aggregate: Asset_Duration_Aggregate;
  /** fetch data from the table: "asset_duration" using primary key columns */
  asset_duration_by_pk?: Maybe<Asset_Duration>;
  /** fetch data from the table: "audit_log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" using primary key columns */
  audit_log_by_pk?: Maybe<Audit_Log>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "background_notifications" */
  background_notifications: Array<Background_Notifications>;
  /** fetch aggregated fields from the table: "background_notifications" */
  background_notifications_aggregate: Background_Notifications_Aggregate;
  /** fetch data from the table: "background_notifications" using primary key columns */
  background_notifications_by_pk?: Maybe<Background_Notifications>;
  /** fetch data from the table: "branch" */
  branch: Array<Branch>;
  /** fetch aggregated fields from the table: "branch" */
  branch_aggregate: Branch_Aggregate;
  /** fetch data from the table: "branch" using primary key columns */
  branch_by_pk?: Maybe<Branch>;
  /** fetch data from the table: "client_investment_profile" */
  client_investment_profile: Array<Client_Investment_Profile>;
  /** fetch aggregated fields from the table: "client_investment_profile" */
  client_investment_profile_aggregate: Client_Investment_Profile_Aggregate;
  /** fetch data from the table: "client_investment_profile" using primary key columns */
  client_investment_profile_by_pk?: Maybe<Client_Investment_Profile>;
  /** fetch data from the table: "contribution_source" */
  contribution_source: Array<Contribution_Source>;
  /** fetch aggregated fields from the table: "contribution_source" */
  contribution_source_aggregate: Contribution_Source_Aggregate;
  /** fetch data from the table: "contribution_source" using primary key columns */
  contribution_source_by_pk?: Maybe<Contribution_Source>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "dashboard_summary" */
  dashboard_summary: Array<Dashboard_Summary>;
  /** fetch aggregated fields from the table: "dashboard_summary" */
  dashboard_summary_aggregate: Dashboard_Summary_Aggregate;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_share" */
  deal_share: Array<Deal_Share>;
  /** fetch aggregated fields from the table: "deal_share" */
  deal_share_aggregate: Deal_Share_Aggregate;
  /** fetch data from the table: "deal_share" using primary key columns */
  deal_share_by_pk?: Maybe<Deal_Share>;
  /** fetch data from the table: "direct_debit_charge" */
  direct_debit_charge: Array<Direct_Debit_Charge>;
  /** fetch aggregated fields from the table: "direct_debit_charge" */
  direct_debit_charge_aggregate: Direct_Debit_Charge_Aggregate;
  /** fetch data from the table: "direct_debit_charge" using primary key columns */
  direct_debit_charge_by_pk?: Maybe<Direct_Debit_Charge>;
  /** fetch data from the table: "economic_indicator" */
  economic_indicator: Array<Economic_Indicator>;
  /** fetch aggregated fields from the table: "economic_indicator" */
  economic_indicator_aggregate: Economic_Indicator_Aggregate;
  /** fetch data from the table: "economic_indicator" using primary key columns */
  economic_indicator_by_pk?: Maybe<Economic_Indicator>;
  /** fetch data from the table: "expected_return" */
  expected_return: Array<Expected_Return>;
  /** fetch aggregated fields from the table: "expected_return" */
  expected_return_aggregate: Expected_Return_Aggregate;
  /** fetch data from the table: "expected_return" using primary key columns */
  expected_return_by_pk?: Maybe<Expected_Return>;
  /** fetch data from the table: "gender" */
  gender: Array<Gender>;
  /** fetch aggregated fields from the table: "gender" */
  gender_aggregate: Gender_Aggregate;
  /** fetch data from the table: "gender" using primary key columns */
  gender_by_pk?: Maybe<Gender>;
  /** fetch data from the table: "goal" */
  goal: Array<Goal>;
  /** fetch aggregated fields from the table: "goal" */
  goal_aggregate: Goal_Aggregate;
  /** fetch data from the table: "goal" using primary key columns */
  goal_by_pk?: Maybe<Goal>;
  /** fetch data from the table: "goal_insurance" */
  goal_insurance: Array<Goal_Insurance>;
  /** fetch aggregated fields from the table: "goal_insurance" */
  goal_insurance_aggregate: Goal_Insurance_Aggregate;
  /** fetch data from the table: "goal_insurance" using primary key columns */
  goal_insurance_by_pk?: Maybe<Goal_Insurance>;
  /** fetch data from the table: "goal_product" */
  goal_product: Array<Goal_Product>;
  /** fetch aggregated fields from the table: "goal_product" */
  goal_product_aggregate: Goal_Product_Aggregate;
  /** fetch data from the table: "goal_product" using primary key columns */
  goal_product_by_pk?: Maybe<Goal_Product>;
  /** fetch data from the table: "hmd_education" */
  hmd_education: Array<Hmd_Education>;
  /** fetch aggregated fields from the table: "hmd_education" */
  hmd_education_aggregate: Hmd_Education_Aggregate;
  /** fetch data from the table: "hmd_education" using primary key columns */
  hmd_education_by_pk?: Maybe<Hmd_Education>;
  /** fetch data from the table: "hmd_expense_base" */
  hmd_expense_base: Array<Hmd_Expense_Base>;
  /** fetch aggregated fields from the table: "hmd_expense_base" */
  hmd_expense_base_aggregate: Hmd_Expense_Base_Aggregate;
  /** fetch data from the table: "hmd_expense_base" using primary key columns */
  hmd_expense_base_by_pk?: Maybe<Hmd_Expense_Base>;
  /** fetch data from the table: "hmd_expense_category" */
  hmd_expense_category: Array<Hmd_Expense_Category>;
  /** fetch aggregated fields from the table: "hmd_expense_category" */
  hmd_expense_category_aggregate: Hmd_Expense_Category_Aggregate;
  /** fetch data from the table: "hmd_expense_category" using primary key columns */
  hmd_expense_category_by_pk?: Maybe<Hmd_Expense_Category>;
  /** fetch data from the table: "hmd_expense_multiplier" */
  hmd_expense_multiplier: Array<Hmd_Expense_Multiplier>;
  /** fetch aggregated fields from the table: "hmd_expense_multiplier" */
  hmd_expense_multiplier_aggregate: Hmd_Expense_Multiplier_Aggregate;
  /** fetch data from the table: "hmd_expense_multiplier" using primary key columns */
  hmd_expense_multiplier_by_pk?: Maybe<Hmd_Expense_Multiplier>;
  /** fetch data from the table: "hmd_lifestyle" */
  hmd_lifestyle: Array<Hmd_Lifestyle>;
  /** fetch aggregated fields from the table: "hmd_lifestyle" */
  hmd_lifestyle_aggregate: Hmd_Lifestyle_Aggregate;
  /** fetch data from the table: "hmd_lifestyle" using primary key columns */
  hmd_lifestyle_by_pk?: Maybe<Hmd_Lifestyle>;
  /** fetch data from the table: "identification_type" */
  identification_type: Array<Identification_Type>;
  /** fetch aggregated fields from the table: "identification_type" */
  identification_type_aggregate: Identification_Type_Aggregate;
  /** fetch data from the table: "identification_type" using primary key columns */
  identification_type_by_pk?: Maybe<Identification_Type>;
  /** fetch data from the table: "income_source" */
  income_source: Array<Income_Source>;
  /** fetch aggregated fields from the table: "income_source" */
  income_source_aggregate: Income_Source_Aggregate;
  /** fetch data from the table: "income_source" using primary key columns */
  income_source_by_pk?: Maybe<Income_Source>;
  /** fetch data from the table: "industry" */
  industry: Array<Industry>;
  /** fetch aggregated fields from the table: "industry" */
  industry_aggregate: Industry_Aggregate;
  /** fetch data from the table: "industry" using primary key columns */
  industry_by_pk?: Maybe<Industry>;
  /** fetch data from the table: "insurance_rate_age_range" */
  insurance_rate_age_range: Array<Insurance_Rate_Age_Range>;
  /** fetch aggregated fields from the table: "insurance_rate_age_range" */
  insurance_rate_age_range_aggregate: Insurance_Rate_Age_Range_Aggregate;
  /** fetch data from the table: "insurance_rate_age_range" using primary key columns */
  insurance_rate_age_range_by_pk?: Maybe<Insurance_Rate_Age_Range>;
  /** fetch data from the table: "insurance_rate_duration" */
  insurance_rate_duration: Array<Insurance_Rate_Duration>;
  /** fetch aggregated fields from the table: "insurance_rate_duration" */
  insurance_rate_duration_aggregate: Insurance_Rate_Duration_Aggregate;
  /** fetch data from the table: "insurance_rate_duration" using primary key columns */
  insurance_rate_duration_by_pk?: Maybe<Insurance_Rate_Duration>;
  /** fetch data from the table: "insurance_type" */
  insurance_type: Array<Insurance_Type>;
  /** fetch aggregated fields from the table: "insurance_type" */
  insurance_type_aggregate: Insurance_Type_Aggregate;
  /** fetch data from the table: "insurance_type" using primary key columns */
  insurance_type_by_pk?: Maybe<Insurance_Type>;
  /** fetch data from the table: "marital_status" */
  marital_status: Array<Marital_Status>;
  /** fetch aggregated fields from the table: "marital_status" */
  marital_status_aggregate: Marital_Status_Aggregate;
  /** fetch data from the table: "marital_status" using primary key columns */
  marital_status_by_pk?: Maybe<Marital_Status>;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member_beneficiary" */
  member_beneficiary: Array<Member_Beneficiary>;
  /** fetch aggregated fields from the table: "member_beneficiary" */
  member_beneficiary_aggregate: Member_Beneficiary_Aggregate;
  /** fetch data from the table: "member_beneficiary" using primary key columns */
  member_beneficiary_by_pk?: Maybe<Member_Beneficiary>;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "member_contribution_source" */
  member_contribution_source: Array<Member_Contribution_Source>;
  /** fetch aggregated fields from the table: "member_contribution_source" */
  member_contribution_source_aggregate: Member_Contribution_Source_Aggregate;
  /** fetch data from the table: "member_contribution_source" using primary key columns */
  member_contribution_source_by_pk?: Maybe<Member_Contribution_Source>;
  /** fetch data from the table: "member_dependent" */
  member_dependent: Array<Member_Dependent>;
  /** fetch aggregated fields from the table: "member_dependent" */
  member_dependent_aggregate: Member_Dependent_Aggregate;
  /** fetch data from the table: "member_dependent" using primary key columns */
  member_dependent_by_pk?: Maybe<Member_Dependent>;
  /** fetch data from the table: "member_goal" */
  member_goal: Array<Member_Goal>;
  /** fetch aggregated fields from the table: "member_goal" */
  member_goal_aggregate: Member_Goal_Aggregate;
  /** fetch data from the table: "member_goal" using primary key columns */
  member_goal_by_pk?: Maybe<Member_Goal>;
  /** fetch data from the table: "member_identification" */
  member_identification: Array<Member_Identification>;
  /** fetch aggregated fields from the table: "member_identification" */
  member_identification_aggregate: Member_Identification_Aggregate;
  /** fetch data from the table: "member_identification" using primary key columns */
  member_identification_by_pk?: Maybe<Member_Identification>;
  /** fetch data from the table: "member_in_trust_for" */
  member_in_trust_for: Array<Member_In_Trust_For>;
  /** fetch aggregated fields from the table: "member_in_trust_for" */
  member_in_trust_for_aggregate: Member_In_Trust_For_Aggregate;
  /** fetch data from the table: "member_in_trust_for" using primary key columns */
  member_in_trust_for_by_pk?: Maybe<Member_In_Trust_For>;
  /** fetch data from the table: "member_income_source" */
  member_income_source: Array<Member_Income_Source>;
  /** fetch aggregated fields from the table: "member_income_source" */
  member_income_source_aggregate: Member_Income_Source_Aggregate;
  /** fetch data from the table: "member_income_source" using primary key columns */
  member_income_source_by_pk?: Maybe<Member_Income_Source>;
  /** fetch data from the table: "member_mode_of_instruction_communication" */
  member_mode_of_instruction_communication: Array<Member_Mode_Of_Instruction_Communication>;
  /** fetch aggregated fields from the table: "member_mode_of_instruction_communication" */
  member_mode_of_instruction_communication_aggregate: Member_Mode_Of_Instruction_Communication_Aggregate;
  /** fetch data from the table: "member_mode_of_instruction_communication" using primary key columns */
  member_mode_of_instruction_communication_by_pk?: Maybe<Member_Mode_Of_Instruction_Communication>;
  /** fetch data from the table: "member_payment_instruction" */
  member_payment_instruction: Array<Member_Payment_Instruction>;
  /** fetch aggregated fields from the table: "member_payment_instruction" */
  member_payment_instruction_aggregate: Member_Payment_Instruction_Aggregate;
  /** fetch data from the table: "member_payment_instruction" using primary key columns */
  member_payment_instruction_by_pk?: Maybe<Member_Payment_Instruction>;
  /** fetch data from the table: "member_redemption" */
  member_redemption: Array<Member_Redemption>;
  /** fetch aggregated fields from the table: "member_redemption" */
  member_redemption_aggregate: Member_Redemption_Aggregate;
  /** fetch data from the table: "member_redemption" using primary key columns */
  member_redemption_by_pk?: Maybe<Member_Redemption>;
  /** fetch data from the table: "member_reject" */
  member_reject: Array<Member_Reject>;
  /** fetch aggregated fields from the table: "member_reject" */
  member_reject_aggregate: Member_Reject_Aggregate;
  /** fetch data from the table: "member_reject" using primary key columns */
  member_reject_by_pk?: Maybe<Member_Reject>;
  /** fetch data from the table: "mode_of_instruction_communication" */
  mode_of_instruction_communication: Array<Mode_Of_Instruction_Communication>;
  /** fetch aggregated fields from the table: "mode_of_instruction_communication" */
  mode_of_instruction_communication_aggregate: Mode_Of_Instruction_Communication_Aggregate;
  /** fetch data from the table: "mode_of_instruction_communication" using primary key columns */
  mode_of_instruction_communication_by_pk?: Maybe<Mode_Of_Instruction_Communication>;
  /** fetch data from the table: "mp_product_split" */
  mp_product_split: Array<Mp_Product_Split>;
  /** fetch aggregated fields from the table: "mp_product_split" */
  mp_product_split_aggregate: Mp_Product_Split_Aggregate;
  /** fetch data from the table: "mp_product_split" using primary key columns */
  mp_product_split_by_pk?: Maybe<Mp_Product_Split>;
  /** fetch data from the table: "next_of_kin" */
  next_of_kin: Array<Next_Of_Kin>;
  /** fetch aggregated fields from the table: "next_of_kin" */
  next_of_kin_aggregate: Next_Of_Kin_Aggregate;
  /** fetch data from the table: "next_of_kin" using primary key columns */
  next_of_kin_by_pk?: Maybe<Next_Of_Kin>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_channel" */
  payment_channel: Array<Payment_Channel>;
  /** fetch aggregated fields from the table: "payment_channel" */
  payment_channel_aggregate: Payment_Channel_Aggregate;
  /** fetch data from the table: "payment_channel" using primary key columns */
  payment_channel_by_pk?: Maybe<Payment_Channel>;
  /** fetch data from the table: "payment_mode" */
  payment_mode: Array<Payment_Mode>;
  /** fetch aggregated fields from the table: "payment_mode" */
  payment_mode_aggregate: Payment_Mode_Aggregate;
  /** fetch data from the table: "payment_mode" using primary key columns */
  payment_mode_by_pk?: Maybe<Payment_Mode>;
  /** fetch data from the table: "payment_provider" */
  payment_provider: Array<Payment_Provider>;
  /** fetch aggregated fields from the table: "payment_provider" */
  payment_provider_aggregate: Payment_Provider_Aggregate;
  /** fetch data from the table: "payment_provider" using primary key columns */
  payment_provider_by_pk?: Maybe<Payment_Provider>;
  /** fetch data from the table: "payment_share" */
  payment_share: Array<Payment_Share>;
  /** fetch aggregated fields from the table: "payment_share" */
  payment_share_aggregate: Payment_Share_Aggregate;
  /** fetch data from the table: "payment_share" using primary key columns */
  payment_share_by_pk?: Maybe<Payment_Share>;
  /** fetch data from the table: "permission" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "permission" */
  permission_aggregate: Permission_Aggregate;
  /** fetch data from the table: "permission" using primary key columns */
  permission_by_pk?: Maybe<Permission>;
  /** fetch data from the table: "price" */
  price: Array<Price>;
  /** fetch aggregated fields from the table: "price" */
  price_aggregate: Price_Aggregate;
  /** fetch data from the table: "price" using primary key columns */
  price_by_pk?: Maybe<Price>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "redemption_share" */
  redemption_share: Array<Redemption_Share>;
  /** fetch aggregated fields from the table: "redemption_share" */
  redemption_share_aggregate: Redemption_Share_Aggregate;
  /** fetch data from the table: "redemption_share" using primary key columns */
  redemption_share_by_pk?: Maybe<Redemption_Share>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: Region_Aggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table: "relationship" */
  relationship: Array<Relationship>;
  /** fetch aggregated fields from the table: "relationship" */
  relationship_aggregate: Relationship_Aggregate;
  /** fetch data from the table: "relationship" using primary key columns */
  relationship_by_pk?: Maybe<Relationship>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "role_permission" */
  role_permission: Array<Role_Permission>;
  /** fetch aggregated fields from the table: "role_permission" */
  role_permission_aggregate: Role_Permission_Aggregate;
  /** fetch data from the table: "role_permission" using primary key columns */
  role_permission_by_pk?: Maybe<Role_Permission>;
  /** fetch data from the table: "status" */
  status: Array<Status>;
  /** fetch aggregated fields from the table: "status" */
  status_aggregate: Status_Aggregate;
  /** fetch data from the table: "status" using primary key columns */
  status_by_pk?: Maybe<Status>;
  /** fetch data from the table: "title" */
  title: Array<Title>;
  /** fetch aggregated fields from the table: "title" */
  title_aggregate: Title_Aggregate;
  /** fetch data from the table: "title" using primary key columns */
  title_by_pk?: Maybe<Title>;
  /** fetch data from the table: "university" */
  university: Array<University>;
  /** fetch aggregated fields from the table: "university" */
  university_aggregate: University_Aggregate;
  /** fetch data from the table: "university" using primary key columns */
  university_by_pk?: Maybe<University>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "user_activity_log" */
  user_activity_log: Array<User_Activity_Log>;
  /** fetch aggregated fields from the table: "user_activity_log" */
  user_activity_log_aggregate: User_Activity_Log_Aggregate;
  /** fetch data from the table: "user_activity_log" using primary key columns */
  user_activity_log_by_pk?: Maybe<User_Activity_Log>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
};


/** subscription root */
export type Subscription_RootAdvisorArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAdvisor_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAdvisor_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootAll_Tables_TemplateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAll_Tables_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAll_Tables_Template_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootApkArgs = {
  distinct_on?: Maybe<Array<Apk_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apk_Order_By>>;
  where?: Maybe<Apk_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApk_AggregateArgs = {
  distinct_on?: Maybe<Array<Apk_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Apk_Order_By>>;
  where?: Maybe<Apk_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApk_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootApp_ConfigArgs = {
  distinct_on?: Maybe<Array<App_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Config_Order_By>>;
  where?: Maybe<App_Config_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApp_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<App_Config_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<App_Config_Order_By>>;
  where?: Maybe<App_Config_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootApp_Config_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootAsset_AllocationArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAsset_Allocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Allocation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Allocation_Order_By>>;
  where?: Maybe<Asset_Allocation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAsset_Allocation_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootAsset_DurationArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAsset_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAsset_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootAudit_LogArgs = {
  distinct_on?: Maybe<Array<Audit_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Log_Order_By>>;
  where?: Maybe<Audit_Log_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudit_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Log_Order_By>>;
  where?: Maybe<Audit_Log_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: Maybe<Audit_Logged_Actions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootBackground_NotificationsArgs = {
  distinct_on?: Maybe<Array<Background_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Background_Notifications_Order_By>>;
  where?: Maybe<Background_Notifications_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBackground_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Background_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Background_Notifications_Order_By>>;
  where?: Maybe<Background_Notifications_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBackground_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBranchArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBranch_AggregateArgs = {
  distinct_on?: Maybe<Array<Branch_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Branch_Order_By>>;
  where?: Maybe<Branch_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBranch_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootClient_Investment_ProfileArgs = {
  distinct_on?: Maybe<Array<Client_Investment_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Investment_Profile_Order_By>>;
  where?: Maybe<Client_Investment_Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClient_Investment_Profile_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Investment_Profile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Investment_Profile_Order_By>>;
  where?: Maybe<Client_Investment_Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClient_Investment_Profile_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootContribution_SourceArgs = {
  distinct_on?: Maybe<Array<Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contribution_Source_Order_By>>;
  where?: Maybe<Contribution_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContribution_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contribution_Source_Order_By>>;
  where?: Maybe<Contribution_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCountry_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootDashboard_SummaryArgs = {
  distinct_on?: Maybe<Array<Dashboard_Summary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dashboard_Summary_Order_By>>;
  where?: Maybe<Dashboard_Summary_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDashboard_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Dashboard_Summary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dashboard_Summary_Order_By>>;
  where?: Maybe<Dashboard_Summary_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDealArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDeal_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDeal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootDeal_ShareArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDeal_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Share_Order_By>>;
  where?: Maybe<Deal_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDeal_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootDirect_Debit_ChargeArgs = {
  distinct_on?: Maybe<Array<Direct_Debit_Charge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Debit_Charge_Order_By>>;
  where?: Maybe<Direct_Debit_Charge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDirect_Debit_Charge_AggregateArgs = {
  distinct_on?: Maybe<Array<Direct_Debit_Charge_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Direct_Debit_Charge_Order_By>>;
  where?: Maybe<Direct_Debit_Charge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDirect_Debit_Charge_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootEconomic_IndicatorArgs = {
  distinct_on?: Maybe<Array<Economic_Indicator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Economic_Indicator_Order_By>>;
  where?: Maybe<Economic_Indicator_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEconomic_Indicator_AggregateArgs = {
  distinct_on?: Maybe<Array<Economic_Indicator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Economic_Indicator_Order_By>>;
  where?: Maybe<Economic_Indicator_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEconomic_Indicator_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootExpected_ReturnArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExpected_Return_AggregateArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExpected_Return_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootGenderArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGender_AggregateArgs = {
  distinct_on?: Maybe<Array<Gender_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gender_Order_By>>;
  where?: Maybe<Gender_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGender_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootGoalArgs = {
  distinct_on?: Maybe<Array<Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Order_By>>;
  where?: Maybe<Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Order_By>>;
  where?: Maybe<Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootGoal_InsuranceArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_Insurance_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Insurance_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Insurance_Order_By>>;
  where?: Maybe<Goal_Insurance_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_Insurance_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootGoal_ProductArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Goal_Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Goal_Product_Order_By>>;
  where?: Maybe<Goal_Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootGoal_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootHmd_EducationArgs = {
  distinct_on?: Maybe<Array<Hmd_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Education_Order_By>>;
  where?: Maybe<Hmd_Education_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Education_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Education_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Education_Order_By>>;
  where?: Maybe<Hmd_Education_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Education_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootHmd_Expense_BaseArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Base_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Base_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Base_Order_By>>;
  where?: Maybe<Hmd_Expense_Base_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Base_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootHmd_Expense_CategoryArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Category_Order_By>>;
  where?: Maybe<Hmd_Expense_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Category_Order_By>>;
  where?: Maybe<Hmd_Expense_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Category_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootHmd_Expense_MultiplierArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Multiplier_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Expense_Multiplier_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Expense_Multiplier_Order_By>>;
  where?: Maybe<Hmd_Expense_Multiplier_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Expense_Multiplier_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootHmd_LifestyleArgs = {
  distinct_on?: Maybe<Array<Hmd_Lifestyle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Lifestyle_Order_By>>;
  where?: Maybe<Hmd_Lifestyle_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Lifestyle_AggregateArgs = {
  distinct_on?: Maybe<Array<Hmd_Lifestyle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hmd_Lifestyle_Order_By>>;
  where?: Maybe<Hmd_Lifestyle_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHmd_Lifestyle_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootIdentification_TypeArgs = {
  distinct_on?: Maybe<Array<Identification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identification_Type_Order_By>>;
  where?: Maybe<Identification_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIdentification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Identification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Identification_Type_Order_By>>;
  where?: Maybe<Identification_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIdentification_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootIncome_SourceArgs = {
  distinct_on?: Maybe<Array<Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Income_Source_Order_By>>;
  where?: Maybe<Income_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIncome_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Income_Source_Order_By>>;
  where?: Maybe<Income_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIncome_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootIndustryArgs = {
  distinct_on?: Maybe<Array<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIndustry_AggregateArgs = {
  distinct_on?: Maybe<Array<Industry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Industry_Order_By>>;
  where?: Maybe<Industry_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootIndustry_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootInsurance_Rate_Age_RangeArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Rate_Age_Range_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Rate_Age_Range_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootInsurance_Rate_DurationArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Rate_Duration_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Rate_Duration_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootInsurance_TypeArgs = {
  distinct_on?: Maybe<Array<Insurance_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Type_Order_By>>;
  where?: Maybe<Insurance_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Type_Order_By>>;
  where?: Maybe<Insurance_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMarital_StatusArgs = {
  distinct_on?: Maybe<Array<Marital_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Marital_Status_Order_By>>;
  where?: Maybe<Marital_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMarital_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Marital_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Marital_Status_Order_By>>;
  where?: Maybe<Marital_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMarital_Status_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMemberArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_BeneficiaryArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Beneficiary_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Beneficiary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Beneficiary_Order_By>>;
  where?: Maybe<Member_Beneficiary_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Beneficiary_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_Contribution_SourceArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Contribution_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Contribution_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Contribution_Source_Order_By>>;
  where?: Maybe<Member_Contribution_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Contribution_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_DependentArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Dependent_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Dependent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Dependent_Order_By>>;
  where?: Maybe<Member_Dependent_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Dependent_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_GoalArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Goal_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Goal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Goal_Order_By>>;
  where?: Maybe<Member_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Goal_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_IdentificationArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Identification_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Identification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Identification_Order_By>>;
  where?: Maybe<Member_Identification_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Identification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_In_Trust_ForArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_In_Trust_For_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_In_Trust_For_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_In_Trust_For_Order_By>>;
  where?: Maybe<Member_In_Trust_For_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_In_Trust_For_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_Income_SourceArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Income_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Income_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Income_Source_Order_By>>;
  where?: Maybe<Member_Income_Source_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Income_Source_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_Mode_Of_Instruction_CommunicationArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Mode_Of_Instruction_Communication_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Member_Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Mode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_Payment_InstructionArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Payment_Instruction_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Payment_Instruction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Payment_Instruction_Order_By>>;
  where?: Maybe<Member_Payment_Instruction_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Payment_Instruction_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_RedemptionArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Redemption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Redemption_Order_By>>;
  where?: Maybe<Member_Redemption_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Redemption_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMember_RejectArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Reject_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Reject_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Reject_Order_By>>;
  where?: Maybe<Member_Reject_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMember_Reject_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMode_Of_Instruction_CommunicationArgs = {
  distinct_on?: Maybe<Array<Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMode_Of_Instruction_Communication_AggregateArgs = {
  distinct_on?: Maybe<Array<Mode_Of_Instruction_Communication_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mode_Of_Instruction_Communication_Order_By>>;
  where?: Maybe<Mode_Of_Instruction_Communication_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMode_Of_Instruction_Communication_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootMp_Product_SplitArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMp_Product_Split_AggregateArgs = {
  distinct_on?: Maybe<Array<Mp_Product_Split_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Mp_Product_Split_Order_By>>;
  where?: Maybe<Mp_Product_Split_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMp_Product_Split_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootNext_Of_KinArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNext_Of_Kin_AggregateArgs = {
  distinct_on?: Maybe<Array<Next_Of_Kin_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Next_Of_Kin_Order_By>>;
  where?: Maybe<Next_Of_Kin_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNext_Of_Kin_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPayment_ChannelArgs = {
  distinct_on?: Maybe<Array<Payment_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Channel_Order_By>>;
  where?: Maybe<Payment_Channel_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Channel_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Channel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Channel_Order_By>>;
  where?: Maybe<Payment_Channel_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Channel_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPayment_ModeArgs = {
  distinct_on?: Maybe<Array<Payment_Mode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Mode_Order_By>>;
  where?: Maybe<Payment_Mode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Mode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Mode_Order_By>>;
  where?: Maybe<Payment_Mode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Mode_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPayment_ProviderArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Provider_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Provider_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Provider_Order_By>>;
  where?: Maybe<Payment_Provider_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Provider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPayment_ShareArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Share_Order_By>>;
  where?: Maybe<Payment_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPermissionArgs = {
  distinct_on?: Maybe<Array<Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permission_Order_By>>;
  where?: Maybe<Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPermission_AggregateArgs = {
  distinct_on?: Maybe<Array<Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Permission_Order_By>>;
  where?: Maybe<Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPermission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootPriceArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrice_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPrice_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRedemption_ShareArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRedemption_Share_AggregateArgs = {
  distinct_on?: Maybe<Array<Redemption_Share_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Redemption_Share_Order_By>>;
  where?: Maybe<Redemption_Share_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRedemption_Share_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRegionArgs = {
  distinct_on?: Maybe<Array<Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Region_Order_By>>;
  where?: Maybe<Region_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegion_AggregateArgs = {
  distinct_on?: Maybe<Array<Region_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Region_Order_By>>;
  where?: Maybe<Region_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegion_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRelationshipArgs = {
  distinct_on?: Maybe<Array<Relationship_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Relationship_Order_By>>;
  where?: Maybe<Relationship_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRelationship_AggregateArgs = {
  distinct_on?: Maybe<Array<Relationship_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Relationship_Order_By>>;
  where?: Maybe<Relationship_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRelationship_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootRole_PermissionArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRole_Permission_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Permission_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Permission_Order_By>>;
  where?: Maybe<Role_Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRole_Permission_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStatusArgs = {
  distinct_on?: Maybe<Array<Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Status_Order_By>>;
  where?: Maybe<Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStatus_AggregateArgs = {
  distinct_on?: Maybe<Array<Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Status_Order_By>>;
  where?: Maybe<Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootTitleArgs = {
  distinct_on?: Maybe<Array<Title_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Title_Order_By>>;
  where?: Maybe<Title_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTitle_AggregateArgs = {
  distinct_on?: Maybe<Array<Title_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Title_Order_By>>;
  where?: Maybe<Title_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTitle_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootUniversityArgs = {
  distinct_on?: Maybe<Array<University_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<University_Order_By>>;
  where?: Maybe<University_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUniversity_AggregateArgs = {
  distinct_on?: Maybe<Array<University_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<University_Order_By>>;
  where?: Maybe<University_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUniversity_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Activity_LogArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Activity_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Activity_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "title" */
export type Title = {
  __typename?: 'title';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  created_date: Scalars['date'];
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  /** An array relationship */
  members: Array<Member>;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  /** An array relationship */
  members_jt: Array<Member>;
  /** An aggregated array relationship */
  members_jt_aggregate: Member_Aggregate;
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "title" */
export type TitleMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleMembers_JtArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "title" */
export type TitleMembers_Jt_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};

/** aggregated selection of "title" */
export type Title_Aggregate = {
  __typename?: 'title_aggregate';
  aggregate?: Maybe<Title_Aggregate_Fields>;
  nodes: Array<Title>;
};

/** aggregate fields of "title" */
export type Title_Aggregate_Fields = {
  __typename?: 'title_aggregate_fields';
  avg?: Maybe<Title_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Title_Max_Fields>;
  min?: Maybe<Title_Min_Fields>;
  stddev?: Maybe<Title_Stddev_Fields>;
  stddev_pop?: Maybe<Title_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Title_Stddev_Samp_Fields>;
  sum?: Maybe<Title_Sum_Fields>;
  var_pop?: Maybe<Title_Var_Pop_Fields>;
  var_samp?: Maybe<Title_Var_Samp_Fields>;
  variance?: Maybe<Title_Variance_Fields>;
};


/** aggregate fields of "title" */
export type Title_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Title_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "title" */
export type Title_Aggregate_Order_By = {
  avg?: Maybe<Title_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Title_Max_Order_By>;
  min?: Maybe<Title_Min_Order_By>;
  stddev?: Maybe<Title_Stddev_Order_By>;
  stddev_pop?: Maybe<Title_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Title_Stddev_Samp_Order_By>;
  sum?: Maybe<Title_Sum_Order_By>;
  var_pop?: Maybe<Title_Var_Pop_Order_By>;
  var_samp?: Maybe<Title_Var_Samp_Order_By>;
  variance?: Maybe<Title_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "title" */
export type Title_Arr_Rel_Insert_Input = {
  data: Array<Title_Insert_Input>;
  on_conflict?: Maybe<Title_On_Conflict>;
};

/** aggregate avg on columns */
export type Title_Avg_Fields = {
  __typename?: 'title_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "title" */
export type Title_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "title". All fields are combined with a logical 'AND'. */
export type Title_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Title_Bool_Exp>>>;
  _not?: Maybe<Title_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Title_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  created_date?: Maybe<Date_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  members_jt?: Maybe<Member_Bool_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "title" */
export enum Title_Constraint {
  /** unique or primary key constraint */
  TitleIdKey1 = 'title_id_key1',
  /** unique or primary key constraint */
  TitleNameKey = 'title_name_key',
  /** unique or primary key constraint */
  TitlePkey = 'title_pkey'
}

/** input type for incrementing integer column in table "title" */
export type Title_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "title" */
export type Title_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  created_date?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  members_jt?: Maybe<Member_Arr_Rel_Insert_Input>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Title_Max_Fields = {
  __typename?: 'title_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  created_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "title" */
export type Title_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Title_Min_Fields = {
  __typename?: 'title_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  created_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "title" */
export type Title_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "title" */
export type Title_Mutation_Response = {
  __typename?: 'title_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Title>;
};

/** input type for inserting object relation for remote table "title" */
export type Title_Obj_Rel_Insert_Input = {
  data: Title_Insert_Input;
  on_conflict?: Maybe<Title_On_Conflict>;
};

/** on conflict condition type for table "title" */
export type Title_On_Conflict = {
  constraint: Title_Constraint;
  update_columns: Array<Title_Update_Column>;
  where?: Maybe<Title_Bool_Exp>;
};

/** ordering options when selecting data from "title" */
export type Title_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_jt_aggregate?: Maybe<Member_Aggregate_Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "title" */
export type Title_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "title" */
export enum Title_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "title" */
export type Title_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  created_date?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Title_Stddev_Fields = {
  __typename?: 'title_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "title" */
export type Title_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Title_Stddev_Pop_Fields = {
  __typename?: 'title_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "title" */
export type Title_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Title_Stddev_Samp_Fields = {
  __typename?: 'title_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "title" */
export type Title_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Title_Sum_Fields = {
  __typename?: 'title_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "title" */
export type Title_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "title" */
export enum Title_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Title_Var_Pop_Fields = {
  __typename?: 'title_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "title" */
export type Title_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Title_Var_Samp_Fields = {
  __typename?: 'title_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "title" */
export type Title_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Title_Variance_Fields = {
  __typename?: 'title_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "title" */
export type Title_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** columns and relationships of "university" */
export type University = {
  __typename?: 'university';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  grad_yearly_fees_multiplier: Scalars['numeric'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  name: Scalars['String'];
  public: Scalars['Boolean'];
  undergrad_sem_fees_multiplier: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "university" */
export type University_Aggregate = {
  __typename?: 'university_aggregate';
  aggregate?: Maybe<University_Aggregate_Fields>;
  nodes: Array<University>;
};

/** aggregate fields of "university" */
export type University_Aggregate_Fields = {
  __typename?: 'university_aggregate_fields';
  avg?: Maybe<University_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<University_Max_Fields>;
  min?: Maybe<University_Min_Fields>;
  stddev?: Maybe<University_Stddev_Fields>;
  stddev_pop?: Maybe<University_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<University_Stddev_Samp_Fields>;
  sum?: Maybe<University_Sum_Fields>;
  var_pop?: Maybe<University_Var_Pop_Fields>;
  var_samp?: Maybe<University_Var_Samp_Fields>;
  variance?: Maybe<University_Variance_Fields>;
};


/** aggregate fields of "university" */
export type University_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<University_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "university" */
export type University_Aggregate_Order_By = {
  avg?: Maybe<University_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<University_Max_Order_By>;
  min?: Maybe<University_Min_Order_By>;
  stddev?: Maybe<University_Stddev_Order_By>;
  stddev_pop?: Maybe<University_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<University_Stddev_Samp_Order_By>;
  sum?: Maybe<University_Sum_Order_By>;
  var_pop?: Maybe<University_Var_Pop_Order_By>;
  var_samp?: Maybe<University_Var_Samp_Order_By>;
  variance?: Maybe<University_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "university" */
export type University_Arr_Rel_Insert_Input = {
  data: Array<University_Insert_Input>;
  on_conflict?: Maybe<University_On_Conflict>;
};

/** aggregate avg on columns */
export type University_Avg_Fields = {
  __typename?: 'university_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "university" */
export type University_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "university". All fields are combined with a logical 'AND'. */
export type University_Bool_Exp = {
  _and?: Maybe<Array<Maybe<University_Bool_Exp>>>;
  _not?: Maybe<University_Bool_Exp>;
  _or?: Maybe<Array<Maybe<University_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  grad_yearly_fees_multiplier?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  undergrad_sem_fees_multiplier?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "university" */
export enum University_Constraint {
  /** unique or primary key constraint */
  UniversityPkey = 'university_pkey'
}

/** input type for incrementing integer column in table "university" */
export type University_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "university" */
export type University_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type University_Max_Fields = {
  __typename?: 'university_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "university" */
export type University_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type University_Min_Fields = {
  __typename?: 'university_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "university" */
export type University_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "university" */
export type University_Mutation_Response = {
  __typename?: 'university_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<University>;
};

/** input type for inserting object relation for remote table "university" */
export type University_Obj_Rel_Insert_Input = {
  data: University_Insert_Input;
  on_conflict?: Maybe<University_On_Conflict>;
};

/** on conflict condition type for table "university" */
export type University_On_Conflict = {
  constraint: University_Constraint;
  update_columns: Array<University_Update_Column>;
  where?: Maybe<University_Bool_Exp>;
};

/** ordering options when selecting data from "university" */
export type University_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "university" */
export type University_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "university" */
export enum University_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GradYearlyFeesMultiplier = 'grad_yearly_fees_multiplier',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  Public = 'public',
  /** column name */
  UndergradSemFeesMultiplier = 'undergrad_sem_fees_multiplier',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "university" */
export type University_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type University_Stddev_Fields = {
  __typename?: 'university_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "university" */
export type University_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type University_Stddev_Pop_Fields = {
  __typename?: 'university_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "university" */
export type University_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type University_Stddev_Samp_Fields = {
  __typename?: 'university_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "university" */
export type University_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type University_Sum_Fields = {
  __typename?: 'university_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "university" */
export type University_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** update columns of table "university" */
export enum University_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  GradYearlyFeesMultiplier = 'grad_yearly_fees_multiplier',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Name = 'name',
  /** column name */
  Public = 'public',
  /** column name */
  UndergradSemFeesMultiplier = 'undergrad_sem_fees_multiplier',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type University_Var_Pop_Fields = {
  __typename?: 'university_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "university" */
export type University_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type University_Var_Samp_Fields = {
  __typename?: 'university_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "university" */
export type University_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type University_Variance_Fields = {
  __typename?: 'university_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  grad_yearly_fees_multiplier?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  undergrad_sem_fees_multiplier?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "university" */
export type University_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  grad_yearly_fees_multiplier?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  undergrad_sem_fees_multiplier?: Maybe<Order_By>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An array relationship */
  advisors: Array<Advisor>;
  /** An array relationship */
  advisorsByCreatedBy: Array<Advisor>;
  /** An aggregated array relationship */
  advisorsByCreatedBy_aggregate: Advisor_Aggregate;
  /** An array relationship */
  advisorsByModifiedBy: Array<Advisor>;
  /** An aggregated array relationship */
  advisorsByModifiedBy_aggregate: Advisor_Aggregate;
  /** An aggregated array relationship */
  advisors_aggregate: Advisor_Aggregate;
  /** An array relationship */
  allTablesTemplatesByModifiedBy: Array<All_Tables_Template>;
  /** An aggregated array relationship */
  allTablesTemplatesByModifiedBy_aggregate: All_Tables_Template_Aggregate;
  /** An array relationship */
  all_tables_templates: Array<All_Tables_Template>;
  /** An aggregated array relationship */
  all_tables_templates_aggregate: All_Tables_Template_Aggregate;
  /** An array relationship */
  assetDurationsByModifiedBy: Array<Asset_Duration>;
  /** An aggregated array relationship */
  assetDurationsByModifiedBy_aggregate: Asset_Duration_Aggregate;
  /** An array relationship */
  asset_durations: Array<Asset_Duration>;
  /** An aggregated array relationship */
  asset_durations_aggregate: Asset_Duration_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_birth_jt?: Maybe<Scalars['date']>;
  /** An array relationship */
  deals: Array<Deal>;
  /** An array relationship */
  dealsByModifiedBy: Array<Deal>;
  /** An aggregated array relationship */
  dealsByModifiedBy_aggregate: Deal_Aggregate;
  /** An aggregated array relationship */
  deals_aggregate: Deal_Aggregate;
  deleted: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  email_jt?: Maybe<Scalars['String']>;
  /** An array relationship */
  expectedReturnsByModifiedBy: Array<Expected_Return>;
  /** An aggregated array relationship */
  expectedReturnsByModifiedBy_aggregate: Expected_Return_Aggregate;
  /** An array relationship */
  expected_returns: Array<Expected_Return>;
  /** An aggregated array relationship */
  expected_returns_aggregate: Expected_Return_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  first_name_jt?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  gender: Gender;
  gender_id: Scalars['bigint'];
  gender_id_jt?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  gender_jt?: Maybe<Gender>;
  guid?: Maybe<Scalars['uuid']>;
  id: Scalars['bigint'];
  /** An array relationship */
  insuranceRateAgeRangesByModifiedBy: Array<Insurance_Rate_Age_Range>;
  /** An aggregated array relationship */
  insuranceRateAgeRangesByModifiedBy_aggregate: Insurance_Rate_Age_Range_Aggregate;
  /** An array relationship */
  insurance_rate_age_ranges: Array<Insurance_Rate_Age_Range>;
  /** An aggregated array relationship */
  insurance_rate_age_ranges_aggregate: Insurance_Rate_Age_Range_Aggregate;
  /** An array relationship */
  insurance_rate_durations: Array<Insurance_Rate_Duration>;
  /** An aggregated array relationship */
  insurance_rate_durations_aggregate: Insurance_Rate_Duration_Aggregate;
  last_name?: Maybe<Scalars['String']>;
  last_name_jt?: Maybe<Scalars['String']>;
  /** An array relationship */
  members: Array<Member>;
  /** An array relationship */
  membersByAssignedTo: Array<Member>;
  /** An aggregated array relationship */
  membersByAssignedTo_aggregate: Member_Aggregate;
  /** An aggregated array relationship */
  members_aggregate: Member_Aggregate;
  mobile?: Maybe<Scalars['String']>;
  mobile_jt?: Maybe<Scalars['String']>;
  mobile_jt2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  other_name?: Maybe<Scalars['String']>;
  other_name_jt?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payment>;
  /** An array relationship */
  paymentsByModifiedBy: Array<Payment>;
  /** An aggregated array relationship */
  paymentsByModifiedBy_aggregate: Payment_Aggregate;
  /** An aggregated array relationship */
  payments_aggregate: Payment_Aggregate;
  /** An array relationship */
  prices: Array<Price>;
  /** An array relationship */
  pricesByCreatedBy: Array<Price>;
  /** An aggregated array relationship */
  pricesByCreatedBy_aggregate: Price_Aggregate;
  /** An aggregated array relationship */
  prices_aggregate: Price_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  user_activity_logs: Array<User_Activity_Log>;
  /** An aggregated array relationship */
  user_activity_logs_aggregate: User_Activity_Log_Aggregate;
  /** An object relationship */
  user_advisor?: Maybe<Advisor>;
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregated array relationship */
  user_roles_aggregate: User_Role_Aggregate;
};


/** columns and relationships of "user" */
export type UserAdvisorsArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAdvisorsByCreatedByArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAdvisorsByCreatedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAdvisorsByModifiedByArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAdvisorsByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAdvisors_AggregateArgs = {
  distinct_on?: Maybe<Array<Advisor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Advisor_Order_By>>;
  where?: Maybe<Advisor_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAllTablesTemplatesByModifiedByArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAllTablesTemplatesByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAll_Tables_TemplatesArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAll_Tables_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<All_Tables_Template_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<All_Tables_Template_Order_By>>;
  where?: Maybe<All_Tables_Template_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAssetDurationsByModifiedByArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAssetDurationsByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAsset_DurationsArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserAsset_Durations_AggregateArgs = {
  distinct_on?: Maybe<Array<Asset_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Asset_Duration_Order_By>>;
  where?: Maybe<Asset_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserDealsArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserDealsByModifiedByArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserDealsByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserDeals_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Deal_Order_By>>;
  where?: Maybe<Deal_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserExpectedReturnsByModifiedByArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserExpectedReturnsByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserExpected_ReturnsArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserExpected_Returns_AggregateArgs = {
  distinct_on?: Maybe<Array<Expected_Return_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Expected_Return_Order_By>>;
  where?: Maybe<Expected_Return_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsuranceRateAgeRangesByModifiedByArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsuranceRateAgeRangesByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsurance_Rate_Age_RangesArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsurance_Rate_Age_Ranges_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Age_Range_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Age_Range_Order_By>>;
  where?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsurance_Rate_DurationsArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserInsurance_Rate_Durations_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Rate_Duration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Rate_Duration_Order_By>>;
  where?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMembersByAssignedToArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMembersByAssignedTo_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPaymentsByModifiedByArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPaymentsByModifiedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPricesArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPricesByCreatedByArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPricesByCreatedBy_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserPrices_AggregateArgs = {
  distinct_on?: Maybe<Array<Price_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Price_Order_By>>;
  where?: Maybe<Price_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Activity_LogsArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Activity_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Activity_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Activity_Log_Order_By>>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** columns and relationships of "user_activity_log" */
export type User_Activity_Log = {
  __typename?: 'user_activity_log';
  activity?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "user_activity_log" */
export type User_Activity_Log_Aggregate = {
  __typename?: 'user_activity_log_aggregate';
  aggregate?: Maybe<User_Activity_Log_Aggregate_Fields>;
  nodes: Array<User_Activity_Log>;
};

/** aggregate fields of "user_activity_log" */
export type User_Activity_Log_Aggregate_Fields = {
  __typename?: 'user_activity_log_aggregate_fields';
  avg?: Maybe<User_Activity_Log_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Activity_Log_Max_Fields>;
  min?: Maybe<User_Activity_Log_Min_Fields>;
  stddev?: Maybe<User_Activity_Log_Stddev_Fields>;
  stddev_pop?: Maybe<User_Activity_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Activity_Log_Stddev_Samp_Fields>;
  sum?: Maybe<User_Activity_Log_Sum_Fields>;
  var_pop?: Maybe<User_Activity_Log_Var_Pop_Fields>;
  var_samp?: Maybe<User_Activity_Log_Var_Samp_Fields>;
  variance?: Maybe<User_Activity_Log_Variance_Fields>;
};


/** aggregate fields of "user_activity_log" */
export type User_Activity_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Activity_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_activity_log" */
export type User_Activity_Log_Aggregate_Order_By = {
  avg?: Maybe<User_Activity_Log_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Activity_Log_Max_Order_By>;
  min?: Maybe<User_Activity_Log_Min_Order_By>;
  stddev?: Maybe<User_Activity_Log_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Activity_Log_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Activity_Log_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Activity_Log_Sum_Order_By>;
  var_pop?: Maybe<User_Activity_Log_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Activity_Log_Var_Samp_Order_By>;
  variance?: Maybe<User_Activity_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_activity_log" */
export type User_Activity_Log_Arr_Rel_Insert_Input = {
  data: Array<User_Activity_Log_Insert_Input>;
  on_conflict?: Maybe<User_Activity_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Activity_Log_Avg_Fields = {
  __typename?: 'user_activity_log_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_activity_log" */
export type User_Activity_Log_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_activity_log". All fields are combined with a logical 'AND'. */
export type User_Activity_Log_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Activity_Log_Bool_Exp>>>;
  _not?: Maybe<User_Activity_Log_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Activity_Log_Bool_Exp>>>;
  activity?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_activity_log" */
export enum User_Activity_Log_Constraint {
  /** unique or primary key constraint */
  UserActivityLogPkey = 'user_activity_log_pkey'
}

/** input type for incrementing integer column in table "user_activity_log" */
export type User_Activity_Log_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_activity_log" */
export type User_Activity_Log_Insert_Input = {
  activity?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Activity_Log_Max_Fields = {
  __typename?: 'user_activity_log_max_fields';
  activity?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_activity_log" */
export type User_Activity_Log_Max_Order_By = {
  activity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Activity_Log_Min_Fields = {
  __typename?: 'user_activity_log_min_fields';
  activity?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_activity_log" */
export type User_Activity_Log_Min_Order_By = {
  activity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_activity_log" */
export type User_Activity_Log_Mutation_Response = {
  __typename?: 'user_activity_log_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Activity_Log>;
};

/** input type for inserting object relation for remote table "user_activity_log" */
export type User_Activity_Log_Obj_Rel_Insert_Input = {
  data: User_Activity_Log_Insert_Input;
  on_conflict?: Maybe<User_Activity_Log_On_Conflict>;
};

/** on conflict condition type for table "user_activity_log" */
export type User_Activity_Log_On_Conflict = {
  constraint: User_Activity_Log_Constraint;
  update_columns: Array<User_Activity_Log_Update_Column>;
  where?: Maybe<User_Activity_Log_Bool_Exp>;
};

/** ordering options when selecting data from "user_activity_log" */
export type User_Activity_Log_Order_By = {
  activity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_activity_log" */
export type User_Activity_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_activity_log" */
export enum User_Activity_Log_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_activity_log" */
export type User_Activity_Log_Set_Input = {
  activity?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Activity_Log_Stddev_Fields = {
  __typename?: 'user_activity_log_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_activity_log" */
export type User_Activity_Log_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Activity_Log_Stddev_Pop_Fields = {
  __typename?: 'user_activity_log_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_activity_log" */
export type User_Activity_Log_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Activity_Log_Stddev_Samp_Fields = {
  __typename?: 'user_activity_log_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_activity_log" */
export type User_Activity_Log_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Activity_Log_Sum_Fields = {
  __typename?: 'user_activity_log_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_activity_log" */
export type User_Activity_Log_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "user_activity_log" */
export enum User_Activity_Log_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type User_Activity_Log_Var_Pop_Fields = {
  __typename?: 'user_activity_log_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_activity_log" */
export type User_Activity_Log_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Activity_Log_Var_Samp_Fields = {
  __typename?: 'user_activity_log_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_activity_log" */
export type User_Activity_Log_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Activity_Log_Variance_Fields = {
  __typename?: 'user_activity_log_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_activity_log" */
export type User_Activity_Log_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
  stddev?: Maybe<User_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Sum_Order_By>;
  var_pop?: Maybe<User_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Var_Samp_Order_By>;
  variance?: Maybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  advisors?: Maybe<Advisor_Bool_Exp>;
  advisorsByCreatedBy?: Maybe<Advisor_Bool_Exp>;
  advisorsByModifiedBy?: Maybe<Advisor_Bool_Exp>;
  allTablesTemplatesByModifiedBy?: Maybe<All_Tables_Template_Bool_Exp>;
  all_tables_templates?: Maybe<All_Tables_Template_Bool_Exp>;
  assetDurationsByModifiedBy?: Maybe<Asset_Duration_Bool_Exp>;
  asset_durations?: Maybe<Asset_Duration_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  date_of_birth_jt?: Maybe<Date_Comparison_Exp>;
  deals?: Maybe<Deal_Bool_Exp>;
  dealsByModifiedBy?: Maybe<Deal_Bool_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_jt?: Maybe<String_Comparison_Exp>;
  expectedReturnsByModifiedBy?: Maybe<Expected_Return_Bool_Exp>;
  expected_returns?: Maybe<Expected_Return_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  first_name_jt?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  gender?: Maybe<Gender_Bool_Exp>;
  gender_id?: Maybe<Bigint_Comparison_Exp>;
  gender_id_jt?: Maybe<Bigint_Comparison_Exp>;
  gender_jt?: Maybe<Gender_Bool_Exp>;
  guid?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  insuranceRateAgeRangesByModifiedBy?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
  insurance_rate_age_ranges?: Maybe<Insurance_Rate_Age_Range_Bool_Exp>;
  insurance_rate_durations?: Maybe<Insurance_Rate_Duration_Bool_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  last_name_jt?: Maybe<String_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  membersByAssignedTo?: Maybe<Member_Bool_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  mobile_jt?: Maybe<String_Comparison_Exp>;
  mobile_jt2?: Maybe<String_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  other_name?: Maybe<String_Comparison_Exp>;
  other_name_jt?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  paymentsByModifiedBy?: Maybe<Payment_Bool_Exp>;
  prices?: Maybe<Price_Bool_Exp>;
  pricesByCreatedBy?: Maybe<Price_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_activity_logs?: Maybe<User_Activity_Log_Bool_Exp>;
  user_advisor?: Maybe<Advisor_Bool_Exp>;
  user_roles?: Maybe<User_Role_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserGuidKey = 'user_guid_key',
  /** unique or primary key constraint */
  UserIdKey = 'user_id_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for incrementing integer column in table "user" */
export type User_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  advisors?: Maybe<Advisor_Arr_Rel_Insert_Input>;
  advisorsByCreatedBy?: Maybe<Advisor_Arr_Rel_Insert_Input>;
  advisorsByModifiedBy?: Maybe<Advisor_Arr_Rel_Insert_Input>;
  allTablesTemplatesByModifiedBy?: Maybe<All_Tables_Template_Arr_Rel_Insert_Input>;
  all_tables_templates?: Maybe<All_Tables_Template_Arr_Rel_Insert_Input>;
  assetDurationsByModifiedBy?: Maybe<Asset_Duration_Arr_Rel_Insert_Input>;
  asset_durations?: Maybe<Asset_Duration_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_birth_jt?: Maybe<Scalars['date']>;
  deals?: Maybe<Deal_Arr_Rel_Insert_Input>;
  dealsByModifiedBy?: Maybe<Deal_Arr_Rel_Insert_Input>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_jt?: Maybe<Scalars['String']>;
  expectedReturnsByModifiedBy?: Maybe<Expected_Return_Arr_Rel_Insert_Input>;
  expected_returns?: Maybe<Expected_Return_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  first_name_jt?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender_Obj_Rel_Insert_Input>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  gender_jt?: Maybe<Gender_Obj_Rel_Insert_Input>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  insuranceRateAgeRangesByModifiedBy?: Maybe<Insurance_Rate_Age_Range_Arr_Rel_Insert_Input>;
  insurance_rate_age_ranges?: Maybe<Insurance_Rate_Age_Range_Arr_Rel_Insert_Input>;
  insurance_rate_durations?: Maybe<Insurance_Rate_Duration_Arr_Rel_Insert_Input>;
  last_name?: Maybe<Scalars['String']>;
  last_name_jt?: Maybe<Scalars['String']>;
  members?: Maybe<Member_Arr_Rel_Insert_Input>;
  membersByAssignedTo?: Maybe<Member_Arr_Rel_Insert_Input>;
  mobile?: Maybe<Scalars['String']>;
  mobile_jt?: Maybe<Scalars['String']>;
  mobile_jt2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  other_name?: Maybe<Scalars['String']>;
  other_name_jt?: Maybe<Scalars['String']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  paymentsByModifiedBy?: Maybe<Payment_Arr_Rel_Insert_Input>;
  prices?: Maybe<Price_Arr_Rel_Insert_Input>;
  pricesByCreatedBy?: Maybe<Price_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_activity_logs?: Maybe<User_Activity_Log_Arr_Rel_Insert_Input>;
  user_advisor?: Maybe<Advisor_Obj_Rel_Insert_Input>;
  user_roles?: Maybe<User_Role_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_birth_jt?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  email_jt?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  first_name_jt?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  last_name_jt?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile_jt?: Maybe<Scalars['String']>;
  mobile_jt2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  other_name?: Maybe<Scalars['String']>;
  other_name_jt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  date_of_birth_jt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_jt?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  first_name_jt?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_name_jt?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile_jt?: Maybe<Order_By>;
  mobile_jt2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  other_name?: Maybe<Order_By>;
  other_name_jt?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_birth_jt?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  email_jt?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  first_name_jt?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  last_name_jt?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile_jt?: Maybe<Scalars['String']>;
  mobile_jt2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  other_name?: Maybe<Scalars['String']>;
  other_name_jt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  date_of_birth_jt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_jt?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  first_name_jt?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_name_jt?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  mobile_jt?: Maybe<Order_By>;
  mobile_jt2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  other_name?: Maybe<Order_By>;
  other_name_jt?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  advisorsByCreatedBy_aggregate?: Maybe<Advisor_Aggregate_Order_By>;
  advisorsByModifiedBy_aggregate?: Maybe<Advisor_Aggregate_Order_By>;
  advisors_aggregate?: Maybe<Advisor_Aggregate_Order_By>;
  allTablesTemplatesByModifiedBy_aggregate?: Maybe<All_Tables_Template_Aggregate_Order_By>;
  all_tables_templates_aggregate?: Maybe<All_Tables_Template_Aggregate_Order_By>;
  assetDurationsByModifiedBy_aggregate?: Maybe<Asset_Duration_Aggregate_Order_By>;
  asset_durations_aggregate?: Maybe<Asset_Duration_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  date_of_birth_jt?: Maybe<Order_By>;
  dealsByModifiedBy_aggregate?: Maybe<Deal_Aggregate_Order_By>;
  deals_aggregate?: Maybe<Deal_Aggregate_Order_By>;
  deleted?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_jt?: Maybe<Order_By>;
  expectedReturnsByModifiedBy_aggregate?: Maybe<Expected_Return_Aggregate_Order_By>;
  expected_returns_aggregate?: Maybe<Expected_Return_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  first_name_jt?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  gender?: Maybe<Gender_Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  gender_jt?: Maybe<Gender_Order_By>;
  guid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insuranceRateAgeRangesByModifiedBy_aggregate?: Maybe<Insurance_Rate_Age_Range_Aggregate_Order_By>;
  insurance_rate_age_ranges_aggregate?: Maybe<Insurance_Rate_Age_Range_Aggregate_Order_By>;
  insurance_rate_durations_aggregate?: Maybe<Insurance_Rate_Duration_Aggregate_Order_By>;
  last_name?: Maybe<Order_By>;
  last_name_jt?: Maybe<Order_By>;
  membersByAssignedTo_aggregate?: Maybe<Member_Aggregate_Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  mobile?: Maybe<Order_By>;
  mobile_jt?: Maybe<Order_By>;
  mobile_jt2?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  other_name?: Maybe<Order_By>;
  other_name_jt?: Maybe<Order_By>;
  paymentsByModifiedBy_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  pricesByCreatedBy_aggregate?: Maybe<Price_Aggregate_Order_By>;
  prices_aggregate?: Maybe<Price_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_activity_logs_aggregate?: Maybe<User_Activity_Log_Aggregate_Order_By>;
  user_advisor?: Maybe<Advisor_Order_By>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted: Scalars['Boolean'];
  id: Scalars['bigint'];
  modified_by?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  role?: Maybe<Role>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate';
  aggregate?: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields';
  avg?: Maybe<User_Role_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Role_Max_Fields>;
  min?: Maybe<User_Role_Min_Fields>;
  stddev?: Maybe<User_Role_Stddev_Fields>;
  stddev_pop?: Maybe<User_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Role_Stddev_Samp_Fields>;
  sum?: Maybe<User_Role_Sum_Fields>;
  var_pop?: Maybe<User_Role_Var_Pop_Fields>;
  var_samp?: Maybe<User_Role_Var_Samp_Fields>;
  variance?: Maybe<User_Role_Variance_Fields>;
};


/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_role" */
export type User_Role_Aggregate_Order_By = {
  avg?: Maybe<User_Role_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Role_Max_Order_By>;
  min?: Maybe<User_Role_Min_Order_By>;
  stddev?: Maybe<User_Role_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Role_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Role_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Role_Sum_Order_By>;
  var_pop?: Maybe<User_Role_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Role_Var_Samp_Order_By>;
  variance?: Maybe<User_Role_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_role" */
export type User_Role_Arr_Rel_Insert_Input = {
  data: Array<User_Role_Insert_Input>;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Role_Avg_Fields = {
  __typename?: 'user_role_avg_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_role" */
export type User_Role_Avg_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Role_Bool_Exp>>>;
  _not?: Maybe<User_Role_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Role_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Bigint_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  modified_by?: Maybe<Bigint_Comparison_Exp>;
  role?: Maybe<Role_Bool_Exp>;
  role_id?: Maybe<Bigint_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint */
  UserRolePkey = 'user_role_pkey'
}

/** input type for incrementing integer column in table "user_role" */
export type User_Role_Inc_Input = {
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role?: Maybe<Role_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "user_role" */
export type User_Role_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "user_role" */
export type User_Role_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Role>;
};

/** input type for inserting object relation for remote table "user_role" */
export type User_Role_Obj_Rel_Insert_Input = {
  data: User_Role_Insert_Input;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};

/** on conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns: Array<User_Role_Update_Column>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** ordering options when selecting data from "user_role" */
export type User_Role_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role?: Maybe<Role_Order_By>;
  role_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_role" */
export type User_Role_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type User_Role_Stddev_Fields = {
  __typename?: 'user_role_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_role" */
export type User_Role_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Role_Stddev_Pop_Fields = {
  __typename?: 'user_role_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_role" */
export type User_Role_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Role_Stddev_Samp_Fields = {
  __typename?: 'user_role_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_role" */
export type User_Role_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Role_Sum_Fields = {
  __typename?: 'user_role_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user_role" */
export type User_Role_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type User_Role_Var_Pop_Fields = {
  __typename?: 'user_role_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_role" */
export type User_Role_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Role_Var_Samp_Fields = {
  __typename?: 'user_role_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_role" */
export type User_Role_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Role_Variance_Fields = {
  __typename?: 'user_role_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_role" */
export type User_Role_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DateOfBirthJt = 'date_of_birth_jt',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  EmailJt = 'email_jt',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameJt = 'first_name_jt',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GenderIdJt = 'gender_id_jt',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameJt = 'last_name_jt',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  MobileJt = 'mobile_jt',
  /** column name */
  MobileJt2 = 'mobile_jt2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  OtherName = 'other_name',
  /** column name */
  OtherNameJt = 'other_name_jt',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['bigint']>;
  date_of_birth?: Maybe<Scalars['date']>;
  date_of_birth_jt?: Maybe<Scalars['date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_jt?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  first_name_jt?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  guid?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  last_name?: Maybe<Scalars['String']>;
  last_name_jt?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  mobile_jt?: Maybe<Scalars['String']>;
  mobile_jt2?: Maybe<Scalars['String']>;
  modified_by?: Maybe<Scalars['bigint']>;
  other_name?: Maybe<Scalars['String']>;
  other_name_jt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  created_by?: Maybe<Scalars['bigint']>;
  gender_id?: Maybe<Scalars['bigint']>;
  gender_id_jt?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  modified_by?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DateOfBirthJt = 'date_of_birth_jt',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Email = 'email',
  /** column name */
  EmailJt = 'email_jt',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FirstNameJt = 'first_name_jt',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GenderId = 'gender_id',
  /** column name */
  GenderIdJt = 'gender_id_jt',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastNameJt = 'last_name_jt',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  MobileJt = 'mobile_jt',
  /** column name */
  MobileJt2 = 'mobile_jt2',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  OtherName = 'other_name',
  /** column name */
  OtherNameJt = 'other_name_jt',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  created_by?: Maybe<Scalars['Float']>;
  gender_id?: Maybe<Scalars['Float']>;
  gender_id_jt?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  modified_by?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  created_by?: Maybe<Order_By>;
  gender_id?: Maybe<Order_By>;
  gender_id_jt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AdvisorsQueryVariables = {};


export type AdvisorsQuery = (
  { __typename?: 'query_root' }
  & { advisor: Array<(
    { __typename?: 'advisor' }
    & Pick<Advisor, 'id' | 'advisor_code' | 'residential_address' | 'identification_type_id' | 'identification_number'>
    & { userById: (
      { __typename?: 'user' }
      & Pick<User, 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'created_at' | 'updated_at' | 'date_of_birth' | 'mobile' | 'email'>
      & { gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'name'>
      ) }
    ), identification_type: (
      { __typename?: 'identification_type' }
      & Pick<Identification_Type, 'name'>
    ) }
  )> }
);

export type Add_AdvisorMutationVariables = {
  changes: Array<Advisor_Insert_Input>;
};


export type Add_AdvisorMutation = (
  { __typename?: 'mutation_root' }
  & { insert_advisor?: Maybe<(
    { __typename?: 'advisor_mutation_response' }
    & { returning: Array<(
      { __typename?: 'advisor' }
      & Pick<Advisor, 'id' | 'advisor_code' | 'residential_address' | 'identification_type_id' | 'identification_number'>
      & { userById: (
        { __typename?: 'user' }
        & Pick<User, 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'created_at' | 'updated_at' | 'date_of_birth' | 'mobile' | 'email'>
        & { gender: (
          { __typename?: 'gender' }
          & Pick<Gender, 'name'>
        ) }
      ), identification_type: (
        { __typename?: 'identification_type' }
        & Pick<Identification_Type, 'name'>
      ) }
    )> }
  )> }
);

export type Update_AdvisorMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  advisor_changes?: Maybe<Advisor_Set_Input>;
  user_changes?: Maybe<User_Set_Input>;
};


export type Update_AdvisorMutation = (
  { __typename?: 'mutation_root' }
  & { update_advisor?: Maybe<(
    { __typename?: 'advisor_mutation_response' }
    & Pick<Advisor_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'advisor' }
      & Pick<Advisor, 'id' | 'advisor_code' | 'residential_address' | 'identification_type_id' | 'identification_number'>
      & { userById: (
        { __typename?: 'user' }
        & Pick<User, 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
        & { gender: (
          { __typename?: 'gender' }
          & Pick<Gender, 'name'>
        ) }
      ), identification_type: (
        { __typename?: 'identification_type' }
        & Pick<Identification_Type, 'name'>
      ) }
    )> }
  )>, update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type ApksQueryVariables = {};


export type ApksQuery = (
  { __typename?: 'query_root' }
  & { apk: Array<(
    { __typename?: 'apk' }
    & Pick<Apk, 'id' | 'name' | 'version' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_ApkMutationVariables = {
  changes: Array<Apk_Insert_Input>;
};


export type Add_ApkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_apk?: Maybe<(
    { __typename?: 'apk_mutation_response' }
    & { returning: Array<(
      { __typename?: 'apk' }
      & Pick<Apk, 'id' | 'name' | 'version' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_ApkMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Apk_Set_Input>;
};


export type Update_ApkMutation = (
  { __typename?: 'mutation_root' }
  & { update_apk?: Maybe<(
    { __typename?: 'apk_mutation_response' }
    & Pick<Apk_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'apk' }
      & Pick<Apk, 'id' | 'name' | 'version' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_ApkMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_ApkMutation = (
  { __typename?: 'mutation_root' }
  & { update_apk?: Maybe<(
    { __typename?: 'apk_mutation_response' }
    & Pick<Apk_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'apk' }
      & Pick<Apk, 'id' | 'name' | 'version' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type GetAppConfigQueryVariables = {
  name: Scalars['String'];
};


export type GetAppConfigQuery = (
  { __typename?: 'query_root' }
  & { app_config: Array<(
    { __typename?: 'app_config' }
    & Pick<App_Config, 'id' | 'name' | 'value'>
  )> }
);

export type ApprovePaymentsMutationVariables = {
  ids: Array<Scalars['Int']>;
};


export type ApprovePaymentsMutation = (
  { __typename?: 'mutation_root' }
  & { approvePayments: (
    { __typename?: 'ApprovePaymentResponse' }
    & Pick<ApprovePaymentResponse, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'path' | 'message'>
    )>> }
  ) }
);

export type AssetAllocationQueryVariables = {};


export type AssetAllocationQuery = (
  { __typename?: 'query_root' }
  & { asset_allocation: Array<(
    { __typename?: 'asset_allocation' }
    & Pick<Asset_Allocation, 'id' | 'asset_duration_id' | 'product_id' | 'weight' | 'created_at' | 'updated_at' | 'deleted'>
    & { product: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
    ), asset_duration: (
      { __typename?: 'asset_duration' }
      & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end'>
    ) }
  )> }
);

export type Add_Asset_AllocationMutationVariables = {
  changes: Array<Asset_Allocation_Insert_Input>;
};


export type Add_Asset_AllocationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_asset_allocation?: Maybe<(
    { __typename?: 'asset_allocation_mutation_response' }
    & { returning: Array<(
      { __typename?: 'asset_allocation' }
      & Pick<Asset_Allocation, 'id' | 'asset_duration_id' | 'product_id' | 'weight' | 'created_at' | 'updated_at' | 'deleted'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ), asset_duration: (
        { __typename?: 'asset_duration' }
        & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end'>
      ) }
    )> }
  )> }
);

export type Update_Asset_AllocationMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Asset_Allocation_Set_Input>;
};


export type Update_Asset_AllocationMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_allocation?: Maybe<(
    { __typename?: 'asset_allocation_mutation_response' }
    & Pick<Asset_Allocation_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'asset_allocation' }
      & Pick<Asset_Allocation, 'id' | 'asset_duration_id' | 'product_id' | 'weight' | 'created_at' | 'updated_at' | 'deleted'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ), asset_duration: (
        { __typename?: 'asset_duration' }
        & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end'>
      ) }
    )> }
  )> }
);

export type Delete_Asset_AllocationMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Asset_AllocationMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_allocation?: Maybe<(
    { __typename?: 'asset_allocation_mutation_response' }
    & Pick<Asset_Allocation_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'asset_allocation' }
      & Pick<Asset_Allocation, 'id' | 'asset_duration_id' | 'product_id' | 'weight' | 'created_at' | 'updated_at' | 'deleted'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ), asset_duration: (
        { __typename?: 'asset_duration' }
        & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end'>
      ) }
    )> }
  )> }
);

export type AssetDurationQueryVariables = {};


export type AssetDurationQuery = (
  { __typename?: 'query_root' }
  & { asset_duration: Array<(
    { __typename?: 'asset_duration' }
    & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Asset_DurationMutationVariables = {
  changes: Array<Asset_Duration_Insert_Input>;
};


export type Add_Asset_DurationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_asset_duration?: Maybe<(
    { __typename?: 'asset_duration_mutation_response' }
    & { returning: Array<(
      { __typename?: 'asset_duration' }
      & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Asset_DurationMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Asset_Duration_Set_Input>;
};


export type Update_Asset_DurationMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_duration?: Maybe<(
    { __typename?: 'asset_duration_mutation_response' }
    & Pick<Asset_Duration_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'asset_duration' }
      & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Asset_DurationMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Asset_DurationMutation = (
  { __typename?: 'mutation_root' }
  & { update_asset_duration?: Maybe<(
    { __typename?: 'asset_duration_mutation_response' }
    & Pick<Asset_Duration_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'asset_duration' }
      & Pick<Asset_Duration, 'id' | 'duration_start' | 'duration_end' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type PaginatedBackgroundNotificationsQueryVariables = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type PaginatedBackgroundNotificationsQuery = (
  { __typename?: 'query_root' }
  & { background_notifications_aggregate: (
    { __typename?: 'background_notifications_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'background_notifications_aggregate_fields' }
      & Pick<Background_Notifications_Aggregate_Fields, 'count'>
    )> }
  ), background_notifications: Array<(
    { __typename?: 'background_notifications' }
    & Pick<Background_Notifications, 'id' | 'pslcode' | 'status' | 'response' | 'type' | 'created_at'>
  )> }
);

export type BackgroundNotificationQueryVariables = {
  id?: Maybe<Scalars['Int']>;
};


export type BackgroundNotificationQuery = (
  { __typename?: 'query_root' }
  & { background_notifications: Array<(
    { __typename?: 'background_notifications' }
    & Pick<Background_Notifications, 'id' | 'status' | 'type' | 'pslcode' | 'created_at' | 'response'>
  )> }
);

export type BranchQueryVariables = {};


export type BranchQuery = (
  { __typename?: 'query_root' }
  & { branch: Array<(
    { __typename?: 'branch' }
    & Pick<Branch, 'id' | 'name' | 'payment_provider_id' | 'created_at' | 'updated_at'>
    & { payment_provider: (
      { __typename?: 'payment_provider' }
      & Pick<Payment_Provider, 'id' | 'name'>
    ) }
  )> }
);

export type ContributionSourceQueryVariables = {};


export type ContributionSourceQuery = (
  { __typename?: 'query_root' }
  & { contribution_source: Array<(
    { __typename?: 'contribution_source' }
    & Pick<Contribution_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Contribution_SourceMutationVariables = {
  changes: Array<Contribution_Source_Insert_Input>;
};


export type Add_Contribution_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_contribution_source?: Maybe<(
    { __typename?: 'contribution_source_mutation_response' }
    & { returning: Array<(
      { __typename?: 'contribution_source' }
      & Pick<Contribution_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Contribution_SourceMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Contribution_Source_Set_Input>;
};


export type Update_Contribution_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_contribution_source?: Maybe<(
    { __typename?: 'contribution_source_mutation_response' }
    & Pick<Contribution_Source_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'contribution_source' }
      & Pick<Contribution_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Contribution_SourceMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Contribution_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_contribution_source?: Maybe<(
    { __typename?: 'contribution_source_mutation_response' }
    & Pick<Contribution_Source_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'contribution_source' }
      & Pick<Contribution_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type CountryQueryVariables = {};


export type CountryQuery = (
  { __typename?: 'query_root' }
  & { country: Array<(
    { __typename?: 'country' }
    & Pick<Country, 'id' | 'name' | 'created_at' | 'updated_at'>
  )> }
);

export type CustomerQueryVariables = {};


export type CustomerQuery = (
  { __typename?: 'query_root' }
  & { member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'psl_code' | 'nationality' | 'tin'>
    & { status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      & { gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type PaginatedCustomersQueryVariables = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type PaginatedCustomersQuery = (
  { __typename?: 'query_root' }
  & { member_aggregate: (
    { __typename?: 'member_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'member_aggregate_fields' }
      & Pick<Member_Aggregate_Fields, 'count'>
    )> }
  ), member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'account_type' | 'psl_code' | 'nationality' | 'tin'>
    & { status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      & { gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type RecentCustomersQueryVariables = {};


export type RecentCustomersQuery = (
  { __typename?: 'query_root' }
  & { member: Array<(
    { __typename?: 'member' }
    & { user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name' | 'email' | 'mobile'>
    ) }
  )> }
);

export type DashboardSummaryQueryVariables = {};


export type DashboardSummaryQuery = (
  { __typename?: 'query_root' }
  & { dashboard_summary: Array<(
    { __typename?: 'dashboard_summary' }
    & Pick<Dashboard_Summary, 'agent_count' | 'last_thirty_days_customer_count' | 'total_customer_count' | 'percentage_customer_count_increase' | 'total_prospect_count' | 'last_thirty_days_prospect_count' | 'percentage_prospect_count_increase'>
  )> }
);

export type ExpectedReturnsQueryVariables = {};


export type ExpectedReturnsQuery = (
  { __typename?: 'query_root' }
  & { expected_return: Array<(
    { __typename?: 'expected_return' }
    & Pick<Expected_Return, 'duration' | 'expected_return'>
  )> }
);

export type GendersQueryVariables = {};


export type GendersQuery = (
  { __typename?: 'query_root' }
  & { gender: Array<(
    { __typename?: 'gender' }
    & Pick<Gender, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_GenderMutationVariables = {
  changes: Array<Gender_Insert_Input>;
};


export type Add_GenderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_gender?: Maybe<(
    { __typename?: 'gender_mutation_response' }
    & { returning: Array<(
      { __typename?: 'gender' }
      & Pick<Gender, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_GenderMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Gender_Set_Input>;
};


export type Update_GenderMutation = (
  { __typename?: 'mutation_root' }
  & { update_gender?: Maybe<(
    { __typename?: 'gender_mutation_response' }
    & Pick<Gender_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'gender' }
      & Pick<Gender, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_GenderMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_GenderMutation = (
  { __typename?: 'mutation_root' }
  & { update_gender?: Maybe<(
    { __typename?: 'gender_mutation_response' }
    & Pick<Gender_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'gender' }
      & Pick<Gender, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Generate_Signeds3_Request_For_UploadMutationVariables = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};


export type Generate_Signeds3_Request_For_UploadMutation = (
  { __typename?: 'mutation_root' }
  & { generateSignedS3RequestForUpload: (
    { __typename?: 'S3PayloadResponse' }
    & Pick<S3PayloadResponse, 'signedRequest' | 'url'>
  ) }
);

export type Generate_Signeds3_Request_For_DownloadMutationVariables = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};


export type Generate_Signeds3_Request_For_DownloadMutation = (
  { __typename?: 'mutation_root' }
  & { generateSignedS3RequestForDownload: (
    { __typename?: 'S3PayloadResponse' }
    & Pick<S3PayloadResponse, 'signedRequest' | 'url'>
  ) }
);

export type GenerateStatementMutationVariables = {
  customerId: Scalars['Float'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type GenerateStatementMutation = (
  { __typename?: 'mutation_root' }
  & { generateStatement: (
    { __typename?: 'GenerateStatementResponse' }
    & Pick<GenerateStatementResponse, 'success'>
    & { results?: Maybe<(
      { __typename?: 'StatementType' }
      & Pick<StatementType, 'startDate' | 'endDate' | 'beginningBalance' | 'contributions' | 'totalPeriodNetIncome' | 'endOfPeriodOfBalance' | 'redemptions' | 'goalSummaryTotal'>
      & { payments: Array<(
        { __typename?: 'ProductPayment' }
        & Pick<ProductPayment, 'date' | 'firstPaymentDate' | 'verboseDate' | 'total'>
        & { payments: Array<(
          { __typename?: 'AggregatedPayment' }
          & Pick<AggregatedPayment, 'product' | 'amount'>
        )> }
      )>, goalAllocations: Array<(
        { __typename?: 'GoalAllocation' }
        & Pick<GoalAllocation, 'goal' | 'allocation'>
      )>, goalSummary: Array<(
        { __typename?: 'GoalSummary' }
        & Pick<GoalSummary, 'goal' | 'percentageGoalAchieved' | 'goalBalance'>
      )>, customerDetails: Array<(
        { __typename?: 'CustomerDetail' }
        & Pick<CustomerDetail, 'fullName' | 'postalAddress' | 'homeAddress' | 'city' | 'pslCode'>
      )> }
    )> }
  ) }
);

export type GoalsQueryVariables = {};


export type GoalsQuery = (
  { __typename?: 'query_root' }
  & { goal: Array<(
    { __typename?: 'goal' }
    & Pick<Goal, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    & { goal_products: Array<(
      { __typename?: 'goal_product' }
      & Pick<Goal_Product, 'id'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type Add_GoalMutationVariables = {
  goal_changes: Array<Goal_Insert_Input>;
};


export type Add_GoalMutation = (
  { __typename?: 'mutation_root' }
  & { insert_goal?: Maybe<(
    { __typename?: 'goal_mutation_response' }
    & { returning: Array<(
      { __typename?: 'goal' }
      & Pick<Goal, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
      & { goal_products: Array<(
        { __typename?: 'goal_product' }
        & Pick<Goal_Product, 'id'>
        & { product: (
          { __typename?: 'product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type Update_GoalMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  products: Array<Goal_Product_Insert_Input>;
  changes?: Maybe<Goal_Set_Input>;
};


export type Update_GoalMutation = (
  { __typename?: 'mutation_root' }
  & { delete_goal_product?: Maybe<(
    { __typename?: 'goal_product_mutation_response' }
    & Pick<Goal_Product_Mutation_Response, 'affected_rows'>
  )>, insert_goal_product?: Maybe<(
    { __typename?: 'goal_product_mutation_response' }
    & Pick<Goal_Product_Mutation_Response, 'affected_rows'>
  )>, update_goal?: Maybe<(
    { __typename?: 'goal_mutation_response' }
    & Pick<Goal_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'goal' }
      & Pick<Goal, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
      & { goal_products: Array<(
        { __typename?: 'goal_product' }
        & Pick<Goal_Product, 'id'>
        & { product: (
          { __typename?: 'product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type Delete_GoalMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_GoalMutation = (
  { __typename?: 'mutation_root' }
  & { delete_goal_product?: Maybe<(
    { __typename?: 'goal_product_mutation_response' }
    & Pick<Goal_Product_Mutation_Response, 'affected_rows'>
  )>, update_goal?: Maybe<(
    { __typename?: 'goal_mutation_response' }
    & Pick<Goal_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'goal' }
      & Pick<Goal, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
      & { goal_products: Array<(
        { __typename?: 'goal_product' }
        & Pick<Goal_Product, 'id'>
        & { product: (
          { __typename?: 'product' }
          & Pick<Product, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type CalcFutureValueGivenPpiMutationVariables = {
  goalType: Scalars['String'];
  currentValue: Scalars['Float'];
  duration: Scalars['Float'];
  yearsToFund: Scalars['Float'];
};


export type CalcFutureValueGivenPpiMutation = (
  { __typename?: 'mutation_root' }
  & { goalCalculations: (
    { __typename?: 'GoalCalculationResponse' }
    & Pick<GoalCalculationResponse, 'value'>
  ) }
);

export type CalcFutureValueGivenMcdMutationVariables = {
  monthlyContribution: Scalars['Float'];
  duration: Scalars['Float'];
  expectedReturn: Scalars['Float'];
};


export type CalcFutureValueGivenMcdMutation = (
  { __typename?: 'mutation_root' }
  & { goalCalculations: (
    { __typename?: 'GoalCalculationResponse' }
    & Pick<GoalCalculationResponse, 'value'>
  ) }
);

export type CalcMonthlyContributionMutationVariables = {
  futureValue: Scalars['Float'];
  duration: Scalars['Float'];
  expectedReturn: Scalars['Float'];
};


export type CalcMonthlyContributionMutation = (
  { __typename?: 'mutation_root' }
  & { goalCalculations: (
    { __typename?: 'GoalCalculationResponse' }
    & Pick<GoalCalculationResponse, 'value'>
  ) }
);

export type CalcDurationMutationVariables = {
  futureValue: Scalars['Float'];
  monthlyContribution: Scalars['Float'];
  expectedReturn: Scalars['Float'];
};


export type CalcDurationMutation = (
  { __typename?: 'mutation_root' }
  & { goalCalculations: (
    { __typename?: 'GoalCalculationResponse' }
    & Pick<GoalCalculationResponse, 'value'>
  ) }
);

export type IdentificationTypeQueryVariables = {};


export type IdentificationTypeQuery = (
  { __typename?: 'query_root' }
  & { identification_type: Array<(
    { __typename?: 'identification_type' }
    & Pick<Identification_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Identification_TypeMutationVariables = {
  changes: Array<Identification_Type_Insert_Input>;
};


export type Add_Identification_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_identification_type?: Maybe<(
    { __typename?: 'identification_type_mutation_response' }
    & { returning: Array<(
      { __typename?: 'identification_type' }
      & Pick<Identification_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Identification_TypeMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Identification_Type_Set_Input>;
};


export type Update_Identification_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_identification_type?: Maybe<(
    { __typename?: 'identification_type_mutation_response' }
    & Pick<Identification_Type_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'identification_type' }
      & Pick<Identification_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Identification_TypeMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Identification_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_identification_type?: Maybe<(
    { __typename?: 'identification_type_mutation_response' }
    & Pick<Identification_Type_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'identification_type' }
      & Pick<Identification_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type IncomeSourceQueryVariables = {};


export type IncomeSourceQuery = (
  { __typename?: 'query_root' }
  & { income_source: Array<(
    { __typename?: 'income_source' }
    & Pick<Income_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Income_SourceMutationVariables = {
  changes: Array<Income_Source_Insert_Input>;
};


export type Add_Income_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_income_source?: Maybe<(
    { __typename?: 'income_source_mutation_response' }
    & { returning: Array<(
      { __typename?: 'income_source' }
      & Pick<Income_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Income_SourceMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Income_Source_Set_Input>;
};


export type Update_Income_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_income_source?: Maybe<(
    { __typename?: 'income_source_mutation_response' }
    & Pick<Income_Source_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'income_source' }
      & Pick<Income_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Income_SourceMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Income_SourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_income_source?: Maybe<(
    { __typename?: 'income_source_mutation_response' }
    & Pick<Income_Source_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'income_source' }
      & Pick<Income_Source, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type IndustriesQueryVariables = {};


export type IndustriesQuery = (
  { __typename?: 'query_root' }
  & { industry: Array<(
    { __typename?: 'industry' }
    & Pick<Industry, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_IndustryMutationVariables = {
  changes: Array<Industry_Insert_Input>;
};


export type Add_IndustryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_industry?: Maybe<(
    { __typename?: 'industry_mutation_response' }
    & { returning: Array<(
      { __typename?: 'industry' }
      & Pick<Industry, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_IndustryMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Industry_Set_Input>;
};


export type Update_IndustryMutation = (
  { __typename?: 'mutation_root' }
  & { update_industry?: Maybe<(
    { __typename?: 'industry_mutation_response' }
    & Pick<Industry_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'industry' }
      & Pick<Industry, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_IndustryMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_IndustryMutation = (
  { __typename?: 'mutation_root' }
  & { update_industry?: Maybe<(
    { __typename?: 'industry_mutation_response' }
    & Pick<Industry_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'industry' }
      & Pick<Industry, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type GetInsurancePremiumMutationVariables = {
  sumAssured: Scalars['Float'];
  age: Scalars['Int'];
  duration: Scalars['Float'];
  insuranceTypeId: Scalars['Int'];
};


export type GetInsurancePremiumMutation = (
  { __typename?: 'mutation_root' }
  & { calculateInsurancePremium: (
    { __typename?: 'InsurancePremiumResponse' }
    & Pick<InsurancePremiumResponse, 'rate' | 'monthlyPremium'>
  ) }
);

export type Insurance_TypeQueryVariables = {};


export type Insurance_TypeQuery = (
  { __typename?: 'query_root' }
  & { insurance_type: Array<(
    { __typename?: 'insurance_type' }
    & Pick<Insurance_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Insurance_TypeMutationVariables = {
  changes: Array<Insurance_Type_Insert_Input>;
};


export type Add_Insurance_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_insurance_type?: Maybe<(
    { __typename?: 'insurance_type_mutation_response' }
    & { returning: Array<(
      { __typename?: 'insurance_type' }
      & Pick<Insurance_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Insurance_TypeMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Insurance_Type_Set_Input>;
};


export type Update_Insurance_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_insurance_type?: Maybe<(
    { __typename?: 'insurance_type_mutation_response' }
    & Pick<Insurance_Type_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'insurance_type' }
      & Pick<Insurance_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Insurance_TypeMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Insurance_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_insurance_type?: Maybe<(
    { __typename?: 'insurance_type_mutation_response' }
    & Pick<Insurance_Type_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'insurance_type' }
      & Pick<Insurance_Type, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type LoginMutationVariables = {
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
};


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'id' | 'accessToken' | 'shouldUpdatePassword'>
  ) }
);

export type LogoutMutationVariables = {};


export type LogoutMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'logout'>
);

export type MaritalStatusQueryVariables = {};


export type MaritalStatusQuery = (
  { __typename?: 'query_root' }
  & { marital_status: Array<(
    { __typename?: 'marital_status' }
    & Pick<Marital_Status, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_Marital_StatusMutationVariables = {
  changes: Array<Marital_Status_Insert_Input>;
};


export type Add_Marital_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { insert_marital_status?: Maybe<(
    { __typename?: 'marital_status_mutation_response' }
    & { returning: Array<(
      { __typename?: 'marital_status' }
      & Pick<Marital_Status, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_Marital_StatusMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Marital_Status_Set_Input>;
};


export type Update_Marital_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_marital_status?: Maybe<(
    { __typename?: 'marital_status_mutation_response' }
    & Pick<Marital_Status_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'marital_status' }
      & Pick<Marital_Status, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_Marital_StatusMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Marital_StatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_marital_status?: Maybe<(
    { __typename?: 'marital_status_mutation_response' }
    & Pick<Marital_Status_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'marital_status' }
      & Pick<Marital_Status, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type MeQueryVariables = {};


export type MeQuery = (
  { __typename?: 'query_root' }
  & { me: (
    { __typename?: 'MeResponse' }
    & { user_type?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'email' | 'fullname' | 'id' | 'guid'>
    )> }
  ) }
);

export type Update_Member_SignatureMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  signature?: Maybe<Scalars['String']>;
};


export type Update_Member_SignatureMutation = (
  { __typename: 'mutation_root' }
  & { update_member?: Maybe<(
    { __typename?: 'member_mutation_response' }
    & Pick<Member_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member' }
      & Pick<Member, 'id'>
    )> }
  )> }
);

export type Convert_Prospect_CustomerMutationVariables = {
  id: Scalars['Int'];
  isDirect?: Maybe<Scalars['Boolean']>;
};


export type Convert_Prospect_CustomerMutation = (
  { __typename?: 'mutation_root' }
  & { createCustomer: (
    { __typename?: 'CreateCustomerResponse' }
    & Pick<CreateCustomerResponse, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'message'>
    )>> }
  ) }
);

export type Send_Welcome_PackMutationVariables = {
  id: Scalars['Int'];
};


export type Send_Welcome_PackMutation = (
  { __typename?: 'mutation_root' }
  & { sendWelcomePack: (
    { __typename?: 'SendWelcomePackResponse' }
    & Pick<SendWelcomePackResponse, 'success'>
  ) }
);

export type Reject_ProspectMutationVariables = {
  id: Scalars['bigint'];
  reason: Scalars['String'];
  doneBy: Scalars['bigint'];
};


export type Reject_ProspectMutation = (
  { __typename: 'mutation_root' }
  & { insert_member_reject?: Maybe<(
    { __typename?: 'member_reject_mutation_response' }
    & Pick<Member_Reject_Mutation_Response, 'affected_rows'>
  )>, update_member?: Maybe<(
    { __typename?: 'member_mutation_response' }
    & { returning: Array<(
      { __typename?: 'member' }
      & Pick<Member, 'id' | 'psl_code' | 'nationality' | 'tin'>
      & { status?: Maybe<(
        { __typename?: 'status' }
        & Pick<Status, 'id' | 'name'>
      )>, assigned_to_user?: Maybe<(
        { __typename?: 'user' }
        & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      )>, user: (
        { __typename?: 'user' }
        & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
        & { user_advisor?: Maybe<(
          { __typename?: 'advisor' }
          & Pick<Advisor, 'advisor_code'>
        )>, gender: (
          { __typename?: 'gender' }
          & Pick<Gender, 'id' | 'name'>
        ) }
      ) }
    )> }
  )> }
);

export type Update_User_DetailMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  member_changes?: Maybe<Member_Set_Input>;
  user_changes?: Maybe<User_Set_Input>;
  member_next_of_kin_id?: Maybe<Scalars['bigint']>;
  member_next_of_kin_changes?: Maybe<Next_Of_Kin_Set_Input>;
  member_client_investment_profile_id?: Maybe<Scalars['bigint']>;
  member_client_investment_profile_changes?: Maybe<Client_Investment_Profile_Set_Input>;
  member_identification_changes?: Maybe<Member_Identification_Set_Input>;
  member_goals_changes: Array<Member_Goal_Insert_Input>;
  member_beneficiary_changes: Array<Member_Beneficiary_Insert_Input>;
  member_payment_instruction_changes: Array<Member_Payment_Instruction_Insert_Input>;
  member_mode_of_instruction_changes: Array<Member_Mode_Of_Instruction_Communication_Insert_Input>;
  member_income_source_change: Array<Member_Income_Source_Insert_Input>;
  member_contribution_source_change: Array<Member_Contribution_Source_Insert_Input>;
  member_dependent_changes: Array<Member_Dependent_Insert_Input>;
};


export type Update_User_DetailMutation = (
  { __typename: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
  )>, update_next_of_kin?: Maybe<(
    { __typename?: 'next_of_kin_mutation_response' }
    & Pick<Next_Of_Kin_Mutation_Response, 'affected_rows'>
  )>, update_client_investment_profile?: Maybe<(
    { __typename?: 'client_investment_profile_mutation_response' }
    & Pick<Client_Investment_Profile_Mutation_Response, 'affected_rows'>
  )>, update_member_identification?: Maybe<(
    { __typename?: 'member_identification_mutation_response' }
    & Pick<Member_Identification_Mutation_Response, 'affected_rows'>
  )>, insert_member_beneficiary?: Maybe<(
    { __typename?: 'member_beneficiary_mutation_response' }
    & Pick<Member_Beneficiary_Mutation_Response, 'affected_rows'>
  )>, insert_member_dependent?: Maybe<(
    { __typename?: 'member_dependent_mutation_response' }
    & Pick<Member_Dependent_Mutation_Response, 'affected_rows'>
  )>, insert_member_goal?: Maybe<(
    { __typename?: 'member_goal_mutation_response' }
    & Pick<Member_Goal_Mutation_Response, 'affected_rows'>
  )>, insert_member_payment_instruction?: Maybe<(
    { __typename?: 'member_payment_instruction_mutation_response' }
    & Pick<Member_Payment_Instruction_Mutation_Response, 'affected_rows'>
  )>, insert_member_mode_of_instruction_communication?: Maybe<(
    { __typename?: 'member_mode_of_instruction_communication_mutation_response' }
    & Pick<Member_Mode_Of_Instruction_Communication_Mutation_Response, 'affected_rows'>
  )>, delete_member_income_source?: Maybe<(
    { __typename?: 'member_income_source_mutation_response' }
    & Pick<Member_Income_Source_Mutation_Response, 'affected_rows'>
  )>, delete_member_contribution_source?: Maybe<(
    { __typename?: 'member_contribution_source_mutation_response' }
    & Pick<Member_Contribution_Source_Mutation_Response, 'affected_rows'>
  )>, insert_member_income_source?: Maybe<(
    { __typename?: 'member_income_source_mutation_response' }
    & Pick<Member_Income_Source_Mutation_Response, 'affected_rows'>
  )>, insert_member_contribution_source?: Maybe<(
    { __typename?: 'member_contribution_source_mutation_response' }
    & Pick<Member_Contribution_Source_Mutation_Response, 'affected_rows'>
  )>, update_member?: Maybe<(
    { __typename?: 'member_mutation_response' }
    & Pick<Member_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member' }
      & Pick<Member, 'id'>
    )> }
  )> }
);

export type Delete_BeneficiaryMutationVariables = {
  guid?: Maybe<Scalars['uuid']>;
};


export type Delete_BeneficiaryMutation = (
  { __typename?: 'mutation_root' }
  & { update_member_beneficiary?: Maybe<(
    { __typename?: 'member_beneficiary_mutation_response' }
    & Pick<Member_Beneficiary_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member_beneficiary' }
      & Pick<Member_Beneficiary, 'guid'>
    )> }
  )> }
);

export type Delete_DependentMutationVariables = {
  guid?: Maybe<Scalars['uuid']>;
};


export type Delete_DependentMutation = (
  { __typename?: 'mutation_root' }
  & { update_member_dependent?: Maybe<(
    { __typename?: 'member_dependent_mutation_response' }
    & Pick<Member_Dependent_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member_dependent' }
      & Pick<Member_Dependent, 'guid'>
    )> }
  )> }
);

export type Delete_PaymentMutationVariables = {
  guid?: Maybe<Scalars['uuid']>;
};


export type Delete_PaymentMutation = (
  { __typename?: 'mutation_root' }
  & { update_member_payment_instruction?: Maybe<(
    { __typename?: 'member_payment_instruction_mutation_response' }
    & Pick<Member_Payment_Instruction_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member_payment_instruction' }
      & Pick<Member_Payment_Instruction, 'guid'>
    )> }
  )> }
);

export type MemberSearchQueryVariables = {
  searchKey: Scalars['String'];
};


export type MemberSearchQuery = (
  { __typename?: 'query_root' }
  & { member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'is_customer' | 'psl_code' | 'nationality' | 'tin'>
    & { status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      & { user_advisor?: Maybe<(
        { __typename?: 'advisor' }
        & Pick<Advisor, 'advisor_code'>
      )>, gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type InsertMembersMutationVariables = {
  data: Array<Member_Insert_Input>;
};


export type InsertMembersMutation = (
  { __typename?: 'mutation_root' }
  & { insert_member?: Maybe<(
    { __typename?: 'member_mutation_response' }
    & Pick<Member_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member' }
      & Pick<Member, 'id' | 'account_type' | 'is_customer' | 'age' | 'title_id' | 'marital_status_id' | 'marital_status_id_jt' | 'place_of_birth' | 'place_of_birth_jt' | 'industry_id' | 'status_id' | 'city' | 'nationality' | 'mf_code' | 'mobile_secondary' | 'occupation' | 'occupation_jt' | 'postal_address' | 'psl_code' | 'signature' | 'tin' | 'tin_jt' | 'home_address' | 'home_address_jt' | 'employer' | 'employer_jt' | 'monthly_income' | 'current_savings' | 'permit_expiry_date' | 'permit_expiry_date_jt' | 'permit_issue_date' | 'permit_issue_date_jt' | 'regular_top_up_amount' | 'initial_investment_amount' | 'regular_withdrawal_amount' | 'residential_permit_number' | 'residential_permit_number_jt' | 'residential_place_of_issue' | 'residential_place_of_issue_jt' | 'country_of_origin_id' | 'country_of_origin_id_jt' | 'residential_status' | 'residential_status_jt' | 'anticipated_investment_activity_top_ups' | 'anticipated_investment_activity_top_ups_other' | 'anticipated_investment_activity_withdrawals' | 'anticipated_investment_activity_withdrawals_other' | 'employer_address' | 'employer_address_jt' | 'employer_city' | 'employer_city_jt' | 'employer_contact' | 'employer_contact_jt' | 'employer_email' | 'employer_email_jt' | 'employment_status' | 'employment_status_jt' | 'nature_of_business' | 'nature_of_business_jt' | 'years_of_current_employment' | 'years_of_current_employment_jt' | 'years_of_employment' | 'years_of_employment_jt' | 'years_of_previous_employment' | 'years_of_previous_employment_jt' | 'professional_license_number' | 'professional_license_number_jt' | 'mode_of_statement_delivery' | 'statement_frequency' | 'account_mandate' | 'profession' | 'profession_jt' | 'gps_address' | 'gps_address_jt'>
      & { user: (
        { __typename?: 'user' }
        & Pick<User, 'id' | 'first_name' | 'first_name_jt' | 'other_name' | 'other_name_jt' | 'last_name' | 'last_name_jt' | 'mobile' | 'mobile_jt' | 'mobile_jt2' | 'email' | 'email_jt' | 'date_of_birth' | 'date_of_birth_jt' | 'gender_id' | 'gender_id_jt' | 'created_at'>
      ), client_investment_profile?: Maybe<(
        { __typename?: 'client_investment_profile' }
        & Pick<Client_Investment_Profile, 'investment_objective' | 'investment_knowledge' | 'risk_tolerance' | 'investment_horizon' | 'approximate_annual_income'>
      )>, beneficiaries: Array<(
        { __typename?: 'member_beneficiary' }
        & Pick<Member_Beneficiary, 'id' | 'guid' | 'first_name' | 'home_address' | 'last_name' | 'deleted' | 'gender_id' | 'email' | 'date_of_birth' | 'mobile' | 'other_names' | 'relationship_id' | 'allocation' | 'residential_status' | 'residential_place_of_issue' | 'residential_permit_number' | 'country_of_origin_id' | 'permit_expiry_date' | 'permit_issue_date' | 'occupation' | 'profession' | 'mobile2' | 'gps_address' | 'title' | 'place_of_birth' | 'marital_status' | 'tin' | 'id_type' | 'id_number' | 'id_expiry_date' | 'id_issue_date' | 'professional_license'>
        & { region?: Maybe<(
          { __typename?: 'region' }
          & Pick<Region, 'id' | 'name'>
        )> }
      )>, next_of_kin?: Maybe<(
        { __typename?: 'next_of_kin' }
        & Pick<Next_Of_Kin, 'id' | 'guid' | 'first_name' | 'last_name' | 'email' | 'date_of_birth' | 'deleted' | 'mobile' | 'other_names' | 'home_address' | 'gender_id' | 'relationship_id' | 'residential_status' | 'residential_place_of_issue' | 'residential_permit_number' | 'country_of_origin_id' | 'permit_expiry_date' | 'permit_issue_date' | 'occupation' | 'profession' | 'mobile2' | 'gps_address' | 'title' | 'place_of_birth' | 'marital_status' | 'tin' | 'id_type' | 'id_number' | 'id_expiry_date' | 'id_issue_date' | 'professional_license'>
        & { region?: Maybe<(
          { __typename?: 'region' }
          & Pick<Region, 'id' | 'name'>
        )> }
      )>, member_dependents: Array<(
        { __typename?: 'member_dependent' }
        & Pick<Member_Dependent, 'id' | 'guid' | 'name' | 'age' | 'deleted'>
      )>, member_identifications: Array<(
        { __typename?: 'member_identification' }
        & Pick<Member_Identification, 'id' | 'guid' | 'identification_type_id' | 'issue_country_id' | 'identification_card_image_url' | 'identification_number' | 'identification_expiry_date' | 'identification_issue_date' | 'place_of_issue' | 'deleted'>
      )>, member_income_sources: Array<(
        { __typename?: 'member_income_source' }
        & Pick<Member_Income_Source, 'id' | 'guid' | 'income_source_id'>
      )>, member_contribution_sources: Array<(
        { __typename?: 'member_contribution_source' }
        & Pick<Member_Contribution_Source, 'id' | 'guid' | 'contribution_source_id'>
      )>, member_goals: Array<(
        { __typename?: 'member_goal' }
        & Pick<Member_Goal, 'id' | 'guid' | 'goal_id' | 'goal_for' | 'accepted' | 'accepted_on' | 'current_value' | 'future_value' | 'duration_years' | 'duration_months' | 'monthly_contribution' | 'administrative_fees' | 'expected_return' | 'has_insurance' | 'deleted' | 'created_at'>
        & { goal_insurances: Array<(
          { __typename?: 'goal_insurance' }
          & Pick<Goal_Insurance, 'id' | 'insurance_premium' | 'insurance_rate_per_1000' | 'insurance_type_id'>
        )> }
      )>, member_payment_instructions: Array<(
        { __typename?: 'member_payment_instruction' }
        & Pick<Member_Payment_Instruction, 'id' | 'guid' | 'payment_provider_id' | 'account_number' | 'account_name' | 'branch_id' | 'mobile_money_number' | 'preferred' | 'deleted'>
      )>, member_mode_of_instruction_communications: Array<(
        { __typename?: 'member_mode_of_instruction_communication' }
        & Pick<Member_Mode_Of_Instruction_Communication, 'id' | 'guid' | 'mode_of_instruction_communication_id'>
      )>, region?: Maybe<(
        { __typename?: 'region' }
        & Pick<Region, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ModeofInstructionCommunicationQueryVariables = {};


export type ModeofInstructionCommunicationQuery = (
  { __typename?: 'query_root' }
  & { mode_of_instruction_communication: Array<(
    { __typename?: 'mode_of_instruction_communication' }
    & Pick<Mode_Of_Instruction_Communication, 'id' | 'name'>
  )> }
);

export type OccupationQueryVariables = {};


export type OccupationQuery = (
  { __typename?: 'query_root' }
  & { gender: Array<(
    { __typename?: 'gender' }
    & Pick<Gender, 'id' | 'name'>
  )> }
);

export type PasswordResetMutationVariables = {
  usernameOrEmail: Scalars['String'];
  newPassword: Scalars['String'];
};


export type PasswordResetMutation = (
  { __typename?: 'mutation_root' }
  & { resetPassword: (
    { __typename?: 'PasswordResetResponse' }
    & Pick<PasswordResetResponse, 'id' | 'accessToken'>
  ) }
);

export type PaymentsSubscriptionVariables = {};


export type PaymentsSubscription = (
  { __typename?: 'subscription_root' }
  & { payment: Array<(
    { __typename?: 'payment' }
    & Pick<Payment, 'id' | 'amount' | 'payment_date' | 'value_date'>
    & { member?: Maybe<(
      { __typename?: 'member' }
      & Pick<Member, 'mf_code' | 'psl_code'>
    )>, status: (
      { __typename?: 'status' }
      & Pick<Status, 'name'>
    ) }
  )> }
);

export type GetStatusQueryVariables = {};


export type GetStatusQuery = (
  { __typename?: 'query_root' }
  & { status: Array<(
    { __typename?: 'status' }
    & Pick<Status, 'id' | 'name'>
  )> }
);

export type GetPaymentsPendingApprovalOrConfirmQueryVariables = {
  statusId?: Maybe<Scalars['bigint']>;
};


export type GetPaymentsPendingApprovalOrConfirmQuery = (
  { __typename?: 'query_root' }
  & { payment: Array<(
    { __typename?: 'payment' }
    & Pick<Payment, 'id' | 'payment_date' | 'value_date' | 'amount'>
    & { member?: Maybe<(
      { __typename?: 'member' }
      & Pick<Member, 'mf_code'>
    )>, status: (
      { __typename?: 'status' }
      & Pick<Status, 'name'>
    ), payment_shares: Array<(
      { __typename?: 'payment_share' }
      & Pick<Payment_Share, 'member_goal_id' | 'goal_amount' | 'product_amount'>
      & { member_goal: (
        { __typename?: 'member_goal' }
        & { goal: (
          { __typename?: 'goal' }
          & Pick<Goal, 'id' | 'name'>
        ) }
      ), product: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
      ) }
    )>, payment_mode?: Maybe<(
      { __typename?: 'payment_mode' }
      & Pick<Payment_Mode, 'id' | 'name'>
    )> }
  )> }
);

export type GetPaymentsByValueDateQueryVariables = {
  valueDate: Scalars['date'];
  statusId: Scalars['bigint'];
};


export type GetPaymentsByValueDateQuery = (
  { __typename?: 'query_root' }
  & { payment: Array<(
    { __typename?: 'payment' }
    & Pick<Payment, 'id' | 'batch_id' | 'payment_date' | 'value_date' | 'amount'>
    & { member?: Maybe<(
      { __typename?: 'member' }
      & Pick<Member, 'mf_code' | 'psl_code'>
      & { user: (
        { __typename?: 'user' }
        & Pick<User, 'first_name' | 'other_name' | 'last_name'>
      ) }
    )>, status: (
      { __typename?: 'status' }
      & Pick<Status, 'name'>
    ), payment_shares: Array<(
      { __typename?: 'payment_share' }
      & Pick<Payment_Share, 'member_goal_id' | 'goal_amount' | 'product_amount'>
      & { member_goal: (
        { __typename?: 'member_goal' }
        & { goal: (
          { __typename?: 'goal' }
          & Pick<Goal, 'id' | 'name'>
        ) }
      ), product: (
        { __typename?: 'product' }
        & Pick<Product, 'name'>
      ) }
    )>, payment_mode?: Maybe<(
      { __typename?: 'payment_mode' }
      & Pick<Payment_Mode, 'id' | 'name'>
    )> }
  )> }
);

export type ConfirmPaymentsMutationVariables = {
  valueDate: Scalars['date'];
  statusId: Scalars['bigint'];
};


export type ConfirmPaymentsMutation = (
  { __typename?: 'mutation_root' }
  & { update_payment?: Maybe<(
    { __typename?: 'payment_mutation_response' }
    & Pick<Payment_Mutation_Response, 'affected_rows'>
  )> }
);

export type LoadPaymentsMutationVariables = {
  data: Array<LoadPaymentsInput>;
};


export type LoadPaymentsMutation = (
  { __typename?: 'mutation_root' }
  & { loadPayments: (
    { __typename?: 'LoadPaymentsResponse' }
    & Pick<LoadPaymentsResponse, 'success'>
  ) }
);

export type PaymentModesQueryVariables = {};


export type PaymentModesQuery = (
  { __typename?: 'query_root' }
  & { payment_mode: Array<(
    { __typename?: 'payment_mode' }
    & Pick<Payment_Mode, 'id' | 'name'>
  )> }
);

export type DirectDebitChargeQueryVariables = {
  date: Scalars['date'];
};


export type DirectDebitChargeQuery = (
  { __typename?: 'query_root' }
  & { direct_debit_charge: Array<(
    { __typename?: 'direct_debit_charge' }
    & Pick<Direct_Debit_Charge, 'amount'>
  )> }
);

export type PaymentChannelQueryVariables = {};


export type PaymentChannelQuery = (
  { __typename?: 'query_root' }
  & { payment_channel: Array<(
    { __typename?: 'payment_channel' }
    & Pick<Payment_Channel, 'id' | 'name'>
  )> }
);

export type PaymentProviderQueryVariables = {};


export type PaymentProviderQuery = (
  { __typename?: 'query_root' }
  & { payment_provider: Array<(
    { __typename?: 'payment_provider' }
    & Pick<Payment_Provider, 'id' | 'name' | 'code' | 'created_at' | 'updated_at' | 'deleted'>
    & { payment_channel: (
      { __typename?: 'payment_channel' }
      & Pick<Payment_Channel, 'id' | 'name'>
    ) }
  )> }
);

export type Add_Payment_ProviderMutationVariables = {
  changes: Array<Payment_Provider_Insert_Input>;
};


export type Add_Payment_ProviderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_payment_provider?: Maybe<(
    { __typename?: 'payment_provider_mutation_response' }
    & { returning: Array<(
      { __typename?: 'payment_provider' }
      & Pick<Payment_Provider, 'id' | 'name' | 'code' | 'created_at' | 'updated_at' | 'deleted'>
      & { payment_channel: (
        { __typename?: 'payment_channel' }
        & Pick<Payment_Channel, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type Update_Payment_ProviderMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Payment_Provider_Set_Input>;
};


export type Update_Payment_ProviderMutation = (
  { __typename?: 'mutation_root' }
  & { update_payment_provider?: Maybe<(
    { __typename?: 'payment_provider_mutation_response' }
    & Pick<Payment_Provider_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'payment_provider' }
      & Pick<Payment_Provider, 'id' | 'name' | 'code' | 'created_at' | 'updated_at' | 'deleted'>
      & { payment_channel: (
        { __typename?: 'payment_channel' }
        & Pick<Payment_Channel, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type Delete_Payment_ProviderMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_Payment_ProviderMutation = (
  { __typename?: 'mutation_root' }
  & { update_payment_provider?: Maybe<(
    { __typename?: 'payment_provider_mutation_response' }
    & Pick<Payment_Provider_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'payment_provider' }
      & Pick<Payment_Provider, 'id' | 'name' | 'code' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type ProductQueryVariables = {};


export type ProductQuery = (
  { __typename?: 'query_root' }
  & { product: Array<(
    { __typename?: 'product' }
    & Pick<Product, 'id' | 'name' | 'fund_code' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_ProductMutationVariables = {
  product_changes: Array<Product_Insert_Input>;
};


export type Add_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & { returning: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'fund_code' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_ProductMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Product_Set_Input>;
};


export type Update_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'fund_code' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_ProductMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_ProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_product?: Maybe<(
    { __typename?: 'product_mutation_response' }
    & Pick<Product_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'fund_code' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type ProductUnitPriceQueryVariables = {};


export type ProductUnitPriceQuery = (
  { __typename?: 'query_root' }
  & { price: Array<(
    { __typename?: 'price' }
    & Pick<Price, 'id' | 'unit_price' | 'price_date' | 'created_at' | 'updated_at'>
    & { product: (
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name'>
    ) }
  )> }
);

export type Add_Product_Unit_PriceMutationVariables = {
  price_changes: Array<Price_Insert_Input>;
};


export type Add_Product_Unit_PriceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_price?: Maybe<(
    { __typename?: 'price_mutation_response' }
    & { returning: Array<(
      { __typename?: 'price' }
      & Pick<Price, 'id' | 'unit_price' | 'price_date' | 'created_at' | 'updated_at'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type Update_Product_Unit_PriceMutationVariables = {
  id: Scalars['bigint'];
  changes: Price_Set_Input;
};


export type Update_Product_Unit_PriceMutation = (
  { __typename?: 'mutation_root' }
  & { update_price?: Maybe<(
    { __typename?: 'price_mutation_response' }
    & Pick<Price_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'price' }
      & Pick<Price, 'id' | 'unit_price' | 'price_date' | 'created_at' | 'updated_at'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type Delete_Product_Unit_PriceMutationVariables = {
  id: Scalars['bigint'];
};


export type Delete_Product_Unit_PriceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_price?: Maybe<(
    { __typename?: 'price_mutation_response' }
    & Pick<Price_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'price' }
      & Pick<Price, 'id' | 'unit_price' | 'price_date' | 'created_at' | 'updated_at'>
      & { product: (
        { __typename?: 'product' }
        & Pick<Product, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type ProspectQueryVariables = {};


export type ProspectQuery = (
  { __typename?: 'query_root' }
  & { member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'psl_code' | 'nationality' | 'tin'>
    & { status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      & { user_advisor?: Maybe<(
        { __typename?: 'advisor' }
        & Pick<Advisor, 'advisor_code'>
      )>, gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type ProspectDetailQueryVariables = {
  id?: Maybe<Scalars['bigint']>;
};


export type ProspectDetailQuery = (
  { __typename?: 'query_root' }
  & { member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'welcome_pack_sent_at' | 'age' | 'marital_status_id' | 'marital_status_id_jt' | 'place_of_birth' | 'place_of_birth_jt' | 'signature' | 'city' | 'title_id_jt' | 'psl_code' | 'mf_code' | 'nationality' | 'tin' | 'tin_jt' | 'occupation' | 'occupation_jt' | 'profession' | 'profession_jt' | 'employer' | 'employer_jt' | 'mobile_secondary' | 'home_address' | 'home_address_jt' | 'postal_address' | 'permit_expiry_date' | 'permit_expiry_date_jt' | 'permit_issue_date' | 'permit_issue_date_jt' | 'regular_top_up_amount' | 'initial_investment_amount' | 'regular_withdrawal_amount' | 'residential_permit_number' | 'residential_permit_number_jt' | 'anticipated_investment_activity_top_ups' | 'anticipated_investment_activity_top_ups_other' | 'anticipated_investment_activity_withdrawals' | 'anticipated_investment_activity_withdrawals_other' | 'residential_place_of_issue' | 'residential_place_of_issue_jt' | 'country_of_origin_id' | 'country_of_origin_id_jt' | 'country_of_residence_id' | 'country_of_residence_id_jt' | 'employer_address' | 'employer_address_jt' | 'employer_city' | 'employer_city_jt' | 'employer_contact' | 'employer_contact_jt' | 'employer_email' | 'employer_email_jt' | 'employment_status' | 'employment_status_jt' | 'nature_of_business' | 'nature_of_business_jt' | 'years_of_current_employment' | 'years_of_current_employment_jt' | 'years_of_employment' | 'years_of_employment_jt' | 'years_of_previous_employment' | 'years_of_previous_employment_jt' | 'professional_license_number' | 'professional_license_number_jt' | 'place_of_issue_jt' | 'mode_of_statement_delivery' | 'statement_frequency' | 'account_mandate' | 'account_type' | 'gps_address' | 'gps_address_jt' | 'relationship_jt' | 'id_type_jt' | 'id_number_jt' | 'id_issue_date_jt' | 'id_expiry_date_jt' | 'region_id' | 'residential_status' | 'residential_status_jt'>
    & { user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'first_name_jt' | 'last_name' | 'last_name_jt' | 'other_name' | 'other_name_jt' | 'mobile' | 'mobile_jt' | 'mobile_jt2' | 'email' | 'email_jt' | 'date_of_birth' | 'date_of_birth_jt' | 'gender_id' | 'gender_id_jt'>
      & { gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ), gender_jt?: Maybe<(
        { __typename?: 'gender' }
        & Pick<Gender, 'name'>
      )> }
    ), marital_status?: Maybe<(
      { __typename?: 'marital_status' }
      & Pick<Marital_Status, 'id' | 'name'>
    )>, marital_status_jt?: Maybe<(
      { __typename?: 'marital_status' }
      & Pick<Marital_Status, 'id' | 'name'>
    )>, client_investment_profile?: Maybe<(
      { __typename?: 'client_investment_profile' }
      & Pick<Client_Investment_Profile, 'investment_objective' | 'investment_knowledge' | 'risk_tolerance' | 'investment_horizon' | 'approximate_annual_income'>
    )>, country_of_origin?: Maybe<(
      { __typename?: 'country' }
      & Pick<Country, 'id' | 'name'>
    )>, country_of_origin_jt?: Maybe<(
      { __typename?: 'country' }
      & Pick<Country, 'id' | 'name'>
    )>, country_of_residence?: Maybe<(
      { __typename?: 'country' }
      & Pick<Country, 'id' | 'name'>
    )>, country_of_residence_jt?: Maybe<(
      { __typename?: 'country' }
      & Pick<Country, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, industry?: Maybe<(
      { __typename?: 'industry' }
      & Pick<Industry, 'id' | 'name'>
    )>, region?: Maybe<(
      { __typename?: 'region' }
      & Pick<Region, 'id' | 'name'>
    )>, next_of_kin?: Maybe<(
      { __typename?: 'next_of_kin' }
      & Pick<Next_Of_Kin, 'id' | 'first_name' | 'last_name' | 'other_names' | 'relationship_id' | 'gender_id' | 'home_address' | 'date_of_birth' | 'mobile' | 'email' | 'residential_status' | 'residential_place_of_issue' | 'residential_permit_number' | 'country_of_origin_id' | 'region_id' | 'permit_expiry_date' | 'permit_issue_date' | 'occupation' | 'profession' | 'mobile2' | 'gps_address' | 'title' | 'place_of_birth' | 'marital_status' | 'tin' | 'id_type' | 'id_number' | 'id_expiry_date' | 'id_issue_date' | 'professional_license'>
      & { relationship?: Maybe<(
        { __typename?: 'relationship' }
        & Pick<Relationship, 'id' | 'name'>
      )>, gender?: Maybe<(
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      )>, country_of_origin?: Maybe<(
        { __typename?: 'country' }
        & Pick<Country, 'id' | 'name'>
      )>, region?: Maybe<(
        { __typename?: 'region' }
        & Pick<Region, 'id' | 'name'>
      )> }
    )>, member_income_sources: Array<(
      { __typename?: 'member_income_source' }
      & Pick<Member_Income_Source, 'income_source_id'>
      & { income_source: (
        { __typename?: 'income_source' }
        & Pick<Income_Source, 'id' | 'name'>
      ) }
    )>, member_contribution_sources: Array<(
      { __typename?: 'member_contribution_source' }
      & Pick<Member_Contribution_Source, 'contribution_source_id'>
      & { contribution_source: (
        { __typename?: 'contribution_source' }
        & Pick<Contribution_Source, 'id' | 'name'>
      ) }
    )>, beneficiaries: Array<(
      { __typename?: 'member_beneficiary' }
      & Pick<Member_Beneficiary, 'id' | 'guid' | 'first_name' | 'last_name' | 'mobile' | 'other_names' | 'home_address' | 'date_of_birth' | 'allocation' | 'relationship_id' | 'gender_id' | 'email' | 'residential_status' | 'residential_place_of_issue' | 'residential_permit_number' | 'country_of_origin_id' | 'region_id' | 'permit_expiry_date' | 'permit_issue_date' | 'occupation' | 'profession' | 'mobile2' | 'gps_address' | 'title' | 'place_of_birth' | 'marital_status' | 'tin' | 'id_type' | 'id_number' | 'id_expiry_date' | 'id_issue_date' | 'professional_license'>
      & { relationship: (
        { __typename?: 'relationship' }
        & Pick<Relationship, 'id' | 'name'>
      ), gender?: Maybe<(
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      )>, country_of_origin?: Maybe<(
        { __typename?: 'country' }
        & Pick<Country, 'id' | 'name'>
      )>, region?: Maybe<(
        { __typename?: 'region' }
        & Pick<Region, 'id' | 'name'>
      )> }
    )>, member_dependents: Array<(
      { __typename?: 'member_dependent' }
      & Pick<Member_Dependent, 'id' | 'guid' | 'name' | 'age'>
    )>, member_payment_instructions: Array<(
      { __typename?: 'member_payment_instruction' }
      & Pick<Member_Payment_Instruction, 'id' | 'guid' | 'account_name' | 'account_number' | 'branch_id' | 'preferred' | 'mobile_money_number'>
      & { payment_provider: (
        { __typename?: 'payment_provider' }
        & Pick<Payment_Provider, 'id' | 'name' | 'payment_channel_id'>
      ) }
    )>, member_mode_of_instruction_communications: Array<(
      { __typename?: 'member_mode_of_instruction_communication' }
      & Pick<Member_Mode_Of_Instruction_Communication, 'mode_of_instruction_communication_id'>
      & { mode_of_instruction_communication: (
        { __typename?: 'mode_of_instruction_communication' }
        & Pick<Mode_Of_Instruction_Communication, 'id' | 'name'>
      ) }
    )>, member_identifications: Array<(
      { __typename?: 'member_identification' }
      & Pick<Member_Identification, 'id' | 'identification_number' | 'identification_card_image_url' | 'identification_expiry_date' | 'identification_issue_date' | 'place_of_issue'>
      & { identification_type: (
        { __typename?: 'identification_type' }
        & Pick<Identification_Type, 'id' | 'name'>
      ), country?: Maybe<(
        { __typename?: 'country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )>, member_goals: Array<(
      { __typename?: 'member_goal' }
      & Pick<Member_Goal, 'id' | 'guid' | 'years_to_fund' | 'member_id' | 'goal_for' | 'accepted' | 'current_value' | 'future_value' | 'duration_years' | 'duration_months' | 'administrative_fees' | 'goal_id' | 'monthly_contribution' | 'expected_return' | 'has_insurance'>
      & { goal: (
        { __typename?: 'goal' }
        & Pick<Goal, 'id' | 'name'>
      ), goal_insurances: Array<(
        { __typename?: 'goal_insurance' }
        & Pick<Goal_Insurance, 'id' | 'insurance_rate_per_1000' | 'insurance_premium' | 'insurance_type_id'>
        & { insurance_type?: Maybe<(
          { __typename?: 'insurance_type' }
          & Pick<Insurance_Type, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type Update_Member_IdentificationMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  member_identification_changes?: Maybe<Member_Identification_Set_Input>;
};


export type Update_Member_IdentificationMutation = (
  { __typename: 'mutation_root' }
  & { update_member_identification?: Maybe<(
    { __typename?: 'member_identification_mutation_response' }
    & Pick<Member_Identification_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'member_identification' }
      & Pick<Member_Identification, 'id'>
    )> }
  )> }
);

export type PaginatedProspectsQueryVariables = {
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type PaginatedProspectsQuery = (
  { __typename?: 'query_root' }
  & { member_aggregate: (
    { __typename?: 'member_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'member_aggregate_fields' }
      & Pick<Member_Aggregate_Fields, 'count'>
    )> }
  ), member: Array<(
    { __typename?: 'member' }
    & Pick<Member, 'id' | 'account_type' | 'psl_code' | 'nationality' | 'tin'>
    & { status?: Maybe<(
      { __typename?: 'status' }
      & Pick<Status, 'id' | 'name'>
    )>, assigned_to_user?: Maybe<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'last_name' | 'other_name'>
      & { user_advisor?: Maybe<(
        { __typename?: 'advisor' }
        & Pick<Advisor, 'advisor_code'>
      )>, gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type RegionsQueryVariables = {};


export type RegionsQuery = (
  { __typename?: 'query_root' }
  & { region: Array<(
    { __typename?: 'region' }
    & Pick<Region, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Register_UserMutationVariables = {
  username: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  otherName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  email: Scalars['String'];
  mobile: Scalars['String'];
  genderId: Scalars['Float'];
  userRole: Scalars['String'];
};


export type Register_UserMutation = (
  { __typename?: 'mutation_root' }
  & { register: (
    { __typename?: 'RegisterResponse' }
    & Pick<RegisterResponse, 'success' | 'id'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'message' | 'path'>
    )>> }
  ) }
);

export type RelationshipsQueryVariables = {};


export type RelationshipsQuery = (
  { __typename?: 'query_root' }
  & { relationship: Array<(
    { __typename?: 'relationship' }
    & Pick<Relationship, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_RelationshipMutationVariables = {
  changes: Array<Relationship_Insert_Input>;
};


export type Add_RelationshipMutation = (
  { __typename?: 'mutation_root' }
  & { insert_relationship?: Maybe<(
    { __typename?: 'relationship_mutation_response' }
    & { returning: Array<(
      { __typename?: 'relationship' }
      & Pick<Relationship, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_RelationshipMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Relationship_Set_Input>;
};


export type Update_RelationshipMutation = (
  { __typename?: 'mutation_root' }
  & { update_relationship?: Maybe<(
    { __typename?: 'relationship_mutation_response' }
    & Pick<Relationship_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'relationship' }
      & Pick<Relationship, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_RelationshipMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_RelationshipMutation = (
  { __typename?: 'mutation_root' }
  & { update_relationship?: Maybe<(
    { __typename?: 'relationship_mutation_response' }
    & Pick<Relationship_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'relationship' }
      & Pick<Relationship, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type TitlesQueryVariables = {};


export type TitlesQuery = (
  { __typename?: 'query_root' }
  & { title: Array<(
    { __typename?: 'title' }
    & Pick<Title, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
  )> }
);

export type Add_TitleMutationVariables = {
  changes: Array<Title_Insert_Input>;
};


export type Add_TitleMutation = (
  { __typename?: 'mutation_root' }
  & { insert_title?: Maybe<(
    { __typename?: 'title_mutation_response' }
    & { returning: Array<(
      { __typename?: 'title' }
      & Pick<Title, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Update_TitleMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  changes?: Maybe<Title_Set_Input>;
};


export type Update_TitleMutation = (
  { __typename?: 'mutation_root' }
  & { update_title?: Maybe<(
    { __typename?: 'title_mutation_response' }
    & Pick<Title_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'title' }
      & Pick<Title, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type Delete_TitleMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_TitleMutation = (
  { __typename?: 'mutation_root' }
  & { update_title?: Maybe<(
    { __typename?: 'title_mutation_response' }
    & Pick<Title_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'title' }
      & Pick<Title, 'id' | 'name' | 'created_at' | 'updated_at' | 'deleted'>
    )> }
  )> }
);

export type UsersQueryVariables = {};


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
    & { gender: (
      { __typename?: 'gender' }
      & Pick<Gender, 'name'>
    ) }
  )> }
);

export type AdminsQueryVariables = {};


export type AdminsQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
    & { gender: (
      { __typename?: 'gender' }
      & Pick<Gender, 'name'>
    ) }
  )> }
);

export type PortfolioAnalystsQueryVariables = {};


export type PortfolioAnalystsQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
    & { gender: (
      { __typename?: 'gender' }
      & Pick<Gender, 'name'>
    ) }
  )> }
);

export type BackOfficersQueryVariables = {};


export type BackOfficersQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
    & { gender: (
      { __typename?: 'gender' }
      & Pick<Gender, 'name'>
    ) }
  )> }
);

export type Update_UserMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  user_changes?: Maybe<User_Set_Input>;
};


export type Update_UserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user' }
      & Pick<User, 'id' | 'first_name' | 'other_name' | 'last_name' | 'gender_id' | 'date_of_birth' | 'mobile' | 'email' | 'created_at' | 'updated_at'>
      & { gender: (
        { __typename?: 'gender' }
        & Pick<Gender, 'name'>
      ) }
    )> }
  )> }
);

export type Delete_UserMutationVariables = {
  id?: Maybe<Scalars['bigint']>;
  doneBy?: Maybe<Scalars['bigint']>;
};


export type Delete_UserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'user_mutation_response' }
    & Pick<User_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'user' }
      & Pick<User, 'id'>
    )> }
  )> }
);


export const AdvisorsDocument = gql`
    query Advisors {
  advisor(where: {userById: {deleted: {_eq: false}}}, order_by: {id: desc}) {
    id
    advisor_code
    userById {
      first_name
      other_name
      last_name
      gender_id
      gender {
        name
      }
      created_at
      updated_at
      date_of_birth
      mobile
      email
    }
    residential_address
    identification_type_id
    identification_type {
      name
    }
    identification_number
  }
}
    `;

/**
 * __useAdvisorsQuery__
 *
 * To run a query within a React component, call `useAdvisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvisorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdvisorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvisorsQuery, AdvisorsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvisorsQuery, AdvisorsQueryVariables>(AdvisorsDocument, baseOptions);
      }
export function useAdvisorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvisorsQuery, AdvisorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvisorsQuery, AdvisorsQueryVariables>(AdvisorsDocument, baseOptions);
        }
export type AdvisorsQueryHookResult = ReturnType<typeof useAdvisorsQuery>;
export type AdvisorsLazyQueryHookResult = ReturnType<typeof useAdvisorsLazyQuery>;
export type AdvisorsQueryResult = ApolloReactCommon.QueryResult<AdvisorsQuery, AdvisorsQueryVariables>;
export const Add_AdvisorDocument = gql`
    mutation add_advisor($changes: [advisor_insert_input!]!) {
  insert_advisor(objects: $changes) {
    returning {
      id
      advisor_code
      userById {
        first_name
        other_name
        last_name
        gender_id
        gender {
          name
        }
        created_at
        updated_at
        date_of_birth
        mobile
        email
      }
      residential_address
      identification_type_id
      identification_type {
        name
      }
      identification_number
    }
  }
}
    `;
export type Add_AdvisorMutationFn = ApolloReactCommon.MutationFunction<Add_AdvisorMutation, Add_AdvisorMutationVariables>;

/**
 * __useAdd_AdvisorMutation__
 *
 * To run a mutation, you first call `useAdd_AdvisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_AdvisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdvisorMutation, { data, loading, error }] = useAdd_AdvisorMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_AdvisorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_AdvisorMutation, Add_AdvisorMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_AdvisorMutation, Add_AdvisorMutationVariables>(Add_AdvisorDocument, baseOptions);
      }
export type Add_AdvisorMutationHookResult = ReturnType<typeof useAdd_AdvisorMutation>;
export type Add_AdvisorMutationResult = ApolloReactCommon.MutationResult<Add_AdvisorMutation>;
export type Add_AdvisorMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_AdvisorMutation, Add_AdvisorMutationVariables>;
export const Update_AdvisorDocument = gql`
    mutation update_advisor($id: bigint, $advisor_changes: advisor_set_input, $user_changes: user_set_input) {
  update_advisor(where: {id: {_eq: $id}}, _set: $advisor_changes) {
    affected_rows
    returning {
      id
      advisor_code
      userById {
        first_name
        other_name
        last_name
        gender_id
        gender {
          name
        }
        date_of_birth
        mobile
        email
        created_at
        updated_at
      }
      residential_address
      identification_type_id
      identification_type {
        name
      }
      identification_number
    }
  }
  update_user(where: {id: {_eq: $id}}, _set: $user_changes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type Update_AdvisorMutationFn = ApolloReactCommon.MutationFunction<Update_AdvisorMutation, Update_AdvisorMutationVariables>;

/**
 * __useUpdate_AdvisorMutation__
 *
 * To run a mutation, you first call `useUpdate_AdvisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_AdvisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvisorMutation, { data, loading, error }] = useUpdate_AdvisorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      advisor_changes: // value for 'advisor_changes'
 *      user_changes: // value for 'user_changes'
 *   },
 * });
 */
export function useUpdate_AdvisorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_AdvisorMutation, Update_AdvisorMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_AdvisorMutation, Update_AdvisorMutationVariables>(Update_AdvisorDocument, baseOptions);
      }
export type Update_AdvisorMutationHookResult = ReturnType<typeof useUpdate_AdvisorMutation>;
export type Update_AdvisorMutationResult = ApolloReactCommon.MutationResult<Update_AdvisorMutation>;
export type Update_AdvisorMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_AdvisorMutation, Update_AdvisorMutationVariables>;
export const ApksDocument = gql`
    query Apks {
  apk(where: {deleted: {_eq: false}}) {
    id
    name
    version
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useApksQuery__
 *
 * To run a query within a React component, call `useApksQuery` and pass it any options that fit your needs.
 * When your component renders, `useApksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApksQuery({
 *   variables: {
 *   },
 * });
 */
export function useApksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApksQuery, ApksQueryVariables>) {
        return ApolloReactHooks.useQuery<ApksQuery, ApksQueryVariables>(ApksDocument, baseOptions);
      }
export function useApksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApksQuery, ApksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApksQuery, ApksQueryVariables>(ApksDocument, baseOptions);
        }
export type ApksQueryHookResult = ReturnType<typeof useApksQuery>;
export type ApksLazyQueryHookResult = ReturnType<typeof useApksLazyQuery>;
export type ApksQueryResult = ApolloReactCommon.QueryResult<ApksQuery, ApksQueryVariables>;
export const Add_ApkDocument = gql`
    mutation add_apk($changes: [apk_insert_input!]!) {
  insert_apk(objects: $changes, on_conflict: {constraint: apk_version_key, update_columns: [name, version, modified_by, deleted]}) {
    returning {
      id
      name
      version
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_ApkMutationFn = ApolloReactCommon.MutationFunction<Add_ApkMutation, Add_ApkMutationVariables>;

/**
 * __useAdd_ApkMutation__
 *
 * To run a mutation, you first call `useAdd_ApkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_ApkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApkMutation, { data, loading, error }] = useAdd_ApkMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_ApkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_ApkMutation, Add_ApkMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_ApkMutation, Add_ApkMutationVariables>(Add_ApkDocument, baseOptions);
      }
export type Add_ApkMutationHookResult = ReturnType<typeof useAdd_ApkMutation>;
export type Add_ApkMutationResult = ApolloReactCommon.MutationResult<Add_ApkMutation>;
export type Add_ApkMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_ApkMutation, Add_ApkMutationVariables>;
export const Update_ApkDocument = gql`
    mutation update_apk($id: bigint, $changes: apk_set_input) {
  update_apk(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      version
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_ApkMutationFn = ApolloReactCommon.MutationFunction<Update_ApkMutation, Update_ApkMutationVariables>;

/**
 * __useUpdate_ApkMutation__
 *
 * To run a mutation, you first call `useUpdate_ApkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_ApkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApkMutation, { data, loading, error }] = useUpdate_ApkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_ApkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_ApkMutation, Update_ApkMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_ApkMutation, Update_ApkMutationVariables>(Update_ApkDocument, baseOptions);
      }
export type Update_ApkMutationHookResult = ReturnType<typeof useUpdate_ApkMutation>;
export type Update_ApkMutationResult = ApolloReactCommon.MutationResult<Update_ApkMutation>;
export type Update_ApkMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_ApkMutation, Update_ApkMutationVariables>;
export const Delete_ApkDocument = gql`
    mutation delete_apk($id: bigint, $doneBy: bigint) {
  update_apk(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      version
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_ApkMutationFn = ApolloReactCommon.MutationFunction<Delete_ApkMutation, Delete_ApkMutationVariables>;

/**
 * __useDelete_ApkMutation__
 *
 * To run a mutation, you first call `useDelete_ApkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_ApkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApkMutation, { data, loading, error }] = useDelete_ApkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_ApkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_ApkMutation, Delete_ApkMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_ApkMutation, Delete_ApkMutationVariables>(Delete_ApkDocument, baseOptions);
      }
export type Delete_ApkMutationHookResult = ReturnType<typeof useDelete_ApkMutation>;
export type Delete_ApkMutationResult = ApolloReactCommon.MutationResult<Delete_ApkMutation>;
export type Delete_ApkMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_ApkMutation, Delete_ApkMutationVariables>;
export const GetAppConfigDocument = gql`
    query GetAppConfig($name: String!) {
  app_config(where: {name: {_eq: $name}}) {
    id
    name
    value
  }
}
    `;

/**
 * __useGetAppConfigQuery__
 *
 * To run a query within a React component, call `useGetAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppConfigQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAppConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, baseOptions);
      }
export function useGetAppConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, baseOptions);
        }
export type GetAppConfigQueryHookResult = ReturnType<typeof useGetAppConfigQuery>;
export type GetAppConfigLazyQueryHookResult = ReturnType<typeof useGetAppConfigLazyQuery>;
export type GetAppConfigQueryResult = ApolloReactCommon.QueryResult<GetAppConfigQuery, GetAppConfigQueryVariables>;
export const ApprovePaymentsDocument = gql`
    mutation ApprovePayments($ids: [Int!]!) {
  approvePayments(data: {ids: $ids}) {
    success
    errors {
      path
      message
    }
  }
}
    `;
export type ApprovePaymentsMutationFn = ApolloReactCommon.MutationFunction<ApprovePaymentsMutation, ApprovePaymentsMutationVariables>;

/**
 * __useApprovePaymentsMutation__
 *
 * To run a mutation, you first call `useApprovePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvePaymentsMutation, { data, loading, error }] = useApprovePaymentsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useApprovePaymentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovePaymentsMutation, ApprovePaymentsMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovePaymentsMutation, ApprovePaymentsMutationVariables>(ApprovePaymentsDocument, baseOptions);
      }
export type ApprovePaymentsMutationHookResult = ReturnType<typeof useApprovePaymentsMutation>;
export type ApprovePaymentsMutationResult = ApolloReactCommon.MutationResult<ApprovePaymentsMutation>;
export type ApprovePaymentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovePaymentsMutation, ApprovePaymentsMutationVariables>;
export const AssetAllocationDocument = gql`
    query AssetAllocation {
  asset_allocation(where: {deleted: {_eq: false}}) {
    id
    asset_duration_id
    product_id
    product {
      id
      name
    }
    asset_duration {
      id
      duration_start
      duration_end
    }
    weight
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useAssetAllocationQuery__
 *
 * To run a query within a React component, call `useAssetAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetAllocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssetAllocationQuery, AssetAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<AssetAllocationQuery, AssetAllocationQueryVariables>(AssetAllocationDocument, baseOptions);
      }
export function useAssetAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssetAllocationQuery, AssetAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssetAllocationQuery, AssetAllocationQueryVariables>(AssetAllocationDocument, baseOptions);
        }
export type AssetAllocationQueryHookResult = ReturnType<typeof useAssetAllocationQuery>;
export type AssetAllocationLazyQueryHookResult = ReturnType<typeof useAssetAllocationLazyQuery>;
export type AssetAllocationQueryResult = ApolloReactCommon.QueryResult<AssetAllocationQuery, AssetAllocationQueryVariables>;
export const Add_Asset_AllocationDocument = gql`
    mutation add_asset_allocation($changes: [asset_allocation_insert_input!]!) {
  insert_asset_allocation(objects: $changes) {
    returning {
      id
      asset_duration_id
      product_id
      product {
        id
        name
      }
      asset_duration {
        id
        duration_start
        duration_end
      }
      weight
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Asset_AllocationMutationFn = ApolloReactCommon.MutationFunction<Add_Asset_AllocationMutation, Add_Asset_AllocationMutationVariables>;

/**
 * __useAdd_Asset_AllocationMutation__
 *
 * To run a mutation, you first call `useAdd_Asset_AllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Asset_AllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetAllocationMutation, { data, loading, error }] = useAdd_Asset_AllocationMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Asset_AllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Asset_AllocationMutation, Add_Asset_AllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Asset_AllocationMutation, Add_Asset_AllocationMutationVariables>(Add_Asset_AllocationDocument, baseOptions);
      }
export type Add_Asset_AllocationMutationHookResult = ReturnType<typeof useAdd_Asset_AllocationMutation>;
export type Add_Asset_AllocationMutationResult = ApolloReactCommon.MutationResult<Add_Asset_AllocationMutation>;
export type Add_Asset_AllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Asset_AllocationMutation, Add_Asset_AllocationMutationVariables>;
export const Update_Asset_AllocationDocument = gql`
    mutation update_asset_allocation($id: bigint, $changes: asset_allocation_set_input) {
  update_asset_allocation(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      asset_duration_id
      product_id
      product {
        id
        name
      }
      asset_duration {
        id
        duration_start
        duration_end
      }
      weight
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Asset_AllocationMutationFn = ApolloReactCommon.MutationFunction<Update_Asset_AllocationMutation, Update_Asset_AllocationMutationVariables>;

/**
 * __useUpdate_Asset_AllocationMutation__
 *
 * To run a mutation, you first call `useUpdate_Asset_AllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Asset_AllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetAllocationMutation, { data, loading, error }] = useUpdate_Asset_AllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Asset_AllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Asset_AllocationMutation, Update_Asset_AllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Asset_AllocationMutation, Update_Asset_AllocationMutationVariables>(Update_Asset_AllocationDocument, baseOptions);
      }
export type Update_Asset_AllocationMutationHookResult = ReturnType<typeof useUpdate_Asset_AllocationMutation>;
export type Update_Asset_AllocationMutationResult = ApolloReactCommon.MutationResult<Update_Asset_AllocationMutation>;
export type Update_Asset_AllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Asset_AllocationMutation, Update_Asset_AllocationMutationVariables>;
export const Delete_Asset_AllocationDocument = gql`
    mutation delete_asset_allocation($id: bigint, $doneBy: bigint) {
  update_asset_allocation(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      asset_duration_id
      product_id
      product {
        id
        name
      }
      asset_duration {
        id
        duration_start
        duration_end
      }
      weight
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Asset_AllocationMutationFn = ApolloReactCommon.MutationFunction<Delete_Asset_AllocationMutation, Delete_Asset_AllocationMutationVariables>;

/**
 * __useDelete_Asset_AllocationMutation__
 *
 * To run a mutation, you first call `useDelete_Asset_AllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Asset_AllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetAllocationMutation, { data, loading, error }] = useDelete_Asset_AllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Asset_AllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Asset_AllocationMutation, Delete_Asset_AllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Asset_AllocationMutation, Delete_Asset_AllocationMutationVariables>(Delete_Asset_AllocationDocument, baseOptions);
      }
export type Delete_Asset_AllocationMutationHookResult = ReturnType<typeof useDelete_Asset_AllocationMutation>;
export type Delete_Asset_AllocationMutationResult = ApolloReactCommon.MutationResult<Delete_Asset_AllocationMutation>;
export type Delete_Asset_AllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Asset_AllocationMutation, Delete_Asset_AllocationMutationVariables>;
export const AssetDurationDocument = gql`
    query AssetDuration {
  asset_duration(where: {deleted: {_eq: false}}) {
    id
    duration_start
    duration_end
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useAssetDurationQuery__
 *
 * To run a query within a React component, call `useAssetDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetDurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetDurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssetDurationQuery, AssetDurationQueryVariables>) {
        return ApolloReactHooks.useQuery<AssetDurationQuery, AssetDurationQueryVariables>(AssetDurationDocument, baseOptions);
      }
export function useAssetDurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssetDurationQuery, AssetDurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssetDurationQuery, AssetDurationQueryVariables>(AssetDurationDocument, baseOptions);
        }
export type AssetDurationQueryHookResult = ReturnType<typeof useAssetDurationQuery>;
export type AssetDurationLazyQueryHookResult = ReturnType<typeof useAssetDurationLazyQuery>;
export type AssetDurationQueryResult = ApolloReactCommon.QueryResult<AssetDurationQuery, AssetDurationQueryVariables>;
export const Add_Asset_DurationDocument = gql`
    mutation add_asset_duration($changes: [asset_duration_insert_input!]!) {
  insert_asset_duration(objects: $changes) {
    returning {
      id
      duration_start
      duration_end
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Asset_DurationMutationFn = ApolloReactCommon.MutationFunction<Add_Asset_DurationMutation, Add_Asset_DurationMutationVariables>;

/**
 * __useAdd_Asset_DurationMutation__
 *
 * To run a mutation, you first call `useAdd_Asset_DurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Asset_DurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetDurationMutation, { data, loading, error }] = useAdd_Asset_DurationMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Asset_DurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Asset_DurationMutation, Add_Asset_DurationMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Asset_DurationMutation, Add_Asset_DurationMutationVariables>(Add_Asset_DurationDocument, baseOptions);
      }
export type Add_Asset_DurationMutationHookResult = ReturnType<typeof useAdd_Asset_DurationMutation>;
export type Add_Asset_DurationMutationResult = ApolloReactCommon.MutationResult<Add_Asset_DurationMutation>;
export type Add_Asset_DurationMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Asset_DurationMutation, Add_Asset_DurationMutationVariables>;
export const Update_Asset_DurationDocument = gql`
    mutation update_asset_duration($id: bigint, $changes: asset_duration_set_input) {
  update_asset_duration(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      duration_start
      duration_end
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Asset_DurationMutationFn = ApolloReactCommon.MutationFunction<Update_Asset_DurationMutation, Update_Asset_DurationMutationVariables>;

/**
 * __useUpdate_Asset_DurationMutation__
 *
 * To run a mutation, you first call `useUpdate_Asset_DurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Asset_DurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetDurationMutation, { data, loading, error }] = useUpdate_Asset_DurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Asset_DurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Asset_DurationMutation, Update_Asset_DurationMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Asset_DurationMutation, Update_Asset_DurationMutationVariables>(Update_Asset_DurationDocument, baseOptions);
      }
export type Update_Asset_DurationMutationHookResult = ReturnType<typeof useUpdate_Asset_DurationMutation>;
export type Update_Asset_DurationMutationResult = ApolloReactCommon.MutationResult<Update_Asset_DurationMutation>;
export type Update_Asset_DurationMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Asset_DurationMutation, Update_Asset_DurationMutationVariables>;
export const Delete_Asset_DurationDocument = gql`
    mutation delete_asset_duration($id: bigint, $doneBy: bigint) {
  update_asset_duration(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      duration_start
      duration_end
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Asset_DurationMutationFn = ApolloReactCommon.MutationFunction<Delete_Asset_DurationMutation, Delete_Asset_DurationMutationVariables>;

/**
 * __useDelete_Asset_DurationMutation__
 *
 * To run a mutation, you first call `useDelete_Asset_DurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Asset_DurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetDurationMutation, { data, loading, error }] = useDelete_Asset_DurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Asset_DurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Asset_DurationMutation, Delete_Asset_DurationMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Asset_DurationMutation, Delete_Asset_DurationMutationVariables>(Delete_Asset_DurationDocument, baseOptions);
      }
export type Delete_Asset_DurationMutationHookResult = ReturnType<typeof useDelete_Asset_DurationMutation>;
export type Delete_Asset_DurationMutationResult = ApolloReactCommon.MutationResult<Delete_Asset_DurationMutation>;
export type Delete_Asset_DurationMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Asset_DurationMutation, Delete_Asset_DurationMutationVariables>;
export const PaginatedBackgroundNotificationsDocument = gql`
    query paginatedBackgroundNotifications($limit: Int!, $offset: Int, $search: String) {
  background_notifications_aggregate(where: {_or: [{pslcode: {_ilike: $search}}, {status: {_ilike: $search}}, {type: {_ilike: $search}}]}) {
    aggregate {
      count
    }
  }
  background_notifications(where: {_or: [{pslcode: {_ilike: $search}}, {status: {_ilike: $search}}, {type: {_ilike: $search}}]}, limit: $limit, offset: $offset, order_by: {id: desc}) {
    id
    pslcode
    status
    response
    type
    created_at
  }
}
    `;

/**
 * __usePaginatedBackgroundNotificationsQuery__
 *
 * To run a query within a React component, call `usePaginatedBackgroundNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedBackgroundNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedBackgroundNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginatedBackgroundNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaginatedBackgroundNotificationsQuery, PaginatedBackgroundNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaginatedBackgroundNotificationsQuery, PaginatedBackgroundNotificationsQueryVariables>(PaginatedBackgroundNotificationsDocument, baseOptions);
      }
export function usePaginatedBackgroundNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaginatedBackgroundNotificationsQuery, PaginatedBackgroundNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaginatedBackgroundNotificationsQuery, PaginatedBackgroundNotificationsQueryVariables>(PaginatedBackgroundNotificationsDocument, baseOptions);
        }
export type PaginatedBackgroundNotificationsQueryHookResult = ReturnType<typeof usePaginatedBackgroundNotificationsQuery>;
export type PaginatedBackgroundNotificationsLazyQueryHookResult = ReturnType<typeof usePaginatedBackgroundNotificationsLazyQuery>;
export type PaginatedBackgroundNotificationsQueryResult = ApolloReactCommon.QueryResult<PaginatedBackgroundNotificationsQuery, PaginatedBackgroundNotificationsQueryVariables>;
export const BackgroundNotificationDocument = gql`
    query backgroundNotification($id: Int) {
  background_notifications(where: {id: {_eq: $id}}) {
    id
    status
    type
    pslcode
    created_at
    response
  }
}
    `;

/**
 * __useBackgroundNotificationQuery__
 *
 * To run a query within a React component, call `useBackgroundNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackgroundNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackgroundNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackgroundNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BackgroundNotificationQuery, BackgroundNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<BackgroundNotificationQuery, BackgroundNotificationQueryVariables>(BackgroundNotificationDocument, baseOptions);
      }
export function useBackgroundNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BackgroundNotificationQuery, BackgroundNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BackgroundNotificationQuery, BackgroundNotificationQueryVariables>(BackgroundNotificationDocument, baseOptions);
        }
export type BackgroundNotificationQueryHookResult = ReturnType<typeof useBackgroundNotificationQuery>;
export type BackgroundNotificationLazyQueryHookResult = ReturnType<typeof useBackgroundNotificationLazyQuery>;
export type BackgroundNotificationQueryResult = ApolloReactCommon.QueryResult<BackgroundNotificationQuery, BackgroundNotificationQueryVariables>;
export const BranchDocument = gql`
    query Branch {
  branch {
    id
    name
    payment_provider_id
    payment_provider {
      id
      name
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useBranchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BranchQuery, BranchQueryVariables>) {
        return ApolloReactHooks.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, baseOptions);
      }
export function useBranchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, baseOptions);
        }
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchQueryResult = ApolloReactCommon.QueryResult<BranchQuery, BranchQueryVariables>;
export const ContributionSourceDocument = gql`
    query ContributionSource {
  contribution_source(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useContributionSourceQuery__
 *
 * To run a query within a React component, call `useContributionSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContributionSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContributionSourceQuery({
 *   variables: {
 *   },
 * });
 */
export function useContributionSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContributionSourceQuery, ContributionSourceQueryVariables>) {
        return ApolloReactHooks.useQuery<ContributionSourceQuery, ContributionSourceQueryVariables>(ContributionSourceDocument, baseOptions);
      }
export function useContributionSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContributionSourceQuery, ContributionSourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContributionSourceQuery, ContributionSourceQueryVariables>(ContributionSourceDocument, baseOptions);
        }
export type ContributionSourceQueryHookResult = ReturnType<typeof useContributionSourceQuery>;
export type ContributionSourceLazyQueryHookResult = ReturnType<typeof useContributionSourceLazyQuery>;
export type ContributionSourceQueryResult = ApolloReactCommon.QueryResult<ContributionSourceQuery, ContributionSourceQueryVariables>;
export const Add_Contribution_SourceDocument = gql`
    mutation add_contribution_source($changes: [contribution_source_insert_input!]!) {
  insert_contribution_source(objects: $changes, on_conflict: {constraint: contribution_source_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Contribution_SourceMutationFn = ApolloReactCommon.MutationFunction<Add_Contribution_SourceMutation, Add_Contribution_SourceMutationVariables>;

/**
 * __useAdd_Contribution_SourceMutation__
 *
 * To run a mutation, you first call `useAdd_Contribution_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Contribution_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContributionSourceMutation, { data, loading, error }] = useAdd_Contribution_SourceMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Contribution_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Contribution_SourceMutation, Add_Contribution_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Contribution_SourceMutation, Add_Contribution_SourceMutationVariables>(Add_Contribution_SourceDocument, baseOptions);
      }
export type Add_Contribution_SourceMutationHookResult = ReturnType<typeof useAdd_Contribution_SourceMutation>;
export type Add_Contribution_SourceMutationResult = ApolloReactCommon.MutationResult<Add_Contribution_SourceMutation>;
export type Add_Contribution_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Contribution_SourceMutation, Add_Contribution_SourceMutationVariables>;
export const Update_Contribution_SourceDocument = gql`
    mutation update_contribution_source($id: bigint, $changes: contribution_source_set_input) {
  update_contribution_source(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Contribution_SourceMutationFn = ApolloReactCommon.MutationFunction<Update_Contribution_SourceMutation, Update_Contribution_SourceMutationVariables>;

/**
 * __useUpdate_Contribution_SourceMutation__
 *
 * To run a mutation, you first call `useUpdate_Contribution_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Contribution_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContributionSourceMutation, { data, loading, error }] = useUpdate_Contribution_SourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Contribution_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Contribution_SourceMutation, Update_Contribution_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Contribution_SourceMutation, Update_Contribution_SourceMutationVariables>(Update_Contribution_SourceDocument, baseOptions);
      }
export type Update_Contribution_SourceMutationHookResult = ReturnType<typeof useUpdate_Contribution_SourceMutation>;
export type Update_Contribution_SourceMutationResult = ApolloReactCommon.MutationResult<Update_Contribution_SourceMutation>;
export type Update_Contribution_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Contribution_SourceMutation, Update_Contribution_SourceMutationVariables>;
export const Delete_Contribution_SourceDocument = gql`
    mutation delete_contribution_source($id: bigint, $doneBy: bigint) {
  update_contribution_source(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Contribution_SourceMutationFn = ApolloReactCommon.MutationFunction<Delete_Contribution_SourceMutation, Delete_Contribution_SourceMutationVariables>;

/**
 * __useDelete_Contribution_SourceMutation__
 *
 * To run a mutation, you first call `useDelete_Contribution_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Contribution_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContributionSourceMutation, { data, loading, error }] = useDelete_Contribution_SourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Contribution_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Contribution_SourceMutation, Delete_Contribution_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Contribution_SourceMutation, Delete_Contribution_SourceMutationVariables>(Delete_Contribution_SourceDocument, baseOptions);
      }
export type Delete_Contribution_SourceMutationHookResult = ReturnType<typeof useDelete_Contribution_SourceMutation>;
export type Delete_Contribution_SourceMutationResult = ApolloReactCommon.MutationResult<Delete_Contribution_SourceMutation>;
export type Delete_Contribution_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Contribution_SourceMutation, Delete_Contribution_SourceMutationVariables>;
export const CountryDocument = gql`
    query Country {
  country {
    id
    name
    created_at
    updated_at
  }
}
    `;

/**
 * __useCountryQuery__
 *
 * To run a query within a React component, call `useCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountryQuery, CountryQueryVariables>) {
        return ApolloReactHooks.useQuery<CountryQuery, CountryQueryVariables>(CountryDocument, baseOptions);
      }
export function useCountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountryQuery, CountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountryQuery, CountryQueryVariables>(CountryDocument, baseOptions);
        }
export type CountryQueryHookResult = ReturnType<typeof useCountryQuery>;
export type CountryLazyQueryHookResult = ReturnType<typeof useCountryLazyQuery>;
export type CountryQueryResult = ApolloReactCommon.QueryResult<CountryQuery, CountryQueryVariables>;
export const CustomerDocument = gql`
    query Customer {
  member(where: {is_customer: {_eq: true}}) {
    id
    psl_code
    nationality
    status {
      id
      name
    }
    tin
    status {
      id
      name
    }
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    user {
      id
      first_name
      last_name
      other_name
      gender {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
      }
export function useCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, baseOptions);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const PaginatedCustomersDocument = gql`
    query paginatedCustomers($limit: Int!, $offset: Int, $search: String) {
  member_aggregate(where: {is_customer: {_eq: true}, _or: [{psl_code: {_ilike: $search}}, {user: {first_name: {_ilike: $search}}}, {user: {last_name: {_ilike: $search}}}, {user: {full_name: {_ilike: $search}}}, {user: {gender: {name: {_ilike: $search}}}}, {status: {name: {_ilike: $search}}}, {assigned_to_user: {first_name: {_ilike: $search}}}, {assigned_to_user: {last_name: {_ilike: $search}}}, {assigned_to_user: {full_name: {_ilike: $search}}}]}) {
    aggregate {
      count
    }
  }
  member(where: {is_customer: {_eq: true}, _or: [{psl_code: {_ilike: $search}}, {user: {first_name: {_ilike: $search}}}, {user: {last_name: {_ilike: $search}}}, {user: {full_name: {_ilike: $search}}}, {user: {gender: {name: {_ilike: $search}}}}, {status: {name: {_ilike: $search}}}, {assigned_to_user: {first_name: {_ilike: $search}}}, {assigned_to_user: {last_name: {_ilike: $search}}}, {assigned_to_user: {full_name: {_ilike: $search}}}]}, limit: $limit, offset: $offset, order_by: {id: desc}) {
    id
    account_type
    psl_code
    nationality
    status {
      id
      name
    }
    tin
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    user {
      id
      first_name
      last_name
      other_name
      gender {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePaginatedCustomersQuery__
 *
 * To run a query within a React component, call `usePaginatedCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedCustomersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginatedCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>(PaginatedCustomersDocument, baseOptions);
      }
export function usePaginatedCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>(PaginatedCustomersDocument, baseOptions);
        }
export type PaginatedCustomersQueryHookResult = ReturnType<typeof usePaginatedCustomersQuery>;
export type PaginatedCustomersLazyQueryHookResult = ReturnType<typeof usePaginatedCustomersLazyQuery>;
export type PaginatedCustomersQueryResult = ApolloReactCommon.QueryResult<PaginatedCustomersQuery, PaginatedCustomersQueryVariables>;
export const RecentCustomersDocument = gql`
    query RecentCustomers {
  member(order_by: {approved_on: desc_nulls_last}, where: {is_customer: {_eq: true}}, limit: 4) {
    user {
      id
      first_name
      last_name
      other_name
      email
      mobile
    }
  }
}
    `;

/**
 * __useRecentCustomersQuery__
 *
 * To run a query within a React component, call `useRecentCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecentCustomersQuery, RecentCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<RecentCustomersQuery, RecentCustomersQueryVariables>(RecentCustomersDocument, baseOptions);
      }
export function useRecentCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecentCustomersQuery, RecentCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecentCustomersQuery, RecentCustomersQueryVariables>(RecentCustomersDocument, baseOptions);
        }
export type RecentCustomersQueryHookResult = ReturnType<typeof useRecentCustomersQuery>;
export type RecentCustomersLazyQueryHookResult = ReturnType<typeof useRecentCustomersLazyQuery>;
export type RecentCustomersQueryResult = ApolloReactCommon.QueryResult<RecentCustomersQuery, RecentCustomersQueryVariables>;
export const DashboardSummaryDocument = gql`
    query DashboardSummary {
  dashboard_summary {
    agent_count
    last_thirty_days_customer_count
    total_customer_count
    percentage_customer_count_increase
    total_prospect_count
    last_thirty_days_prospect_count
    percentage_prospect_count_increase
  }
}
    `;

/**
 * __useDashboardSummaryQuery__
 *
 * To run a query within a React component, call `useDashboardSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardSummaryQuery, DashboardSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardSummaryQuery, DashboardSummaryQueryVariables>(DashboardSummaryDocument, baseOptions);
      }
export function useDashboardSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardSummaryQuery, DashboardSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardSummaryQuery, DashboardSummaryQueryVariables>(DashboardSummaryDocument, baseOptions);
        }
export type DashboardSummaryQueryHookResult = ReturnType<typeof useDashboardSummaryQuery>;
export type DashboardSummaryLazyQueryHookResult = ReturnType<typeof useDashboardSummaryLazyQuery>;
export type DashboardSummaryQueryResult = ApolloReactCommon.QueryResult<DashboardSummaryQuery, DashboardSummaryQueryVariables>;
export const ExpectedReturnsDocument = gql`
    query ExpectedReturns {
  expected_return {
    duration
    expected_return
  }
}
    `;

/**
 * __useExpectedReturnsQuery__
 *
 * To run a query within a React component, call `useExpectedReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpectedReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpectedReturnsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpectedReturnsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpectedReturnsQuery, ExpectedReturnsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExpectedReturnsQuery, ExpectedReturnsQueryVariables>(ExpectedReturnsDocument, baseOptions);
      }
export function useExpectedReturnsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpectedReturnsQuery, ExpectedReturnsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExpectedReturnsQuery, ExpectedReturnsQueryVariables>(ExpectedReturnsDocument, baseOptions);
        }
export type ExpectedReturnsQueryHookResult = ReturnType<typeof useExpectedReturnsQuery>;
export type ExpectedReturnsLazyQueryHookResult = ReturnType<typeof useExpectedReturnsLazyQuery>;
export type ExpectedReturnsQueryResult = ApolloReactCommon.QueryResult<ExpectedReturnsQuery, ExpectedReturnsQueryVariables>;
export const GendersDocument = gql`
    query Genders {
  gender(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useGendersQuery__
 *
 * To run a query within a React component, call `useGendersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGendersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGendersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGendersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GendersQuery, GendersQueryVariables>) {
        return ApolloReactHooks.useQuery<GendersQuery, GendersQueryVariables>(GendersDocument, baseOptions);
      }
export function useGendersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GendersQuery, GendersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GendersQuery, GendersQueryVariables>(GendersDocument, baseOptions);
        }
export type GendersQueryHookResult = ReturnType<typeof useGendersQuery>;
export type GendersLazyQueryHookResult = ReturnType<typeof useGendersLazyQuery>;
export type GendersQueryResult = ApolloReactCommon.QueryResult<GendersQuery, GendersQueryVariables>;
export const Add_GenderDocument = gql`
    mutation add_gender($changes: [gender_insert_input!]!) {
  insert_gender(objects: $changes, on_conflict: {constraint: gender_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_GenderMutationFn = ApolloReactCommon.MutationFunction<Add_GenderMutation, Add_GenderMutationVariables>;

/**
 * __useAdd_GenderMutation__
 *
 * To run a mutation, you first call `useAdd_GenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_GenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGenderMutation, { data, loading, error }] = useAdd_GenderMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_GenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_GenderMutation, Add_GenderMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_GenderMutation, Add_GenderMutationVariables>(Add_GenderDocument, baseOptions);
      }
export type Add_GenderMutationHookResult = ReturnType<typeof useAdd_GenderMutation>;
export type Add_GenderMutationResult = ApolloReactCommon.MutationResult<Add_GenderMutation>;
export type Add_GenderMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_GenderMutation, Add_GenderMutationVariables>;
export const Update_GenderDocument = gql`
    mutation update_gender($id: bigint, $changes: gender_set_input) {
  update_gender(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_GenderMutationFn = ApolloReactCommon.MutationFunction<Update_GenderMutation, Update_GenderMutationVariables>;

/**
 * __useUpdate_GenderMutation__
 *
 * To run a mutation, you first call `useUpdate_GenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_GenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenderMutation, { data, loading, error }] = useUpdate_GenderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_GenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_GenderMutation, Update_GenderMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_GenderMutation, Update_GenderMutationVariables>(Update_GenderDocument, baseOptions);
      }
export type Update_GenderMutationHookResult = ReturnType<typeof useUpdate_GenderMutation>;
export type Update_GenderMutationResult = ApolloReactCommon.MutationResult<Update_GenderMutation>;
export type Update_GenderMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_GenderMutation, Update_GenderMutationVariables>;
export const Delete_GenderDocument = gql`
    mutation delete_gender($id: bigint, $doneBy: bigint) {
  update_gender(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_GenderMutationFn = ApolloReactCommon.MutationFunction<Delete_GenderMutation, Delete_GenderMutationVariables>;

/**
 * __useDelete_GenderMutation__
 *
 * To run a mutation, you first call `useDelete_GenderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_GenderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGenderMutation, { data, loading, error }] = useDelete_GenderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_GenderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_GenderMutation, Delete_GenderMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_GenderMutation, Delete_GenderMutationVariables>(Delete_GenderDocument, baseOptions);
      }
export type Delete_GenderMutationHookResult = ReturnType<typeof useDelete_GenderMutation>;
export type Delete_GenderMutationResult = ApolloReactCommon.MutationResult<Delete_GenderMutation>;
export type Delete_GenderMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_GenderMutation, Delete_GenderMutationVariables>;
export const Generate_Signeds3_Request_For_UploadDocument = gql`
    mutation generate_signeds3_request_for_upload($filename: String!, $filetype: String!) {
  generateSignedS3RequestForUpload(data: {filename: $filename, fileType: $filetype}) {
    signedRequest
    url
  }
}
    `;
export type Generate_Signeds3_Request_For_UploadMutationFn = ApolloReactCommon.MutationFunction<Generate_Signeds3_Request_For_UploadMutation, Generate_Signeds3_Request_For_UploadMutationVariables>;

/**
 * __useGenerate_Signeds3_Request_For_UploadMutation__
 *
 * To run a mutation, you first call `useGenerate_Signeds3_Request_For_UploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate_Signeds3_Request_For_UploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSigneds3RequestForUploadMutation, { data, loading, error }] = useGenerate_Signeds3_Request_For_UploadMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      filetype: // value for 'filetype'
 *   },
 * });
 */
export function useGenerate_Signeds3_Request_For_UploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Generate_Signeds3_Request_For_UploadMutation, Generate_Signeds3_Request_For_UploadMutationVariables>) {
        return ApolloReactHooks.useMutation<Generate_Signeds3_Request_For_UploadMutation, Generate_Signeds3_Request_For_UploadMutationVariables>(Generate_Signeds3_Request_For_UploadDocument, baseOptions);
      }
export type Generate_Signeds3_Request_For_UploadMutationHookResult = ReturnType<typeof useGenerate_Signeds3_Request_For_UploadMutation>;
export type Generate_Signeds3_Request_For_UploadMutationResult = ApolloReactCommon.MutationResult<Generate_Signeds3_Request_For_UploadMutation>;
export type Generate_Signeds3_Request_For_UploadMutationOptions = ApolloReactCommon.BaseMutationOptions<Generate_Signeds3_Request_For_UploadMutation, Generate_Signeds3_Request_For_UploadMutationVariables>;
export const Generate_Signeds3_Request_For_DownloadDocument = gql`
    mutation generate_signeds3_request_for_download($filename: String!, $filetype: String!) {
  generateSignedS3RequestForDownload(data: {filename: $filename, fileType: $filetype}) {
    signedRequest
    url
  }
}
    `;
export type Generate_Signeds3_Request_For_DownloadMutationFn = ApolloReactCommon.MutationFunction<Generate_Signeds3_Request_For_DownloadMutation, Generate_Signeds3_Request_For_DownloadMutationVariables>;

/**
 * __useGenerate_Signeds3_Request_For_DownloadMutation__
 *
 * To run a mutation, you first call `useGenerate_Signeds3_Request_For_DownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate_Signeds3_Request_For_DownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSigneds3RequestForDownloadMutation, { data, loading, error }] = useGenerate_Signeds3_Request_For_DownloadMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      filetype: // value for 'filetype'
 *   },
 * });
 */
export function useGenerate_Signeds3_Request_For_DownloadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Generate_Signeds3_Request_For_DownloadMutation, Generate_Signeds3_Request_For_DownloadMutationVariables>) {
        return ApolloReactHooks.useMutation<Generate_Signeds3_Request_For_DownloadMutation, Generate_Signeds3_Request_For_DownloadMutationVariables>(Generate_Signeds3_Request_For_DownloadDocument, baseOptions);
      }
export type Generate_Signeds3_Request_For_DownloadMutationHookResult = ReturnType<typeof useGenerate_Signeds3_Request_For_DownloadMutation>;
export type Generate_Signeds3_Request_For_DownloadMutationResult = ApolloReactCommon.MutationResult<Generate_Signeds3_Request_For_DownloadMutation>;
export type Generate_Signeds3_Request_For_DownloadMutationOptions = ApolloReactCommon.BaseMutationOptions<Generate_Signeds3_Request_For_DownloadMutation, Generate_Signeds3_Request_For_DownloadMutationVariables>;
export const GenerateStatementDocument = gql`
    mutation GenerateStatement($customerId: Float!, $startDate: DateTime!, $endDate: DateTime!) {
  generateStatement(data: {customerId: $customerId, startDate: $startDate, endDate: $endDate}) {
    success
    results {
      startDate
      endDate
      beginningBalance
      contributions
      totalPeriodNetIncome
      endOfPeriodOfBalance
      redemptions
      payments {
        date
        firstPaymentDate
        verboseDate
        payments {
          product
          amount
        }
        total
      }
      goalAllocations {
        goal
        allocation
      }
      goalSummary {
        goal
        percentageGoalAchieved
        goalBalance
      }
      goalSummaryTotal
      customerDetails {
        fullName
        postalAddress
        homeAddress
        city
        pslCode
      }
    }
  }
}
    `;
export type GenerateStatementMutationFn = ApolloReactCommon.MutationFunction<GenerateStatementMutation, GenerateStatementMutationVariables>;

/**
 * __useGenerateStatementMutation__
 *
 * To run a mutation, you first call `useGenerateStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateStatementMutation, { data, loading, error }] = useGenerateStatementMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGenerateStatementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateStatementMutation, GenerateStatementMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateStatementMutation, GenerateStatementMutationVariables>(GenerateStatementDocument, baseOptions);
      }
export type GenerateStatementMutationHookResult = ReturnType<typeof useGenerateStatementMutation>;
export type GenerateStatementMutationResult = ApolloReactCommon.MutationResult<GenerateStatementMutation>;
export type GenerateStatementMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateStatementMutation, GenerateStatementMutationVariables>;
export const GoalsDocument = gql`
    query Goals {
  goal(where: {deleted: {_eq: false}}) {
    id
    name
    goal_products {
      id
      product {
        id
        name
      }
    }
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useGoalsQuery__
 *
 * To run a query within a React component, call `useGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalsQuery, GoalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalsQuery, GoalsQueryVariables>(GoalsDocument, baseOptions);
      }
export function useGoalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalsQuery, GoalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalsQuery, GoalsQueryVariables>(GoalsDocument, baseOptions);
        }
export type GoalsQueryHookResult = ReturnType<typeof useGoalsQuery>;
export type GoalsLazyQueryHookResult = ReturnType<typeof useGoalsLazyQuery>;
export type GoalsQueryResult = ApolloReactCommon.QueryResult<GoalsQuery, GoalsQueryVariables>;
export const Add_GoalDocument = gql`
    mutation add_goal($goal_changes: [goal_insert_input!]!) {
  insert_goal(objects: $goal_changes, on_conflict: {constraint: goal_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      goal_products {
        id
        product {
          id
          name
        }
      }
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_GoalMutationFn = ApolloReactCommon.MutationFunction<Add_GoalMutation, Add_GoalMutationVariables>;

/**
 * __useAdd_GoalMutation__
 *
 * To run a mutation, you first call `useAdd_GoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_GoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoalMutation, { data, loading, error }] = useAdd_GoalMutation({
 *   variables: {
 *      goal_changes: // value for 'goal_changes'
 *   },
 * });
 */
export function useAdd_GoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_GoalMutation, Add_GoalMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_GoalMutation, Add_GoalMutationVariables>(Add_GoalDocument, baseOptions);
      }
export type Add_GoalMutationHookResult = ReturnType<typeof useAdd_GoalMutation>;
export type Add_GoalMutationResult = ApolloReactCommon.MutationResult<Add_GoalMutation>;
export type Add_GoalMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_GoalMutation, Add_GoalMutationVariables>;
export const Update_GoalDocument = gql`
    mutation update_goal($id: bigint, $products: [goal_product_insert_input!]!, $changes: goal_set_input) {
  delete_goal_product(where: {goal_id: {_eq: $id}}) {
    affected_rows
  }
  insert_goal_product(objects: $products) {
    affected_rows
  }
  update_goal(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      goal_products {
        id
        product {
          id
          name
        }
      }
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_GoalMutationFn = ApolloReactCommon.MutationFunction<Update_GoalMutation, Update_GoalMutationVariables>;

/**
 * __useUpdate_GoalMutation__
 *
 * To run a mutation, you first call `useUpdate_GoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_GoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutation, { data, loading, error }] = useUpdate_GoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      products: // value for 'products'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_GoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_GoalMutation, Update_GoalMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_GoalMutation, Update_GoalMutationVariables>(Update_GoalDocument, baseOptions);
      }
export type Update_GoalMutationHookResult = ReturnType<typeof useUpdate_GoalMutation>;
export type Update_GoalMutationResult = ApolloReactCommon.MutationResult<Update_GoalMutation>;
export type Update_GoalMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_GoalMutation, Update_GoalMutationVariables>;
export const Delete_GoalDocument = gql`
    mutation delete_goal($id: bigint, $doneBy: bigint) {
  delete_goal_product(where: {goal_id: {_eq: $id}}) {
    affected_rows
  }
  update_goal(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      goal_products {
        id
        product {
          id
          name
        }
      }
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_GoalMutationFn = ApolloReactCommon.MutationFunction<Delete_GoalMutation, Delete_GoalMutationVariables>;

/**
 * __useDelete_GoalMutation__
 *
 * To run a mutation, you first call `useDelete_GoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_GoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGoalMutation, { data, loading, error }] = useDelete_GoalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_GoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_GoalMutation, Delete_GoalMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_GoalMutation, Delete_GoalMutationVariables>(Delete_GoalDocument, baseOptions);
      }
export type Delete_GoalMutationHookResult = ReturnType<typeof useDelete_GoalMutation>;
export type Delete_GoalMutationResult = ApolloReactCommon.MutationResult<Delete_GoalMutation>;
export type Delete_GoalMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_GoalMutation, Delete_GoalMutationVariables>;
export const CalcFutureValueGivenPpiDocument = gql`
    mutation calcFutureValueGivenPPI($goalType: String!, $currentValue: Float!, $duration: Float!, $yearsToFund: Float!) {
  goalCalculations(data: {calcFor: "future_value", goalType: $goalType, currentValue: $currentValue, duration: $duration, yearsToFund: $yearsToFund}) {
    value
  }
}
    `;
export type CalcFutureValueGivenPpiMutationFn = ApolloReactCommon.MutationFunction<CalcFutureValueGivenPpiMutation, CalcFutureValueGivenPpiMutationVariables>;

/**
 * __useCalcFutureValueGivenPpiMutation__
 *
 * To run a mutation, you first call `useCalcFutureValueGivenPpiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalcFutureValueGivenPpiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calcFutureValueGivenPpiMutation, { data, loading, error }] = useCalcFutureValueGivenPpiMutation({
 *   variables: {
 *      goalType: // value for 'goalType'
 *      currentValue: // value for 'currentValue'
 *      duration: // value for 'duration'
 *      yearsToFund: // value for 'yearsToFund'
 *   },
 * });
 */
export function useCalcFutureValueGivenPpiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalcFutureValueGivenPpiMutation, CalcFutureValueGivenPpiMutationVariables>) {
        return ApolloReactHooks.useMutation<CalcFutureValueGivenPpiMutation, CalcFutureValueGivenPpiMutationVariables>(CalcFutureValueGivenPpiDocument, baseOptions);
      }
export type CalcFutureValueGivenPpiMutationHookResult = ReturnType<typeof useCalcFutureValueGivenPpiMutation>;
export type CalcFutureValueGivenPpiMutationResult = ApolloReactCommon.MutationResult<CalcFutureValueGivenPpiMutation>;
export type CalcFutureValueGivenPpiMutationOptions = ApolloReactCommon.BaseMutationOptions<CalcFutureValueGivenPpiMutation, CalcFutureValueGivenPpiMutationVariables>;
export const CalcFutureValueGivenMcdDocument = gql`
    mutation calcFutureValueGivenMCD($monthlyContribution: Float!, $duration: Float!, $expectedReturn: Float!) {
  goalCalculations(data: {calcFor: "future_value", monthlyContribution: $monthlyContribution, duration: $duration, expectedReturn: $expectedReturn}) {
    value
  }
}
    `;
export type CalcFutureValueGivenMcdMutationFn = ApolloReactCommon.MutationFunction<CalcFutureValueGivenMcdMutation, CalcFutureValueGivenMcdMutationVariables>;

/**
 * __useCalcFutureValueGivenMcdMutation__
 *
 * To run a mutation, you first call `useCalcFutureValueGivenMcdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalcFutureValueGivenMcdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calcFutureValueGivenMcdMutation, { data, loading, error }] = useCalcFutureValueGivenMcdMutation({
 *   variables: {
 *      monthlyContribution: // value for 'monthlyContribution'
 *      duration: // value for 'duration'
 *      expectedReturn: // value for 'expectedReturn'
 *   },
 * });
 */
export function useCalcFutureValueGivenMcdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalcFutureValueGivenMcdMutation, CalcFutureValueGivenMcdMutationVariables>) {
        return ApolloReactHooks.useMutation<CalcFutureValueGivenMcdMutation, CalcFutureValueGivenMcdMutationVariables>(CalcFutureValueGivenMcdDocument, baseOptions);
      }
export type CalcFutureValueGivenMcdMutationHookResult = ReturnType<typeof useCalcFutureValueGivenMcdMutation>;
export type CalcFutureValueGivenMcdMutationResult = ApolloReactCommon.MutationResult<CalcFutureValueGivenMcdMutation>;
export type CalcFutureValueGivenMcdMutationOptions = ApolloReactCommon.BaseMutationOptions<CalcFutureValueGivenMcdMutation, CalcFutureValueGivenMcdMutationVariables>;
export const CalcMonthlyContributionDocument = gql`
    mutation calcMonthlyContribution($futureValue: Float!, $duration: Float!, $expectedReturn: Float!) {
  goalCalculations(data: {calcFor: "monthly_contribution", futureValue: $futureValue, duration: $duration, expectedReturn: $expectedReturn}) {
    value
  }
}
    `;
export type CalcMonthlyContributionMutationFn = ApolloReactCommon.MutationFunction<CalcMonthlyContributionMutation, CalcMonthlyContributionMutationVariables>;

/**
 * __useCalcMonthlyContributionMutation__
 *
 * To run a mutation, you first call `useCalcMonthlyContributionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalcMonthlyContributionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calcMonthlyContributionMutation, { data, loading, error }] = useCalcMonthlyContributionMutation({
 *   variables: {
 *      futureValue: // value for 'futureValue'
 *      duration: // value for 'duration'
 *      expectedReturn: // value for 'expectedReturn'
 *   },
 * });
 */
export function useCalcMonthlyContributionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalcMonthlyContributionMutation, CalcMonthlyContributionMutationVariables>) {
        return ApolloReactHooks.useMutation<CalcMonthlyContributionMutation, CalcMonthlyContributionMutationVariables>(CalcMonthlyContributionDocument, baseOptions);
      }
export type CalcMonthlyContributionMutationHookResult = ReturnType<typeof useCalcMonthlyContributionMutation>;
export type CalcMonthlyContributionMutationResult = ApolloReactCommon.MutationResult<CalcMonthlyContributionMutation>;
export type CalcMonthlyContributionMutationOptions = ApolloReactCommon.BaseMutationOptions<CalcMonthlyContributionMutation, CalcMonthlyContributionMutationVariables>;
export const CalcDurationDocument = gql`
    mutation calcDuration($futureValue: Float!, $monthlyContribution: Float!, $expectedReturn: Float!) {
  goalCalculations(data: {calcFor: "duration", futureValue: $futureValue, monthlyContribution: $monthlyContribution, expectedReturn: $expectedReturn}) {
    value
  }
}
    `;
export type CalcDurationMutationFn = ApolloReactCommon.MutationFunction<CalcDurationMutation, CalcDurationMutationVariables>;

/**
 * __useCalcDurationMutation__
 *
 * To run a mutation, you first call `useCalcDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalcDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calcDurationMutation, { data, loading, error }] = useCalcDurationMutation({
 *   variables: {
 *      futureValue: // value for 'futureValue'
 *      monthlyContribution: // value for 'monthlyContribution'
 *      expectedReturn: // value for 'expectedReturn'
 *   },
 * });
 */
export function useCalcDurationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalcDurationMutation, CalcDurationMutationVariables>) {
        return ApolloReactHooks.useMutation<CalcDurationMutation, CalcDurationMutationVariables>(CalcDurationDocument, baseOptions);
      }
export type CalcDurationMutationHookResult = ReturnType<typeof useCalcDurationMutation>;
export type CalcDurationMutationResult = ApolloReactCommon.MutationResult<CalcDurationMutation>;
export type CalcDurationMutationOptions = ApolloReactCommon.BaseMutationOptions<CalcDurationMutation, CalcDurationMutationVariables>;
export const IdentificationTypeDocument = gql`
    query IdentificationType {
  identification_type(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useIdentificationTypeQuery__
 *
 * To run a query within a React component, call `useIdentificationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentificationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentificationTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentificationTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IdentificationTypeQuery, IdentificationTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<IdentificationTypeQuery, IdentificationTypeQueryVariables>(IdentificationTypeDocument, baseOptions);
      }
export function useIdentificationTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IdentificationTypeQuery, IdentificationTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IdentificationTypeQuery, IdentificationTypeQueryVariables>(IdentificationTypeDocument, baseOptions);
        }
export type IdentificationTypeQueryHookResult = ReturnType<typeof useIdentificationTypeQuery>;
export type IdentificationTypeLazyQueryHookResult = ReturnType<typeof useIdentificationTypeLazyQuery>;
export type IdentificationTypeQueryResult = ApolloReactCommon.QueryResult<IdentificationTypeQuery, IdentificationTypeQueryVariables>;
export const Add_Identification_TypeDocument = gql`
    mutation add_identification_type($changes: [identification_type_insert_input!]!) {
  insert_identification_type(objects: $changes, on_conflict: {constraint: identification_type_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Identification_TypeMutationFn = ApolloReactCommon.MutationFunction<Add_Identification_TypeMutation, Add_Identification_TypeMutationVariables>;

/**
 * __useAdd_Identification_TypeMutation__
 *
 * To run a mutation, you first call `useAdd_Identification_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Identification_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdentificationTypeMutation, { data, loading, error }] = useAdd_Identification_TypeMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Identification_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Identification_TypeMutation, Add_Identification_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Identification_TypeMutation, Add_Identification_TypeMutationVariables>(Add_Identification_TypeDocument, baseOptions);
      }
export type Add_Identification_TypeMutationHookResult = ReturnType<typeof useAdd_Identification_TypeMutation>;
export type Add_Identification_TypeMutationResult = ApolloReactCommon.MutationResult<Add_Identification_TypeMutation>;
export type Add_Identification_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Identification_TypeMutation, Add_Identification_TypeMutationVariables>;
export const Update_Identification_TypeDocument = gql`
    mutation update_identification_type($id: bigint, $changes: identification_type_set_input) {
  update_identification_type(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Identification_TypeMutationFn = ApolloReactCommon.MutationFunction<Update_Identification_TypeMutation, Update_Identification_TypeMutationVariables>;

/**
 * __useUpdate_Identification_TypeMutation__
 *
 * To run a mutation, you first call `useUpdate_Identification_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Identification_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentificationTypeMutation, { data, loading, error }] = useUpdate_Identification_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Identification_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Identification_TypeMutation, Update_Identification_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Identification_TypeMutation, Update_Identification_TypeMutationVariables>(Update_Identification_TypeDocument, baseOptions);
      }
export type Update_Identification_TypeMutationHookResult = ReturnType<typeof useUpdate_Identification_TypeMutation>;
export type Update_Identification_TypeMutationResult = ApolloReactCommon.MutationResult<Update_Identification_TypeMutation>;
export type Update_Identification_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Identification_TypeMutation, Update_Identification_TypeMutationVariables>;
export const Delete_Identification_TypeDocument = gql`
    mutation delete_identification_type($id: bigint, $doneBy: bigint) {
  update_identification_type(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Identification_TypeMutationFn = ApolloReactCommon.MutationFunction<Delete_Identification_TypeMutation, Delete_Identification_TypeMutationVariables>;

/**
 * __useDelete_Identification_TypeMutation__
 *
 * To run a mutation, you first call `useDelete_Identification_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Identification_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIdentificationTypeMutation, { data, loading, error }] = useDelete_Identification_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Identification_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Identification_TypeMutation, Delete_Identification_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Identification_TypeMutation, Delete_Identification_TypeMutationVariables>(Delete_Identification_TypeDocument, baseOptions);
      }
export type Delete_Identification_TypeMutationHookResult = ReturnType<typeof useDelete_Identification_TypeMutation>;
export type Delete_Identification_TypeMutationResult = ApolloReactCommon.MutationResult<Delete_Identification_TypeMutation>;
export type Delete_Identification_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Identification_TypeMutation, Delete_Identification_TypeMutationVariables>;
export const IncomeSourceDocument = gql`
    query IncomeSource {
  income_source {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useIncomeSourceQuery__
 *
 * To run a query within a React component, call `useIncomeSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomeSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomeSourceQuery({
 *   variables: {
 *   },
 * });
 */
export function useIncomeSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncomeSourceQuery, IncomeSourceQueryVariables>) {
        return ApolloReactHooks.useQuery<IncomeSourceQuery, IncomeSourceQueryVariables>(IncomeSourceDocument, baseOptions);
      }
export function useIncomeSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncomeSourceQuery, IncomeSourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncomeSourceQuery, IncomeSourceQueryVariables>(IncomeSourceDocument, baseOptions);
        }
export type IncomeSourceQueryHookResult = ReturnType<typeof useIncomeSourceQuery>;
export type IncomeSourceLazyQueryHookResult = ReturnType<typeof useIncomeSourceLazyQuery>;
export type IncomeSourceQueryResult = ApolloReactCommon.QueryResult<IncomeSourceQuery, IncomeSourceQueryVariables>;
export const Add_Income_SourceDocument = gql`
    mutation add_income_source($changes: [income_source_insert_input!]!) {
  insert_income_source(objects: $changes, on_conflict: {constraint: income_source_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Income_SourceMutationFn = ApolloReactCommon.MutationFunction<Add_Income_SourceMutation, Add_Income_SourceMutationVariables>;

/**
 * __useAdd_Income_SourceMutation__
 *
 * To run a mutation, you first call `useAdd_Income_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Income_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIncomeSourceMutation, { data, loading, error }] = useAdd_Income_SourceMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Income_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Income_SourceMutation, Add_Income_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Income_SourceMutation, Add_Income_SourceMutationVariables>(Add_Income_SourceDocument, baseOptions);
      }
export type Add_Income_SourceMutationHookResult = ReturnType<typeof useAdd_Income_SourceMutation>;
export type Add_Income_SourceMutationResult = ApolloReactCommon.MutationResult<Add_Income_SourceMutation>;
export type Add_Income_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Income_SourceMutation, Add_Income_SourceMutationVariables>;
export const Update_Income_SourceDocument = gql`
    mutation update_income_source($id: bigint, $changes: income_source_set_input) {
  update_income_source(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Income_SourceMutationFn = ApolloReactCommon.MutationFunction<Update_Income_SourceMutation, Update_Income_SourceMutationVariables>;

/**
 * __useUpdate_Income_SourceMutation__
 *
 * To run a mutation, you first call `useUpdate_Income_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Income_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIncomeSourceMutation, { data, loading, error }] = useUpdate_Income_SourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Income_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Income_SourceMutation, Update_Income_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Income_SourceMutation, Update_Income_SourceMutationVariables>(Update_Income_SourceDocument, baseOptions);
      }
export type Update_Income_SourceMutationHookResult = ReturnType<typeof useUpdate_Income_SourceMutation>;
export type Update_Income_SourceMutationResult = ApolloReactCommon.MutationResult<Update_Income_SourceMutation>;
export type Update_Income_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Income_SourceMutation, Update_Income_SourceMutationVariables>;
export const Delete_Income_SourceDocument = gql`
    mutation delete_income_source($id: bigint, $doneBy: bigint) {
  update_income_source(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Income_SourceMutationFn = ApolloReactCommon.MutationFunction<Delete_Income_SourceMutation, Delete_Income_SourceMutationVariables>;

/**
 * __useDelete_Income_SourceMutation__
 *
 * To run a mutation, you first call `useDelete_Income_SourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Income_SourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIncomeSourceMutation, { data, loading, error }] = useDelete_Income_SourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Income_SourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Income_SourceMutation, Delete_Income_SourceMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Income_SourceMutation, Delete_Income_SourceMutationVariables>(Delete_Income_SourceDocument, baseOptions);
      }
export type Delete_Income_SourceMutationHookResult = ReturnType<typeof useDelete_Income_SourceMutation>;
export type Delete_Income_SourceMutationResult = ApolloReactCommon.MutationResult<Delete_Income_SourceMutation>;
export type Delete_Income_SourceMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Income_SourceMutation, Delete_Income_SourceMutationVariables>;
export const IndustriesDocument = gql`
    query Industries {
  industry(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
        return ApolloReactHooks.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, baseOptions);
      }
export function useIndustriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, baseOptions);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = ApolloReactCommon.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export const Add_IndustryDocument = gql`
    mutation add_industry($changes: [industry_insert_input!]!) {
  insert_industry(objects: $changes, on_conflict: {constraint: industry_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_IndustryMutationFn = ApolloReactCommon.MutationFunction<Add_IndustryMutation, Add_IndustryMutationVariables>;

/**
 * __useAdd_IndustryMutation__
 *
 * To run a mutation, you first call `useAdd_IndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_IndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIndustryMutation, { data, loading, error }] = useAdd_IndustryMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_IndustryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_IndustryMutation, Add_IndustryMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_IndustryMutation, Add_IndustryMutationVariables>(Add_IndustryDocument, baseOptions);
      }
export type Add_IndustryMutationHookResult = ReturnType<typeof useAdd_IndustryMutation>;
export type Add_IndustryMutationResult = ApolloReactCommon.MutationResult<Add_IndustryMutation>;
export type Add_IndustryMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_IndustryMutation, Add_IndustryMutationVariables>;
export const Update_IndustryDocument = gql`
    mutation update_industry($id: bigint, $changes: industry_set_input) {
  update_industry(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_IndustryMutationFn = ApolloReactCommon.MutationFunction<Update_IndustryMutation, Update_IndustryMutationVariables>;

/**
 * __useUpdate_IndustryMutation__
 *
 * To run a mutation, you first call `useUpdate_IndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_IndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndustryMutation, { data, loading, error }] = useUpdate_IndustryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_IndustryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_IndustryMutation, Update_IndustryMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_IndustryMutation, Update_IndustryMutationVariables>(Update_IndustryDocument, baseOptions);
      }
export type Update_IndustryMutationHookResult = ReturnType<typeof useUpdate_IndustryMutation>;
export type Update_IndustryMutationResult = ApolloReactCommon.MutationResult<Update_IndustryMutation>;
export type Update_IndustryMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_IndustryMutation, Update_IndustryMutationVariables>;
export const Delete_IndustryDocument = gql`
    mutation delete_industry($id: bigint, $doneBy: bigint) {
  update_industry(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_IndustryMutationFn = ApolloReactCommon.MutationFunction<Delete_IndustryMutation, Delete_IndustryMutationVariables>;

/**
 * __useDelete_IndustryMutation__
 *
 * To run a mutation, you first call `useDelete_IndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_IndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndustryMutation, { data, loading, error }] = useDelete_IndustryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_IndustryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_IndustryMutation, Delete_IndustryMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_IndustryMutation, Delete_IndustryMutationVariables>(Delete_IndustryDocument, baseOptions);
      }
export type Delete_IndustryMutationHookResult = ReturnType<typeof useDelete_IndustryMutation>;
export type Delete_IndustryMutationResult = ApolloReactCommon.MutationResult<Delete_IndustryMutation>;
export type Delete_IndustryMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_IndustryMutation, Delete_IndustryMutationVariables>;
export const GetInsurancePremiumDocument = gql`
    mutation getInsurancePremium($sumAssured: Float!, $age: Int!, $duration: Float!, $insuranceTypeId: Int!) {
  calculateInsurancePremium(data: {age: $age, duration: $duration, insuranceTypeId: $insuranceTypeId, sumAssured: $sumAssured}) {
    rate
    monthlyPremium
  }
}
    `;
export type GetInsurancePremiumMutationFn = ApolloReactCommon.MutationFunction<GetInsurancePremiumMutation, GetInsurancePremiumMutationVariables>;

/**
 * __useGetInsurancePremiumMutation__
 *
 * To run a mutation, you first call `useGetInsurancePremiumMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInsurancePremiumMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInsurancePremiumMutation, { data, loading, error }] = useGetInsurancePremiumMutation({
 *   variables: {
 *      sumAssured: // value for 'sumAssured'
 *      age: // value for 'age'
 *      duration: // value for 'duration'
 *      insuranceTypeId: // value for 'insuranceTypeId'
 *   },
 * });
 */
export function useGetInsurancePremiumMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GetInsurancePremiumMutation, GetInsurancePremiumMutationVariables>) {
        return ApolloReactHooks.useMutation<GetInsurancePremiumMutation, GetInsurancePremiumMutationVariables>(GetInsurancePremiumDocument, baseOptions);
      }
export type GetInsurancePremiumMutationHookResult = ReturnType<typeof useGetInsurancePremiumMutation>;
export type GetInsurancePremiumMutationResult = ApolloReactCommon.MutationResult<GetInsurancePremiumMutation>;
export type GetInsurancePremiumMutationOptions = ApolloReactCommon.BaseMutationOptions<GetInsurancePremiumMutation, GetInsurancePremiumMutationVariables>;
export const Insurance_TypeDocument = gql`
    query Insurance_type {
  insurance_type(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useInsurance_TypeQuery__
 *
 * To run a query within a React component, call `useInsurance_TypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsurance_TypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsurance_TypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useInsurance_TypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Insurance_TypeQuery, Insurance_TypeQueryVariables>) {
        return ApolloReactHooks.useQuery<Insurance_TypeQuery, Insurance_TypeQueryVariables>(Insurance_TypeDocument, baseOptions);
      }
export function useInsurance_TypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Insurance_TypeQuery, Insurance_TypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Insurance_TypeQuery, Insurance_TypeQueryVariables>(Insurance_TypeDocument, baseOptions);
        }
export type Insurance_TypeQueryHookResult = ReturnType<typeof useInsurance_TypeQuery>;
export type Insurance_TypeLazyQueryHookResult = ReturnType<typeof useInsurance_TypeLazyQuery>;
export type Insurance_TypeQueryResult = ApolloReactCommon.QueryResult<Insurance_TypeQuery, Insurance_TypeQueryVariables>;
export const Add_Insurance_TypeDocument = gql`
    mutation add_insurance_type($changes: [insurance_type_insert_input!]!) {
  insert_insurance_type(objects: $changes, on_conflict: {constraint: insurance_type_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Insurance_TypeMutationFn = ApolloReactCommon.MutationFunction<Add_Insurance_TypeMutation, Add_Insurance_TypeMutationVariables>;

/**
 * __useAdd_Insurance_TypeMutation__
 *
 * To run a mutation, you first call `useAdd_Insurance_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Insurance_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInsuranceTypeMutation, { data, loading, error }] = useAdd_Insurance_TypeMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Insurance_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Insurance_TypeMutation, Add_Insurance_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Insurance_TypeMutation, Add_Insurance_TypeMutationVariables>(Add_Insurance_TypeDocument, baseOptions);
      }
export type Add_Insurance_TypeMutationHookResult = ReturnType<typeof useAdd_Insurance_TypeMutation>;
export type Add_Insurance_TypeMutationResult = ApolloReactCommon.MutationResult<Add_Insurance_TypeMutation>;
export type Add_Insurance_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Insurance_TypeMutation, Add_Insurance_TypeMutationVariables>;
export const Update_Insurance_TypeDocument = gql`
    mutation update_insurance_type($id: bigint, $changes: insurance_type_set_input) {
  update_insurance_type(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Insurance_TypeMutationFn = ApolloReactCommon.MutationFunction<Update_Insurance_TypeMutation, Update_Insurance_TypeMutationVariables>;

/**
 * __useUpdate_Insurance_TypeMutation__
 *
 * To run a mutation, you first call `useUpdate_Insurance_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Insurance_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceTypeMutation, { data, loading, error }] = useUpdate_Insurance_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Insurance_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Insurance_TypeMutation, Update_Insurance_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Insurance_TypeMutation, Update_Insurance_TypeMutationVariables>(Update_Insurance_TypeDocument, baseOptions);
      }
export type Update_Insurance_TypeMutationHookResult = ReturnType<typeof useUpdate_Insurance_TypeMutation>;
export type Update_Insurance_TypeMutationResult = ApolloReactCommon.MutationResult<Update_Insurance_TypeMutation>;
export type Update_Insurance_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Insurance_TypeMutation, Update_Insurance_TypeMutationVariables>;
export const Delete_Insurance_TypeDocument = gql`
    mutation delete_insurance_type($id: bigint, $doneBy: bigint) {
  update_insurance_type(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Insurance_TypeMutationFn = ApolloReactCommon.MutationFunction<Delete_Insurance_TypeMutation, Delete_Insurance_TypeMutationVariables>;

/**
 * __useDelete_Insurance_TypeMutation__
 *
 * To run a mutation, you first call `useDelete_Insurance_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Insurance_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceTypeMutation, { data, loading, error }] = useDelete_Insurance_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Insurance_TypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Insurance_TypeMutation, Delete_Insurance_TypeMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Insurance_TypeMutation, Delete_Insurance_TypeMutationVariables>(Delete_Insurance_TypeDocument, baseOptions);
      }
export type Delete_Insurance_TypeMutationHookResult = ReturnType<typeof useDelete_Insurance_TypeMutation>;
export type Delete_Insurance_TypeMutationResult = ApolloReactCommon.MutationResult<Delete_Insurance_TypeMutation>;
export type Delete_Insurance_TypeMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Insurance_TypeMutation, Delete_Insurance_TypeMutationVariables>;
export const LoginDocument = gql`
    mutation Login($usernameOrEmail: String!, $password: String!) {
  login(data: {usernameOrEmail: $usernameOrEmail, password: $password}) {
    id
    accessToken
    shouldUpdatePassword
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MaritalStatusDocument = gql`
    query MaritalStatus {
  marital_status(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useMaritalStatusQuery__
 *
 * To run a query within a React component, call `useMaritalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaritalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaritalStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaritalStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MaritalStatusQuery, MaritalStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<MaritalStatusQuery, MaritalStatusQueryVariables>(MaritalStatusDocument, baseOptions);
      }
export function useMaritalStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaritalStatusQuery, MaritalStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MaritalStatusQuery, MaritalStatusQueryVariables>(MaritalStatusDocument, baseOptions);
        }
export type MaritalStatusQueryHookResult = ReturnType<typeof useMaritalStatusQuery>;
export type MaritalStatusLazyQueryHookResult = ReturnType<typeof useMaritalStatusLazyQuery>;
export type MaritalStatusQueryResult = ApolloReactCommon.QueryResult<MaritalStatusQuery, MaritalStatusQueryVariables>;
export const Add_Marital_StatusDocument = gql`
    mutation add_marital_status($changes: [marital_status_insert_input!]!) {
  insert_marital_status(objects: $changes, on_conflict: {constraint: marital_status_name_key, update_columns: [name, modified_by, deleted]}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Marital_StatusMutationFn = ApolloReactCommon.MutationFunction<Add_Marital_StatusMutation, Add_Marital_StatusMutationVariables>;

/**
 * __useAdd_Marital_StatusMutation__
 *
 * To run a mutation, you first call `useAdd_Marital_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Marital_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMaritalStatusMutation, { data, loading, error }] = useAdd_Marital_StatusMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Marital_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Marital_StatusMutation, Add_Marital_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Marital_StatusMutation, Add_Marital_StatusMutationVariables>(Add_Marital_StatusDocument, baseOptions);
      }
export type Add_Marital_StatusMutationHookResult = ReturnType<typeof useAdd_Marital_StatusMutation>;
export type Add_Marital_StatusMutationResult = ApolloReactCommon.MutationResult<Add_Marital_StatusMutation>;
export type Add_Marital_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Marital_StatusMutation, Add_Marital_StatusMutationVariables>;
export const Update_Marital_StatusDocument = gql`
    mutation update_marital_status($id: bigint, $changes: marital_status_set_input) {
  update_marital_status(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Marital_StatusMutationFn = ApolloReactCommon.MutationFunction<Update_Marital_StatusMutation, Update_Marital_StatusMutationVariables>;

/**
 * __useUpdate_Marital_StatusMutation__
 *
 * To run a mutation, you first call `useUpdate_Marital_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Marital_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaritalStatusMutation, { data, loading, error }] = useUpdate_Marital_StatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Marital_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Marital_StatusMutation, Update_Marital_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Marital_StatusMutation, Update_Marital_StatusMutationVariables>(Update_Marital_StatusDocument, baseOptions);
      }
export type Update_Marital_StatusMutationHookResult = ReturnType<typeof useUpdate_Marital_StatusMutation>;
export type Update_Marital_StatusMutationResult = ApolloReactCommon.MutationResult<Update_Marital_StatusMutation>;
export type Update_Marital_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Marital_StatusMutation, Update_Marital_StatusMutationVariables>;
export const Delete_Marital_StatusDocument = gql`
    mutation delete_marital_status($id: bigint, $doneBy: bigint) {
  update_marital_status(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Marital_StatusMutationFn = ApolloReactCommon.MutationFunction<Delete_Marital_StatusMutation, Delete_Marital_StatusMutationVariables>;

/**
 * __useDelete_Marital_StatusMutation__
 *
 * To run a mutation, you first call `useDelete_Marital_StatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Marital_StatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaritalStatusMutation, { data, loading, error }] = useDelete_Marital_StatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Marital_StatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Marital_StatusMutation, Delete_Marital_StatusMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Marital_StatusMutation, Delete_Marital_StatusMutationVariables>(Delete_Marital_StatusDocument, baseOptions);
      }
export type Delete_Marital_StatusMutationHookResult = ReturnType<typeof useDelete_Marital_StatusMutation>;
export type Delete_Marital_StatusMutationResult = ApolloReactCommon.MutationResult<Delete_Marital_StatusMutation>;
export type Delete_Marital_StatusMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Marital_StatusMutation, Delete_Marital_StatusMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    user_type {
      email
      fullname
      id
      guid
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<MeQuery, MeQueryVariables>;
export const Update_Member_SignatureDocument = gql`
    mutation update_member_signature($id: bigint, $signature: String) {
  __typename
  update_member(where: {id: {_eq: $id}}, _set: {signature: $signature}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type Update_Member_SignatureMutationFn = ApolloReactCommon.MutationFunction<Update_Member_SignatureMutation, Update_Member_SignatureMutationVariables>;

/**
 * __useUpdate_Member_SignatureMutation__
 *
 * To run a mutation, you first call `useUpdate_Member_SignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Member_SignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberSignatureMutation, { data, loading, error }] = useUpdate_Member_SignatureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useUpdate_Member_SignatureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Member_SignatureMutation, Update_Member_SignatureMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Member_SignatureMutation, Update_Member_SignatureMutationVariables>(Update_Member_SignatureDocument, baseOptions);
      }
export type Update_Member_SignatureMutationHookResult = ReturnType<typeof useUpdate_Member_SignatureMutation>;
export type Update_Member_SignatureMutationResult = ApolloReactCommon.MutationResult<Update_Member_SignatureMutation>;
export type Update_Member_SignatureMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Member_SignatureMutation, Update_Member_SignatureMutationVariables>;
export const Convert_Prospect_CustomerDocument = gql`
    mutation convert_prospect_customer($id: Int!, $isDirect: Boolean) {
  createCustomer(data: {id: $id, isDirect: $isDirect}) {
    success
    errors {
      message
    }
  }
}
    `;
export type Convert_Prospect_CustomerMutationFn = ApolloReactCommon.MutationFunction<Convert_Prospect_CustomerMutation, Convert_Prospect_CustomerMutationVariables>;

/**
 * __useConvert_Prospect_CustomerMutation__
 *
 * To run a mutation, you first call `useConvert_Prospect_CustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvert_Prospect_CustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertProspectCustomerMutation, { data, loading, error }] = useConvert_Prospect_CustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDirect: // value for 'isDirect'
 *   },
 * });
 */
export function useConvert_Prospect_CustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Convert_Prospect_CustomerMutation, Convert_Prospect_CustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<Convert_Prospect_CustomerMutation, Convert_Prospect_CustomerMutationVariables>(Convert_Prospect_CustomerDocument, baseOptions);
      }
export type Convert_Prospect_CustomerMutationHookResult = ReturnType<typeof useConvert_Prospect_CustomerMutation>;
export type Convert_Prospect_CustomerMutationResult = ApolloReactCommon.MutationResult<Convert_Prospect_CustomerMutation>;
export type Convert_Prospect_CustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<Convert_Prospect_CustomerMutation, Convert_Prospect_CustomerMutationVariables>;
export const Send_Welcome_PackDocument = gql`
    mutation send_welcome_pack($id: Int!) {
  sendWelcomePack(data: {id: $id}) {
    success
  }
}
    `;
export type Send_Welcome_PackMutationFn = ApolloReactCommon.MutationFunction<Send_Welcome_PackMutation, Send_Welcome_PackMutationVariables>;

/**
 * __useSend_Welcome_PackMutation__
 *
 * To run a mutation, you first call `useSend_Welcome_PackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSend_Welcome_PackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWelcomePackMutation, { data, loading, error }] = useSend_Welcome_PackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSend_Welcome_PackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Send_Welcome_PackMutation, Send_Welcome_PackMutationVariables>) {
        return ApolloReactHooks.useMutation<Send_Welcome_PackMutation, Send_Welcome_PackMutationVariables>(Send_Welcome_PackDocument, baseOptions);
      }
export type Send_Welcome_PackMutationHookResult = ReturnType<typeof useSend_Welcome_PackMutation>;
export type Send_Welcome_PackMutationResult = ApolloReactCommon.MutationResult<Send_Welcome_PackMutation>;
export type Send_Welcome_PackMutationOptions = ApolloReactCommon.BaseMutationOptions<Send_Welcome_PackMutation, Send_Welcome_PackMutationVariables>;
export const Reject_ProspectDocument = gql`
    mutation reject_prospect($id: bigint!, $reason: String!, $doneBy: bigint!) {
  __typename
  insert_member_reject(objects: {member_id: $id, reason: $reason, created_by: $doneBy}) {
    affected_rows
  }
  update_member(where: {id: {_eq: $id}}, _set: {status_id: 5}) {
    returning {
      id
      psl_code
      nationality
      status {
        id
        name
      }
      tin
      status {
        id
        name
      }
      assigned_to_user {
        id
        first_name
        last_name
        other_name
      }
      user {
        id
        first_name
        last_name
        other_name
        user_advisor {
          advisor_code
        }
        gender {
          id
          name
        }
      }
    }
  }
}
    `;
export type Reject_ProspectMutationFn = ApolloReactCommon.MutationFunction<Reject_ProspectMutation, Reject_ProspectMutationVariables>;

/**
 * __useReject_ProspectMutation__
 *
 * To run a mutation, you first call `useReject_ProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReject_ProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectProspectMutation, { data, loading, error }] = useReject_ProspectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useReject_ProspectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Reject_ProspectMutation, Reject_ProspectMutationVariables>) {
        return ApolloReactHooks.useMutation<Reject_ProspectMutation, Reject_ProspectMutationVariables>(Reject_ProspectDocument, baseOptions);
      }
export type Reject_ProspectMutationHookResult = ReturnType<typeof useReject_ProspectMutation>;
export type Reject_ProspectMutationResult = ApolloReactCommon.MutationResult<Reject_ProspectMutation>;
export type Reject_ProspectMutationOptions = ApolloReactCommon.BaseMutationOptions<Reject_ProspectMutation, Reject_ProspectMutationVariables>;
export const Update_User_DetailDocument = gql`
    mutation update_user_detail($id: bigint, $member_changes: member_set_input, $user_changes: user_set_input, $member_next_of_kin_id: bigint, $member_next_of_kin_changes: next_of_kin_set_input, $member_client_investment_profile_id: bigint, $member_client_investment_profile_changes: client_investment_profile_set_input, $member_identification_changes: member_identification_set_input, $member_goals_changes: [member_goal_insert_input!]!, $member_beneficiary_changes: [member_beneficiary_insert_input!]!, $member_payment_instruction_changes: [member_payment_instruction_insert_input!]!, $member_mode_of_instruction_changes: [member_mode_of_instruction_communication_insert_input!]!, $member_income_source_change: [member_income_source_insert_input!]!, $member_contribution_source_change: [member_contribution_source_insert_input!]!, $member_dependent_changes: [member_dependent_insert_input!]!) {
  __typename
  update_user(where: {id: {_eq: $id}}, _set: $user_changes) {
    affected_rows
  }
  update_next_of_kin(where: {id: {_eq: $member_next_of_kin_id}}, _set: $member_next_of_kin_changes) {
    affected_rows
  }
  update_client_investment_profile(where: {id: {_eq: $member_client_investment_profile_id}}, _set: $member_client_investment_profile_changes) {
    affected_rows
  }
  update_member_identification(where: {member_id: {_eq: $id}}, _set: $member_identification_changes) {
    affected_rows
  }
  insert_member_beneficiary(objects: $member_beneficiary_changes, on_conflict: {constraint: member_beneficiary_guid_key, update_columns: [first_name, other_names, last_name, date_of_birth, home_address, mobile, relationship_id, gender_id, email, allocation, modified_by, deleted, residential_status, residential_place_of_issue, residential_permit_number, country_of_origin_id, region_id, permit_expiry_date, permit_issue_date, occupation, profession, mobile2, gps_address, title, place_of_birth, marital_status, tin, id_type, id_number, id_expiry_date, id_issue_date, professional_license]}) {
    affected_rows
  }
  insert_member_dependent(objects: $member_dependent_changes, on_conflict: {constraint: member_dependent_guid_key, update_columns: [name, age, modified_by, deleted]}) {
    affected_rows
  }
  insert_member_goal(objects: $member_goals_changes, on_conflict: {constraint: member_goal_guid_key, update_columns: [accepted, duration_months, duration_years, expected_return, has_insurance, goal_id, monthly_contribution, future_value, current_value, goal_for, administrative_fees, years_to_fund, modified_by, deleted]}) {
    affected_rows
  }
  insert_member_payment_instruction(objects: $member_payment_instruction_changes, on_conflict: {constraint: member_payment_instruction_guid_key, update_columns: [account_name, account_number, branch_id, payment_provider_id, preferred, mobile_money_number, modified_by, deleted]}) {
    affected_rows
  }
  insert_member_mode_of_instruction_communication(objects: $member_mode_of_instruction_changes, on_conflict: {constraint: member_mode_of_instruction_communication_mode_of_instruction_co, update_columns: [mode_of_instruction_communication_id, deleted]}) {
    affected_rows
  }
  delete_member_income_source(where: {member_id: {_eq: $id}}) {
    affected_rows
  }
  delete_member_contribution_source(where: {member_id: {_eq: $id}}) {
    affected_rows
  }
  insert_member_income_source(objects: $member_income_source_change) {
    affected_rows
  }
  insert_member_contribution_source(objects: $member_contribution_source_change) {
    affected_rows
  }
  update_member(where: {id: {_eq: $id}}, _set: $member_changes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type Update_User_DetailMutationFn = ApolloReactCommon.MutationFunction<Update_User_DetailMutation, Update_User_DetailMutationVariables>;

/**
 * __useUpdate_User_DetailMutation__
 *
 * To run a mutation, you first call `useUpdate_User_DetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_User_DetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailMutation, { data, loading, error }] = useUpdate_User_DetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      member_changes: // value for 'member_changes'
 *      user_changes: // value for 'user_changes'
 *      member_next_of_kin_id: // value for 'member_next_of_kin_id'
 *      member_next_of_kin_changes: // value for 'member_next_of_kin_changes'
 *      member_client_investment_profile_id: // value for 'member_client_investment_profile_id'
 *      member_client_investment_profile_changes: // value for 'member_client_investment_profile_changes'
 *      member_identification_changes: // value for 'member_identification_changes'
 *      member_goals_changes: // value for 'member_goals_changes'
 *      member_beneficiary_changes: // value for 'member_beneficiary_changes'
 *      member_payment_instruction_changes: // value for 'member_payment_instruction_changes'
 *      member_mode_of_instruction_changes: // value for 'member_mode_of_instruction_changes'
 *      member_income_source_change: // value for 'member_income_source_change'
 *      member_contribution_source_change: // value for 'member_contribution_source_change'
 *      member_dependent_changes: // value for 'member_dependent_changes'
 *   },
 * });
 */
export function useUpdate_User_DetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_User_DetailMutation, Update_User_DetailMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_User_DetailMutation, Update_User_DetailMutationVariables>(Update_User_DetailDocument, baseOptions);
      }
export type Update_User_DetailMutationHookResult = ReturnType<typeof useUpdate_User_DetailMutation>;
export type Update_User_DetailMutationResult = ApolloReactCommon.MutationResult<Update_User_DetailMutation>;
export type Update_User_DetailMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_User_DetailMutation, Update_User_DetailMutationVariables>;
export const Delete_BeneficiaryDocument = gql`
    mutation delete_beneficiary($guid: uuid) {
  update_member_beneficiary(where: {guid: {_eq: $guid}}, _set: {deleted: true}) {
    affected_rows
    returning {
      guid
    }
  }
}
    `;
export type Delete_BeneficiaryMutationFn = ApolloReactCommon.MutationFunction<Delete_BeneficiaryMutation, Delete_BeneficiaryMutationVariables>;

/**
 * __useDelete_BeneficiaryMutation__
 *
 * To run a mutation, you first call `useDelete_BeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_BeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBeneficiaryMutation, { data, loading, error }] = useDelete_BeneficiaryMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useDelete_BeneficiaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_BeneficiaryMutation, Delete_BeneficiaryMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_BeneficiaryMutation, Delete_BeneficiaryMutationVariables>(Delete_BeneficiaryDocument, baseOptions);
      }
export type Delete_BeneficiaryMutationHookResult = ReturnType<typeof useDelete_BeneficiaryMutation>;
export type Delete_BeneficiaryMutationResult = ApolloReactCommon.MutationResult<Delete_BeneficiaryMutation>;
export type Delete_BeneficiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_BeneficiaryMutation, Delete_BeneficiaryMutationVariables>;
export const Delete_DependentDocument = gql`
    mutation delete_dependent($guid: uuid) {
  update_member_dependent(where: {guid: {_eq: $guid}}, _set: {deleted: true}) {
    affected_rows
    returning {
      guid
    }
  }
}
    `;
export type Delete_DependentMutationFn = ApolloReactCommon.MutationFunction<Delete_DependentMutation, Delete_DependentMutationVariables>;

/**
 * __useDelete_DependentMutation__
 *
 * To run a mutation, you first call `useDelete_DependentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_DependentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDependentMutation, { data, loading, error }] = useDelete_DependentMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useDelete_DependentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_DependentMutation, Delete_DependentMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_DependentMutation, Delete_DependentMutationVariables>(Delete_DependentDocument, baseOptions);
      }
export type Delete_DependentMutationHookResult = ReturnType<typeof useDelete_DependentMutation>;
export type Delete_DependentMutationResult = ApolloReactCommon.MutationResult<Delete_DependentMutation>;
export type Delete_DependentMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_DependentMutation, Delete_DependentMutationVariables>;
export const Delete_PaymentDocument = gql`
    mutation delete_payment($guid: uuid) {
  update_member_payment_instruction(where: {guid: {_eq: $guid}}, _set: {deleted: true}) {
    affected_rows
    returning {
      guid
    }
  }
}
    `;
export type Delete_PaymentMutationFn = ApolloReactCommon.MutationFunction<Delete_PaymentMutation, Delete_PaymentMutationVariables>;

/**
 * __useDelete_PaymentMutation__
 *
 * To run a mutation, you first call `useDelete_PaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_PaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDelete_PaymentMutation({
 *   variables: {
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useDelete_PaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_PaymentMutation, Delete_PaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_PaymentMutation, Delete_PaymentMutationVariables>(Delete_PaymentDocument, baseOptions);
      }
export type Delete_PaymentMutationHookResult = ReturnType<typeof useDelete_PaymentMutation>;
export type Delete_PaymentMutationResult = ApolloReactCommon.MutationResult<Delete_PaymentMutation>;
export type Delete_PaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_PaymentMutation, Delete_PaymentMutationVariables>;
export const MemberSearchDocument = gql`
    query MemberSearch($searchKey: String!) {
  member(where: {user: {deleted: {_eq: false}, _or: [{first_name: {_ilike: $searchKey}}, {last_name: {_ilike: $searchKey}}, {last_name: {_ilike: $searchKey}}]}}) {
    id
    is_customer
    psl_code
    nationality
    status {
      id
      name
    }
    tin
    status {
      id
      name
    }
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    user {
      id
      first_name
      last_name
      other_name
      user_advisor {
        advisor_code
      }
      gender {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMemberSearchQuery__
 *
 * To run a query within a React component, call `useMemberSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberSearchQuery({
 *   variables: {
 *      searchKey: // value for 'searchKey'
 *   },
 * });
 */
export function useMemberSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<MemberSearchQuery, MemberSearchQueryVariables>(MemberSearchDocument, baseOptions);
      }
export function useMemberSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MemberSearchQuery, MemberSearchQueryVariables>(MemberSearchDocument, baseOptions);
        }
export type MemberSearchQueryHookResult = ReturnType<typeof useMemberSearchQuery>;
export type MemberSearchLazyQueryHookResult = ReturnType<typeof useMemberSearchLazyQuery>;
export type MemberSearchQueryResult = ApolloReactCommon.QueryResult<MemberSearchQuery, MemberSearchQueryVariables>;
export const InsertMembersDocument = gql`
    mutation insertMembers($data: [member_insert_input!]!) {
  insert_member(objects: $data) {
    affected_rows
    returning {
      id
      account_type
      is_customer
      age
      title_id
      marital_status_id
      marital_status_id_jt
      place_of_birth
      place_of_birth_jt
      user {
        id
        first_name
        first_name_jt
        other_name
        other_name_jt
        last_name
        last_name_jt
        mobile
        mobile_jt
        mobile_jt2
        email
        email_jt
        date_of_birth
        date_of_birth_jt
        gender_id
        gender_id_jt
        created_at
      }
      industry_id
      status_id
      city
      nationality
      mf_code
      mobile_secondary
      nationality
      occupation
      occupation_jt
      postal_address
      psl_code
      signature
      tin
      tin_jt
      home_address
      home_address_jt
      employer
      employer_jt
      city
      monthly_income
      current_savings
      permit_expiry_date
      permit_expiry_date_jt
      permit_issue_date
      permit_issue_date_jt
      regular_top_up_amount
      initial_investment_amount
      regular_withdrawal_amount
      residential_permit_number
      residential_permit_number_jt
      residential_place_of_issue
      residential_place_of_issue_jt
      country_of_origin_id
      country_of_origin_id_jt
      residential_status
      residential_status_jt
      anticipated_investment_activity_top_ups
      anticipated_investment_activity_top_ups_other
      anticipated_investment_activity_withdrawals
      anticipated_investment_activity_withdrawals_other
      employer_address
      employer_address_jt
      employer_city
      employer_city_jt
      employer_contact
      employer_contact_jt
      employer_email
      employer_email_jt
      employment_status
      employment_status_jt
      nature_of_business
      nature_of_business_jt
      years_of_current_employment
      years_of_current_employment_jt
      years_of_employment
      years_of_employment_jt
      years_of_previous_employment
      years_of_previous_employment_jt
      professional_license_number
      professional_license_number_jt
      mode_of_statement_delivery
      statement_frequency
      account_mandate
      profession
      profession_jt
      gps_address
      gps_address_jt
      client_investment_profile {
        investment_objective
        investment_knowledge
        risk_tolerance
        investment_horizon
        approximate_annual_income
      }
      beneficiaries {
        id
        guid
        first_name
        home_address
        last_name
        deleted
        gender_id
        email
        date_of_birth
        mobile
        other_names
        relationship_id
        allocation
        deleted
        residential_status
        residential_place_of_issue
        residential_permit_number
        country_of_origin_id
        permit_expiry_date
        permit_issue_date
        occupation
        profession
        mobile2
        gps_address
        title
        place_of_birth
        marital_status
        tin
        id_type
        id_number
        id_expiry_date
        id_issue_date
        professional_license
        region {
          id
          name
        }
      }
      next_of_kin {
        id
        guid
        first_name
        last_name
        email
        date_of_birth
        deleted
        mobile
        other_names
        home_address
        gender_id
        relationship_id
        residential_status
        residential_place_of_issue
        residential_permit_number
        country_of_origin_id
        permit_expiry_date
        permit_issue_date
        occupation
        profession
        mobile2
        gps_address
        title
        place_of_birth
        marital_status
        tin
        id_type
        id_number
        id_expiry_date
        id_issue_date
        professional_license
        region {
          id
          name
        }
      }
      member_dependents {
        id
        guid
        name
        age
        deleted
      }
      member_identifications {
        id
        guid
        identification_type_id
        issue_country_id
        identification_card_image_url
        identification_number
        identification_expiry_date
        identification_issue_date
        place_of_issue
        deleted
      }
      member_income_sources {
        id
        guid
        income_source_id
      }
      member_contribution_sources {
        id
        guid
        contribution_source_id
      }
      member_goals {
        id
        guid
        goal_id
        goal_for
        accepted
        accepted_on
        current_value
        future_value
        duration_years
        duration_months
        monthly_contribution
        administrative_fees
        expected_return
        has_insurance
        goal_insurances {
          id
          insurance_premium
          insurance_rate_per_1000
          insurance_type_id
        }
        deleted
        created_at
      }
      member_payment_instructions {
        id
        guid
        payment_provider_id
        account_number
        account_name
        branch_id
        mobile_money_number
        preferred
        deleted
      }
      member_mode_of_instruction_communications {
        id
        guid
        mode_of_instruction_communication_id
      }
      region {
        id
        name
      }
    }
  }
}
    `;
export type InsertMembersMutationFn = ApolloReactCommon.MutationFunction<InsertMembersMutation, InsertMembersMutationVariables>;

/**
 * __useInsertMembersMutation__
 *
 * To run a mutation, you first call `useInsertMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMembersMutation, { data, loading, error }] = useInsertMembersMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInsertMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertMembersMutation, InsertMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertMembersMutation, InsertMembersMutationVariables>(InsertMembersDocument, baseOptions);
      }
export type InsertMembersMutationHookResult = ReturnType<typeof useInsertMembersMutation>;
export type InsertMembersMutationResult = ApolloReactCommon.MutationResult<InsertMembersMutation>;
export type InsertMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertMembersMutation, InsertMembersMutationVariables>;
export const ModeofInstructionCommunicationDocument = gql`
    query ModeofInstructionCommunication {
  mode_of_instruction_communication {
    id
    name
  }
}
    `;

/**
 * __useModeofInstructionCommunicationQuery__
 *
 * To run a query within a React component, call `useModeofInstructionCommunicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useModeofInstructionCommunicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModeofInstructionCommunicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useModeofInstructionCommunicationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModeofInstructionCommunicationQuery, ModeofInstructionCommunicationQueryVariables>) {
        return ApolloReactHooks.useQuery<ModeofInstructionCommunicationQuery, ModeofInstructionCommunicationQueryVariables>(ModeofInstructionCommunicationDocument, baseOptions);
      }
export function useModeofInstructionCommunicationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModeofInstructionCommunicationQuery, ModeofInstructionCommunicationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModeofInstructionCommunicationQuery, ModeofInstructionCommunicationQueryVariables>(ModeofInstructionCommunicationDocument, baseOptions);
        }
export type ModeofInstructionCommunicationQueryHookResult = ReturnType<typeof useModeofInstructionCommunicationQuery>;
export type ModeofInstructionCommunicationLazyQueryHookResult = ReturnType<typeof useModeofInstructionCommunicationLazyQuery>;
export type ModeofInstructionCommunicationQueryResult = ApolloReactCommon.QueryResult<ModeofInstructionCommunicationQuery, ModeofInstructionCommunicationQueryVariables>;
export const OccupationDocument = gql`
    query Occupation {
  gender {
    id
    name
  }
}
    `;

/**
 * __useOccupationQuery__
 *
 * To run a query within a React component, call `useOccupationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOccupationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOccupationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOccupationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OccupationQuery, OccupationQueryVariables>) {
        return ApolloReactHooks.useQuery<OccupationQuery, OccupationQueryVariables>(OccupationDocument, baseOptions);
      }
export function useOccupationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OccupationQuery, OccupationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OccupationQuery, OccupationQueryVariables>(OccupationDocument, baseOptions);
        }
export type OccupationQueryHookResult = ReturnType<typeof useOccupationQuery>;
export type OccupationLazyQueryHookResult = ReturnType<typeof useOccupationLazyQuery>;
export type OccupationQueryResult = ApolloReactCommon.QueryResult<OccupationQuery, OccupationQueryVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($usernameOrEmail: String!, $newPassword: String!) {
  resetPassword(data: {usernameOrEmail: $usernameOrEmail, newPassword: $newPassword}) {
    id
    accessToken
  }
}
    `;
export type PasswordResetMutationFn = ApolloReactCommon.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, baseOptions);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = ApolloReactCommon.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const PaymentsDocument = gql`
    subscription Payments {
  payment(order_by: {created_at: desc}, where: {status_id: {_neq: 3}}) {
    id
    amount
    payment_date
    value_date
    member {
      mf_code
      psl_code
    }
    status {
      name
    }
  }
}
    `;

/**
 * __usePaymentsSubscription__
 *
 * To run a query within a React component, call `usePaymentsSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsSubscription({
 *   variables: {
 *   },
 * });
 */
export function usePaymentsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<PaymentsSubscription, PaymentsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<PaymentsSubscription, PaymentsSubscriptionVariables>(PaymentsDocument, baseOptions);
      }
export type PaymentsSubscriptionHookResult = ReturnType<typeof usePaymentsSubscription>;
export type PaymentsSubscriptionResult = ApolloReactCommon.SubscriptionResult<PaymentsSubscription>;
export const GetStatusDocument = gql`
    query GetStatus {
  status {
    id
    name
  }
}
    `;

/**
 * __useGetStatusQuery__
 *
 * To run a query within a React component, call `useGetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, baseOptions);
      }
export function useGetStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStatusQuery, GetStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStatusQuery, GetStatusQueryVariables>(GetStatusDocument, baseOptions);
        }
export type GetStatusQueryHookResult = ReturnType<typeof useGetStatusQuery>;
export type GetStatusLazyQueryHookResult = ReturnType<typeof useGetStatusLazyQuery>;
export type GetStatusQueryResult = ApolloReactCommon.QueryResult<GetStatusQuery, GetStatusQueryVariables>;
export const GetPaymentsPendingApprovalOrConfirmDocument = gql`
    query GetPaymentsPendingApprovalOrConfirm($statusId: bigint) {
  payment(where: {status_id: {_eq: $statusId}}) {
    id
    member {
      mf_code
    }
    payment_date
    value_date
    amount
    status {
      name
    }
    payment_shares {
      member_goal_id
      member_goal {
        goal {
          id
          name
        }
      }
      product {
        name
      }
      goal_amount
      product_amount
    }
    payment_mode {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPaymentsPendingApprovalOrConfirmQuery__
 *
 * To run a query within a React component, call `useGetPaymentsPendingApprovalOrConfirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsPendingApprovalOrConfirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsPendingApprovalOrConfirmQuery({
 *   variables: {
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useGetPaymentsPendingApprovalOrConfirmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentsPendingApprovalOrConfirmQuery, GetPaymentsPendingApprovalOrConfirmQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentsPendingApprovalOrConfirmQuery, GetPaymentsPendingApprovalOrConfirmQueryVariables>(GetPaymentsPendingApprovalOrConfirmDocument, baseOptions);
      }
export function useGetPaymentsPendingApprovalOrConfirmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentsPendingApprovalOrConfirmQuery, GetPaymentsPendingApprovalOrConfirmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentsPendingApprovalOrConfirmQuery, GetPaymentsPendingApprovalOrConfirmQueryVariables>(GetPaymentsPendingApprovalOrConfirmDocument, baseOptions);
        }
export type GetPaymentsPendingApprovalOrConfirmQueryHookResult = ReturnType<typeof useGetPaymentsPendingApprovalOrConfirmQuery>;
export type GetPaymentsPendingApprovalOrConfirmLazyQueryHookResult = ReturnType<typeof useGetPaymentsPendingApprovalOrConfirmLazyQuery>;
export type GetPaymentsPendingApprovalOrConfirmQueryResult = ApolloReactCommon.QueryResult<GetPaymentsPendingApprovalOrConfirmQuery, GetPaymentsPendingApprovalOrConfirmQueryVariables>;
export const GetPaymentsByValueDateDocument = gql`
    query GetPaymentsByValueDate($valueDate: date!, $statusId: bigint!) {
  payment(where: {status_id: {_eq: $statusId}, value_date: {_eq: $valueDate}}) {
    id
    batch_id
    member {
      mf_code
      psl_code
      user {
        first_name
        other_name
        last_name
      }
    }
    payment_date
    value_date
    amount
    status {
      name
    }
    payment_shares {
      member_goal_id
      member_goal {
        goal {
          id
          name
        }
      }
      product {
        name
      }
      goal_amount
      product_amount
    }
    payment_mode {
      id
      name
    }
  }
}
    `;

/**
 * __useGetPaymentsByValueDateQuery__
 *
 * To run a query within a React component, call `useGetPaymentsByValueDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsByValueDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsByValueDateQuery({
 *   variables: {
 *      valueDate: // value for 'valueDate'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useGetPaymentsByValueDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentsByValueDateQuery, GetPaymentsByValueDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentsByValueDateQuery, GetPaymentsByValueDateQueryVariables>(GetPaymentsByValueDateDocument, baseOptions);
      }
export function useGetPaymentsByValueDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentsByValueDateQuery, GetPaymentsByValueDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentsByValueDateQuery, GetPaymentsByValueDateQueryVariables>(GetPaymentsByValueDateDocument, baseOptions);
        }
export type GetPaymentsByValueDateQueryHookResult = ReturnType<typeof useGetPaymentsByValueDateQuery>;
export type GetPaymentsByValueDateLazyQueryHookResult = ReturnType<typeof useGetPaymentsByValueDateLazyQuery>;
export type GetPaymentsByValueDateQueryResult = ApolloReactCommon.QueryResult<GetPaymentsByValueDateQuery, GetPaymentsByValueDateQueryVariables>;
export const ConfirmPaymentsDocument = gql`
    mutation confirmPayments($valueDate: date!, $statusId: bigint!) {
  update_payment(_set: {status_id: $statusId}, where: {value_date: {_eq: $valueDate}}) {
    affected_rows
  }
}
    `;
export type ConfirmPaymentsMutationFn = ApolloReactCommon.MutationFunction<ConfirmPaymentsMutation, ConfirmPaymentsMutationVariables>;

/**
 * __useConfirmPaymentsMutation__
 *
 * To run a mutation, you first call `useConfirmPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPaymentsMutation, { data, loading, error }] = useConfirmPaymentsMutation({
 *   variables: {
 *      valueDate: // value for 'valueDate'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useConfirmPaymentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmPaymentsMutation, ConfirmPaymentsMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmPaymentsMutation, ConfirmPaymentsMutationVariables>(ConfirmPaymentsDocument, baseOptions);
      }
export type ConfirmPaymentsMutationHookResult = ReturnType<typeof useConfirmPaymentsMutation>;
export type ConfirmPaymentsMutationResult = ApolloReactCommon.MutationResult<ConfirmPaymentsMutation>;
export type ConfirmPaymentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmPaymentsMutation, ConfirmPaymentsMutationVariables>;
export const LoadPaymentsDocument = gql`
    mutation loadPayments($data: [LoadPaymentsInput!]!) {
  loadPayments(data: $data) {
    success
  }
}
    `;
export type LoadPaymentsMutationFn = ApolloReactCommon.MutationFunction<LoadPaymentsMutation, LoadPaymentsMutationVariables>;

/**
 * __useLoadPaymentsMutation__
 *
 * To run a mutation, you first call `useLoadPaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadPaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadPaymentsMutation, { data, loading, error }] = useLoadPaymentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoadPaymentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoadPaymentsMutation, LoadPaymentsMutationVariables>) {
        return ApolloReactHooks.useMutation<LoadPaymentsMutation, LoadPaymentsMutationVariables>(LoadPaymentsDocument, baseOptions);
      }
export type LoadPaymentsMutationHookResult = ReturnType<typeof useLoadPaymentsMutation>;
export type LoadPaymentsMutationResult = ApolloReactCommon.MutationResult<LoadPaymentsMutation>;
export type LoadPaymentsMutationOptions = ApolloReactCommon.BaseMutationOptions<LoadPaymentsMutation, LoadPaymentsMutationVariables>;
export const PaymentModesDocument = gql`
    query PaymentModes {
  payment_mode(where: {deleted: {_eq: false}}) {
    id
    name
  }
}
    `;

/**
 * __usePaymentModesQuery__
 *
 * To run a query within a React component, call `usePaymentModesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentModesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentModesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentModesQuery, PaymentModesQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentModesQuery, PaymentModesQueryVariables>(PaymentModesDocument, baseOptions);
      }
export function usePaymentModesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentModesQuery, PaymentModesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentModesQuery, PaymentModesQueryVariables>(PaymentModesDocument, baseOptions);
        }
export type PaymentModesQueryHookResult = ReturnType<typeof usePaymentModesQuery>;
export type PaymentModesLazyQueryHookResult = ReturnType<typeof usePaymentModesLazyQuery>;
export type PaymentModesQueryResult = ApolloReactCommon.QueryResult<PaymentModesQuery, PaymentModesQueryVariables>;
export const DirectDebitChargeDocument = gql`
    query DirectDebitCharge($date: date!) {
  direct_debit_charge(where: {_or: [{end_date: {_gte: $date}}, {end_date: {_is_null: true}}], start_date: {_lte: $date}}, limit: 1) {
    amount
  }
}
    `;

/**
 * __useDirectDebitChargeQuery__
 *
 * To run a query within a React component, call `useDirectDebitChargeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectDebitChargeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectDebitChargeQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDirectDebitChargeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirectDebitChargeQuery, DirectDebitChargeQueryVariables>) {
        return ApolloReactHooks.useQuery<DirectDebitChargeQuery, DirectDebitChargeQueryVariables>(DirectDebitChargeDocument, baseOptions);
      }
export function useDirectDebitChargeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirectDebitChargeQuery, DirectDebitChargeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirectDebitChargeQuery, DirectDebitChargeQueryVariables>(DirectDebitChargeDocument, baseOptions);
        }
export type DirectDebitChargeQueryHookResult = ReturnType<typeof useDirectDebitChargeQuery>;
export type DirectDebitChargeLazyQueryHookResult = ReturnType<typeof useDirectDebitChargeLazyQuery>;
export type DirectDebitChargeQueryResult = ApolloReactCommon.QueryResult<DirectDebitChargeQuery, DirectDebitChargeQueryVariables>;
export const PaymentChannelDocument = gql`
    query PaymentChannel {
  payment_channel {
    id
    name
  }
}
    `;

/**
 * __usePaymentChannelQuery__
 *
 * To run a query within a React component, call `usePaymentChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentChannelQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentChannelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentChannelQuery, PaymentChannelQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentChannelQuery, PaymentChannelQueryVariables>(PaymentChannelDocument, baseOptions);
      }
export function usePaymentChannelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentChannelQuery, PaymentChannelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentChannelQuery, PaymentChannelQueryVariables>(PaymentChannelDocument, baseOptions);
        }
export type PaymentChannelQueryHookResult = ReturnType<typeof usePaymentChannelQuery>;
export type PaymentChannelLazyQueryHookResult = ReturnType<typeof usePaymentChannelLazyQuery>;
export type PaymentChannelQueryResult = ApolloReactCommon.QueryResult<PaymentChannelQuery, PaymentChannelQueryVariables>;
export const PaymentProviderDocument = gql`
    query PaymentProvider {
  payment_provider(where: {deleted: {_eq: false}}) {
    id
    name
    code
    payment_channel {
      id
      name
    }
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __usePaymentProviderQuery__
 *
 * To run a query within a React component, call `usePaymentProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentProviderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(PaymentProviderDocument, baseOptions);
      }
export function usePaymentProviderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentProviderQuery, PaymentProviderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentProviderQuery, PaymentProviderQueryVariables>(PaymentProviderDocument, baseOptions);
        }
export type PaymentProviderQueryHookResult = ReturnType<typeof usePaymentProviderQuery>;
export type PaymentProviderLazyQueryHookResult = ReturnType<typeof usePaymentProviderLazyQuery>;
export type PaymentProviderQueryResult = ApolloReactCommon.QueryResult<PaymentProviderQuery, PaymentProviderQueryVariables>;
export const Add_Payment_ProviderDocument = gql`
    mutation add_payment_provider($changes: [payment_provider_insert_input!]!) {
  insert_payment_provider(objects: $changes, on_conflict: {constraint: payment_provider_name_key, update_columns: [name, code, modified_by, deleted]}) {
    returning {
      id
      name
      code
      payment_channel {
        id
        name
      }
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_Payment_ProviderMutationFn = ApolloReactCommon.MutationFunction<Add_Payment_ProviderMutation, Add_Payment_ProviderMutationVariables>;

/**
 * __useAdd_Payment_ProviderMutation__
 *
 * To run a mutation, you first call `useAdd_Payment_ProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Payment_ProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaymentProviderMutation, { data, loading, error }] = useAdd_Payment_ProviderMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_Payment_ProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Payment_ProviderMutation, Add_Payment_ProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Payment_ProviderMutation, Add_Payment_ProviderMutationVariables>(Add_Payment_ProviderDocument, baseOptions);
      }
export type Add_Payment_ProviderMutationHookResult = ReturnType<typeof useAdd_Payment_ProviderMutation>;
export type Add_Payment_ProviderMutationResult = ApolloReactCommon.MutationResult<Add_Payment_ProviderMutation>;
export type Add_Payment_ProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Payment_ProviderMutation, Add_Payment_ProviderMutationVariables>;
export const Update_Payment_ProviderDocument = gql`
    mutation update_payment_provider($id: bigint, $changes: payment_provider_set_input) {
  update_payment_provider(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      code
      payment_channel {
        id
        name
      }
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_Payment_ProviderMutationFn = ApolloReactCommon.MutationFunction<Update_Payment_ProviderMutation, Update_Payment_ProviderMutationVariables>;

/**
 * __useUpdate_Payment_ProviderMutation__
 *
 * To run a mutation, you first call `useUpdate_Payment_ProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Payment_ProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentProviderMutation, { data, loading, error }] = useUpdate_Payment_ProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Payment_ProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Payment_ProviderMutation, Update_Payment_ProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Payment_ProviderMutation, Update_Payment_ProviderMutationVariables>(Update_Payment_ProviderDocument, baseOptions);
      }
export type Update_Payment_ProviderMutationHookResult = ReturnType<typeof useUpdate_Payment_ProviderMutation>;
export type Update_Payment_ProviderMutationResult = ApolloReactCommon.MutationResult<Update_Payment_ProviderMutation>;
export type Update_Payment_ProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Payment_ProviderMutation, Update_Payment_ProviderMutationVariables>;
export const Delete_Payment_ProviderDocument = gql`
    mutation delete_payment_provider($id: bigint, $doneBy: bigint) {
  update_payment_provider(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      code
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_Payment_ProviderMutationFn = ApolloReactCommon.MutationFunction<Delete_Payment_ProviderMutation, Delete_Payment_ProviderMutationVariables>;

/**
 * __useDelete_Payment_ProviderMutation__
 *
 * To run a mutation, you first call `useDelete_Payment_ProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Payment_ProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentProviderMutation, { data, loading, error }] = useDelete_Payment_ProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_Payment_ProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Payment_ProviderMutation, Delete_Payment_ProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Payment_ProviderMutation, Delete_Payment_ProviderMutationVariables>(Delete_Payment_ProviderDocument, baseOptions);
      }
export type Delete_Payment_ProviderMutationHookResult = ReturnType<typeof useDelete_Payment_ProviderMutation>;
export type Delete_Payment_ProviderMutationResult = ApolloReactCommon.MutationResult<Delete_Payment_ProviderMutation>;
export type Delete_Payment_ProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Payment_ProviderMutation, Delete_Payment_ProviderMutationVariables>;
export const ProductDocument = gql`
    query Product {
  product(where: {deleted: {_eq: false}}) {
    id
    name
    fund_code
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
      }
export function useProductLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = ApolloReactCommon.QueryResult<ProductQuery, ProductQueryVariables>;
export const Add_ProductDocument = gql`
    mutation add_product($product_changes: [product_insert_input!]!) {
  insert_product(objects: $product_changes, on_conflict: {constraint: product_name_key, update_columns: [name, fund_code, modified_by, deleted]}) {
    returning {
      id
      name
      fund_code
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_ProductMutationFn = ApolloReactCommon.MutationFunction<Add_ProductMutation, Add_ProductMutationVariables>;

/**
 * __useAdd_ProductMutation__
 *
 * To run a mutation, you first call `useAdd_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAdd_ProductMutation({
 *   variables: {
 *      product_changes: // value for 'product_changes'
 *   },
 * });
 */
export function useAdd_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_ProductMutation, Add_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_ProductMutation, Add_ProductMutationVariables>(Add_ProductDocument, baseOptions);
      }
export type Add_ProductMutationHookResult = ReturnType<typeof useAdd_ProductMutation>;
export type Add_ProductMutationResult = ApolloReactCommon.MutationResult<Add_ProductMutation>;
export type Add_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_ProductMutation, Add_ProductMutationVariables>;
export const Update_ProductDocument = gql`
    mutation update_product($id: bigint, $changes: product_set_input) {
  update_product(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      fund_code
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_ProductMutationFn = ApolloReactCommon.MutationFunction<Update_ProductMutation, Update_ProductMutationVariables>;

/**
 * __useUpdate_ProductMutation__
 *
 * To run a mutation, you first call `useUpdate_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdate_ProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_ProductMutation, Update_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_ProductMutation, Update_ProductMutationVariables>(Update_ProductDocument, baseOptions);
      }
export type Update_ProductMutationHookResult = ReturnType<typeof useUpdate_ProductMutation>;
export type Update_ProductMutationResult = ApolloReactCommon.MutationResult<Update_ProductMutation>;
export type Update_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_ProductMutation, Update_ProductMutationVariables>;
export const Delete_ProductDocument = gql`
    mutation delete_product($id: bigint, $doneBy: bigint) {
  update_product(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      fund_code
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_ProductMutationFn = ApolloReactCommon.MutationFunction<Delete_ProductMutation, Delete_ProductMutationVariables>;

/**
 * __useDelete_ProductMutation__
 *
 * To run a mutation, you first call `useDelete_ProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_ProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDelete_ProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_ProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_ProductMutation, Delete_ProductMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_ProductMutation, Delete_ProductMutationVariables>(Delete_ProductDocument, baseOptions);
      }
export type Delete_ProductMutationHookResult = ReturnType<typeof useDelete_ProductMutation>;
export type Delete_ProductMutationResult = ApolloReactCommon.MutationResult<Delete_ProductMutation>;
export type Delete_ProductMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_ProductMutation, Delete_ProductMutationVariables>;
export const ProductUnitPriceDocument = gql`
    query ProductUnitPrice {
  price {
    id
    unit_price
    price_date
    product {
      id
      name
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useProductUnitPriceQuery__
 *
 * To run a query within a React component, call `useProductUnitPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductUnitPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductUnitPriceQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductUnitPriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProductUnitPriceQuery, ProductUnitPriceQueryVariables>) {
        return ApolloReactHooks.useQuery<ProductUnitPriceQuery, ProductUnitPriceQueryVariables>(ProductUnitPriceDocument, baseOptions);
      }
export function useProductUnitPriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductUnitPriceQuery, ProductUnitPriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProductUnitPriceQuery, ProductUnitPriceQueryVariables>(ProductUnitPriceDocument, baseOptions);
        }
export type ProductUnitPriceQueryHookResult = ReturnType<typeof useProductUnitPriceQuery>;
export type ProductUnitPriceLazyQueryHookResult = ReturnType<typeof useProductUnitPriceLazyQuery>;
export type ProductUnitPriceQueryResult = ApolloReactCommon.QueryResult<ProductUnitPriceQuery, ProductUnitPriceQueryVariables>;
export const Add_Product_Unit_PriceDocument = gql`
    mutation add_product_unit_price($price_changes: [price_insert_input!]!) {
  insert_price(objects: $price_changes, on_conflict: {constraint: price_product_id_price_date_key, update_columns: [product_id, unit_price, price_date, modified_by]}) {
    returning {
      id
      unit_price
      price_date
      product {
        id
        name
      }
      created_at
      updated_at
    }
  }
}
    `;
export type Add_Product_Unit_PriceMutationFn = ApolloReactCommon.MutationFunction<Add_Product_Unit_PriceMutation, Add_Product_Unit_PriceMutationVariables>;

/**
 * __useAdd_Product_Unit_PriceMutation__
 *
 * To run a mutation, you first call `useAdd_Product_Unit_PriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_Product_Unit_PriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductUnitPriceMutation, { data, loading, error }] = useAdd_Product_Unit_PriceMutation({
 *   variables: {
 *      price_changes: // value for 'price_changes'
 *   },
 * });
 */
export function useAdd_Product_Unit_PriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_Product_Unit_PriceMutation, Add_Product_Unit_PriceMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_Product_Unit_PriceMutation, Add_Product_Unit_PriceMutationVariables>(Add_Product_Unit_PriceDocument, baseOptions);
      }
export type Add_Product_Unit_PriceMutationHookResult = ReturnType<typeof useAdd_Product_Unit_PriceMutation>;
export type Add_Product_Unit_PriceMutationResult = ApolloReactCommon.MutationResult<Add_Product_Unit_PriceMutation>;
export type Add_Product_Unit_PriceMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_Product_Unit_PriceMutation, Add_Product_Unit_PriceMutationVariables>;
export const Update_Product_Unit_PriceDocument = gql`
    mutation update_product_unit_price($id: bigint!, $changes: price_set_input!) {
  update_price(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      unit_price
      price_date
      product {
        id
        name
      }
      created_at
      updated_at
    }
  }
}
    `;
export type Update_Product_Unit_PriceMutationFn = ApolloReactCommon.MutationFunction<Update_Product_Unit_PriceMutation, Update_Product_Unit_PriceMutationVariables>;

/**
 * __useUpdate_Product_Unit_PriceMutation__
 *
 * To run a mutation, you first call `useUpdate_Product_Unit_PriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Product_Unit_PriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductUnitPriceMutation, { data, loading, error }] = useUpdate_Product_Unit_PriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_Product_Unit_PriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Product_Unit_PriceMutation, Update_Product_Unit_PriceMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Product_Unit_PriceMutation, Update_Product_Unit_PriceMutationVariables>(Update_Product_Unit_PriceDocument, baseOptions);
      }
export type Update_Product_Unit_PriceMutationHookResult = ReturnType<typeof useUpdate_Product_Unit_PriceMutation>;
export type Update_Product_Unit_PriceMutationResult = ApolloReactCommon.MutationResult<Update_Product_Unit_PriceMutation>;
export type Update_Product_Unit_PriceMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Product_Unit_PriceMutation, Update_Product_Unit_PriceMutationVariables>;
export const Delete_Product_Unit_PriceDocument = gql`
    mutation delete_product_unit_price($id: bigint!) {
  delete_price(where: {id: {_eq: $id}}) {
    affected_rows
    returning {
      id
      unit_price
      price_date
      product {
        id
        name
      }
      created_at
      updated_at
    }
  }
}
    `;
export type Delete_Product_Unit_PriceMutationFn = ApolloReactCommon.MutationFunction<Delete_Product_Unit_PriceMutation, Delete_Product_Unit_PriceMutationVariables>;

/**
 * __useDelete_Product_Unit_PriceMutation__
 *
 * To run a mutation, you first call `useDelete_Product_Unit_PriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Product_Unit_PriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductUnitPriceMutation, { data, loading, error }] = useDelete_Product_Unit_PriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Product_Unit_PriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_Product_Unit_PriceMutation, Delete_Product_Unit_PriceMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_Product_Unit_PriceMutation, Delete_Product_Unit_PriceMutationVariables>(Delete_Product_Unit_PriceDocument, baseOptions);
      }
export type Delete_Product_Unit_PriceMutationHookResult = ReturnType<typeof useDelete_Product_Unit_PriceMutation>;
export type Delete_Product_Unit_PriceMutationResult = ApolloReactCommon.MutationResult<Delete_Product_Unit_PriceMutation>;
export type Delete_Product_Unit_PriceMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_Product_Unit_PriceMutation, Delete_Product_Unit_PriceMutationVariables>;
export const ProspectDocument = gql`
    query Prospect {
  member(where: {is_customer: {_eq: false}, user: {deleted: {_eq: false}}, status: {_or: [{name: {_eq: "Pending Approval"}}, {name: {_eq: "Rejected"}}]}}, order_by: {id: asc}) {
    id
    psl_code
    nationality
    status {
      id
      name
    }
    tin
    status {
      id
      name
    }
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    user {
      id
      first_name
      last_name
      other_name
      user_advisor {
        advisor_code
      }
      gender {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProspectQuery__
 *
 * To run a query within a React component, call `useProspectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectQuery({
 *   variables: {
 *   },
 * });
 */
export function useProspectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectQuery, ProspectQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectQuery, ProspectQueryVariables>(ProspectDocument, baseOptions);
      }
export function useProspectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectQuery, ProspectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectQuery, ProspectQueryVariables>(ProspectDocument, baseOptions);
        }
export type ProspectQueryHookResult = ReturnType<typeof useProspectQuery>;
export type ProspectLazyQueryHookResult = ReturnType<typeof useProspectLazyQuery>;
export type ProspectQueryResult = ApolloReactCommon.QueryResult<ProspectQuery, ProspectQueryVariables>;
export const ProspectDetailDocument = gql`
    query ProspectDetail($id: bigint) {
  member(where: {id: {_eq: $id}, user: {deleted: {_eq: false}}}) {
    id
    user {
      id
      first_name
      first_name_jt
      last_name
      last_name_jt
      other_name
      other_name_jt
      mobile
      mobile_jt
      mobile_jt2
      email
      email_jt
      date_of_birth
      date_of_birth_jt
      gender_id
      gender {
        id
        name
      }
      gender_id_jt
      gender_jt {
        name
      }
    }
    welcome_pack_sent_at
    age
    marital_status_id
    marital_status {
      id
      name
    }
    marital_status_id_jt
    marital_status_jt {
      id
      name
    }
    place_of_birth
    place_of_birth_jt
    signature
    city
    title_id_jt
    psl_code
    mf_code
    nationality
    tin
    tin_jt
    occupation
    occupation_jt
    profession
    profession_jt
    employer
    employer_jt
    mobile_secondary
    home_address
    home_address_jt
    postal_address
    permit_expiry_date
    permit_expiry_date_jt
    permit_issue_date
    permit_issue_date_jt
    regular_top_up_amount
    initial_investment_amount
    regular_withdrawal_amount
    residential_permit_number
    residential_permit_number_jt
    anticipated_investment_activity_top_ups
    anticipated_investment_activity_top_ups_other
    anticipated_investment_activity_withdrawals
    anticipated_investment_activity_withdrawals_other
    residential_place_of_issue
    residential_place_of_issue_jt
    country_of_origin_id
    country_of_origin_id_jt
    country_of_residence_id
    country_of_residence_id_jt
    employer_address
    employer_address_jt
    employer_city
    employer_city_jt
    employer_contact
    employer_contact_jt
    employer_email
    employer_email_jt
    employment_status
    employment_status_jt
    nature_of_business
    nature_of_business_jt
    years_of_current_employment
    years_of_current_employment_jt
    years_of_employment
    years_of_employment_jt
    years_of_previous_employment
    years_of_previous_employment_jt
    professional_license_number
    professional_license_number_jt
    place_of_issue_jt
    mode_of_statement_delivery
    statement_frequency
    account_mandate
    account_type
    gps_address
    gps_address_jt
    relationship_jt
    id_type_jt
    id_number_jt
    id_issue_date_jt
    id_expiry_date_jt
    region_id
    client_investment_profile {
      investment_objective
      investment_knowledge
      risk_tolerance
      investment_horizon
      approximate_annual_income
    }
    country_of_origin {
      id
      name
    }
    country_of_origin_jt {
      id
      name
    }
    country_of_residence {
      id
      name
    }
    country_of_residence_jt {
      id
      name
    }
    residential_status
    residential_status_jt
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    status {
      id
      name
    }
    industry {
      id
      name
    }
    region {
      id
      name
    }
    next_of_kin {
      id
      first_name
      last_name
      other_names
      relationship_id
      relationship {
        id
        name
      }
      gender_id
      gender {
        id
        name
      }
      home_address
      date_of_birth
      mobile
      email
      residential_status
      residential_place_of_issue
      residential_permit_number
      country_of_origin_id
      region_id
      country_of_origin {
        id
        name
      }
      region {
        id
        name
      }
      permit_expiry_date
      permit_issue_date
      occupation
      profession
      mobile2
      gps_address
      title
      place_of_birth
      marital_status
      tin
      id_type
      id_number
      id_expiry_date
      id_issue_date
      professional_license
    }
    member_income_sources(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      income_source_id
      income_source {
        id
        name
      }
    }
    member_contribution_sources(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      contribution_source_id
      contribution_source {
        id
        name
      }
    }
    beneficiaries(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      id
      guid
      first_name
      last_name
      mobile
      other_names
      home_address
      date_of_birth
      allocation
      relationship_id
      relationship {
        id
        name
      }
      gender_id
      gender {
        id
        name
      }
      email
      residential_status
      residential_place_of_issue
      residential_permit_number
      country_of_origin_id
      region_id
      country_of_origin {
        id
        name
      }
      region {
        id
        name
      }
      permit_expiry_date
      permit_issue_date
      occupation
      profession
      mobile2
      gps_address
      title
      place_of_birth
      marital_status
      tin
      id_type
      id_number
      id_expiry_date
      id_issue_date
      professional_license
    }
    member_dependents(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      id
      guid
      name
      age
    }
    member_payment_instructions(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      id
      guid
      account_name
      account_number
      branch_id
      payment_provider {
        id
        name
        payment_channel_id
      }
      preferred
      mobile_money_number
    }
    member_mode_of_instruction_communications(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      mode_of_instruction_communication_id
      mode_of_instruction_communication {
        id
        name
      }
    }
    member_identifications(where: {member_id: {_eq: $id}, deleted: {_eq: false}}) {
      id
      identification_type {
        id
        name
      }
      identification_number
      identification_card_image_url
      identification_expiry_date
      identification_issue_date
      place_of_issue
      country {
        id
        name
      }
    }
    member_goals(where: {member_id: {_eq: $id}, accepted: {_eq: true}, deleted: {_eq: false}}) {
      id
      guid
      years_to_fund
      member_id
      goal_for
      accepted
      current_value
      future_value
      duration_years
      duration_months
      administrative_fees
      goal_id
      goal {
        id
        name
      }
      goal_id
      monthly_contribution
      expected_return
      has_insurance
      goal_insurances {
        id
        insurance_rate_per_1000
        insurance_premium
        insurance_type_id
        insurance_type {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useProspectDetailQuery__
 *
 * To run a query within a React component, call `useProspectDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProspectDetailQuery, ProspectDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<ProspectDetailQuery, ProspectDetailQueryVariables>(ProspectDetailDocument, baseOptions);
      }
export function useProspectDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProspectDetailQuery, ProspectDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProspectDetailQuery, ProspectDetailQueryVariables>(ProspectDetailDocument, baseOptions);
        }
export type ProspectDetailQueryHookResult = ReturnType<typeof useProspectDetailQuery>;
export type ProspectDetailLazyQueryHookResult = ReturnType<typeof useProspectDetailLazyQuery>;
export type ProspectDetailQueryResult = ApolloReactCommon.QueryResult<ProspectDetailQuery, ProspectDetailQueryVariables>;
export const Update_Member_IdentificationDocument = gql`
    mutation update_member_identification($id: bigint, $member_identification_changes: member_identification_set_input) {
  __typename
  update_member_identification(where: {id: {_eq: $id}}, _set: $member_identification_changes) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type Update_Member_IdentificationMutationFn = ApolloReactCommon.MutationFunction<Update_Member_IdentificationMutation, Update_Member_IdentificationMutationVariables>;

/**
 * __useUpdate_Member_IdentificationMutation__
 *
 * To run a mutation, you first call `useUpdate_Member_IdentificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Member_IdentificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberIdentificationMutation, { data, loading, error }] = useUpdate_Member_IdentificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      member_identification_changes: // value for 'member_identification_changes'
 *   },
 * });
 */
export function useUpdate_Member_IdentificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_Member_IdentificationMutation, Update_Member_IdentificationMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_Member_IdentificationMutation, Update_Member_IdentificationMutationVariables>(Update_Member_IdentificationDocument, baseOptions);
      }
export type Update_Member_IdentificationMutationHookResult = ReturnType<typeof useUpdate_Member_IdentificationMutation>;
export type Update_Member_IdentificationMutationResult = ApolloReactCommon.MutationResult<Update_Member_IdentificationMutation>;
export type Update_Member_IdentificationMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_Member_IdentificationMutation, Update_Member_IdentificationMutationVariables>;
export const PaginatedProspectsDocument = gql`
    query PaginatedProspects($limit: Int!, $offset: Int, $search: String) {
  member_aggregate(where: {is_customer: {_eq: false}, user: {deleted: {_eq: false}}, status: {_or: [{name: {_eq: "Pending Approval"}}, {name: {_eq: "Rejected"}}]}, _or: [{psl_code: {_ilike: $search}}, {user: {first_name: {_ilike: $search}}}, {user: {last_name: {_ilike: $search}}}, {user: {full_name: {_ilike: $search}}}, {user: {gender: {name: {_ilike: $search}}}}, {status: {name: {_ilike: $search}}}, {assigned_to_user: {first_name: {_ilike: $search}}}, {assigned_to_user: {last_name: {_ilike: $search}}}, {assigned_to_user: {full_name: {_ilike: $search}}}]}) {
    aggregate {
      count
    }
  }
  member(where: {is_customer: {_eq: false}, user: {deleted: {_eq: false}}, status: {_or: [{name: {_eq: "Pending Approval"}}, {name: {_eq: "Rejected"}}]}, _or: [{psl_code: {_ilike: $search}}, {user: {first_name: {_ilike: $search}}}, {user: {last_name: {_ilike: $search}}}, {user: {full_name: {_ilike: $search}}}, {user: {gender: {name: {_ilike: $search}}}}, {status: {name: {_ilike: $search}}}, {assigned_to_user: {first_name: {_ilike: $search}}}, {assigned_to_user: {last_name: {_ilike: $search}}}, {assigned_to_user: {full_name: {_ilike: $search}}}]}, limit: $limit, offset: $offset, order_by: {id: desc}) {
    id
    account_type
    psl_code
    nationality
    status {
      id
      name
    }
    tin
    status {
      id
      name
    }
    assigned_to_user {
      id
      first_name
      last_name
      other_name
    }
    user {
      id
      first_name
      last_name
      other_name
      user_advisor {
        advisor_code
      }
      gender {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePaginatedProspectsQuery__
 *
 * To run a query within a React component, call `usePaginatedProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedProspectsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePaginatedProspectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaginatedProspectsQuery, PaginatedProspectsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaginatedProspectsQuery, PaginatedProspectsQueryVariables>(PaginatedProspectsDocument, baseOptions);
      }
export function usePaginatedProspectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaginatedProspectsQuery, PaginatedProspectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaginatedProspectsQuery, PaginatedProspectsQueryVariables>(PaginatedProspectsDocument, baseOptions);
        }
export type PaginatedProspectsQueryHookResult = ReturnType<typeof usePaginatedProspectsQuery>;
export type PaginatedProspectsLazyQueryHookResult = ReturnType<typeof usePaginatedProspectsLazyQuery>;
export type PaginatedProspectsQueryResult = ApolloReactCommon.QueryResult<PaginatedProspectsQuery, PaginatedProspectsQueryVariables>;
export const RegionsDocument = gql`
    query Regions {
  region(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
      }
export function useRegionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = ApolloReactCommon.QueryResult<RegionsQuery, RegionsQueryVariables>;
export const Register_UserDocument = gql`
    mutation register_user($username: String!, $password: String!, $firstName: String!, $otherName: String, $lastName: String!, $dateOfBirth: DateTime!, $email: String!, $mobile: String!, $genderId: Float!, $userRole: String!) {
  register(data: {username: $username, password: $password, firstName: $firstName, otherName: $otherName, lastName: $lastName, dateOfBirth: $dateOfBirth, email: $email, mobile: $mobile, genderId: $genderId, userRole: $userRole}) {
    success
    id
    errors {
      message
      path
    }
  }
}
    `;
export type Register_UserMutationFn = ApolloReactCommon.MutationFunction<Register_UserMutation, Register_UserMutationVariables>;

/**
 * __useRegister_UserMutation__
 *
 * To run a mutation, you first call `useRegister_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegister_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegister_UserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      otherName: // value for 'otherName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      email: // value for 'email'
 *      mobile: // value for 'mobile'
 *      genderId: // value for 'genderId'
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useRegister_UserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Register_UserMutation, Register_UserMutationVariables>) {
        return ApolloReactHooks.useMutation<Register_UserMutation, Register_UserMutationVariables>(Register_UserDocument, baseOptions);
      }
export type Register_UserMutationHookResult = ReturnType<typeof useRegister_UserMutation>;
export type Register_UserMutationResult = ApolloReactCommon.MutationResult<Register_UserMutation>;
export type Register_UserMutationOptions = ApolloReactCommon.BaseMutationOptions<Register_UserMutation, Register_UserMutationVariables>;
export const RelationshipsDocument = gql`
    query Relationships {
  relationship(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useRelationshipsQuery__
 *
 * To run a query within a React component, call `useRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelationshipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
        return ApolloReactHooks.useQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, baseOptions);
      }
export function useRelationshipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, baseOptions);
        }
export type RelationshipsQueryHookResult = ReturnType<typeof useRelationshipsQuery>;
export type RelationshipsLazyQueryHookResult = ReturnType<typeof useRelationshipsLazyQuery>;
export type RelationshipsQueryResult = ApolloReactCommon.QueryResult<RelationshipsQuery, RelationshipsQueryVariables>;
export const Add_RelationshipDocument = gql`
    mutation add_relationship($changes: [relationship_insert_input!]!) {
  insert_relationship(objects: $changes, on_conflict: {constraint: relationship_name_key, update_columns: name}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_RelationshipMutationFn = ApolloReactCommon.MutationFunction<Add_RelationshipMutation, Add_RelationshipMutationVariables>;

/**
 * __useAdd_RelationshipMutation__
 *
 * To run a mutation, you first call `useAdd_RelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_RelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRelationshipMutation, { data, loading, error }] = useAdd_RelationshipMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_RelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_RelationshipMutation, Add_RelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_RelationshipMutation, Add_RelationshipMutationVariables>(Add_RelationshipDocument, baseOptions);
      }
export type Add_RelationshipMutationHookResult = ReturnType<typeof useAdd_RelationshipMutation>;
export type Add_RelationshipMutationResult = ApolloReactCommon.MutationResult<Add_RelationshipMutation>;
export type Add_RelationshipMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_RelationshipMutation, Add_RelationshipMutationVariables>;
export const Update_RelationshipDocument = gql`
    mutation update_relationship($id: bigint, $changes: relationship_set_input) {
  update_relationship(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_RelationshipMutationFn = ApolloReactCommon.MutationFunction<Update_RelationshipMutation, Update_RelationshipMutationVariables>;

/**
 * __useUpdate_RelationshipMutation__
 *
 * To run a mutation, you first call `useUpdate_RelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_RelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipMutation, { data, loading, error }] = useUpdate_RelationshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_RelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_RelationshipMutation, Update_RelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_RelationshipMutation, Update_RelationshipMutationVariables>(Update_RelationshipDocument, baseOptions);
      }
export type Update_RelationshipMutationHookResult = ReturnType<typeof useUpdate_RelationshipMutation>;
export type Update_RelationshipMutationResult = ApolloReactCommon.MutationResult<Update_RelationshipMutation>;
export type Update_RelationshipMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_RelationshipMutation, Update_RelationshipMutationVariables>;
export const Delete_RelationshipDocument = gql`
    mutation delete_relationship($id: bigint, $doneBy: bigint) {
  update_relationship(where: {id: {_eq: $id}}, _set: {deleted: true}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_RelationshipMutationFn = ApolloReactCommon.MutationFunction<Delete_RelationshipMutation, Delete_RelationshipMutationVariables>;

/**
 * __useDelete_RelationshipMutation__
 *
 * To run a mutation, you first call `useDelete_RelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_RelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRelationshipMutation, { data, loading, error }] = useDelete_RelationshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_RelationshipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_RelationshipMutation, Delete_RelationshipMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_RelationshipMutation, Delete_RelationshipMutationVariables>(Delete_RelationshipDocument, baseOptions);
      }
export type Delete_RelationshipMutationHookResult = ReturnType<typeof useDelete_RelationshipMutation>;
export type Delete_RelationshipMutationResult = ApolloReactCommon.MutationResult<Delete_RelationshipMutation>;
export type Delete_RelationshipMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_RelationshipMutation, Delete_RelationshipMutationVariables>;
export const TitlesDocument = gql`
    query Titles {
  title(where: {deleted: {_eq: false}}) {
    id
    name
    created_at
    updated_at
    deleted
  }
}
    `;

/**
 * __useTitlesQuery__
 *
 * To run a query within a React component, call `useTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTitlesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TitlesQuery, TitlesQueryVariables>) {
        return ApolloReactHooks.useQuery<TitlesQuery, TitlesQueryVariables>(TitlesDocument, baseOptions);
      }
export function useTitlesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TitlesQuery, TitlesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TitlesQuery, TitlesQueryVariables>(TitlesDocument, baseOptions);
        }
export type TitlesQueryHookResult = ReturnType<typeof useTitlesQuery>;
export type TitlesLazyQueryHookResult = ReturnType<typeof useTitlesLazyQuery>;
export type TitlesQueryResult = ApolloReactCommon.QueryResult<TitlesQuery, TitlesQueryVariables>;
export const Add_TitleDocument = gql`
    mutation add_title($changes: [title_insert_input!]!) {
  insert_title(objects: $changes, on_conflict: {constraint: title_name_key, update_columns: name}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Add_TitleMutationFn = ApolloReactCommon.MutationFunction<Add_TitleMutation, Add_TitleMutationVariables>;

/**
 * __useAdd_TitleMutation__
 *
 * To run a mutation, you first call `useAdd_TitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdd_TitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTitleMutation, { data, loading, error }] = useAdd_TitleMutation({
 *   variables: {
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useAdd_TitleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Add_TitleMutation, Add_TitleMutationVariables>) {
        return ApolloReactHooks.useMutation<Add_TitleMutation, Add_TitleMutationVariables>(Add_TitleDocument, baseOptions);
      }
export type Add_TitleMutationHookResult = ReturnType<typeof useAdd_TitleMutation>;
export type Add_TitleMutationResult = ApolloReactCommon.MutationResult<Add_TitleMutation>;
export type Add_TitleMutationOptions = ApolloReactCommon.BaseMutationOptions<Add_TitleMutation, Add_TitleMutationVariables>;
export const Update_TitleDocument = gql`
    mutation update_title($id: bigint, $changes: title_set_input) {
  update_title(where: {id: {_eq: $id}}, _set: $changes) {
    affected_rows
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
  }
}
    `;
export type Update_TitleMutationFn = ApolloReactCommon.MutationFunction<Update_TitleMutation, Update_TitleMutationVariables>;

/**
 * __useUpdate_TitleMutation__
 *
 * To run a mutation, you first call `useUpdate_TitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_TitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTitleMutation, { data, loading, error }] = useUpdate_TitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdate_TitleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_TitleMutation, Update_TitleMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_TitleMutation, Update_TitleMutationVariables>(Update_TitleDocument, baseOptions);
      }
export type Update_TitleMutationHookResult = ReturnType<typeof useUpdate_TitleMutation>;
export type Update_TitleMutationResult = ApolloReactCommon.MutationResult<Update_TitleMutation>;
export type Update_TitleMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_TitleMutation, Update_TitleMutationVariables>;
export const Delete_TitleDocument = gql`
    mutation delete_title($id: bigint, $doneBy: bigint) {
  update_title(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
      name
      created_at
      updated_at
      deleted
    }
    affected_rows
  }
}
    `;
export type Delete_TitleMutationFn = ApolloReactCommon.MutationFunction<Delete_TitleMutation, Delete_TitleMutationVariables>;

/**
 * __useDelete_TitleMutation__
 *
 * To run a mutation, you first call `useDelete_TitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_TitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTitleMutation, { data, loading, error }] = useDelete_TitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_TitleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_TitleMutation, Delete_TitleMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_TitleMutation, Delete_TitleMutationVariables>(Delete_TitleDocument, baseOptions);
      }
export type Delete_TitleMutationHookResult = ReturnType<typeof useDelete_TitleMutation>;
export type Delete_TitleMutationResult = ApolloReactCommon.MutationResult<Delete_TitleMutation>;
export type Delete_TitleMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_TitleMutation, Delete_TitleMutationVariables>;
export const UsersDocument = gql`
    query Users {
  user(where: {deleted: {_eq: false}}) {
    id
    first_name
    other_name
    last_name
    gender_id
    gender {
      name
    }
    date_of_birth
    mobile
    email
    created_at
    updated_at
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const AdminsDocument = gql`
    query Admins {
  user(where: {deleted: {_eq: false}, user_roles: {role: {name: {_eq: "admin"}}}}) {
    id
    first_name
    other_name
    last_name
    gender_id
    gender {
      name
    }
    date_of_birth
    mobile
    email
    created_at
    updated_at
  }
}
    `;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, baseOptions);
      }
export function useAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, baseOptions);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = ApolloReactCommon.QueryResult<AdminsQuery, AdminsQueryVariables>;
export const PortfolioAnalystsDocument = gql`
    query PortfolioAnalysts {
  user(where: {deleted: {_eq: false}, user_roles: {role: {name: {_eq: "portfolio-analyst"}}}}) {
    id
    first_name
    other_name
    last_name
    gender_id
    gender {
      name
    }
    date_of_birth
    mobile
    email
    created_at
    updated_at
  }
}
    `;

/**
 * __usePortfolioAnalystsQuery__
 *
 * To run a query within a React component, call `usePortfolioAnalystsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioAnalystsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioAnalystsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioAnalystsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PortfolioAnalystsQuery, PortfolioAnalystsQueryVariables>) {
        return ApolloReactHooks.useQuery<PortfolioAnalystsQuery, PortfolioAnalystsQueryVariables>(PortfolioAnalystsDocument, baseOptions);
      }
export function usePortfolioAnalystsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PortfolioAnalystsQuery, PortfolioAnalystsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PortfolioAnalystsQuery, PortfolioAnalystsQueryVariables>(PortfolioAnalystsDocument, baseOptions);
        }
export type PortfolioAnalystsQueryHookResult = ReturnType<typeof usePortfolioAnalystsQuery>;
export type PortfolioAnalystsLazyQueryHookResult = ReturnType<typeof usePortfolioAnalystsLazyQuery>;
export type PortfolioAnalystsQueryResult = ApolloReactCommon.QueryResult<PortfolioAnalystsQuery, PortfolioAnalystsQueryVariables>;
export const BackOfficersDocument = gql`
    query BackOfficers {
  user(where: {deleted: {_eq: false}, user_roles: {role: {name: {_eq: "backofficer"}}}}) {
    id
    first_name
    other_name
    last_name
    gender_id
    gender {
      name
    }
    date_of_birth
    mobile
    email
    created_at
    updated_at
  }
}
    `;

/**
 * __useBackOfficersQuery__
 *
 * To run a query within a React component, call `useBackOfficersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackOfficersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackOfficersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackOfficersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BackOfficersQuery, BackOfficersQueryVariables>) {
        return ApolloReactHooks.useQuery<BackOfficersQuery, BackOfficersQueryVariables>(BackOfficersDocument, baseOptions);
      }
export function useBackOfficersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BackOfficersQuery, BackOfficersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BackOfficersQuery, BackOfficersQueryVariables>(BackOfficersDocument, baseOptions);
        }
export type BackOfficersQueryHookResult = ReturnType<typeof useBackOfficersQuery>;
export type BackOfficersLazyQueryHookResult = ReturnType<typeof useBackOfficersLazyQuery>;
export type BackOfficersQueryResult = ApolloReactCommon.QueryResult<BackOfficersQuery, BackOfficersQueryVariables>;
export const Update_UserDocument = gql`
    mutation update_user($id: bigint, $user_changes: user_set_input) {
  update_user(where: {id: {_eq: $id}}, _set: $user_changes) {
    affected_rows
    returning {
      id
      first_name
      other_name
      last_name
      gender_id
      gender {
        name
      }
      date_of_birth
      mobile
      email
      created_at
      updated_at
    }
  }
}
    `;
export type Update_UserMutationFn = ApolloReactCommon.MutationFunction<Update_UserMutation, Update_UserMutationVariables>;

/**
 * __useUpdate_UserMutation__
 *
 * To run a mutation, you first call `useUpdate_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdate_UserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user_changes: // value for 'user_changes'
 *   },
 * });
 */
export function useUpdate_UserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Update_UserMutation, Update_UserMutationVariables>) {
        return ApolloReactHooks.useMutation<Update_UserMutation, Update_UserMutationVariables>(Update_UserDocument, baseOptions);
      }
export type Update_UserMutationHookResult = ReturnType<typeof useUpdate_UserMutation>;
export type Update_UserMutationResult = ApolloReactCommon.MutationResult<Update_UserMutation>;
export type Update_UserMutationOptions = ApolloReactCommon.BaseMutationOptions<Update_UserMutation, Update_UserMutationVariables>;
export const Delete_UserDocument = gql`
    mutation delete_user($id: bigint, $doneBy: bigint) {
  update_user(where: {id: {_eq: $id}}, _set: {deleted: true, modified_by: $doneBy}) {
    returning {
      id
    }
    affected_rows
  }
}
    `;
export type Delete_UserMutationFn = ApolloReactCommon.MutationFunction<Delete_UserMutation, Delete_UserMutationVariables>;

/**
 * __useDelete_UserMutation__
 *
 * To run a mutation, you first call `useDelete_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDelete_UserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      doneBy: // value for 'doneBy'
 *   },
 * });
 */
export function useDelete_UserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Delete_UserMutation, Delete_UserMutationVariables>) {
        return ApolloReactHooks.useMutation<Delete_UserMutation, Delete_UserMutationVariables>(Delete_UserDocument, baseOptions);
      }
export type Delete_UserMutationHookResult = ReturnType<typeof useDelete_UserMutation>;
export type Delete_UserMutationResult = ApolloReactCommon.MutationResult<Delete_UserMutation>;
export type Delete_UserMutationOptions = ApolloReactCommon.BaseMutationOptions<Delete_UserMutation, Delete_UserMutationVariables>;