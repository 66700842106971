import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';
import theme from '../../theme/theme';
import Separator from '../separator/Separator';
/**@jsx jsx*/

interface IProps {
  title: string;
  className?: string;
  id?: string;
  defaultState?: accordionState;
  isValid?: boolean;
  appearance?: 'compact' | 'loose';
}

type accordionState = 'expanded' | 'collapsed';

const Accordion: React.FC<IProps> = ({
  title,
  children,
  className,
  id,
  defaultState = 'expanded',
  isValid = false,
  appearance = 'loose',
}) => {
  const [state, setState] = React.useState<accordionState>(defaultState);

  function toggleAccordion() {
    if (state === 'expanded') {
      setState('collapsed');
      return;
    }
    setState('expanded');
  }

  return (
    <div
      css={{
        border:
          appearance === 'loose' ? `1px solid ${theme.colors.gray[100]}` : '',
        borderRadius: '6px',
        padding: appearance === 'loose' ? '24px' : '',
      }}
      className={className}
      id={id}>
      <Flex
        onClick={toggleAccordion}
        css={{ userSelect: 'none' }}
        jc="space-between"
        ai="center">
        <div css={{ width: '100%' }}>
          <Flex ai="center">
            {appearance === 'loose' && (
              <Icon
                size="xs"
                css={{ marginRight: '16px' }}
                color={theme.colors.gray[500]}
                icon={['fas', 'chevron-down']}></Icon>
            )}
            <h4 css={{ fontSize: '14px' }}>{title}</h4>
          </Flex>
          {appearance === 'compact' && <Separator />}
        </div>

        <div onClick={toggleAccordion}>
          {isValid && (
            <Avatar size="xs" background={theme.colors.green[100]}>
              <Icon
                size="xs"
                color={theme.colors.green[500]}
                icon={['fas', 'check']}></Icon>
            </Avatar>
          )}
        </div>
      </Flex>
      <div
        css={{
          maxHeight: state === 'collapsed' ? 0 : '3000px',
          overflow: state === 'collapsed' ? 'hidden' : 'visible',
          transition: state === 'collapsed' ? '.4s ease-out' : ' .7s ease-out',
          webkittransition: state === 'collapsed' ? '.4s' : ' .7s',
          MozTransition: state === 'collapsed' ? '.4s' : ' .7s',
          msTransition: state === 'collapsed' ? '.4s' : ' .7s'
        }}>
          <div css={{ marginTop: '20px'}}>
            {children}
          </div>
      </div>
    </div>
  );
};

export default Accordion;
