import React from 'react';
import { jsx } from '@emotion/core';
import Loader from '../loader/Loader';
import Icon from '../icon/Icon';
import useDropDown from '../../hooks/useDropDown';
import theme from '../../theme/theme';
import Portal from '../portal/Portal';
import usePostion from '../../hooks/usePosition';
import { ISelectAction } from '../../utils/types';
import Flex from '../layout/Flex';
import { useAuthState } from '../../context/auth';
/**@jsx jsx */

interface IProps {
  options: ISelectAction[];
  isLoading?: boolean;
  showBorder?: boolean;
  className?: string;
  label?: string;
}

const ActionSelect: React.FC<IProps> = ({
  options,
  isLoading = false,
  showBorder = false,
  className,
  label,
}) => {
  const { isOpen, handleVisibilityChange, elRef } = useDropDown(false, true);
  const position = usePostion(elRef);
  const { role } = useAuthState();

  return (
    <div
      ref={elRef}
      css={{ position: 'relative', width: label ? 'auto' : '32px' }}>
      {isOpen && options.length > 0 && (
        <Portal>
          <div
            css={{
              position: 'absolute',
              background: theme.colors.white,
              padding: '4px',
              top: `calc(${position?.y}px + 44px)`,
              left: `calc(${position?.x}px - 118px)`,
              border: `1px solid ${theme.colors.gray[100]}`,
              boxShadow: theme.shadows.min,
              zIndex: 102,
              minWidth: '160px',
              width: 'auto',
              maxWidth: '240px',
              borderRadius: '4px',
              animation: `${theme.animations.scaleUp} .5s`,
            }}>
            {options.map((option, index) => {
              if (option.allowed && !option.allowed?.includes(role as string)) {
                return null;
              }
              return (
                <button
                  className={option.className}
                  css={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    textTransform: 'capitalize',
                    borderRadius: '4px',
                    padding: '12px',
                    color: theme.colors.gray[500],
                    '&:hover': {
                      background: theme.colors.gray[50],
                    },
                  }}
                  key={index}
                  onClick={(e) => {
                    if (option.action) {
                      option.action();
                    }

                    handleVisibilityChange(e);
                  }}>
                  {option.label}
                </button>
              );
            })}
          </div>
        </Portal>
      )}
      <button
        onClick={handleVisibilityChange}
        className={className}
        css={{
          height: '40px',
          borderRadius: '4px',
          width: label ? 'auto' : '40px',
          zIndex: 101,
          position: 'relative',
          transition: 'boxShadow .5s',
          border: showBorder ? `1px solid ${theme.colors.gray[200]}` : '',
          '&:hover': {
            border: `1px solid ${theme.colors.gray[200]}`,
            // boxShadow: theme.shadows.min,
          },
        }}>
        {isLoading ? (
          <Loader size="small" />
        ) : label ? (
          <Flex jc="space-between" ai="center">
            <p
              css={{
                marginRight: '8px',
                fontFamily: theme.typography.fonts.semibold,
              }}>
              {label}
            </p>
            <div>
              <Icon
                color={theme.colors.blue[700]}
                size="sm"
                icon={['fas', 'chevron-down']}></Icon>
            </div>
          </Flex>
        ) : (
          <Icon
            size="lg"
            color={theme.colors.gray[400]}
            icon="ellipsis-h"></Icon>
        )}
      </button>
      {isOpen && (
        <div
          css={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            zIndex: 100,
            pointerEvents: 'none',
          }}></div>
      )}
    </div>
  );
};

export default ActionSelect;
