import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import MenuItem from './components/MenuItem';
import SubMenu from './components/SubMenu';
import { flattenRoutes } from '../../utils/helpers';
import { menuRoutes, IRoute } from '../../routes/routes';
import { AuthStateContext } from '../../context/auth';
/**@jsx jsx*/

interface IProps {
  openCurrentSubMenuOnly?: boolean;
  role?:
    | 'admin'
    | 'backofficer'
    | 'advisor'
    | 'anonymous'
    | 'portfolio'
    | 'client_service';
}
interface IState {
  openIds: string[];
}

class Sidebar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { openIds: [] };
  }

  componentDidMount() {
    const { role } = this.context;
    const { pathname } = window.location;

    const allRoutesFlat = menuRoutes
      .filter((route) => route.allowed?.includes(role))
      .map((route) => {
        return flattenRoutes(route);
      })
      .flat();
    const activeRoute = allRoutesFlat.find((route) => {
      return route.route === pathname;
    });

    const subMenus = allRoutesFlat.filter((route) => {
      return route.subId !== undefined;
    });

    function getActiveSubMenu() {
      let x: IRoute = { id: 0, name: '', route: '' };
      subMenus.forEach((s) => {
        s.children?.forEach((b: IRoute) => {
          if (b.route === activeRoute?.route) {
            x = s;
          }
        });
      });
      return x;
    }

    const activeSubMenu: IRoute = getActiveSubMenu();
    if (activeSubMenu?.children) {
      this.setState({
        openIds: activeSubMenu.parentSubIds
          ? [...activeSubMenu.parentSubIds, activeSubMenu.subId as string]
          : [...this.state.openIds, activeSubMenu.subId as string],
      });
    }
  }

  toggleSubMenu = (id: string, route?: IRoute) => {
    const { openIds } = this.state;
    const { openCurrentSubMenuOnly = false } = this.props;
    if (openIds.includes(id)) {
      const newOpenIds = openIds.filter((openId) => {
        return openId !== id;
      });
      this.setState({ openIds: newOpenIds });
      return;
    }
    if (openCurrentSubMenuOnly) {
      this.setState({
        openIds: route?.parentSubIds ? [...route.parentSubIds, id] : [id],
      });
      return;
    }
    this.setState({
      openIds: [...openIds, id],
    });
  };

  closeAllSubMenus = () => {
    this.setState({ openIds: [] });
  };

  render() {
    const { openIds } = this.state;
    const { pathname } = window.location;
    const { role = 'all' } = this.props;
    return (
      <div
        css={{
          background: theme.colors.gray[50],
          width: '100%',
          height: '100%',
          padding: ' 88px 0px',
          borderRight: `1px solid ${theme.colors.gray[100]}`,
          overflowY: 'scroll',
        }}>
        {menuRoutes.map((route, index) => {
          if (!route.allowed?.includes(role)) {
            return null;
          }
          if (route.children) {
            return (
              <SubMenu
                openIds={openIds}
                isExpanded={openIds.includes(route.subId as string)}
                key={index}
                route={route}
                onOpen={this.toggleSubMenu}></SubMenu>
            );
          }
          return (
            <MenuItem
              onClick={this.closeAllSubMenus}
              isActiveRoute={pathname === route.route}
              key={index}
              route={route}></MenuItem>
          );
        })}
      </div>
    );
  }
}

Sidebar.contextType = AuthStateContext;

export default Sidebar;
