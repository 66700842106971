import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { BrowserRouter } from 'react-router-dom';
import SecureLS from 'secure-ls';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './accessToken';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { startIconLibrary } from './components/icon/Icon';
import { AuthProvider } from './context/auth';
import 'whatwg-fetch';
import { CustomerProvider } from './context/customer';

startIconLibrary();

export const ls = new SecureLS({ encodingType: 'aes' });

const cache = new InMemoryCache({});

const abortController = new AbortController();
const signal = abortController.signal;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HASURA_ENDPOINT,
  fetch,
  credentials: 'include',
  headers: {
    'X-Hasura-Admin-Secret': process.env.REACT_APP_HASURA_ADMIN_SECRET,
  },
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_WS_ENDPOINT!,
  options: {
    reconnect: true,
    timeout: 30000,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((resolvedOperation) => {
          const accessToken = getAccessToken();

          resolvedOperation.setContext({
            headers: {
              authorization: accessToken ? `Bearer ${accessToken}` : '',
            },
          });
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) {
          return true;
        }

        try {
          const { exp } = jwtDecode(token);

          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch (error) {
          return false;
        }
      },
      fetchAccessToken: () =>
        fetch(`${process.env.REACT_APP_CBL_ENDPOINT}/auth/refresh_token`, {
          method: 'POST',
          credentials: 'include',
          signal: signal,
        }),
      handleFetch: (accessToken) => {
        setAccessToken(accessToken);
      },
      handleError: (err) => {
        // handling token fetch Error
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);
      },
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    splitLink,
  ]),
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <CustomerProvider>
            <App />
          </CustomerProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
