import React from 'react';
import { toast } from 'react-toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import PortfolioUsersTable from './components/PortfolioUsersTable';
import PortfolioUserModal from './components/PortfolioUserModal';
import {
  usePortfolioAnalystsQuery,
  useDelete_UserMutation,
} from '../../generated/graphql';
import { IUser } from '../../utils/types';
import moment from 'moment';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';

const PortfolioUsers: React.FC = () => {
  const { userId } = useAuthState();
  const { data: portfolioUser, loading } = usePortfolioAnalystsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteUser] = useDelete_UserMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedPortfolioUser, setSelectedPortfolioUser] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedPortfolioUser(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (portfolioUser: IUser) => {
    setIsOpen(true);
    setSelectedPortfolioUser(portfolioUser.id);
  };

  const deactivate = (portfolioUser: IUser) => {
    setSelectedPortfolioUser(portfolioUser.id);
    setShowConfirm(true);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const deactivateUser = async () => {
    const response = await deleteUser({
      variables: {
        id: selectedPortfolioUser,
        doneBy: userId,
      },
      refetchQueries: ['PortfolioUsers'],
      awaitRefetchQueries: true,
    });
    setShowConfirm(false);

    if (response.data?.update_user?.affected_rows) {
      notify(true, 'Portfolio user deactivated successfully.');
    } else {
      notify(false, 'failed to deactivate portfolio analyst.');
    }
  };

  const getPortfolioUser = (id?: number) => {
    if (id) {
      const user = portfolioUser?.user?.find((user) => user.id === id);
      if (user) {
        return {
          id: user.id,
          firstName: user.first_name as string,
          lastName: user.last_name as string,
          otherName: user.other_name ?? '',
          gender: user.gender_id,
          dateOfBirth: moment(user.date_of_birth).toDate(),
          mobile: user.mobile as string,
          email: user.email as string,
          username: user.email as string,
          password: 'advisor',
        };
      }
    }
    return;
  };

  const getPortfolioUsers = (): IUser[] => {
    return (
      portfolioUser?.user?.map((user) => ({
        id: user.id,
        fullName: `${user.first_name ?? ''} ${user.other_name ?? ''} ${
          user.last_name ?? ''
        }`,
        gender: user.gender.name as string,
        gender_id: user.gender_id,
        dateOfBirth: user.date_of_birth,
        mobile: user.mobile as string,
        email: user.email as string,
        createdAt: user.created_at,
        lastChanged: user.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && <PortfolioUserModal onClose={close} portfolioUser={getPortfolioUser(selectedPortfolioUser)} />}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: deactivateUser }}
          onClose={close}
          title="Deactivate Portfolio Analyst"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Portfolio Analysts"
        action={{
          label: 'Add new Portfolio Analyst',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <PortfolioUsersTable
        isLoading={loading}
        portfolioUsers={getPortfolioUsers()}
        actions={[
          { label: 'Deactivate', action: deactivate },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default PortfolioUsers;
