import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_GenderMutation,
  useAdd_GenderMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type IGenderModal = IModal & {
  gender?: { id: number; name: string };
};

const GenderSchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const GenderModal: React.FC<IGenderModal> = ({ onClose, gender }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addGender,
    { data: addGenderResult, loading: addGenderLoading, error: addGenderError },
  ] = useAdd_GenderMutation();

  const [
    updateGender,
    {
      data: updateGenderResult,
      loading: updateGenderLoading,
      error: updateGenderError,
    },
  ] = useUpdate_GenderMutation();

  React.useEffect(() => {
    if (gender) {
      setInitialValues({
        name: gender.name,
      });
    }
  }, [gender]);

  const addNewGender = (values: ValueProps) => {
    addGender({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addGender,
    });
  };

  const upateExistingGender = (values: ValueProps) => {
    updateGender({
      variables: {
        id: gender?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (gender) {
      upateExistingGender(values);
    } else {
      addNewGender(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addGenderLoading || updateGenderLoading);
  const error = !!(addGenderError || updateGenderError);
  const success = !!(
    addGenderResult?.insert_gender?.returning?.length ||
    updateGenderResult?.update_gender?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={GenderSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${gender ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${gender ? 'Update' : 'Add'} Gender`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Gender ${gender ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Gender Name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default GenderModal;
