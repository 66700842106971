import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import theme from '../../../theme/theme';
/**@jsx jsx*/

interface IProps {
  isHeader?: boolean;
  onClick?: (args: any) => void;
  path?: string;
  className?: string;
  colSpan?: number;
}

const defaultCellStyles: CSSObject = {
  textAlign: 'left',
  padding: '12px 12px',
  fontSize: '14px',
};

const Cell: React.FC<IProps> = ({ children, isHeader = false, ...rest }) => {
  if (isHeader) {
    return (
      <th
        css={{
          ...defaultCellStyles,
          background: theme.colors.primary,
          color: theme.colors.white,
          textTransform: 'capitalize',
        }}
        {...rest}>
        {children}
      </th>
    );
  }
  return (
    <td {...rest} css={{ ...defaultCellStyles }}>
      {children}
    </td>
  );
};

export default Cell;
