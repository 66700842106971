import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../components/layout/Flex';
import Avatar from '../../components/avatar/Avatar';
import Grid, { GridItem } from '../../components/layout/Grid';
import theme from '../../theme/theme';
import Separator from '../../components/separator/Separator';
import Button from '../../components/button/Button';
import Stack from '../../components/stack/Stack';
import StatementModal from './components/StatementModal';
import { useParams } from 'react-router-dom';
import MiniNav from '../prospects/components/MiniNav';
import { toast } from 'react-toastify';
import Toastify from '../../components/toastify/Toastify';
import Accordion from '../../components/accordion/Accordion';
import LabeledValue from '../../components/labeledValue/LabeledValue';
import {
  useProspectDetailQuery,
  useSend_Welcome_PackMutation,
} from '../../generated/graphql';
import Goal from '../prospects/components/Goal';
import InfoCard from '../../components/card/InfoCard';
import CustomerDetailModal, {
  ICustomerDetailModalConfig,
} from './components/CustomerDetailModal';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import Loader from '../../components/loader/Loader';
import RedemptionModal from './components/RedemptionModal';
import ActionSelect from '../../components/select/ActionSelect';
import {
  customer_type,
  sections,
  AccountType,
  individualResidentialInfo,
  jointResidentialInfo,
  individualOccupationInfo,
  jointOccupationInfo,
  individualBioInfo,
  jointBioInfo,
  nextofkinInfo,
  beneficiaryInfo,
  trustBioInfo,
  trustInfo
} from '../../shared/sections';
import { useAuthState } from '../../context/auth';
/**@jsx jsx*/

function formatLabel(label: string): string {
  if (!label) {
    return '';
  }
  if (['id', 'ID'].includes(label.split('_')[0])) {
    return `IDENTIFICATION ${label.split('_')[1]}`
  }
  if (label.slice(-2) === 'jt') {
    return label.slice(0, -3)
  }
  return label.split('_').join(' ');
}

function formatAccountType(label: string | undefined) {
  if (label === customer_type.individual) {
    return AccountType.individual;
  }
  if (label === customer_type.trust) {
    return AccountType.trust;
  }
  if (label === customer_type.joint) {
    return AccountType.joint;
  }
}
export function extractObjectFields(fields: string[], object: any) {
  if (!object) {
    return;
  }
  let newObject: any = {};
  fields.forEach((field) => {
    newObject[field] = object[field];
  });
  return newObject;
}

export const InfoDisplayGrid: React.FC<{
  columns?: number;
  object?: { [key: string]: any };
  ignore?: string[];
  extraAttributes?: [{ [key: string]: any }];
}> = ({ columns = 3, object = {}, ignore, extraAttributes }) => {
  const keys = Object.keys(object);
  const a = keys.map((key: string) => {
    if (object[key] === null) {
      return { label: key, value: '' };
    }

    if (typeof object[key] !== 'string' && typeof object[key] !== 'number') {
      return object[key]['name']
        ? { label: key, value: object[key]['name'] }
        : { label: key, value: '' };
    }
    return { label: key, value: object[key] };
  });
  return (
    <Grid lg={columns}>
      {a.map((data, index) => {
        if (ignore && ignore.includes(data.label)) {
          return null;
        }
        if (data.label === 'id' || data.label === 'guid') {
          return null;
        }
        return (
          <LabeledValue
            key={index}
            label={formatLabel(data.label)}
            value={data.value}></LabeledValue>
        );
      })}
      {
        extraAttributes?.map((data: any, index) => {
          return (
            <LabeledValue
              key={index}
              label={formatLabel(data.label)}
              value={data.value}></LabeledValue>
          );
        })
      }
    </Grid>
  );
};


const ViewCustomer: React.FC = () => {
  const [statementModalIsOpen, setStatementModalIsOpen] = React.useState(false);
  const [detailModalIsOpen, setDetailModalIsOpen] = React.useState(false);
  const [redemptionModalIsOpen, setRedemptionModalIsOpen] = React.useState(false);
  const [detailModalData, setDetailModalData] = React.useState<
    ICustomerDetailModalConfig
  >({
    title: '',
  });

  const [sendWelcomePack] = useSend_Welcome_PackMutation();

  const { id } = useParams();
  const { role } = useAuthState()

  const { data: prospect, loading } = useProspectDetailQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const sendWelcomePackHandler = async () => {
    try {
      const { data } = await sendWelcomePack({
        variables: {
          id: prospect?.member?.[0].id,
        },
        awaitRefetchQueries: true,
      });

      if (data?.sendWelcomePack.success) {
        notify(true, 'Welcome Pack sent successfully');
      } else {
        notify(true, 'Welcome Pack sending failed');
      }
    } catch (err) {
      notify(true, 'Welcome Pack sending failed');
    }
  };

  const handleEditCustomer = (id: number) => {
    if (prospectData?.account_type === customer_type.individual) {
      return `/dashboard/customers/${id}/edit/individual`
    }
    if (prospectData?.account_type === customer_type.joint) {
      return `/dashboard/customers/${id}/edit/joint`
    }
    if (prospectData?.account_type === customer_type.trust) {
      return `/dashboard/customers/${id}/edit/trust`
    }
  }


  const prospectData = prospect?.member?.[0];

  // check if customer is a joint or trust account type
  var isJointAccount = prospectData?.account_type === customer_type.joint
  var isTrustAccount = prospectData?.account_type === customer_type.trust
  var isIndividualAndJoint = prospectData?.account_type === customer_type.individual || prospectData?.account_type === customer_type.joint

  // construct source of funds data
  const memberIncomeSources = prospectData?.member_income_sources.filter(src => src.income_source_id !== 5)?.map((item) => item.income_source.name)
    .join(', ');
  const memberContributionSources = prospectData?.member_contribution_sources.filter(src => src.contribution_source_id !== 6)?.map((item) => item.contribution_source.name)
    .join(', ');

  // construct mode of instruction data
  const memberModeOfInstructions = prospectData?.member_mode_of_instruction_communications.filter(src => src.mode_of_instruction_communication_id !== 4)
    ?.map((item) => item.mode_of_instruction_communication.name)
    .join(', ');

  function handleViewCustomerDetail(config: ICustomerDetailModalConfig): void {
    setDetailModalData(config);
    setDetailModalIsOpen(true);
  }
  return (
    <React.Fragment>
      {redemptionModalIsOpen && (
        <RedemptionModal
          onClose={() => {
            setRedemptionModalIsOpen(false);
          }}
        />
      )}
      {statementModalIsOpen && (
        <StatementModal
          onClose={() => {
            setStatementModalIsOpen(false);
          }}
          customerId={id}
        />
      )}
      {detailModalIsOpen && (
        <CustomerDetailModal
          onClose={() => {
            setDetailModalIsOpen(false);
          }}
          title={detailModalData.title}
          data={detailModalData.data}
          render={detailModalData.render}
          ignore={detailModalData.ignore}
        />
      )}
      <SectionTitle gap={48} useBreadCrumb></SectionTitle>

      {loading ? (
        <Flex css={{ height: '200px' }} ai="center" jc="center">
          <Loader
            size="small"
            color={theme.colors.primary}
            label="Loading Customer Data"
          />
        </Flex>
      ) : (
        <Grid>
          <GridItem span={2}>
            <div css={{ position: 'fixed' }}>
              <MiniNav sections={sections} />
            </div>
          </GridItem>
          <GridItem span={10}>
            <div css={{ marginBottom: '48px' }}>
              <Flex ai="flex-end" jc="space-between">
                <Flex ai="center">
                  <Avatar
                    background={theme.colors.blue[50]}
                    css={{
                      marginRight: '16px',
                      color: theme.colors.primary,
                    }}
                    size="md"
                    name={`${prospectData?.user?.first_name ?? ''} ${prospectData?.user?.last_name ?? ''
                      }`}></Avatar>
                  <div>
                    <h2 css={{ fontSize: '14px' }}>{`${prospectData?.user?.first_name ?? ''
                      } ${prospectData?.user?.last_name ?? ''}`}</h2>
                    <p
                      css={{ color: theme.colors.gray[400], fontSize: '12px' }}>
                      {prospectData?.user.email}
                    </p>
                  </div>
                </Flex>
                <Stack spacing={12} isInline>
                  <Button
                    action={() => {
                      setStatementModalIsOpen(true);
                    }}>
                    Get Statement
                  </Button>

                  <Button appearance="outline" action={sendWelcomePackHandler}>
                    {`${prospect?.member[0]?.welcome_pack_sent_at
                      ? 'Resend '
                      : 'Send '
                      }Welcome Pack`}
                  </Button>

                  {role !== 'advisor' &&
                    <Button
                      appearance="outline"
                      path={handleEditCustomer(id)}>
                      Edit Customer
                    </Button>
                  }

                  <ActionSelect
                    showBorder
                    label="more"
                    options={[
                      // { label: 'Create Ticket' },
                      // { label: 'Generate Date of Birth' },
                      {
                        label: 'Redeem',
                        action: () => setRedemptionModalIsOpen(true),
                      },
                    ]}
                  />
                </Stack>
              </Flex>
              <Separator />
            </div>

            <div>
              <Stack>
                <Accordion id="acceptedgoals" title="Accepted Goals">
                  <Grid lg={3}>
                    {prospectData?.member_goals.map((goal, index) => {
                      return (
                        <Goal
                          onView={() => {
                            handleViewCustomerDetail({
                              title: goal.goal.name,
                              data: goal,
                            });
                          }}
                          mode="read"
                          data={goal as any}
                          key={index}
                          actions={
                            [
                              // {
                              //   label: 'Redeem',
                              //   buttonType: 'outline',
                              //   function: () => {
                              //     setRedemptionModalIsOpen(true);
                              //   },
                              // },
                            ]
                          }
                        />
                      );
                    })}
                  </Grid>
                </Accordion>

                <Accordion id="bio" title="Bio">
                  <Grid lg={3}>
                    <LabeledValue
                      label="Account Type"
                      value={formatAccountType(prospectData?.account_type?.toString())} />
                    <LabeledValue
                      label="MF Code"
                      value={prospectData?.mf_code?.toString()} />
                  </Grid>
                  <Separator gap={26} />
                  {isJointAccount && <SectionTitle title="Applicant 1 Details"></SectionTitle>}
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      individualBioInfo,
                      prospectData?.user,
                    )} ignore={['__typename']}
                    extraAttributes={[
                      { label: "Marital Status", value: prospectData?.marital_status?.name },]}
                  >
                  </InfoDisplayGrid>
                  {/* joint account bio */}
                  {isJointAccount &&
                    <React.Fragment>
                      <Separator gap={26} />
                      <SectionTitle title="Applicant 2 Details"></SectionTitle>
                      <InfoDisplayGrid object={
                        extractObjectFields(
                          jointBioInfo,
                          prospectData?.user)}
                        extraAttributes={[
                          { label: "Marital Status", value: prospectData?.marital_status_jt?.name },]}

                      />
                    </React.Fragment>
                  }
                </Accordion>

                <Accordion id="residentialinfo" title="Residential Info">
                  {isJointAccount && <SectionTitle title="Applicant 1 Residential Info"></SectionTitle>}
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      individualResidentialInfo,
                      prospectData)}
                  />

                  {/* joint account residentialinfo */}
                  {isJointAccount &&
                    <React.Fragment>
                      <Separator gap={26} />
                      <SectionTitle title="Applicant 2 Residential Info"></SectionTitle>
                      <InfoDisplayGrid object={extractObjectFields(
                        jointResidentialInfo,
                        prospectData
                      )} />
                    </React.Fragment>
                  }
                </Accordion>

                <Accordion id="occupation" title="Occupation">
                  {isJointAccount && <SectionTitle title="Applicant 1 Occupation"></SectionTitle>}
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      individualOccupationInfo,
                      prospectData
                    )}
                  />
                  {/* joint account occupation */}
                  {isJointAccount &&
                    <React.Fragment>
                      <Separator gap={26} />
                      <SectionTitle title="Applicant 2 Occupation"></SectionTitle>
                      <InfoDisplayGrid object={extractObjectFields(
                        jointOccupationInfo,
                        prospectData
                      )} />
                    </React.Fragment>
                  }
                </Accordion>

                <Accordion id="sourceoffunds" title="Source of Funds">
                  <Grid lg={2}>
                    <div>
                      <LabeledValue
                        key={1}
                        label={formatLabel('Source Of Funds')}
                        value={memberIncomeSources}></LabeledValue>
                    </div>
                    <div>
                      <LabeledValue
                        key={2}
                        label={formatLabel('Source Of Contribution')}
                        value={memberContributionSources}></LabeledValue>
                    </div>
                  </Grid>
                </Accordion>

                <Accordion
                  id="expectedaccountactivity"
                  title="Expected Account Activity">
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      [
                        'initial_investment_amount',
                        'regular_top_up_amount',
                        'regular_withdrawal_amount',
                        'anticipated_investment_activity_top_ups',
                        'anticipated_investment_activity_withdrawals',
                      ],
                      prospectData
                    )}></InfoDisplayGrid>
                </Accordion>

                <Accordion
                  id="investmentprofile"
                  title="Investment Profile">
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      [
                        'investment_objective',
                        'investment_knowledge',
                        'risk_tolerance',
                        'investment_horizon',
                        'approximate_annual_income',
                      ],
                      prospectData?.client_investment_profile
                    )}></InfoDisplayGrid>
                </Accordion>

                <Accordion
                  id="accountmandate"
                  title="Account Manadate">
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      [
                        'account_mandate'
                      ],
                      prospectData
                    )}></InfoDisplayGrid>
                </Accordion>

                <Accordion id="statementservice" title="Statement services">
                  <InfoDisplayGrid
                    object={extractObjectFields(
                      [
                        'mode_of_statement_delivery',
                        'statement_frequency',
                      ],
                      prospectData
                    )}
                  />
                </Accordion>
                {
                  isTrustAccount &&
                  <Accordion id="intrustfor" title="In Trust For">
                    <InfoDisplayGrid object={
                      extractObjectFields(
                        trustBioInfo,
                        prospectData?.user)}
                    />
                    <Separator gap={20} />
                    <InfoDisplayGrid
                      object={extractObjectFields(
                        trustInfo,
                        prospectData
                      )}>
                    </InfoDisplayGrid>
                  </Accordion>
                }
                {
                  isIndividualAndJoint &&
                  <Accordion id="nextofkin" title="Next of Kin">
                    <InfoDisplayGrid
                      object={extractObjectFields(
                        nextofkinInfo,
                        prospectData?.next_of_kin
                      )}
                      ignore={['__typename']}></InfoDisplayGrid>
                  </Accordion>
                }
                <Accordion id="beneficiaries" title="Beneficiaries">
                  <Grid lg={3}>
                    {prospectData?.beneficiaries.map((beneficiary, index) => {
                      return (
                        <InfoCard
                          initials={beneficiary.first_name}
                          title={`${beneficiary.last_name} ${beneficiary.first_name}`}
                          subText={`${beneficiary.mobile ?? ''}, ${beneficiary.mobile2 ?? ''}`}
                          key={index}
                          action={{
                            label: 'View More',
                            function: () => {
                              // filter country of origin
                              handleViewCustomerDetail({
                                title: 'Beneficiary',
                                data: extractObjectFields(beneficiaryInfo, beneficiary),
                                ignore: ['__typename', 'country_of_origin_id']
                              });
                            },
                          }}></InfoCard>
                      );
                    })}
                  </Grid>
                </Accordion>
                <Accordion id="dependents" title="Dependents">
                  <Grid lg={3}>
                    {prospectData?.member_dependents.map((dependent, index) => {
                      return (
                        <InfoCard
                          action={{
                            label: 'View More',
                            function: () => {
                              handleViewCustomerDetail({
                                title: 'Dependent',
                                data: dependent,
                                ignore: ['__typename']
                              });
                            },
                          }}
                          initials={dependent?.name ?? ''}
                          key={index}
                          title={dependent?.name ?? ''}></InfoCard>
                      );
                    })}
                  </Grid>
                </Accordion>
                <Accordion id="paymentchannels" title="Payment Channels">
                  <Grid lg={3}>
                    {prospectData?.member_payment_instructions.filter(paymentInfo => paymentInfo.payment_provider.id !== 30)?.map(
                      (instruction, index) => {
                        return (
                          <InfoCard
                            action={{
                              label: 'View More',
                              function: () => {
                                handleViewCustomerDetail({
                                  title: 'Payment Channel',
                                  data: instruction,
                                  ignore: ['__typename']
                                });
                              },
                            }}
                            key={index}
                            title={instruction.payment_provider.name ?? ''}
                            subText={instruction?.account_number?.length ? `${instruction?.account_number}` : `${instruction?.mobile_money_number}`}
                            icon="building-columns"></InfoCard>
                        );
                      }
                    )}
                  </Grid>
                </Accordion>
                <Accordion id="modeofinstruction" title="Mode of Instruction">
                  <Grid lg={2}>
                    <div>
                      <LabeledValue
                        key={1}
                        label={formatLabel('Mode of Instruction')}
                        value={memberModeOfInstructions}></LabeledValue>
                    </div>
                  </Grid>
                </Accordion>
                <Accordion id="identification" title="Identification">
                  <Grid lg={3}>
                    {prospectData?.member_identifications.map((id, index) => {
                      return (
                        <InfoCard
                          action={{
                            label: 'View More',
                            function: () => {
                              handleViewCustomerDetail({
                                title: 'Identification',
                                render: () => {
                                  return (
                                    <div>
                                      <div
                                        css={{
                                          width: '300px',
                                          height: '200px',
                                          background: theme.colors.gray[100],
                                          borderRadius: '4px',
                                          marginBottom: '36px',
                                          overflow: 'hidden',
                                        }}>
                                        <img width="100%" src={id.identification_card_image_url ?? ""} alt="" />
                                      </div>
                                      <InfoDisplayGrid
                                        object={id}
                                        ignore={['__typename']}></InfoDisplayGrid>
                                    </div>
                                  );
                                },
                              });
                            },
                          }}
                          icon="id-card"
                          key={index}
                          title={id.identification_type.name}
                          subText={id.identification_number}></InfoCard>
                      );
                    })}

                    <InfoCard
                      action={{
                        label: 'View Signature',
                        function: () => {
                          handleViewCustomerDetail({
                            title: 'Signaure',
                            render: () => {
                              return (
                                <Flex jc="center">
                                  <div
                                    css={{
                                      width: '300px',
                                      height: '300px',
                                      background: theme.colors.gray[200],
                                      borderRadius: '4px',
                                    }}>
                                    <img
                                      width="100%"
                                      src={prospectData?.signature ?? ''}
                                      alt=""
                                    />
                                  </div>
                                </Flex>
                              );
                            },
                          });
                        },
                      }}
                      icon="signature"
                      title="Signature"></InfoCard>
                  </Grid>
                </Accordion>
              </Stack>
            </div>
          </GridItem>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ViewCustomer;
