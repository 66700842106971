import React from 'react';
import { jsx } from '@emotion/core';
import moment from 'moment';
import { IApk } from '../../../utils/types';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

type Actions = { label: string; action: (gender: IApk) => void }[];

interface IProps {
  apks?: IApk[];
  actions: Actions;
  isLoading?: boolean;
}

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Version',
    dataIndex: 'version',
    key: 'version',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: Date) => {
      return <span>{moment(createdAt).format('DD/MM/YYYY')}</span>;
    },
  },
  {
    title: 'Last Changed',
    dataIndex: 'lastChanged',
    key: 'lastChanged',
    render: (lastChanged: Date) => {
      return <span>{moment(lastChanged).format('DD/MM/YYYY')}</span>;
    },
  },
];

const ApksTable: React.FC<IProps> = ({ apks = [], actions, isLoading }) => {
  return (
    <DataTable
      showControls
      showPagination
      data={apks}
      columns={columns}
      actions={actions}
      searchFilter={['name', 'version']}
      isLoading={isLoading}></DataTable>
  );
};

export default ApksTable;
