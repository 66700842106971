import React from 'react';
import { jsx, keyframes } from '@emotion/core';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
/**@jsx jsx */

interface IProps {
  scheme?: 'light' | 'dark';
  color?: string;
  label?: string;
  size?: 'small' | 'large' | 'xsmall';
  className?: string;
}

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg)
  }
  to{
    transform: rotate(360deg)
  }
`;

const sizes = {
  small: '24px',
  large: '32px',
  xsmall: '16px',
};

const Loader: React.FC<IProps> = ({
  label,
  scheme = 'light',
  size = 'large',
  color = theme.colors.white,
  className,
}) => {
  return (
    <Flex
      className={className}
      ai="center"
      css={{
        p: {
          color:
            scheme === 'light' ? theme.colors.gray[400] : theme.colors.white,
        },
      }}>
      <div
        css={{
          height: sizes[size],
          width: sizes[size],
          borderRadius: '50%',
          borderWidth: size === 'large' ? '4px' : '3px',
          borderStyle: 'solid',
          borderColor: `${color} ${color} ${theme.colors.transparent} ${theme.colors.transparent}`,
          animation: `${loadingAnimation} 1.0s infinite`,
          marginRight: label && '15px',
          flexShrink: 0,
        }}></div>
      {label && (
        <p css={{ fontSize: size === 'xsmall' ? '12px' : '14px' }}>{label}</p>
      )}
    </Flex>
  );
};

export default Loader;
