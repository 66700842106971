import React from 'react';

const usePostion = (elementRef: React.RefObject<HTMLElement>) => {
  const [position, setPosition] = React.useState<DOMRect | null>(null);

  function getPosition() {
    return elementRef.current && elementRef.current.getBoundingClientRect();
  }

  function handleSetPosition() {
    setPosition(getPosition());
  }

  React.useEffect(() => {
    handleSetPosition();
    window.addEventListener('resize', () => {
      handleSetPosition();
    });
    return window.removeEventListener('resize', () => {
      handleSetPosition();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return position;
};

export default usePostion;
