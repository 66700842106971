import React from 'react';
import { jsx } from '@emotion/core';
import InputLabel from '../input/components/InputLabel';
import { IInputProps } from '../../utils/types';
import { inputStyles } from '../input/Input';
/**@jsx jsx*/

interface IProps extends IInputProps {
  canResize?: boolean;
}

const TextArea: React.FC<IProps> = ({
  label,
  name,
  id,
  placeholder,
  onChange,
  canResize = false,
  className,
}) => {
  return (
    <div className={className}>
      <InputLabel id={id}>{label}</InputLabel>
      <textarea
        placeholder={placeholder}
        css={{
          ...inputStyles,
          height: '60px',
          padding: '16px',
          resize: canResize ? 'horizontal' : 'none',
        }}
        name={name}
        id={id}
        onChange={onChange}></textarea>
    </div>
  );
};

export default TextArea;
