import React from 'react';

const useDropDown = (
  isOpenArg: boolean = false,
  shouldToggle: boolean = false
) => {
  const [isOpen, setIsOpen] = React.useState(isOpenArg);
  const elRef = React.useRef<HTMLDivElement>(null);

  function handleOutsideClick(e: any) {
    if (elRef.current && elRef.current.contains(e.target)) {
      return;
    }
    if (e.target.dataset.block) {
      return;
    }
    setIsOpen(false);
    document.removeEventListener('click', handleOutsideClick);
  }

  function handleVisibilityChange(e: any) {
    e.stopPropagation();

    if (isOpen && shouldToggle) {
      setIsOpen(false);
      document.removeEventListener('click', handleOutsideClick);
      return;
    }

    setIsOpen(true);
    document.addEventListener('click', handleOutsideClick);
  }

  return {
    isOpen,
    setIsOpen,
    elRef,
    handleVisibilityChange,
    handleOutsideClick,
  };
};

export default useDropDown;
