import React from 'react';
import { jsx } from '@emotion/core';
import Card from '../card/Card';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import Icon from '../icon/Icon';
import { overflow } from '../../utils/types'
/**@jsx jsx*/

interface IStatData {
  value: number | string;
  growth?: number;
  footnote?: string;
}

interface IProps {
  title: string;
  data?: IStatData;
  cardOverflow?: overflow
}

const StatCard: React.FC<IProps> = ({ title, data, children, cardOverflow }) => {
  return (
    <Card overflow={cardOverflow}>
      <div
        css={{
          color: theme.colors.gray[500],
          borderBottom: `1px solid ${theme.colors.gray[200]}`,
          padding: '12px 24px',
        }}>
        {title}
      </div>

      {data && (
        <div css={{ padding: '16px 24px' }}>
          <Flex css={{ marginBottom: '8px' }} ai="center">
            <h1 css={{ marginRight: '8px' }}>{data.value}</h1>
            {data.growth && (
              <Flex ai="center">
                <Icon
                  style={{ marginRight: '8px' }}
                  size="lg"
                  icon={['fas', 'sort-up']}
                  color={theme.colors.green[400]}
                />
                <p css={{ color: theme.colors.gray[500] }}>{data.growth}%</p>
              </Flex>
            )}
          </Flex>
          {data.footnote && (
            <p css={{ fontSize: '12px', color: theme.colors.gray[400] }}>
              {data.footnote}
            </p>
          )}
        </div>
      )}
      {children}
    </Card>
  );
};

export default StatCard;
