import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
import Icon from '../icon/Icon';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IRoute, allRoutes } from '../../routes/routes';
/**@jsx jsx*/

interface IProps {
  routes?: IRoute[];
  root?: string;
}

function generateRoutes(paths: string[], root: string) {
  return paths.map((path, index) => {
    const route = `/${root}/${paths.slice(0, index + 1).join('/')}`;
    return route;
  });
}

const BreadCrumb: React.FC<IProps> = ({ routes, root = 'dashboard' }) => {
  const { pathname } = useLocation();
  const params: any = useParams();
  const [internalRoutes, setInternalRoutes] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (routes) {
      return;
    }
    const pathnames = pathname.split('/').filter((path) => {
      return path !== root && path !== '';
    });
    const generatedRoutes = generateRoutes(pathnames, root);
    const routeObjects = generatedRoutes
      .map((pathname) => {
        return allRoutes.find((route) => {
          return route.route.replace(/:id/, params.id) === pathname;
        });
      })
      .filter((route) => route)
      .map((route) => {
        return { ...route, route: route?.route.replace(/:id/, params.id) };
      });
    setInternalRoutes(routeObjects);
  }, [pathname, root, params.id, routes]);

  const routesToRender = routes ? routes : internalRoutes;

  return (
    <Flex>
      {routesToRender.map((route: any, index: any) => {
        const isLastRoute = index === routesToRender.length - 1;
        if (isLastRoute) {
          return (
            <h1
              key={index}
              css={{
                color: theme.colors.darkBlue,
                fontFamily: theme.typography.fonts.semibold,
                fontSize: '16px',
                textTransform: 'capitalize',
              }}>
              {route.name}
            </h1>
          );
        }
        return (
          <Link to={route.route} css={{ marginRight: '8px' }} key={index}>
            <Flex ai="center">
              <h1
                css={{
                  color: theme.colors.gray[500],
                  fontFamily: theme.typography.fonts.semibold,
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  marginRight: '8px',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}>
                {route.name}
              </h1>

              <Icon
                size="xs"
                color={theme.colors.gray[400]}
                icon={['fas', 'chevron-right']}
              />
            </Flex>
          </Link>
        );
      })}
    </Flex>
  );
};

export default BreadCrumb;
