import React from 'react';
import { jsx } from '@emotion/core';
import Flex, { IFlex } from '../layout/Flex';
/**@jsx jsx*/

interface IProps extends IFlex {
  spacing?: number;
  isInline?: boolean;
  className?: string;
}

function cloneElement(element: any, props: any) {
  if (!element.type) {
    return;
  }

  return jsx(element.type, {
    key: element.key,
    ref: element.ref,
    ...element.props,
    ...props,
  });
}

const Stack: React.FC<IProps> = ({
  children,
  spacing = 16,
  isInline = false,
  className,
  ...rest
}) => {
  const count = React.Children.count(children);

  return (
    <Flex {...rest} className={className} stack={!isInline}>
      {React.Children.map(children, (child, index) => {
        if (!child) {
          return;
        }
        return cloneElement(child, {
          css: {
            marginBottom:
              isInline === false && index !== count - 1 && `${spacing}px`,
            marginRight:
              isInline === true && index !== count - 1 && `${spacing}px`,
          },
        });
      })}
    </Flex>
  );
};

export default Stack;
