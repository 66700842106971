import React from 'react';
import { toast } from 'react-toastify';
import theme from '../../theme/theme';
import DurationsTable from './components/DurationsTable';
import DurationModal from './components/DurationModal';
import {
  useAssetDurationQuery,
  useDelete_Asset_DurationMutation,
} from '../../generated/graphql';
import { IAssetDuration } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';

const Durations: React.FC = () => {
  const { userId } = useAuthState();
  const { data: durations, loading } = useAssetDurationQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAssetDuration] = useDelete_Asset_DurationMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedDuration, setSelectedDuration] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedDuration(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (duration: any) => {
    setIsOpen(true);
    setSelectedDuration(duration.id);
  };

  const remove = (duration: any) => {
    setShowConfirm(true);
    setSelectedDuration(duration.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const removeAssetDuration = async () => {
    const response = await deleteAssetDuration({
      variables: {
        id: selectedDuration,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteAssetDuration,
    });
    setShowConfirm(false);

    if (response.data?.update_asset_duration?.affected_rows) {
      notify(true, 'Asset duration deleted successfully.');
    } else {
      notify(false, 'failed to delete asset duration.');
    }
  };

  const getAssetDuration = (id?: number) => {
    if (id) {
      const duration = durations?.asset_duration?.find(
        (duration) => duration.id === id
      );
      if (duration) {
        return {
          id: duration.id,
          start: duration.duration_start.toString(),
          end: duration.duration_end.toString(),
        };
      }
    }
    return;
  };

  const getAssetDurations = (): IAssetDuration[] => {
    return (
      durations?.asset_duration?.map((duration) => ({
        id: duration.id,
        duration: `${duration.duration_start.toString()} - ${duration.duration_end.toString()}`,
        createdAt: duration.created_at,
        lastChanged: duration.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <DurationModal
          onClose={close}
          duration={getAssetDuration(selectedDuration)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          onClose={close}
          confirm={{ label: 'ok', function: removeAssetDuration }}
          title="Delete Duration"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Duration"
        action={{
          label: 'Add new duration',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <DurationsTable
        isLoading={loading}
        durations={getAssetDurations()}
        actions={[
          { label: 'Delete', action: remove },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Durations;
