import colors from './colors';
import breakpoints from './breakpoints';
import typography from './typography';
import animations from './animations';
import shadows from './shadows';

export default {
  colors,
  breakpoints,
  typography,
  animations,
  shadows,
};
