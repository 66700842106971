import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import axios from 'axios';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import UpdateHandlers from '../../../utils/handlers';
import {
  useUpdate_ApkMutation,
  useAdd_ApkMutation,
  useGenerate_Signeds3_Request_For_UploadMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import ErrorMessage from '../../../components/ErrorMessage';
import Stack from '../../../components/stack/Stack';
import FileUploader from '../../../components/uploader/FileUploader';
import { ApkSchema } from '../../../utils/yup-schema';
import { useAuthState } from '../../../context/auth';
import { formatFilenameForAPKUpload } from '../../../utils/helpers';
/**@jsx jsx*/

interface ValueProps {
  name: string;
  version: string;
  apk?: File;
}

type IApkModal = IModal & {
  apk?: { id: number; name: string; version: string };
};

const ApkModal: React.FC<IApkModal> = ({ onClose, apk }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
    version: '',
    apk: undefined,
  });

  const [
    generateSignedURLForUpload,
  ] = useGenerate_Signeds3_Request_For_UploadMutation();

  const [
    addApk,
    { data: addApkResult, loading: addApkLoading, error: addApkError },
  ] = useAdd_ApkMutation();

  const [
    updateApk,
    { data: updateApkResult, loading: updateApkLoading, error: updateApkError },
  ] = useUpdate_ApkMutation();

  React.useEffect(() => {
    if (apk) {
      setInitialValues({
        name: apk.name,
        version: apk.version,
        apk: undefined,
      });
    }
  }, [apk]);

  const getUploadURL = async (
    filetype: string,
    filename: string
  ): Promise<string | undefined> => {
    const response = await generateSignedURLForUpload({
      variables: {
        filename: `APK-mobile/${formatFilenameForAPKUpload(filename)}`,
        filetype,
      },
    });

    if (response.data?.generateSignedS3RequestForUpload.signedRequest) {
      return response.data.generateSignedS3RequestForUpload.signedRequest;
    }
    return undefined;
  };

  const uploadAPK = async (
    url: string,
    filetype: string,
    apk: File
  ): Promise<Boolean> => {
    try {
      const options = {
        headers: {
          'Content-Type': filetype,
        },
      };

      const response = await axios.put(url, apk, options);

      if (response) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const addNewApk = async (values: ValueProps) => {
    const apkFile = values.apk;
    const name = values.name;
    if (apkFile) {
      const filetype = name.split('.')[1];

      const url = await getUploadURL(filetype, name);

      if (url) {
        const success = await uploadAPK(url, filetype, apkFile);
        if (success) {
          addApk({
            variables: {
              changes: [
                {
                  name,
                  version: values.version,
                  created_by: userId,
                  modified_by: userId,
                },
              ],
            },
            update: UpdateHandlers.addApk,
          }).catch(() => {});
        }
      }
    }
  };

  const upateExistingApk = async (values: ValueProps) => {
    const apkFile = values.apk;
    let name = values.name;
    if (apkFile) {
      const filetype = name.split('.')[1];

      const url = await getUploadURL(filetype, name);

      if (url) {
        const success = await uploadAPK(url, filetype, apkFile);
        if (!success) {
          return;
        }
      }
    }
    updateApk({
      variables: {
        id: apk?.id,
        changes: {
          name,
          version: values.version,
          modified_by: userId,
        },
      },
    }).catch(() => {});
  };

  const handleSubmit = async (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (apk) {
      await upateExistingApk(values);
    } else {
      await addNewApk(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addApkLoading || updateApkLoading);
  const error = !!(addApkError || updateApkError);
  const success = !!(
    addApkResult?.insert_apk?.returning?.length ||
    updateApkResult?.update_apk?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ApkSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${apk ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${apk ? 'Update' : 'Add'} APK`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`APK ${apk ? 'updated' : 'added'} successfully...`}
                </Flex>
              )}
              <Stack>
                <div>
                  <Input
                    type="text"
                    name="version"
                    placeholder="Enter version"
                    label="Version"
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    value={formikProps.values.version}
                  />
                  <ErrorMessage name="version" />
                </div>
                <div>
                  <FileUploader
                    id="apk"
                    name="apk"
                    label="upload apk"
                    onChange={(e) => {
                      e.preventDefault();
                      const file = e.target.files[0];
                      if (file) {
                        formikProps.setFieldValue('apk', file);
                        formikProps.setFieldValue('name', file.name);
                      }
                    }}
                    accept=".apk"
                  />
                  <ErrorMessage name="apk" />
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ApkModal;
