import React from 'react';
import { jsx } from '@emotion/core';
import { toast } from 'react-toastify';
import theme from '../../theme/theme';
import IndustriesTable from './components/IndustriesTable';
import TitleModal from './components/IndustryModal';
import {
  useIndustriesQuery,
  useDelete_IndustryMutation,
} from '../../generated/graphql';
import { ITitle } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const Industries: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedTitle, setSelectedTitle] = React.useState<
    number | undefined
  >();
  const { data: industries, loading } = useIndustriesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteIndustry] = useDelete_IndustryMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedTitle(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (industry: any) => {
    setIsOpen(true);
    setSelectedTitle(industry.id);
  };

  const confirm = (industry: any) => {
    setSelectedTitle(industry.id);
    setShowConfirm(true);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deleteIndustry({
      variables: {
        id: selectedTitle,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteIndustry,
    });
    setShowConfirm(false);

    if (response.data?.update_industry?.affected_rows) {
      notify(true, 'Industry deleted successfully.');
    } else {
      notify(false, 'failed to delete industry.');
    }
  };

  const getTitle = (id?: number) => {
    if (id) {
      const industry = industries?.industry?.find(
        (industry) => industry.id === id
      );
      if (industry) {
        return {
          id: industry.id,
          name: industry.name,
        };
      }
    }
    return;
  };

  const getIndustries = (): ITitle[] => {
    return (
      industries?.industry?.map((industry) => ({
        id: industry.id,
        name: industry.name,
        createdAt: industry.created_at,
        lastChanged: industry.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <TitleModal onClose={close} industry={getTitle(selectedTitle)} />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Industry"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Industries"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <IndustriesTable
        isLoading={loading}
        industries={getIndustries()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Industries;
