import { IAccountType }  from '../utils/types'

export const account_types: IAccountType[] = [
  { id : 0, name: 'Individual Account' },
  { id : 1, name: 'Trust Account' },
  { id : 2, name: 'Joint Account' }
]

export enum AccountType {
  individual = "Individual Account",
  trust = "Trust Account",
  joint = "Joint Account"
}

export enum customer_type {
  individual = "individual",
  trust = "trust",
  joint = "joint"
}

export const sections = [
  'Accepted Goals',
  'bio',
  'residential info',
  'occupation',
  'source of funds',
  'expected account activity',
  'investment profile',
  'account mandate',
  'statement service',
  'next of kin',
  'beneficiaries',
  'dependents',
  'payment channels',
  'mode of instruction',
  'identification',
];

export const trust_sections = [
  'Accepted Goals',
  'bio',
  'residential info',
  'occupation',
  'source of funds',
  'expected account activity',
  'investment profile',
  'account mandate',
  'statement service',
  'in trust for',
  'beneficiaries',
  'dependents',
  'payment channels',
  'mode of instruction',
  'identification',
];

// account activity intervals
export const accountActivity = [
  'Monthly',
  'Quarterly',
  'Bi_Annually',
  'Annually',
  'Other',
];

export const employementStatus = [
  'Employed',
  'Self_Employed',
  'Unemployed',
  'Retired',
  'Student',
];

export const residential_statuses = [
  'Resident_Ghanaian',
  'Resident_Foreigner',
  'Non_Resident_Ghanaian',
  'Non_Resident_Foreigner',
];

export const accountMandates = [
  'One To Sign',
  'Either To Sign',
  'Both To Sign',
];

export const modeOfDeliveries = [
  'Email',
  'By_Post',
  'SMS',
  'Collection',
  'Unknown',
];

export const frequencies = ['Quarterly', 'Request', 'Unknown'];

export const investmentObjectives = [
  'Security',
  'Income',
  'Balance',
  'Growth',
  'Aggressive',
];
export const investmentKnowledge = [
  'Low',
  'Medium',
  'High',
  'Good',
  'Fair',
  'Novice',
  'Professional',
];
export const riskTolerance = [
  'Low', 
  'Medium', 
  'High'
];
export const investmentHorizons = [
  'Short_Term',
  'Medium_Term',
  'Long_Term',
  'Under_One_Year',
  'One_to_Two_years',
  'Three_to_Five_years',
  'Five_to_Ten_years',
  'Over_Ten_years',
];
export const approximateAnnualIncome = [
  'Below 2,000',
  '2,000 - 9,999',
  '10,000 - 24,999',
  '25,000 - 44,999',
  '50,000 and above',
];

export const individualResidentialInfo = [ 
  'residential_status',
  'country_of_origin',
  'country_of_residence',
  'permit_expiry_date',
  'permit_issue_date',
  'residential_permit_number',
  'residential_place_of_issue',
  'home_address',
  'tin',
  'gps_address'
]

export const jointResidentialInfo =  [
  'residential_status_jt',
  'country_of_origin_jt',
  'country_of_residence_jt',
  'permit_expiry_date_jt',
  'permit_issue_date_jt',
  'residential_permit_number_jt',
  'residential_place_of_issue_jt',
  'home_address_jt',
  'tin_jt',
  'gps_address_jt'
]

export const individualOccupationInfo =  [
  'employment_status',
  'industry',
  'occupation',
  'profession',
  'employer',
  'employer_address',
  'employer_city',
  'employer_email',
  'employer_contact',
  'nature_of_business',
  'years_of_employment',
  'years_of_current_employment',
  'years_of_previous_employment',
  'professional_license_number',
]

export const jointOccupationInfo = [
  'employment_status_jt',
  'occupation_jt',
  'profession_jt',
  'employer_jt',
  'employer_address_jt',
  'employer_city_jt',
  'employer_email_jt',
  'employer_contact_jt',
  'years_of_employment_jt',
  'years_of_current_employment_jt',
  'years_of_previous_employment_jt',
  'professional_license_number_jt'
]

export const individualBioInfo = [
  'first_name',
  'last_name',
  'other_name',
  'mobile',
  'email',
  'date_of_birth',
  'gender',
]
export const jointBioInfo = [
  'first_name_jt',
  'last_name_jt',
  'other_name_jt',
  'mobile_jt',
  'email_jt',
  'date_of_birth_jt',
  'gender_jt',
]

export const nextofkinInfo = [
  'first_name',
  'last_name',
  'last_name',
  'other_names',
  'gender',
  'relationship',
  'date_of_birth',
  'place_of_birth',
  'marital_status',
  'home_address',
  'mobile',
  'mobile2',
  'email',
  'occupation',
  'profession',
  'professional_license',
  'tin',
  'residential_status',
  'residential_place_of_issue',
  'residential_permit_number',
  'gps_address',
  'country_of_origin',
  'region',
  'permit_expiry_date',
  'permit_issue_date',
  'id_type',
  'id_number',
  'id_expiry_date',
  'id_issue_date'
]

export const beneficiaryInfo = [
  'first_name',
  'last_name',
  'last_name',
  'other_names',
  'gender',
  'home_address',
  'date_of_birth',
  'allocation',
  'relationship',
  'country_of_origin',
  'region',
  'residential_status',
  'residential_place_of_issue',
  'residential_permit_number',
  'permit_expiry_date',
  'permit_issue_date',
  'id_type',
  'id_number',
  'id_expiry_date',
  'id_issue_date',
  'occupation',
  'mobile2',
  'email',
  'gps_address',
  'title',
  'place_of_birth',
  'marital_status',
  'tin',
  'id_type',
  'id_number',
  'id_expiry_date',
  'id_issue_date',
  'professional_license'
]
export const trustBioInfo = [
  'first_name_jt',
  'last_name_jt',
  'other_name_jt',
  'mobile_jt',
  'mobile_jt2',
  'gender_jt',
  'date_of_birth_jt',
  'email_jt',
]

export const trustInfo = [
  'marital_status_jt',
  'place_of_birth',
  'home_address_jt',
  'gps_address_jt',
  'occupation_jt',
  'profession_jt',
  'professional_license_number_jt',
  'tin_jt',
  'residential_status_jt',
  'country_of_origin_jt',
  'country_of_residence_jt',
  'permit_expiry_date_jt',
  'permit_issue_date_jt',
  'residential_permit_number_jt',
  'residential_place_of_issue_jt',
  // 'relationship_jt',
  // 'id_type_jt',
  'id_number_jt',
  'id_issue_date_jt',
  'id_expiry_date_jt'
]