import React from 'react';
import { jsx } from '@emotion/core';
import NativeSelect from '../../select/NativeSelect';
import Flex from '../../layout/Flex';
import { capitalize } from '../../../utils/helpers';
import Input from '../../input/Input';
import Icon from '../../icon/Icon';
import theme from '../../../theme/theme';
/**@jsx jsx*/

interface IProps {
  className?: string;
  keyword: string;
  onFilter: (e: any) => void;
  data: any[];
  id: string;
  type: string;
}

const ColumnFilterControl: React.FC<IProps> = ({
  className,
  data,
  id,
  type,
  onFilter,
}) => {
  const [value, setValue] = React.useState('');
  const options = React.useMemo(() => {
    const options = new Set<string>();
    data.forEach((row) => {
      options.add(row[id]);
    });
    return Array.from(options);
  }, [id, data]);

  return (
    <React.Fragment>
      {type === 'select' && (
        <Flex ai="center">
          <label css={{ marginRight: '20px' }}>
            {capitalize(id.replace('_', ' '))}
          </label>
          <div
            className={className}
            css={{ position: 'relative', minWidth: '150px' }}>
            <NativeSelect onChange={onFilter}>
              <option value="">All</option>
              {options.map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </NativeSelect>
          </div>
        </Flex>
      )}
      {type === 'text' && (
        <div
          className={className}
          css={{ position: 'relative', minWidth: '150px' }}>
          <Input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              onFilter(e);
            }}
            placeholder={`${id.replace('_', ' ')}`}
            css={{ input: { paddingRight: '40px' } }}
          />
          <Icon
            size="sm"
            color={theme.colors.gray[300]}
            style={{ position: 'absolute', right: '16px', top: '12px' }}
            icon={['fas', 'magnifying-glass']}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ColumnFilterControl;
