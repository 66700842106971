import * as React from 'react';
import { useEffect } from 'react';
import { ls } from '..';

type Role =
  | 'admin'
  | 'backofficer'
  | 'advisor'
  | 'anonymous'
  | 'portfolio'
  | 'client_service';

type Action =
  | { type: 'SET_ROLE'; role: Role }
  | { type: 'SET_USER_ID'; userId?: number }
  | { type: 'SET_BOTH'; role?: Role; userId?: number };
type Dispatch = (action: Action) => void;
type State = {
  role?: Role;
  userId?: number;
};

type AuthProviderProps = { children: React.ReactNode };

export const AuthStateContext = React.createContext<State | undefined>(
  undefined
);
const AuthDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_ROLE': {
      return {
        ...state,
        role: action.role,
      };
    }
    case 'SET_USER_ID': {
      return {
        ...state,
        userId: action.userId,
      };
    }
    case 'SET_BOTH': {
      const { role, userId } = action;
      return {
        ...state,
        role,
        userId,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = React.useReducer(authReducer, {
    role: 'anonymous',
    userId: undefined,
  });

  useEffect(() => {
    try {
      const { role, userId } = ls.get('user_id_role') ?? {};
      dispatch({ type: 'SET_BOTH', role, userId });
    } catch (err) {}
  }, [dispatch]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}
function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}
export { AuthProvider, useAuthState, useAuthDispatch };
