import React from 'react';
import { GoalType } from '../views/customers/components/GoalType';
import { FormData, IModal } from '../utils/types';
import Modal from '../components/modal/Modal';
import GoalCalculator from '../views/customers/components/GoalCalculator';
import { useGoalsQuery } from '../generated/graphql';
import { ArrayHelpers, useFormikContext } from 'formik';
import { v4 } from 'uuid';
import moment from 'moment';
interface IProp extends IModal {
  guid: string;
  goals: {
    id: string;
    name: string;
  }[];
  arrayHelpers?: ArrayHelpers;
}

const NewGoal = {
  guid: '',
  administrative_fees: 0,
  current_value: 0,
  duration_months: 0,
  duration_years: 0,
  expected_return: 0,
  goal_for: 0,
  goal_id: '',
  goal: {
    id: '',
    name: '',
  },
  has_insurance: false,
  monthly_contribution: 0,
  years_to_fund: 0,
  accepted: true,
  accepted_on: moment().format('YYYY-MM-DD')
};


export const GoalModal: React.FC<IProp> = ({
  guid,
  goals,
  onClose,
  arrayHelpers,
}) => {
  const { values } = useFormikContext<FormData>();
  const [goalTypeId, setGoalTypeId] = React.useState(0);
  const [goalGuid, setGoalGuid] = React.useState('');
  const [goalTypeOpen, setGoalTypeOpen] = React.useState(true);
  const [goalCalculatorOpen, setGoalCalculatorOpen] = React.useState(false);
  const { data: goalTypesData } = useGoalsQuery();
  const setMemberGoalTypeId = React.useCallback(
    (id) => {
      setGoalTypeId(id);
      const goal = goalTypesData?.goal.find((goal) => goal.id === id);
      const guid = v4();
      arrayHelpers!.push({
        ...NewGoal,
        goal: {
          id: goal!.id,
          name: goal!.name,
        },
        goal_id: id,
        has_insurance: goal?.name.toLowerCase() === 'education' ? true : false,
        goal_for: `${values.user?.first_name} ${
          values.user?.other_name ?? ''
        } ${values.user?.last_name}`,
        guid,
      });
      setGoalGuid(guid);
      setGoalTypeOpen(false);
      setGoalCalculatorOpen(true);
    },
    [arrayHelpers, goalTypesData, values.user]
  );

  return (
    <Modal
      onClose={onClose}
      width="700px"
      top={50}
      icon={{ name: 'certificate' }}
      title="Goal Calculator">
      {goalTypeOpen && (
        <GoalType goals={goals} selectGoalType={setMemberGoalTypeId} />
      )}

      {goalCalculatorOpen && (
        <GoalCalculator guid={goalGuid} goalId={goalTypeId} onClose={onClose} />
      )}
    </Modal>
  );
};
