import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import Stack from '../../../components/stack/Stack';
import Button from '../../../components/button/Button';
import { IModal } from '../../../utils/types';
/**@jsx jsx*/

interface IProps extends IModal {}

const PasswordResetModal: React.FC<IProps> = ({ onClose }) => {
  return (
    <Modal onClose={onClose} title="Reset password">
      <Stack spacing={12}>
        <Input label="Current Password" type="password"></Input>
        <Input label="New Password" type="password"></Input>
        <Button>Save new password</Button>
      </Stack>
    </Modal>
  );
};

export default PasswordResetModal;
