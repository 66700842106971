import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import GendersTable from './components/GendersTable';
import GenderModal from './components/GenderModal';
import {
  useGendersQuery,
  useDelete_GenderMutation,
} from '../../generated/graphql';
import { IGender } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/
import { testRow } from '../../shared/constants';

const Genders: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedGender, setSelectedGender] = React.useState<
    number | undefined
  >();
  const { data: genders, loading } = useGendersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteGender] = useDelete_GenderMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedGender(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (gender: IGender) => {
    setIsOpen(true);
    setSelectedGender(gender.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deleteGender({
      variables: {
        id: selectedGender,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteGender,
    });
    setShowConfirm(false);

    if (response.data?.update_gender?.affected_rows) {
      notify(true, 'Gender deleted successfully.');
    } else {
      notify(false, 'failed to delete gender.');
    }
  };

  const confirm = (gender: IGender) => {
    setSelectedGender(gender.id);
    setShowConfirm(true);
  };

  const getGender = (id?: number) => {
    if (id) {
      const gender = genders?.gender?.find((gender) => gender.id === id);
      if (gender) {
        return {
          id: gender.id,
          name: gender.name,
        };
      }
    }
    return;
  };

  const getGenders = (): IGender[] => {
    return (
      genders?.gender
        ?.filter((gender) => {
          return gender.name !== testRow;
        })
        .map((gender) => ({
          id: gender.id,
          name: gender.name,
          createdAt: gender.created_at,
          lastChanged: gender.updated_at,
        })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <GenderModal onClose={close} gender={getGender(selectedGender)} />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Gender"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Gender"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>

      <GendersTable
        isLoading={loading}
        genders={getGenders()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Genders;
