import React from 'react';
import { jsx } from '@emotion/core';
import StatCard from '../../../components/stat/StatCard';
import theme from '../../../theme/theme';
import Stack from '../../../components/stack/Stack';
import { Member_Goal } from '../../../generated/graphql';
import { formatDuration, capitalize } from '../../../utils/helpers';
import Button from '../../../components/button/Button';
import Separator from '../../../components/separator/Separator';
import { IAction } from '../../../utils/types';
/**@jsx jsx*/

interface IGoal {
  data: Partial<Member_Goal>;
  onEdit?: () => void;
  onDelete?: () => void;
  onView?(): void;
  actions?: IAction[];
  mode?: 'read' | 'write';
}

const Goal: React.FC<IGoal> = ({
  data,
  onEdit,
  onDelete,
  mode = 'write',
  onView,
  actions,
}) => {
  return (
    <StatCard title={`${capitalize(data.goal?.name)}`}>
      <div
        css={{
          padding: '12px 24px',
          p: { color: theme.colors.gray[400], fontSize: '14px' },
          h2: {
            fontFamily: theme.typography.fonts.bold,
            fontSize: '14px',
            color:
              mode === 'read' ? theme.colors.primary : theme.colors.gray[600],
          },
        }}>
        <Stack spacing={8}>
          <div>
            <p>{`Monthly Contribution: ${data.monthly_contribution}`}</p>
            <p>
              {`Duration: ${formatDuration(
                data.duration_years,
                data.duration_months
              )}`}
            </p>
          </div>
          <h2> {`GHS ${data.future_value}`}</h2>
        </Stack>
        <Separator gap={24} />
        <Stack isInline>
          {mode === 'write' ? (
            <Button
              type="button"
              action={onEdit}
              icon={{ name: 'pencil', color: 'white', position: 'after' }}>
              Edit
            </Button>
          ) : (
            <Stack spacing={12} ai="center" isInline>
              <Button appearance="outline" type="button" action={onView}>
                View
              </Button>
              {actions?.map((action, index) => {
                return (
                  <Button action={action.function} key={index} {...action}>
                    {action.label}
                  </Button>
                );
              })}
            </Stack>
          )}
          {/* <Button
            type="button"
            action={onDelete}
            icon={{
              name: "times",
              color: theme.colors.gray[500],
              position: "after",
            }}
            appearance="outline"
          >
            Delete
          </Button> */}
        </Stack>
      </div>
    </StatCard>
  );
};

export default Goal;
