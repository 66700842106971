import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import { IInputProps } from '../../utils/types';
import Flex from '../layout/Flex';
/**@jsx jsx*/

interface IProps extends IInputProps {
  isChecked?: boolean;
}

const Checkbox: React.FC<IProps> = ({
  className,
  onChange,
  name,
  id,
  value,
  isChecked = false,
  label,
}) => {
  return (
    <div
      className={className}
      css={{
        position: 'relative',
        label: {
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        },

        'input:checked + label .custom__box': {
          background: theme.colors.secondary,
        },
      }}>
      <input
        onChange={onChange}
        value={value}
        css={{
          position: 'absolute',
          top: '-20px',
          pointerEvents: 'none',
          opacity: 0,
        }}
        type="checkbox"
        name={name}
        id={id}
        defaultChecked={isChecked}
      />
      <label css={{ display: 'flex' }} htmlFor={id}>
        <Flex
          className="custom__box"
          ai="center"
          jc="center"
          styles={{
            height: '16px',
            width: '16px',
            background: theme.colors.white,
            border: `2px solid ${theme.colors.secondary}`,
            borderRadius: '3px',
          }}>
          <div
            css={{
              height: '6px',
              width: '9px',
              borderLeft: `2px solid ${theme.colors.white}`,
              borderBottom: `2px solid ${theme.colors.white}`,
              transform: 'rotate(-45deg)',
              marginBottom: '3px',
            }}></div>
        </Flex>
        <div
          css={{
            marginLeft: '10px',
            fontSize: '16px',
            color: theme.colors.gray[500],
          }}>
          {label}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
