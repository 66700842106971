import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../../../components/modal/Modal';
import Stack from '../../../components/stack/Stack';
import Input from '../../../components/input/Input';
import NativeSelect from '../../../components/select/NativeSelect';
import TextArea from '../../../components/textarea/TextArea';
import theme from '../../../theme/theme';
import Flex from '../../../components/layout/Flex';
import ActionSelect from '../../../components/select/ActionSelect';
import Avatar from '../../../components/avatar/Avatar';
import Icon from '../../../components/icon/Icon';
import { IModal } from '../../../utils/types';
/**@jsx jsx*/

interface IProps extends IModal {}

const RedemptionForm: React.FC = () => {
  const [paymentMode, setPaymentMode] = React.useState('bank');

  function selectPaymentMode(e: any) {
    setPaymentMode(e.target.value);
  }
  return (
    <Stack spacing={8}>
      <NativeSelect label="Select goal"></NativeSelect>
      <TextArea label="Redemption Reason"></TextArea>
      <Input label="Amount"></Input>
      <NativeSelect label="Payment Mode"></NativeSelect>
      {paymentMode === 'bank' && (
        <Stack spacing={8}>
          <NativeSelect label="Bank"></NativeSelect>
          <NativeSelect label="Branch"></NativeSelect>
          <Input label="Bank account number"></Input>
        </Stack>
      )}
      {paymentMode === 'momo' && (
        <Stack spacing={8}>
          <NativeSelect
            onChange={selectPaymentMode}
            label="Network Provider"></NativeSelect>
          <Input label="Phone number"></Input>
        </Stack>
      )}
    </Stack>
  );
};

const AuthorizeRedemption: React.FC = () => {
  return (
    <div>
      <Stack>
        <div>
          <p css={{ fontSize: '13px', color: theme.colors.gray[500] }}>
            A message containing a pin has been sent to your email address
            “email@petra.com”. Kindly enter the code in the input provided below
            to complete your withdrawal.
          </p>
        </div>
        <div>
          <Stack spacing={4}>
            <Input placeholder="Enter pin here"></Input>
            <Flex ai="center" jc="space-between">
              <p css={{ fontSize: '12px', color: theme.colors.gray[400] }}>
                your code is valid for only 25 seconds
              </p>
              <ActionSelect
                css={{
                  fontSize: '12px',
                  height: '30px',
                  p: { color: theme.colors.blue[700] },
                }}
                label="Resend code"
                options={[]}></ActionSelect>
            </Flex>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

const FeedbackScreen: React.FC = () => {
  return (
    <Flex stack ai="center">
      <Avatar css={{ marginBottom: '16px' }}>
        <Icon color={theme.colors.blue[700]} icon="check"></Icon>
      </Avatar>
      <Flex
        stack
        ai="center"
        css={{
          padding: '0 48px',
          marginBottom: '16px',
          'p,h2': { textAlign: 'center' },
        }}>
        <h2 css={{ fontSize: '15px', marginBottom: '4px' }}>
          Redemption successfully completed
        </h2>
        <p css={{ fontSize: '13px', color: theme.colors.gray[500] }}>
          Your request has been successfully completed. Your funds should be in
          your account in 2-4 business days
        </p>
      </Flex>
    </Flex>
  );
};

const RedemptionModal: React.FC<IProps> = ({ onClose }) => {
  const [step, setStep] = React.useState<'form' | 'authorize' | 'feedback'>(
    'form'
  );

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const confirmLabel = step === 'form' ? 'Continue' : 'Redeem Funds';
  const confirmAction = step === 'form' ? onContinue : onSubmitRedemption;

  function onContinue() {
    setStep('authorize');
  }

  function onSubmitRedemption() {
    setIsSubmitting(true);
    setTimeout(() => {
      setStep('feedback');
      setIsSubmitting(false);
    }, 2000);
  }

  const steps = {
    form: <RedemptionForm />,
    authorize: <AuthorizeRedemption />,
    feedback: <FeedbackScreen />,
  };

  return (
    <Modal
      onClose={onClose}
      confirm={
        step === 'feedback'
          ? undefined
          : {
              label: confirmLabel,
              function: confirmAction,
              loading: isSubmitting,
            }
      }
      cancel={
        step === 'feedback' ? undefined : { label: 'Cancel', function: onClose }
      }
      title={step === 'feedback' ? '' : 'Request Redemption'}>
      {steps[step]}
    </Modal>
  );
};

export default RedemptionModal;
