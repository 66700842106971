import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import { IInputProps, actionStatus } from '../../utils/types';
import Flex from '../layout/Flex';
import Avatar from '../avatar/Avatar';
import Icon from '../icon/Icon';
import Loader from '../loader/Loader';
/**@jsx jsx*/

interface IProps extends IInputProps {
  accept?: string;
}

function truncateFileName(name: string) {
  if (!name) {
    return;
  }

  //TODO:some file names have more than one period.
  //refactor to handle
  const [fileName, extension] = name.split('.');
  return `${fileName.substring(0, 30)}.${extension}`;
}

const FileUploader: React.FC<IProps> = ({
  name,
  accept,
  id = 'file',
  onChange = () => {},
}) => {
  const [state, setState] = React.useState<actionStatus>('idle');
  const [file, setFile] = React.useState<File | null>(null);

  function handleUpload(e: any) {
    if (e.target.files.length === 0) {
      return;
    }
    setState('loading');
    e.persist();

    setTimeout(() => {
      setFile(e.target.files[0]);
      setState('success');
      onChange(e);
    }, 2000);
  }

  //   function resetUploader() {
  //     setState("idle");
  //     setFile(null);
  //   }

  return (
    <div css={{ position: 'relative' }}>
      <input
        onChange={handleUpload}
        css={{ position: 'absolute', pointerEvents: 'none', opacity: 0 }}
        name={name}
        id={id}
        type="file"
        accept={accept}
      />
      <label htmlFor={id}>
        <div
          css={{
            width: '100%',
            border: `1px dashed ${theme.colors.gray[200]}`,
            height: '80px',
            borderRadius: '8px',
            padding: '0 24px',
          }}>
          <Flex ai="center" jc="space-between">
            <Flex css={{ height: '80px' }} ai="center">
              <Avatar
                css={{ marginRight: '16px' }}
                size="md"
                background={theme.colors.cyan[50]}>
                {state === 'idle' && (
                  <Icon
                    color={theme.colors.cyan[600]}
                    size="1x"
                    icon={['fas', 'file-arrow-up']}></Icon>
                )}
                {state === 'loading' && (
                  <Loader size="small" color={theme.colors.cyan[600]} />
                )}
                {state === 'success' && (
                  <Icon
                    color={theme.colors.cyan[600]}
                    icon={['fas', 'check']}></Icon>
                )}
              </Avatar>

              <div>
                <p
                  css={{
                    color: theme.colors.gray[500],
                    fontSize: '14px',
                  }}>
                  {state === 'idle' &&
                    `click here to upload ${name ? name : 'file'}`}
                  {state === 'loading' && `uploading ${name ? name : 'file'}`}
                  {state === 'success' && file && truncateFileName(file.name)}
                </p>
              </div>
            </Flex>
            {/* {(state === "loading" || state === "success") && (
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  resetUploader();
                }}
              >
                <Icon
                  css={{ pointerEvents: "none" }}
                  color={theme.colors.gray[400]}
                  icon={["far", "times"]}
                ></Icon>
              </div>
            )} */}
          </Flex>
        </div>
      </label>
    </div>
  );
};

export default FileUploader;
