import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './containers/Dashboard';
import Home from './containers/Home';
import Error from './containers/Error';
import { setAccessToken } from './accessToken';
import { AuthRoute } from './routes/AuthRoute';

const App: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let runEffect = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetch(`${process.env.REACT_APP_CBL_ENDPOINT}/auth/refresh_token`, {
      method: 'POST',
      credentials: 'include',
      signal: signal,
    }).then(async (response) => {
      const { accessToken } = await response.json();
      if (runEffect) {
        setAccessToken(accessToken);
        setLoading(false);
      }
    });

    return () => {
      abortController.abort();
      runEffect = false;
    };
  }, []);

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div className="App">
      <Error>
        <Switch>
          <Redirect exact={true} from="/" to="/dashboard" />
          <AuthRoute path="/dashboard" component={Dashboard} />
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <ToastContainer />
      </Error>
    </div>
  );
};

export default App;
