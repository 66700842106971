import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../components/layout/Flex';
import Loader from '../../components/loader/Loader';
import { useLogoutMutation } from '../../generated/graphql';
import { useHistory } from 'react-router-dom';
import { setAccessToken } from '../../accessToken';
import theme from '../../theme/theme';
import { ls } from '../..';
/**@jsx jsx*/

const Logout: React.FC = () => {
  const history = useHistory();
  const [logout, { client }] = useLogoutMutation();

  //Run logout logic here
  React.useEffect(() => {
    logout()
      .then(() => {
        setAccessToken('');
        history.push('/login');
        client!.clearStore();
        ls.remove('user_id_role');
      })
      .catch((error) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex ai="center" jc="center" css={{ height: '500px' }}>
      <Loader color={theme.colors.primary} label="Logging out"></Loader>
    </Flex>
  );
};

export default Logout;
