import React from 'react';
import { jsx } from '@emotion/core';
import InputLabel from '../input/components/InputLabel';
import { IInputProps, ISelectOption } from '../../utils/types';
import { inputStyles, inputErrorStyles } from '../input/Input';
import Flex from '../layout/Flex';
import Icon from '../icon/Icon';
import theme from '../../theme/theme';
import useDropDownExp from '../../hooks/useDropDownExp';
import Portal from '../portal/Portal';
/**@jsx jsx*/

interface IProps extends IInputProps {
  options?: ISelectOption[];
  defaultIsOpen?: boolean;
  openOnFocus?: boolean;
  value?: string[];
  defaultValue?: string[];
}

const SelectOption: React.FC<{
  option: ISelectOption;
  onRemove?: (option: ISelectOption) => void;
}> = ({ option, onRemove = () => {} }) => {
  return (
    <Flex
      onClick={(e) => {
        e.stopPropagation();
      }}
      ai="center"
      css={{
        background: theme.colors.gray[50],
        height: '28px',
        padding: '0 8px',
        marginRight: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '2px',
        fontSize: '14px',
      }}>
      <p css={{ marginRight: '8px', pointerEvents: 'none' }}>{option.label}</p>
      <span
        data-block={true}
        onClick={(e) => {
          e.stopPropagation();
          onRemove(option);
        }}>
        <Icon
          style={{ pointerEvents: 'none' }}
          color={theme.colors.gray[400]}
          size="xs"
          icon={['fas', 'xmark']}></Icon>
      </span>
    </Flex>
  );
};

//this function maintains the original ordering of options passed
//messes with selected options
//left here for reference
function getOptionsfromValues(options: ISelectOption[], values: string[] = []) {
  const o = options.filter((option) => {
    return values.includes(option.value as string);
  });
  return o;
}

function findOption(options: ISelectOption[], value: string) {
  return options.filter((option) => {
    return option.value === value;
  })[0];
}

//this function fixes the ordering problem
//TODO: Rename function...possibly refactor to be simpler
function properlyGetOptionsfromValues(
  options: ISelectOption[],
  values: string[] = []
) {
  return values.map((value) => {
    return { value: value, label: findOption(options, value)?.label };
  });
}

function extractValuesFromOptions(options: ISelectOption[]) {
  return options.map((option) => {
    return option.value;
  });
}

const ComboBox: React.FC<IProps> = ({
  label,
  className,
  options = [],
  placeholder,
  defaultIsOpen = false,
  openOnFocus = true,
  name,
  error,
  defaultValue,
  value,
  onChange = () => {},
}) => {
  //TODO:allow users to use this component in both controlled and uncontrolled ways
  //use internal state value when uncontrolled
  //use prop value when controlled
  //defaultValue doesn't support async values as at now.
  //if you want to pass async values , use value prop.
  const [selectedItems, setSelectedItems] = React.useState<ISelectOption[]>(
    getOptionsfromValues(options, defaultValue)
  );

  //TODO:Filter options based on input state
  const [inputState, setInputState] = React.useState('');
  const [highlightedIndex, setHighlightedIndex] = React.useState<number | null>(
    null
  );
  const [inputIsFocused, setInputIsFocused] = React.useState(false);
  const { isOpen, setIsOpen, elRef, portalRef } = useDropDownExp(
    defaultIsOpen,
    true
  );
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [liveRect, setLiveRect] = React.useState({} as DOMRect);

  React.useLayoutEffect(() => {
    const rect = wrapperRef.current?.getBoundingClientRect();
    setLiveRect(rect as DOMRect);
  }, [value]);

  function handleInputClick(e: any) {
    e.stopPropagation();
    if (!openOnFocus) {
      return;
    }
    inputRef.current?.focus();
    setIsOpen(true);
  }

  function handleMouseOver(index: number) {
    setHighlightedIndex(index);
  }

  //TODO:Refactor to use array.includes
  function isSelected(option: ISelectOption) {
    const filteredOptions = selectedItems.filter((item) => {
      return item.value === option.value;
    });
    return filteredOptions.length > 0;
  }

  function getOptionsAfterRemoval(option: ISelectOption): ISelectOption[] {
    if (!option) {
      return [];
    }
    const filteredOptions = selectedItems.filter((item) => {
      return item.value !== option.value;
    });

    return filteredOptions;
  }

  function handleRemoveSelection(option: ISelectOption) {
    const optionsLeft = getOptionsAfterRemoval(option);
    setSelectedItems(optionsLeft);
    onChange({
      target: {
        name: name,
        value: [...extractValuesFromOptions(optionsLeft)],
      },
    });
  }

  function onButtonClick(e: any) {
    setIsOpen((prev) => !prev);
    inputRef.current?.focus();
  }

  function onInputFocus() {
    setInputIsFocused(true);
  }

  function onInputBlur() {
    setInputIsFocused(false);
  }

  function onSelect(option: ISelectOption) {
    //check if component is being used in controlled mode
    //if it's controlled, call the onchange handler to update state in parent component
    //when parent component state changes, the value prop is reset to the current value of the value prop
    if (value) {
      if (isSelected(option)) {
        onChange({
          target: {
            name: name,
            value: [
              ...extractValuesFromOptions(getOptionsAfterRemoval(option)),
            ],
          },
        });
        setInputState('');
        inputRef.current && inputRef.current.focus();
        return;
      }
      setSelectedItems([...selectedItems, option]);
      onChange({
        target: {
          name: name,
          value: [...extractValuesFromOptions(selectedItems), option.value],
        },
      });

      inputRef.current && inputRef.current.focus();
      setInputState('');

      return;
    }

    if (isSelected(option)) {
      setSelectedItems(getOptionsAfterRemoval(option));
      onChange({
        target: {
          name: name,
          value: [...extractValuesFromOptions(getOptionsAfterRemoval(option))],
        },
      });
      setInputState('');
      inputRef.current && inputRef.current.focus();
      return;
    }
    //select an option.
    //add selected option to selectedItems array
    //force focus into input component
    inputRef.current && inputRef.current.focus();
    setInputState('');
    setSelectedItems([...selectedItems, option]);
    onChange({
      target: {
        name: name,
        value: [...extractValuesFromOptions(selectedItems), option.value],
      },
    });
  }

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (value) {
      const o = properlyGetOptionsfromValues(options, value);
      setSelectedItems(o);
    }
  }, [value, options]);

  const optionsToRender = options.filter((option) => {
    return option.label.toLowerCase().includes(inputState.toLowerCase());
  });

  return (
    <div
      ref={wrapperRef}
      className={className}
      onClick={(e) => {
        e.stopPropagation();
      }}
      css={{ width: '100%' }}>
      <InputLabel>{label}</InputLabel>
      <div ref={elRef} css={{ width: '100%', position: 'relative' }}>
        {isOpen && (
          <Portal>
            <div
              ref={portalRef}
              css={{
                position: 'absolute',
                top: `calc(${liveRect.y + liveRect.height}px + 5px)`,
                width: liveRect.width + 'px',
                left: liveRect.x + 'px',
                background: theme.colors.white,
                padding: '2px',
                borderRadius: '4px',
                border: `1px solid ${theme.colors.gray[100]}`,
                boxShadow: theme.shadows.min,
                maxHeight: '300px',
                overflow: 'scroll',
                zIndex: 999999999,
              }}>
              {optionsToRender.length === 0 ? (
                <Flex stack ai="center" jc="center" css={{ padding: '8px' }}>
                  <Icon
                    style={{ marginBottom: '16px' }}
                    size="2x"
                    icon={['fas', 'transporter-empty']}
                    color={theme.colors.gray[400]}></Icon>
                  <p css={{ color: theme.colors.gray[400] }}>No data</p>
                </Flex>
              ) : (
                optionsToRender.map((option, index) => {
                  return (
                    <li
                      onMouseOver={() => {
                        handleMouseOver(index);
                      }}
                      onClick={() => {
                        onSelect(option);
                      }}
                      key={index}>
                      <Flex
                        jc="space-between"
                        css={{
                          borderRadius: '4px',
                          padding: '12px 8px',
                          fontSize: '14px',
                          background:
                            highlightedIndex === index
                              ? theme.colors.gray[50]
                              : 'none',
                          '&:hover': { background: theme.colors.gray[50] },
                        }}>
                        <p>{option.label}</p>
                        <span>
                          {isSelected(option) && (
                            <Icon
                              color={theme.colors.cyan[400]}
                              icon={['fas', 'check']}></Icon>
                          )}
                        </span>
                      </Flex>
                    </li>
                  );
                })
              )}
            </div>
          </Portal>
        )}
        <div
          css={{
            ...inputStyles,
            padding: '0 0 0 4px',
            flexWrap: 'wrap',
            height: 'auto',
            minHeight: '36px',
            border: `1px solid ${
              inputIsFocused ? theme.colors.blue[200] : theme.colors.gray[200]
            }`,
            outline: 'none',
            boxShadow: inputIsFocused ? `0 0 4px rgba(24,144,255,.4)` : 'none',
          }}>
          <Flex jc="space-between">
            <Flex
              onClick={handleInputClick}
              css={{
                flexWrap: 'wrap',
                width: 'calc(100% - 40px)',
                cursor: 'text',
              }}
              ai="center">
              {selectedItems.map((item, index) => {
                return (
                  <SelectOption
                    onRemove={handleRemoveSelection}
                    option={item}
                    key={index}
                  />
                );
              })}
              <span css={{ width: '4px' }}>
                <input
                  onChange={(e) => {
                    setInputState(e.target.value);
                    setIsOpen(true);
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleInputClick(e);
                  }}
                  ref={inputRef}
                  placeholder={selectedItems.length === 0 ? placeholder : ''}
                  css={{
                    width: 'auto',
                    flexGrow: 0,
                    //TODO:set input width to width of actual text keyed in
                    minWidth: '3px',
                    border: 'none',
                    height: '32px',
                    marginTop: '2px',
                    marginBottom: '2px',
                    '&:focus': { border: 'none', outline: 'none' },
                    '&::placeholder': { color: theme.colors.gray[400] },
                  }}
                  type="text"
                  value={inputState}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}></input>
              </span>
            </Flex>
            <button
              onClick={(e) => {
                onButtonClick(e);
              }}
              css={{
                width: '40px',
                height: 'auto',
                minHeight: '36px',
                background: theme.colors.gray[50],
                '&:focus': { outline: 'none' },
              }}>
              <Icon icon="chevron-down" />
            </button>
          </Flex>
        </div>
      </div>
      {error && <p css={{ ...inputErrorStyles }}>{error}</p>}
    </div>
  );
};

export default ComboBox;
