/* eslint-disable react/display-name */
import React from 'react';
import { jsx } from '@emotion/core';
import Row from '../../components/layout/Row';
import Flex from '../../components/layout/Flex';
import Stack from '../../components/stack/Stack';
import DataTable from '../../components/table/DataTable';
import Tag from '../../components/tag/Tag';
import ComboBox from '../../components/select/ComboBox';
import CrudSectionTitle from '../../components/sectionTitle/CrudSectionTitle';

/**@jsx jsx*/

const data = [
  {
    _id: '5edf31e9cdc504d45e73d606',
    isActive: true,
    age: 27,
    name: 'Leola Williamson',
    email: 'leolawilliamson@otherside.com',
  },
  {
    _id: '5edf31e9180368e93f27d5b9',
    isActive: true,
    age: 33,
    name: 'Harrington Lamb',
    email: 'harringtonlamb@otherside.com',
  },
  {
    _id: '5edf31e977bf0b9ede721512',
    isActive: false,
    age: 29,
    name: 'Schwartz Romero',
    email: 'schwartzromero@otherside.com',
  },
  {
    _id: '5edf31e9478df1d1fc1d0ed0',
    isActive: false,
    age: 38,
    name: 'Sue Graves',
    email: 'suegraves@otherside.com',
  },
  {
    _id: '5edf31e9de558cd2c527a52c',
    isActive: true,
    age: 23,
    name: 'Aileen Norris',
    email: 'aileennorris@otherside.com',
  },
  {
    _id: '5edf31e9ae2a382d7002a875',
    isActive: true,
    age: 37,
    name: 'Walter Hopkins',
    email: 'walterhopkins@otherside.com',
  },
  {
    _id: '5edf31e91dff727c4ce7484f',
    isActive: false,
    age: 22,
    name: 'Neva Washington',
    email: 'nevawashington@otherside.com',
  },
  {
    _id: '5edf31e914405baf8779fd95',
    isActive: true,
    age: 24,
    name: 'Fanny Forbes',
    email: 'fannyforbes@otherside.com',
  },
  {
    _id: '5edf31e90ae80b21e73d0fca',
    isActive: false,
    age: 29,
    name: 'Vazquez Gibbs',
    email: 'vazquezgibbs@otherside.com',
  },
  {
    _id: '5edf31e9aac65225c5b837cb',
    isActive: true,
    age: 22,
    name: 'Woodard Ford',
    email: 'woodardford@otherside.com',
  },
  {
    _id: '5edf31e93d1aa71d069ddc7a',
    isActive: true,
    age: 34,
    name: 'Kathleen Powell',
    email: 'kathleenpowell@otherside.com',
  },
  {
    _id: '5edf31e9b7ccc8d11dd94c74',
    isActive: true,
    age: 39,
    name: 'Rosetta Hutchinson',
    email: 'rosettahutchinson@otherside.com',
  },
  {
    _id: '5edf31e904f0953ff3772dd5',
    isActive: true,
    age: 35,
    name: 'Lora Edwards',
    email: 'loraedwards@otherside.com',
  },
  {
    _id: '5edf31e9b3f5ce1b59a2ad1c',
    isActive: false,
    age: 25,
    name: 'Violet Zimmerman',
    email: 'violetzimmerman@otherside.com',
  },
  {
    _id: '5edf31e9054cbacb38408fca',
    isActive: true,
    age: 39,
    name: 'Nieves Frye',
    email: 'nievesfrye@otherside.com',
  },
  {
    _id: '5edf31e9eb9aa3f8eefd8c55',
    isActive: false,
    age: 28,
    name: 'Clay Meyers',
    email: 'claymeyers@otherside.com',
  },
  {
    _id: '5edf31e97fc9e50f46b912c8',
    isActive: true,
    age: 27,
    name: 'Johns Chandler',
    email: 'johnschandler@otherside.com',
  },
  {
    _id: '5edf31e964e8a2bddc78cc91',
    isActive: true,
    age: 36,
    name: 'Sandy Rollins',
    email: 'sandyrollins@otherside.com',
  },
  {
    _id: '5edf31e96d1c485c5fbb0155',
    isActive: true,
    age: 23,
    name: 'Glenda Hahn',
    email: 'glendahahn@otherside.com',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive: boolean) => {
      return (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Active' : 'Disabled'}
        </Tag>
      );
    },
  },
];

const TestArea: React.FC = () => {
  function doSomething(item: any) {
    console.log(item);
  }

  const [people, setPeople] = React.useState<string[]>([]);

  function onChange(e: any) {
    const { value } = e.target;
    setPeople(value);
  }

  //async example
  React.useEffect(() => {
    setTimeout(() => {
      setPeople([data[0].name]);
    }, 5000);
  }, []);

  return (
    <div>
      <Row>
        <div css={{ paddingTop: '50px' }}>
          <CrudSectionTitle
            add={{ label: 'Add vibes' }}
            title="Leave me alone"></CrudSectionTitle>
        </div>
        <Flex stack ai="center">
          <Flex css={{ width: '1000px', marginTop: '50px' }} jc="center">
            <Stack css={{ width: '100%', marginBottom: '100px' }}>
              <div>
                <ComboBox
                  placeholder="select user"
                  label="Users"
                  options={data.map((d) => {
                    return { label: d.name, value: d.name };
                  })}
                  css={{ width: '300px' }}
                  onChange={onChange}
                  value={people}
                />
              </div>
              <DataTable
                actions={[
                  {
                    label: 'Review',
                    action: doSomething,
                  },
                  {
                    label: 'Review',
                    action: doSomething,
                  },
                ]}
                data={data}
                columns={columns}
                searchFilter={['name', 'email']}
                rowSelection={{ type: 'checkbox' }}
              />
            </Stack>
          </Flex>
        </Flex>
      </Row>
    </div>
  );
};

export default TestArea;
