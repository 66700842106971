import React, { useCallback, useMemo, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import {
  PaginatedCustomersQueryVariables,
  usePaginatedCustomersQuery,
} from '../../generated/graphql';
import TableEx from '../../components/table/TableEx';
import CustomerTypeModal from './components/CustomerTypeModal';
import Tag from '../../components/tag/Tag';
import Button from '../../components/button/Button';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import theme from '../../theme/theme';
import { ADMIN } from '../../routes/routes';
import { accountType } from '../../utils/types';
import { getAccountType } from '../../utils/helpers';
import { AccountType } from '../../shared/sections'
/**@jsx jsx*/

const columns = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'PSL Code',
    accessor: 'pslCode',
  },
  {
    Header: 'Account Type',
    accessor: 'accountType',
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedTo',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (row: any) => {
      return <Tag>{row.value}</Tag>;
    },
  },
];

const Customers: React.FC = () => {
  const dataRef = useRef<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const { data, loading, fetchMore } = usePaginatedCustomersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 10,
      offset: 0,
    },
  });
  const placeholder = "first name, last name, assigned user"

  const totalCount = data?.member_aggregate.aggregate?.count ?? 0

  interface ValueProps {
    name: accountType;
  }

  const history = useHistory();

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const aColumns = useMemo(
    () => [
      ...columns,
      {
        Header: 'Action',
        accessor: 'action',
        Cell: (row: any) => {
          return (
            <Button
              action={(e: any) => {
                e.stopPropagation();
                history.push(`/dashboard/customers/${row.value}`);
              }}>
              <span>View</span>
            </Button>
          );
        },
      },
    ],
    [history]
  );

  const transformData = useCallback((tData: any[]) => {
    return (
      tData.map((customer) => ({
        id: customer.id as string,
        fullName: `${customer.user?.first_name ?? ''} ${customer.user?.last_name ?? ''
          }`,
        pslCode: customer.psl_code ?? '',
        accountType: getAccountType(customer.account_type),
        assignedTo: `${customer.assigned_to_user?.first_name ?? ''} ${customer.assigned_to_user?.last_name ?? ''
          }`,
        status: customer.status?.name ?? '',
        action: customer.id as string,
      })) ?? []
    );
  }, []);

  React.useEffect(() => {
    if (data) {
      dataRef.current = data?.member ?? [];
      setTableData(transformData(data.member));
    }
  }, [data, transformData]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, search }) => {
      const offset = pageIndex * pageSize;

      let fetchMoreMod = fetchMore;
      if (search) {
        fetchMoreMod = debounce(fetchMore, 300, { leading: true });
      }

      fetchMoreMod<keyof PaginatedCustomersQueryVariables>({
        variables: {
          offset,
          limit: pageSize,
          search: search ? `%${search}%` : null,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const newMembers = fetchMoreResult.member;
          setTableData(transformData(newMembers));
          return {
            ...prev,
            member: newMembers,
          };
        },
      });
    },
    [fetchMore, transformData]
  );

  const handleSubmit = (values: ValueProps) => {
    if (values.name === AccountType.individual) {
      history.push(`/dashboard/customers/new/individual`);
    }
    else if (values.name === AccountType.trust) {
      history.push(`/dashboard/customers/new/trust`);
    }
    else if (values.name === AccountType.joint) {
      history.push(`/dashboard/customers/new/joint`);
    }
  };

  return (
    <React.Fragment>
      {isOpen && (
        <CustomerTypeModal onClose={close} title="Create Customer Account" handleSubmit={handleSubmit} />
      )}
      <SectionTitle
        title="Customers"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}
      />
      <TableEx
        showPagination
        showControls
        data={tableData}
        columns={aColumns}
        totalCount={totalCount}
        loading={loading}
        fetchData={fetchData}
        placeholder={placeholder}
      />
    </React.Fragment>
  );
};
export default Customers;
