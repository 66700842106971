import React from 'react';
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { ICustomer, ITableColumn } from '../../../utils/types';
import Tag from '../../../components/tag/Tag';
import DataTable from '../../../components/table/DataTable';
/**@jsx jsx*/

interface IProps {
  members?: ICustomer[];
  isLoading?: boolean;
}

const columns: ITableColumn[] = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'PSL Code',
    dataIndex: 'pslCode',
    key: 'pslCode',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
  },
  {
    title: 'Assigned To',
    dataIndex: 'assignedTo',
    key: 'assignedTo',
  },
  {
    title: 'Type',
    dataIndex: 'isCustomer',
    key: 'isCustomer',
    render: (isCustomer: boolean) => {
      return <Tag>{isCustomer ? 'Customer' : 'Prospect'}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      return <Tag>{status}</Tag>;
    },
  },
];

const SearchTable: React.FC<IProps> = ({ members = [], isLoading }) => {
  const history = useHistory();
  return (
    <DataTable
      showControls
      showPagination
      data={members}
      isLoading={isLoading}
      columns={columns}
      actions={[
        {
          label: 'View',
          action: (member) => {
            history.push(
              `/dashboard/${member.isCustomer ? 'customers' : 'prospects'}/${
                member.id
              }`
            );
          },
        },
      ]}
      searchFilter="fullName"></DataTable>
  );
};

export default SearchTable;
