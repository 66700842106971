import React from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Grid from '../../components/layout/Grid';
import Stack from '../../components/stack/Stack';
import Flex from '../../components/layout/Flex';
import Accordion from '../../components/accordion/Accordion';
import LabeledValue from '../../components/labeledValue/LabeledValue';
import theme from '../../theme/theme';
import Loader from '../../components/loader/Loader';
import { toast } from 'react-toastify';
import Toastify from '../../components/toastify/Toastify';
import { jsx } from '@emotion/core';
import { ADMIN, BACKOFFICER } from '../../routes/routes';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import moment from 'moment';
import { useConvert_Prospect_CustomerMutation, useBackgroundNotificationQuery } from '../../generated/graphql'
/**@jsx jsx*/

interface INotification{
    id: string;
    status: string;
    pslcode: string | undefined;
    type: string | undefined;
    response: any | undefined;
    created_at: string;
}
interface IProp {
    notification?: INotification;
}

const ViewNotification: React.FC = () => {

  const history = useHistory();
  const location = useLocation();
  let notification_id = location.state as number
  const [
    approveProspect,
    { data: response },
  ] = useConvert_Prospect_CustomerMutation();

  const { data: bg_nofification , loading } = useBackgroundNotificationQuery({
    variables:{
        id: notification_id
    },
  });
  var notif =  bg_nofification?.background_notifications?.[0];

  const isCustomer = response?.createCustomer;

  const createCustomerCrm = () => {
    if (notif) {
      approveProspect({
        variables: {
          id: Number(notif.pslcode)
        },
        refetchQueries: ['Prospect'],
        awaitRefetchQueries: true
      });
    }
  };
  
  const viewCustomer = () => {
    history.push(`/dashboard/customers/${notif?.pslcode}`)
  }

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  React.useEffect(() => {
    if (isCustomer) {
        if(isCustomer.success)  {
            notify(true, 'Customer updated successfully.');
        setTimeout(() => {
            history.push('/dashboard/notifications');
        }, 1000);
        } else {
            notify(false, 'Failed to create customer.');
        }
    }
  }, [isCustomer, history]);

  
  return (
    <React.Fragment>
    {notif?.status === "failed" &&
        <SectionTitle title="Notifications" useBreadCrumb 
            action={{
                label: 'Re-Create Customer',
                function: () => {
                    createCustomerCrm()
                },
                allowed: [ADMIN, BACKOFFICER]}}
        />
    }
    {notif?.status === "success" &&
        <SectionTitle title="Notifications" useBreadCrumb 
            action={{
                label: 'View Customer',
                function: () => {
                    viewCustomer()
                },
                allowed: [ADMIN, BACKOFFICER]}}
        />
    }
    {
      notif?.status !== "success" && notif?.status !== "failed" &&
       <SectionTitle title="Notifications" useBreadCrumb/> 
    }
    {loading ? (
        <Flex css={{ height: '200px' }} ai="center" jc="center">
          <Loader
            size="small"
            color={theme.colors.primary}
            label="Loading Customer Data"
          />
        </Flex>
      ):
      (
       <React.Fragment>
        <Stack>
            <Accordion id="notification" title="Notification Details">
                <Grid lg={3}>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Notification Id"
                        value={`${notif?.id ?? ''}`}
                        >
                        </LabeledValue>
                    </div>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Created At"
                        value={moment(notif?.created_at ?? '').format('dddd, Do MMMM, YYYY')}
                        >
                        </LabeledValue>
                    </div>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Customer ID"
                        value={`${notif?.pslcode ?? ''}`}
                        >
                        </LabeledValue>
                    </div>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Type"
                        value={`${notif?.type ?? ''}`}
                        >
                        </LabeledValue>
                    </div>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Status"
                        value={`${notif?.status ?? ''}`}
                        >
                        </LabeledValue>
                    </div>
                </Grid>
            </Accordion>
            <Accordion id="notificationresponse" title="Notification Response">
                <Grid lg={3}>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Response Status"
                        value={`${notif?.response?.status ?? ''}`}
                        >
                        </LabeledValue>
                    </div>
                    <div>
                        <LabeledValue
                        key={1}
                        label="Request Response Message"
                        value={`${typeof notif?.response?.message == "object" ? notif?.response?.message?.stack ?? '' : notif?.response?.message ?? '' }`}
                        >
                        </LabeledValue>
                    </div>
                    
                </Grid>
            </Accordion>
            {typeof notif?.response?.message == "object" && 
                <Accordion id="notificationerror" title="Notification Error">
                <div css={{ maxWidth: '180px'}}>
                    <LabeledValue
                    key={1}
                    label="Response Message"
                    value={`${typeof notif?.response?.message == "object" ? notif?.response?.message?.config?.data ?? '' : notif?.response?.message ?? '' }`}
                    >
                    </LabeledValue>
                </div>
                </Accordion>
            }
        </Stack>
       </React.Fragment>
      )
    }
    </React.Fragment>
  );
};
export default ViewNotification;
