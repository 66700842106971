import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle, { ISectionTitleProps } from './SectionTitle';
import { useAuthState } from '../../context/auth';
import { IAction } from '../../utils/types';
/**@jsx jsx*/

// abandoned implementation. keep for reference
interface IProps extends ISectionTitleProps {
  add?: IAction;
}

const CrudSectionTitle: React.FC<IProps> = ({ add, action, ...rest }) => {
  const { role } = useAuthState();

  function getActions(): IAction | IAction[] {
    if (role === 'admin' && add !== undefined) {
      if (Array.isArray(action)) {
        return [add, ...action];
      }
      return add;
    }
    return [];
  }
  return (
    <React.Fragment>
      <SectionTitle {...rest} action={getActions()}></SectionTitle>
    </React.Fragment>
  );
};

export default CrudSectionTitle;
