import React from 'react';
import { toast } from 'react-toastify';
import theme from '../../theme/theme';
import AllocationsTable from './components/AllocationsTable';
import AllocationModal from './components/AllocationModal';
import {
  useAssetAllocationQuery,
  useDelete_Asset_AllocationMutation,
} from '../../generated/graphql';
import { IAssetAllocation } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';

const Durations: React.FC = () => {
  const { userId } = useAuthState();
  const { data: allocations, loading } = useAssetAllocationQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAssetAllocation] = useDelete_Asset_AllocationMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedAllocation, setSelectedAllocation] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedAllocation(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (allocation: any) => {
    setIsOpen(true);
    setSelectedAllocation(allocation.id);
  };

  const remove = (allocation: any) => {
    setShowConfirm(true);
    setSelectedAllocation(allocation.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const removeAllocationDuration = async () => {
    const response = await deleteAssetAllocation({
      variables: {
        id: selectedAllocation,
        doneBy: userId,
      },
      update: UpdateHandlers.deleteAssetAllocation,
    });
    setShowConfirm(false);

    if (response.data?.update_asset_allocation?.affected_rows) {
      notify(true, 'Asset allocation deleted successfully.');
    } else {
      notify(false, 'failed to delete asset allocation.');
    }
  };

  const getAssetAllocation = (id?: number) => {
    if (id) {
      const allocation = allocations?.asset_allocation?.find(
        (allocation) => allocation.id === id
      );
      if (allocation) {
        return {
          id: allocation.id,
          product: allocation.product_id,
          duration: allocation.asset_duration_id,
          weight: (allocation.weight * 100).toString(),
        };
      }
    }
    return;
  };

  const getAssetAllocations = (): IAssetAllocation[] => {
    return (
      allocations?.asset_allocation?.map((allocation) => ({
        id: allocation.id,
        product: allocation.product.name,
        productId: allocation.product_id,
        duration: `${allocation.asset_duration.duration_start} - ${allocation.asset_duration.duration_end}`,
        assetDurationId: allocation.product_id,
        weight: (allocation.weight * 100).toString(),
        createdAt: allocation.created_at,
        lastChanged: allocation.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <AllocationModal
          onClose={close}
          allocation={getAssetAllocation(selectedAllocation)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          onClose={close}
          confirm={{ label: 'ok', function: removeAllocationDuration }}
          title="Delete Allocation"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Allocation"
        action={{
          label: 'Add new Allocation',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <AllocationsTable
        isLoading={loading}
        allocations={getAssetAllocations()}
        actions={[
          { label: 'Delete', action: remove },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default Durations;
