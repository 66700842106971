import React from 'react';
import { jsx } from '@emotion/core';
import Card from './Card';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
import Separator from '../separator/Separator';
import Avatar from '../avatar/Avatar';
import Icon from '../icon/Icon';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { IAction } from '../../utils/types';
import Button from '../button/Button';
/**@jsx jsx*/

interface IProps {
  title: string;
  subText?: string;
  className?: string;
  initials?: string;
  icon?: IconName;
  action?: IAction;
}

export const InfoRow: React.FC<{
  label: string;
  value?: string;
  className?: string;
}> = ({ label, value, className }) => {
  return (
    <div className={className}>
      <Flex jc="space-between" ai="center">
        <p
          css={{
            color: theme.colors.gray[700],
            fontFamily: theme.typography.fonts.semibold,
            fontSize: '15px',
          }}>
          {label}
        </p>
        <p
          css={{
            color: theme.colors.gray[500],
            textAlign: 'left',
            fontSize: '15px',
          }}>
          {value}
        </p>
      </Flex>
      <Separator gap={8} />
    </div>
  );
};

const InfoCard: React.FC<IProps> = ({
  children,
  title,
  icon,
  className,
  subText,
  initials,
  action,
}) => {
  return (
    <Card className={className}>
      <div css={{}}>
        <Flex
          ai={subText ? 'flex-start' : 'center'}
          css={{ padding: '16px 16px', marginBottom: '12px' }}>
          <Avatar
            css={{ marginRight: '16px' }}
            background={theme.colors.cyan[100]}
            size="sm"
            name={initials}>
            {icon && (
              <Icon
                color={theme.colors.cyan[700]}
                icon={['fas', icon]}></Icon>
            )}
          </Avatar>
          <div>
            <h3
              css={{
                fontSize: '14px',
                fontFamily: theme.typography.fonts.semibold,
                color: theme.colors.gray[600],
              }}>
              {title}
            </h3>
            <p css={{ color: theme.colors.gray[400], fontSize: '14px' }}>
              {subText}
            </p>
          </div>
        </Flex>
        <Separator gap={0} />
        <Flex css={{ padding: '8px 16px' }}>
          <Button
            css={{
              color: theme.colors.blue[600],
              fontFamily: theme.typography.fonts.semibold,
              padding: `8px 0`,
              height: 'auto',
            }}
            appearance="ghost"
            action={action?.function}
            path={action?.path}>
            {action?.label}
          </Button>
        </Flex>
      </div>
      <div>{children}</div>
    </Card>
  );
};

export default InfoCard;
