import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
/**@jsx jsx */

interface IProps {
  className?: 'string';
}
const Row: React.FC<IProps> = ({ children, className }) => {
  return (
    <div
      className={className}
      css={{
        width: '80%',
        maxWidth: '1440px',
        margin: '0 auto',
        [theme.breakpoints.sm]: { width: '90%' },
        [theme.breakpoints.lg]: { width: '90%' },
      }}>
      {children}
    </div>
  );
};

export default Row;
