import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../../../components/modal/Modal';
import Grid from '../../../components/layout/Grid';
import DateInput from '../../../components/date/DateInput';
import Separator from '../../../components/separator/Separator';
import Statement from '../../../components/statement/Statement';
import { IModal, StatementData } from '../../../utils/types';
import { useGenerateStatementMutation } from '../../../generated/graphql';
import { StatementSchema } from '../../../utils/yup-schema';
import { Formik, Field, FieldProps, ErrorMessage } from 'formik';
import Button from '../../../components/button/Button';
import axios from 'axios';
import FileSaver from 'file-saver';
import { toast } from 'react-toastify';
import { getAccessToken } from '../../../accessToken';
import moment from 'moment';
import Stack from '../../../components/stack/Stack';
import Flex from '../../../components/layout/Flex';
import Toastify from '../../../components/toastify/Toastify';
import theme from '../../../theme/theme';
/**@jsx jsx*/

interface ValueProps {
  startDate: Date;
  endDate: Date;
}

type IProps = IModal & {
  customerId: string;
};

const StatementModal: React.FC<IProps> = ({ onClose, customerId }) => {
  const [initialValues] = React.useState<ValueProps>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [statementResponse, setStatementResponse] = React.useState<
    StatementData | undefined
  >();
  const [
    generateStatement,
    { loading: statementGenerationLoading },
  ] = useGenerateStatementMutation();

  const [shouldDownloadStatement, setShouldDownloadStatement] = React.useState<
    boolean
  >(false);

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const InitiateDownload = (
    e: React.MouseEvent<HTMLElement>,
    submit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  ) => {
    e.preventDefault();
    setShouldDownloadStatement(true);
    submit();
  };

  const DownloadStatement = async (values: ValueProps) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CBL_ENDPOINT}/statement/download`,
        {
          responseType: 'arraybuffer',
          params: {
            customerId: customerId,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: moment(values.endDate).format('YYYY-MM-DD'),
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      FileSaver.saveAs(blob, 'statement.pdf');
      notify(true, 'Downloaded Successfully');
      setShouldDownloadStatement(false);
    } catch (err) {
      notify(false, 'Download failed');
      setShouldDownloadStatement(false);
    }
  };

  const getStatementData = async (values: ValueProps) => {
    try {
      const response = await generateStatement({
        variables: {
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
          customerId: Number(customerId),
        },
      });

      if (response.data?.generateStatement.success) {
        setStatementResponse(response.data.generateStatement.results);
      }
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleSubmit = async (values: ValueProps) => {
    try {
      if (shouldDownloadStatement) {
        await DownloadStatement(values);
        return;
      }
      await getStatementData(values);
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={StatementSchema}
      enableReinitialize
      render={({
        values,
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            onClose={onClose}
            top={50}
            // zIndex={9998}
            width="1100px"
            icon={{ name: 'file-download' }}
            title="Generate Statement">
            <Grid
              css={{ alignItems: 'flex-end', marginBottom: '24px' }}
              gap={12}
              lg={2}>
              <div>
                <Field name="startDate">
                  {({ field, meta }: FieldProps) => (
                    <div>
                      <DateInput
                        {...field}
                        type="text"
                        name="startDate"
                        placeholder="Enter Start Date"
                        label="Start Date"
                        defaultDate={values.startDate}
                        minDate={new Date(1700, 5, 4)}
                        maxDate={new Date()}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="startDate" />
                    </div>
                  )}
                </Field>
              </div>

              <div>
                <Field name="endDate">
                  {({ field, meta }: FieldProps) => (
                    <div>
                      <DateInput
                        {...field}
                        type="text"
                        name="endDate"
                        placeholder="Enter End Date"
                        label="End Date"
                        defaultDate={values.endDate}
                        minDate={new Date(1700, 5, 4)}
                        maxDate={new Date()}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="endDate" />
                    </div>
                  )}
                </Field>
              </div>
            </Grid>

            <Flex jc="center">
              <Stack isInline>
                <Button
                  type="submit"
                  className="update-btn"
                  isLoading={statementGenerationLoading}>
                  Preview
                </Button>

                <Button
                  appearance="outline"
                  className="update-btn"
                  action={(e: React.MouseEvent<HTMLElement>) =>
                    InitiateDownload(e, handleSubmit)
                  }
                  isLoading={shouldDownloadStatement}>
                  Download
                </Button>
              </Stack>
            </Flex>
            <Separator />

            <div>
              <Statement statementPayload={statementResponse} />
            </div>
          </Modal>
        </form>
      )}
    />
  );
};

export default StatementModal;
