import React from 'react';
import { jsx } from '@emotion/core';
import theme from '../../../theme/theme';
import { useLocation } from 'react-router-dom';
/**@jsx jsx*/

function stripSpaces(string: string) {
  return string.split(' ').join('').toLowerCase();
}

const MiniNav: React.FC<{ sections?: string[] }> = ({ sections = [] }) => {
  const location = useLocation();

  function isActiveSection(section: string) {
    return location.hash === '#' + stripSpaces(section);
  }
  return (
    <div
      css={{
        a: {
          display: 'block',
          textTransform: 'capitalize',
          padding: '6px 8px',
          fontSize: '12px',
          borderRadius: '4px',
        },
      }}>
      {sections.map((section, index) => {
        return (
          <a
            css={{
              color: isActiveSection(section)
                ? theme.colors.blue[600]
                : theme.colors.gray[400],
              background: isActiveSection(section)
                ? theme.colors.blue[50]
                : theme.colors.gray[0],
              '&:hover': {
                background: isActiveSection(section)
                  ? theme.colors.blue[50]
                  : theme.colors.gray[50],
              },
            }}
            key={index}
            href={'#' + stripSpaces(section)}>
            {section}
          </a>
        );
      })}
    </div>
  );
};

export default MiniNav;
