// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from 'react';
import { jsx } from '@emotion/core';
import { getIn, useFormikContext } from 'formik';
import Flex from '../layout/Flex';
import theme from '../../theme/theme';
import { FormData } from '../../utils/types';

/**@jsx jsx*/

const ErrorMessageComp = ({ name }: { name: string }) => {
  const { errors } = useFormikContext<FormData>();
  const error = getIn(errors, name) ?? null;
  return (
    <React.Fragment>
      {error && (
        <Flex
          jc="flex-end"
          css={{ color: theme.colors.red[400] }}
          className={`${name}-error`}>
          {error}
        </Flex>
      )}
    </React.Fragment>
  );
};

export default ErrorMessageComp;
