import React from 'react';
import { toast } from 'react-toastify';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import AgentsTable from './components/BackOfficerTable';
import BackOfficerModal from './components/BackOfficerModal';
import {
  useBackOfficersQuery,
  useDelete_UserMutation,
} from '../../generated/graphql';
import { IUser } from '../../utils/types';
import moment from 'moment';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';

const BackOfficers: React.FC = () => {
  const { userId } = useAuthState();
  const { data: officers, loading } = useBackOfficersQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAdvisor] = useDelete_UserMutation();

  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedOfficer, setSelectedOfficer] = React.useState<
    number | undefined
  >();
  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedOfficer(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (backOfficer: IUser) => {
    setIsOpen(true);
    setSelectedOfficer(backOfficer.id);
  };

  const deactivate = (backOfficer: IUser) => {
    setSelectedOfficer(backOfficer.id);
    setShowConfirm(true);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const deactivateBackOfficer = async () => {
    const response = await deleteAdvisor({
      variables: {
        id: selectedOfficer,
        doneBy: userId,
      },
      refetchQueries: ['BackOfficers'],
      awaitRefetchQueries: true,
    });
    setShowConfirm(false);

    if (response.data?.update_user?.affected_rows) {
      notify(true, 'BackOfficer deleted successfully.');
    } else {
      notify(false, 'failed to delete backofficer.');
    }
  };

  const getBackOfficer = (id?: number) => {
    if (id) {
      const officer = officers?.user?.find((officer) => officer.id === id);
      if (officer) {
        return {
          id: officer.id,
          firstName: officer.first_name as string,
          lastName: officer.last_name as string,
          otherName: officer.other_name ?? '',
          gender: officer.gender_id,
          dateOfBirth: moment(officer.date_of_birth).toDate(),
          mobile: officer.mobile as string,
          email: officer.email as string,
          username: officer.email as string,
          password: 'officer6_',
        };
      }
    }
    return;
  };

  const getBackOfficers = (): IUser[] => {
    return (
      officers?.user?.map((officer) => ({
        id: officer.id,
        fullName: `${officer.first_name ?? ''} ${officer.other_name ?? ''} ${
          officer.last_name ?? ''
        }`,
        gender: officer.gender.name as string,
        gender_id: officer.gender_id,
        dateOfBirth: officer.date_of_birth,
        mobile: officer.mobile as string,
        email: officer.email as string,
        createdAt: officer.created_at,
        lastChanged: officer.updated_at,
        status: 'active',
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <BackOfficerModal
          onClose={close}
          officer={getBackOfficer(selectedOfficer)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: deactivateBackOfficer }}
          onClose={close}
          title="Delete Backofficer"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="BackOfficers"
        action={{
          label: 'Add new BackOfficer',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <AgentsTable
        isLoading={loading}
        agents={getBackOfficers()}
        actions={[
          { label: 'Deactivate', action: deactivate },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default BackOfficers;
