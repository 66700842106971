import React from 'react';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import DataTable from '../../components/table/DataTable';
import { usePaymentsSubscription } from '../../generated/graphql';
/**@jsx jsx*/

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'PSL Code',
    dataIndex: 'psl_code',
    key: 'psl_code',
  },
  {
    title: 'MF Code',
    dataIndex: 'mf_code',
    key: 'mf_code',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    key: 'payment_date',
  },
  {
    title: 'Value Date',
    dataIndex: 'value_date',
    key: 'value_date',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const Payments: React.FC = () => {
  const { data, loading } = usePaymentsSubscription();

  const payments =
    data?.payment.map((entry) => ({
      id: entry.id,
      mf_code: entry.member?.mf_code ?? '',
      psl_code: entry.member?.psl_code ?? '',
      payment_date: entry.payment_date,
      value_date: entry.value_date,
      amount: entry.amount,
      status: entry.status?.name ?? '',
    })) || [];

  return (
    <React.Fragment>
      <SectionTitle title="Payments" />
      <DataTable
        isLoading={loading}
        showControls
        showPagination
        data={payments}
        columns={columns}
        // actions={[
        //   // { label: 'Delete', action: () => {} },
        //   // { label: 'Edit', action: () => {} },
        // ]}
        searchFilter={['id', 'mf_code', 'psl_code', 'amount', 'status']}
      />
    </React.Fragment>
  );
};

export default Payments;
