import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { useMeQuery } from '../generated/graphql';
import { setAccessToken } from '../accessToken';
import { allRoutes } from './routes';
import { useAuthState } from '../context/auth';

export const AuthRoute: React.FC<RouteProps> = ({
  component,
  // children,
  ...rest
}) => {
  const { data, loading } = useMeQuery();
  const { role } = useAuthState();
  const { pathname } = useLocation();

  const renderRoute = (routeProps: RouteComponentProps<{}>) => {
    if (!data || loading) {
      return null;
    }

    if (!data || !data.me.user_type?.id) {
      setAccessToken('');
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { next: routeProps.location.pathname },
          }}
        />
      );
    }

    const match = routeProps.match;
    const params: any = match.params;
    const activeRoute = allRoutes.find((route) => {
      return route.route.replace(/:id/, params.id) === pathname;
    });

    let allowedRoute = false;
    if (role) {
      allowedRoute = !!activeRoute?.allowed?.includes(role);
    }

    if (activeRoute && !allowedRoute) {
      return <Redirect to={{ pathname: '/dashboard' }} />;
    }

    const Component = component as any;
    return <Component {...routeProps} />;
  };

  return <Route {...rest} render={renderRoute}></Route>;
};
