import React from "react";
import ReactExport from 'react-data-export';
import Button from "../../../components/button/Button";


const ExcelFile = ReactExport.ExcelFile;
const { ExcelSheet } = ReactExport;
const { ExcelColumn } = ReactExport;


interface PaymentSplitData {
    paymentId: number;
    mf_code?: string;
    customer_code: string;
    customer: string;
    payment_date: string;
    value_date: string;
    amount: number;
    balanced_income_fund: number;
    plus_income_fund: number;
}
interface ISplitPayment {
  valueDate: string;
  splitData: PaymentSplitData[];
}


const SplitDownload = ({ valueDate, splitData }: ISplitPayment) => {

  return (
      <ExcelFile
          element={<Button>Download Payment Split</Button>}
          filename={`Payment Split Data - ${valueDate}`}
       >
      <ExcelSheet data={splitData} name={`Payment Split Data - ${valueDate}`}>
        <ExcelColumn label="Payment ID" value="paymentId" />
        <ExcelColumn label="MF Code" value="mf_code" />
        <ExcelColumn label="Customer Code" value="customer_code" />
        <ExcelColumn label="Customer" value="customer" />
        <ExcelColumn label="Transaction Date" value="payment_date" />
        <ExcelColumn label="Value Data" value="value_date" />
        <ExcelColumn label="Amount" value="amount" />
        <ExcelColumn
          label="Plus Balanced Fund"
          value="balanced_income_fund"
        />
        <ExcelColumn label="Plus Income Fund" value="plus_income_fund" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default SplitDownload;


