import React from 'react';
import { jsx, CSSObject } from '@emotion/core';
import theme from '../../theme/theme';
/**@jsx jsx */
interface IProps {
  gap?: string | number;
  xs?: number;
  lg?: number;
  sm?: number;
  md?: number;
  xl?: number;
  className?: string;
}

const Grid: React.FC<IProps> = ({
  gap = '20px',
  lg = 12,
  md,
  sm,
  xl,
  xs,
  children,
  className,
}) => {
  return (
    <div
      className={className}
      css={{
        display: 'grid',
        gridTemplateColumns: `repeat(${lg},1fr)`,
        gridGap: gap,
        [theme.breakpoints.md]: {
          gridTemplateColumns: md && `repeat(${md},1fr) !important`,
        },
        [theme.breakpoints.lg]: {
          gridTemplateColumns: lg && `repeat(${lg},1fr) !important`,
        },
        [theme.breakpoints.xl]: {
          gridTemplateColumns: xl && `repeat(${xl},1fr) !important`,
        },
        [theme.breakpoints.sm]: {
          gridTemplateColumns: sm && `repeat(${sm},1fr) !important`,
        },
        [theme.breakpoints.xs]: {
          gridTemplateColumns: xs && `repeat(${xs},1fr) !important`,
        },
      }}>
      {children}
    </div>
  );
};

interface IGridItemProps {
  span?: string | number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  className?: string;
  styles?: CSSObject;
}

export const GridItem: React.FC<IGridItemProps> = ({
  children,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  styles,
}) => {
  return (
    <div
      className={className}
      css={{
        gridColumn: `span ${span}`,
        [theme.breakpoints.xs]: { gridColumn: xs && `span ${xs} !important` },
        [theme.breakpoints.sm]: { gridColumn: sm && `span ${sm} !important` },
        [theme.breakpoints.md]: { gridColumn: md && `span ${md} !important` },
        [theme.breakpoints.lg]: { gridColumn: lg && `span ${lg} !important` },
        [theme.breakpoints.xl]: {
          gridColumn: xl && `span ${xl} !important`,
        },
        ...styles,
      }}>
      {children}
    </div>
  );
};

export default Grid;
