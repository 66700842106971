import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import MaritalStatussTable from './components/MaritalStatusesTable';
import MaritalStatusModal from './components/MaritalStatusModal';
import {
  useMaritalStatusQuery,
  useDelete_Marital_StatusMutation,
} from '../../generated/graphql';
import { MaritalStatus } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/

const MaritalStatuses: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = React.useState<
    number | undefined
  >();
  const { data: maritalStatuses, loading } = useMaritalStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteMaritalStatus] = useDelete_Marital_StatusMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedMaritalStatus(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (maritalStatus: MaritalStatus) => {
    setIsOpen(true);
    setSelectedMaritalStatus(maritalStatus.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    try {
      const response = await deleteMaritalStatus({
        variables: {
          id: selectedMaritalStatus,
          doneBy: userId,
        },
        update: UpdateHandlers.deleteMaritalStatus,
      });
      setShowConfirm(false);

      if (response.data?.update_marital_status?.affected_rows) {
        notify(true, 'Marital Status deleted successfully.');
      } else {
        notify(false, 'failed to delete marital status.');
      }
    } catch (err) {}
  };

  const confirm = (maritalStatus: MaritalStatus) => {
    setSelectedMaritalStatus(maritalStatus.id);
    setShowConfirm(true);
  };

  const getMaritalStatus = (id?: number) => {
    if (id) {
      const maritalStatus = maritalStatuses?.marital_status?.find(
        (maritalStatus) => maritalStatus.id === id
      );
      if (maritalStatus) {
        return {
          id: maritalStatus.id,
          name: maritalStatus.name,
        };
      }
    }
    return;
  };

  const getMaritalStatuss = (): MaritalStatus[] => {
    return (
      maritalStatuses?.marital_status?.map((maritalStatus) => ({
        id: maritalStatus.id,
        name: maritalStatus.name,
        createdAt: maritalStatus.created_at,
        lastChanged: maritalStatus.updated_at,
      })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <MaritalStatusModal
          onClose={close}
          maritalStatus={getMaritalStatus(selectedMaritalStatus)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Marital Status"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Marital Status"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <MaritalStatussTable
        isLoading={loading}
        maritalStatuses={getMaritalStatuss()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default MaritalStatuses;
