import React from 'react';
import { jsx } from '@emotion/core';
import Flex from '../../layout/Flex';
import Icon from '../../icon/Icon';
import theme from '../../../theme/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import { IRoute } from '../../../routes/routes';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface IProps {
  route: IRoute;
  className?: string;
  isActiveRoute?: boolean;
  isSubMenu?: boolean;
  onClick?(): void;
}

const MenuItem: React.FC<IProps> = ({
  route,
  className,
  isActiveRoute,
  isSubMenu = false,
  onClick,
}) => {
  const { role } = useAuthState();

  if (!route.allowed?.includes(role as string)) {
    return null;
  }

  return (
    <div>
        <Link onClick={onClick} to={route.route}>
          <div
            className={className}
            css={{
              padding: '8px 20px',
              borderLeft: isSubMenu
                ? '0px solid transparent'
                : `3px solid 
                  ${isActiveRoute ? theme.colors.blue[600] : 'transparent'}`,
              width: '100%',
              background: isActiveRoute ? theme.colors.blue[100] : 'transparent',
              '&:hover': {
                background: isActiveRoute
                  ? theme.colors.blue[100]
                  : theme.colors.gray[200],
              },
              userSelect: 'none',
              cursor: 'pointer'
            }}>
              <Flex css={{ marginRight: '8px', transition: 'all .5s' }} ai="center">
                {/* Add width to ensure equal distance between icon and menu label */}
                <div css={{ marginRight: '8px', width: '24px' }}>
                  {route.icon && (
                    <Icon
                      color={
                        isActiveRoute
                          ? theme.colors.blue[600]
                          : theme.colors.gray[500]
                      }
                      css={{ fontSize: '16px' }}
                      // icon={['fas', route.icon as IconName]}></Icon>
                      icon={route.icon as IconProp}></Icon>
                  )}
                </div>
                <p
                  css={{
                    fontFamily: isActiveRoute
                      ? theme.typography.fonts.semibold
                      : theme.typography.fonts.medium,
                    textTransform: 'capitalize',
                    color: isActiveRoute
                      ? theme.colors.blue[800]
                      : theme.colors.gray[700],
                    fontSize: '14px',
                  }}>
                  {route.name}
                </p>
              </Flex>
          </div>
        </Link>
    </div>
  );
};

export default MenuItem;
