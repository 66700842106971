import { IconName } from '@fortawesome/free-solid-svg-icons';
import { flattenRoutes } from '../utils/helpers';

export interface IRoute {
  id?: number;
  name: string;
  route: string;
  icon?: IconName;
  children?: IRoute[];
  subId?: string;
  parentSubIds?: string[];
  allowed?: string[];
}

export const ADMIN = 'admin';
export const BACKOFFICER = 'backofficer';
export const ADVISOR = 'advisor';
export const PORTFOLIO = 'portfolio-analyst';
export const CLIENT_SERVICE = 'client_service';
export const ANONYMOUS = 'anonymous';

export const menuRoutes: IRoute[] = [
  {
    id: 1,
    name: 'dashboard',
    route: '/dashboard',
    icon: 'table-columns',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
  {
    id: 2,
    name: 'customers',
    route: '/dashboard/customers',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
  {
    id: 3,
    name: 'prospects',
    route: '/dashboard/prospects',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
  {
    id: 4,
    subId: 'sub1',
    name: 'administration',
    route: '#',
    icon: 'building-user',
    allowed: [ADMIN],
    children: [
      {
        id: 1,
        name: 'Payment Providers',
        route: '/dashboard/payment-providers',
        allowed: [ADMIN],
      },
      {
        id: 2,
        name: 'Goals',
        route: '/dashboard/goals',
        allowed: [ADMIN],
      },

      {
        id: 3,
        name: 'Gender',
        route: '/dashboard/genders',
        allowed: [ADMIN],
      },
      {
        id: 4,
        name: 'Relationships',
        route: '/dashboard/relationships',
        allowed: [ADMIN],
      },
      {
        id: 5,
        name: 'Titles',
        route: '/dashboard/titles',
        allowed: [ADMIN],
      },
      {
        id: 6,
        name: 'Industries',
        route: '/dashboard/industries',
        allowed: [ADMIN],
      },
      {
        id: 7,
        name: 'Products',
        route: '/dashboard/products',
        allowed: [ADMIN],
      },
      {
        id: 8,
        name: 'Product Unit Prices',
        route: '/dashboard/product-unit-prices',
        allowed: [ADMIN],
      },
      {
        id: 9,
        name: 'Identification Types',
        route: '/dashboard/identification-types',
        allowed: [ADMIN],
      },
      {
        id: 10,
        name: 'Income Sources',
        route: '/dashboard/income-sources',
        allowed: [ADMIN],
      },
      {
        id: 11,
        name: 'Contribution Sources',
        route: '/dashboard/contribution-sources',
        allowed: [ADMIN],
      },
      {
        id: 12,
        name: 'Marital Status',
        route: '/dashboard/marital-status',
        allowed: [ADMIN],
      },
      {
        id: 13,
        name: 'Insurance Type',
        route: '/dashboard/insurance-type',
        allowed: [ADMIN],
      },
      {
        id: 14,
        name: 'APKs',
        route: '/dashboard/apk',
        allowed: [ADMIN],
      },
      {
        id: 15,
        subId: 'sub2',
        parentSubIds: ['sub1'],
        name: 'Glide Path',
        route: '#',
        allowed: [ADMIN],
        children: [
          {
            id: 1,
            name: 'Asset Duration',
            route: '/dashboard/durations',
            allowed: [ADMIN],
          },
          {
            id: 2,
            name: 'Asset Allocation',
            route: '/dashboard/allocations',
            allowed: [ADMIN],
          },
        ],
      },
      {
        id: 16,
        subId: 'sub3',
        parentSubIds: ['sub1'],
        name: 'Users',
        route: '#',
        allowed: [ADMIN],
        children: [
          {
            id: 1,
            name: 'Back Officers',
            route: '/dashboard/backofficers',
            allowed: [ADMIN],
          },
          {
            id: 2,
            name: 'Admins',
            route: '/dashboard/admins',
            allowed: [ADMIN],
          },
          {
            id: 3,
            name: 'Advisors',
            route: '/dashboard/advisors',
            allowed: [ADMIN],
          },
          {
            id: 4,
            name: 'Portfolio Analysts',
            route: '/dashboard/portfolio-analysts',
            allowed: [ADMIN],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    name: 'payments',
    route: '#',
    icon: 'money-bill',
    subId: 'sub4',
    allowed: [ADMIN, BACKOFFICER, CLIENT_SERVICE, PORTFOLIO],
    children: [
      {
        id: 1,
        name: 'Import',
        allowed: [ADMIN, BACKOFFICER],
        route: '/dashboard/import-payments',
      },
      {
        id: 2,
        name: 'List',
        allowed: [ADMIN, BACKOFFICER],
        route: '/dashboard/list-payments',
      },
      {
        id: 3,
        name: 'Approvals',
        allowed: [ADMIN, BACKOFFICER, CLIENT_SERVICE, PORTFOLIO],
        route: '/dashboard/approve-payments',
      },
    ],
  },
  {
    id: 6,
    name: 'Notifications',
    route: '/dashboard/notifications',
    icon: 'bell',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 7,
    name: 'Settings',
    route: '/dashboard/settings',
    icon: 'gear',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
];
export const appRoutes: IRoute[] = [
  ...menuRoutes,
  {
    id: 7,
    name: 'Customer',
    route: '/dashboard/customers/:id',
    icon: 'table-columns',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
  {
    id: 8,
    name: 'Edit',
    route: '/dashboard/customers/:id/edit',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 9,
    name: 'Add Individual Customer',
    route: '/dashboard/customers/new/individual',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 10,
    name: 'Add Trust Customer',
    route: '/dashboard/customers/new/trust',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 11,
    name: 'Add Joint Customer',
    route: '/dashboard/customers/new/joint',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 12,
    name: '',
    route: '/dashboard/prospects/:id',
    icon: 'circle-user',
    allowed: [ADMIN, BACKOFFICER, ADVISOR],
  },
  {
    id: 13,
    name: 'Add Prospects',
    route: '/dashboard/create-prospect',
    icon: 'circle-user',
    allowed: [ADVISOR]
  },
  {
    id: 14,
    name: 'View Notification',
    route: '/dashboard/notifications/:id',
    icon: 'bell',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 15,
    name: '',
    route: '/dashboard/search',
    allowed: [ADMIN, BACKOFFICER],
  },
  {
    id: 16,
    name: '',
    route: 'dashboard/logout',
    allowed: [ADMIN, BACKOFFICER],
  },
];

export const allRoutes = appRoutes
  .map((route) => {
    return flattenRoutes(route);
  })
  .flat();

export const getAllowedMenuRoutes = (role: string) => {
  menuRoutes.map((menuRoute) => {
    return {
      ...menuRoute,
      ...(Array.isArray(menuRoute.children)
        ? {
          children: menuRoute.children.filter((route) =>
            route.allowed?.includes(role)
          ),
        }
        : {}),
    };
  });
};
