import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useAdd_RelationshipMutation,
  useUpdate_RelationshipMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import UpdateHandlers from '../../../utils/handlers';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  name: string;
}

type IRelationshipModal = IModal & {
  relationship?: { id: number; name: string };
};

const RelationshipSchema = Yup.object().shape({
  name: Yup.string().required('required'),
});

const RelationshipModal: React.FC<IRelationshipModal> = ({
  onClose,
  relationship,
}) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    name: '',
  });

  const [
    addRelationship,
    {
      data: addRelationshipResult,
      loading: addRelationshipLoading,
      error: addRelationshipError,
    },
  ] = useAdd_RelationshipMutation();

  const [
    updateRelationship,
    {
      data: updateRelationshipResult,
      loading: updateRelationshipLoading,
      error: updateRelationshipError,
    },
  ] = useUpdate_RelationshipMutation();

  React.useEffect(() => {
    if (relationship) {
      setInitialValues({
        name: relationship.name,
      });
    }
  }, [relationship]);

  const addNewRelationship = (values: ValueProps) => {
    addRelationship({
      variables: {
        changes: [
          {
            name: values.name,
            created_by: userId,
            modified_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addRelationship,
    });
  };

  const upateExistingRelationship = (values: ValueProps) => {
    updateRelationship({
      variables: {
        id: relationship?.id,
        changes: {
          name: values.name,
          modified_by: userId,
        },
      },
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (relationship) {
      upateExistingRelationship(values);
    } else {
      addNewRelationship(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addRelationshipLoading || updateRelationshipLoading);
  const error = !!(addRelationshipError || updateRelationshipError);
  const success = !!(
    addRelationshipResult?.insert_relationship?.returning?.length ||
    updateRelationshipResult?.update_relationship?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={RelationshipSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${relationship ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${relationship ? 'Update' : 'Add'} Relationship`}>
            <React.Fragment>
              {error && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.red[500] }}
                  className="add-failed">
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Relationship ${
                    relationship ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}
              <div>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  label="Relationship Name"
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.name}
                />
                <ErrorMessage name="name" />
              </div>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default RelationshipModal;
