import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../../../components/modal/Modal';
import { IModal } from '../../../utils/types';
import { InfoDisplayGrid } from '../ViewCustomer';
/**@jsx jsx*/

export interface ICustomerDetailModalConfig extends IModal {
  title: string;
  data?: any;
  render?(): void;
  ignore?: string[];
}

const CustomerDetailModal: React.FC<ICustomerDetailModalConfig> = ({
  title,
  data,
  onClose,
  render,
  ignore,
}) => {
  return (
    <Modal
      width="50%"
      icon={{ name:'circle-user'}}
      onClose={onClose}
      title={title}>
      {render && render()}
      {data && <InfoDisplayGrid ignore={ignore} object={data} />}
    </Modal>
  );
};

export default CustomerDetailModal;
