import React from 'react';
import { jsx } from '@emotion/core';
import { Formik, Field, Form, FormikHelpers, FieldProps } from 'formik';
import Modal from '../../../components/modal/Modal';
import Input from '../../../components/input/Input';
import { IModal } from '../../../utils/types';
import theme from '../../../theme/theme';
import {
  useAdd_Asset_DurationMutation,
  useUpdate_Asset_DurationMutation,
} from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import { AssetDurationSchema } from '../../../utils/yup-schema';
import UpdateHandlers from '../../../utils/handlers';
import Stack from '../../../components/stack/Stack';
import ErrorMessage from '../../../components/ErrorMessage';
import { useAuthState } from '../../../context/auth';
/**@jsx jsx*/

interface ValueProps {
  start: string;
  end: string;
}

type IAllocationModal = IModal & {
  duration?: { id: number; start: string; end: string };
};

const DurationModal: React.FC<IAllocationModal> = ({ onClose, duration }) => {
  const { userId } = useAuthState();
  const [initialValues, setInitialValues] = React.useState<ValueProps>({
    start: '',
    end: '',
  });

  const [
    addPaymentProvider,
    {
      data: addAssetDurationResult,
      loading: addAssetDurationLoading,
      error: addAssetDurationError,
    },
  ] = useAdd_Asset_DurationMutation();
  const [
    updatePaymentProvider,
    {
      data: updateAssetDurationResult,
      loading: updateAssetDurationLoading,
      error: updateAssetDurationError,
    },
  ] = useUpdate_Asset_DurationMutation();

  React.useEffect(() => {
    if (duration) {
      setInitialValues({
        start: duration.start,
        end: duration.end,
      });
    }
  }, [duration]);

  const addAssetDuration = (values: ValueProps) => {
    addPaymentProvider({
      variables: {
        changes: [
          {
            duration_start: Number(values.start),
            duration_end: Number(values.end),
            created_by: userId,
          },
        ],
      },
      update: UpdateHandlers.addAssetDuration,
    });
  };

  const updateAssetDuration = (values: ValueProps) => {
    updatePaymentProvider({
      variables: {
        id: duration?.id,
        changes: {
          duration_start: Number(values.start),
          duration_end: Number(values.end),
          modified_by: userId,
        },
      },
      update: UpdateHandlers.updateAssetDuration,
    });
  };

  const handleSubmit = (
    values: ValueProps,
    { setSubmitting }: FormikHelpers<ValueProps>
  ) => {
    if (duration) {
      updateAssetDuration(values);
    } else {
      addAssetDuration(values);
    }
    setSubmitting(false);
  };

  const loading = !!(addAssetDurationLoading || updateAssetDurationLoading);
  const error = !!(addAssetDurationError || updateAssetDurationError);
  const success = !!(
    addAssetDurationResult?.insert_asset_duration?.returning?.length ||
    updateAssetDurationResult?.update_asset_duration?.returning?.length
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={AssetDurationSchema}
      enableReinitialize>
      {(formikProps) => (
        <Form>
          <Modal
            onClose={onClose}
            cancel={{ label: 'Cancel', function: onClose }}
            confirm={{
              type: 'submit',
              label: `${duration ? 'Update' : 'Add'}`,
              loading: formikProps.isSubmitting || loading,
              icon: {
                name: 'plus',
                color: theme.colors.white,
                position: 'after',
              },
            }}
            icon={{ name: 'building-columns' }}
            title={`${duration ? 'Update' : 'Add'} Asset Duration`}>
            <React.Fragment>
              {error && (
                <Flex jc="center" css={{ color: theme.colors.red[500] }}>
                  Operation failed...please try again
                </Flex>
              )}
              {success && (
                <Flex
                  jc="center"
                  css={{ color: theme.colors.green[500] }}
                  className="add-success">
                  {`Asset Duration ${
                    duration ? 'updated' : 'added'
                  } successfully...`}
                </Flex>
              )}

              <Stack>
                <div>
                  <Field name="start">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          type="text"
                          name="start"
                          placeholder="start"
                          label="Start"
                          onChange={formikProps.handleChange}
                          {...field}
                        />
                        <ErrorMessage name="start" />
                      </div>
                    )}
                  </Field>

                  <Field name="end">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          type="text"
                          name="end"
                          placeholder="end"
                          label="End"
                          onChange={formikProps.handleChange}
                          {...field}
                        />
                        <ErrorMessage name="end" />
                      </div>
                    )}
                  </Field>

                  {/* <Field name="start">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          type="text"
                          name="start"
                          placeholder="Enter duration start"
                          label="Start"
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <Flex
                            jc="flex-end"
                            css={{ color: theme.colors.red[200] }}
                          >
                            {meta.error}
                          </Flex>
                        )}
                      </div>
                    )}
                  </Field>

                  <Field name="end">
                    {({ field, meta }: FieldProps) => (
                      <div>
                        <Input
                          type="text"
                          placeholder="Enter duration end"
                          label="End"
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <Flex
                            jc="flex-end"
                            css={{ color: theme.colors.red[200] }}
                          >
                            {meta.error}
                          </Flex>
                        )}
                      </div>
                    )}
                  </Field> */}
                </div>
              </Stack>
            </React.Fragment>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default DurationModal;
