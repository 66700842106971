import React from 'react';
import { jsx } from '@emotion/core';
import Modal from '../modal/Modal';
import { IModal } from '../../utils/types';
import theme from '../../theme/theme';
import Flex from '../layout/Flex';
import Icon from '../icon/Icon';
import Avatar from '../avatar/Avatar';
/**@jsx jsx*/

interface IProps extends IModal {
  message?: string;
}

const Confirm: React.FC<IProps> = ({
  title,
  message,
  confirm,
  cancel,
  onClose,
  icon,
}) => {
  return (
    <Modal
      onClose={onClose}
      confirm={confirm}
      cancel={cancel}
      width="520px"
      title={title}>
      <Flex ai="center">
        {icon && (
          <Avatar
            size="sm"
            css={{ marginRight: '16px' }}
            background={theme.colors[icon.color as string][50]}>
            <Icon
              color={theme.colors[icon.color as string][500]}
              icon={icon.name}></Icon>
          </Avatar>
        )}
        <p className="feedback-message" css={{ color: theme.colors.gray[500] }}>
          {message}
        </p>
      </Flex>
    </Modal>
  );
};

export default Confirm;
