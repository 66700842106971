import * as Yup from 'yup';
import { Member_Beneficiary } from '../generated/graphql';

type BeneficiaryContext = { beneficiaries: Partial<Member_Beneficiary> };
const nonRequiredEmployment = [
  'Self_Employed',
  'Retired',
  'Student',
  'Unemployed',
];

const SUPPORTED_IMAGE_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const semVerRegex = /^((([0-9]+)\.([0-9]+)\.([0-9]+)(?:-([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?)$/;
const numberRegex = /^\d*$/;

export const CustomerAccountTypeSchema = Yup.object().shape({
  name: Yup.string().required('required'),
})

export const ProspectSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().email('Invalid prospect email').nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
  }),
  tin: Yup.string()
  .required('required').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  residential_status: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required(
    'required'
  ),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required').nullable(),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required').nullable(),
    investment_knowledge: Yup.string().required('required').nullable(),
    risk_tolerance: Yup.string().required('required').nullable(),
    investment_horizon: Yup.string().required('required').nullable(),
    approximate_annual_income: Yup.string().required('required').nullable(),
  }).nullable(),
  next_of_kin: Yup.object().shape({
    title: Yup.string().nullable(),
    first_name: Yup.string().when(
      'title',
      (title: any, schema: any) => {
        if (["", null, undefined, "Select Title"].includes(title)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    last_name: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(/^[A-Za-z-_ ]+$/, 'must be letters').nullable();
        }
      }
    ),
    date_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    mobile: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(phoneRegex, 'Invalid phone number').length(10, 'must be 10 digits').nullable();
        }
      }
    ),
    gender_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    place_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    marital_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    relationship_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    gps_address: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    country_of_origin_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", ".", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    region_id: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id !== '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_permit_number: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (['82', '', undefined, null].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_issue_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (['82', '', undefined, null].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_expiry_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (['82', '', undefined, null].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_place_of_issue: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (['82', '', undefined, null].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    )
  }).nullable(),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_payment_instructions: Yup.array().of(
    Yup.object().shape({
      payment_provider: Yup.object()
        .shape({
          id: Yup.number().typeError('required').required('required'),
          payment_channel_id: Yup.number()
            .typeError('required')
            .required('required'),
        })
        .nullable(),
      account_number: Yup.string()
        .when('payment_provider.payment_channel_id', {
          is: (value) => value === 1,
          then: Yup.string().required('required'),
        })
        .nullable(),
      account_name: Yup.string()
        .when('payment_provider.payment_channel_id', {
          is: (value) => value === 1,
          then: Yup.string().required('required'),
        })
        .nullable(),
      branch_id: Yup.string()
        .when('payment_provider.payment_channel_id', {
          is: (value) => value === 1,
          then: Yup.string().required('required'),
        })
        .nullable(),
      mobile_money_number: Yup.string()
        .when('payment_provider.payment_channel_id', {
          is: (value) => value === 2,
          then: Yup.string().required('required'),
        })
        .nullable(),
    })
  ),
  member_identifications: Yup.array().of(
    Yup.object().shape({
      identification_type: Yup.object().shape({
        id: Yup.number().required('required'),
      }),
      country: Yup.object()
        .shape({
          id: Yup.number().required('required'),
        })
        .nullable(),
      identification_card_image_url: Yup.string().nullable(),
      identification_number: Yup.string().required('required').nullable(),
      identification_expiry_date: Yup.string().nullable(),
      identification_issue_date: Yup.string().required('required').nullable(),
      place_of_issue: Yup.string().required('required').nullable(),
    })
  ),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  id_file: Yup.mixed().when(
    'member_identifications[0].identification_card_image_url',
    {
      is: (value) => !value,
      then: Yup.mixed()
        .required('required')
        .test(
          'fileFormat',
          'Unsupported Format',
          (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
        ),
    }
  ),
});

export const RejectFormSchema = Yup.object().shape({
  reason: Yup.string().required('required'),
});

export const AdvisorSchema = Yup.object().shape({
  username: Yup.string().required('required'),
  password: Yup.string()
    .required('required')
    .min(6, 'must be at least 6 characters'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  otherName: Yup.string().nullable(),
  gender: Yup.string().required('required'),
  dateOfBirth: Yup.string().required('required'),
  mobile: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .length(10, 'must be 10 digits')
    .required('required'),
  email: Yup.string().required('required').email('Invalid email'),
  residentialAddress: Yup.string().required('required'),
  identificationType: Yup.string().required('required'),
  identificationNumber: Yup.string().required('required'),
  advisorCode: Yup.string()
    .min(10, 'must be at least 10 characters')
    .max(10, 'must not exceed 10 characters')
    .nullable(),
});

export const AssetDurationSchema = Yup.object().shape({
  start: Yup.string()
    .required('required')
    .matches(numberRegex, 'must be a number'),
  end: Yup.string()
    .required('required')
    .matches(numberRegex, 'must be a number'),
});

export const AssetAllocationSchema = Yup.object().shape({
  product: Yup.string().required('required'),
  duration: Yup.string().required('required'),
  weight: Yup.string()
    .required('required')
    .matches(numberRegex, 'must be a number'),
});

export const UserSchema = Yup.object().shape({
  username: Yup.string().required('required'),
  password: Yup.string()
    .required('required')
    .min(6, 'must be at least 6 characters'),
  firstName: Yup.string().required('required'),
  lastName: Yup.string().required('required'),
  otherName: Yup.string().nullable(),
  gender: Yup.string().required('required'),
  dateOfBirth: Yup.string().required('required'),
  mobile: Yup.string()
    .matches(phoneRegex, 'Invalid phone number')
    .length(10, 'must be 10 digits')
    .required('required'),
  email: Yup.string().required('required').email('Invalid email'),
});

export const PaymentSchema = Yup.object().shape({
  payment_file: Yup.mixed().required('required'),
});

export const StatementSchema = Yup.object().shape({
  startDate: Yup.date().required('required'),
  endDate: Yup.date().required('required'),
});

export const ApkSchema = Yup.object().shape({
  name: Yup.string().required('required'),
  version: Yup.string()
    .required('required')
    .matches(semVerRegex, 'invalid version'),
});

export const AddCustomerSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().required('required').email('Invalid customer email').nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    gender_id: Yup.number().required('required'),
  }),
  title_id: Yup.number().nullable(),
  mf_code: Yup.string().nullable(),
  psl_code: Yup.string()
    .when('mf_code', {
      is: (value) => value !== undefined,
      then: Yup.string().required('required'),
    })
    .nullable(),
  tin: Yup.string()
  .required('required').nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  residential_status: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required(
    'required'
  ),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required').nullable(),
    investment_knowledge: Yup.string().required('required').nullable(),
    risk_tolerance: Yup.string().required('required').nullable(),
    investment_horizon: Yup.string().required('required').nullable(),
    approximate_annual_income: Yup.string().required('required').nullable(),
  }),
  next_of_kin: Yup.object().shape({
    title: Yup.string().nullable(),
    first_name: Yup.string().when(
      'title',
      (title: any, schema: any) => {
        if (["", null, undefined, "Select Title"].includes(title)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    last_name: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(/^[A-Za-z-_ ]+$/, 'must be letters').nullable();
        }
      }
    ),
    date_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    mobile: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(phoneRegex, 'Invalid phone number').length(10, 'must be 10 digits').nullable();
        }
      }
    ),
    gender_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    place_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    marital_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    relationship_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    gps_address: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    country_of_origin_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    region_id: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id !== '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_permit_number: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_issue_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_expiry_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_place_of_issue: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    )
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  id_file: Yup.mixed().when(
    'member_identifications[0].identification_card_image_url',
    {
      is: (value) => !value,
      then: Yup.mixed()
        .required('required')
        .test(
          'fileFormat',
          'Unsupported Format',
          (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
        ),
    }
  ),
});

export const EditCustomerSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().email('Invalid email').nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    gender_id: Yup.number().required('required'),
  }),
  title_id: Yup.number().nullable(),
  mf_code: Yup.string().nullable(),
  psl_code: Yup.string()
    .when('mf_code', {
      is: (value) => value !== undefined,
      then: Yup.string().required('required'),
    })
    .nullable(),
  tin: Yup.string()
    .required('required').nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  residential_status: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  country_of_residence_id: Yup.string().required('required').nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required(
    'required'
  ),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required'),
    investment_knowledge: Yup.string().required('required'),
    risk_tolerance: Yup.string().required('required'),
    investment_horizon: Yup.string().required('required'),
    approximate_annual_income: Yup.string().required('required'),
  }),
  next_of_kin: Yup.object().shape({
    title: Yup.string().nullable(),
    first_name: Yup.string().when(
      'title',
      (title: any, schema: any) => {
        if (["", null, undefined, "Select Title"].includes(title)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    last_name: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(/^[A-Za-z-_ ]+$/, 'must be letters').nullable();
        }
      }
    ),
    date_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    mobile: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(phoneRegex, 'Invalid phone number').length(10, 'must be 10 digits').nullable();
        }
      }
    ),
    gender_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    place_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    marital_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    relationship_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    gps_address: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    country_of_origin_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    region_id: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id !== '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_permit_number: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_issue_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_expiry_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_place_of_issue: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    )
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
});

export const AddCustomerJointSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    first_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().required('required').email('Invalid customer email').nullable(),
    email_jt: Yup.string().required('required').email('Invalid customer email').nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    date_of_birth_jt: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    gender_id: Yup.number().required('required'),
    gender_id_jt: Yup.number().required('required'),
  }),
  title_id: Yup.number().required('required'),
  title_id_jt: Yup.number().required('required'),
  tin: Yup.string().nullable(),
  tin_jt: Yup.string().nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  home_address_jt: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  gps_address_jt: Yup.string().nullable(),
  residential_status: Yup.string().required('required').nullable(),
  residential_status_jt: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  country_of_origin_id_jt: Yup.string().required('required').nullable(),
  country_of_residence_id: Yup.string().required('required').nullable(),
  country_of_residence_id_jt: Yup.string().required('required').nullable(),
  id_type_jt: Yup.string().required('required'),
  id_number_jt: Yup.string().required('required').nullable(),
  id_issue_date_jt: Yup.string().required('required').nullable(),
  id_expiry_date_jt: Yup.string().nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employment_status_jt: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  marital_status_id: Yup.string().required('required').nullable(),
  marital_status_id_jt: Yup.string().required('required').nullable(),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required(
    'required'
  ),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required').nullable(),
    investment_knowledge: Yup.string().required('required').nullable(),
    risk_tolerance: Yup.string().required('required').nullable(),
    investment_horizon: Yup.string().required('required').nullable(),
    approximate_annual_income: Yup.string().required('required').nullable(),
  }),
  next_of_kin: Yup.object().shape({
    title: Yup.string().nullable(),
    first_name: Yup.string().when(
      'title',
      (title: any, schema: any) => {
        if (["", null, undefined, "Select Title"].includes(title)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    last_name: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(/^[A-Za-z-_ ]+$/, 'must be letters').nullable();
        }
      }
    ),
    date_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    mobile: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(phoneRegex, 'Invalid phone number').length(10, 'must be 10 digits').nullable();
        }
      }
    ),
    gender_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    place_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    marital_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    relationship_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    gps_address: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    country_of_origin_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    region_id: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id !== '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_permit_number: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_issue_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_expiry_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_place_of_issue: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (["", undefined, null, "82"].includes(country_of_origin_id)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    )
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  id_file: Yup.mixed().when(
    'member_identifications[0].identification_card_image_url',
    {
      is: (value) => !value,
      then: Yup.mixed()
        .required('required')
        .test(
          'fileFormat',
          'Unsupported Format',
          (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
        ),
    }
  ),
});

// edit joint schema
export const EditCustomerJointTrustSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    first_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),  
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().email('Invalid email').nullable(),
    email_jt: Yup.string().email('Invalid email').nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    date_of_birth_jt: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    gender_id: Yup.number().required('required'),
    gender_id_jt: Yup.number().required('required'),
  }),
  title_id: Yup.number().nullable(),
  mf_code: Yup.string().nullable(),
  psl_code: Yup.string()
    .when('mf_code', {
      is: (value) => value !== undefined,
      then: Yup.string().required('required'),
    })
    .nullable(),
  tin: Yup.string()
    .required('required').nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  home_address_jt: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  gps_address_jt: Yup.string().nullable(),
  residential_status: Yup.string().required('required').nullable(),
  residential_status_jt: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  country_of_origin_id_jt: Yup.string().required('required').nullable(),
  country_of_residence_id_jt: Yup.string().required('required').nullable(),
  id_type_jt: Yup.string().required('required'),
  id_number_jt: Yup.string().required('required').nullable(),
  id_issue_date_jt: Yup.string().required('required').nullable(),
  id_expiry_date_jt: Yup.string().nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employment_status_jt: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation_jt: Yup.string().when(
    'employment_status_jt',
    (employment_status_jt: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status_jt)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required(
    'required'
  ),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required'),
    investment_knowledge: Yup.string().required('required'),
    risk_tolerance: Yup.string().required('required'),
    investment_horizon: Yup.string().required('required'),
    approximate_annual_income: Yup.string().required('required'),
  }),
  next_of_kin: Yup.object().shape({
    title: Yup.string().nullable(),
    first_name: Yup.string().when(
      'title',
      (title: any, schema: any) => {
        if (["", null, undefined, "Select Title"].includes(title)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    last_name: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(/^[A-Za-z-_ ]+$/, 'must be letters').nullable();
        }
      }
    ),
    date_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    mobile: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').matches(phoneRegex, 'Invalid phone number').length(10, 'must be 10 digits').nullable();
        }
      }
    ),
    gender_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    place_of_birth: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    marital_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    relationship_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_status: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    gps_address: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    country_of_origin_id: Yup.string().when(
      'first_name',
      (first_name: any, schema: any) => {
        if (["", null, undefined].includes(first_name)) {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    region_id: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id !== '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_permit_number: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_issue_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    permit_expiry_date: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    ),
    residential_place_of_issue: Yup.string().when(
      'country_of_origin_id',
      (country_of_origin_id: any, schema: any) => {
        if (country_of_origin_id === '82') {
          return schema.nullable();
        } else {
          return schema.required('required').nullable();
        }
      }
    )
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
});

// add customer trust schema
export const AddCustomerTrustSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    first_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().required('required').email('Invalid customer email').nullable(),
    email_jt: Yup.string().nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    date_of_birth_jt: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt2: Yup.string().nullable(),
    gender_id: Yup.number().required('required'),
    gender_id_jt: Yup.number().required('required'),
  }),
  title_id: Yup.number().required('required'),
  title_id_jt: Yup.number().required('required'),
  tin: Yup.string().required('required').nullable(),
  tin_jt: Yup.string().nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  home_address_jt: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  gps_address_jt: Yup.string().nullable(),
  residential_status: Yup.string().required('required').nullable(),
  residential_status_jt: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  country_of_origin_id_jt: Yup.string().required('required').nullable(),
  country_of_residence_id: Yup.string().required('required').nullable(),
  country_of_residence_id_jt: Yup.string().required('required').nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation_jt: Yup.string().nullable(),
  place_of_birth_jt: Yup.string().nullable(),
  marital_status_id: Yup.string().required('required').nullable(),
  marital_status_id_jt: Yup.string().required('required').nullable(),
  relationship_jt: Yup.string().required('required'),
  id_type_jt: Yup.string().required('required'),
  id_number_jt: Yup.string().required('required').nullable(),
  id_issue_date_jt: Yup.string().required('required').nullable(),
  id_expiry_date_jt: Yup.string().nullable(),
  profession_jt: Yup.string().nullable(),
  professional_license_number_jt: Yup.string().nullable(),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required('required'),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required').nullable(),
    investment_knowledge: Yup.string().required('required').nullable(),
    risk_tolerance: Yup.string().required('required').nullable(),
    investment_horizon: Yup.string().required('required').nullable(),
    approximate_annual_income: Yup.string().required('required').nullable(),
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  id_file: Yup.mixed().when(
    'member_identifications[0].identification_card_image_url',
    {
      is: (value) => !value,
      then: Yup.mixed()
        .required('required')
        .test(
          'fileFormat',
          'Unsupported Format',
          (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
        ),
    }
  ),
});

export const EditCustomerTrustSchema = Yup.object().shape({
  user: Yup.object().shape({
    first_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    first_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    last_name_jt: Yup.string()
      .required('required')
      .matches(/^[A-Za-z-_ ]+$/, 'must be letters')
      .nullable(),
    email: Yup.string().required('required').email('Invalid customer email').nullable(),
    email_jt: Yup.string().nullable(),
    date_of_birth: Yup.string().required('required').nullable(),
    date_of_birth_jt: Yup.string().required('required').nullable(),
    mobile: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt: Yup.string()
      .required('required')
      .matches(phoneRegex, 'Invalid phone number')
      .length(10, 'must be 10 digits')
      .nullable(),
    mobile_jt2: Yup.string().nullable(),
    gender_id: Yup.number().required('required'),
    gender_id_jt: Yup.number().required('required'),
  }),
  title_id_jt: Yup.number().nullable(),
  tin: Yup.string().required('required').nullable(),
  tin_jt: Yup.string().nullable(),
  age: Yup.string().default('').nullable(),
  signature: Yup.string().required('required').nullable(),
  home_address: Yup.string().required('required').nullable(),
  home_address_jt: Yup.string().required('required').nullable(),
  gps_address: Yup.string().required('required').nullable(),
  gps_address_jt: Yup.string().nullable(),
  residential_status: Yup.string().required('required').nullable(),
  residential_status_jt: Yup.string().required('required').nullable(),
  country_of_origin_id: Yup.string().required('required').nullable(),
  country_of_origin_id_jt: Yup.string().required('required').nullable(),
  country_of_residence_id_jt: Yup.string().required('required').nullable(),
  region_id: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id !== '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_permit_number_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_issue_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  permit_expiry_date_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue: Yup.string().when(
    'country_of_origin_id',
    (country_of_origin_id: any, schema: any) => {
      if (country_of_origin_id === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  residential_place_of_issue_jt: Yup.string().when(
    'country_of_origin_id_jt',
    (country_of_origin_id_jt: any, schema: any) => {
      if (country_of_origin_id_jt === '82') {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employment_status: Yup.string().required('required').nullable(),
  employer: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_address: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_city: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_email: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  employer_contact: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation: Yup.string().when(
    'employment_status',
    (employment_status: any, schema: any) => {
      if (nonRequiredEmployment.includes(employment_status)) {
        return schema.nullable();
      } else {
        return schema.required('required').nullable();
      }
    }
  ),
  occupation_jt: Yup.string().nullable(),
  place_of_birth_jt: Yup.string().nullable(),
  marital_status_id: Yup.string().required('required').nullable(),
  marital_status_id_jt: Yup.string().required('required').nullable(),
  relationship_jt: Yup.string().required('required'),
  id_type_jt: Yup.string().required('required'),
  id_number_jt: Yup.string().required('required').nullable(),
  id_issue_date_jt: Yup.string().required('required').nullable(),
  id_expiry_date_jt: Yup.string().nullable(),
  profession_jt: Yup.string().nullable(),
  professional_license_number_jt: Yup.string().nullable(),
  regular_top_up_amount: Yup.number().required('required').nullable(),
  initial_investment_amount: Yup.number().required('required'),
  regular_withdrawal_amount: Yup.number().required('required'),
  anticipated_investment_activity_top_ups: Yup.string().required('required'),
  anticipated_investment_activity_withdrawals: Yup.string().required('required'),
  mode_of_statement_delivery: Yup.string().required('required'),
  statement_frequency: Yup.string().required('required'),
  account_mandate: Yup.string().required('required'),
  client_investment_profile: Yup.object().shape({
    investment_objective: Yup.string().required('required').nullable(),
    investment_knowledge: Yup.string().required('required').nullable(),
    risk_tolerance: Yup.string().required('required').nullable(),
    investment_horizon: Yup.string().required('required').nullable(),
    approximate_annual_income: Yup.string().required('required').nullable(),
  }),
  beneficiaries: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        home_address: Yup.string().required('required'),
        gender_id: Yup.string().required('required'),
        last_name: Yup.string()
          .required('required')
          .matches(/^[A-Za-z-_ ]+$/, 'must be letters'),
        date_of_birth: Yup.string().required('required'),
        mobile: Yup.string()
          .required('required')
          .matches(phoneRegex, 'Invalid phone number')
          .length(10, 'must be 10 digits'),
        other_names: Yup.string()
          .matches(/^$|^[A-Za-z-_ ]+$/, 'must be letters')
          .nullable(),
        relationship_id: Yup.number()
          .typeError('required')
          .required('required')
          .nullable(),
        allocation: Yup.number()
          .typeError('must be numeric')
          .required('required')
          .test(
            'allocation-max',
            'sum of all allocations must be 100%',
            function () {
              // @ts-ignore
              // prettier-ignore
              const sum = this.options?.context?.beneficiaries.reduce(
                (total: number, val?: Member_Beneficiary) => {
                  return total + Number(val?.allocation ?? 0);
                },
                0
              ) ?? 0;
              return sum === 100;
            }
          )
          .nullable(),
        place_of_birth: Yup.string().required('required').nullable(),
        marital_status: Yup.string().required('required').nullable(),
        residential_status: Yup.string().required('required').nullable(),
        gps_address: Yup.string().required('required').nullable(),
        country_of_origin_id: Yup.string().required('required').nullable(),
        region_id: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id !== '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),

        residential_permit_number: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_issue_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        permit_expiry_date: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        ),
        residential_place_of_issue: Yup.string().when(
          'country_of_origin_id',
          (country_of_origin_id: any, schema: any) => {
            if (country_of_origin_id === '82') {
              return schema.nullable();
            } else {
              return schema.required('required').nullable();
            }
          }
        )
      })
    ),
  member_goals: Yup.array()
    .of(
      Yup.object().shape({
        guid: Yup.string().required('required'),
        administrative_fees: Yup.number().required('required'),
        current_value: Yup.number().required('required'),
        duration_months: Yup.number().required('required'),
        duration_years: Yup.number().required('required'),
        expected_return: Yup.number().required('required'),
        monthly_contribution: Yup.number().required('required'),
        years_to_fund: Yup.number().required('required'),
        goal_for: Yup.string().nullable(),
      })
    )
    .min(1, 'required'),
  member_payment_instructions: Yup.array()
    .of(
      Yup.object().shape({
        payment_provider: Yup.object()
          .shape({
            id: Yup.number().typeError('required').required('required'),
            payment_channel_id: Yup.number()
              .typeError('required')
              .required('required'),
          })
          .nullable(),
        account_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        account_name: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        branch_id: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 1,
            then: Yup.string().required('required'),
          })
          .nullable(),
        mobile_money_number: Yup.string()
          .when('payment_provider.payment_channel_id', {
            is: (value) => value === 2,
            then: Yup.string().required('required'),
          })
          .nullable(),
      })
    )
    .min(1, 'required'),
  member_identifications: Yup.array()
    .of(
      Yup.object().shape({
        identification_type: Yup.object().shape({
          id: Yup.number().required('required'),
        }),
        country: Yup.object()
          .shape({
            id: Yup.number().required('required'),
          })
          .nullable(),
        identification_card_image_url: Yup.string().nullable(),
        identification_number: Yup.string().required('required').nullable(),
        identification_expiry_date: Yup.string().nullable(),
        identification_issue_date: Yup.string().required('required').nullable(),
        place_of_issue: Yup.string().required('required').nullable(),
      })
    )
    .required('required'),
  member_income_sources: Yup.array()
    .of(
      Yup.object().shape({
        income_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  member_contribution_sources: Yup.array()
    .of(
      Yup.object().shape({
        contribution_source: Yup.object().shape({
          id: Yup.string().required('required'),
          name: Yup.string().required('required'),
        }),
      })
    )
    .min(1, 'required')
    .nullable(),
  id_file: Yup.mixed().when(
    'member_identifications[0].identification_card_image_url',
    {
      is: (value) => !value,
      then: Yup.mixed()
        .required('required')
        .test(
          'fileFormat',
          'Unsupported Format',
          (value) => value && SUPPORTED_IMAGE_FORMATS.includes(value.type)
        ),
    }
  ),
});
