import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import theme from '../../theme/theme';
import ContributionSourcesTable from './components/ContributionSourcesTable';
import ContributionSourceModal from './components/ContributionSourceModal';
import {
  useContributionSourceQuery,
  useDelete_Contribution_SourceMutation,
} from '../../generated/graphql';
import { ContributionSource } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/
import { testRow } from '../../shared/constants';

const ContributionSources: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [
    selectedContributionSource,
    setSelectedContributionSource,
  ] = React.useState<number | undefined>();
  const { data: contributionSources, loading } = useContributionSourceQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deleteContributionSource] = useDelete_Contribution_SourceMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedContributionSource(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (contributionSource: ContributionSource) => {
    setIsOpen(true);
    setSelectedContributionSource(contributionSource.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    try {
      const response = await deleteContributionSource({
        variables: {
          id: selectedContributionSource,
          doneBy: userId,
        },
        update: UpdateHandlers.deleteContributionSource,
      });
      setShowConfirm(false);

      if (response.data?.update_contribution_source?.affected_rows) {
        notify(true, 'Contribution Source deleted successfully.');
      } else {
        notify(false, 'failed to delete contribution source.');
      }
    } catch (err) {}
  };

  const confirm = (contributionSource: ContributionSource) => {
    setSelectedContributionSource(contributionSource.id);
    setShowConfirm(true);
  };

  const getContributionSource = (id?: number) => {
    if (id) {
      const contributionSource = contributionSources?.contribution_source?.find(
        (contributionSource) => contributionSource.id === id
      );
      if (contributionSource) {
        return {
          id: contributionSource.id,
          name: contributionSource.name,
        };
      }
    }
    return;
  };

  const getContributionSources = (): ContributionSource[] => {
    return (
      contributionSources?.contribution_source
        ?.filter((gender) => {
          return gender.name !== testRow;
        })
        .map((contributionSource) => ({
          id: contributionSource.id,
          name: contributionSource.name,
          createdAt: contributionSource.created_at,
          lastChanged: contributionSource.updated_at,
        })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <ContributionSourceModal
          onClose={close}
          contributionSource={getContributionSource(selectedContributionSource)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Contribution Source"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Contribution Sources"
        action={{
          label: 'Add',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <ContributionSourcesTable
        isLoading={loading}
        contributionSources={getContributionSources()}
        actions={[
          { label: 'Delete', action: confirm },
          { label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default ContributionSources;
