import React from 'react';
import { toast } from 'react-toastify';
import { jsx } from '@emotion/core';
import SectionTitle from '../../components/sectionTitle/SectionTitle';
import theme from '../../theme/theme';
import PaymentProvidersTable from './components/PaymentProvidersTable';
import PaymentProviderModal from './components/PaymentProviderModal';
import {
  usePaymentProviderQuery,
  useDelete_Payment_ProviderMutation,
} from '../../generated/graphql';
import { IProvider } from '../../utils/types';
import UpdateHandlers from '../../utils/handlers';
import Confirm from '../../components/confirm/Confirm';
import Toastify from '../../components/toastify/Toastify';
import { useAuthState } from '../../context/auth';
import { ADMIN } from '../../routes/routes';
/**@jsx jsx*/
import { testRow } from '../../shared/constants';

const PaymentProvider: React.FC = () => {
  const { userId } = useAuthState();
  const [isOpen, setIsOpen] = React.useState(false);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState<
    number | undefined
  >();
  const { data: paymentProviders, loading } = usePaymentProviderQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [deletePaymentProvider] = useDelete_Payment_ProviderMutation();

  const close = React.useCallback(() => {
    setIsOpen(false);
    setSelectedProvider(undefined);
    setShowConfirm(false);
  }, []);

  const edit = (provider: IProvider) => {
    setIsOpen(true);
    setSelectedProvider(provider.id);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const remove = async () => {
    const response = await deletePaymentProvider({
      variables: {
        id: selectedProvider,
        doneBy: userId,
      },
      update: UpdateHandlers.deletePaymentProvider,
    });
    setShowConfirm(false);

    if (response.data?.update_payment_provider?.affected_rows) {
      notify(true, 'Payment provider deleted successfully.');
    } else {
      notify(false, 'failed to delete payment provider.');
    }
  };

  const confirm = (provider: IProvider) => {
    setSelectedProvider(provider.id);
    setShowConfirm(true);
  };

  const getProvider = (id?: number) => {
    if (id) {
      const provider = paymentProviders?.payment_provider?.find(
        (provider) => provider.id === id
      );
      if (provider) {
        return {
          id: provider.id,
          name: provider.name,
          code: provider.code,
          channel: provider.payment_channel?.id,
        };
      }
    }
    return;
  };

  const getPaymentProviders = (): IProvider[] => {
    return (
      paymentProviders?.payment_provider
        ?.filter((gender) => {
          return gender.name !== testRow;
        })
        .map((provider) => ({
          id: provider.id,
          name: provider.name,
          code: provider.code,
          channel: provider.payment_channel.name,
          createdAt: provider.created_at,
          lastChanged: provider.updated_at,
          status: 'active',
        })) || []
    );
  };

  return (
    <React.Fragment>
      {isOpen && (
        <PaymentProviderModal
          onClose={close}
          provider={getProvider(selectedProvider)}
        />
      )}

      {showConfirm && (
        <Confirm
          cancel={{ label: 'cancel', function: close }}
          confirm={{ label: 'ok', function: remove }}
          onClose={close}
          title="Delete Payment Provider"
          message="Are you sure you want to delete?"
        />
      )}

      <SectionTitle
        title="Payment Providers"
        action={{
          label: 'Add',
          className: 'add-action',
          icon: { name: 'plus', color: theme.colors.white, position: 'after' },
          function: () => {
            setIsOpen(true);
          },
          allowed: [ADMIN],
        }}></SectionTitle>
      <PaymentProvidersTable
        isLoading={loading}
        providers={getPaymentProviders()}
        actions={[
          { className: 'delete', label: 'Delete', action: confirm },
          { className: 'edit', label: 'Edit', action: edit },
        ]}
      />
    </React.Fragment>
  );
};

export default PaymentProvider;
