/* eslint-disable no-case-declarations */
import React from 'react';
import { jsx } from '@emotion/core';
import { useFormikContext } from 'formik';
import Modal from '../../../components/modal/Modal';
import Stack from '../../../components/stack/Stack';
import NativeSelect from '../../../components/select/NativeSelect';
import {
  FootNote,
  SubHeading,
  HeadingStrip,
} from '../../../components/text/Text';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Input from '../../../components/input/Input';
import Separator from '../../../components/separator/Separator';
import theme from '../../../theme/theme';
import Checkbox from '../../../components/checkbox/Checkbox';
import Button from '../../../components/button/Button';
import { IModal } from '../../../utils/types';
import {
  Member_Goal,
  useGoalsQuery,
  useInsurance_TypeQuery,
  useCalcDurationMutation,
  useCalcMonthlyContributionMutation,
  useCalcFutureValueGivenPpiMutation,
  useCalcFutureValueGivenMcdMutation,
  useGetInsurancePremiumMutation,
} from '../../../generated/graphql';
import { FormData, ReducerState, ReducerActions } from '../../../utils/types';
import { useExpectedReturnsQuery } from '../../../generated/graphql';
import Flex from '../../../components/layout/Flex';
import Icon from '../../../components/icon/Icon';
/**@jsx jsx*/

type Goal = Partial<Member_Goal> & { currentOrTarget?: 'target' };

type GoalCalculatorReturnType = {
  target_amount: number;
  calc_future_val: boolean;
  calc_monthly_payment: boolean;
  calc_duration: boolean;
  monthly_contribution: number;
  admin_fees: number;
  duration_months: number;
  duration_years: number;
  rate: string;
  insurance_monthly_premium: string;
  policy_term: number;
  exp: any;
};

const initialReducerStates: ReducerState = {
  lock1: false,
  lock2: false,
  lock3: false,
  current_value: false,
  target_amount: false,
  duration_years: false,
  duration_months: false,
  years_to_fund: false,
  monthly_contribution: false,
};

interface IProps extends IModal {
  guid: string;
}

const GoalCalculatorModal: React.FC<IProps> = ({ guid, onClose }) => {
  const [callee, setCallee] = React.useState('');
  const [currentOrTarget, setCurrentOrTarget] = React.useState('target');
  const { values, handleChange, setFieldValue } = useFormikContext<FormData>();
  const [calcFutureValueGivenPPI] = useCalcFutureValueGivenPpiMutation();
  const [calcFutureValueGivenMCD] = useCalcFutureValueGivenMcdMutation();
  const [calcMonthlyContribution] = useCalcMonthlyContributionMutation();
  const [getInsurancePremium] = useGetInsurancePremiumMutation();
  const [calcDuration] = useCalcDurationMutation();
  const { data: expectedReturnsData } = useExpectedReturnsQuery();
  const { data: insuranceTypesData } = useInsurance_TypeQuery();
  const { data: goalTypesData } = useGoalsQuery();
  const { member_goals, age } = values;
  const {
    goal_id,
    future_value,
    monthly_contribution,
    administrative_fees,
    current_value,
    duration_months,
    duration_years,
    has_insurance,
    years_to_fund,
    // expected_return,
    goal_insurances,
  } = (member_goals as Member_Goal[]).find(
    (goal) => goal.guid === guid
  ) as Member_Goal;

  const id = (member_goals as Member_Goal[]).findIndex(
    (goal) => goal.guid === guid
  );

  const { insurance_type_id, insurance_premium, insurance_rate_per_1000 } =
    goal_insurances?.[0] ?? {};

  const [locks, dispatch] = React.useReducer<
    React.Reducer<ReducerState, ReducerActions>
  >(
    (prevState, action) => {
      // eslint-disable-next-line default-case
      switch (action.type) {
        case 'TOGGLE_CURRENT':
          return {
            ...prevState,
            current_value: action.current_value,
          };
        case 'TOGGLE_TARGET':
          return {
            ...prevState,
            target_amount: action.target_amount,
          };
        case 'TOGGLE_DURATION':
          return {
            ...prevState,
            duration_years: action.duration_years,
            duration_months: action.duration_months,
            years_to_fund: action.years_to_fund,
          };
        case 'TOGGLE_MONTHLY_CONTRIBUTION':
          return {
            ...prevState,
            monthly_contribution: action.monthly_contribution,
          };
        case 'TOGGLE_LOCK1':
          let changes1: { [key: string]: boolean } = {};
          if (prevState.lock2 || prevState.lock3) {
            changes1['lock2'] = false;
            changes1['lock3'] = false;
            changes1['duration_months'] = false;
            changes1['duration_years'] = false;
            changes1['years_to_fund'] = false;
            changes1['monthly_contribution'] = false;
          }
          if (currentOrTarget === 'target') {
            changes1['target_amount'] = prevState.lock1 ? false : true;
          } else {
            changes1['current_value'] = prevState.lock1 ? false : true;
          }
          changes1['lock1'] = prevState.lock1 ? false : true;
          return {
            ...prevState,
            ...changes1,
          };
        case 'TOGGLE_LOCK2':
          let changes2: { [key: string]: boolean } = {};
          if (prevState.lock1 || prevState.lock3) {
            changes2['lock1'] = false;
            changes2['lock3'] = false;
            if (currentOrTarget === 'target') {
              changes2['target_amount'] = false;
            } else {
              changes2['current_value'] = false;
            }
            changes2['monthly_contribution'] = false;
          }
          changes2['lock2'] = prevState.lock2 ? false : true;
          return {
            ...prevState,
            ...changes2,
          };
        case 'TOGGLE_LOCK3':
          let changes3: { [key: string]: boolean } = {};
          if (prevState.lock1 || prevState.lock2) {
            changes3['lock1'] = false;
            changes3['lock2'] = false;
            changes3['duration_months'] = false;
            changes3['duration_years'] = false;
            changes3['years_to_fund'] = false;
            if (currentOrTarget === 'target') {
              changes3['target_amount'] = false;
            } else {
              changes3['current_value'] = false;
            }
          }
          changes3['lock3'] = prevState.lock3 ? false : true;
          return {
            ...prevState,
            ...changes3,
          };
        default:
          return {
            ...prevState,
          };
      }
    },
    {
      ...initialReducerStates,
      current_value: currentOrTarget === 'current' ? false : true,
      target_amount: currentOrTarget === 'target' ? false : true,
    }
  );

  const {
    lock1,
    lock2,
    lock3,
    current_value: current_value_lock,
    target_amount: target_amount_lock,
    duration_months: duration_months_lock,
    duration_years: duration_years_lock,
    monthly_contribution: monthly_contribution_lock,
  } = locks;

  React.useEffect(() => {
    const goalCalculator = async () => {
      const CURRENT_VALUE = '2';

      let duration = Number(duration_months) || 0;
      if (duration_years) {
        duration += Number(duration_years) * 12 || 0;
      }

      const tenorInYears = duration / 12;
      const expectedReturnObj: { [key: string]: number } = {};
      expectedReturnsData?.expected_return?.map((entry) => {
        expectedReturnObj[String(entry.duration)] = entry.expected_return;
      });

      let expectedReturn = 0.0;
      if (tenorInYears < 1 && Object.keys(expectedReturnObj).length) {
        if (tenorInYears === 0.3) {
          expectedReturn = expectedReturnObj['0.3'];
        } else if (tenorInYears === 0.5) {
          expectedReturn = expectedReturnObj['0.5'];
        } else if (tenorInYears > 0.5) {
          expectedReturn = expectedReturnObj['1'];
        } else if (tenorInYears > 0.3) {
          expectedReturn = expectedReturnObj['0.5'];
        } else {
          expectedReturn = expectedReturnObj['0.3'];
        }
      } else {
        const keys = Object.keys(expectedReturnObj);
        const yearsStr = String(Math.ceil(tenorInYears));
        if (keys.includes(yearsStr)) {
          expectedReturn = expectedReturnObj[yearsStr];
        } else {
          expectedReturn = expectedReturnObj['7'];
        }
      }

      let duration_locked = duration_years_lock && duration_months_lock;
      let amount_locked = target_amount_lock && current_value_lock;
      let monthly_payments_locked = monthly_contribution_lock;
      let monthly_payments_val = Number(monthly_contribution) || 0;
      let target_amount_val = Number(future_value) || 0;
      let current_value_val = Number(current_value) || 0;

      let rate = 0.0;
      let num_years = Math.floor(duration / 12);
      let monthly_premium = 0;
      let calculated_future_val = 0;
      let monthly_contribution_val = 0;
      let value_known = currentOrTarget === 'target' ? '1' : '2';
      let calc_monthly_payment = false;
      let calc_duration = false;
      let calc_future_val = false;

      if (
        (amount_locked && callee === 'monthly_contribution') ||
        callee === 'future_value' ||
        callee === 'current_value'
      ) {
        calc_duration = true;
      }

      if (value_known === CURRENT_VALUE && !amount_locked) {
        calc_future_val = true;
        const goalType = goalTypesData?.goal?.find(
          (type) => type.id === goal_id
        );
        const calcResponse = await calcFutureValueGivenPPI({
          variables: {
            goalType: goalType?.name ?? '',
            currentValue: current_value_val,
            duration,
            yearsToFund: years_to_fund,
          },
        });
        calculated_future_val =
          calcResponse.data?.goalCalculations?.value ?? 0.0;
      } else if (
        monthly_payments_val &&
        !amount_locked &&
        callee !== 'future_value'
      ) {
        calc_future_val = true;
        const calcResponse = await calcFutureValueGivenMCD({
          variables: {
            monthlyContribution: monthly_payments_val,
            duration,
            expectedReturn,
          },
        });
        calculated_future_val =
          calcResponse.data?.goalCalculations?.value ?? 0.0;
      }

      if (!monthly_payments_locked && calculated_future_val) {
        calc_monthly_payment = true;
        const calcResponse = await calcMonthlyContribution({
          variables: {
            futureValue: calculated_future_val,
            duration,
            expectedReturn,
          },
        });
        monthly_contribution_val =
          calcResponse.data?.goalCalculations?.value ?? 0.0;
      }

      if (
        (duration_locked || amount_locked) &&
        !monthly_payments_locked &&
        callee !== 'monthly_contribution'
      ) {
        calc_monthly_payment = true;

        if (calculated_future_val) {
          const calcResponse = await calcMonthlyContribution({
            variables: {
              futureValue: calculated_future_val,
              duration,
              expectedReturn,
            },
          });
          monthly_contribution_val =
            calcResponse.data?.goalCalculations?.value ?? 0.0;
        } else {
          const calcResponse = await calcMonthlyContribution({
            variables: {
              futureValue: target_amount_val,
              duration,
              expectedReturn,
            },
          });
          monthly_contribution_val =
            calcResponse.data?.goalCalculations?.value ?? 0.0;
        }
      } else if (
        (amount_locked || monthly_payments_locked) &&
        calc_duration &&
        monthly_payments_val
      ) {
        const total =
          expectedReturnObj['0.3'] +
          expectedReturnObj['0.5'] +
          expectedReturnObj['1'];
        expectedReturn = total / 3;
        calc_duration = true;

        if (calc_future_val) {
          const calcResponse = await calcDuration({
            variables: {
              futureValue: calculated_future_val,
              monthlyContribution: monthly_payments_val,
              expectedReturn,
            },
          });
          duration = calcResponse.data?.goalCalculations?.value ?? 0.0;
        } else {
          const calcResponse = await calcDuration({
            variables: {
              futureValue: target_amount_val,
              monthlyContribution: monthly_payments_val,
              expectedReturn,
            },
          });
          duration = calcResponse.data?.goalCalculations?.value ?? 0.0;
        }

        if (value_known === CURRENT_VALUE) calc_duration = false;
      }

      if (has_insurance && insurance_type_id && age) {
        const sum_assured =
          calculated_future_val && calc_future_val
            ? calculated_future_val
            : target_amount_val;
        const response = await getInsurancePremium({
          variables: {
            insuranceTypeId: Number(insurance_type_id),
            sumAssured: sum_assured,
            age: age as number,
            duration,
          },
        });

        if (response.data?.calculateInsurancePremium?.rate) {
          rate = response.data?.calculateInsurancePremium?.rate;
          monthly_premium =
            response.data?.calculateInsurancePremium?.monthlyPremium ?? 0;
        }
      }

      return {
        target_amount: calculated_future_val
          ? calculated_future_val
          : target_amount_val,
        calc_future_val: calc_future_val,
        calc_monthly_payment: calc_monthly_payment,
        calc_duration: calc_duration,
        monthly_contribution: monthly_contribution_val
          ? (monthly_contribution_val * 1) / 0.98
          : monthly_payments_val,
        admin_fees: monthly_contribution_val
          ? (monthly_contribution_val * 1) / 0.98 - monthly_contribution_val
          : monthly_payments_val - monthly_payments_val * 0.98,
        duration_months: duration % 12,
        duration_years: Math.floor(duration / 12),
        rate: has_insurance ? rate.toFixed(2) : '0.0',
        insurance_monthly_premium: has_insurance
          ? monthly_premium.toFixed(2)
          : '0.0',
        policy_term: num_years,
        exp: expectedReturn?.toFixed(4) ?? 0.0,
      };
    };

    const updateFields = (res: GoalCalculatorReturnType, callee: string) => {
      const exp = Number(res.exp) * 100;
      setFieldValue(`member_goals[${id}].expected_return`, exp.toFixed(2));
      if (has_insurance) {
        setFieldValue(
          `member_goals[${id}].goal_insurances[0].insurance_rate_per_1000`,
          res.rate
        );
        setFieldValue(
          `member_goals[${id}].goal_insurances[0].insurance_premium`,
          res.insurance_monthly_premium
        );
      }

      if (res.calc_future_val && !['future_value'].includes(callee)) {
        setFieldValue(
          `member_goals[${id}].future_value`,
          typeof res.target_amount === 'string'
            ? res.target_amount
            : res.target_amount.toFixed(2)
        );
      }

      if (res.calc_monthly_payment && callee !== 'monthly_contribution') {
        setFieldValue(
          `member_goals[${id}].monthly_contribution`,
          res.monthly_contribution === Infinity
            ? 0.0
            : typeof res.monthly_contribution === 'string'
            ? res.monthly_contribution
            : res.monthly_contribution.toFixed(2)
        );
        setFieldValue(
          `member_goals[${id}].administrative_fees`,
          typeof res.admin_fees === 'string'
            ? res.admin_fees
            : res.admin_fees.toFixed(2)
        );
      }

      if (res.calc_monthly_payment && callee === 'monthly_contribution') {
        setFieldValue(
          `member_goals[${id}].administrative_fees`,
          typeof res.admin_fees === 'string'
            ? res.admin_fees
            : res.admin_fees.toFixed(2)
        );
      }

      if (
        res.calc_duration &&
        !['duration_months', 'duration_years'].includes(callee)
      ) {
        setFieldValue(
          `member_goals[${id}].duration_years`,
          res.duration_years.toString()
        );
        setFieldValue(
          `member_goals[${id}].duration_months`,
          res.duration_months.toString()
        );
      }
    };

    const runGoalCalculation = async () => {
      const res = await goalCalculator();
      updateFields(res, callee);
      if (['duration_months', 'duration_years'].includes(callee)) {
        setCallee('future_value');
      }
    };

    if (lock1 || lock2 || lock3) {
      runGoalCalculation();
    }
  }, [
    setFieldValue,
    future_value,
    monthly_contribution,
    currentOrTarget,
    current_value,
    duration_months,
    duration_years,
    callee,
    lock1,
    lock2,
    lock3,
    current_value_lock,
    duration_years_lock,
    monthly_contribution_lock,
    target_amount_lock,
    duration_months_lock,
    goalTypesData,
    goal_id,
    insurance_type_id,
    age,
    has_insurance,
    years_to_fund,
    id,
    calcDuration,
    calcFutureValueGivenMCD,
    calcFutureValueGivenPPI,
    expectedReturnsData,
    calcMonthlyContribution,
    getInsurancePremium,
  ]);

  React.useEffect(() => {
    const future_value = values?.member_goals?.[id]?.future_value;
    const current_value = values?.member_goals?.[id]?.current_value;
    if (future_value && Number(future_value) > 0 && !Number(current_value)) {
      setCurrentOrTarget('target');
    }
    if (current_value && Number(current_value) > 0 && !Number(future_value)) {
      setCurrentOrTarget('current');
    }
  }, [id, values]);

  const getGoalType = () => {
    return goalTypesData?.goal?.find((goalType) => goalType.id === goal_id);
  };

  const handleTargetOrCurrentChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    if (value === 'target') {
      dispatch({ type: 'TOGGLE_TARGET', target_amount: false });
      dispatch({ type: 'TOGGLE_CURRENT', current_value: true });
      setFieldValue(`member_goals[${id}].future_value`, 0);
    } else {
      dispatch({ type: 'TOGGLE_TARGET', target_amount: true });
      dispatch({ type: 'TOGGLE_CURRENT', current_value: false });
      setFieldValue(`member_goals[${id}].current_value`, 0);
    }

    setCurrentOrTarget(value);
  };

  const toggleCurrentOrTarget = () => {
    if (currentOrTarget === 'current') {
      dispatch({ type: 'TOGGLE_CURRENT', current_value: !locks.current_value });
    } else if (currentOrTarget === 'target') {
      dispatch({ type: 'TOGGLE_TARGET', target_amount: !locks.target_amount });
    }
  };

  const toggleDuration = () => {
    dispatch({
      type: 'TOGGLE_DURATION',
      duration_months: !locks.duration_months,
      duration_years: !locks.duration_years,
      years_to_fund: !locks.years_to_fund,
    });
  };

  const toggleMonthlyContributions = () => {
    dispatch({
      type: 'TOGGLE_MONTHLY_CONTRIBUTION',
      monthly_contribution: !locks.monthly_contribution,
    });
  };

  const handleOnChange = (callee: string, item: string) => {
    setFieldValue(`member_goals[${id}].${callee}`, item);
    setCallee(callee);
  };

  return (
    <Modal
      onClose={onClose}
      width="700px"
      top={50}
      icon={{ name: 'certificate' }}
      title="Goal Calculator">
      <Stack css={{ marginBottom: '24px' }}>
        <Stack>
          <div>
            <NativeSelect
              label="I know the"
              onChange={handleTargetOrCurrentChange}>
              <option value="target">Target Value</option>
              <option value="current">Current Value</option>
            </NativeSelect>
            <FootNote gap={8}>
              This will be used to calculate a monthly payment and a duration
            </FootNote>
          </div>
          <Grid>
            <GridItem span={11}>
              <Grid gap={12} lg={2}>
                <Input
                  label="Current Value"
                  name={`member_goals[${id}].current_value`}
                  onChange={(e) =>
                    handleOnChange('current_value', e.target.value)
                  }
                  value={current_value ?? 0.0}
                  disabled={locks.current_value}
                />
                <Input
                  label="Target Amount"
                  name={`member_goals[${id}].future_value`}
                  onChange={(e) =>
                    handleOnChange('future_value', e.target.value)
                  }
                  value={future_value ?? 0.0}
                  disabled={locks.target_amount}
                />
              </Grid>
            </GridItem>
            <GridItem span={1}>
              <Flex
                ai="center"
                jc="center"
                onClick={() => {
                  toggleCurrentOrTarget();
                  dispatch({ type: 'TOGGLE_LOCK1' });
                }}
                styles={{
                  marginTop: '30px',
                }}>
                <Icon
                  color={theme.colors.primary}
                  icon={['fas', lock1 ? 'lock' : 'lock-open']}
                  size={'2x'}
                />
              </Flex>
            </GridItem>
          </Grid>
        </Stack>
        <div>
          <HeadingStrip>
            <SubHeading gap={0}>Duration</SubHeading>
          </HeadingStrip>
          <Stack>
            <Grid>
              <GridItem span={11}>
                <Grid gap={12} lg={2}>
                  <Input
                    label="Years"
                    name={`member_goals[${id}].duration_years`}
                    onChange={(e) =>
                      handleOnChange('duration_years', e.target.value)
                    }
                    value={duration_years ?? 0}
                    disabled={locks.duration_years}
                  />
                  <Input
                    label="Months"
                    name={`member_goals[${id}].duration_months`}
                    onChange={(e) =>
                      handleOnChange('duration_months', e.target.value)
                    }
                    value={duration_months ?? 0}
                    disabled={locks.duration_months}
                  />
                </Grid>
              </GridItem>
              <GridItem span={1}>
                <Flex
                  onClick={() => {
                    toggleDuration();
                    dispatch({ type: 'TOGGLE_LOCK2' });
                    setCallee('future_value');
                  }}
                  styles={{
                    marginTop: '30px',
                  }}>
                  <Icon
                    icon={['fas', lock2 ? 'lock' : 'lock-open']}
                    size={'2x'}
                  />
                </Flex>
              </GridItem>
            </Grid>
            {(getGoalType()?.name?.toLowerCase() === 'education' ||
              getGoalType()?.name?.toLowerCase() === 'retirement') && (
              <Grid>
                <GridItem span={11}>
                  <Input
                    label="Number of Years you want to Fund...?"
                    name={`member_goals[${id}].years_to_fund`}
                    onChange={(e) =>
                      handleOnChange('years_to_fund', e.target.value)
                    }
                    value={years_to_fund ?? 0}
                    disabled={locks.years_to_fund}
                  />
                </GridItem>
              </Grid>
            )}
          </Stack>
        </div>
        <div>
          <HeadingStrip>
            <SubHeading gap={0}>Contributions</SubHeading>
          </HeadingStrip>
          <Stack>
            <Grid>
              <GridItem span={11}>
                <Grid gap={12} lg={2}>
                  <Input
                    label="Monthly Contribution"
                    name={`member_goals[${id}].monthly_contribution`}
                    onChange={(e) =>
                      handleOnChange('monthly_contribution', e.target.value)
                    }
                    value={monthly_contribution ?? 0.0}
                    disabled={locks.monthly_contribution}
                  />
                  <Input
                    label="Administrative Fees"
                    value={administrative_fees ?? 0.0}
                    disabled
                  />
                </Grid>
              </GridItem>
              <GridItem span={1}>
                <Flex
                  onClick={() => {
                    toggleMonthlyContributions();
                    dispatch({ type: 'TOGGLE_LOCK3' });
                  }}
                  styles={{
                    marginTop: '30px',
                  }}>
                  <Icon
                    icon={['fas', lock3 ? 'lock' : 'lock-open']}
                    size={'2x'}
                  />
                </Flex>
              </GridItem>
            </Grid>
          </Stack>
        </div>
        {getGoalType()?.name?.toLowerCase() === 'education' && (
          <div>
            <div>
              <HeadingStrip>
                <SubHeading gap={0}>Insurance</SubHeading>
              </HeadingStrip>
              <Stack spacing={24}>
                <Checkbox
                  id="is_insured"
                  label="Has Insurance"
                  name={`member_goals[${id}].has_insurance`}
                  onChange={handleChange}
                  value={has_insurance}
                />
                <NativeSelect
                  label="Available Insurance Covers"
                  name={`member_goals[${id}].goal_insurances[0].insurance_type_id`}
                  onChange={handleChange}
                  value={insurance_type_id}>
                  <option value="">Select cover</option>
                  {insuranceTypesData?.insurance_type?.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </NativeSelect>
              </Stack>

              <Separator />
              <p
                css={{
                  fontSize: '14px',
                  color: theme.colors.gray[500],
                  marginBottom: '16px',
                }}>
                Rate per 1000
              </p>

              <Grid gap={12} lg={2}>
                <Input
                  label="Monthly Premium"
                  value={insurance_premium ?? 0.0}
                  disabled
                />
                <Input
                  label="Rate"
                  value={insurance_rate_per_1000 ?? 0.0}
                  disabled
                />
              </Grid>
            </div>
            <div>
              <HeadingStrip>
                <SubHeading gap={0}>TOTAL CONTRIBUTION</SubHeading>
              </HeadingStrip>
              <Stack spacing={24}>
                <Grid gap={12} lg={2}>
                  <Input
                    label="Total Monthly Contribution"
                    value={
                      Number(monthly_contribution ?? 0.0) +
                      Number(insurance_premium ?? 0.0)
                    }
                    disabled
                  />
                </Grid>
              </Stack>
            </div>
          </div>
        )}
      </Stack>

      <Stack isInline>
        <Button type="button" action={onClose}>
          Done
        </Button>
        {/* <Button appearance="outline">Save Only</Button> */}
      </Stack>
    </Modal>
  );
};

export default GoalCalculatorModal;
