import React from 'react';
import { jsx } from '@emotion/core';
import Table, { TableRow } from './Table';
import Cell from './components/Cell';
import usePagination from '../../hooks/usePagination';
import ActionSelect from '../select/ActionSelect';
import { ITable } from '../../utils/types';
import Button from '../button/Button';
import Checkbox from '../checkbox/Checkbox';
import ColumnFilterControl from './components/ColumnFilterControl';
import Stack from '../stack/Stack';
import SearchControl from './components/SearchControl';
/**@jsx jsx*/

const DataTable: React.FC<ITable> = ({
  className,
  columns,
  data = [],
  showControls = true,
  showPagination = true,
  paginationData,
  actions,
  searchFilter,
  columnFilter,
  rowSelection,
  isLoading,
}) => {
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [columnFilterKeyword, setColumnFilterKeyword] = React.useState('');


  function handleSearch(e: any) {
    setSearchKeyword(e.target.value);
  }

  function handleColumnFilter(e: any) {
    setColumnFilterKeyword(e.target.value);
  }

  const headings =
    columns &&
    columns.map((column) => {
      return column.title;
    });

  function getSearchResults() {
    let filteredData = data;

    if (columnFilterKeyword) {
      const { id = '' } = columnFilter ?? {};
      filteredData = data.filter((item) => {
        return item[id]?.includes(columnFilterKeyword.toLowerCase());
      });
    }

    if (typeof searchFilter === 'string') {
      filteredData = data.filter((item) => {
        if (!item[searchFilter as string]) {
          return '';
        }

        return item[searchFilter as string]
          .toString()
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
      });
    }

    if (Array.isArray(searchFilter)) {
      const results = searchFilter.map((filter) => {
        return filteredData.filter((item) => {
          if (!item[filter.trim()]) {
            return '';
          }
          return item[filter.trim()]
            .toString()
            .toLowerCase()
            .includes(searchKeyword.toLowerCase());
        });
      });

      const tempResults = results.flat();
      const resultsSet = new Set();
      tempResults.forEach((row) => {
        resultsSet.add(JSON.stringify(row));
      });
      filteredData = Array.from(resultsSet).map((row) => {
        return JSON.parse(row as string);
      });
    }

    return filteredData;
  }

  const dataToPaginate =
    searchKeyword || columnFilterKeyword ? getSearchResults() : data;

  const { paginatedData, paginationConfig } = usePagination(
    dataToPaginate,
    paginationData
  );

  return (
    <React.Fragment>
      <Stack>
        {showControls && (
          <Stack isInline spacing={8} jc="space-between">
            <SearchControl keyword={searchKeyword} onSearch={handleSearch} />
            {columnFilter && columnFilter.id && columnFilter.type && (
              <ColumnFilterControl
                data={data}
                id={columnFilter.id}
                type={columnFilter.type}
                keyword={columnFilterKeyword}
                onFilter={handleColumnFilter}
              />
            )}
          </Stack>
        )}
        <Table
          isLoading={isLoading}
          searchKeyword={searchKeyword}
          headings={actions ? [...(headings as []), 'Actions'] : headings}
          showControls={showControls}
          isEmpty={paginatedData.length === 0}
          paginationData={paginationConfig}
          className={className}
          showPagination={data.length > 0 && showPagination}
          rowSelection={rowSelection}>
          {paginatedData.map((item, index) => {
            return (
              <TableRow key={index}>
                {rowSelection && (
                  <Cell>
                    <Checkbox id={`row-${index}`} />
                  </Cell>
                )}
                {columns?.map((column, index) => {
                  if (column.render) {
                    return (
                      <Cell key={index}>
                        {column.render(item[column.dataIndex])}
                      </Cell>
                    );
                  }
                  return <Cell key={index}>{item[column.dataIndex]}</Cell>;
                })}
                {actions && (
                  <Cell>
                    {actions.length === 1 ? (
                      <Button
                        path={actions[0].path}
                        action={(e: any) => {
                          e.stopPropagation();
                          if (actions[0].action) {
                            actions[0].action(item);
                          }
                        }}>
                        {actions[0].label}
                      </Button>
                    ) : (
                      <ActionSelect
                        className="action-select"
                        options={actions.map((action) => {
                          return {
                            label: action.label,
                            action: () => {
                              if (action.action) {
                                action.action(item);
                              }
                            },
                          };
                        })}></ActionSelect>
                    )}
                  </Cell>
                )}
              </TableRow>
            );
          })}
        </Table>
      </Stack>
    </React.Fragment>
  );
};

export default DataTable;
